const initialState = {
  fulfillmentTypes: [],
  fullfillmentTypesFetched: false,
  quoteTypes: [],
  quoteTypesFulfilled: false,
  quoteClose: [],
  quoteCloseFulfilled: false,
  contactReasons: [],
  contactReasonsFulfilled: false,
  inventoryCondtions: [],
  inventoryCondtionsFulfilled: false,
  noteTypes: [],
  noteTypesFulfilled: false,
  platforms: [],
  platformsFulfilled: false,
  locations: [],
  locationssFulfilled: false,
  users: [],
  usersFulfilled: false
};

export default function setting(state = initialState, action) {
  switch (action.type) {
    case 'FULFILLMENT_TYPES_FULFILLED':
      return {
        ...state,
        fullfillmentTypesFetched: true,
        fulfillmentTypes: action.payload.data
      };
    case 'FULFILLMENT_TYPES_REJECTED':
      return {
        ...state,
        fullfillmentTypesFetched: false,
        fulfillmentTypes: action.payload
      };
    case 'GET_QUOTE_TYPES_FULFILLED':
      return {
        ...state,
        quoteTypes: action.payload.data,
        quoteTypesFulfilled: true
      };
    case 'GET_QUOTE_TYPES_REJECTED':
      return {
        ...state,
        quoteTypesError: action.payload.data,
        quoteTypesFulfilled: false
      };

    case 'GET_QUOTE_CLOSE_FULFILLED':
      return {
        ...state,
        quoteClose: action.payload.data,
        quoteCloseFulfilled: true
      };
    case 'GET_QUOTE_CLOSE_REJECTED':
      return {
        ...state,
        quoteClosedError: action.payload.data,
        quoteClosedFulfilled: false
      };

    case 'GET_CONTACT_REASONS_FULFILLED':
      return {
        ...state,
        contactReasons: action.payload.data,
        contactReasonsFulfilled: true
      };
    case 'GET_CONTACT_REASONS_REJECTED':
      return {
        ...state,
        contactReasonsError: action.payload.data,
        contactReasonsFulfilled: false
      };
    case 'GET_INVENTORY_CONDITIONS_FULFILLED':
      return {
        ...state,
        inventoryCondtions: action.payload.data,
        inventoryCondtionsFulfilled: true
      };
    case 'GET_INVENTORY_CONDITIONS_REJECTED':
      return {
        ...state,
        inventoryCondtions: action.payload.data,
        inventoryCondtionsFulfilled: false
      };
    case 'GET_NOTETYPES_FULFILLED':
      return {
        ...state,
        noteTypes: action.payload.data,
        noteTypesFulfilled: true
      };
    case 'GET_NOTETYPES_REJECTED':
      return {
        ...state,
        noteTypes: action.payload.data,
        noteTypesFulfilled: false
      };
    case 'GET_PLATFORMS_FULFILLED':
      return {
        ...state,
        platforms: action.payload.data,
        platformsFulfilled: true
      };
    case 'GET_PLATFORMS_REJECTED':
      return {
        ...state,
        platforms: action.payload.data,
        platformsFulfilled: false
      };
    case 'GET_LOCATIONS_FULFILLED':
      return {
        ...state,
        locations: action.payload.data,
        locationsFulfilled: true
      };
    case 'GET_LOCATIONS_REJECTED':
      return {
        ...state,
        locations: action.payload.data,
        locationsFulfilled: false
      };
    case 'GET_USERS_FULFILLED':
      return {
        ...state,
        users: action.payload.data,
        usersFulfilled: true
      };
    case 'GET_USERS_REJECTED':
      return {
        ...state,
        users: action.payload.data,
        usersFulfilled: false
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
