import { Dispatch, SetStateAction } from "react";
import { UseUnprintPickItem } from "./UnprintPickItem/UnprintPickItemTypes";

export interface PickListContextType
  extends UsePickList, UsePrintPickList, UseUnprintPickItem {
    mapPLItemToPickableItem: (transactionReferenceId: number) => PrintPickListItem;
   };

export type PickListTypesEnumValue = {
  path: string;
  responseProperty: keyof PickListResponse;
}

export type ListType = 'PRINTED' | 'UNPRINTED';

export type PickListTypesEnum = {
  PRINTED: ListType;
  UNPRINTED: ListType;
}

export const LIST_TYPE_ENUM: PickListTypesEnum = {
  UNPRINTED: 'UNPRINTED',
  PRINTED: 'PRINTED',
}

export type PickableItemsQueryParams = {
  warehouse?: string;
  listType?: ListType;
};

export type ChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

export type GetPickList = (() => Promise<void>) | (() => void);

export type PickListErrors = {
  warehouse?: string[];
  printed?: string[];
}

export interface PickListResponse extends PickListErrors {
  merged_pick_list?: PickableItem[]
  unprinted_pick_list?: PickableItem[]
  printed_pick_list?: PickableItem[]
}

export type UsePickList = {
  pickList: PickableItem[];
  getPickList: GetPickList;
  setPickList: Dispatch<SetStateAction<PickableItem[]>>;
  queryParams: PickableItemsQueryParams;
  loadingPickList: boolean;
  setQueryParams: Dispatch<SetStateAction<PickableItemsQueryParams>>;
  pickListErrors: PickListErrors;
  setPickListErrors: Dispatch<SetStateAction<boolean>>;
};

export type UsePrintPickList = {
  itemsToPrint: number[];
  setItemsToPrint: Dispatch<SetStateAction<number[]>>;
  loadingPrintPickList: boolean;
  printPickListErrors: PrintPickListErrors;
  setPrintPickListErrors: Dispatch<SetStateAction<PrintPickListErrors>>;
  printPickListSuccess: boolean;
  setPrintPickListSuccess: Dispatch<SetStateAction<boolean>>;
  printPickList: PrintPickList;
};

export type PrintPickListItem = {
  imsTransactionReferenceId: null | number;
  itemMemberRefId: null | number;
  itemGroupRefId: null | number;
  fkProductId: null | number;
  productUpc: null | string;
  productAltUpc?: null | string;
  itemCondition: null | string;
  itemWarehouse: null | string;
  quantityRequested: null | number;
  isExpress: boolean;
  isFreight: boolean;
  isPremium: boolean;
  isPrime: boolean;
  shipByDate: null | string;
  suggestedBinId?: null | number;
};

export type PrintPickListData = {
  printedBy: string;
  items: PrintPickListItem[];
}

export type PrintPickListErrors = {
  error?: string;
  message?: {
    printedBy: string[];
    items: string[];
  },
  itemErrors?: {
    [key: number]: string[];
  }
};

export type PrintResponseItem = {
  ims_transaction_reference_id: number;
  item_member_ref_id: number;
  item_group_ref_id: number;
  fk_product_id: number;
  product_upc: string;
  product_alt_upc: null | string;
  item_condition: string;
  item_warehouse: string;
  quantity_requested: number;
  is_express: boolean;
  is_freight: boolean;
  is_premium: boolean;
  is_prime: boolean;
  ship_by_date: string;
  suggested_bin_id: number;
  ims_pick_list_id: number;
  updated_at: string;
  created_at: string;
  id: number;
}
export type PrintPickListSuccessResponse = PrintResponseItem[];
export type PrintPickListResponse = PrintPickListErrors | PrintPickListSuccessResponse;


export type PrintPickList =
  | ((printPickListData: PrintPickListData) => Promise<PrintPickListResponse>)
  | (() => void);



export type PickableItem = {
  transactionReferenceId: number;
  quantity: number;
  invoice: number;
  tid: number;
  productId: number;
  model: string;
  upc: string;
  alternateUpc?: null | string;
  condition: string;
  warehouse: string;
  isFreight: boolean;
  isPrime: boolean;
  isPremium: boolean;
  isExpress: boolean;
  shipByDate: string;
  suggestedBin: PickListBin;
  availableBins?: PickListBin[];
  printedAt?: null | string;
  warning?: null | string;
  pickListId?: null | number;
  pickListItemId?: null | number;
};

export type PickListBin = {
  binId?: null | number;
  binWarehouse?: null | string;
  binCondition?: null | string;
  binName?: null | string;
  binShelf?: null | number;
  binRow?: null | number;
  binBay?: null | number;
  binItemId?: null | number;
  itemQtyInBin?: null | number;
};