import React, { useRef } from 'react';
import {
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import TableView from '../../common/LayoutComponents/Table/TableView';
import GlueButton from '../Presentational/GlueButton.tsx';

function Field(props) {
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      props.onKeyDownCallback(event);
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
      }}
    >
      <h3 style={{ margin: 0 }}>{props.label}</h3>
      <TextField
        variant="outlined"
        style={{ marginLeft: 25, marginBottom: '1rem', flex: 1 }}
        onKeyDown={handleKeyDown}
        inputRef={props.forwardRef}
        label={props.label}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        autoFocus
      />
    </div>
  );
}

function ScannedItems({ scannedItems }) {
  return (
    <div style={{ width: '100%' }}>
      <ExpansionPanel>
        <ExpansionPanelSummary>
          <h5>Scanned Items {scannedItems.length}</h5>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableView header={['UPC', 'Serials']}>
            {scannedItems.map((scannedItem, key) => (
              <tr key={key}>
                {Object.entries(scannedItem).map(([k, v]) => {
                  if (k !== 'id') {
                    if (v instanceof Array) {
                      return <td key={key}>{v.join(',')}</td>;
                    }
                    return <td key={k}>{v}</td>;
                  }
                })}
              </tr>
            ))}
          </TableView>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default function Scanner(props) {
  const {
    addSerial,
    amountScanned,
    amountToScan,
    commitmentId,
    commitmentType,
    currentSerial,
    handleCurrentSerial,
    handleUpc,
    message,
    model,
    numberInCommitment,
    scannedItems,
    setScanSetup,
    serials,
    status,
    upc,
    validateUpc,
    forwardRef
  } = props;

  const serialField = useRef(null);
  const laymanTerms = { receipt: 'Purchase Order' };

  return (
    <div
      style={{
        padding: '1rem',
        backgroundColor: status !== null ? (status === true ? '#d9ffc2' : '#ffc2c2') : null
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 20 }}>
            <h5 style={{ marginBottom: 0 }}>{laymanTerms[commitmentType]}</h5>
            <h1 style={{ marginTop: 0, fontWeight: 100 }}>{commitmentId}</h1>
          </div>
          <div style={{ marginRight: 20 }}>
            <h5 style={{ marginBottom: 0 }}>Model</h5>
            <h1 style={{ marginTop: 0, fontWeight: 100 }}>{model}</h1>
          </div>
        </div>
        <div style={{ marginRight: 20 }}>
          <h5 style={{ marginBottom: 0 }}>Items Scanned</h5>
          <h1 style={{ marginTop: 0, fontWeight: 100 }}>
            {scannedItems.length} / {numberInCommitment}
          </h1>
        </div>
      </div>
      {message && <h3>Message: {message}</h3>}
      <form>
        <Field
          forwardRef={forwardRef}
          label="Scan UPC"
          onKeyDownCallback={() => {
            validateUpc(forwardRef, () => {
              setTimeout(() => {
                serialField.current.focus();
              }, 500);
            });
          }}
          onChange={handleUpc}
          value={upc}
        />
        {amountToScan > 0 && (
          <>
            <Field
              forwardRef={serialField}
              label={`Scan Serial ${amountScanned} / ${amountToScan}`}
              onKeyDownCallback={() => {
                // addScannedItem();
                // upcField.current.focus();
                addSerial(forwardRef, () => {
                  setTimeout(() => {
                    forwardRef.current.focus();
                  }, 500);
                });
              }}
              onChange={handleCurrentSerial}
              value={currentSerial}
              disabled={message !== ''}
            />
            <h4>Serials Scanned for this UPC</h4>
            <div>
              {serials.map(serial => (
                <p key={serial}>{serial}</p>
              ))}
            </div>
          </>
        )}
      </form>
      <div>{scannedItems.length > 0 && <ScannedItems scannedItems={scannedItems} />}</div>
      <GlueButton
        style={{ marginLeft: 'auto', display: 'block' }}
        onClick={() => setScanSetup(true)}
      >
        Scan Setup
      </GlueButton>
    </div>
  );
}
