export default class ScanEvent {
  /**
   * The value that was scanned.
   * @type {string}
   */
  scannedValue: string;

  /**
   * Controls whether the scan event will "bubble" up the registered listeners, in the
   * order of a stack (last one in, first one out). To stop scan event bubbling, use
   * the method "stopPropagation" on the event object, just as you would a normal DOM event.
   * @type {boolean} [bubbles=true]
   */
  bubbles: boolean = true;

  /**
   * Controls whether the scan event will trigger listeners when the event target is
   * a user-inputtable HTML element such as input or textarea. False by default, since
   * intuitively if a user is targeting an inputtable element in the browser, they most
   * likely do not want other scan listening side effects to be invoked (e.g. redirects).
   * If you would like to enable listening for such cases, use the method
   *  "allowInputFieldListening".
   * @type {boolean} [inputFieldListeningAllowed=false]
   */
  inputFieldListeningAllowed: boolean = false;

  /**
   * The last keypress event that was captured in the creation of the scan event. This
   * value is used for determining certain default ScanEventBus behaviors, such as
   * whether to react to scan events whose target is an inputtable HTML element.
   * @type {KeyboardEvent | null} [lastKeypressEvent=null]
   */
  lastKeypressEvent: KeyboardEvent | null = null;

  constructor(scannedValue: string) {
    this.scannedValue = scannedValue;
  }

  stopPropagation = (): void => {
    this.bubbles = false;
  };

  allowInputFieldListening = (): void => {
    this.inputFieldListeningAllowed = true;
  };
}
