import { useState, useCallback } from 'react';
import { orderBy } from 'natural-orderby';
import { useHttp } from '../../../common/Hooks';
import {
  SearchInventorySerials,
  SearchProductInventory,
  SearchProductInventoryOptions,
  UseSearchInventorySerials,
  UseSearchProductInventory
} from './InventorySearchTypes';
import { ProductSnapshot } from '../../../common/Types/DbSnapshots/ProductDbSnapshotTypes';
import { InventorySerialSnapshot } from '../../../common/Types/DbSnapshots/InventorySerialDbSnapshotTypes';

const DEFAULT_SEARCH_PRODUCT_INVENTORY_OPTIONS: SearchProductInventoryOptions = {
  searchType: 'model',
  includeInactive: false,
  startsWith: true
};

const DEFAULT_SEARCH_INVENTORY_SERIAL_OPTIONS: SearchProductInventoryOptions = {
  includeInactive: false
};

export const useSearchProductInventory = (): UseSearchProductInventory => {
  const [productInventoryResults, setProductInventoryResults] = useState<ProductSnapshot[]>([]);
  const [request, response] = useHttp(`ims`);

  const searchProductInventory = useCallback<SearchProductInventory>(
    async (searchTerm, options = DEFAULT_SEARCH_PRODUCT_INVENTORY_OPTIONS) => {
      const {
        searchType = DEFAULT_SEARCH_PRODUCT_INVENTORY_OPTIONS.searchType,
        includeInactive = DEFAULT_SEARCH_PRODUCT_INVENTORY_OPTIONS.includeInactive,
        startsWith = DEFAULT_SEARCH_PRODUCT_INVENTORY_OPTIONS.startsWith
      } = options;
      const params = new URLSearchParams(`${searchType}=${searchTerm}`);
      if (includeInactive) {
        params.set('includeInactive', '1');
      }
      if (startsWith) {
        params.set('startsWith', '1');
      }
      const results = await request.get(`/inventory/products/search?${params.toString()}`);
      if (response.ok) {
        const sortedResults: ProductSnapshot[] = orderBy(results, 'model', 'asc');
        setProductInventoryResults(sortedResults);
        return sortedResults;
      }
      return [];
    },
    []
  );

  return {
    productInventoryResults,
    searchProductInventory
  };
};

export const useSearchInventorySerials = (): UseSearchInventorySerials => {
  const [inventorySerialResults, setInventorySerialResults] = useState<InventorySerialSnapshot[]>(
    []
  );
  const [request, response] = useHttp(`ims`);

  const searchInventorySerials = useCallback<SearchInventorySerials>(
    async (searchTerm, options = DEFAULT_SEARCH_INVENTORY_SERIAL_OPTIONS) => {
      const { includeInactive = DEFAULT_SEARCH_INVENTORY_SERIAL_OPTIONS.includeInactive } = options;
      const params = new URLSearchParams(`serial=${searchTerm}`);
      if (includeInactive) {
        params.set('includeInactive', '1');
      }
      const results = await request.get(`/inventory-serials/search?${params.toString()}`);
      if (response.ok) {
        const sortedResults: Array<InventorySerialSnapshot> = orderBy(
          results,
          'serial_number',
          'asc'
        );
        setInventorySerialResults(sortedResults);
        return sortedResults;
      }
      return [];
    },
    []
  );

  return {
    inventorySerialResults,
    searchInventorySerials
  };
};
