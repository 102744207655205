import ScanEvent from './ScanEvent';

const NON_USER_INPUTTABLE_HTML_INPUT_TYPES: string[] = [
  'button',
  'checkbox',
  'color',
  'file',
  'hidden',
  'image',
  'radio',
  'range',
  'reset',
  'submit'
];

export function isUserInputtableHtmlElement(scanEvent: ScanEvent): boolean {
  if (
    scanEvent.lastKeypressEvent?.target instanceof HTMLTextAreaElement ||
    scanEvent.lastKeypressEvent?.target instanceof HTMLTableCellElement
  ) {
    return true;
  }
  if (scanEvent.lastKeypressEvent?.target instanceof HTMLInputElement) {
    const target: HTMLInputElement = scanEvent.lastKeypressEvent?.target;
    if (!NON_USER_INPUTTABLE_HTML_INPUT_TYPES.includes(target.type)) {
      return true;
    }
  }
  return false;
}
