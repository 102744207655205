const initialState = {
  assignableProducts: [],
  assignProductFulfilled: false,
  assignedInventory: '',
  assignedInventoryFulfilled: false,
  releaseInventoryFulfilled: false,
  workOrder: {},
  workorderSearched: [],
  workOrderItems: [],
  workOrderLaborLines: [],
  workOrderLineItem: {},
  workOrderLineItemFulfilled: false,
  fullWorkOrderFulfilled: '',
  convertToInvoiceFulfilled: '',
  workOrderIssuesCount: 0
};

export default function workOrder(state = initialState, action) {
  switch (action.type) {
    case 'WORKORDER_ITEM_RESET':
      return {
        ...state,
        assignableProducts: [],
        assignProductFulfilled: false,
        assignedInventory: '',
        assignedInventoryFulfilled: false,
        releaseInventoryFulfilled: false
      };
    case 'GET_FULL_WORKORDER_FULFILLED':
      return {
        ...state,
        workOrder: action.payload.data,
        workOrderItems: action.payload.data.work_order_items,
        workOrderLaborLines: action.payload.data.workorder_labor_lines,
        fullWorkOrderFulfilled: true
      };
    case 'GET_FULL_WORKORDER_REJECTED':
      return {
        ...state,
        workOrder: action.payload,
        fullWorkOrderFulfilled: false
      };
    case 'WORKORDER_UPDATE_FULFILLED':
      return {
        ...state,
        workOrder: action.payload,
        fullWorkOrderFulfilled: true
      };
    case 'WORKORDER_UPDATE_REJECTED':
      return {
        ...state,
        workOrder: action.payload,
        fullWorkOrderFulfilled: false
      };
    case 'GET_WORKORDER_ITEM_FULFILLED':
      return {
        ...state,
        workOrderLineItem: action.payload,
        workOrderLineItemFulfilled: true
      };
    case 'GET_WORKORDER_ITEM_REJECTED':
      return {
        ...state,
        workOrderLineItem: action.payload,
        workOrderLineItemFulfilled: false
      };
    case 'GET_ASSIGNED_PRODUCT_FULFILLED':
      return {
        ...state,
        assignableProducts: action.payload.data
      };
    case 'GET_ASSIGNED_PRODUCT_REJECTED':
      return {
        ...state,
        assignableProducts: action.payload
      };
    case 'ASSIGN_PRODUCT_FULFILLED':
      return {
        ...state,
        workOrderLineItem: action.payload.data.inventory,
        assignedInventory: action.payload.data.workorderItem,
        assignProductFulfilled: true
      };
    case 'ASSIGN_PRODUCT_REJECTED':
      return {
        ...state,
        // assignableProducts: action.payload,
        assignProductFulfilled: false
      };
    case 'GET_ASSIGNED_INVENTORY_FULFILLED':
      return {
        ...state,
        assignedInventory: action.payload.data,
        assignedInventoryFulfilled: true
      };
    case 'GET_ASSIGNED_INVENTORY_REJECTED':
      return {
        ...state,
        assignableProducts: action.payload
      };
    case 'RELEASE_INVENTORY_FULFILLED':
      return {
        ...state,
        workOrderLineItem: action.payload.data.inventory,
        releaseInventoryFulfilled: true
      };
    case 'RELEASE_INVENTORY_REJECTED':
      return {
        ...state,
        workOrderLineItem: action.payload
      };
    case 'WORKORDER_ITEM_UPDATE_FULFILLED':
      return {
        ...state,
        workOrderLineItem: action.payload
      };
    case 'WORKORDER_ITEM_UPDATE_REJECTED':
      return {
        ...state,
        workOrderLineItem: action.payload
      };
    case 'WORKORDER_ITEM_DELETE_FULFILLED':
      return {
        ...state,
        workOrderLineItem: action.payload
      };
    case 'WORKORDER_ITEM_DELETE_REJECTED':
      return {
        ...state,
        workOrderLineItem: action.payload
      };
    case 'SEARCH_WORKORDER_FULFILLED':
      return {
        ...state,
        workorderSearched: action.payload.data,
        workorderSearchedFulfilled: true
      };
    case 'SEARCH_WORKORDER_REJECTED':
      return {
        ...state,
        workorderSearched: action.payload,
        workorderSearchedFulfilled: false
      };
    case 'WORKORDER_INVENTORY_RESET':
      return {
        ...state,
        assignableProducts: []
      };

    case 'SEARCH_CONTACTS_FOR_WORKORDER_REJECTED':
      return {
        ...state
      };
    case 'SEARCH_CONTACTS_FOR_WORKORDER_FULFILLED':
      return {
        ...state,
        workorderSearched: action.payload.data
      };
    case 'CREATE_WORKORDER_LABOR_LINE_FULFILLED':
      // let newLaborItemLines = state.workOrderLaborLines
      // newLaborItemLines.push(action.payload)
      return {
        ...state,
        workOrderLaborLines: [...state.workOrderLaborLines, action.payload]
      };
    case 'UPDATE_WORKORDER_LABOR_LINE_FULFILLED': {
      // let updateActiveQuoteItems = new Array();
      const updatedLaborLines = [];
      for (let i = 0; i < state.workOrderLaborLines.length; i++) {
        if (state.workOrderLaborLines[i].id === action.payload.id) {
          updatedLaborLines.push(action.payload);
        } else {
          updatedLaborLines.push(state.workOrderLaborLines[i]);
        }
      }
      return {
        ...state,
        workOrderLaborLines: updatedLaborLines
      };
    }
    case 'DELETE_WORKORDER_LABOR_LINE_FULFILLED': {
      const newQuoteLaborLines = [];
      for (let i = 0; i < state.workOrderLaborLines.length; i++) {
        if (state.workOrderLaborLines[i].id !== action.payload) {
          newQuoteLaborLines.push(state.workOrderLaborLines[i]);
        }
      }
      return {
        ...state,
        workOrderLaborLines: newQuoteLaborLines
      };
    }
    case 'GET_COUNT_OF_WORKORDER_ISSUES_FULFILLED':
      return {
        ...state,
        workOrderIssuesCount: action.payload.data
      };
    case 'STORE_RESET':
      return {
        ...state,
        assignableProducts: [],
        assignedInventory: '',
        assignedInventoryFulfilled: false,
        releaseInventoryFulfilled: '',
        workOrder: '',
        workOrderItems: [],
        workOrderLineItem: '',
        workOrderLineItemFulfilled: false,
        fullWorkOrderFulfilled: '',
        convertToInvoiceFulfilled: ''
      };
    case 'LOGOUT':
      return {
        ...state,
        assignableProducts: [],
        assignProductFulfilled: false,
        assignedInventory: '',
        assignedInventoryFulfilled: false,
        releaseInventoryFulfilled: false,
        // workOrder:{},
        // workorderSearched:[],
        // workOrderItems:[],
        // workOrderLineItem:{},
        // workOrderLineItemFulfilled: false,
        fullWorkOrderFulfilled: '',
        convertToInvoiceFulfilled: ''
      };
    default:
      return state;
  }
}
