import React, { useMemo } from 'react';
import { TableRow, TableCell, Link, Typography } from '@material-ui/core';
import lowerCase from 'lodash/lowerCase';
import TableView from '../../common/LayoutComponents/Table';
import Results from '../../common/LayoutComponents/ResultsView';

function SimpleCommittedInventoryTable({ committedTransactions, columns, title, idLink }) {
  const headers = useMemo(
    () => ['ID', ...columns.map(field => lowerCase(Array.isArray(field) ? field[0] : field))],
    [columns]
  );

  return (
    <Results id={idLink}>
      <div>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography component="p" display="block">
          Total number of transactions: {committedTransactions.length}
        </Typography>
        {committedTransactions.length > 0 ? (
          <TableView
            header={headers}
            tableProps={{
              size: 'small'
            }}
            tableHeaderCellProps={{
              style: {
                fontSize: '1.1em'
              }
            }}
          >
            {committedTransactions.map(row => (
              <TableRow key={row.ims_transaction_reference_id}>
                <TableCell key="ims_transaction_reference_id">
                  <Link
                    target="_blank"
                    href={`/inventory-transactions/${row.ims_transaction_reference_id}`}
                  >
                    {row.ims_transaction_reference_id}
                  </Link>
                </TableCell>
                {columns.map(field =>
                  Array.isArray(field) ? (
                    <TableCell key={field}>{field[1](row[field[0]])}</TableCell>
                  ) : (
                    <TableCell key={field}>{row[field]}</TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableView>
        ) : null}
      </div>
    </Results>
  );
}

export default SimpleCommittedInventoryTable;
