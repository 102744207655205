import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import { usePODialogStyles } from '../PurchaseOrderDetailsStyles';

import Scanner from '../../Scanner';
import ScanEventUnsubscriber from '../../../common/ScanEventListening/ScanEventUnsubscriber';
import { PurchaseOrderItem } from '../../../common/Types/ApiOsposDbModels/PurchaseOrderItemTypes';
import { PurchaseOrderData } from '../PurchaseOrderDetailsTypes';

type PurchaseOrderReceivingProps = {
  dialogIsOpen: boolean;
  poItemToReceive: PurchaseOrderItem;
  poData: PurchaseOrderData;
  onClose: () => void;
};

export default function PurchaseOrderReceiving(props: PurchaseOrderReceivingProps) {
  const { poItemToReceive, poData, dialogIsOpen, onClose } = props;

  const classes = usePODialogStyles();

  const handleClose = () => {
    onClose();
  };

  const amountForScan = poItemToReceive.qty_ordered - poItemToReceive.qty_received;

  return (
    <Dialog fullWidth maxWidth="lg" open={dialogIsOpen} onClose={handleClose}>
      <ScanEventUnsubscriber />
      <div className={classes.root}>
        <Button onClick={handleClose} style={{ float: 'right' }}>
          <Close />
        </Button>
        <Scanner
          // TODO: Either change the name, or refactor to use ims_transaction_reference_id
          commitmentId={poData.PO.id}
          commitmentType="receipt"
          handleClose={handleClose}
          numberInCommitment={amountForScan}
          product={poItemToReceive.product}
          referenceItemId={poItemToReceive.id}
          scanningLocation={poData.PO.ims_location_code}
        />
      </div>
    </Dialog>
  );
}
