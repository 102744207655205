import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import green from '@material-ui/core/colors/green';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { useHttp, useToggle } from '../../../common/Hooks';
import { AuthContext } from '../../../AuthContext';
import Can from '../../../roles/Can';

export const useStyles = makeStyles(theme => ({
  tabPaper: {
    margin: '20px auto 0 auto',
    maxWidth: '80vw'
  },
  scanSummaryTab: {
    margin: theme.spacing(2)
  },
  listFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  adjustment: {
    verticalAlign: 'middle',
    display: 'inline'
  },
  serialCard: {
    flex: '0 1 calc(25% - 10px)',
    margin: 5
  },
  serialHeader: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  doneIcon: {
    color: green[500],
    fontWeight: 'bold'
  },
  forwardIcon: {
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7)
  },
  hideOrShowContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  wrapIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  quantityDiff: {
    marginLeft: theme.spacing(0.7),
    fontSize: 14,
    fontWeight: 'bold'
  }
}));

function TabPanel({ children, tabIndex, index }) {
  return tabIndex === index && children;
}

function ScanSummary({ scannedItems, adjustmentItem, adjustmentStatus, discrepanciesStatus }) {
  const classes = useStyles();
  return (
    <div className={classes.scanSummaryTab}>
      <h2>
        Audit of {adjustmentItem.product.model} at {adjustmentItem.audit_bucket_map.bucket}
      </h2>
      <h3>Adjustment Status: {adjustmentStatus ? 'Submitted' : 'Not Submitted'}</h3>
      <h3>Discrepancies Status: {discrepanciesStatus ? 'Resolved' : 'Not Resolved'}</h3>
      <Link target="_blank" to={`/inventory/detail/model/${adjustmentItem.product.model}`}>
        {adjustmentItem.product.product_title}
      </Link>
      <h3>Tallied Quantity: {adjustmentItem.tallied_quantity || 0}</h3>
      <h3>Expected Quantity: {adjustmentItem.recorded_qty_at_start_of_tally}</h3>
      <h4>Serials Scanned:</h4>
      <List>
        {scannedItems.length > 0 &&
          scannedItems.reverse().map(item => (
            <ListItem style={{ float: 'left', width: '25%' }} key={item.serials}>
              {item.serials}
            </ListItem>
          ))}
      </List>
    </div>
  );
}

export function MissingAndDiscoveredSerialsList({
  serials,
  showDiff = false,
  defaultForEmptyActualValue = '(Unknown)'
}) {
  const classes = useStyles();
  const stringifyExpectedNode = useCallback(
    item =>
      !isEmpty(item.expected_location_code)
        ? `${item.expected_location_code} / ${item.expected_condition_code} / ${item.expected_disposition_code}`
        : `(Non-Existent)`,
    []
  );
  const stringifyActualNode = useCallback(
    item =>
      !isEmpty(item.actual_location_code)
        ? `${item.actual_location_code} / ${item.actual_condition_code} / ${item.actual_disposition_code}`
        : defaultForEmptyActualValue,
    [defaultForEmptyActualValue]
  );
  return (
    <div className={classes.scanSummaryTab}>
      <div className={classes.listFlex}>
        {serials.length &&
          serials.length > 0 &&
          serials.map(item => (
            <Card key={item.serial_number} className={classes.serialCard}>
              <CardContent>
                <Typography className={classes.serialHeader}>
                  {item.ims_inventory_serial_id ? (
                    <Link target="_blank" to={`/inventory-serials/${item.ims_inventory_serial_id}`}>
                      {item.serial_number}
                    </Link>
                  ) : (
                    item.serial_number
                  )}
                </Typography>
                {showDiff ? (
                  <Typography className={classes.wrapIcon}>
                    {stringifyExpectedNode(item)}{' '}
                    <ArrowForwardIcon className={classes.forwardIcon} /> {stringifyActualNode(item)}
                  </Typography>
                ) : (
                  <>
                    <Typography>Expected: {stringifyExpectedNode(item)}</Typography>
                    <Typography>Actual: {stringifyActualNode(item)}</Typography>
                  </>
                )}
                {!isEmpty(item.scanned_by) ? (
                  <Typography>Scanned By: {item.scanned_by}</Typography>
                ) : null}
              </CardContent>
            </Card>
          ))}
      </div>
    </div>
  );
}

export function HideOrShow({
  component: Component = 'div',
  children,
  show: initialShowValue = false,
  hideText = 'Hide',
  showText = 'Show',
  ...restOfProps
}) {
  const [show, toggleShow] = useToggle(initialShowValue);

  return (
    <Component {...restOfProps}>
      {show && children}
      <Button onClick={toggleShow}>{show ? hideText : showText}</Button>
    </Component>
  );
}

function SerialsMatchingExpected({ matchingSerials }) {
  const classes = useStyles();
  return (
    <div className={classes.scanSummaryTab}>
      <div className={classes.listFlex}>
        {isEmpty(matchingSerials)
          ? '0'
          : matchingSerials.map(item => (
              <Card key={item.serial_number} className={classes.serialCard}>
                <CardContent>
                  <DoneIcon className={classes.doneIcon} />
                  <Typography className={classes.serialHeader} component="span">
                    {item.ims_inventory_serial_id ? (
                      <Link
                        target="_blank"
                        to={`/inventory-serials/${item.ims_inventory_serial_id}`}
                      >
                        {item.serial_number}
                      </Link>
                    ) : (
                      item.serial_number
                    )}
                  </Typography>
                </CardContent>
              </Card>
            ))}
      </div>
    </div>
  );
}

function AreYouSure({ confirmed, backOut }) {
  return (
    <Alert
      severity="warning"
      action={
        <>
          <Button variant="outlined" onClick={confirmed}>
            Yes
          </Button>
          <Button color="inherit" onClick={backOut}>
            No
          </Button>
        </>
      }
    >
      <AlertTitle>Are you sure?</AlertTitle>
    </Alert>
  );
}

function PreviewAdjustments({ auditReview, adjustmentItem, closeResolutionBoard }) {
  const classes = useStyles();

  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [request, response] = useHttp('ims/audit-adjustments/');

  const { username } = useContext(AuthContext);

  async function actOnAuditAdjustment(action) {
    const imsTransactionRefId = adjustmentItem.ims_transaction_reference_id;
    await request.post(`${imsTransactionRefId}/${action}`, {
      submittedById: username,
      submitNotes: '',
      shouldPerformAsync: true
    });
    if (response.ok) {
      closeResolutionBoard();
    }
  }

  async function transactAuditAdjustment() {
    return actOnAuditAdjustment('transact');
  }

  async function resolveAuditAdjustment() {
    return actOnAuditAdjustment('resolve');
  }

  const matchingSerials = useMemo(() => {
    if (isEmpty(auditReview.scanned_serials)) {
      return [];
    }
    const missingKeyed = keyBy(auditReview.missing_serials, 'serial_number');
    const discoveredKeyed = keyBy(auditReview.discovered_serials, 'serial_number');
    return auditReview.scanned_serials.filter(
      serial =>
        !has(missingKeyed, serial.serial_number) && !has(discoveredKeyed, serial.serial_number)
    );
  }, [auditReview]);

  return (
    <div className={classes.scanSummaryTab}>
      <h2>Preview Adjustments for {adjustmentItem.product.model}</h2>
      <Typography component="span" className={classes.wrapIcon}>
        Adjust Quantity in {adjustmentItem.audit_bucket_map.bucket}:{' '}
        <Typography component="span" className={`${classes.wrapIcon} ${classes.quantityDiff}`}>
          {auditReview.expected_quantity}
          <ArrowForwardIcon className={classes.forwardIcon} />
          {auditReview.actual_tallied_quantity}
        </Typography>
      </Typography>
      <h3>Serials Matching Expectation ({matchingSerials.length}):</h3>
      {!isEmpty(matchingSerials) && (
        <HideOrShow show={false} className={classes.hideOrShowContainer}>
          <SerialsMatchingExpected matchingSerials={matchingSerials} />
        </HideOrShow>
      )}
      <h3>
        Discovered serials to be adjusted to LIVE inventory at{' '}
        {adjustmentItem.audit_bucket_map.bucket} ({auditReview.discovered_serials.length}):
      </h3>
      {!isEmpty(auditReview.discovered_serials) && (
        <HideOrShow show={false} className={classes.hideOrShowContainer}>
          <MissingAndDiscoveredSerialsList serials={auditReview.discovered_serials} showDiff />
        </HideOrShow>
      )}
      <h3>Missing serials to be removed ({auditReview.missing_serials.length}):</h3>
      {!isEmpty(auditReview.missing_serials) && (
        <HideOrShow show={false} className={classes.hideOrShowContainer}>
          <MissingAndDiscoveredSerialsList
            serials={auditReview.missing_serials}
            showDiff
            defaultForEmptyActualValue="(Removed)"
          />
        </HideOrShow>
      )}
      <HideOrShow
        show={false}
        className={classes.hideOrShowContainer}
        component="span"
        showText="..."
        hideText="<<"
      >
        <Can
          perform="audit-scan:submit"
          yes={() => (
            <Button
              variant="contained"
              onClick={transactAuditAdjustment}
              disabled={auditReview.is_submitted}
            >
              {auditReview.is_submitted
                ? `Adjustment Submitted`
                : `Submit Adjustment Only (Resolve Later)`}
            </Button>
          )}
          no={() => (
            <Button variant="contained" disabled>
              {auditReview.is_submitted
                ? `Adjustment Submitted`
                : `ADMIN ONLY - Submit Adjustment Only (Resolve Later)`}
            </Button>
          )}
        />
      </HideOrShow>
      <Can
        perform="audit-scan:submit"
        yes={() =>
          confirmSubmission ? (
            <AreYouSure
              confirmed={resolveAuditAdjustment}
              backOut={() => setConfirmSubmission(false)}
            />
          ) : (
            <Button
              variant="contained"
              onClick={() => setConfirmSubmission(true)}
              disabled={auditReview.is_resolved}
            >
              {auditReview.is_submitted
                ? `Resolve Discrepancies`
                : `Submit Adjustment and Resolve Discrepancies`}
            </Button>
          )
        }
        no={() => (
          <Button variant="contained" disabled>
            {auditReview.is_submitted
              ? `ADMIN ONLY - Resolve Discrepancies`
              : `ADMIN ONLY - Submit Adjustment and Resolve Discrepancies`}
          </Button>
        )}
      />
    </div>
  );
}

export default function ResolutionBoard({ adjustmentItem, closeResolutionBoard }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [scannedItems] = useState(
    adjustmentItem.serials
      ? adjustmentItem.serials.reverse().map(scan => ({
          serials: scan.scanned_serial_number
        }))
      : []
  );
  const [auditBatchId] = useState(adjustmentItem.ims_transaction_reference_id);
  const [request, response] = useHttp('ims/audit-adjustments');
  const [auditReview, setAuditReview] = useState({}); // maybe dont need the whole object

  const classes = useStyles();

  const handleTabChange = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const fetchInventoryAuditReview = useCallback(async auditBatchIdArg => {
    const auditReviewObj = await request.get(`/${auditBatchIdArg}/review`);
    if (response.ok) {
      setAuditReview(auditReviewObj);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(auditReview)) {
      fetchInventoryAuditReview(auditBatchId);
    }
    return () => {
      setAuditReview({});
    };
  }, [auditBatchId, fetchInventoryAuditReview]);

  let i = 0;
  return (
    <>
      <Paper className={classes.tabPaper}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          onChange={handleTabChange}
          centered
          aria-label="audit resolution dashboard tabs"
        >
          <Tab label="Scan Summary" />
          <Tab
            label={
              auditReview.missing_serials
                ? `Missing Serials (${auditReview.missing_serials.length})`
                : 'Missing Serials (0)'
            }
          />
          <Tab
            label={
              auditReview.discovered_serials
                ? `Discovered Serials (${auditReview.discovered_serials.length})`
                : 'Discovered Serials (0)'
            }
          />
          <Tab label="Preview Adjustments" />
        </Tabs>
      </Paper>
      <TabPanel tabIndex={tabIndex} index={i++}>
        <ScanSummary
          scannedItems={scannedItems}
          adjustmentItem={adjustmentItem}
          adjustmentStatus={auditReview.is_submitted}
          discrepanciesStatus={auditReview.is_resolved}
        />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={i++}>
        <MissingAndDiscoveredSerialsList serials={auditReview.missing_serials} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={i++}>
        <MissingAndDiscoveredSerialsList serials={auditReview.discovered_serials} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={i++}>
        {isEmpty(auditReview) ? (
          () => <div className={classes.scanSummaryTab}>Loading</div>
        ) : (
          <PreviewAdjustments
            auditReview={auditReview}
            adjustmentItem={adjustmentItem}
            closeResolutionBoard={closeResolutionBoard}
          />
        )}
      </TabPanel>
    </>
  );
}
