import { Grid } from '@material-ui/core';
import React from 'react';
import PickListTypeFilterPopper from './PickListTypeFilterPopper';

export type PrintedAtColumnHeaderProps = {};

export default function PrintedAtColumnHeader({ }: PrintedAtColumnHeaderProps) {

  return (
    <Grid
      container
      justify={'space-around'}
      alignItems={'center'}
    >
      Printed At
      <PickListTypeFilterPopper />
    </Grid>
  );
}