import { ListItem, ListItemText, List, createStyles, makeStyles } from '@material-ui/core';
import React from 'react'
import { GlueTheme } from '../../../GlueThemeType';
import { PickListBin } from './PickListTypes'

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    designatedBin: {
      fontWeight: 'bold',
    },
    listItem: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    listItemText: {
      margin: theme.spacing(0),
      padding: theme.spacing(0, 0, 0.5, 0),
    }
  })
);

export type BinListProps = {
  availableBins: PickListBin[];
  isPrintVersion?: boolean;
};

export default function BinList({
  availableBins,
  isPrintVersion
}: BinListProps) {
  const classes = useStyles();
  const sliceEnd = isPrintVersion ? 3 : 1;
  const primaryClass = isPrintVersion ? classes.designatedBin : '';
  const mappedBins = availableBins
    .slice(0, sliceEnd)
    .map((bin: PickListBin, i) => {
      const binName = !!bin.binName ? bin.binName : 'Received';
      const isPrimary = i < 1 && isPrintVersion;
      return (
        <ListItem key={bin.binId ?? i} className={classes.listItemText}>
          <ListItemText
            className={classes.listItemText}
            classes={{
              primary: isPrimary ? primaryClass : '',
            }}
            primary={binName}
          />
        </ListItem>
      )
    })

  return (
    <List dense={true}>
      {mappedBins}
    </List>
  )
}