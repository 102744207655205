import React, { useState, useMemo, useEffect } from 'react';
import { isEmpty, startCase } from 'lodash';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from '@material-ui/styles';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import InventorySearch from '../Search/InventorySearch';
import Results from '../../../common/LayoutComponents/ResultsView';
import TableView from '../../../common/LayoutComponents/Table';
import { useLocationCompare } from '../../../actions/locationActions';
import DistributorInventoryView from '../DistributorInventoryView';
import { useHttp, useToggle } from '../../../common/Hooks';
import SimpleCommittedInventoryTable from '../SimpleCommittedInventoryTable';
import { GlueCopyToClipboardIconButton } from '../../Presentational/GlueCopyToClipboardIconButton';

const COMMITTED_TRANSFERS_COLUMNS = [
  'from_bucket',
  'to_bucket',
  'committed_quantity',
  'pos_transfer_batch_ref_id',
  'pos_transfer_batch_name',
  'committed_at',
  'committed_by_id',
  ['scanning_required', required => (required ? 'Yes' : 'No')],
  'commit_notes'
];

const COMMITTED_SALES_COLUMNS = [
  ['source_bucket', sourceBucket => sourceBucket || 'NOT_SOURCED'],
  'committed_quantity',
  'committed_at',
  'committed_by_id',
  'sale_type_code',
  [
    'legacy_pos_invoice_id',
    invoiceId => (
      <MuiLink
        target="_blank"
        rel="noopener"
        href={`https://wpos.walts.com/pos/review_invoice.php?invoice=${invoiceId}`}
      >
        {invoiceId}
      </MuiLink>
    )
  ],
  ['scanning_required', required => (required ? 'Yes' : 'No')],
  'commit_notes'
];

// TODO: migrate inline styles to `makeStyles` hook
const useStyles = makeStyles(theme => ({
  panelHeading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular
  },
  serialDialogPaper: {
    minWidth: 300,
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerNavLink: {
    margin: theme.spacing(1, 1.5)
  },
  flexOperator: {
    fontSize: '2rem',
    alignSelf: 'center'
  },
  fullWidth: {
    minWidth: '90vw'
  }
}));

function DetailLink({ children, to, className = '', ...restOfProps }) {
  return (
    <Typography className={className} component="span">
      <MuiLink component={Link} to={to} {...restOfProps}>
        {children}
      </MuiLink>
    </Typography>
  );
}

function LineItem({ title, children }) {
  return (
    <li
      style={{
        margin: 6,
        listStyleType: 'none',
        textAlign: 'center'
      }}
    >
      <Typography style={{ fontWeight: 'bold' }}>{title}:</Typography>
      <Typography>{children}</Typography>
    </li>
  );
}

const useSelectedLocation = () => {
  const { search } = useLocation();

  const selectedLocation = useMemo(() => {
    if (search) {
      return search.replace('?from_bucket=', '');
    }
    return '';
  }, [search]);

  return selectedLocation;
};

const useInventoryDetails = () => {
  const [details, setDetails] = useState({});
  const [request, response] = useHttp('ims/inventory/products/search');
  const { type, searchValue } = useParams();

  useEffect(() => {
    async function fetchDetails() {
      const productDetails = await request.get(
        `?${type}=${searchValue}&exact=1&includeCommitted=1&includeInactive=1`
      );
      setDetails(response.ok ? productDetails[0] : {});
    }
    if (type && searchValue) {
      fetchDetails();
    }
    // eslint-disable-next-line
  }, [type, searchValue]);

  return [details];
};

export default function InventoryDetails() {
  const classes = useStyles();

  const primaryLocationCode = useSelector(state => state.location.location);
  const locationCompare = useLocationCompare();

  const [modal, setModal] = useState(false);
  const [detailView, setDetailView] = useState([]);
  const [hover, toggleHover] = useToggle(false);

  const selectedLocation = useSelectedLocation();
  const [details] = useInventoryDetails();

  const history = useHistory();

  const openModal = detailsArg => {
    setDetailView(detailsArg);
    setModal(true);
  };

  const totalInventoryCount = useMemo(() => {
    if (!details.inventory) return 0;
    return details.inventory.reduce((sum, { quantity }) => sum + quantity, 0);
  }, [details.inventory]);

  const primaryBucketInventoryCount = useMemo(() => {
    if (!details.inventory) return 0;
    return details.inventory
      .filter(bucket => locationCompare(bucket.location_code) && bucket.condition_code === 'NEW')
      .reduce((sum, { quantity }) => sum + quantity, 0);
  }, [details.inventory, locationCompare]);

  const committedOutboundTransfersCount = useMemo(() => {
    if (!details.committed_transfers) return 0;
    return details.committed_transfers
      .filter(b => locationCompare(b.from_location_code) && b.from_condition_code === 'NEW')
      .reduce((sum, { committed_quantity }) => sum + committed_quantity, 0);
  }, [details.committed_transfers, locationCompare]);

  const sourcedCommittedSalesCount = useMemo(() => {
    if (!details.committed_sales) return 0;
    return details.committed_sales
      .filter(b => locationCompare(b.source_location_code) && b.source_condition_code === 'NEW')
      .reduce((sum, { committed_quantity }) => sum + committed_quantity, 0);
  }, [details.committed_sales, locationCompare]);

  const unsourcedCommittedSalesCount = useMemo(() => {
    if (!details.committed_sales) return 0;
    return details.committed_sales
      .filter(({ source_location_code }) => source_location_code === null)
      .reduce((sum, { committed_quantity }) => sum + committed_quantity, 0);
  }, [details.committed_sales]);

  const primaryLocationCodeStartCase = startCase(primaryLocationCode.toLowerCase());

  return (
    <InventorySearch>
      {!isEmpty(details) ? (
        <>
          <Results style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ boxSizing: 'border-box', padding: 25 }}>
                <Link to={`/inventory/simulation/?model=${details.model}`}>
                  <img
                    style={{
                      maxHeight: 175,
                      maxWidth: 350,
                      display: 'block'
                    }}
                    src={details.image_url}
                    alt="Product Model"
                  />
                </Link>
                <div>
                  <DetailLink
                    className={classes.headerNavLink}
                    to={`/inventory/simulation/?model=${details.model}`}
                  >
                    Inventory Events Simulation
                  </DetailLink>
                  <DetailLink
                    className={classes.headerNavLink}
                    to={`/inventory/transfer/${details.model}`}
                  >
                    Transfer Tool
                  </DetailLink>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '80%'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <h4 style={{ fontSize: 11, margin: '24px 0 2px 0' }}>
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: 400,
                          marginBottom: 0
                        }}
                      >
                        <GlueCopyToClipboardIconButton parentTypographyVariant="h6">
                          {details.model}
                        </GlueCopyToClipboardIconButton>
                      </p>
                      <DetailLink to={`/inventory/simulation/?model=${details.model}`}>
                        {details.product_title}
                      </DetailLink>
                    </h4>
                    <ul
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        paddingLeft: 0,
                        marginTop: 4,
                        marginBottom: 4
                      }}
                    >
                      <LineItem title="Active">{details.active ? 'yes' : 'no'}</LineItem>
                      <LineItem title="Brand">{details.brand_name}</LineItem>
                      <LineItem title="Model">{details.model}</LineItem>
                      <LineItem title="MPN">{details.mpn}</LineItem>
                      <LineItem title="UPC">{details.upc}</LineItem>
                      <LineItem title="Serial Tracked">
                        {details.serial_number_tracked ? 'yes' : 'no'}
                      </LineItem>
                      <LineItem title="Physical Product">
                        {details.is_physical_product ? 'yes' : 'no'}
                      </LineItem>
                      <LineItem title="Master Carton Code">
                        {details.master_carton_code && details.master_carton_code.toString()}
                      </LineItem>
                      <LineItem title="Master Box QTY">{details.master_box_qty}</LineItem>
                      <LineItem title="Reg Cost">{details.cost}</LineItem>
                      <LineItem title="Adj Map">{details.master_listing_adj_map_price}</LineItem>
                      <LineItem title="Adj Cart">{details.master_listing_adj_cart_price}</LineItem>
                      <LineItem title="Commission">
                        {Math.trunc(details.master_listing_commission_rate * 100)}%
                      </LineItem>
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: hover ? 'space-around' : 'flex-end'
                  }}
                >
                  <InventorySnapshot
                    title="Total Qty"
                    inventoryCount={totalInventoryCount}
                    link={`/inventory/transfer/${details.model}`}
                    titleStyles={{ fontWeight: 'bold' }}
                    countStyles={{ fontWeight: 300 }}
                  />
                  {hover ? (
                    <div style={{ display: 'flex' }}>
                      <span className={classes.flexOperator}>:</span>
                      <InventorySnapshot
                        title={`${primaryLocationCodeStartCase} New`}
                        inventoryCount={primaryBucketInventoryCount}
                        link={`/inventory/transfer/${details.model}`}
                      />
                      <span className={classes.flexOperator}>-</span>
                      <InventorySnapshot
                        title={`Sourced via ${primaryLocationCodeStartCase}`}
                        inventoryCount={sourcedCommittedSalesCount}
                        link="#inventory-details-sourced-committed-sales"
                      />
                      <span className={classes.flexOperator}>-</span>
                      <InventorySnapshot
                        title="Unsourced Sales"
                        inventoryCount={unsourcedCommittedSalesCount}
                        link="#inventory-details-unsourced-committed-sales"
                      />
                      <span className={classes.flexOperator}>-</span>
                      <InventorySnapshot
                        title="Committed Transfers"
                        inventoryCount={committedOutboundTransfersCount}
                        link="#inventory-details-committed-transfers"
                      />
                      <span className={classes.flexOperator}>=</span>
                    </div>
                  ) : null}
                  <InventorySnapshot
                    title={`Net ${primaryLocationCodeStartCase}`}
                    handleClick={toggleHover}
                    expandable
                    inventoryCount={
                      primaryBucketInventoryCount -
                      sourcedCommittedSalesCount -
                      unsourcedCommittedSalesCount -
                      committedOutboundTransfersCount
                    }
                    countStyles={{
                      fontWeight: 300,
                      color:
                        primaryBucketInventoryCount -
                          sourcedCommittedSalesCount -
                          unsourcedCommittedSalesCount -
                          committedOutboundTransfersCount <
                        0
                          ? 'red'
                          : 'green'
                    }}
                    titleStyles={{ fontWeight: 'bold' }}
                  />
                </div>
              </div>
            </div>
          </Results>
          {details.inventory.length ? (
            <div style={{ margin: '40px 0' }}>
              {details.inventory.map(item =>
                item.quantity !== 0 ? (
                  <ExpansionPanel
                    key={`${item.model}_${item.bucket}`}
                    expanded={selectedLocation === item.bucket}
                    onChange={(_, isExpanded) => {
                      history.push(
                        isExpanded ? `?from_bucket=${item.bucket}` : history.location.pathname
                      );
                    }}
                  >
                    <ExpansionPanelSummary style={{ justifyContent: 'space-between' }}>
                      <Typography className={classes.panelHeading}>
                        {item.bucket}
                        <span style={{ marginLeft: 15 }}>{item.quantity}</span>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <ResultsTable
                        model={details.model}
                        inventoryList={item}
                        toggleFunction={openModal}
                        isExpanded={selectedLocation === item.bucket}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : null
              )}
            </div>
          ) : null}
          <DistributorInventoryView model={details.model} />
          <SimpleCommittedInventoryTable
            idLink="inventory-details-committed-transfers"
            title={`Committed Transfers (From ${primaryLocationCode} / NEW = ${committedOutboundTransfersCount})`}
            committedTransactions={details.committed_transfers}
            columns={COMMITTED_TRANSFERS_COLUMNS}
          />
          <SimpleCommittedInventoryTable
            idLink="inventory-details-sourced-committed-sales"
            title={`Sourced Committed Sales (${primaryLocationCode} / NEW = ${sourcedCommittedSalesCount})`}
            committedTransactions={details.committed_sales.filter(
              s => s.source_location_code != null
            )}
            columns={COMMITTED_SALES_COLUMNS}
          />
          <SimpleCommittedInventoryTable
            idLink="inventory-details-unsourced-committed-sales"
            title={`Unsourced Committed Sales (${unsourcedCommittedSalesCount})`}
            committedTransactions={details.committed_sales.filter(
              s => s.source_location_code == null
            )}
            columns={COMMITTED_SALES_COLUMNS}
          />
          <Dialog
            open={modal}
            onClose={() => setModal(false)}
            PaperProps={{
              className: `${classes.serialDialogPaper} ${
                detailView.length > 0 ? classes.fullWidth : ''
              }`
            }}
          >
            {detailView && detailView.length > 0 ? (
              <SerialsTable serialList={detailView} />
            ) : (
              <Typography>There are no serials to display.</Typography>
            )}
          </Dialog>
        </>
      ) : (
        <Typography>No Data Available.</Typography>
      )}
    </InventorySearch>
  );
}

const useSnapshotClasses = makeStyles(() => ({
  typographyRoot: {
    fontSize: '2.3rem'
  }
}));

function InventorySnapshot({
  inventoryCount,
  title,
  link = null,
  countStyles = {},
  titleStyles = {},
  handleClick,
  expandable
}) {
  const classes = useSnapshotClasses();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={handleClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        background: '#fcfcfc',
        border: '1px solid #f5f5f5',
        padding: 8,
        margin: 12,
        color: '#000',
        maxWidth: '12rem',
        cursor: expandable ? 'pointer' : 'intial'
      }}
    >
      <h2
        style={{
          margin: 0,
          fontWeight: 400,
          fontSize: '.9rem',
          textTransform: 'uppercase',
          ...titleStyles
        }}
      >
        {title}
      </h2>
      <p
        style={{
          fontSize: '2.3rem',
          lineHeight: 1,
          marginTop: 0,
          marginBottom: 0,
          fontWeight: 150,
          ...countStyles
        }}
      >
        {link ? (
          link.startsWith('#') ? (
            <MuiLink href={link}>{inventoryCount}</MuiLink>
          ) : (
            <DetailLink className={classes.typographyRoot} to={link}>
              {inventoryCount}
            </DetailLink>
          )
        ) : (
          inventoryCount
        )}
      </p>
    </div>
  );
}

function ResultsTable({ inventoryList, toggleFunction, model, isExpanded }) {
  const [serials, setSerials] = useState({});
  const [request, response] = useHttp('ims/inventory-serials/search-by-proxy');

  useEffect(() => {
    async function fetchSerials() {
      const serialList = await request.get(
        `?model=${model}&conditionCode=${inventoryList.condition_code}&locationCode=${inventoryList.location_code}`
      );
      if (response.ok) {
        setSerials(
          (isEmpty(serialList) ? [] : serialList).filter(
            serial => serial.disposition_code === 'LIVE'
          )
        );
      }
    }
    if (isExpanded) {
      fetchSerials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryList.condition_code, model, inventoryList.location_code, isExpanded]);

  return (
    <TableView
      header={['bucket', 'quantity', 'notes', 'last updated at', 'serials']}
      tableProps={{
        size: 'small'
      }}
      tableHeaderCellProps={{
        style: {
          fontSize: '1.1em'
        }
      }}
    >
      <TableRow>
        <TableCell>
          {inventoryList.bucket ||
            `${inventoryList.location_code} / ${inventoryList.condition_code}`}
        </TableCell>
        <TableCell>
          <DetailLink
            to={`/inventory/transfer/${model}?from_bucket=${inventoryList.bucket ||
              `${inventoryList.location_code}_${inventoryList.condition_code}`}`}
          >
            {inventoryList.quantity}
          </DetailLink>
        </TableCell>
        <TableCell>{inventoryList.notes}</TableCell>
        <TableCell>{inventoryList.last_updated_at}</TableCell>
        <TableCell>
          <Button onClick={() => toggleFunction(serials)}>View Details</Button>
        </TableCell>
      </TableRow>
    </TableView>
  );
}

function SerialsTable({ serialList }) {
  return (
    <div style={{ width: '90%' }}>
      <h3>Total number of serials: {serialList.length}</h3>
      <TableView
        header={[
          'ID',
          'Serial Number',
          'Location Code',
          'Condition Code',
          'Disposition Code',
          'PO Number',
          'Legacy PO Number',
          'LPN',
          'Notes',
          'Customer Comments'
        ]}
        tableProps={{
          size: 'small'
        }}
        tableHeaderCellProps={{
          style: {
            fontSize: '1.1em'
          }
        }}
      >
        {serialList.map(row => (
          <TableRow key={row.serial_number}>
            <TableCell>
              <DetailLink target="_blank" to={`/inventory-serials/${row.id}`}>
                {row.id}
              </DetailLink>
            </TableCell>
            <TableCell>{row.serial_number}</TableCell>
            <TableCell>{row.location_code}</TableCell>
            <TableCell>{row.condition_code}</TableCell>
            <TableCell>{row.disposition_code}</TableCell>
            <TableCell>{row.po_api_po_id}</TableCell>
            <TableCell>{row.legacy_po_api_po_id}</TableCell>
            <TableCell>{row.distributor_inventory_item_id}</TableCell>
            <TableCell>{row.notes}</TableCell>
            <TableCell>{row.customer_comments}</TableCell>
          </TableRow>
        ))}
      </TableView>
    </div>
  );
}
