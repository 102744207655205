import { CHANGE_LOCATION, STORAGE_LOCATION_INDEX, locations } from '../actions/locationActions';

const initialState = {
  location: locations[+localStorage.getItem(STORAGE_LOCATION_INDEX)] || locations[0]
};

export default function loading(state = initialState, { type, payload }) {
  switch (type) {
    case CHANGE_LOCATION:
      return {
        ...state,
        location: payload
      };
    default:
      return state;
  }
}
