import { useState, useEffect } from 'react';
import { isURL } from 'validator';

export const useValidUrl = url => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(isURL(url, { protocols: ['https'], require_protocol: true }));
  }, [url]);

  return isValid;
};
