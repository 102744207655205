import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import Card from '@material-ui/core/Paper';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHttp } from '../../../common/Hooks';
import InventorySerialTransaction from './InventorySerialTransaction';

const useTextTitleStyles = makeStyles(theme => ({
  lineItem: {
    margin: theme.spacing(1),
    listStyleType: 'none',
    textAlign: 'center',
    marginLeft: 0,
    flexGrow: 1
  },
  lineItemTitle: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold
  }
}));

function CommittedSaleDetails({ committedSaleSerial }) {
  const classes = useInventorySerialDetailStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.detailsContainer}>
        <div className={classes.cardHeader}>
          <Typography variant="h3" className={classes.cardTitleCommittedSerial}>
            <strong>
              Committed to{' '}
              <Link
                to={{
                  pathname: `https://wpos.walts.com/pos/review_invoice.php?invoice=${committedSaleSerial.sale.legacy_pos_invoice_id}`
                }}
                target="_blank"
              >
                invoice {committedSaleSerial.sale.legacy_pos_invoice_id}
              </Link>
            </strong>
          </Typography>
          <MuiLink
            className={classes.cardTitleCommittedSerial}
            component={Link}
            to={`/inventory-transactions/${committedSaleSerial.sale.ims_transaction_reference_id}`}
          >
            Committed Sale Details
          </MuiLink>
          <CardContent className={classes.content} component="ul">
            <TextAndTitle title="Scanned at">{committedSaleSerial.scanned_at}</TextAndTitle>
            <TextAndTitle title="Scanned UPC">{committedSaleSerial.scanned_upc}</TextAndTitle>
            <TextAndTitle title="Status">
              {committedSaleSerial.qc_complete
                ? 'QC Complete'
                : committedSaleSerial.packed
                ? 'Packed'
                : 'Not Packed'}
            </TextAndTitle>
          </CardContent>
        </div>
      </div>
    </Card>
  );
}

function TextAndTitle({ children, title }) {
  const classes = useTextTitleStyles();
  return (
    <Typography component="li" className={classes.lineItem}>
      <Typography component="h6" className={classes.lineItemTitle}>
        {title}:
      </Typography>
      {children}
    </Typography>
  );
}

const useInventorySerialDetailStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    padding: theme.spacing(3),
    minWidth: 300,
    marginBottom: theme.spacing(3)
  },
  detailsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  imageLink: {
    paddingRight: theme.spacing(5),
    height: 'min-content',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  imageStyles: {
    height: '100%',
    maxHeight: 175,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 500
    }
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  cardTitle: { ...theme.typography.h4 },
  cardSubtitle: { ...theme.typography.h5, fontWeight: theme.typography.fontWeightMedium },
  content: { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 0 },
  cardTitleCommittedSerial: { ...theme.typography.h5 }
}));

const useSerialData = () => {
  const { id } = useParams();
  const [request, response, loading] = useHttp(`ims/inventory-serials`);
  const [serialDetails, setSerialDetails] = useState({});
  const [serialSimulation, setSerialSimulation] = useState([]);

  useEffect(() => {
    async function fetchInventorySerialHistory() {
      const data = await request.get(`/${id}/history`);
      if (response.ok && data) {
        setSerialSimulation(data);
      }
    }

    async function fetchInventorySerials() {
      const data = await request.get(`/${id}`);
      if (response.ok && data) {
        setSerialDetails(data);
      }
    }

    fetchInventorySerials();
    fetchInventorySerialHistory();
  }, [id]);

  return [serialDetails, serialSimulation, loading];
};

export default function InventorySerialDetails() {
  const [serialDetails, serialSimulation, loading] = useSerialData();
  const classes = useInventorySerialDetailStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Card className={classes.card}>
        {!isEmpty(serialDetails) ? (
          <div className={classes.detailsContainer}>
            {matches && serialDetails.product.image_url ? (
              <Link
                className={classes.imageLink}
                to={`/inventory/detail/model/${serialDetails.product.model}`}
              >
                <img
                  alt={serialDetails.product.model}
                  className={classes.imageStyles}
                  src={serialDetails.product.image_url}
                />
              </Link>
            ) : null}
            <div className={classes.cardHeader}>
              <Typography variant="h3" className={classes.cardTitle}>
                {serialDetails.serial_number}
              </Typography>
              <MuiLink
                className={classes.cardSubtitle}
                component={Link}
                to={`/inventory/detail/model/${serialDetails.product.model}`}
              >
                {serialDetails.product.product_title}
              </MuiLink>

              <CardContent className={classes.content} component="ul">
                <TextAndTitle title="ID">{serialDetails.id}</TextAndTitle>
                <TextAndTitle title="Location">{serialDetails.location_code}</TextAndTitle>
                <TextAndTitle title="Condition">{serialDetails.condition_code}</TextAndTitle>
                <TextAndTitle title="Disposition">{serialDetails.disposition_code}</TextAndTitle>
                <TextAndTitle title="PO API PO ID">{serialDetails.po_api_po_id}</TextAndTitle>
                <TextAndTitle title="Legacy PO API PO ID">
                  {serialDetails.legacy_po_api_po_id}
                </TextAndTitle>
                <TextAndTitle title="Distributer Inventory Item ID">
                  {serialDetails.distributer_inventory_item_id}
                </TextAndTitle>
                <TextAndTitle title="Notes">{serialDetails.notes}</TextAndTitle>
                <TextAndTitle title="Customer Comments">
                  {serialDetails.customer_comments}
                </TextAndTitle>
              </CardContent>
            </div>
          </div>
        ) : loading ? (
          <div>Loading</div>
        ) : (
          <div>No Results To Display</div>
        )}
      </Card>
      {!isEmpty((serialDetails || {}).committed_sale_serials) ? (
        <CommittedSaleDetails committedSaleSerial={serialDetails.committed_sale_serials[0]} />
      ) : (
        () => (loading ? <Card className={classes.card}>Loading...</Card> : '')
      )}
      {orderBy(serialSimulation, o => moment(o.events_date), ['asc']).map(item => (
        <InventorySerialTransaction key={item.events_date} item={item} />
      ))}
    </>
  );
}
