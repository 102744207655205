import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import InventorySimulation from './InventorySimulationSerial';
import InventorySearch from '../Search/InventorySearch';
// import data from "./example-of-inventory-history-for-serials.json";
import { useHttp } from '../../../common/Hooks';

function InventorySimulationSerialContainer() {
  const { serial } = useParams();
  // How do I set this after the fact?
  // Maybe with a defualt value based on the length
  const [panels, setPanels] = useState({ 0: true, 1: false });
  const [data, setData] = useState({});
  const handleExpansion = panel => {
    setPanels(panelz => {
      return { ...panelz, [panel]: !panels[panel] };
    });
  };

  const [request, response] = useHttp('ims/inventory-serials');

  async function fetchInventoryHistory(serial) {
    const data = await request.get(`/${serial}/history`);
    if (response.ok) {
      setData(data);
    }
  }

  useEffect(() => {
    if (serial !== ':serial') {
      fetchInventoryHistory(serial);
    }
  }, [serial]);

  return (
    <InventorySearch>
      {!isEmpty(data) ? (
        <InventorySimulation
          handleExpansion={handleExpansion}
          panels={Object.entries(panels)}
          data={data.data}
        />
      ) : (
        <h3>No Data Available.</h3>
      )}
    </InventorySearch>
  );
}

export default InventorySimulationSerialContainer;
