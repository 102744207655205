import React, { useContext } from 'react';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { UserContext } from '../../roles/User';
import { check } from '../../roles/Can';

function Route(props) {
  const { title, path } = props;
  return (
    <Typography>
      <MuiLink component={Link} to={path}>
        {title}
      </MuiLink>
    </Typography>
  );
}

const useHomeStyles = makeStyles(theme => ({
  typography: {
    ...theme.typography.h4
  }
}));

export default function HomePage(props) {
  const { pages } = props;
  const user = useContext(UserContext);
  const classes = useHomeStyles();
  const productionRoutes = pages
    .filter(page => check(user.role, page.permission) && page.exact && !page.excludeFromHome)
    .map(route => <Route key={route.title} title={route.title} path={route.path} />);

  return (
    <>
      <Typography className={classes.typography} gutterBottom variant="h2">
        Welcome to wPOS 3
      </Typography>
      {productionRoutes}
    </>
  );
}
