import { isEmpty, startsWith } from 'lodash';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useHttp, useStatus } from '../../common/Hooks';
import {
  UsePickItem,
  UnpickedItem,
  AlreadyPickedItem,
  AvailableBin,
  PickItemRequest,
  UseFetchUnpickedItem,
  UseFetchAlreadyPickedItem
} from './PickItemTypes';

const makePickErrorMessage = (pickResult: any): string => {
  if (!pickResult) {
    return 'Unknown error - no response from IMS was received.';
  }
  if (pickResult.message && pickResult.message.includes('already been picked')) {
    if (startsWith(pickResult.message, 'Committed sale')) {
      return 'Item has already been picked, possibly via the old pick page in POS 1. No item was removed from any bin.';
    }
    return 'Item has already been picked.';
  }
  return pickResult.message || 'Unknown error occurred when picking.';
};

export function useFetchUnpickedItem(): UseFetchUnpickedItem {
  const [unpickedItem, setUnpickedItem] = useState<UnpickedItem | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [request, response] = useHttp('ims/picklist');

  const fetchUnpickedItem = useCallback(
    async (pickListItemId: number): Promise<UnpickedItem | null> => {
      setIsFetching(true);
      const data = await request.get(`/item/${pickListItemId}`);
      if (response.ok && !isEmpty(response.data)) {
        const unpickedItemFromResponse: UnpickedItem = {
          pickListItemId: data[0].pickListItemId,
          transactionReferenceId: data[0].transactionReferenceId,
          model: data[0].model,
          invoice: data[0].invoice,
          tid: data[0].tid,
          quantity: data[0].quantity,
          upc: data[0].upc,
          alternateUpc: data[0].alternateUpc,
          availableBins: data[0].availableBins,
          suggestedBin: data[0].suggestedBin,
          condition: data[0].condition,
          warehouse: data[0].warehouse,
          isFreight: data[0].isFreight,
          isPrime: data[0].isPrime,
          isPremium: data[0].isPremium,
          isExpress: data[0].isExpress,
          shipByDate: data[0].shipByDate,
          printedAt: data[0].printedAt,
          warning: data[0].warning,
          pickListId: data[0].pickListId
        };
        setUnpickedItem(unpickedItemFromResponse);
        setIsFetching(false);
        return unpickedItemFromResponse;
      }
      setIsFetching(false);
      return null;
    },
    []
  );
  return {
    unpickedItem,
    fetchUnpickedItem,
    isFetching
  };
}

export function useFetchAlreadyPickedItem(): UseFetchAlreadyPickedItem {
  const [alreadyPickedItem, setAlreadyPickedItem] = useState<AlreadyPickedItem | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [request, response] = useHttp('ims/picklist');

  const fetchAlreadyPickedItem = useCallback(
    async (pickListItemId: number): Promise<AlreadyPickedItem | null> => {
      setIsFetching(true);
      const data = await request.get(`/already-picked-item/${pickListItemId}`);
      if (response.ok && !isEmpty(response.data) && !isEmpty(data.picked_item)) {
        const alreadyPickedItemFromResponse: AlreadyPickedItem = {
          pickListItemId: data.picked_item.id,
          pickedListId: data.picked_item.ims_pick_list_id,
          imsTransactionReferenceId: data.picked_item.ims_transaction_reference_id,
          itemMemberRefId: data.picked_item.item_member_ref_id,
          itemGroupRefId: data.picked_item.item_group_ref_id,
          itemCondition: data.picked_item.item_condition,
          quantityRequested: data.picked_item.quantity_requested,
          itemWarehouse: data.picked_item.item_warehouse,
          isFreight: data.picked_item.is_freight,
          isPrime: data.picked_item.is_prime,
          isPremium: data.picked_item.is_premium,
          isExpress: data.picked_item.is_express,
          shipByDate: data.picked_item.ship_by_date,
          productUpc: data.picked_item.product_upc,
          productAltUpc: data.picked_item.product_alt_upc,
          pickedQuantity: data.picked_item.picked_quantity,
          pickedAt: data.picked_item.picked_at,
          pickedBy: data.picked_item.picked_by,
          productModel: data.picked_item.product.model
        };
        setAlreadyPickedItem(alreadyPickedItemFromResponse);
        setIsFetching(false);
        return alreadyPickedItemFromResponse;
      }
      setIsFetching(false);
      return null;
    },
    []
  );
  return {
    alreadyPickedItem,
    fetchAlreadyPickedItem,
    isFetching
  };
}

export function usePickItem(): UsePickItem {
  const [scannedBinId, setScannedBinId] = useState<number | null>(null);
  const [scannedBinName, setScannedBinName] = useState<string>('');
  const [scannedUpc, setScannedUpc] = useState<string>('');
  const [binApproved, setBinApproved] = useState<boolean>(false);
  const [upcApproved, setUpcApproved] = useState<boolean>(false);
  const [itemSuccessfullyPicked, setItemSuccessfullyPicked] = useState<boolean>(false);
  const [doNotRemoveFromAnyBin, setDoNotRemoveFromAnyBin] = useState<boolean>(false);
  const [binStatus, setBinStatus, binMessage, setBinMessage] = useStatus();
  const [upcStatus, setUpcStatus, upcMessage, setUpcMessage] = useStatus();
  const [pickErrorMessage, setPickErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pickedBy, setPickedBy] = useState<string | null>(null);

  const {
    unpickedItem,
    fetchUnpickedItem,
    isFetching: isFetchingUnpickedItem
  } = useFetchUnpickedItem();

  const {
    alreadyPickedItem,
    fetchAlreadyPickedItem,
    isFetching: isFetchingAlreadyPickedItem
  } = useFetchAlreadyPickedItem();

  const [request, response] = useHttp('ims/picklist');

  const upcOptions = useMemo<string[]>(() => {
    if (!unpickedItem) {
      return [];
    }
    if (unpickedItem && unpickedItem.alternateUpc) {
      return [unpickedItem.upc, unpickedItem.alternateUpc];
    }
    return [unpickedItem.upc];
  }, [unpickedItem]);

  useEffect(() => {
    if (unpickedItem && isEmpty(unpickedItem.availableBins)) {
      setDoNotRemoveFromAnyBin(true);
      setScannedBinName('');
      setScannedBinId(null);
      setBinApproved(true);
    }
  }, [unpickedItem]);

  const submitItemToPick = useCallback(async (): Promise<boolean> => {
    if (!unpickedItem) {
      return false;
    }
    if (!scannedUpc || !upcOptions.includes(scannedUpc)) {
      setUpcStatus(false);
      setUpcMessage('Cannot Submit - Invalid UPC');
      setUpcApproved(false);
      return false;
    }
    let scannedBinIdForRequest = scannedBinId;
    const expectingScannedBinButDoesntHaveIt = !scannedBinIdForRequest && !doNotRemoveFromAnyBin;
    if (expectingScannedBinButDoesntHaveIt) {
      if (!scannedBinName) {
        setBinStatus(false);
        setBinMessage('Cannot Submit - Bin Field Cannot Be Empty');
        setBinApproved(false);
        return false;
      }
      const matchingBin = (unpickedItem?.availableBins || []).find(
        (availableBin: AvailableBin) => availableBin.binName === scannedBinName
      );
      if (!matchingBin) {
        setBinStatus(false);
        setBinMessage('Cannot Submit - Invalid Bin');
        setBinApproved(false);
        return false;
      }
      scannedBinIdForRequest = matchingBin.binId;
    }
    setIsSubmitting(true);
    const submitItemRequest: PickItemRequest = { pickedBy };
    if (!doNotRemoveFromAnyBin) {
      submitItemRequest.actualPickedBinId = scannedBinIdForRequest;
    }
    const pickResult = await request.post(
      `/item/${unpickedItem.pickListItemId}/pick`,
      submitItemRequest
    );
    if (response.ok) {
      setIsSubmitting(false);
      setItemSuccessfullyPicked(true);
      return true;
    }
    setPickErrorMessage(makePickErrorMessage(pickResult));
    setIsSubmitting(false);
    setItemSuccessfullyPicked(false);
    return false;
  }, [
    unpickedItem,
    scannedBinId,
    pickedBy,
    doNotRemoveFromAnyBin,
    scannedUpc,
    scannedBinName,
    upcOptions
  ]);

  // useEffect for managing when the auto-submit happens:
  useEffect(() => {
    const autoSubmitItemToPick = async (): Promise<void> => {
      await submitItemToPick();
    };
    // Auto-submit if both the UPC and Bin are ready-to-go.
    // Otherwise, the user should click the submit button.
    const requiredValuesAreSet = upcApproved && binApproved;
    if (requiredValuesAreSet) {
      autoSubmitItemToPick();
    }
  }, [upcApproved, binApproved, submitItemToPick]);

  const processBinScan = useCallback(
    (binNameArg: string): boolean => {
      if (!binNameArg) {
        setBinApproved(false);
        setBinStatus(false);
        setBinMessage('Bin Field Cannot Be Empty');
        return false;
      }
      // Example barcode value: BINASSIGN=AZ02_R01-B01-S01
      const trimmedBin = binNameArg.split('_').pop();
      if (!trimmedBin) {
        setBinApproved(false);
        setBinStatus(false);
        setBinMessage('Invalid Bin Barcode');
        return false;
      }
      const matchingBin = (unpickedItem?.availableBins || []).find(
        (availableBin: AvailableBin) => availableBin.binName === trimmedBin
      );

      if (matchingBin) {
        setScannedBinName(trimmedBin);
        setScannedBinId(matchingBin.binId);
        setBinApproved(true);
        return true;
      }
      setScannedBinName('');
      setScannedBinId(null);
      setBinApproved(false);
      setBinStatus(false);
      setBinMessage('Scanned bin does not match one of the available bins');
      return false;
    },
    [unpickedItem]
  );

  const processUpcScan = useCallback(
    (upcArg: string): boolean => {
      if (!upcArg) {
        setUpcApproved(false);
        setScannedUpc('');
        setUpcStatus(false);
        setUpcMessage('UPC cannot be empty');
        return false;
      }
      if (!upcOptions.includes(upcArg)) {
        setUpcApproved(false);
        setScannedUpc('');
        setUpcStatus(false);
        setUpcMessage('Invalid UPC');
        return false;
      }
      setScannedUpc(upcArg);
      setUpcApproved(true);
      return true;
    },
    [upcOptions]
  );

  const handleCheckDoNotRemoveFromAnyBin = (): void => {
    if (isEmpty(unpickedItem?.availableBins)) {
      return;
    }
    setBinApproved(false);
    setDoNotRemoveFromAnyBin(!doNotRemoveFromAnyBin);
    setScannedBinName('');
    setScannedBinId(null);
  };

  return {
    unpickedItem,
    fetchUnpickedItem,
    alreadyPickedItem,
    fetchAlreadyPickedItem,
    submitItemToPick,
    isFetching: isFetchingUnpickedItem || isFetchingAlreadyPickedItem,
    isSubmitting,
    processBinScan,
    processUpcScan,
    scannedBinName,
    scannedUpc,
    upcOptions,
    binApproved,
    upcApproved,
    setScannedBinName,
    setScannedUpc,
    setPickedBy,
    doNotRemoveFromAnyBin,
    handleCheckDoNotRemoveFromAnyBin,
    binStatus,
    binMessage,
    upcStatus,
    upcMessage,
    itemSuccessfullyPicked,
    pickErrorMessage
  };
}
