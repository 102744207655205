import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    margin: theme.spacing(2, 0)
  },
  cardContent: { paddingTop: 0 },
  cardHead: { paddingBottom: 0 }
}));

export default function IndividualDailyReceived(props) {
  const { location, newObject } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer}>
      <CardHeader title={location} className={classes.cardHead} />
      <CardContent className={classes.cardContent}>
        <List>
          {Object.values(newObject).map(value => {
            return (
              <ListItem divider>
                <ListItemText>
                  <Typography>
                    Model: <strong>{value.model}</strong>
                  </Typography>
                  <Typography>Total Received: {value.total_qty_received}</Typography>
                  {/* TURN THESE TO LINKS TO PO REVIEW EVENTUALLY */}
                  <Typography>PO: {value.po_ref.join(', ')}</Typography>{' '}
                  <Typography>On Hand Quantity: {value.on_hand_qty}</Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}
