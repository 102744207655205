import { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from './User';
import rules from './rules';

const baseCheck = (role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const check = (role, action) => {
  return baseCheck(role, action, []);
};

const Can = props => {
  const user = useContext(UserContext);
  return baseCheck(props.role || user.role, props.perform, props.data) ? props.yes() : props.no();
};

export default Can;

Can.propTypes = {
  yes: PropTypes.func,
  no: PropTypes.func,
  role: PropTypes.string,
  perform: PropTypes.string,
  data: PropTypes.string
};

Can.defaultProps = {
  yes: () => null,
  no: () => null
};
