import React, { useContext } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import PickListLocationSelect from './PickListLocationSelect';
import PickListTable from './PickListTable';
import GlueTooltip from '../../Presentational/GlueTooltip';
import { PickListContext } from './PickListContext';
import { LIST_TYPE_ENUM } from './PickListTypes';
import { GlueTheme } from '../../../GlueThemeType';
import PrintSelectedPickItemsModal from './PrintPickList/PrintSelectedPickItemsModal';
import GlueButton from '../../Presentational/GlueButton';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    button: {
      margin: theme.spacing(4, 0)
    },
  })
);

export default function PickListLayout() {
  
  const { queryParams, loadingPickList, getPickList } = useContext(PickListContext);
  const isPrinted = queryParams.listType === LIST_TYPE_ENUM.PRINTED;
  const pageSubtitle = isPrinted
    ? 'Printed Pick List'
    : 'Unprinted Pick List';
  const printButtonTooltipMsg = isPrinted
    ? 'Printed items cannot be reprinted.'
    : 'Feature not avaiable yet. Coming soon!';

  const handleRefresh = () => {
    getPickList();
  }
  return (
    <Grid container>
      <Grid container spacing={3} justify={'space-between'} alignItems={'center'}>
        <Grid item xs={8}>
          <Typography variant="h5">{pageSubtitle}</Typography>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item>
              <PrintSelectedPickItemsModal />
            </Grid>
            <Grid item>
              <div>
                <GlueTooltip
                  title={'Pick List is not updated in real time. Click to refresh the pick list.'}>
                  <span>
                    <GlueButton
                      onClick={handleRefresh}
                      variant={'outlined'}
                      color='secondary'
                      disabled={loadingPickList}
                      endIcon='refresh'>
                      Refresh Pick List
                    </GlueButton>
                  </span>
                </GlueTooltip>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <PickListLocationSelect />
        </Grid>
        <Grid item xs={12} >
          <PickListTable />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={'center'}>
            <PrintSelectedPickItemsModal />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
