/* attaches .env.local to process.env so you can grab your local API Dev.
 * .env.local should be in the root directory, at the same level as src.
 */
import dotenv from 'dotenv';

dotenv.config({ path: '../../dotenv' });

let base_url = '';
let auth_url = '';
let client_id = '';
let client_secret = '';
/* Checks if running in dev, if so grabs from .env the DEV api route,
 * if not it checks the host URL to determine the correct base_url to use for
 * api, can easily add it more locations based on hostname
 */
base_url = process.env.REACT_APP_DEV_API;
auth_url = process.env.REACT_APP_DEV_URL;
client_id = process.env.REACT_APP_CLIENT_ID;
client_secret = process.env.REACT_APP_CLIENT_SECRET;
// if (process.env.NODE_ENV === 'staging') {
// base_url = 'https://test-api-ospos.walts.com/api';
// auth_url = 'https://test-api-ospos.walts.com';
// client_id = '2';
// client_secret = '1xm1q4eyMvEjUuv1xSeGBhR0tbn6xQwrVCdaTFPy';
// } else
if (window.location.hostname === 'nate-wpos3.walts.com') {
  base_url = 'https://nate-api-ospos.walts.com/api';
  auth_url = 'https://nate-api-ospos.walts.com';
  client_id = '2';
  client_secret = '1xm1q4eyMvEjUuv1xSeGBhR0tbn6xQwrVCdaTFPy';
} // else if (process.env.NODE_ENV === 'production') {
// base_url = 'https://api-ospos.walts.com/api';
// auth_url = 'https://api-ospos.walts.com';
// client_id = '4';
// client_secret = 'J0tOxMS2xNxzti2jUYcCFYa29IRknpcyAbhbdGTJ';
// }

export const apiOspos = {
  baseUrl: base_url,
  authUrl: auth_url,
  grant_type: 'password',
  client_id,
  client_secret
};

export const googleClient = {
  client_id: '1027404820453-28sfjuqov9ut6l28jt9jm2u0h8if5bkb.apps.googleusercontent.com'
};
