import isEmpty from 'lodash/isEmpty';

export const getHttpResponseErrorMessage = response => {
  const primaryMessage =
    isEmpty(response.data) || response.status !== 422
      ? 'Unknown error'
      : !isEmpty(response.data.message)
      ? response.data.message
      : JSON.stringify(response.data);
  if (primaryMessage === 'The given data was invalid.') {
    return `${primaryMessage} Details: ${JSON.stringify(response.data.errors)}`;
  }
  return primaryMessage;
};

export const getAxiosResponseErrorMessage = error => {
  return isEmpty(error.response)
    ? 'Unknown error'
    : !isEmpty(error.response.data) && !isEmpty(error.response.data.message)
    ? error.response.data.message
    : JSON.stringify(error.response.data);
};
