const initialState = {
  brands: [],
  brandsFulfilled: false,
  categories: [],
  categoriesFulfilled: false,
  productsSearched: [],
  productsSearchedFulfilled: false,
  productSelected: {},
  products: [],
  productsFulfilled: false,
  productCreated: false,
  productDeleted: false
};

export default function brands(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCT_DELETE_SUCCESSFUL':
      return {
        ...state,
        products: [...state.products.filter(x => x.id !== action.payload.id)],
        productDeleted: true
      };
    case 'PRODUCT_DELETE_READY':
      return {
        ...state,
        productDeleted: false
      };
    case 'PRODUCT_LIST_SUCCESSFUL':
      return {
        ...state,
        products: action.payload,
        productsFulfilled: true
      };
    case 'PRODUCT_LIST_REJECTED':
      return {
        ...state,
        productsFulfilled: false
      };
    case 'CATEGORY_CREATION_SUCCESSFUL':
      return {
        ...state,
        categories: [...state.categories, action.payload.category_name],
        categoriesFulfilled: true
      };
    case 'CATEGORY_CREATION_REJECTED':
      return {
        ...state,
        categoriesFulfilled: false
      };
    case 'MANUFACTURER_CREATION_SUCCESSFUL':
      return {
        ...state,
        categories: [...state.manufacturers, action.payload.manufacturer_name],
        manufacturersFulfilled: true
      };
    case 'MANUFACTURER_CREATION_REJECTED':
      return {
        ...state,
        manufacturerFulfilled: false
      };
    case 'BRAND_CREATION_SUCCESSFUL':
      return {
        ...state,
        brands: [...state.brands, action.payload.brand_name],
        brandsFulfilled: true
      };
    case 'BRAND_CREATION_REJECTED':
      return {
        ...state,
        brandsFulfilled: false
      };
    case 'PRODUCT_CREATION_SUCCESSFUL':
      return {
        ...state,
        productCreated: true
      };
    case 'PRODUCT_CREATION_REJECTED':
      return {
        ...state,
        productCreated: false
      };
    case 'BRAND_LIST_RETRIEVED':
      return {
        ...state,
        brands: action.payload,
        brandsFulfilled: true
      };
    case 'BRAND_LIST_REJECTED':
      return {
        ...state,
        brandsFulfilled: false
      };
    case 'CATEGORY_LIST_RETRIEVED':
      return {
        ...state,
        categories: action.payload,
        categoriesFulfilled: true
      };
    case 'CATEGORY_LIST_REJECTED':
      return {
        ...state,
        categoriesFulfilled: false
      };
    case 'PRODUCT_SEARCH_FULFILLED':
      return {
        ...state,
        productsSearched: action.payload.data,
        productsSearchedFulfilled: true
      };
    case 'PRODUCT_SEARCH_REJECTED':
      return {
        ...state,
        productsSearched: action.payload,
        productsSearchedFulfilled: false
      };
    case 'PRODUCT_DETAILS':
      return {
        ...state,
        productSelected: action.payload
      };
    case 'PRODUCT_STORE_RESET':
      return {
        ...state,
        brands: [],
        categories: [],
        products: [],
        brandsFulfilled: false,
        categoriesFulfilled: false,
        productsSearched: [],
        productsSearchedFulfilled: false,
        productSelected: {}
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
