export const styles = {
  justMarginBottom: {
    marginBottom: '1em'
  },
  justMarginTop: {
    marginTop: '1em'
  },
  selectAdornment: {
    marginRight: '3em'
  },
  selectAdornmentTrackingWider: {
    marginRight: '4em'
  },
  paper: {
    marginTop: '1em',
    marginBottom: '1em',
    padding: '2em'
  },
  locationSelect: {
    fontSize: '2em',
    fontFamily: 'Jost'
  },
  w100: {
    width: '100px'
  },
  w200: {
    width: '200px'
  },
  aliasText: {
    marginTop: '1em',
    maxWidth: '325px'
  },
  formControl: {
    width: '100px',
    marginTop: '1em'
  },
  infoIconLabel: {
    width: '200px',
    display: 'flex',
    alignItems: 'center'
  },
  infoIconLabelSpan: {
    marginRight: '0.5em'
  },
  tooltipText: {
    fontSize: '12px'
  },
  pickupHoursHeading: {
    marginTop: '2em',
    fontFamily: 'Jost',
    display: 'flex',
    alignItems: 'center'
  },
  ml5px: {
    marginLeft: '5px'
  },
  timeFormControl: {
    width: '100px',
    marginLeft: '1em'
  },
  submitButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3em'
  }
};
