import React, { useCallback } from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';
import InventoryTransactionDetails from './InventoryTransactionDetails';
import InventoryTransactionSearchWrapper from './InventoryTransactionSearchWrapper';
import InventoryTransactionList from './InventoryTransactionList';

export default function InventoryTransactionsContainer() {
  const match = useRouteMatch();
  const history = useHistory();

  const navigateToTransactionDetails = useCallback(
    (id, searchType) => {
      if (searchType === 'imsTransactionReferenceId') {
        history.push(`${match.url}/${id}`.replace('//', '/'));
      } else {
        history.push(`${match.url}/search?searchType=${searchType}&id=${id}`);
      }
    },
    [match.url, history]
  );

  return (
    <InventoryTransactionSearchWrapper onSubmitSearch={navigateToTransactionDetails}>
      <Switch>
        <Route path={`${match.url}/search`}>
          <InventoryTransactionList />
        </Route>
        <Route path={`${match.url}/:id`}>
          <InventoryTransactionDetails />
        </Route>
      </Switch>
    </InventoryTransactionSearchWrapper>
  );
}
