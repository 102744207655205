import { apiOspos } from '../config/config';

function get(endpoint, access_token) {
  return fetch(`${apiOspos.baseUrl}/${endpoint}`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

function destroy(endpoint, access_token) {
  return fetch(`${apiOspos.baseUrl}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

function put(endpoint, payload, access_token) {
  return fetch(`${apiOspos.baseUrl}/${endpoint}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

function post(endpoint, payload, access_token) {
  return fetch(`${apiOspos.baseUrl}/${endpoint}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export function storeProduct(access_token, product) {
  return dispatch => {
    post('product', product, access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'PRODUCT_CREATION_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'PRODUCT_CREATION_REJECTED',
            payload: data.data
          });
        }
      });
  };
}

export function deleteProduct(id, access_token) {
  return dispatch => {
    dispatch({ type: 'PRODUCT_DELETE_READY', payload: false });
    destroy(`product/${id}`, access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'PRODUCT_DELETE_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'PRODUCT_DELETE_REJECTED',
            payload: data.data
          });
        }
      })
      .catch(e => console.warn(e));
  };
}

export function getProducts(brand, category, searchTerm, modelTerm, upc, access_token) {
  return dispatch => {
    get(
      `product?category=${category}&brand=${brand}&description=${searchTerm}&modelTerm=${modelTerm}&upc=${upc}`,
      access_token
    )
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'PRODUCT_LIST_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'PRODUCT_LIST_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.info(e));
  };
}

export function updateProduct(product, access_token) {
  return () => {
    put(`product/${product.id}`, product, access_token).then(response =>
      console.info(response.json())
    );
  };
}

export function storeBrand(brand, access_token) {
  return dispatch => {
    post('brand', { brand_name: brand }, access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'BRAND_CREATION_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'BRAND_CREATION_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.warn(e));
  };
}

export function storeManufacturer(manufacturer, access_token) {
  return dispatch => {
    post('manufacturer', manufacturer, access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'MANUFACTURER_CREATION_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'MANUFACTURER_CREATION_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.warn(e));
  };
}

export function storeCategory(category, access_token) {
  return dispatch => {
    post('category', { category_name: category }, access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'CATEGORY_CREATION_SUCCESSFUL',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'CATEGORY_CREATION_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.warn(e));
  };
}

export function listProductBrands(access_token) {
  return dispatch => {
    get('brand/all', access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'BRAND_LIST_RETRIEVED',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'BRAND_LIST_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.info(e));
  };
}

export function listProductCategories(access_token) {
  return dispatch => {
    get('category/all', access_token)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          dispatch({
            type: 'CATEGORY_LIST_RETRIEVED',
            payload: data.data
          });
        } else {
          dispatch({
            type: 'CATEGORY_LIST_REJECTED',
            payload: data.error
          });
        }
      })
      .catch(e => console.info(e));
  };
}

export function productSearch(authorizationToken, searchKey, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/product/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchKey, searchValue })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'PRODUCT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'PRODUCT_SEARCH_FULFILLED', payload: responseData });
        }
      });
  };
}

export function showProduct(product_id, authorizationToken) {
  return async () => {
    return fetch(`${apiOspos.baseUrl}/product/${product_id}`, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        return data.data;
      });
  };
}

export function updateQty(products, updated_product) {
  return dispatch => {
    const newProducts = products.map((item, index) => {
      if (index !== updated_product.index) {
        return item;
      }
      return {
        ...item,
        ...updated_product.qty_committed
      };
    });
    dispatch({
      type: 'UPDATE_PURCHASE_ORDER_ITEM_QTY',
      payload: { ...newProducts }
    });
  };
}

export function storeReset() {
  return dispatch => {
    return dispatch({ type: 'PRODUCT_STORE_RESET', payload: '' });
  };
}

export function modelSearch(authorizationToken, searchKey, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/product/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchKey, searchValue })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'PRODUCT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'PRODUCT_SEARCH_FULFILLED', payload: responseData });
        }
      });
  };
}

export function manufacturerSearch(authorizationToken, searchKey, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/product/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchKey,
        searchValue
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'PRODUCT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'PRODUCT_SEARCH_FULFILLED', payload: responseData });
        }
      });
  };
}

export function categorySearch(authorizationToken, searchKey, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/product/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchKey,
        searchValue
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'PRODUCT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'PRODUCT_SEARCH_FULFILLED', payload: responseData });
        }
      });
  };
}
