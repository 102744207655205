import React, { useState, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/styles';
import { check } from '../../../roles/Can';
import { UserContext } from '../../../roles/User';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

export default function BucketBufferRow({
  bucketName,
  isLocalInventoryBucket: isLocalInventoryBucketProp,
  sendIsLocalInventoryBucket
}) {
  const [isLocalInventoryBucket, setIsLocalInventoryBucket] = useState(
    isLocalInventoryBucketProp === 1
  );
  const user = useContext(UserContext);

  function handleIsLocalInventoryBucketChange(event) {
    setIsLocalInventoryBucket(event.target.checked);
    sendIsLocalInventoryBucket(bucketName, event.target.checked);
  }

  // TODO: This layout isn't super aria friendly.
  // It would be worth while to explore other options in the future.
  return (
    <StyledTableRow>
      <TableCell>{bucketName}</TableCell>
      <TableCell>
        <Checkbox
          color="primary"
          size="small"
          checked={isLocalInventoryBucket}
          disabled={!check(user.role, 'bucket-buffer:edit')}
          onChange={handleIsLocalInventoryBucketChange}
        />
      </TableCell>
    </StyledTableRow>
  );
}
