import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, List, ListItem } from '@material-ui/core';
import { useAxios, useToggle } from '../../common/Hooks';
import ShippingReconciliationDashboard from './ShippingReconciliationDashboard';
// import mockJson from './mocks/mock-sm.json';
import mockJson from './mocks/mock-lg.json';
import Can from '../../roles/Can';
import { getAxiosResponseErrorMessage } from '../../helpers/getErrors';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 400
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto'
  },
  fileUploadInput: {
    display: 'none'
  },
  infoText: {
    margin: 5
  },
  errorText: {
    color: 'red',
    margin: 5
  },
  list: {
    fontSize: 18,
    paddingTop: 0
  },
  listHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    padding: 0
  },
  listItems: {
    marginLeft: 24
  },
  sublistHeader: {
    fontSize: 20,
    textDecoration: 'underline'
  }
}));

function GenericShippingReconciliationUploader({ carrier, title }) {
  const history = useHistory();
  const baseUrl = `/shipping-reconciliation/${carrier.toLowerCase()}`;
  const [filenameOfGeneratedReport, setFilenameOfGeneratedReport] = useState('');
  const [uploadFileForm, setUploadFileForm] = useState(null);
  const [uploadFilename, setUploadFilename] = useState('');
  const [fileSubmitError, setFileSubmitError] = useState(null);
  const [shippingTransactions, setShippingTransactions] = useState(null);
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [axios] = useAxios(baseUrl);
  const [disableDownloadReportSubmitButton, setDisableDownloadReportSubmitButton] = useState(true);
  const [shouldUseMockData, toggleShouldUseMockData] = useToggle(false);

  const classes = useStyles();

  const handleChange = event => {
    if (isEmpty(event.target.files) || isEmpty(event.target.files[0].name)) {
      setUploadFileForm(null);
      setUploadFilename('');
    } else {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      setUploadFileForm(formData);
      setUploadFilename(event.target.files[0].name);
    }
  };

  useEffect(() => {
    async function handleUploadFile() {
      try {
        const response = await axios.post('/upload', uploadFileForm);
        const json = response.data;
        setIsSubmittingFile(false);
        setFilenameOfGeneratedReport(json.filename);
        setDisableDownloadReportSubmitButton(false);
      } catch (error) {
        setIsSubmittingFile(false);
        setUploadFileForm(null);
        setFileSubmitError(getAxiosResponseErrorMessage(error));
      }
    }
    if (uploadFileForm instanceof FormData && uploadFileForm.has('file')) {
      setIsSubmittingFile(true);
      setFileSubmitError(null);
      handleUploadFile();
    }
  }, [uploadFileForm, axios]);

  const handleSubmit = useCallback(() => {
    async function generateShippingReconciliationReport() {
      try {
        const response = await axios.get(`/generate?filename=${filenameOfGeneratedReport}`);
        setShippingTransactions(response.data);
        setIsSubmittingFile(false);
      } catch (error) {
        setIsSubmittingFile(false);
        setFileSubmitError(getAxiosResponseErrorMessage(error));
      }
    }
    if (filenameOfGeneratedReport) {
      setIsSubmittingFile(true);
      setFileSubmitError(null);
      generateShippingReconciliationReport();
    }
  }, [filenameOfGeneratedReport, axios]);

  return (
    <>
      <Can
        perform="role-editor:view"
        yes={() => (
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldUseMockData}
                color="primary"
                onClick={toggleShouldUseMockData}
              />
            }
            label="Use Mock Data"
          />
        )}
      />
      {shouldUseMockData ? (
        <ShippingReconciliationDashboard shippingTransactions={mockJson} />
      ) : !isEmpty(shippingTransactions) ? (
        <ShippingReconciliationDashboard shippingTransactions={shippingTransactions} />
      ) : (
        <Grid container spacing={10}>
          <Grid item xs={4}>
            <Card component="form" className={classes.paper}>
              <CardContent>
                <Typography component="h2">{title}</Typography>
                <input
                  type="file"
                  onChange={handleChange}
                  name="file"
                  id="upload-file"
                  className={classes.fileUploadInput}
                />
                <label htmlFor="upload-file">
                  <Button color="primary" component="span" className={classes.submitButton}>
                    Upload CSV
                  </Button>
                </label>
                <Typography>{uploadFilename}</Typography>
                {isSubmittingFile && (
                  <Typography className={classes.infoText}>Please wait...</Typography>
                )}
                {!isEmpty(fileSubmitError) && (
                  <Typography className={classes.errorText}>{fileSubmitError}</Typography>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={history.goBack}
                  className={classes.submitButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  disabled={disableDownloadReportSubmitButton}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <List className={classes.list}>
              <ListItem className={classes.listHeader}>
                Steps to use CEVA Reconciliation Tool:
              </ListItem>
              <ListItem>Login to CEVA ONEView</ListItem>
              <ListItem>Click on 'Reports' tab</ListItem>
              <ListItem>Click 'Report'</ListItem>
              <ListItem className={classes.sublistHeader}>
                Complete the report fields as follows (Ensure you are in Basic Search):
              </ListItem>
              <ListItem className={classes.listItems}>Profile Name: Walt's TV</ListItem>
              <ListItem className={classes.listItems}>
                Available Report: Air & Ground Int. Key Event Detail Report
              </ListItem>
              <ListItem className={classes.listItems}>
                Report Name: Enter a sensible name for the report
              </ListItem>
              <ListItem className={classes.listItems}>Report Format: Excel</ListItem>
              <ListItem className={classes.listItems}>Show References: Check the box</ListItem>
              <ListItem className={classes.listItems}>Report Weight In: LB-Pounds</ListItem>
              <ListItem className={classes.listItems}>Filter By: House WayBill</ListItem>
              <ListItem className={classes.listItems}>Number: Leave blank</ListItem>
              <ListItem className={classes.listItems}>
                Ship Date: Select a Start Date and End Date
              </ListItem>
              <ListItem className={classes.listItems}>Origin Country: All</ListItem>
              <ListItem className={classes.listItems}>Destination Country: All</ListItem>
              <ListItem className={classes.listItems}>Delivery Status: Leave Blank</ListItem>
              <ListItem>Hit 'Run'</ListItem>
              <ListItem>Wait for Report to Complete</ListItem>
              <ListItem>Open up the excel report and export to a CSV</ListItem>
              <ListItem>Then click UPLOAD CSV and hit SUBMIT</ListItem>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default GenericShippingReconciliationUploader;
