const initialState = {
  shipTransactionsFetched: false,
  shippingTransactions: [],
  filtered: false,
  filters: '',
  filterBy: ''
};

export default function shippingTransactions(state = initialState, action) {
  switch (action.type) {
    case 'SHIP_TRANSACTIONS_FULFILLED':
      return {
        ...state,
        shipTransactionsFetched: true,
        shippingTransactions: action.payload.items,
        filters: action.payload.filters
      };
    case 'SHIP_TRANSACTIONS_REJECTED':
      return {
        ...state,
        shipTransactionsFetched: false,
        shippingTransactions: action.payload
      };
    case 'FILTERED_SHIP_TRANSACTIONS_FULFILLED':
      return {
        ...state,
        shipTransactionsFetched: true,
        filtered: true,
        shippingTransactions: action.payload.items,
        filterBy: action.payload.filterBy
      };
    case 'FILTERED_SHIP_TRANSACTIONS_REJECTED':
      return {
        ...state,
        shipTransactionsFetched: false,
        shippingTransactions: action.payload
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
