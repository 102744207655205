import React, { useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Typography from '@material-ui/core/Typography';
import {
  MenuItem,
  Select,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  makeStyles
} from '@material-ui/core';
import { ValueType } from 'react-select';
import { useHttp } from '../../../common/Hooks';
import { ApiOsposProduct } from '../../../common/Types/ApiOsposDbModels/ApiOsposProductTypes';
import {
  determinePOItemCost,
  formatPOProductSearchResults,
  getOptionValue,
  POProductOption,
  ProductSearchForPOProps
} from './ProductSearchForPOHelpers';

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    marginTop: '1rem'
  },
  asyncSelect: {
    fontFamily: 'sans-serif',
    marginBottom: '1rem'
  },
  margin: {
    marginRight: '2rem'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem'
  },
  gridRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionsWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default function ProductSearchForPO(props: ProductSearchForPOProps) {
  const { addProductToPO } = props;

  const classes = useStyles();
  const [request, response] = useHttp('');

  const [price, setPrice] = useState<number>(0);
  const [qty, setQty] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<ValueType<POProductOption>>(null);
  const [productOptions, setProductOptions] = useState<ApiOsposProduct[]>([]);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string | null>(null);

  const matchingProduct = useMemo<ApiOsposProduct | null | undefined>(
    () =>
      selectedOption && productOptions.length
        ? productOptions.find(product => product.model_number === getOptionValue(selectedOption))
        : null,
    [selectedOption, productOptions]
  );

  useEffect(() => {
    if (matchingProduct) {
      setPrice(Number(matchingProduct.invoice_cost || 0));
      setQty(1);
    }
  }, [matchingProduct]);

  const searchProducts = (input: string, callback: (productResults: POProductOption[]) => void) => {
    request
      .post('product/search', {
        searchValue: input,
        searchKey: 'model_number'
      })
      .then((searchResponse: any) => searchResponse.data)
      .then((productSearchResults: ApiOsposProduct[]) => {
        setProductOptions(productSearchResults);
        const mappedResults: POProductOption[] = response.ok
          ? formatPOProductSearchResults(productSearchResults)
          : [];
        callback(mappedResults);
      });
  };

  const handleReset = () => {
    setQty(1);
    setPrice(0);
    setDiscountAmount(0);
    setDiscountType(null);
    setSelectedOption(null);
  };

  const handleChange = (option: ValueType<POProductOption> | null) => {
    setSelectedOption(option);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">Add Product</Typography>
      <AsyncSelect
        className={classes.asyncSelect}
        value={selectedOption}
        loadOptions={searchProducts}
        onChange={handleChange}
      />
      <Grid container justify="space-between" alignItems="center" spacing={4} component="div">
        <Grid item xs={6}>
          <TextField
            name="price"
            type="number"
            inputProps={{ min: '0', step: '.01' }}
            fullWidth
            label="Item Cost"
            value={price}
            onChange={(event: any) => setPrice(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="qty"
            type="number"
            inputProps={{ min: '1', step: '1' }}
            fullWidth
            label="Quantity to Order"
            value={qty}
            onChange={(event: any) => setQty(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(event: any) => setDiscountAmount(event.target.value)}
            type="number"
            inputProps={{ min: '0', step: '.01' }}
            name="discountAmount"
            fullWidth
            label="Discount Amount"
            value={discountAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Discount Type</InputLabel>
            <Select
              fullWidth
              value={discountType || ''}
              onChange={(event: any) => setDiscountType(event.target.value)}
            >
              <MenuItem value="percent">Percent Off</MenuItem>
              <MenuItem value="dollar">Dollar Off</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.actionsWrapper}>
        <Button onClick={() => handleReset()}>Clear Selected</Button>
        <Button
          variant="contained"
          onClick={() => {
            if (selectedOption && matchingProduct) {
              addProductToPO({
                product: { ...matchingProduct },
                qty: Number(qty),
                cost: determinePOItemCost(Number(price), discountAmount, discountType)
              });
              handleReset();
            }
          }}
        >
          Add Product
        </Button>
      </div>
    </div>
  );
}
