import React, { useContext } from 'react';
import RoleEditor from './RoleEditor';
import { useHttp } from '../common/Hooks';
import { UserContext } from '../roles/User';
import Can from '../roles/Can';

export default function RoleEditorContainer() {
  const user = useContext(UserContext);
  const roles = useHttp('role', {
    onMount: true
  });
  const users = useHttp('user', {
    onMount: true
  });

  const handleChange = (id, newRoleID) => {
    roles
      .put(`/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newRoleID })
      })
      .then(() => {
        user.update(true);
        users.get();
      });
  };
  if (roles.loading || users.loading) return 'Loading';
  return (
    <Can
      role={user.role || 'user'}
      perform="role-editor:view"
      yes={() => {
        return (
          <RoleEditor
            users={users.data}
            roles={roles.data.reduce((acc, item) => {
              acc[item.id] = item.role_name;
              return acc;
            }, {})}
            handleChange={handleChange}
          />
        );
      }}
      no={() => <p>You do not have access to this page.</p>}
    />
  );
}
