import React, { MouseEvent, useState } from 'react';
import { createStyles, IconButton, makeStyles, Paper, Popper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GlueTheme } from '../../../GlueThemeType';
import FilterPickListByTypeOptions from './FilterPickListByTypeOptions';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    filterIcon: {
      color: theme.palette.grey[600]
    },
    popper: {
      zIndex: 3,
    },
    paper: {
      padding: theme.spacing(3),
      width: '100%'
    },
  })
);

export interface PickListTypeFilterPopperProps {
}

export default function PickListTypeFilterPopper({ }: PickListTypeFilterPopperProps) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'open-filter-by-pick-list-type' : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        type="button"
        onClick={handleClick}>
        <FilterListIcon
          className={classes.filterIcon}
          fontSize={'small'} />
      </IconButton>
      <Popper
        className={classes.popper}
        id={id}
        placement={'bottom-end'}
        open={open}
        anchorEl={anchorEl}>
        <Paper className={classes.paper}>
          <FilterPickListByTypeOptions />
        </Paper>
      </Popper>
    </div>
  );
}
