import React from 'react';
import { Link } from 'react-router-dom';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import moment from 'moment';
import TableView from '../../common/LayoutComponents/Table';

export default function TransactedTransfers(props) {
  const { transfers, open, handleOpen, updateTransferNotes } = props;
  const styles = {
    padding: 20
  };

  return (
    <ExpansionPanel expanded={open}>
      <ExpansionPanelSummary onClick={handleOpen}>
        <h2>Recent Submitted Transactions</h2>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {transfers && transfers.length > 0 && (
          <TableView
            header={[
              'Reference ID',
              'Time',
              'Model',
              'From Bucket',
              'To Bucket',
              'Quantity',
              'Transferred By',
              'Comments'
            ]}
          >
            {transfers.map(transfer => (
              <tr key={transfer.ims_transaction_reference_id}>
                <td>
                  <MuiLink
                    component={Link}
                    to={`/inventory-transactions/${transfer.ims_transaction_reference_id}`}
                    target="_blank"
                  >
                    #{transfer.ims_transaction_reference_id}
                  </MuiLink>
                </td>
                <td style={styles}>
                  {moment(transfer.transferred_at).format('MMMM, DD, YYYY H:MM')}
                </td>
                <td style={styles}>{transfer.product.model}</td>
                <td style={styles}>{transfer.from_bucket}</td>
                <td style={styles}>{transfer.to_bucket}</td>
                <td style={styles}>{transfer.transferred_quantity}</td>
                <td style={styles}>{transfer.transferred_by_name}</td>
                <td style={styles}>
                  <form id={transfer.id} onSubmit={updateTransferNotes}>
                    <label>
                      <input type="hidden" name="transfer_id" value={transfer.id}></input>
                      <textarea name="transfer_notes" defaultValue={transfer.transfer_notes} />
                    </label>
                    <input type="submit" name="submitBtn" value="Save Notes" />
                  </form>
                </td>
              </tr>
            ))}
          </TableView>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
