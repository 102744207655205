import React, { useState, useContext, useEffect, useRef } from 'react';
import flatten from 'lodash/flatten';
import { Alert } from '@material-ui/lab';
import Scanner from './Scanner';
import ScanSetup from './ScanSetup';
import { AuthContext } from '../../AuthContext';
import { apiOspos } from '../../config/config';
import playSound from '../../common/playSound';
import { useHttp, useMessageDispatch } from '../../common/Hooks';
import { matchesUpc, matchesPartNumber } from '../../helpers/scanValidator';

export default function ScannerContainer(props) {
  const { commitmentId, commitmentType, numberInCommitment, referenceItemId } = props;
  const access = useContext(AuthContext);
  const [product, setProduct] = useState({});
  const [productFound, setProductFound] = useState(true);
  const [dispatchError] = useMessageDispatch();
  const [productRefresh, setProductRefresh] = useState(false);

  useEffect(() => {
    if (access) {
      fetch(`${apiOspos.baseUrl}/ims/products?model=${props.product.model_number}`, {
        headers: {
          Authorization: `Bearer ${access.auth}`
        }
      }).then(async response => {
        const data = await response.json();
        if (data.length === 0) setProductFound(false);
        setProduct(
          data.find(
            m => m.model.toLowerCase().trim() === props.product.model_number.toLowerCase().trim()
          )
        );
      });
    }
  }, [access, productRefresh]);

  const [status, setStatus] = useState(null);
  const [amountToScan, setAmountToScan] = useState(null);
  const [amountScanned, setAmountScanned] = useState(0);
  const [scannedItems, setScannedItems] = useState([]);
  const [serials, setSerials] = useState([]);
  const [upc, setUpc] = useState('');
  const [currentSerial, setCurrentSerial] = useState('');
  const handleUpc = e => setUpc(e.target.value);
  const handleCurrentSerial = e => setCurrentSerial(e.target.value);
  const [message, setMessage] = useState('');
  const [request] = useHttp(`scanner/${commitmentType}`);
  const currentStatus = useRef(null);
  const upcField = useRef(null);
  const toggleStatus = (status, message = '') => {
    setStatus(status);
    setMessage(message);
    if (status === true) {
      playSound('Good.mp3');
    } else if (status === false) {
      playSound('Bad.mp3');
    }
    if (!currentStatus.current) {
      const timeout = setTimeout(() => {
        setStatus(null);
        setMessage('');
        currentStatus.current = null;
        clearTimeout(timeout);
      }, 6000);
      currentStatus.current = timeout;
    }
  };

  async function validateUpc(fieldToFocus, cb = () => {}) {
    if (matchesUpc(upc.trim(), product)) {
      toggleStatus(true);
      setAmountToScan(
        product.serial_number_tracked === 'true'
          ? parseInt(product.master_box_qty, 10)
          : parseInt(product.num_of_serials_per_item, 10)
      );
      const castedSerialNumber = product.serial_number_tracked === 1;
      if (!castedSerialNumber) {
        addScannedItem();
      } else {
        cb();
      }
    } else {
      toggleStatus(false, "UPC doesn't match");
      setUpc('');
      fieldToFocus.current.focus();
    }
  }

  async function addSerial(fieldToFocus) {
    if (!currentSerial) {
      return;
    }
    const scannedSerials = flatten(scannedItems.map(item => item.serials));
    const trimmedSerial = currentSerial.trim();
    if (trimmedSerial === '') {
      toggleStatus(false, 'Serial cannot be blank');
    } else if (serials.includes(trimmedSerial) || scannedSerials.includes(trimmedSerial)) {
      toggleStatus(false, 'Serial cannot be re-scanned');
      setCurrentSerial('');
    } else if (matchesUpc(trimmedSerial, product)) {
      toggleStatus(false, "Serial can't be UPC");
      setCurrentSerial('');
    } else if (matchesPartNumber(trimmedSerial, product)) {
      toggleStatus(false, "Serial can't be part number");
      setCurrentSerial('');
    } else {
      setSerials(() => [...serials, trimmedSerial]);
      setAmountScanned(() => amountScanned + 1);
      setCurrentSerial('');
      if (amountScanned + 1 === amountToScan) {
        fieldToFocus.current.focus();
      }
    }
  }

  useEffect(() => {
    if (amountToScan !== 0 && amountScanned !== 0 && amountScanned === amountToScan) {
      addScannedItem();
    }
  }, [amountToScan, amountScanned]);

  function clearInputs() {
    setUpc('');
    setCurrentSerial('');
    setSerials([]);
    setAmountScanned(0);
    setAmountToScan(null);
  }

  async function addScannedItem() {
    if (scannedItems.length >= numberInCommitment) {
      return;
    }
    // Put a case statement here that pivots off committment type
    // That will then construct the POST request
    // and what endpoint I'll need to hit
    const scannedItem = await request.post('/quickScanReceive', {
      scannedUpc: upc,
      model: product.model,
      poApiPoId: commitmentId,
      poApiPoItemId: referenceItemId,
      receivingBucket: props.scanningLocation, // This needs to be set to the PO receiving location
      scannedSerialNumbers: serials,
      scannedBy: access.username
    });
    if (scannedItem.message === 'Success') {
      toggleStatus(true);
      setScannedItems(() => [
        ...scannedItems,
        { id: scannedItem.transactionReferenceId, upc, serials }
      ]);
      clearInputs();
      if (upcField.current) {
        upcField.current.focus();
      }
    } else {
      toggleStatus(false, scannedItem.errors);
      clearInputs();
    }
  }

  async function removeItem(scannedItemForRemoval) {
    const removedItem = await request.delete(`/scanned-item/${scannedItemForRemoval.id}`, {
      id: scannedItemForRemoval.id
    });
    if (removedItem.status === true) {
      setScannedItems([
        ...scannedItems.filter(scannedItem => scannedItem.id !== scannedItemForRemoval.id)
      ]);
    }
  }

  async function submitScan() {
    const scanApproved = await request.post('/scanned-item/submit', {
      scannedItems
    });
    if (scanApproved.status === true) {
      toggleStatus(true, 'Scan submitted');
      setTimeout(() => {
        props.handleClose();
      }, 2000);
    }
  }

  useEffect(() => {
    if (!productFound) {
      dispatchError({
        type: 'errors',
        message: `Product ${props.product.model_number} not found in wpos3 products list.`,
        status: 'Unknown Error'
      });
      props.handleClose();
    }
  }, [productFound]);

  const [scanSetup, setScanSetup] = useState(false);

  if (!productFound) return null;

  if (scanSetup) {
    return (
      <ScanSetup
        product={product}
        closeScanSetup={() => {
          setScanSetup(false);
          setProductRefresh(!productRefresh);
        }}
        refreshProduct={() => setProductRefresh(!productRefresh)}
      />
    );
  }

  return scannedItems.length < numberInCommitment ? (
    <Scanner
      addSerial={addSerial}
      amountScanned={amountScanned}
      amountToScan={amountToScan}
      commitmentId={commitmentId}
      commitmentType={commitmentType}
      currentSerial={currentSerial}
      handleCurrentSerial={handleCurrentSerial}
      handleUpc={handleUpc}
      message={message}
      model={product.model}
      numberInCommitment={numberInCommitment}
      removeItem={removeItem}
      scannedItems={scannedItems}
      serials={serials}
      setScanSetup={setScanSetup}
      status={status}
      submitScan={submitScan}
      upc={upc}
      validateUpc={validateUpc}
      forwardRef={upcField}
    />
  ) : (
    <Alert severity="success">All items have been received!</Alert>
  );
}
