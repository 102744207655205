import { db } from './firebase';

// User API

export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email
  });

export const onceGetUsers = () => db.ref('users').once('value');

export const doCreateReview = (productid, message, email, name, stars, date) =>
  db.ref(`reviews/${productid}`).push({
    message,
    name,
    email,
    stars,
    reviewOn: date,
    visible: false
  });

export const getReviews = (productid, cb = () => {}) =>
  db.ref(`reviews`).on('value', function(snapshot) {
    cb(snapshot);
  });

export const getReviewsForProduct = (productid, cb = () => {}) =>
  db.ref(`reviews/${productid}`).on('value', function(snapshot) {
    cb(snapshot);
  });

export const updateReview = (model, referenceId, visibleField) =>
  db.ref(`reviews/${model}/${referenceId}`).update({ visible: visibleField });

// firebase.database().ref().update(updates);
