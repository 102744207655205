import React, { useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ErrorBoundary from './ErrorBoundary.tsx';
import Contacts, {
  ContactsComponent as ContactsComponentNotConnectedToStore
} from './components/contacts/Contacts';
import ContactCreate, {
  ContactCreateComponent as ContactCreateComponentNotConnectedToStore
} from './components/contacts/ContactCreate';
import PurchaseOrderList from './components/PurchaseOrderList';
import PurchaseOrderDetails from './components/PurchaseOrderDetails';
import Reports from './components/ReportsPage';
import CommittedSalesBoard from './components/boards/CommittedSalesBoard';
import ProductReview from './components/products/ProductReviews';
import TaxMap, {
  TaxMapComponent as TaxMapComponentNotConnectedToStore
} from './components/tax/TaxMapper';
import ProductsList, {
  ProductsListComponent as ProductsListComponentNotConnectedToStore
} from './components/ProductList/ProductsList';
import CreateProduct, {
  CreateProductComponent as CreateProductComponentNotConnectedToStore
} from './components/products/components/CreateProduct';
import InventoryTransfer from './components/Inventory/Transfer';
import BinManagement from './components/BinManagement';
import PickItem from './components/PickItem';
import BinContents from './components/BinManagement/BinContents/BinContentsLayout';
import InventoryDetails from './components/Inventory/InventoryDetails';
import InventorySearch from './components/Inventory/Search/InventorySearch';
import InventorySimulation from './components/Inventory/Simulation';
import InventorySimulationSerial from './components/Inventory/SimulationSerial';
import SiteMap from './SiteMap';
import RoleEditor from './RoleEditor';
import InventoryTransactions from './components/InventoryTransactions';
import {
  BucketGroups,
  BucketMaps,
  BucketBuffers,
  BucketCreator,
  BucketUpdater
} from './components/buckets';
import InventoryAuditAdjustments from './components/Inventory/Adjustments';
import DiscrepancyReport from './components/FbaInventory/Discrepancy';
import ShippingTemplate from './components/shippingTemplate';
import Can from './roles/Can';
import { UserContext } from './roles/User';
import { CommittedTransfersDashboard } from './components/CommittedTransfers/CommittedTransfersDashboard';
import HomePage from './components/HomePage/HomePage';
import AccountingInventoryReport from './components/reports/AccountingInventoryReport';
import TransactedReceiptReport from './components/reports/TransactedReceiptReport';
import InventorySerialDetails from './components/InventorySerials/Search/InventorySerialDetails';
import {
  FulfillmentRedirect,
  OrderStatusRedirect,
  ReturnRedirect,
  ShippingTrackerRedirect,
  KY01Redirect,
  TX01Redirect,
  AZ02Redirect
} from './components/Redirect/redirect';
import GeOnHandExport from './components/reports/GeOnHandExport.tsx';
import InventoryAccountingReport from './components/reports/InventoryAccountingReport.tsx';
import SettlementReportUpload from './components/reports/SettlementReportUpload.tsx';
import EPHoldingsCsvUpload from './components/reports/EPHoldingsCsvUpload';
import ShippingReconciliationMenu from './components/ShippingReconciliation/ShippingReconciliationMenu';
import BrandSellThruReport from './components/reports/BrandSellThru/BrandSellThru.tsx';
import SelloutThreatReport from './components/reports/SelloutThreat/SelloutThreat';
import BrandPurchasingReport from './components/reports/BrandPurchasing/BrandPurchasingReport.tsx';
import AccountsPayableInvoices from './components/AccountsPayableInvoice/AccountsPayableInvoices.tsx';
import FinanceCompanyCreation from './components/FinanceCompany/FinanceCompanyCreation.tsx';
import UnReceivedInvoiceReport from './components/reports/UnReceivedInvoicesReport.tsx';
import InactiveInventoryReport from './components/reports/InactiveInventoryReport';
import AuditBatchesLandingPage from './components/AuditBatches/AuditBatchesLandingPage';
import BundleSalesReport from './components/reports/BundleSales';
import AuditBatchDashboard from './components/AuditBatches/AuditBatchDashboard';
import AuditDiscrepancyReport from './components/AuditBatches/AuditDiscrepancyReport';
import PeakDaysFulfillmentReport from './components/reports/PeakDaysFulfillmentReport';
import PickList from './components/boards/PickList';
import { CarouselIndex } from './components/CarouselWizard/CarouselIndex';
import { SupplySourcesIndex } from './components/SupplySources';
import FbaPendingOrdersInDollarsReport from './components/reports/FbaPendingOrdersInDollarsReport';

const NeedsImplementingPlaceholder = () => <p>This page will need to exist</p>;

const Route404 = () => (
  <h2 style={{ textAlign: 'center', padding: '15px' }}>404: Resource Not Found</h2>
);

function Page(props) {
  const { children, title } = props;

  useEffect(() => {
    document.title = title || 'wPOS 3.0a';
  }, [title]);

  return (
    <ErrorBoundary>
      {title ? <Typography variant="h4">{title}</Typography> : null}
      {children}
    </ErrorBoundary>
  );
}

/*
 * Regular Components
 * ==================
 * Not attached to Redux and use the Container pattern
 *
 * Not Implemented Components
 * ==========================
 * Work in progress and use basic, resuable dummy components
 *
 * Legacy Components
 * =================
 * These components use Redux, which we're moving away from
 * toward the Container Pattern
 * They need the extra _NotConnectedToStoreForTesting_ object entry
 * for **TESTING**. They'll continue to work just fine with just the
 * Component object entry we all know and love.
 *
 */

export const wpos3Routes = [
  /*
   * In navigation
   */
  {
    path: '/work-orders/create',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    submenu: 'Create',
    title: 'Create Work Order'
  },
  {
    path: '/work-orders',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    submenu: 'Search',
    title: 'Work Orders'
  },
  {
    path: '/products',
    Component: ProductsList,
    NotConnectedToStoreForTesting: ProductsListComponentNotConnectedToStore,
    exact: true,
    submenu: 'Search',
    title: 'Products',
    permission: 'dev-route:view'
  },
  {
    path: '/purchase-orders',
    Component: PurchaseOrderList,
    exact: true,
    submenu: 'Search',
    permission: 'purchase-order:view',
    title: 'Purchase Orders'
  },
  {
    path: '/contacts',
    NotConnectedToStoreForTesting: ContactsComponentNotConnectedToStore,
    Component: Contacts,
    exact: true,
    submenu: 'Search',
    title: 'Contacts'
  },
  {
    path: '/inventory/detail/:type/:searchValue',
    Component: InventoryDetails,
    exact: false,
    title: 'Inventory Details',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/search',
    Component: InventorySearch,
    exact: true,
    submenu: 'Search',
    title: 'Inventory',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/search/serial',
    Component: () => <Redirect to="/inventory/search?searchBy=serial" />,
    exact: true,
    submenu: 'Search',
    title: 'By Serial',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/transfer/:id',
    Component: InventoryTransfer,
    exact: true,
    submenu: 'Admin',
    title: 'Inventory Transfer',
    permission: 'inventory-transfer:view'
  },
  {
    path: '/inventory/bins',
    Component: BinManagement,
    exact: true,
    submenu: 'Bin Management',
    title: 'Bin Dashboard',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/bins/assignment/:locationCode/:binName',
    Component: BinContents,
    exact: true,
    title: 'Bin Contents',
    permission: 'inventory:view'
  },
  {
    path: '/pick-item/:pickListItemId',
    Component: PickItem,
    exact: true,
    title: 'Pick Item',
    permission: 'inventory:view',
    excludeFromHome: true
  },
  {
    path: '/inventory/simulation/serial/:serial',
    Component: InventorySimulationSerial,
    exact: false,
    title: 'Inventory Serial Simulation',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/simulation',
    Component: InventorySimulation,
    exact: false,
    title: 'Inventory Simulation',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/audit-scans',
    Component: AuditBatchesLandingPage,
    exact: true,
    title: 'Audit Scan Dashboard (Beta)',
    submenu: 'Audit',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/audit-scans/:auditBatchId',
    Component: AuditBatchDashboard,
    exact: false,
    title: 'Audit Scan Tool (Beta)',
    permission: 'inventory:view'
  },
  {
    path: '/inventory/audit-discrepancy-report/:auditBatchId',
    Component: AuditDiscrepancyReport,
    exact: false,
    title: 'Audit Discrepancy Report',
    permission: 'inventory:view'
  },
  {
    path: '/boards/pick',
    Component: PickList,
    exact: true,
    submenu: 'Status Boards',
    title: 'Awaiting Picking',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/fulfillment',
    Component: FulfillmentRedirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'Fulfillment',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/ky01',
    Component: KY01Redirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'KY01',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/tx01',
    Component: TX01Redirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'TX01',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/az02',
    Component: AZ02Redirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'AZ02',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/order-status',
    Component: OrderStatusRedirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'Order',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/returns',
    Component: ReturnRedirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'Returns',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/shipping-tracker',
    Component: ShippingTrackerRedirect,
    exact: true,
    submenu: 'Status Boards',
    title: 'Shipping Tracker',
    permission: 'boards:view',
    excludeFromHome: true
  },
  {
    path: '/boards/committed-sales',
    exact: true,
    Component: CommittedSalesBoard,
    submenu: 'Home',
    title: 'Committed Sales',
    permission: 'boards:view'
  },
  {
    path: '/committed-transfers',
    Component: CommittedTransfersDashboard,
    exact: true,
    submenu: 'Home',
    title: 'Committed Transfers',
    permission: 'boards:view'
  },
  {
    path: '/reports',
    Component: Reports,
    submenu: 'Admin',
    title: 'Reports',
    exact: true
  },
  {
    path: '/tools',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    submenu: 'Admin',
    title: 'Tools'
  },
  {
    path: '/accounting',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    submenu: 'Admin',
    title: 'Accounting'
  },
  {
    path: '/buckets/create',
    Component: BucketCreator,
    exact: true,
    submenu: 'Create',
    permission: 'bucket:create',
    title: 'Create New Bucket'
  },
  {
    path: '/reports/ge-on-hand-export',
    Component: GeOnHandExport,
    exact: true,
    permission: 'purchasing:reports',
    title: 'GE On Hand Export Report'
  },
  {
    path: '/reports/settlement-upload',
    Component: SettlementReportUpload,
    submenu: 'Accounting',
    exact: true,
    permission: 'purchasing:reports',
    title: 'Upload Settlement Report'
  },
  {
    path: '/reports/inventory-accounting',
    Component: InventoryAccountingReport,
    exact: true,
    permission: 'purchasing:reports',
    title: 'Inventory Accounting Report'
  },
  {
    path: '/ep-holdings/orders/upload',
    Component: EPHoldingsCsvUpload,
    exact: true,
    permission: 'purchasing:reports',
    title: 'EP Holdings Orders - CSV Upload'
  },
  /*
   * Not in navigation
   */
  {
    path: '/accounts-payable-invoices',
    Component: AccountsPayableInvoices,
    exact: true,
    title: 'Accounts Payable Invoices'
  },
  {
    path: '/finance-company/create',
    Component: FinanceCompanyCreation,
    exact: true,
    title: 'Create Finance Company',
    submenu: 'Accounting',
    permission: 'finance-company:create'
  },
  {
    path: '/products/walts-website-reviews',
    Component: ProductReview,
    exact: true,
    title: "Walt's Website Reviews"
  },
  {
    path: '/buckets/groups',
    Component: BucketGroups,
    exact: true,
    title: 'Bucket Groups',
    permission: 'bucket-buffer:view'
  },
  {
    path: '/buckets/maps',
    Component: BucketMaps,
    exact: true,
    title: 'Bucket Group Mappings',
    permission: 'bucket-buffer:view'
  },
  {
    path: '/buckets/buffers',
    Component: BucketBuffers,
    exact: true,
    title: 'Bucket Buffers',
    permission: 'bucket-buffer:view'
  },
  {
    path: '/buckets/update',
    Component: BucketUpdater,
    exact: true,
    title: 'Bucket Updater',
    permission: 'admin:override'
  },
  {
    path: '/fba-discrepancy-report',
    Component: DiscrepancyReport,
    exact: true,
    title: 'Discrepancy Report',
    permission: 'inventory:reports'
  },
  {
    path: '/reports/model-po-reconciliation',
    Component: AccountingInventoryReport,
    exact: true,
    title: 'Model PO Reconciliation',
    permission: 'purchase-order:view'
  },
  {
    path: '/inventory/admin-inventory-adjustments',
    Component: InventoryAuditAdjustments,
    submenu: 'Audit',
    exact: true,
    title: 'Admin-Level Inventory Adjustments',
    permission: 'inventory:admin-adjust'
  },
  {
    path: '/purchase-orders/review/:id',
    Component: PurchaseOrderDetails,
    exact: false,
    title: 'Purchase Order',
    permission: 'purchase-order:view'
  },
  {
    path: '/reports/sales',
    Component: NeedsImplementingPlaceholder,
    exact: false,
    title: 'Sales Reports'
  },
  {
    path: '/shipping-templates/create',
    Component: ShippingTemplate,
    submenu: 'Create',
    title: 'Create Shipping Templates',
    permission: 'bucket-buffer:view'
  },
  {
    path: '/shipping-templates',
    Component: ShippingTemplate,
    exact: true,
    title: 'Shipping Templates',
    permission: 'bucket-buffer:view'
  },
  {
    path: '/inventory-transactions',
    Component: InventoryTransactions,
    exact: false,
    submenu: 'Search',
    title: 'Inventory Transactions',
    permission: 'inventory:view'
  },
  {
    path: '/inventory-serials/:id',
    Component: InventorySerialDetails,
    exact: false,
    title: 'Inventory Serial Details',
    permission: 'inventory:view'
  },
  {
    path: '/work-orders/:id/:component',
    Component: NeedsImplementingPlaceholder,
    exact: false,
    title: 'Work Order'
  },
  {
    path: '/work-orders/apply-payment',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    title: 'Apply Payment'
  },
  {
    path: '/boards/fulfillment-tracker',
    Component: NeedsImplementingPlaceholder,
    exact: true,
    submenu: 'Boards',
    title: 'Fulfillment Board'
  },
  {
    path: '/role-editor',
    Component: RoleEditor,
    exact: true,
    title: 'Role Editor'
  },
  {
    path: '/contacts/create',
    Component: ContactCreate,
    NotConnectedToStoreForTesting: ContactCreateComponentNotConnectedToStore,
    exact: true,
    title: 'Create Contact'
  },
  {
    path: '/products/create',
    Component: CreateProduct,
    NotConnectedToStoreForTesting: CreateProductComponentNotConnectedToStore,
    exact: true,
    title: 'Create Product'
  },
  {
    path: '/tax-map',
    Component: TaxMap,
    NotConnectedToStoreForTesting: TaxMapComponentNotConnectedToStore,
    exact: true
  },
  {
    title: 'Amazon FBA vs WPOS FBA Inventory Differences (Possible Amazon Inventory Bucket Issues)',
    path:
      '/reports/amazon-fba-vs-wpos-fba-inventory-differences-possible-amazon-inventory-bucket-issues',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Available FBA Inventory Not at Amazon',
    path: '/reports/available-fba-inventory-not-at-amazon',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Available FBA Inventory Not at ONSITE',
    path: '/reports/available-fba-inventory-not-at-onsite',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Finished Invoice Report Prior Year Comparison',
    path: '/reports/finished-invoice-report-prior-year-comparison',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Inventory On Hand (Walts Only) Report',
    path: '/reports/inventory-on-hand-walts-only-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Marketplace AR Amazon Only',
    path: '/reports/marketplace-ar-amazon-only',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Standard AR (excludes, Claims, Marketplace)',
    path: '/reports/standard-ar-excludes-claims-marketplace',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Unpaid Claims AR (Pending Claims only)',
    path: '/reports/unpaid-claims-ar-pending-claims-only',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Walts GE Inventory',
    path: '/reports/walts-ge-inventory',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Walts Inventory On Hand By Brand ALL',
    path: '/reports/walts-inventory-on-hand-by-brand-all',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Walts Inventory On Hand By Brand AMAZON FBA',
    path: '/reports/walts-inventory-on-hand-by-brand-amazon-fba',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Written Invoice Report Prior Year Comparison',
    path: '/reports/written-invoice-report-prior-year-comparison',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Marketplace Sales Report',
    path: '/reports/marketplace-sales-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Accounting Inventory Report',
    path: '/reports/accounting-inventory-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Inventory On Hand Price Protection',
    path: '/reports/inventory-on-hand-price-protection',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Salesperson Report (Finished)',
    path: '/reports/salesperson-report-finished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Current Accounting Inventory Balance',
    path: '/reports/current-accounting-inventory-balance',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Finished Duplicate and Missing Partner Order Report',
    path: '/reports/finished-duplicate-and-missing-partner-order-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Marketplace AR (i.e. Jet, Rakuten .. Walmart',
    path: '/reports/marketplace-ar-ie-jet-rakuten--walmart',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Rebate Sell Thru',
    path: '/reports/rebate-sell-thru',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Tax Report Export',
    path: '/reports/tax-report-export',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Warranty Sales Report (Finished)',
    path: '/reports/warranty-sales-report-finished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Calls Report',
    path: '/reports/calls-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Daily Sales',
    path: '/reports/daily-sales',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Finished Tickets',
    path: '/reports/finished-tickets',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Inventory Report by Brand then model then bucket (Full Inventory Audit)',
    path: '/reports/inventory-report-by-brand-then-model-then-bucket-full-inventory-audit',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Invoice Type Report',
    path: '/reports/invoice-type-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Labor Detail Report(Finished)',
    path: '/reports/labor-detail-reportfinished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Open Invoice',
    path: '/reports/open-invoice',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Part Sales Report (Finished)',
    path: '/reports/part-sales-report-finished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Serial Model Sales Report (Finished)',
    path: '/reports/serial-model-sales-report-finished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Buyers Sell Thru Report',
    path: '/reports/buyers-sell-thru-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Buyers Stale Report',
    path: '/reports/buyers-stale-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'By Referal',
    path: '/reports/by-referal',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Daily Sales recap volume unit',
    path: '/reports/daily-sales-recap-volume-unit',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Downlaod GE Inventory Report',
    path: '/reports/downlaod-ge-inventory-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Finished Service Invoice Report',
    path: '/reports/finished-service-invoice-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Inventory Balance',
    path: '/reports/inventory-balance',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Sales by Account',
    path: '/reports/sales-by-account',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Sales by Contact',
    path: '/reports/sales-by-contact',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Sales Person Report (Finished)',
    path: '/reports/sales-person-report-finished',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Serial Velocity Report',
    path: '/reports/serial-velocity-report',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Walts Inventory On Hand By Category WH Only',
    path: '/reports/walts-inventory-on-hand-by-category-wh-only',
    Component: NeedsImplementingPlaceholder,
    exact: true
  },
  {
    title: 'Brand Sell Thru Report',
    path: '/reports/brand-sell-thru',
    Component: BrandSellThruReport,
    exact: true,
    permission: 'purchasing:reports'
  },
  {
    title: 'Sellout Threat Report',
    path: '/reports/sellout-threat',
    Component: SelloutThreatReport,
    exact: true,
    permission: 'purchasing:reports'
  },
  {
    title: 'Bundle Sales Report',
    path: '/reports/bundle-sales',
    Component: BundleSalesReport,
    exact: true,
    permission: 'purchasing:reports'
  },
  {
    title: 'Daily Received Report',
    path: '/reports/daily-received',
    Component: TransactedReceiptReport,
    exact: true,
    submenu: 'Admin',
    permission: 'inventory:reports'
  },
  {
    path: '/shipping-reconciliation',
    submenu: 'Accounting',
    Component: ShippingReconciliationMenu,
    permission: 'purchasing:reports',
    title: 'Shipping Reconciliation'
  },
  {
    title: 'Un-Received Invoices Report',
    path: '/report/unreceived-invoices-report',
    Component: UnReceivedInvoiceReport,
    exact: true,
    submenu: 'Admin',
    permission: 'inventory:reports'
  },
  {
    title: 'Brand Purchasing Report',
    path: '/reports/brand-purchasing',
    Component: BrandPurchasingReport,
    exact: true,
    permission: 'inventory:reports'
  },
  {
    title: 'Inactive Inventory Report',
    path: '/reports/inactive-inventory',
    Component: InactiveInventoryReport,
    exact: true,
    permission: 'inventory:reports'
  },
  {
    title: 'Peak Days Fulfillment Report',
    path: '/reports/peak-days-fulfillment',
    Component: PeakDaysFulfillmentReport,
    exact: true,
    permission: 'inventory:reports'
  },
  {
    title: 'Fba Pending Orders Dollar Report',
    path: '/reports/fba-pending-orders-in-dollars',
    Component: FbaPendingOrdersInDollarsReport,
    exact: true,
    permission: 'inventory:reports'
  },
  {
    title: 'Carousel Wizard',
    path: '/carousel-wizard',
    Component: CarouselIndex,
    exact: true,
    permission: 'purchasing:reports'
  },
  {
    title: 'Amazon Supply Sources (ISPU)',
    path: '/supply-sources',
    Component: SupplySourcesIndex,
    exact: true,
    permission: 'supply-sources:manage'
  }
];

const availableRoutes = wpos3Routes.filter(route => route.permission);

function Wpos3() {
  const user = useContext(UserContext);
  return (
    <Switch>
      <Route exact path="/" key="index">
        <Can
          role={user.role || 'user'}
          perform="dev-route:view"
          yes={() => (
            <Page title="Site Map">
              <SiteMap routes={wpos3Routes} needsImplementing={NeedsImplementingPlaceholder} />
            </Page>
          )}
          no={() => (
            <Page title="Home">
              <HomePage pages={availableRoutes} />
            </Page>
          )}
        />
      </Route>
      {wpos3Routes.map(route => {
        const { Component } = route;
        return (
          <Route exact={route.exact} path={route.path} key={route.path}>
            <Can
              role={user.role || 'user'}
              perform={route.permission || 'dev-route:view'}
              yes={() => (
                <Page title={route.title}>
                  <Component />
                </Page>
              )}
              no={() => (
                <p>
                  Your {user.role} role does not have permission for {route.permission}
                </p>
              )}
            />
          </Route>
        );
      })}
      <Route key="404">
        <Route404 />
      </Route>
    </Switch>
  );
}

export default Wpos3;
