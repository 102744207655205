import React, { MouseEvent } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { snakeCase } from 'lodash';
import { useContext } from 'react';
import GlueButton from '../Presentational/GlueButton';
import GlueTable, { GlueTableColumn, GlueTableRow } from '../Presentational/GlueTable';
import { BinManagementContext } from './BinManagementContext';
import { BinLocation } from './BinManagementTypes';
import BinSearch from './BinSearch';
import DeleteBinModal from './DeleteBin/DeleteBinModal';
import GlueTooltip from '../Presentational/GlueTooltip';

const useStyles = makeStyles({
  columnWidth: {
    minWidth: '160px'
  }
});

export default function BinTable() {
  const classes = useStyles();
  const { binLocations, loadingBinLocations, queryParams, setQueryParams, binErrors } = useContext(
    BinManagementContext
  );

  const binTableRows: GlueTableRow[] = binLocations.map((bin: BinLocation) => {
    const view = (
      <GlueTooltip title="Feature not available yet. Coming soon!">
        <span>
          <GlueButton variant={'contained'} size={'small'}>
            View Bin Contents
          </GlueButton>
        </span>
      </GlueTooltip>
    );
    return {
      key: bin.id,
      data: {
        ...bin,
        delete: <DeleteBinModal bin={bin} />,
        view
      }
    };
  });
  const binTableColumns: GlueTableColumn[] = [
    {
      columnName: 'Bin Id',
      columnKey: 'id'
    },
    {
      columnName: 'Bin Name',
      columnKey: 'binName',
      isSortable: true
    },
    {
      columnName: 'Location',
      columnKey: 'locationCode'
    },
    {
      columnName: 'Condition',
      columnKey: 'conditionCode'
    },
    {
      columnName: '',
      columnKey: 'view',
      align: 'right',
      size: 'medium',
      className: classes.columnWidth
    },
    {
      columnName: <BinSearch />,
      columnKey: 'delete',
      align: 'center',
      className: classes.columnWidth
    }
  ];

  const handleRequestSort = (event: MouseEvent<unknown, unknown>, property: any): void => {
    let sortDirection: 'desc' | 'asc' = queryParams.sortDirection === 'asc' ? 'desc' : 'asc';
    setQueryParams({
      ...queryParams,
      orderBy: snakeCase(property),
      sortDirection
    });
  };
  return (
    <Paper>
      <GlueTable
        accessibleAriaLabel={'bin-locations-table'}
        orderBy={queryParams.orderBy ? queryParams.orderBy : 'created_at'}
        sortDirection={queryParams.sortDirection ? queryParams.sortDirection : 'asc'}
        onRequestSort={handleRequestSort}
        columns={binTableColumns}
        rows={binTableRows}
        isLoading={loadingBinLocations}
        isErrors={binErrors}
      />
    </Paper>
  );
}
