import React from 'react';
import BinManagementLayout from './BinManagementLayout';
import { BinLocationsProvider } from './BinManagementContext';

export default function BinManagementContainer() {
  return (
    <BinLocationsProvider>
      <BinManagementLayout />
    </BinLocationsProvider>
  );
}
