import { Dispatch, SetStateAction } from 'react';
import { BinLocation } from '../BinManagementTypes';

export type HandleRowBayShelfChange = React.ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
>;

export type SetByName = {
  row: SetOptions;
  bay: SetOptions;
  shelf: SetOptions;
};

export type SetOptions = {
  set: (value: number) => void;
};

export type ErrorState = {
  isError: boolean;
  helperText: string;
};

export type BinCondition = {
  name: string;
  value: string;
};

export type CreateBinLocationData = Omit<BinLocation, 'id' | 'createdAt' | 'updatedAt'>;

export type CreateBinLocation =
  | ((createBinData: CreateBinLocationData) => Promise<void>)
  | (() => void);

export type CreateBinErrors = {
  binName?: string[];
  conditionCode?: string[];
  row?: string[];
  bay?: string[];
  shelf?: string[];
  locationCode?: string[];
};

export type UseCreateBinLocation = {
  createBinData: CreateBinLocationData;
  setCreateBinData: Dispatch<SetStateAction<CreateBinLocationData>>;
  loadingCreateBin: boolean;
  createBinErrors: CreateBinErrors;
  setCreateBinErrors: Dispatch<SetStateAction<CreateBinErrors>>;
  createBinSuccess: boolean;
  setCreateBinSuccess: Dispatch<SetStateAction<boolean>>;
  createBin: CreateBinLocation;
};

// Future TODO would be to make this data-driven, pulling from IMS inventory_conditions where can_have_bins = true.
export const ALLOWED_BIN_CONDITIONS: BinCondition[] = [
  {
    name: 'NEW',
    value: 'NEW'
  },
  {
    name: 'B_STOCK',
    value: 'B_STOCK'
  },
  {
    name: 'B_STOCK_LOCAL',
    value: 'B_STOCK_LOCAL'
  },
  {
    name: 'C_STOCK',
    value: 'C_STOCK'
  },
  {
    name: 'B_COSMETIC',
    value: 'B_COSMETIC'
  },
  {
    name: 'DAMAGED',
    value: 'DAMAGED'
  },
  {
    name: 'OTHER',
    value: 'OTHER'
  }
];
