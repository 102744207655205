import React from 'react'
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { GlueTheme } from '../../../GlueThemeType';
import GlueTooltip from '../../Presentational/GlueTooltip';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    infoIcon: {
      color: theme.palette.grey[600]
    }
  })
);

export type ShipPriorityColumnHeaderProps = {
  printVersion?: boolean;
}

export default function ShipPriorityColumnHeader({ printVersion }: ShipPriorityColumnHeaderProps) {

  const classes = useStyles();

  return (<Grid
    container
    justify={'space-around'}
    alignItems={'center'}
  >
    Ship Priority
    {
      printVersion
        ? null
        : (<GlueTooltip
          title={'These items are higher prority than other items with the same ship by date.'}
          aria-label={'mist-ship-info'}
        >
          <InfoIcon
            fontSize={'small'}
            className={classes.infoIcon}
          />
        </GlueTooltip>)
    }
  </Grid>);
}