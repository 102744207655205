import React from 'react';
import Paper from '@material-ui/core/Paper';

const styles = {
  paper: {
    padding: 25,
    margin: 25,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flex: 1
  },
  element: {
    marginRight: 15,
    'marginRight:lastChild': 0,
    maxWidth: '100%'
  }
};

export default function Container({ children, ...restOfProps }) {
  return (
    <Paper style={styles.paper} {...restOfProps}>
      {React.Children.map(children, child => (
        <div style={styles.element}>{child}</div>
      ))}
    </Paper>
  );
}
