import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import ShippingTransactionsRequestForm from './ShippingTransactionsRequestForm';
import UpsShippingReconciliationUploader from './UpsShippingReconciliationUploader';
import FedexShippingReconciliationUploader from './FedexShippingReconciliationUploader';
import CevaShippingReconciliationUploader from './CevaShippingReconciliationUploader';
import SearchShippingTransactions from './SearchShippingTransactions';

const subRoutes = {
  'Bulk Updates': [
    {
      relativePath: '/generic',
      Component: ShippingTransactionsRequestForm,
      exact: true,
      title: 'Generic Shipping Reconciliation'
    },
    {
      relativePath: '/ups',
      Component: UpsShippingReconciliationUploader,
      exact: true,
      title: 'UPS Shipping Reconciliation'
    },
    {
      relativePath: '/fedex',
      Component: FedexShippingReconciliationUploader,
      exact: true,
      title: 'Fedex Shipping Reconciliation'
    },
    {
      relativePath: '/ceva',
      Component: CevaShippingReconciliationUploader,
      exact: true,
      title: 'CEVA Shipping Reconciliation'
    }
  ],
  'Search Transactions': [
    {
      relativePath: '/search',
      Component: SearchShippingTransactions,
      exact: true,
      title: 'Search'
    }
  ]
};

function ShippingReconciliationMenu() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.url} key="index">
        {Object.keys(subRoutes).map(routeGroup => {
          return (
            <React.Fragment key={routeGroup}>
              <Typography component="h3">{routeGroup}</Typography>
              <ul>
                {subRoutes[routeGroup].map(route => (
                  <Typography key={route.relativePath} component="li">
                    <MuiLink
                      component={Link}
                      to={`${match.url}/${route.relativePath}`.replace('//', '/')}
                    >
                      {route.title}
                    </MuiLink>
                  </Typography>
                ))}
              </ul>
            </React.Fragment>
          );
        })}
      </Route>
      {Object.keys(subRoutes).map(routeGroup => {
        return subRoutes[routeGroup].map(route => {
          const { Component } = route;
          return (
            <Route
              path={`${match.url}/${route.relativePath}`.replace('//', '/')}
              key={route.relativePath}
              render={() => (
                <>
                  <Typography style={{ marginBottom: 8 }}>
                    <MuiLink component={Link} to={match.url}>
                      Back to Menu
                    </MuiLink>
                  </Typography>
                  <Component />
                </>
              )}
            />
          );
        });
      })}
    </Switch>
  );
}

export default ShippingReconciliationMenu;
