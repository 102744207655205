import React, { useEffect } from 'react';

function BaseRedirect({ redirect }) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.replace(redirect);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);
  return <p>Redirecting to {redirect}</p>;
}

export const FulfillmentRedirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/fulfillment_board.php" />
);

export const OrderStatusRedirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/order_status_board.php" />
);

export const ReturnRedirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/return_board.php" />
);

export const ShippingTrackerRedirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/shipping_tracker.php" />
);

export const KY01Redirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/fulfillment_board_ky_one.php" />
);

export const TX01Redirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/fulfillment_board_tx_one.php" />
);

export const AZ02Redirect = () => (
  <BaseRedirect redirect="https://wpos.walts.com/pos/fulfillment_board_az_two.php" />
);
