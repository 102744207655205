import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Dialog,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import isEmpty from 'lodash/isEmpty';

import { usePODialogStyles } from '../PurchaseOrderDetailsStyles';
import { badSound, unReceiveSound } from '../../../common/playSound';
import Can from '../../../roles/Can';
import ScanEventUnsubscriber from '../../../common/ScanEventListening/ScanEventUnsubscriber';
import { PurchaseOrderItem } from '../../../common/Types/ApiOsposDbModels/PurchaseOrderItemTypes';
import { unReceiveSerialMatches, unReceiveUpcMatches } from './UnReceivingHelpers';
import { TransactedReceiptSnapshot } from '../../../common/Types/DbSnapshots/InventoryEventDbSnapshotTypes';
import { matchesUpc } from '../../../helpers/scanValidator';
import { ProductSnapshot } from '../../../common/Types/DbSnapshots/ProductDbSnapshotTypes';
import {
  useGetUnReceivableTransactedReceipts,
  useUnReceivePOItem,
  useUnReceivingStatus
} from './UnReceivingHooks';
import { looksLikeUpc } from '../../../helpers/upcHelpers';

type PurchaseOrderUnReceivingProps = {
  dialogIsOpen: boolean;
  poItem: PurchaseOrderItem;
  onClose: () => void;
};

const useUnReceivingStyles = makeStyles(theme => ({
  successBackground: {
    backgroundColor: 'lightgreen'
  },
  errorBackground: {
    backgroundColor: 'tomato'
  }
}));

export default function PurchaseOrderUnReceiving(props: PurchaseOrderUnReceivingProps) {
  const { poItem, dialogIsOpen, onClose } = props;
  const poDialogClasses = usePODialogStyles();
  const unReceivingClasses = useUnReceivingStyles();

  const [upcInputValue, setUpcInputValue] = useState<string>('');
  const [serialInputValue, setSerialInputValue] = useState<string>('');
  const [scannedUpc, setScannedUpc] = useState<string>('');
  const [scannedSerials, setScannedSerials] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [processingUnReceive, setProcessingUnReceive] = useState<boolean>(false);
  const [scanningRequired, setScanningRequired] = useState<boolean>(true);
  const [sameNotesAreUsedForAllItems, setSameNotesAreUsedForAllItems] = useState<boolean>(true);
  const [unReceivableList, setUnReceivableList] = useState<TransactedReceiptSnapshot[]>([]);
  const [expectedImsProduct, setExpectedImsProduct] = useState<ProductSnapshot | null>(null);

  const [errorMessage, setErrorMessage, error, setError] = useUnReceivingStatus('');
  const [successMessage, setSuccessMessage, success, setSuccess] = useUnReceivingStatus('');
  const { getUnReceivableTransactedReceipts, isFetching } = useGetUnReceivableTransactedReceipts();
  const { unReceivePOItem, isPosting } = useUnReceivePOItem();

  const upcFieldRef = useRef<HTMLInputElement>(null);
  const serialFieldRef = useRef<HTMLInputElement>(null);
  const notesFieldRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const changeUpc = (e: any) => setUpcInputValue(e.target.value.trim());
  const changeSerial = (e: any) => setSerialInputValue(e.target.value.trim());
  const changeNotes = (e: any) => setNotes(e.target.value);
  const addScannedSerial = (serialArg: string) => {
    setScannedSerials(existingSerials => [...existingSerials, serialArg]);
  };

  const resetScannedItems = useCallback((): void => {
    setUpcInputValue('');
    setSerialInputValue('');
    setScannedUpc('');
    setScannedSerials([]);
  }, []);
  const failWithMessage = useCallback(
    (message: string, shouldResetScannedItems: boolean = true): void => {
      setErrorMessage(message);
      setError(true);
      badSound();
      if (shouldResetScannedItems) {
        resetScannedItems();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const succeedWithMessage = useCallback((message: string): void => {
    setSuccess(true);
    setSuccessMessage(message);
    unReceiveSound();
    resetScannedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnRecevingNonSerialized = useCallback(async () => {
    const nonSerializedTransactionReferenceId = unReceivableList[0].ims_transaction_reference_id;
    const unReceivedNoSerial = await unReceivePOItem({
      imsTransactionReferenceId: nonSerializedTransactionReferenceId,
      poItemId: poItem.id,
      notes
    });
    if (unReceivedNoSerial.requestSuccessful) {
      setUnReceivableList(unReceivedNoSerial.remaining_transacted_receipts);
      succeedWithMessage('Successfully un-received item!');
    } else {
      failWithMessage('Unknown error occurred in un-receive request.');
    }
  }, [poItem.id, unReceivableList, notes, unReceivePOItem, succeedWithMessage, failWithMessage]);

  const handleUnRecevingSerialized = useCallback(
    async (joinedSerial: string) => {
      const transactionReferenceId = unReceivableList
        .filter(listItem => !isEmpty(listItem.serials))
        .filter(listItem => {
          return (
            listItem.serials &&
            listItem.serials.length &&
            listItem.serials[0].scanned_serial_number === joinedSerial &&
            listItem.serials[0].scanned_upc
          );
        })[0].ims_transaction_reference_id;
      const unReceived = await unReceivePOItem({
        imsTransactionReferenceId: transactionReferenceId,
        poItemId: poItem.id,
        notes
      });
      if (unReceived.requestSuccessful) {
        setUnReceivableList(unReceived.remaining_transacted_receipts);
        succeedWithMessage('Un-received item');
      } else {
        failWithMessage('Unknown error occurred in un-receive request.');
      }
    },
    [poItem.id, unReceivableList, notes, unReceivePOItem, succeedWithMessage, failWithMessage]
  );

  const unReceiveItem = useCallback(
    async (upcArg: string, serialsArg: string[] = []) => {
      if (!unReceivableList.length) {
        failWithMessage('Unable to submit: un-receive list is empty.', false);
        return;
      }
      if (!notes) {
        failWithMessage('Notes cannot be empty.', false);
        if (notesFieldRef.current) {
          notesFieldRef.current.focus();
        }
        return;
      }
      if (!unReceiveUpcMatches(upcArg, unReceivableList)) {
        failWithMessage("UPC doesn't match");
        if (upcFieldRef.current) {
          upcFieldRef.current.focus();
        }
        return;
      }
      if (
        expectedImsProduct?.num_of_serials_per_item === 0 ||
        (unReceivableList[0].serials || []).length === 0 ||
        !scanningRequired
      ) {
        setProcessingUnReceive(true);
        await handleUnRecevingNonSerialized();
        setProcessingUnReceive(false);
        return;
      }
      const joinedSerial: string = serialsArg.sort().join(':=:');
      if (unReceiveSerialMatches(joinedSerial, unReceivableList)) {
        setProcessingUnReceive(true);
        await handleUnRecevingSerialized(joinedSerial);
        setProcessingUnReceive(false);
        return;
      }
      failWithMessage("Serial doesn't match");
      if (upcFieldRef.current) {
        upcFieldRef.current.focus();
      }
    },
    [
      handleUnRecevingNonSerialized,
      handleUnRecevingSerialized,
      notes,
      scanningRequired,
      unReceivableList,
      expectedImsProduct,
      failWithMessage
    ]
  );

  const handleScanUpc = (upcArg: string) => {
    if (!expectedImsProduct) {
      throw new Error('Cannot handleScanUpc: expectedImsProduct cannot be empty');
    }
    if (matchesUpc(upcArg, expectedImsProduct)) {
      setScannedUpc(upcArg);
      if (expectedImsProduct.num_of_serials_per_item === 0 && notesFieldRef.current) {
        if (!notes || !sameNotesAreUsedForAllItems) {
          notesFieldRef.current.focus();
        } else if (submitButtonRef.current) {
          submitButtonRef.current.focus();
        }
      } else if (serialFieldRef.current) {
        serialFieldRef.current.focus();
      }
    } else {
      failWithMessage("UPC doesn't match");
    }
  };

  const handleScanSerial = (serialArg: string) => {
    if (!expectedImsProduct) {
      throw new Error('Cannot handleScanSerial: expectedImsProduct cannot be empty');
    }
    if (expectedImsProduct.num_of_serials_per_item > 0) {
      addScannedSerial(serialArg);
      setSerialInputValue('');
      if (
        scannedSerials.length + 1 === expectedImsProduct.num_of_serials_per_item &&
        notesFieldRef.current
      ) {
        if (!notes || !sameNotesAreUsedForAllItems) {
          notesFieldRef.current.focus();
        } else if (submitButtonRef.current) {
          submitButtonRef.current.focus();
        }
      }
    }
  };

  function handleClose() {
    setNotes('');
    resetScannedItems();
    setUnReceivableList([]);
    onClose();
  }

  useEffect(() => {
    const refreshUnreceivableList = async () => {
      const refresehdUnreceivableList = await getUnReceivableTransactedReceipts(poItem.id);
      const imsProduct =
        refresehdUnreceivableList.find(transactedReceipt => !isEmpty(transactedReceipt.product))
          ?.product || null;
      setExpectedImsProduct(imsProduct);
      setUnReceivableList(refresehdUnreceivableList);
    };
    if (dialogIsOpen) {
      refreshUnreceivableList();
    }
  }, [poItem.id, dialogIsOpen, getUnReceivableTransactedReceipts]);

  useEffect(() => {
    if (unReceivableList.length && upcFieldRef.current && isEmpty(scannedUpc)) {
      upcFieldRef.current.focus();
    }
  }, [unReceivableList.length, scannedUpc]);

  useEffect(() => {
    if (
      !processingUnReceive &&
      expectedImsProduct &&
      scannedSerials.length >= expectedImsProduct.num_of_serials_per_item &&
      !isEmpty(notes) &&
      looksLikeUpc(scannedUpc) &&
      sameNotesAreUsedForAllItems &&
      notesFieldRef.current &&
      notesFieldRef.current !== document.activeElement
    ) {
      unReceiveItem(scannedUpc, scannedSerials);
    }
  }, [
    scannedSerials,
    expectedImsProduct,
    scannedUpc,
    unReceiveItem,
    processingUnReceive,
    notes,
    sameNotesAreUsedForAllItems
  ]);

  return (
    <Dialog fullWidth maxWidth="lg" open={dialogIsOpen} onClose={handleClose}>
      <ScanEventUnsubscriber />
      <div
        className={`${poDialogClasses.root} ${
          error
            ? unReceivingClasses.errorBackground
            : success
            ? unReceivingClasses.successBackground
            : ''
        }`}
      >
        <Button onClick={handleClose}>
          <Close />
        </Button>
        <Typography variant="h2" className={poDialogClasses.heading}>
          Un-Receiving{poItem.product ? <strong> {poItem.product.model_number}</strong> : ''}
        </Typography>
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>{errorMessage || successMessage}</p>
        {isFetching ? (
          <Typography>Fetching un-receivable list...</Typography>
        ) : unReceivableList.length === 0 ? (
          <Typography>No items to un-receive</Typography>
        ) : expectedImsProduct === null ? (
          <Typography>
            Fatal error: No product record found in list of {unReceivableList.length} un-receivable
            receipt(s)
          </Typography>
        ) : (
          <>
            <TextField
              value={upcInputValue}
              onChange={changeUpc}
              disabled={!isEmpty(scannedUpc)}
              fullWidth
              inputRef={upcFieldRef}
              style={{ marginBottom: 15 }}
              variant="outlined"
              label="Scan UPC"
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' && !isEmpty(upcInputValue)) {
                  handleScanUpc(upcInputValue);
                }
              }}
            />
            <TextField
              value={serialInputValue}
              onChange={changeSerial}
              disabled={scannedSerials.length >= expectedImsProduct.num_of_serials_per_item}
              fullWidth
              inputRef={serialFieldRef}
              style={{ marginBottom: 15 }}
              variant="outlined"
              label={`Scan Serial ${scannedSerials.length} / ${expectedImsProduct.num_of_serials_per_item}`}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' && !isEmpty(serialInputValue)) {
                  handleScanSerial(serialInputValue);
                }
              }}
            />
            {scannedSerials.length > 0 && (
              <ul>
                {scannedSerials.map(serialNumber => {
                  return <li key={serialNumber}>{serialNumber}</li>;
                })}
              </ul>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sameNotesAreUsedForAllItems}
                  onChange={() => setSameNotesAreUsedForAllItems(previousValue => !previousValue)}
                />
              }
              label="Use Same Notes for All Unreceived"
            />
            <TextField
              style={{ marginBottom: 15 }}
              label="notes"
              variant="outlined"
              fullWidth
              rows={4}
              value={notes}
              inputRef={notesFieldRef}
              onChange={changeNotes}
              onKeyDown={(e: any) => {
                if (
                  !notes ||
                  !scannedUpc ||
                  (expectedImsProduct.num_of_serials_per_item > 0 && isEmpty(scannedSerials))
                ) {
                  return;
                }
                if (e.key === 'Enter') {
                  unReceiveItem(scannedUpc, scannedSerials);
                }
              }}
            />
            <Button
              onClick={() => unReceiveItem(scannedUpc, scannedSerials)}
              disabled={isPosting}
              variant="contained"
              color="primary"
              buttonRef={submitButtonRef}
            >
              Submit Un-Receive
            </Button>
            {(!isEmpty(scannedUpc) || !isEmpty(scannedSerials)) && (
              <Button onClick={resetScannedItems} disabled={isPosting} variant="contained">
                Reset Scan
              </Button>
            )}
            <Can
              perform="admin:override"
              yes={() => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={scanningRequired}
                      color="primary"
                      onChange={event => setScanningRequired(event.target.checked)}
                      value="scanningRequired"
                      inputProps={{ 'aria-label': 'set scanning required checkbox' }}
                    />
                  }
                  labelPlacement="end"
                  label={`Scanning ${scanningRequired ? 'Is' : 'Not'} Required (Admin-Only)`}
                />
              )}
            />
          </>
        )}
      </div>
    </Dialog>
  );
}
