import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Button } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import { useHttp } from '../../../common/Hooks';
import CreateBucketMapDialog from './BucketMapCreator';

export default function BucketMapsList() {
  const [bucketMappings, setBucketMappings] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [request, response] = useHttp('ims/bucket-mappings');

  const getBucketMappings = useCallback(async () => {
    const bucketMapsFromHttp = await request.get('/');
    if (response.ok) {
      setBucketMappings(
        Object.entries(bucketMapsFromHttp).map(([bucket, info]) => {
          return {
            name: bucket,
            buckets: Object.entries(info.buckets).map(([bucketName]) => bucketName)
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    getBucketMappings();
  }, [getBucketMappings]);

  return (
    <Paper style={{ padding: 25 }}>
      <Button variant="contained" onClick={() => setOpenCreateDialog(true)}>
        <Create />
        New Bucket Group Mapping
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {bucketMappings.map(bucketMap => (
          <div key={bucketMap.name} style={{ flex: '20%' }}>
            <h2>{bucketMap.name}</h2>
            <ul>
              {bucketMap.buckets.map(bucket => (
                <li key={bucket}>{bucket}</li>
              ))}
            </ul>
          </div>
        ))}
        <CreateBucketMapDialog
          open={openCreateDialog}
          handleClose={() => {
            setOpenCreateDialog(false);
            getBucketMappings();
          }}
        />
      </div>
    </Paper>
  );
}
