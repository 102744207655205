import { apiOspos } from '../../../config/config';

export default async function updateCommittedTransfer(postData) {
  return await fetch(
    `${apiOspos.baseUrl}/ims/committed-transfers/${postData.ims_transaction_reference_id}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        committedQty: postData.committed_quantity,
        posTransferBatchName: postData.pos_transfer_batch_name,
        posTransferBatchRefId: postData.pos_transfer_batch_ref_id,
        senderSku: postData.sender_sku,
        receiverSku: postData.receiver_sku,
        commitNotes: postData.commit_notes
      })
    }
  );
}
