import React from 'react';
import { createStyles, Icon, makeStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { getTransparentHex, GlueTheme } from '../../GlueThemeType';

export interface GlueButtonProps extends Omit<ButtonProps, 'color'> {
  children: React.ReactNode;
  endIcon?: string;
  className?: string;
  success?: boolean;
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'grey';
}
const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    contained: {
      color: theme.palette.secondaryOrange.contrastText,
      backgroundColor: theme.palette.secondaryOrange.main,
      fontWeight: 'bold',
      letterSpacing: '0.28px',
      '&:hover': {
        backgroundColor: theme.palette.secondaryOrange.dark
      }
    },
    containedSuccess: {
      color: theme.palette.secondaryGreen.contrastText,
      backgroundColor: theme.palette.secondaryGreen.main,
      fontWeight: 'bold',
      letterSpacing: '0.28px',
      '&:hover': {
        backgroundColor: theme.palette.secondaryGreen.dark 
      }
    },
    outlined: {
      color: theme.palette.secondaryOrange.main,
      borderColor: theme.palette.secondaryOrange.main,
      backgroundColor: getTransparentHex(
        theme.palette.background.default, 
        '00'
        ),
      fontWeight: 'bold',
      letterSpacing: '0.28px',
      '&:hover': {
        borderColor: theme.palette.secondaryOrange.dark,
        color: theme.palette.secondaryOrange.dark,
        backgroundColor: getTransparentHex(
          theme.palette.background.default, 
          '00'
          ),
      }
    },
    outlinedGrey: {
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
      backgroundColor: getTransparentHex(
        theme.palette.background.default, 
        '00'
        ),
      fontWeight: 'bold',
      letterSpacing: '0.28px',
      '&:hover': {
        borderColor: theme.palette.grey[600],
        color: theme.palette.grey[600],
        backgroundColor: getTransparentHex(
          theme.palette.grey[200], 
          '00'
          ),

      }
    },
    outlinedSecondary: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      backgroundColor: getTransparentHex(
        theme.palette.background.default, 
        '00'
        ),
      fontWeight: 'bold',
      letterSpacing: '0.28px',
      '&:hover': {
        borderColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.dark,
        backgroundColor: getTransparentHex(
          theme.palette.secondary.light, 
          '00'
          ),
      }
    }
  })
);

const GlueButton = (props: GlueButtonProps) => {
  const classes = useStyles();
  const { children, endIcon, className, variant, success, color, ...otherProps } = props;

  const outlineColorMap = {
    inherit: classes.outlined,
    default: classes.outlined,
    primary: classes.outlined,
    secondary: classes.outlinedSecondary,
    grey: classes.outlinedGrey
  };
  const mappedClasses = {
    contained: success ? classes.containedSuccess : classes.contained,
    outlined: !!color ? outlineColorMap[color] : outlineColorMap.default
  };

  return (
    <Button
      {...otherProps}
      variant={!!variant ? variant : 'contained'}
      classes={mappedClasses}
      endIcon={endIcon ? <Icon>{endIcon}</Icon> : null}
    >
      {children}
    </Button>
  );
};

export default GlueButton;
