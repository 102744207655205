import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { useHttp } from '../../common/Hooks';

export const AuditBatchesContext = createContext({
  updateInProgress: true,
  setToUpdateInProgress: () => {},
  updateCompleted: true,
  setToUpdateCompleted: () => {}
});

export const useInProgressAuditBatches = () => {
  const { updateInProgress, setToUpdateInProgress } = useContext(AuditBatchesContext);
  const [inProgressBatches, setInProgressBatches] = useState([]);
  const [request, response] = useHttp('ims/audit-scans');

  const getInProgressBatches = useCallback(async () => {
    const inProgressBatchesResponse = await request.get('/');
    if (response.ok) {
      setInProgressBatches(Object.values(inProgressBatchesResponse));
    }
  }, []);

  useEffect(() => {
    if (updateInProgress) {
      getInProgressBatches();
      setToUpdateInProgress(false);
    }
  }, [getInProgressBatches, updateInProgress, setToUpdateInProgress]);

  useEffect(() => {
    return () => setToUpdateInProgress(true);
  }, []);

  return [inProgressBatches];
};

export const useCompletedAuditBatches = () => {
  const { updateCompleted, setToUpdateCompleted } = useContext(AuditBatchesContext);
  const [completedBatches, setCompletedBatches] = useState([]);
  const [request, response] = useHttp('ims/audit-scans');

  const getCompletedBatches = useCallback(async () => {
    const completedBatchesResponse = await request.get('/completed');
    if (response.ok) {
      setCompletedBatches(Object.values(completedBatchesResponse));
    }
  }, []);

  useEffect(() => {
    if (updateCompleted) {
      getCompletedBatches();
      setToUpdateCompleted(false);
    }
  }, [getCompletedBatches, updateCompleted, setToUpdateCompleted]);

  useEffect(() => {
    return () => setToUpdateCompleted(true);
  }, []);

  return [completedBatches];
};
