import React, { useContext, useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles
} from '@material-ui/core';
import { UserType } from '../../../roles/RolesTypes';
import { UserContext } from '../../../roles/User';
import { check } from '../../../roles/Can';
import { BinManagementContext } from '../BinManagementContext';
import { DeleteBinData } from './DeleteBinTypes';
import { GlueTheme } from '../../../GlueThemeType';
import { get, isEmpty } from 'lodash';
import { BinLocation } from '../BinManagementTypes';
import GlueConfirmActionModal from '../../Presentational/GlueConfirmActionModal';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    bold: {
      fontWeight: 600
    }
  })
);

export interface DeleteBinProps {
  bin: BinLocation;
}

export default function DeleteBinModal({ bin }: DeleteBinProps) {
  const classes = useStyles();

  const {
    deleteBinErrors,
    setDeleteBinErrors,
    deleteBinSuccess,
    setDeleteBinSuccess,
    deleteBin,
    getBinLocations
  } = useContext(BinManagementContext);

  const [loadingDeleteBin, setLoadingDeleteBin] = useState(false);

  useEffect(() => {
    if (deleteBinSuccess) {
      setLoadingDeleteBin(false);
      let closeDeleteModalTimer = setTimeout(handleClose, 1000);
      return () => {
        clearTimeout(closeDeleteModalTimer);
      };
    }
  }, [deleteBinSuccess]);

  useEffect(() => {
    if (deleteBinErrors.id) {
      setLoadingDeleteBin(false);
    }
  }, [deleteBinErrors.id]);

  const user: UserType = useContext(UserContext);
  const deleteBinEnabled = user && check(user.role, 'bin:admin');
  const deleteButtonText = deleteBinSuccess
    ? 'Bin Deleted Successfully'
    : loadingDeleteBin
    ? 'Deleting Bin'
    : 'Delete Bin';
  let bodyMessageText = (
    <span>
      Are you sure you want to delete the bin named
      <span className={classes.bold}>{' ' + bin.binName + ' '}</span>
      for <span className={classes.bold}>{bin.locationCode}?</span>
    </span>
  );
  if (loadingDeleteBin) {
    bodyMessageText = <span>Deleting Bin</span>;
  }
  if (deleteBinSuccess) {
    bodyMessageText = <span>Bin deleted successfully</span>;
  }
  if (!!deleteBinErrors.id) {
    bodyMessageText = <span>{get(deleteBinErrors, 'id', []).join('')}</span>;
  }

  const handleDeleteBin = (e: any) => {
    e.preventDefault();
    if (deleteBinSuccess) {
      return;
    }
    setLoadingDeleteBin(true);
    const deleteBinData: DeleteBinData = {
      id: bin.id
    };
    deleteBin(deleteBinData);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (deleteBinEnabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteBinSuccess(false);
    setDeleteBinErrors({});
    if (deleteBinSuccess) {
      getBinLocations();
    }
  };

  const handleConfirmSuccess = () => {
    setLoadingDeleteBin(false);
    handleClose();
  }

  return (
    <GlueConfirmActionModal
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onConfirm={handleDeleteBin}
      onSuccessTimeout={handleConfirmSuccess}
      triggerButtonText={'Delete'}
      confirmButtonText={deleteButtonText}
      canPerform={'bin:admin'}
      bodyMessageText={bodyMessageText}
      accessibleLabel={'delete-bin'}
      title={'Delete Bin'}
      confirmSuccess={deleteBinSuccess}
      loading={loadingDeleteBin}
      isError={!isEmpty(deleteBinErrors)}
      />
  );
}