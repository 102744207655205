import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { useCompletedAuditBatches } from './AuditBatchesContext';

function CompletedBatchRow(props) {
  const { audit_batch_name, audit_batch_id } = props;
  const param = new URLSearchParams();
  param.append('id', audit_batch_id);

  return (
    <>
      <TableRow>
        <TableCell>{audit_batch_name}</TableCell>
        <TableCell>
          <Button variant="contained">
            <Link to={`/inventory/audit-discrepancy-report/${audit_batch_id}`}>View Report</Link>
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CompletedAuditBatchesContainer() {
  const [completedBatches] = useCompletedAuditBatches();
  return (
    <Paper style={{ padding: 25 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Audit Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {completedBatches.length > 0 &&
            completedBatches
              .reverse()
              .map(row => <CompletedBatchRow {...row} key={row.audit_batch_id} />)}
        </TableBody>
      </Table>
    </Paper>
  );
}
