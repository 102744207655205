import React, { useEffect, useState } from 'react';
import './transfers.css';

export default function Messaging(props) {
  const { submitFeedback, timeoutLength = 6000 } = props;

  const [messageQueue, setMessageQueue] = useState([]);

  useEffect(() => {
    let disappearTimeout = null;
    const newMessageId = submitFeedback.messageId;

    if (submitFeedback) {
      disappearTimeout = setTimeout(() => {
        setMessageQueue(prevMessageQueue =>
          prevMessageQueue.filter(message => parseInt(message.messageId) !== parseInt(newMessageId))
        );
      }, timeoutLength);

      setMessageQueue(prevMessageQueue => [...prevMessageQueue, submitFeedback]);
    }
    // Clean-up
    return () => {
      if (disappearTimeout) {
        setMessageQueue(prevMessageQueue =>
          prevMessageQueue.filter(message => parseInt(message.messageId) !== parseInt(newMessageId))
        );
      }
    };
  }, [submitFeedback]);

  if (!messageQueue.length) {
    return null;
  }

  return messageQueue.map((feedbackData, i) => {
    if (!feedbackData.messageId) {
      console.error(
        'submitFeedback prop should have a unique messageId property in order for the' +
          ' Messaging component to accurately track message changes. One suggestion would be to' +
          ' assign a messageId property equal to Date.now().'
      );
    }

    if (feedbackData.message && feedbackData.message.toUpperCase().includes('SUCCESS')) {
      return (
        <div key={feedbackData.messageId || i} className="msgStyle successMsg">
          {feedbackData.message} !!
        </div>
      );
    }

    if (
      feedbackData.message &&
      (feedbackData.message.toUpperCase().includes('ERROR') ||
        feedbackData.message.toUpperCase().includes('FAILED'))
    ) {
      if (feedbackData.errors) {
        return (
          <div key={feedbackData.messageId || i} className="msgStyle failedMsg">
            {feedbackData.errors} !!
          </div>
        );
      }
      if (feedbackData.message) {
        return (
          <div key={feedbackData.messageId || i} className="msgStyle failedMsg">
            {feedbackData.message} !!
          </div>
        );
      }
    }
    return (
      <div key={feedbackData.messageId || i} className="msgStyle failedMsg">
        {JSON.stringify(feedbackData.message)}
      </div>
    );
  });
}
