const initialState = {
  payments: [],
  paymentsFulfilled: false,
  paymentClasses: [],
  paymentMethods: [],
  paymentTerminals: [],
  paymentSettingsFulfilled: false
};

export default function payment(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_PAYMENT_REJECTED':
      return {
        ...state
      };
    case 'CREATE_PAYMENT_FULFILLED':
      return {
        ...state,
        payments: [...state.payments, action.payload]
      };
    case 'GET_FULL_WORKORDER_FULFILLED':
      return {
        ...state,
        payments: action.payload.data.payments,
        paymentsFulfilled: true
      };
    case 'PAYMENT_SETTINGS_FULFILLED':
      return {
        ...state,
        paymentMethods: action.payload.methods,
        paymentTerminals: action.payload.terminals,
        paymentSettingsFulfilled: true
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
