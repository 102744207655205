import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
// import SimpleProductSearch from './../productSearch/SimpleProductSearch';
// import ProductCard from './components/ProductCard';
// import ProductCardImage from './components/ProductCardImage';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { auth, db } from '../../firebase';

import * as userActions from '../../actions/userActions';
import * as quoteActions from '../../actions/quoteActions';
import * as productActions from '../../actions/productActions';
import * as brandActions from '../../actions/brandActions';

class ProductReviews extends Component {
  constructor() {
    super();
    this.state = {
      brandsArray: [],
      categoriesArray: [],
      product: null,
      reviews: {},
      productReviews: '',
      productReviewsDatabase: {},
      noReviews: true
    };
  }

  componentDidMount() {
    auth.signIn();
  }

  getProductInfoClicked = product => {
    db.getReviews('allReviews', (snapshot, response) => {
      if (snapshot.val() === null) {
        this.setState({ reviews: {}, noReviews: true });
      } else {
        this.setState({ noReviews: false, produc: snapshot.val() });
      }
    });

    this.setState({ product });
  };

  getReviews = () => {
    db.getReviews('allReviews', (snapshot, response) => {
      if (snapshot.val() === null) {
        this.setState({ reviews: {}, noReviews: true });
      } else {
        this.setState({ noReviews: false, reviews: snapshot.val() });
      }
    });
  };

  getProductInfoEnterKey = searchModel => {};

  navTo = nav => {
    this.props.history.push(nav);
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    db.getReviewsForProduct(event.target.value, (snapshot, response) => {
      if (snapshot.val() === null) {
        this.setState({ reviews: {}, noReviews: true });
      } else {
        this.setState({
          noReviews: false,
          productReviewsDatabase: snapshot.val()
        });
      }
    });
  };

  updateVisibileReview = (model, referenceId, visibleField) => {
    // db    .ref().update(updates);
    db.updateReview(model, referenceId, !visibleField);
  };

  render() {
    // const { id, type, value, onChange, placeholder, onBlur, validation, initialValue } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 10 }}>
            <Button variant="contained" onClick={() => this.getReviews()}>
              Get Products with reviews{' '}
            </Button>
          </div>
          {!this.state.noReviews ? (
            <div>
              <div>
                <InputLabel htmlFor="product-reviews">Get Products Reviews</InputLabel>
              </div>
              <div>
                <Select
                  style={{ width: 200 }}
                  value={this.state.productReviews}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'productReviews',
                    id: 'product-reviews'
                  }}
                >
                  {Object.keys(this.state.reviews).map(i => {
                    return (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
          ) : null}
          <Grid
            style={{ padding: 20 }}
            container
            direction="column"
            justify="flex-start"
            spacing={24}
          >
            {Object.keys(this.state.productReviewsDatabase).map(i => {
              return (
                <Grid item xs={12} key={i}>
                  <Typography align="left" variant="headline" component="h3" gutterBottom>
                    Name: {this.state.productReviewsDatabase[i].name}
                  </Typography>
                  <Typography align="left" variant="headline" component="h3" gutterBottom>
                    Email: {this.state.productReviewsDatabase[i].email}
                  </Typography>
                  <Typography align="left" variant="headline" component="h3" gutterBottom>
                    Stars: {this.state.productReviewsDatabase[i].stars}
                  </Typography>
                  <Typography align="left" variant="headline" component="h3" gutterBottom>
                    Date Reviewd: {this.state.productReviewsDatabase[i].reviewOn}
                  </Typography>
                  <Typography align="left" variant="headline" component="h3" gutterBottom>
                    {this.state.productReviewsDatabase[i].message}
                  </Typography>
                  <Switch
                    checked={this.state.productReviewsDatabase[i].visible}
                    onChange={() =>
                      this.updateVisibileReview(
                        this.state.productReviews,
                        i,
                        this.state.productReviewsDatabase[i].visible
                      )
                    }
                    value="checkedB"
                    color="primary"
                  />
                  Visible:{' '}
                  {this.state.productReviewsDatabase[i].visible ? (
                    <span>Yes</span>
                  ) : (
                    <span>No</span>
                  )}
                  <Divider style={{ marginTop: 10 }} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      access_token: state.user.loginCredentials.access_token,
      productState: state.products,
      userState: state.user
    }),
    dispatch => ({
      brandActions: bindActionCreators(brandActions, dispatch),
      productActions: bindActionCreators(productActions, dispatch),
      quoteActions: bindActionCreators(quoteActions, dispatch),
      userActions: bindActionCreators(userActions, dispatch)
    })
  )(ProductReviews)
);
