import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { locations } from '../../actions/locationActions';
import GlueSelect from '../Presentational/GlueSelect';
import { BinManagementContext } from './BinManagementContext';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    top: -48,
    minWidth: '100%'
  }
});

export default function LocationSelect() {
  const classes = useStyles();
  const { queryParams, setQueryParams } = useContext(BinManagementContext);
  const handleLocationChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    let newLocationCode = '';
    if (typeof event.target.value === 'string') {
      newLocationCode = event.target.value;
    }
    setQueryParams({
      ...queryParams,
      locationCode: newLocationCode
    });
  };
  const options = locations.map((locationCode: string) => {
    return {
      key: locationCode,
      value: locationCode,
      name: locationCode
    };
  });

  return (
    <GlueSelect
      options={options}
      accessibleLabelId={'simple-select-label'}
      label={'Choose your location'}
      handleChange={handleLocationChange}
      value={queryParams.locationCode}
      className={classes.root}
    />
  );
}
