import React from 'react';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FileCopy from '@material-ui/icons/FileCopy';
import Check from '@material-ui/icons/Check';
import isEmpty from 'lodash/isEmpty';
import { useStatus } from '../../../common/Hooks';
import { useGlueCopyToClipboardIconButtonStyles } from './GlueCopyToClipboardIconButtonStyles';

const DEFAULT_TYPOGRAPHY_VARIANT = 'body1';

export const GlueCopyToClipboardIconButton = props => {
  const { children, parentTypographyVariant = null } = props;
  const classes = useGlueCopyToClipboardIconButtonStyles({
    typographyVariant: parentTypographyVariant || DEFAULT_TYPOGRAPHY_VARIANT
  });
  const [valueHasBeenCopied, setValueHasBeenCopied] = useStatus(false, 2.5);

  const handleClickToCopy = () => {
    navigator.clipboard.writeText(children);
    setValueHasBeenCopied(true);
  };

  const icon = isEmpty(children) ? null : (
    <Tooltip title={valueHasBeenCopied ? 'Copied!' : 'Copy value to clipboard.'}>
      {valueHasBeenCopied ? (
        <Check className={classes.icon} onClick={handleClickToCopy} />
      ) : (
        <FileCopy className={classes.icon} onClick={handleClickToCopy} />
      )}
    </Tooltip>
  );

  return (
    <>
      {parentTypographyVariant ? (
        <>
          {children}
          {icon}
        </>
      ) : (
        <Typography variant={DEFAULT_TYPOGRAPHY_VARIANT}>
          {children}
          {icon}
        </Typography>
      )}
    </>
  );
};
