import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/* EditDialog Component */
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as contactActions from '../../actions/contactActions';
import { states } from '../../data/UTILITY'; // Grabbing All 50 states from our config file.

export class ContactCreateComponent extends Component {
  state = {
    open: true,
    loading: false, // Todo:: create a obvious loading component to display creation happening.
    contact: {
      first_name: '',
      last_name: '',
      address: '',
      address2: '',
      city: '',
      state: 'state',
      zip: '',
      primary_phone: '',
      secondary_phone: '',
      primary_email: '',
      source: 'source'
    }
  };

  handleChange = name => event => {
    const newValue = event.target.value;
    this.setState(prevState => ({
      contact: {
        ...prevState.contact,
        [name]: newValue
      }
    }));
  };

  saveAndCreateContact = () => {
    this.setState({ loading: true });
    this.props.contactActions.contactCreate(
      this.props.auth,
      this.state.contact,
      (err, response) => {
        if (response) {
          this.props.contactActions
            .contactHistory(response.id, 'sold_contact_id', this.props.auth)
            .then(() => {
              this.props.history.push('/contacts/details');
              this.setState({ loading: false });
            });
        }
      }
    );
  };

  handleClose = () => {
    this.props.history.push('/contacts');
  };

  render() {
    const { contact } = this.state;

    return (
      <div>
        <EditDialog
          open={this.state.open}
          handleClose={this.handleClose}
          dialogTitle="Create New Contact"
          saveEdit={() => this.saveAndCreateContact()}
        >
          <TextField
            style={{ marginBottom: 20 }}
            id="first_name"
            label="First Name"
            onChange={this.handleChange}
            value={contact.first_name}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="last_name"
            label="Last Name"
            onChange={this.handleChange}
            value={contact.last_name}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="address"
            label="Address"
            onChange={this.handleChange}
            value={contact.address}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="address2"
            label="Address Line 2"
            onChange={this.handleChange}
            value={contact.address2}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="city"
            label="City"
            onChange={this.handleChange}
            value={contact.city}
            fullWidth
          />
          <Select
            tyle={{ marginBottom: 20 }}
            id="state"
            value={contact.state}
            onChange={this.handleChange}
            inputProps={
              {
                // name: 'searchValue',
                // id: 'state',
              }
            }
          >
            <MenuItem key="state" value="state">
              Select State
            </MenuItem>
            {states.map(state => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            style={{ marginBottom: 20 }}
            id="zip"
            label="Zip"
            onChange={this.handleChange}
            value={contact.zip}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="primary_phone"
            label="Primary Phone"
            onChange={this.handleChange}
            value={contact.primary_phone}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="secondary_phone"
            label="Secondary Phone"
            onChange={this.handleChange}
            value={contact.secondary_phone}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 20 }}
            id="primary_email"
            label="Primary Email"
            onChange={this.handleChange}
            value={contact.primary_email}
            fullWidth
          />
          <Select
            style={{ marginBottom: 20 }}
            id="source"
            value={contact.source}
            onChange={this.handleChange}
            inputProps={
              {
                // name: 'searchValue',
                // id: 'source',
              }
            }
          >
            <MenuItem key="source" value="source">
              Select Source
            </MenuItem>
            <MenuItem key="phone" value="phone">
              Phone
            </MenuItem>
            <MenuItem key="in_store" value="in_store">
              In Store
            </MenuItem>
            <MenuItem key="in_home" value="in_home">
              In Home
            </MenuItem>
            <MenuItem key="online" value="online">
              Online
            </MenuItem>
          </Select>
        </EditDialog>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.user.loginCredentials.access_token,
      contact: state.contacts.contact
    }),
    dispatch => ({
      contactActions: bindActionCreators(contactActions, dispatch)
    })
  )(ContactCreateComponent)
);

class EditDialog extends Component {
  state = {
    changeContact: false
  };

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        onChange: child.props.onChange(child.props.id)
      });
    });

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          {this.props.changeContactOption ? (
            <Button onClick={() => this.setState({ changeContact: true })} color="primary">
              Change Contact
            </Button>
          ) : null}
          <Button onClick={this.props.saveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
