import { apiOspos } from '../../../config/config';

export default function deleteCommittedTransfer(id) {
  return fetch(`${apiOspos.baseUrl}/ims/committed-transfers/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json'
    }
  });
}
