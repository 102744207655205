import React, { useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHttp, useStatus } from '../../common/Hooks';
import { getHttpResponseErrorMessage } from '../../helpers/getErrors';

const useStyles = makeStyles(theme => ({
  formCard: {
    maxWidth: 400
  },
  formControl: {
    display: 'inline flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
    minWidth: 100
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto'
  },
  successMessage: {
    backgroundColor: theme.misc.successAlert.backgroundColor,
    fontWeight: 'bold',
    padding: theme.spacing(2),
    overflowWrap: 'break-word'
  },
  errorMessage: {
    backgroundColor: theme.misc.errorAlert.backgroundColor,
    fontWeight: 'bold',
    padding: theme.spacing(2),
    overflowWrap: 'break-word'
  },
  transactionIdentifiersWrapper: {
    fontSize: 16,
    marginBottom: 10
  },
  transactionDetailsWrapper: {
    fontSize: 14,
    marginBottom: 10
  }
}));

function EditShippingTransaction({ shippingTransaction, className }) {
  const classes = useStyles();
  const [update, setUpdate] = useState(shippingTransaction);
  const [defaultTransactionState, setDefaultTransactionState] = useState(shippingTransaction);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateStatus, setUpdateStatus, statusMessage, setStatusMessage] = useStatus(null, 20);
  const [request, response] = useHttp('shipping-reconciliation', {
    headers: { Accept: 'application/json', timeout: 30 }
  });

  const handleInputChange = useCallback(({ target: { name, value } }) => {
    setUpdate(prevState => ({ ...prevState, [name]: value }));
  }, []);

  const handleReset = useCallback(() => {
    setUpdate(defaultTransactionState);
  }, [defaultTransactionState]);

  const sendUpdate = useCallback(
    async updateArg => {
      setIsSubmitting(true);
      await request.put(`/update`, {
        updates: {
          [updateArg.tracking_number]: {
            reported_billed_charge: updateArg.shipping_cost_billed,
            payment_to_apply: updateArg.shipping_cost_paid
          }
        }
      });
      if (response.ok) {
        setUpdateStatus(true);
        setStatusMessage(`Update successful! Batch ID: ${response.data.batchId}`);
      } else {
        setUpdateStatus(false);
        setStatusMessage(`Update failed : ${getHttpResponseErrorMessage(response)}`);
      }
      setIsSubmitting(false);
    },
    [request, response, setUpdateStatus, setStatusMessage]
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      if (!isSubmitting) {
        await sendUpdate(update);
        setDefaultTransactionState(update);
      }
    },
    [isSubmitting, update, sendUpdate]
  );

  return (
    <form onSubmit={handleSubmit} className={className}>
      <Card className={classes.formCard}>
        <CardContent>
          <Typography component="h3" className={classes.transactionIdentifiersWrapper}>
            Tracking Number: {shippingTransaction.tracking_number}
          </Typography>
          <Typography component="p" className={classes.transactionDetailsWrapper}>
            Shipping Quote: {shippingTransaction.shipping_quote}
          </Typography>
          <FormControl className={classes.formControl}>
            <TextField
              id="billed-input"
              type="number"
              name="shipping_cost_billed"
              label="Billed Amount"
              min="0"
              step="0.01"
              value={update.shipping_cost_billed || ''}
              onChange={handleInputChange}
              disabled={isSubmitting}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="paid-input"
              type="number"
              name="shipping_cost_paid"
              label="Paid Amount"
              min="0"
              step="0.01"
              value={update.shipping_cost_paid || ''}
              onChange={handleInputChange}
              disabled={isSubmitting}
            />
          </FormControl>
          {isSubmitting && <Typography component="p">Please wait...</Typography>}
          {updateStatus !== null &&
            (updateStatus ? (
              <Typography component="p" className={classes.successMessage}>
                {statusMessage}
              </Typography>
            ) : (
              <Typography component="p" className={classes.errorMessage}>
                {statusMessage}
              </Typography>
            ))}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            onClick={handleReset}
            className={classes.submitButton}
            disabled={isSubmitting}
          >
            Reset
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default EditShippingTransaction;
