import React, { useState, useEffect } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHttp } from '../../../common/Hooks';
import { useBrandList } from '../BrandSellThru/Hooks';
import IndividualYearOfBrandPurchasing from './IndividualYearOfBrandPurchasing';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

interface brandPurchaseObject {
  givenDates: string;
  prevYearDates: string;
  numOfDays?: number;
}

export default function BrandPurchasingReport() {
  const classes = useStyles();
  const today = moment().format('YYYY-MM-DD'); // format of the dates to be sent with request
  const brandList = useBrandList();
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [brandPurchaseData, setBrandPurchaseData] = useState({});
  const [request, response] = useHttp('brandPurchasingReport');
  const [selectedBrand, setSelectedBrand] = useState<OptionTypeBase | undefined>(undefined);
  const handleSelectedBrand = (option: OptionTypeBase) => {
    setSelectedBrand(option);
  };
  const [numOfDays, setNumOfDays] = useState<number>();

  async function generateReport() {
    if (selectedBrand !== undefined) {
      const data: brandPurchaseObject = await request.get(
        `?givenFromDate=${fromDate}&givenToDate=${toDate}&brand=${selectedBrand.value}`
      );
      if (response.ok) {
        setNumOfDays(data.numOfDays);
        delete data.numOfDays;
        setBrandPurchaseData(data);
      }
    }
  }

  return (
    <Paper className={classes.paper}>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Select
              options={Object.keys(brandList).map(brand => ({
                label: brand,
                value: brand
              }))}
              onChange={handleSelectedBrand}
              value={selectedBrand}
              id="brands"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Start Date:"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={fromDate}
              onChange={event => {
                setFromDate(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="End Date:"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={toDate}
              onChange={event => {
                setToDate(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="contained" onClick={generateReport}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {!isEmpty(brandPurchaseData) ? (
        <>
          <h1>Number of Days in Range: {numOfDays}</h1>
          {Object.entries(brandPurchaseData).map(([dateRange, yearPurchaseData]) => (
            <IndividualYearOfBrandPurchasing
              key={dateRange}
              dateRange={dateRange}
              yearPurchaseData={yearPurchaseData}
            />
          ))}
        </>
      ) : (
        <Typography style={{ fontWeight: 'bold', marginTop: 15 }}>Brand Not Selected, or No Data to Display</Typography>
      )}
    </Paper>
  );
}
