import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useInProgressAuditBatches } from './AuditBatchesContext';

function InProgressBatchRow(props) {
  const { audit_batch_name, audit_batch_id } = props;

  return (
    <TableRow>
      <TableCell>{audit_batch_name}</TableCell>
      <TableCell>
        <Button variant="contained">
          <Link to={`audit-scans/${audit_batch_id}`}>Continue Audit</Link>
        </Button>
      </TableCell>
      <TableCell>
        <Button variant="contained">
          <Link to={`/inventory/audit-discrepancy-report/${audit_batch_id}`}>View Report</Link>
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default function InProgressAuditBatchesContainer() {
  const [inProgressBatches] = useInProgressAuditBatches();
  return (
    <Paper style={{ padding: 25 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Audit Name</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {inProgressBatches.length > 0 &&
            inProgressBatches
              .reverse()
              .map(row => <InProgressBatchRow {...row} key={row.audit_batch_id} />)}
        </TableBody>
      </Table>
    </Paper>
  );
}
