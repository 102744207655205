import { TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { GlueTableColumn } from './GlueTable';
import GlueTableHeader from './GlueTableHeader';

export type GlueTableHeadersProps = {
  sortedColumns: GlueTableColumn[];
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
};

export default function GlueTableHeaders({
  sortedColumns,
  orderBy,
  sortDirection,
  onRequestSort
}: GlueTableHeadersProps) {
  const tableHeaders = sortedColumns.map((column: GlueTableColumn) => {
    return (
      <GlueTableHeader
        key={column.columnKey}
        column={column}
        orderBy={orderBy}
        sortDirection={sortDirection}
        onRequestSort={onRequestSort}
      />
    );
  });
  return (
    <TableHead>
      <TableRow>{tableHeaders}</TableRow>
    </TableHead>
  );
}
