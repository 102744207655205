/* eslint-disable react/destructuring-assignment */
import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, FormControl, Select, MenuItem, Tooltip, makeStyles } from '@material-ui/core';
import { useChangeLocation, locations } from './actions/locationActions';
import { wpos3Routes } from './Wpos3';
import { UserContext } from './roles/User';
import { check } from './roles/Can';
import { AuthContext } from './AuthContext';
import rules from './roles/rules';

// Theme will be used eventually, disabling for the next line.
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  },
  userRoleSelect: {
    color: '#fff'
  }
}));

function filterRoutes(name) {
  return wpos3Routes.filter(item => name === item.submenu);
}

const navLinks = () => [
  { title: 'Home', path: '/' },
  {
    title: 'Create',
    sub: filterRoutes('Create')
  },
  {
    title: 'Search',
    sub: filterRoutes('Search')
  },
  {
    title: 'Status Boards',
    sub: filterRoutes('Status Boards')
  },
  {
    title: 'Accounting',
    sub: filterRoutes('Accounting')
  },
  {
    title: 'Audit',
    sub: filterRoutes('Audit')
  },
  {
    title: 'Bin Management',
    sub: filterRoutes('Bin Management')
  }
];

const useColors = () => {
  const location = useSelector(state => state.location.location);

  const colors = ['#20B2BB', '#0f4D7B', '#4CB944', '#FF7800']; // ['ALL', 'AZ02', 'KY01', 'TX01'];
  const locationIndex = locations.findIndex(loc => location === loc);

  return colors[locationIndex];
};

const MenuLinks = props => {
  const color = useColors();
  return (
    <ul style={{ backgroundColor: color }} className={props.link ? 'submenu' : ''}>
      {props.links.map(link => {
        if (link.path) {
          return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
            <li key={link.title} onClick={props.link ? props.handleClick : null}>
              <span className="placeholder">
                <Link to={link.path}>{link.title}</Link>
              </span>
            </li>
          );
        }
        if (link.sub) {
          return (
            <li key={link.title}>
              <div
                onMouseEnter={() => props.handleHover(link.title)}
                onMouseLeave={props.handleClick}
                className={`placeholder ${link.title === props.openLink ? 'open' : ''}`}
              >
                {link.title}{' '}
                <MenuLinks links={link.sub} link={link} handleClick={props.handleClick} />
              </div>
            </li>
          );
        }
        return (
          <li key={link.name}>
            <span className="placeholder">{link.name}</span>
          </li>
        );
      })}
    </ul>
  );
};

const useLocationStyles = makeStyles({
  root: {
    color: 'white',
    padding: 16,
    height: 20
  }
});

const LocationSelect = () => {
  const changeLocation = useChangeLocation();
  const classes = useLocationStyles();
  const location = useSelector(state => state.location.location);

  return (
    <Select
      className={classes.root}
      SelectDisplayProps={{
        style: { minWidth: 120, paddingBottom: 0, borderColor: 'none' }
      }}
      value={location}
      onChange={({ target: { value } }) => changeLocation(value)}
    >
      {locations.map(location => (
        <MenuItem key={location} value={location}>
          {location}
        </MenuItem>
      ))}
    </Select>
  );
};

const Menu = () => {
  const user = useContext(UserContext);
  const auth = useContext(AuthContext);
  const [openLink, setOpenLink] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const handleHover = ul => setOpenLink(ul);
  const handleClick = () => setOpenLink(null);
  const classes = useStyles();
  let links;

  if (user && check(user.role, 'admin-menu:view')) {
    links = [
      ...navLinks(),
      {
        title: 'Admin',
        sub: filterRoutes('Admin')
      }
    ];
  } else {
    links = navLinks();
  }

  const filteredLinks = links.filter(linkSet => {
    if (linkSet.sub) {
      // TODO: fix this
      // eslint-disable-next-line no-param-reassign
      linkSet.sub = linkSet.sub.filter(subLink => {
        const checked = check(user.role, subLink.permission || 'dev-route:view');
        return checked;
      });
      return linkSet.sub.length > 0;
    }
    return true;
  });

  useEffect(() => {
    const isSuperUserLocalStorage = localStorage.getItem(`${auth.username}::is_super_user`) === '1';

    if (isSuperUserLocalStorage) {
      setIsSuperUser(true);
    }
  }, [auth.username]);

  return (
    <nav className="SiteMenu">
      <MenuLinks
        openLink={openLink}
        links={filteredLinks}
        handleClick={handleClick}
        handleHover={handleHover}
      />
      <LocationSelect />
      {isSuperUser && (
        <div className="placeholder">
          <FormControl className={classes.formControl}>
            <Select
              id="view-as-user-select"
              value={user.role}
              className={classes.userRoleSelect}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                  borderColor: 'none'
                }
              }}
              onChange={async event => {
                const changeResult = await user.sudoSetUserRole(event.target.value);
                if (changeResult) {
                  console.info(`Viewing as user: ${event.target.value}`);
                } else {
                  console.info('Failed to override user role: access denied.');
                }
              }}
            >
              {Object.keys(rules).map(role => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="placeholder">
        <Tooltip title="Logout">
          <a
            href="#0"
            onClick={e => {
              e.preventDefault();
              auth.setIsAuthorized();
            }}
          >
            {auth.username}
          </a>
        </Tooltip>
      </div>
    </nav>
  );
};

export default function Navigation() {
  const color = useColors();

  return (
    <AppBar style={{ background: color }}>
      <Menu />
    </AppBar>
  );
}
