import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useConditions } from '../../../common/Hooks';
import { AUTOGENERATED_LEGACY_BUCKET_MAPPINGS, Checkbox } from './generics';

const useStyles = makeStyles(theme => ({
  addNewForm: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'baseline'
  },
  button: {
    height: 30,
    marginLeft: theme.spacing(1)
  }
}));

function BucketCreationModal({ open, handleClose, form, handleSubmit }) {
  const classes = useStyles();
  const [generatedBuckets, setGeneratedBuckets] = useState([]);
  const [conditions] = useConditions();
  const [newBucket, setNewBucket] = useState({
    legacy_bucket: '',
    condition: '',
    is_local_inventory_bucket: true
  });

  const handleToggle = index => ({ target: { name } }) => {
    setGeneratedBuckets(previousBuckets => {
      const newBuckets = [...previousBuckets];
      newBuckets[index][name] = !newBuckets[index][name];
      return newBuckets;
    });
  };

  const handleDelete = index => {
    setGeneratedBuckets(previousBuckets => {
      previousBuckets.splice(index, 1);
      return [...previousBuckets];
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setNewBucket(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const addNewBucket = () => {
    setGeneratedBuckets(prevBuckets => [...prevBuckets, newBucket]);
    setNewBucket({
      legacy_bucket: '',
      condition: '',
      is_local_inventory_bucket: true
    });
  };

  useEffect(() => {
    const bucketMappings = AUTOGENERATED_LEGACY_BUCKET_MAPPINGS.map(([attachment, condition]) => {
      return {
        legacy_bucket: [form.location_code, attachment]
          .filter(a => a)
          .join('_')
          .toUpperCase(),
        condition,
        is_local_inventory_bucket: true
      };
    });
    setGeneratedBuckets(bucketMappings);
  }, [form.location_code]);

  return (
    <Dialog open={open} onClose={handleClose} closeAfterTransition>
      <DialogTitle>Generating the following legacy buckets</DialogTitle>
      <DialogContent>
        <List dense>
          {generatedBuckets.map(
            ({ legacy_bucket, condition, is_local_inventory_bucket }, index) => {
              return (
                <ListItem dense disableGutters key={legacy_bucket}>
                  <ListItemText primary={legacy_bucket} secondary={condition} />
                  <Checkbox
                    formState={{ is_local_inventory_bucket }}
                    name="is_local_inventory_bucket"
                    handleToggle={handleToggle(index)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      // Can't delete the first one
                      disabled={index === 0}
                      onClick={() => handleDelete(index)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          )}
        </List>
        <div className={classes.addNewForm}>
          <TextField
            margin="dense"
            size="small"
            label="Bucket Name"
            name="legacy_bucket"
            value={newBucket.legacy_bucket}
            onChange={handleChange}
          />
          <FormControl>
            <InputLabel>Condition</InputLabel>
            <Select
              style={{ minWidth: 200 }}
              label="Condition"
              name="condition"
              value={newBucket.condition}
              onChange={handleChange}
            >
              {conditions.map(condition => (
                <MenuItem key={condition} value={condition}>
                  {condition}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={classes.button}
            size="small"
            variant="outlined"
            onClick={addNewBucket}
            color="primary"
          >
            Add new
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => handleSubmit(generatedBuckets)}>
          Create Buckets
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BucketCreationModal;
