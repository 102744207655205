import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/styles';
import ShippingTemplateRulesRow from './ShippingTemplateRulesRow';

const useRuleDisplayStyles = makeStyles({
  tableHeader: {
    '& td': {
      fontWeight: '700'
    }
  },
  tablePaper: {
    padding: 25
  }
});

export default function ShippingTemplateRules(props) {
  const { shippingTemplateRules, shippingTemplateOptions } = props;
  const classes = useRuleDisplayStyles();

  return (
    <Paper className={classes.tablePaper}>
      <Table>
        <thead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Rule Code</TableCell>
            <TableCell>Fallback Shipping Template</TableCell>
            <TableCell>Super Composite Shipping Template</TableCell>
            <TableCell>Uses Compositions</TableCell>
            <TableCell>Submit Row Changes</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {shippingTemplateRules.map((row, index) => {
            return (
              <ShippingTemplateRulesRow
                ruleId={row.id}
                ruleCode={row.rule_code}
                shippingTemplateOptions={shippingTemplateOptions}
                fallbackShippingTemplate={row.fallback_shipping_template}
                groupShippingTemplate={row.group_shipping_template}
                usesTemplateCompositions={row.uses_template_compositions}
                key={row.rule_code}
              />
            );
          })}
        </tbody>
      </Table>
    </Paper>
  );
}
