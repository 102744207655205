import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogContent';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

type ConfirmationDialogProps = {
  open?: boolean;
  loading?: boolean;
  onClose: () => void;
  clearForm: () => void;
  postSettlementReport: () => void;
};

// TODO::073120 migrate loading to a loading handler
export default function ConfirmationDialog({ open, loading, onClose, clearForm, postSettlementReport }: ConfirmationDialogProps) {
  return (
    <Dialog onClose={onClose} open={!!open}>
      <DialogTitle>Confirm Upload</DialogTitle>
      <DialogContent>
        <DialogContentText>
          There was previously a file uploaded with the same name this session. Do you really want
          to upload this report?
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
              clearForm();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button disabled={loading} onClick={postSettlementReport} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
