import React, { useState } from 'react';
import Reports from './Reports';

export default function ReportsContainer() {
  const [filter, setFilter] = useState('');
  const handleChange = event => setFilter(event.target.value);
  const routes = [
    {
      title: 'Sales Reports',
      path: '/reports/sales',
      categories: ['Accounting']
    },
    {
      title:
        'Amazon FBA vs WPOS FBA Inventory Differences (Possible Amazon Inventory Bucket Issues)',
      path:
        '/reports/amazon-fba-vs-wpos-fba-inventory-differences-possible-amazon-inventory-bucket-issues',
      categories: ['Operations']
    },
    {
      title: 'Available FBA Inventory Not at Amazon',
      path: '/reports/available-fba-inventory-not-at-amazon',
      categories: ['Operations', 'Administrative']
    },
    {
      title: 'Available FBA Inventory Not at ONSITE',
      path: '/reports/available-fba-inventory-not-at-onsite',
      categories: ['Operations', 'Procurement']
    },
    {
      title: 'Finished Invoice Report Prior Year Comparison',
      path: '/reports/finished-invoice-report-prior-year-comparison',
      categories: [
        'Operations',
        'Procurement',
        'Procurement',
        'Accounting',
        'Accounting',
        'Installation',
        'Installation',
        'Administrative'
      ]
    },
    {
      title: 'Inventory On Hand (Walts Only) Report',
      path: '/reports/inventory-on-hand-walts-only-report',
      categories: ['Operations', 'Claims', 'Installation', 'Administrative']
    },
    {
      title: 'Marketplace AR Amazon Only',
      path: '/reports/marketplace-ar-amazon-only',
      categories: ['Operations', 'Accounting', 'Claims', 'Installation', 'Administrative']
    },
    {
      title: 'Standard AR (excludes, Claims, Marketplace)',
      path: '/reports/standard-ar-excludes-claims-marketplace',
      categories: ['Operations', 'Accounting', 'Claims', 'Installation', 'Administrative']
    },
    {
      title: 'Unpaid Claims AR (Pending Claims only)',
      path: '/reports/unpaid-claims-ar-pending-claims-only',
      categories: ['Operations', 'Accounting', 'Claims']
    },
    {
      title: 'Walts GE Inventory',
      path: '/reports/walts-ge-inventory',
      categories: ['Operations', 'Administrative']
    },
    {
      title: 'Walts Inventory On Hand By Brand ALL',
      path: '/reports/walts-inventory-on-hand-by-brand-all',
      categories: ['Operations', 'Administrative']
    },
    {
      title: 'Walts Inventory On Hand By Brand AMAZON FBA',
      path: '/reports/walts-inventory-on-hand-by-brand-amazon-fba',
      categories: ['Operations']
    },
    {
      title: 'Written Invoice Report Prior Year Comparison',
      path: '/reports/written-invoice-report-prior-year-comparison',
      categories: ['Operations', 'Installation', 'Administrative']
    },
    {
      title: 'Brand Sell Thru',
      path: '/reports/brand-sell-thru',
      categories: ['Procurement', 'Accounting', 'Administrative']
    },
    {
      title: 'Marketplace Sales Report',
      path: '/reports/marketplace-sales-report',
      categories: ['Procurement', 'Accounting', 'Administrative']
    },
    {
      title: 'Accounting Inventory Report',
      path: '/reports/accounting-inventory-report',
      categories: ['Pricing', 'Accounting', 'Administrative']
    },
    {
      title: 'Inventory On Hand Price Protection',
      path: '/reports/inventory-on-hand-price-protection',
      categories: ['Pricing', 'Accounting']
    },
    {
      title: 'Salesperson Report (Finished)',
      path: '/reports/salesperson-report-finished',
      categories: ['Pricing', 'Accounting', 'Installation']
    },
    {
      title: 'Current Accounting Inventory Balance',
      path: '/reports/current-accounting-inventory-balance',
      categories: ['Accounting', 'Administrative']
    },
    {
      title: 'Finished Duplicate and Missing Partner Order Report',
      path: '/reports/finished-duplicate-and-missing-partner-order-report',
      categories: ['Accounting']
    },
    {
      title: 'Marketplace AR (i.e. Jet, Rakuten .. Walmart',
      path: '/reports/marketplace-ar-ie-jet-rakuten--walmart',
      categories: ['Accounting', 'Claims', 'Installation', 'Administrative']
    },
    {
      title: 'Rebate Sell Thru',
      path: '/reports/rebate-sell-thru',
      categories: ['Accounting']
    },
    {
      title: 'Tax Report Export',
      path: '/reports/tax-report-export',
      categories: ['Accounting', 'Administrative']
    },
    {
      title: 'Warranty Sales Report (Finished)',
      path: '/reports/warranty-sales-report-finished',
      categories: ['Accounting']
    },
    {
      title: 'Calls Report',
      path: '/reports/calls-report',
      categories: ['Installation']
    },
    {
      title: 'Daily Sales',
      path: '/reports/daily-sales',
      categories: ['Installation', 'Administrative']
    },
    {
      title: 'Finished Tickets',
      path: '/reports/finished-tickets',
      categories: ['Installation']
    },
    {
      title: 'Inventory Report by Brand then model then bucket (Full Inventory Audit)',
      path: '/reports/inventory-report-by-brand-then-model-then-bucket-full-inventory-audit',
      categories: ['Installation', 'Administrative']
    },
    {
      title: 'Invoice Type Report',
      path: '/reports/invoice-type-report',
      categories: ['Installation']
    },
    {
      title: 'Labor Detail Report(Finished)',
      path: '/reports/labor-detail-reportfinished',
      categories: ['Installation']
    },
    {
      title: 'Open Invoice',
      path: '/reports/open-invoice',
      categories: ['Installation']
    },
    {
      title: 'Part Sales Report (Finished)',
      path: '/reports/part-sales-report-finished',
      categories: ['Installation']
    },
    {
      title: 'Serial Model Sales Report (Finished)',
      path: '/reports/serial-model-sales-report-finished',
      categories: ['Installation', 'Administrative']
    },
    {
      title: 'Buyers Sell Thru Report',
      path: '/reports/buyers-sell-thru-report',
      categories: ['Administrative']
    },
    {
      title: 'Buyers Stale Report',
      path: '/reports/buyers-stale-report',
      categories: ['Administrative']
    },
    {
      title: 'By Referal',
      path: '/reports/by-referal',
      categories: ['Administrative']
    },
    {
      title: 'Daily Sales recap volume unit',
      path: '/reports/daily-sales-recap-volume-unit',
      categories: ['Administrative']
    },
    {
      title: 'Downlaod GE Inventory Report',
      path: '/reports/downlaod-ge-inventory-report',
      categories: ['Administrative']
    },
    {
      title: 'Finished Service Invoice Report',
      path: '/reports/finished-service-invoice-report',
      categories: ['Administrative']
    },
    {
      title: 'Inventory Balance',
      path: '/reports/inventory-balance',
      categories: ['Administrative']
    },
    {
      title: 'Sales by Account',
      path: '/reports/sales-by-account',
      categories: ['Administrative']
    },
    {
      title: 'Sales by Contact',
      path: '/reports/sales-by-contact',
      categories: ['Administrative']
    },
    {
      title: 'Sales Person Report (Finished)',
      path: '/reports/sales-person-report-finished',
      categories: ['Administrative']
    },
    {
      title: 'Serial Velocity Report',
      path: '/reports/serial-velocity-report',
      categories: ['Administrative']
    },
    {
      title: 'Walts Inventory On Hand By Category WH Only',
      path: '/reports/walts-inventory-on-hand-by-category-wh-only',
      categories: ['Administrative']
    }
  ].filter(item => item.title.toLowerCase().includes(filter.toLowerCase()));

  const categories = [
    'Accounting',
    'Administrative',
    'Claims',
    'Installation',
    'Operations',
    'Procurement',
    'Pricing'
  ];
  const [activeCategories, setActiveCategories] = useState(categories);
  const handleActiveCategories = event => {
    if (activeCategories.includes(event.target.name)) {
      setActiveCategories(activeCategories.filter(item => item !== event.target.name));
    } else {
      setActiveCategories([...activeCategories, event.target.name]);
    }
  };
  const categoryRoutes = activeCategories
    .map(category => {
      const groupedRoutes = [];
      routes.forEach(route => {
        if (route.categories.includes(category)) {
          groupedRoutes.push(route);
        }
      });
      if (groupedRoutes.length > 0) {
        return {
          name: category,
          routes: groupedRoutes.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase())
        };
      }
      return null;
    })
    .filter(item => item !== null);
  return (
    <Reports
      categories={categories}
      categoryRoutes={categoryRoutes}
      filter={filter}
      handleChange={handleChange}
      activeCategories={activeCategories}
      handleActiveCategories={handleActiveCategories}
    />
  );
}
