import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment.js';
import { useHttp } from '../../common/Hooks';
import IndividualDailyReceived from './IndividualDailyReceived';

export default function TransactedReceiptReport() {
  const today = moment().format('YYYY-MM-DD'); // format of the dates to be sent with request

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [receivedList, setReceivedList] = useState({});
  const [request, response] = useHttp('ims/transacted-receipts/daily-received');

  async function getReceived() {
    const data = await request.get(`?startDate=${startDate}&endDate=${endDate}`);
    if (response.ok) {
      setReceivedList(data);
    }
  }

  useEffect(() => {
    getReceived();
  }, []);

  return (
    <>
      <form>
        <TextField
          label="Start Date:"
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          value={startDate}
          onChange={event => {
            setStartDate(event.target.value);
          }}
        />
        <TextField
          label="End Date:"
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          value={endDate}
          onChange={event => {
            setEndDate(event.target.value);
          }}
        />
        <Button variant="contained" onClick={getReceived}>
          Submit
        </Button>
      </form>
      {!isEmpty(receivedList) ? (
        <>
          {Object.entries(receivedList).map(([location, newObject]) => (
            <IndividualDailyReceived key={location} location={location} newObject={newObject} />
          ))}
        </>
      ) : (
        <Typography style={{ fontWeight: 'bold', marginTop: 15 }}>
          Nothing Received to AZ02, KY01, TX01 or Ruby Between These Dates
        </Typography>
      )}
    </>
  );
}
