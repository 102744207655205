import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from 'react-select';
import { makeStyles } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';
import { useToggle } from '../../Hooks';

const useOsposSelectStyles = makeStyles(theme => ({
  shrink: {
    '&$outlined': {
      transform: 'translate(1px, -13px) scale(0.75)'
    }
  },
  outlined: {
    transform: 'translate(8px, 15px) scale(1)'
  },
  label: {
    marginTop: theme.spacing(2)
  },
  helperText: {
    marginLeft: theme.spacing(0)
  }
}));

// Having props destructured in the parameters gives better intellisense, I would recommend doing this
// until we move to TypeScript.
function OsposSelect({
  isDisabled,
  inputValue,
  handleChange,
  label,
  options,
  name,
  error,
  helperText,
  ...rest
}) {
  const classes = useOsposSelectStyles();
  const [inputFocused, toggleInputFocused] = useToggle();

  const handleSelectChange = (value, { action }) => {
    if (['input-change', 'select-option'].includes(action)) {
      handleChange({ target: { name, value } });
    }
  };

  return (
    <FormControl className={classes.label} variant="outlined">
      <InputLabel
        classes={{ shrink: classes.shrink, outlined: classes.outlined }}
        disabled={isDisabled}
        focused={inputFocused}
        error={error}
        shrink={!!(inputFocused || inputValue)}
        variant="outlined"
      >
        {label}
      </InputLabel>
      <Select
        placeholder=""
        inputValue={inputValue}
        isDisabled={isDisabled}
        styles={{
          container: provided => ({ ...provided, minWidth: 250, display: 'inline-block' }),
          control: provided => ({
            ...provided,
            borderColor: error ? red[500] : provided.borderColor
          }),
          indicatorSeparator: provided => ({
            ...provided,
            backgroundColor: error ? red[500] : provided.backgroundColor
          }),
          menu: provided => ({ ...provided, zIndex: 2 })
        }}
        onFocus={toggleInputFocused}
        onBlur={toggleInputFocused}
        isClearable
        controlShouldRenderValue
        onInputChange={handleSelectChange}
        onChange={handleSelectChange}
        options={options}
        getOptionLabel={option => option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      {helperText ? (
        <FormHelperText className={classes.helperText} error={error} variant="outlined">
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default OsposSelect;
