import React from 'react';
import { isObject } from 'lodash';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import Results from '../../../common/LayoutComponents/ResultsView';
// import TableView from "../../../common/LayoutComponents/Table";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    if (splitStr[i].length <= 2) {
      if (splitStr[i] !== 'by' && splitStr[i] !== 'at' && splitStr[i] !== 'to') {
        splitStr[i] = splitStr[i].toUpperCase();
      }
    }
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

function humanReadable(string) {
  return titleCase(string.replace(/_/g, ' '));
}

function TransactionDetails(props) {
  return (
    <div>
      {Object.entries(props).map(([key, value]) => {
        if (!isObject(value)) {
          return (
            <p key={key}>
              {humanReadable(key)}: {value ? value.toString() : 'null'}
            </p>
          );
        }
        if (key === 'serials') {
          return (
            <div style={{ display: 'flex' }}>
              {value.map((serial, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      background: '#fafafa',
                      border: '1px solid #e6e6e6',
                      margin: 15,
                      padding: 15
                    }}
                  >
                    <h4>Serial</h4>
                    {Object.entries(serial).map(([key, value]) => {
                      return (
                        <p key={key}>
                          {humanReadable(key)}: {value}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );
}

function Transaction(props) {
  let message;
  let details;

  const { transaction_type } = props.transaction;

  switch (transaction_type) {
    case 'INVENTORY_RECEIPT':
      details = props.transaction.transacted_receipt;
      message = `Received ${details.received_quantity} onto PO #${details.po_api_po_id} into ${details.receiving_location_code}`;
      break;
    case 'INVENTORY_TRANSFER':
      details = props.transaction.transacted_transfer;
      message = `Transferred ${details.transferred_quantity} from ${
        details.from_location_code
      } to ${details.to_location_code} referencing ${details.pos_transfer_batch_ref_id ||
        details.pos_transfer_batch_name} and ${details.notes || 'no notes'}`;
      break;
    case 'INVENTORY_SALE':
      details = props.transaction.transacted_sale;
      message = `Sold ${details.invoiced_quantity} sourced from ${details.source_location_code} for invoice #${details.legacy_pos_invoice_id}`;
      break;
    default:
      break;
  }
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <p>{message}</p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <TransactionDetails {...details} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function EventHistory(props) {
  const { events_date, simulated_state, events } = props;
  const date = new Date(events_date);
  return (
    <div style={{ padding: 15, border: '1px solid #e6e6e6', marginBottom: 15 }}>
      <p>{date.toLocaleDateString()}</p>
      <div style={{ width: '100%' }}>
        <h3>Events</h3>
        {events.map((event, key) => (
          <Transaction key={key} {...event} />
        ))}
        <h3>Simulated State</h3>
        <div>
          {Object.entries(simulated_state).map(([key, value]) => (
            <p key={key}>
              <strong>{key}:</strong> {value}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default function InventorySimulation(props) {
  const { data } = props;
  return (
    <div>
      <Results>
        <div>
          <img src={data.product.image_url} />
        </div>
        <div style={{ maxWidth: 500 }}>
          <h3>{data.product.product_title}</h3>
          <p>Model: {data.product.model}</p>
          <p>MPN: {data.product.mpn}</p>
          <p>UPC: {data.product.upc}</p>
        </div>
      </Results>
      {data.history.map((historyItem, key) => (
        <EventHistory key={key} {...historyItem} />
      ))}
    </div>
  );
}
