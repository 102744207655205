import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DownloadReport from './DownloadReport';
// import { useDownloadWorker } from '../../common/Hooks';

// NOTE: Commented out code in this module is for an additional report
// download which is not presently useful to stakeholders and is currently
// misleading. We will be revisiting this feature in the future.

function InventoryAccountingReport() {
  const currentDate = moment().format('YYYY-MM-DD');
  const inventoryAccountingReportBaseUrl = '/ims/reporting/inventory/accounting';
  const [dateInput, setDateInput] = useState<string>(currentDate);

  const [inventoryAccountingFileName, setInventoryAccountingFileName] = useState<string>('');
  // const [totalsSummaryFileName, setTotalsSummaryFileName] = useState<string>('');

  const [inventoryAccountingUrl, setInventoryAccountingUrl] = useState<string>(
    inventoryAccountingReportBaseUrl
  );

  useEffect(() => {
    setInventoryAccountingFileName(`${dateInput}-inv_accounting_report`);
    // setTotalsSummaryFileName(`${dateInput}-inv_accounting_totals`);
  }, [dateInput]);

  // const [sendAccountingTotalsUrlToWorker] = useDownloadWorker({
  //   reportName: totalsSummaryFileName
  // });

  const onDateInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setDateInput(value);
  };

  const handleSubmit = () => {
    // sendAccountingTotalsUrlToWorker(`${inventoryAccountingReportBaseUrl}/totals?date=${dateInput}`);
    setInventoryAccountingUrl(previousUrl => `${previousUrl}?date=${dateInput}`);
  };

  return (
    <DownloadReport
      url={inventoryAccountingUrl}
      name={inventoryAccountingFileName}
      hasOptions={true}
      submit={handleSubmit}
    >
      <TextField
        InputLabelProps={{
          shrink: true
        }}
        onChange={onDateInputChange}
        label="Date Of Report"
        type="date"
        value={dateInput}
      />
    </DownloadReport>
  );
}

export default InventoryAccountingReport;
