import React, { useState } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHttp } from '../../common/Hooks';
import GlueButton from '../Presentational/GlueButton.tsx';

const defaultErrorState = {
  status: null,
  data: null
};

export default function PeakDaysFulfillmentReport() {
  const currentDate = moment().format('YYYY-MM-DD');
  const [request, response] = useHttp('report/peak-days-fulfillment');
  const [date, setDate] = useState(currentDate);
  const [disabled, setDisabled] = useState(false);
  const [errorState, setErrorState] = useState(defaultErrorState);

  const handleDateChange = ({ target: { value } }) => {
    setDate(value);
    setDisabled(false);
    setErrorState(defaultErrorState);
  };

  const getReports = async () => {
    setDisabled(true);
    const now = moment().format('YYYY-MM-DDTh-mm-ss');
    await request.get(`/${date}`);
    if (response.ok) {
      const data = await response.blob();
      saveAs(data, `peak-days-fulfillment-report_for_${date}_run_${now}.csv`);
    } else {
      setErrorState({
        status: response.status,
        data: response.data
      });
    }
  };

  return (
    <>
      <div
        style={{
          margin: '1em',
          maxWidth: '350px',
          display: 'flex',
          alignItems: 'middle'
        }}
      >
        <TextField
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: currentDate
          }}
          onChange={handleDateChange}
          label="Date"
          type="date"
          value={date}
          style={{ flex: 1 }}
        />
        <GlueButton
          variant="contained"
          color="primary"
          onClick={getReports}
          style={{ flex: 1, marginLeft: '2em' }}
          disabled={disabled}
        >
          Download Report
        </GlueButton>
      </div>
      <div>
        {errorState.status ? (
          <Alert severity="error" style={{ margin: 10 }}>
            {errorState.data.message}
          </Alert>
        ) : null}
      </div>
    </>
  );
}
