const initialState = {
  loading: false,
  message: ''
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.payload.loading,
        message: action.payload.message
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
