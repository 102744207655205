import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1, 0)
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: theme.spacing(1, 0),
    marginBottom: theme.spacing(1)
  }
}));

export default function InventorySerialSearch({ result }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} key={result.serial_number}>
      <CardContent className={classes.gridContainer}>
        <Typography>
          Serial:{' '}
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {result.serial_number}
          </Typography>
        </Typography>
        <Typography>Model: {result.product.model}</Typography>
        <Typography>Location: {result.location_code}</Typography>
        <Typography>Condition: {result.condition_code}</Typography>
        <Typography>Disposition: {result.disposition_code}</Typography>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          to={{
            pathname: `/inventory-serials/${result.id}`,
            state: { clearState: true }
          }}
          size="small"
          style={{ color: 'inherit' }}
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
}
