import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import isEmpty from 'lodash/isEmpty';
import { useMessageDispatch, useHttp } from '../../common/Hooks';
import CollapseAlert from './components/CollapseAlert';
import RowCheck from './components/RowCheck';
import TableView from '../../common/LayoutComponents/Table';
import ConfirmationDialog from './components/ConfirmationDialog';
import stringToJson, { CheckListItem, JsonRow, StringToJson } from './stringToJson';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: `0 ${theme.spacing(0.5)}px`
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    card: {
      marginBottom: theme.spacing(5)
    },
    cardContent: {
      display: 'flex'
    },
    typography: {
      flex: 1,
      display: 'inline-block',
      alignSelf: 'flex-end',
      textAlign: 'right'
    }
  })
);

const clearEmptyRows = (result: string): string => {
  // remove zero width spaces (ebay)
  const noZeroSpacesResult = result.replace(/[\u200B-\u200D\uFEFF]/g, '');

  // remove empty rows
  // TODO:: regex for this inadvertantly removed rows from Walmart
  // return noZeroSpacesResult.replace(/\n(,+[\n\r][\r\n]?)/g, (...args) => '');
  return noZeroSpacesResult;
};

const createReaderFromFile = (file: File, startRow?: number): Promise<StringToJson> => {
  // Promises have implicit try…catch if an error is thrown from a promise it's treated as reject
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = function onLoad() {
      const result = this.result as string;
      if (!result) {
        throw new Error('No File found from reader');
      }

      try {
        const resolution = stringToJson(clearEmptyRows(result), startRow);
        resolve(resolution);
      } catch (error) {
        console.log({ error });
        let fileName = file.name;
        if (fileName && fileName.length > 13) {
          fileName = `${fileName.substr(0, 5)}...${fileName.substr(-6, fileName.length)}`;
        }
        throw new Error(
          `${error.message || "Couldn't parse file"}${fileName ? ` on ${fileName}` : ''}.`
        );
      }
    };

    reader.readAsText(file);
  });
};

// TODO: The UI starts decaying at phone screen size. Consider alternatives.
function SettlementReportUpload() {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [table, setTable] = useState<JsonRow[]>([]);
  const [checkList, setCheckList] = useState<CheckListItem[]>([]);
  const headers = useMemo(() => (table.length ? Object.keys(table[0]) : null), [table]);
  const [request, response] = useHttp('om/payments/settlement-report', {
    headers: {
      'Sec-Fetch-Site': 'cross-site',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  const [success, setSuccess] = useState<{ message?: string }>({});
  const [errorDispatch] = useMessageDispatch();
  const [startRowIndex, setStartRowIndex] = useState<number | null>(null);
  const isSuccess = !isEmpty(success);

  const [uploadedFilenames, setUploadedFilenames] = useState<string[]>([]);
  const [filename, setFilename] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const shouldConfirm = uploadedFilenames.includes(filename);

  const postSettlementReport = async () => {
    setUploadedFilenames(filenames => [...filenames, filename]);
    const res = await request.post('', { table: JSON.stringify(table) });
    if (response.ok) {
      setUploadedFilenames(filenames => [...filenames, filename]);
      setSuccess(res.data);
    }
    if (openConfirmationDialog) {
      setOpenConfirmationDialog(false);
    }
  };

  const getSubmitFunction = shouldConfirm
    ? () => setOpenConfirmationDialog(true)
    : postSettlementReport;

  const clearForm = useCallback(() => {
    if (inputRef.current) inputRef.current.value = '';
    setSuccess({});
    setTable([]);
  }, []);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStartRowIndex(null);
      async function getFile(file: File) {
        try {
          const fileResults = await createReaderFromFile(file);
          if (fileResults === undefined) {
            throw new Error('Nothing returned from reader.');
          }
          setCheckList(fileResults.checkList);
          setTable(fileResults.table);
          setFilename(file.name ?? '');
        } catch (error) {
          setTable([]);
          errorDispatch({
            type: 'errors',
            message: error.message,
            status: 'Unknown Error'
          });
        }
      }

      const { files } = event.currentTarget;

      if (files?.length) {
        getFile(files[0]);
      }
    },
    [errorDispatch]
  );

  useEffect(() => {
    async function getFile(file: File) {
      try {
        const fileResults = await createReaderFromFile(file, startRowIndex || undefined);
        if (fileResults === undefined) {
          throw new Error('Nothing returned from reader.');
        }
        setCheckList(fileResults.checkList);
        setTable(fileResults.table);
        setFilename(file.name ?? '');
      } catch (error) {
        errorDispatch({
          type: 'errors',
          message: error.message,
          status: 'Unknown Error'
        });
      }
    }

    if (inputRef.current?.files?.length) {
      setTable([]);
      getFile(inputRef.current.files[0]);
    }
  }, [inputRef.current, startRowIndex]);

  // If `table` remains unchanged clear `success` and `table`
  // when the timeout finishes. If `table` does change
  // cancel the timeout and immediately clear `success`.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccess) {
      timeout = setTimeout(clearForm, 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setSuccess({});
      }
    };
  }, [isSuccess, table]);

  const removeRowFromTable = useCallback((index: number) => {
    setTable(previousTable => previousTable.filter(row => row.rowIndex !== index));
    setCheckList(previousCheckList => previousCheckList.filter(item => item.rowIndex !== index));
  }, []);

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <input
            ref={inputRef}
            onChange={handleFileUpload}
            accept=".csv,.tsv,.txt"
            type="file"
            hidden
          />
          <Button
            className={classes.button}
            onClick={() => inputRef.current?.click()}
            variant="contained"
            size="large"
            color="primary"
          >
            <FileIcon className={classes.icon} /> Upload a file
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            disabled={!table.length || isSuccess || request.loading}
            onClick={getSubmitFunction}
          >
            Submit
          </Button>
          <Typography className={classes.typography} noWrap>
            {filename}
          </Typography>
        </CardContent>
        <CollapseAlert open={isSuccess}>{success?.message}</CollapseAlert>
        {false ? ( // todo this has some styling work to be done
          <FormControlLabel
            label="Select a new starting input row"
            labelPlacement="top"
            control={
              <Select
                variant="outlined"
                autoWidth
                onChange={e => setStartRowIndex(e.target.value as number)}
                value={startRowIndex || '0'}
              >
                {Array.from({ length: table.length }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            }
          ></FormControlLabel>
        ) : null}
        {checkList.length ? (
          <RowCheck checkList={checkList} removeRowFromTable={removeRowFromTable} />
        ) : null}
        {table.length ? (
          <TableView maxHeight="50vh" tableProps={{ stickyHeader: true }} header={headers || []}>
            {table.map((row, index) => (
              <TableRow key={index}>
                {headers?.map(key => (
                  <TableCell key={`${index}-${key}`}>{row[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableView>
        ) : null}
      </Card>
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        clearForm={clearForm}
        postSettlementReport={postSettlementReport}
      />
    </>
  );
}

export default SettlementReportUpload;
