import React, { useContext, useMemo } from 'react';
import { Grid, Checkbox, createStyles, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import GlueTooltip from '../../Presentational/GlueTooltip';
import { PickListContext } from './PickListContext';
import { LIST_TYPE_ENUM, PickableItem } from './PickListTypes';
import { uniq } from '../../../helpers/helpers'
import { GlueTheme } from '../../../GlueThemeType';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    infoIcon: {
      color: theme.palette.grey[600]
    }
  })
);

export type PrintColumnHeaderProps = {
  start: number;
  end: number;
  printVersion?: boolean;
};

export default function PrintColumnHeader({
  start,
  end,
  printVersion,
}: PrintColumnHeaderProps) {
  const classes = useStyles();

  const {
    queryParams,
    pickList,
    itemsToPrint,
    setItemsToPrint,
    loadingPickList,
    pickListErrors
  } = useContext(PickListContext);
  const isSelectable = (n: PickableItem) => !(!!n.printedAt || !!n.warning);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = pickList
      .slice(start, end)
      .filter(isSelectable)
      .map((n: PickableItem) => n.transactionReferenceId);
    if (event.target.checked) {
        if (itemsToPrint.length > 0 ) {
          const firstRowIndexOfSelected = pickList
            .map(n => n.transactionReferenceId)
            .indexOf(itemsToPrint[0]);
        if (firstRowIndexOfSelected > start) {
          const newNewSelected = uniq([...newSelecteds, ...itemsToPrint]);
          setItemsToPrint(newNewSelected);
        } else {
          const newNewSelected = uniq([...itemsToPrint, ...newSelecteds]);
          setItemsToPrint(newNewSelected);
        }

      } else {
        setItemsToPrint(newSelecteds);
      }
      return;
    }
    const filteredSelected = itemsToPrint.filter(transactionReferenceId => newSelecteds.indexOf(transactionReferenceId) < 0);
    setItemsToPrint(filteredSelected);
  };
  const numSelected = itemsToPrint.length;
  const isSelected = (transactionReferenceId: number) => itemsToPrint.indexOf(transactionReferenceId) !== -1;
  const areAllRowsOnCurrentPageSelected = useMemo(() => {
    return pickList
    .reduce((acc: boolean, pickItem: PickableItem, i) => {
      const isCheckted = isSelected(pickItem.transactionReferenceId);
      const isCheckable = isSelectable(pickItem);
      if (i >= start && i < end && isCheckable && !isCheckted) {
        acc = false;
      }
      return acc;
    }, numSelected > 0 && !loadingPickList)
  }, [pickList, itemsToPrint, start, end])

  let printColumHeader = (
    <div>
      Print
      <Checkbox
        indeterminate={numSelected > 0 && !areAllRowsOnCurrentPageSelected}
        disabled={loadingPickList || !isEmpty(pickListErrors)}
        checked={areAllRowsOnCurrentPageSelected}
        onChange={handleSelectAllClick}
        inputProps={{ 'aria-label': 'select all pickable items to print' }}
      />
    </div>
  );

  if (queryParams.listType === LIST_TYPE_ENUM.PRINTED) {
    printColumHeader = (
      <Grid
        container
        justify={'space-around'}
        alignItems={'center'}
      >
        Unprint
        <GlueTooltip
          title={'Printed items cannot be reprinted. '
            + 'If you need to print again, please \'unprint\' '
            + 'the item first to void the old printed barcode.'}
          aria-label={'unprint-info'}
        >
          <InfoIcon
            fontSize={'small'}
            className={classes.infoIcon}
          />
        </GlueTooltip>
      </Grid>
    )
  }

  if (printVersion) {
    printColumHeader = (
      <div>Pick Barcode</div>
    )
  }
  
    return (
      <div>
        {printColumHeader}
      </div>
    );
  }
