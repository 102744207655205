import React, { useState, useContext } from 'react';
import Select from 'react-select';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { useHttp } from '../../common/Hooks';
import { ShippingTemplateContext } from '../shippingTemplate/shippingTemplateContext';
import AcivateRuleDialogCell from './AcivateRuleDialogCell';

export default function ShippingTemplateRulesRow(props) {
  const {
    ruleId,
    ruleCode,
    shippingTemplateOptions,
    fallbackShippingTemplate,
    groupShippingTemplate,
    usesTemplateCompositions
  } = props;

  const [fallbackShippingTemplateState, setFallbackShippingTemplateState] = useState(
    fallbackShippingTemplate
  );
  const [groupShippingTemplateState, setGroupShippingTemplateState] = useState(
    groupShippingTemplate
  );
  const [success, setSuccess] = useState(false);

  const { getRules } = useContext(ShippingTemplateContext);

  const [request, response] = useHttp('ims/shipping-templates/rule');
  async function handleUpdateShippingTemplateRule() {
    const sendup = {
      ruleCode,
      fallbackShippingTemplate: fallbackShippingTemplateState,
      groupShippingTemplate: groupShippingTemplateState
    };
    await request.put('', sendup);
    if (response.ok) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    getRules();
  }

  function handleUpdateFallbackShippingTemplate(event) {
    setFallbackShippingTemplateState(event.value);
  }

  function handleUpdateGroupShippingTemplate(event) {
    setGroupShippingTemplateState(event.value);
  }

  return (
    <TableRow key={ruleCode}>
      <TableCell>{ruleCode}</TableCell>
      <TableCell>
        <Select
          name="fallbackShippingTemplate"
          options={shippingTemplateOptions}
          defaultValue={{
            label: fallbackShippingTemplateState,
            value: fallbackShippingTemplateState
          }}
          onChange={handleUpdateFallbackShippingTemplate}
        />
      </TableCell>
      <TableCell>
        <Select
          name="groupShippingTemplate"
          options={shippingTemplateOptions}
          defaultValue={{ label: groupShippingTemplateState, value: groupShippingTemplateState }}
          onChange={handleUpdateGroupShippingTemplate}
        />
      </TableCell>
      <AcivateRuleDialogCell
        usesTemplateCompositions={usesTemplateCompositions}
        ruleCode={ruleCode}
        ruleId={ruleId}
      />
      <TableCell>
        <Button
          type="submit"
          variant="contained"
          onClick={handleUpdateShippingTemplateRule}
          style={{ background: success ? 'lightGreen' : 'lightGray' }}
        >
          Submit
        </Button>
      </TableCell>
    </TableRow>
  );
}
