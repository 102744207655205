import React, { useEffect, useState } from 'react';
import TransactedTransfers from './TransactedTransfers';
import { useHttp } from '../../common/Hooks';

export default function TransactedTransfersContainer({ modelNumber }) {
  const [transfers, setTransfers] = useState([]);
  const [open, setOpen] = useState(false);

  const [request, response] = useHttp('ims/transacted-transfers');

  async function updateTransferNotes(event) {
    event.preventDefault();
    const { elements } = event.target;
    const postVars = {};
    postVars.transfer_notes = elements.transfer_notes.value;
    postVars.id = elements.transfer_id.value;

    await request.post('/updateTransferNotes', postVars);
    if (response.ok) {
      const submitForm = document.getElementById(postVars.id);
      submitForm.submitBtn.disabled = true;
    }
  }

  useEffect(() => {
    async function getTransactedTransfers() {
      const params = new URLSearchParams();
      if (modelNumber) {
        params.append('model', modelNumber);
      }
      const transferData = await request.get(`?${params.toString()}`);
      if (response.ok) {
        setTransfers(transferData);
      }
    }
    if (open) {
      getTransactedTransfers();
    }
  }, [open, modelNumber]);

  return (
    <TransactedTransfers
      transfers={transfers}
      updateTransferNotes={updateTransferNotes}
      open={open}
      handleOpen={() => setOpen(() => !open)}
    />
  );
}
