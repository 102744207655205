import React, { createContext, Context } from 'react';
import {
  ScanEventListeningContextType,
  ScanEventListeningProviderProps
} from './ScanEventListeningTypes';
import { useScanEventBus } from './ScanEventListeningHooks';

const defaultContextValue: ScanEventListeningContextType = {
  scanEventBus: null
};

export const ScanEventListeningContext: Context<ScanEventListeningContextType> = createContext(
  defaultContextValue
);

export function ScanEventListeningProvider({ children }: ScanEventListeningProviderProps) {
  const scanEventBus = useScanEventBus();

  return (
    <ScanEventListeningContext.Provider value={{ scanEventBus }}>
      {children}
    </ScanEventListeningContext.Provider>
  );
}
