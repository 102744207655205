import { apiOspos } from '../config/config';

// export function login(userName, userPassword) {
//    return (dispatch, getState) => {
//        var myFormData = new FormData();
//        myFormData.append('grant_type',apiOspos.grant_type );
//        myFormData.append('username',userName);
//        myFormData.append('password',userPassword);
//        myFormData.append('client_id',apiOspos.client_id);
//        myFormData.append('client_secret',apiOspos.client_secret);
//        return fetch('https://test-api-ospos.walts.com/auth/google', {
//        method: 'POST',
//        headers: {
//           'Accept': 'application/json'
//         },
//        body: myFormData
//      })
//      .then((response) => response.json())
//      .then((responseData) => {
//         if(responseData.error){
//             dispatch({type: "LOGIN_REJECTED", payload: responseData})
//         }else{
//             dispatch({type: "LOGIN_FULFILLED", payload: responseData})
//         }
//     })
//   };
// };

// export function loginGoogle(paramData) {
//   return dispatch => {
//     // TODO: Update from axios to fetch
//     axios.defaults.headers.common = {
//       'X-Requested-With': 'XMLHttpRequest'
//     };
//     const sendData = {
//       email: paramData.profile.email,
//       google_id: paramData.profile.googleId,
//       g_token: paramData.accessToken,
//       g_refresh: paramData.accessToken2, // Matches google token in the DB
//       g_name: paramData.profile.name
//     };
//     axios
//       .post(`${apiOspos.baseUrl}/auth/google/`, sendData)
//       .then(response => {
//         dispatch({
//           type: 'LOGIN_FULFILLED',
//           payload: response,
//           userInfo: paramData
//         });
//       })
//       .catch(response => {
//         dispatch({ type: 'LOGIN_REJECTED', payload: response });
//       });
//   };
// }

export function login(payload) {
  return dispatch => {
    dispatch({
      type: 'LOGIN_FULFILLED',
      payload
    });
  };
}

export function testLoginGoogle(paramData) {
  var sendData = {
    email: paramData.profile.email,
    google_id: paramData.profile.googleId,
    g_token: paramData.accessToken,
    g_refresh: paramData.accessToken2, // Matches google token in the DB
    g_name: paramData.profile.name
  };
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/auth/google/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(sendData)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({ type: 'LOGIN_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'LOGIN_FULFILLED',
            payload: responseData,
            userInfo: paramData
          });
        }
      })
      .catch(responseData => {
        dispatch({ type: 'LOGIN_REJECTED', payload: responseData });
      });
  };
}

export function storeRefresh(accessToken, token, authorized, name, imageUrl) {
  return {
    type: 'LOGIN_REFRESH',
    payload: {
      accessToken,
      token,
      name,
      imageUrl,
      authorized
    }
  };
}

export function storeReset() {
  return dispatch => {
    return dispatch({ type: 'STORE_RESET', payload: '' });
  };
}

export function changeUsername() {
  return dispatch => {
    return dispatch({ type: 'USERNAME_CHANGE', payload: '' });
  };
}

export function userLogout() {
  return dispatch => {
    return dispatch({ type: 'LOGOUT', payload: '' });
  };
}
