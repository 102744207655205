import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles({
  formControl: {
    top: '-16px',
    height: '36px'
  }
});

export interface GlueSearchBarProps extends StandardTextFieldProps {}

export default function GlueSearchBar(props: GlueSearchBarProps) {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Search />
          </Grid>
          <Grid item>
            <TextField
              {...props}
              margin={'dense'}
              size={'small'}
              id="search-input-for-bin-locations"
              label="Search"
            />
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
}
