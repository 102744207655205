import React, { useContext, useEffect } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import GlueSelect from '../../Presentational/GlueSelect';
import { ALLOWED_BIN_CONDITIONS, BinCondition } from './CreateBinTypes';
import { BinManagementContext } from '../BinManagementContext';
import { GlueTheme } from '../../../GlueThemeType';
import { get } from 'lodash';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    label: {
      fontWeight: 500,
      color: theme.palette.grey[900],
      letterSpacing: '0px'
    }
  })
);

export default function BinConditionSelect() {
  const classes = useStyles();

  const { createBinErrors, setCreateBinErrors, setCreateBinData, createBinData } = useContext(
    BinManagementContext
  );

  useEffect(() => {
    const { conditionCode, ...other } = createBinErrors;
    setCreateBinErrors({
      ...other
    });

  }, [createBinData.conditionCode])

  const conditionOptions = ALLOWED_BIN_CONDITIONS.map((condition: BinCondition) => {
    return {
      key: condition.value,
      value: condition.value,
      name: condition.name
    };
  });

  const handleConditionChange = (event: any) => {
    let newConditionCode = '';
    if (typeof event.target.value === 'string') {
      newConditionCode = event.target.value;
    }
    setCreateBinData({
      ...createBinData,
      conditionCode: newConditionCode
    });
  };

  return (
    <Grid container xs={12} spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.label} variant={'body1'}>
          Bin Condition:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <GlueSelect
          required
          options={conditionOptions}
          accessibleLabelId={'bin-condition-select'}
          label={'Bin Condition'}
          handleChange={handleConditionChange}
          value={createBinData.conditionCode}
          error={!!createBinErrors.conditionCode}
          helperText={
            get(createBinErrors, 'conditionCode', []).join('') || 'Select Bin Condition Code'
          }
        />
      </Grid>
    </Grid>
  );
}
