import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment/moment.js';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useHttp } from '../../common/Hooks';
import Select from '../../common/LayoutComponents/Select';
import ShippingReconciliationDashboard from './ShippingReconciliationDashboard';

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'inline flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
    minWidth: 100
  },
  paper: {
    maxWidth: 400,
    minHeight: '35vh'
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto'
  }
}));

const useShipCompany = () => {
  const [shipCompanyList, setShipCompanyList] = useState([]);
  const [request, response] = useHttp('shipping-reconciliation');

  useEffect(() => {
    async function getShipCompanyList() {
      const data = await request.get(`/shipping-companies`);
      if (response.ok) {
        const options = data.map(({ ship_company }) => ship_company);
        setShipCompanyList(options);
      }
    }
    getShipCompanyList();
  }, []);

  return shipCompanyList;
};

export default function ShippingTransactionsRequestForm() {
  const [shippingTransactions, setShippingTransactions] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  const monthAgo = moment()
    .subtract(1, 'months')
    .format('YYYY-MM-DD'); // format of the dates to be sent with request
  const today = moment().format('YYYY-MM-DD');

  const shipCompanyList = useShipCompany();
  const baseUrl = 'shipping-reconciliation';
  const [inputState, setInputState] = useState({
    startDate: monthAgo,
    endDate: today,
    shippingCompany: '',
    fulfillmentWarehouse: '',
    shipCompanyList
  });
  const [request, response] = useHttp(baseUrl, {
    headers: { Accept: 'application/json' }
  });

  const handleChange = ({ target: { name, value } }) => {
    setInputState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmitRequest = useCallback(() => {
    async function submitRequest() {
      await request.get(
        `/generic?from=${inputState.startDate}` +
          `&to=${inputState.endDate}` +
          `&fulfillmentWarehouse=${inputState.fulfillmentWarehouse}` +
          `&shippingCompany=${inputState.shippingCompany}`
      );
      if (response.ok) {
        setShippingTransactions(response.data);
      } else {
        console.log('response.status', response.status);
        console.log('response.data', response.data);
      }
      setIsSubmitting(false);
    }
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitRequest();
    }
  }, [request, response, inputState, isSubmitting]);

  return (
    <>
      {!isEmpty(shippingTransactions) ? (
        <ShippingReconciliationDashboard shippingTransactions={shippingTransactions} />
      ) : (
        <Card component="form" className={classes.paper}>
          <CardContent>
            <TextField
              className={classes.formControl}
              label="Start Date:"
              name="startDate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={inputState.startDate}
              onChange={handleChange}
            />
            <TextField
              className={classes.formControl}
              label="End Date:"
              name="endDate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={inputState.endDate}
              onChange={handleChange}
            />
            <Select
              className={classes.formControl}
              label="Shipping Company"
              name="shippingCompany"
              inputValue={inputState.shippingCompany}
              options={shipCompanyList}
              handleChange={handleChange}
            />
            <Select
              className={classes.formControl}
              label="Fulfillment Warehouse"
              name="fulfillmentWarehouse"
              inputValue={inputState.fulfillmentWarehouse}
              options={['AZ02', 'KY01', 'TX01', 'WALTS']}
              handleChange={handleChange}
            />
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={history.goBack} className={classes.submitButton}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handleSubmitRequest}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Please wait...' : 'Submit'}
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}
