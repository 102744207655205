const initialState = {
  activeQuote: {},
  activeQuoteItemLines: [],
  quote: '',
  quotesSearched: [],
  contactQuotesSearched: [],
  quotesSearchedFulfilled: false,
  convertToOrderFulfilled: '',
  quoteUpdateFulfilled: false,
  quoteDetailsFetched: false,
  createNewQuote: false,
  addItemToQuote: false,
  quoteItemDetailsFetched: false,
  updateQuoteItemFulfilled: false,
  quoteInfoMessage: '',
  quoteItems: [],
  quoteItemUpdateError: '',
  activeQuoteLaborLines: [],
  newWorkOrder: ''
};

export default function quotes(state = initialState, action) {
  switch (action.type) {
    case 'RESET_MESSAGES':
      return {
        ...state,
        quoteInfoMessage: ''
      };
    case 'CREATE_NEW_QUOTE':
      return {
        ...state,
        createNewQuote: true
      };
    case 'GET_FULL_QUOTE_FULFILLED':
      return {
        ...state,
        quote: action.payload.data.body,
        quoteItems: action.payload.data.lines,
        quoteDetailsFetched: true,
        activeQuote: action.payload.data.body,
        activeQuoteItemLines: action.payload.data.lines,
        activeQuoteLaborLines: action.payload.data.laborLines
      };
    case 'GET_FULL_QUOTE_REJECTED':
      return {
        ...state,
        quote: action.payload,
        quoteDetailsFetched: false
      };
    case 'CREATE_QUOTE_FULFILLED':
      return {
        ...state,
        quote: action.payload,
        quoteFulfilled: true,
        createNewQuote: false,
        quoteInfoMessage: 'New Quote Created!'
      };
    case 'CREATE_QUOTE_REJECTED':
      return {
        ...state,
        quote: action.payload
      };
    case 'QUOTE_DETAILS':
      return {
        ...state,
        quote: action.payload
      };
    case 'QUOTE_UPDATE_FULFILLED':
      return {
        ...state,
        quote: action.payload,
        quoteUpdateFulfilled: true,
        quoteInfoMessage: 'Quote Updated Successfully!'
      };
    case 'QUOTE_ITEM_UPDATE_REJECTED': {
      const { error, exceptionMessage } = action.payload;
      return {
        ...state,
        updateQuoteItemFulfilled: false,
        quoteItemUpdateError: `${error} - ${exceptionMessage}`
      };
    }
    case 'QUOTE_ITEM_UPDATE_FULFILLED': {
      // let updateActiveQuoteItems = new Array();
      const updateActiveQuoteItems = [];
      for (let i = 0, len = state.activeQuoteItemLines.length; i < len; i++) {
        if (state.activeQuoteItemLines[i].id === action.payload.id) {
          updateActiveQuoteItems.push(action.payload);
        } else {
          updateActiveQuoteItems.push(state.activeQuoteItemLines[i]);
        }
      }
      return {
        ...state,
        activeQuoteItemLines: updateActiveQuoteItems
      };
    }
    case 'GOTO_ADD_QUOTE_ITEM':
      return {
        ...state,
        addItemToQuote: true
        // quoteUpdateFulfilled: false,
      };
    case 'QUOTE_UPDATE_REJECTED':
      return {
        ...state,
        quoteFulfilled: false
      };
    case 'QUOTE_UPDATE':
      return {
        ...state,
        quote: action.payload,
        quoteInfoMessage: 'Quote Updated!'
      };
    case 'SEARCH_QUOTES_FULFILLED':
      return {
        ...state,
        quotesSearched: action.payload.data,
        quotesSearchedFulfilled: true
      };
    case 'SEARCH_QUOTES_REJECTED':
      return {
        ...state,
        quotesSearched: [],
        quotesSearchedFulfilled: false
      };
    case 'QUOTE_ITEMS_FULFILLED':
      return {
        ...state,
        quoteItems: action.payload
        // productsSearchedFulfilled: false,
        // addItemToQuote: false,
        // quoteItemDetailsFetched: false
      };
    case 'QUOTE_ITEMS_REJECTED':
      return {
        ...state,
        storeIsReset: false,
        quoteItems: action.payload
      };
    case 'QUOTE_ITEM_DETAILS':
      return {
        ...state,
        storeIsReset: false,
        addQuoteItem: null,
        quoteItemDetail: action.payload,
        // addQuoteItemFulfilled: false,
        // updateQuoteItemFulfilled: false,
        quoteItemDetailsFetched: true
      };
    case 'ADD_QUOTE_ITEM_FULFILLED': {
      const newQuoteItemLines = state.activeQuoteItemLines;
      newQuoteItemLines.push(action.payload);
      return {
        ...state,
        storeIsReset: false,
        addQuoteItem: action.payload,
        quoteInfoMessage: 'New Item Added to Quote!',
        activeQuoteItemLines: newQuoteItemLines
      };
    }
    case 'ADD_QUOTE_ITEM_REJECTED':
      return {
        ...state,
        storeIsReset: false,
        addQuoteItem: action.payload,
        addQuoteItemFulfilled: false
      };
    case 'QUOTE_ITEM_DELETE_FULFILLED': {
      // let newQuoteLineItems = new Array();
      const newQuoteLineItems = [];
      for (let i = 0, len = state.activeQuoteItemLines.length; i < len; i++) {
        if (state.activeQuoteItemLines[i].id !== action.payload) {
          newQuoteLineItems.push(state.activeQuoteItemLines[i]);
        }
      }
      return {
        ...state,
        activeQuoteItemLines: newQuoteLineItems
        // storeIsReset: false,
        // productsSearchedFulfilled: false,
        // //addItemToQuote: false,
        // quoteInfoMessage: 'Quote Item Deleted!',
      };
    }
    case 'QUOTE_ITEM_DELETE_REJECTED':
      return {
        ...state,
        storeIsReset: false,
        addQuoteItem: action.payload,
        addQuoteItemFulfilled: false,
        contactInfoMessage: 'Delete Item Failed!'
      };
    case 'CONVERT_TO_WORKORDER_REJECTED':
      return {
        ...state,
        storeIsReset: false,
        newWorkOrder: action.payload,
        convertToOrderFulfilled: false
      };
    case 'CONVERT_TO_WORKORDER_FULFILLED':
      return {
        ...state,
        storeIsReset: false,
        newWorkOrder: action.payload.data,
        convertToOrderFulfilled: true
      };
    case 'EDIT_QUOTE_ITEMS':
      return {
        ...state,
        quoteItems: action.payload
      };
    case 'SEARCH_WORKORDER_REJECTED':
    case 'SEARCH_CONTACTS_FOR_QUOTES_REJECTED':
      return {
        ...state
      };
    case 'SEARCH_WORKORDER_FULFILLED':
    case 'SEARCH_CONTACTS_FOR_QUOTES_FULFILLED':
      return {
        ...state,
        contactQuotesSearched: action.payload.data,
        quotesSearched: action.payload.data
      };
    case 'SELECTED_QUOTE':
      return {
        ...state,
        activeQuote: action.payload,
        activeQuoteItemLines: action.payload.quote_items
      };

    // TODO: I don't think this does anything
    case 'CREATE_QUOTE_LABOR_LINE_FULFILLED': {
      const newLaborItemLines = state.activeQuoteLaborLines;
      newLaborItemLines.push(action.payload);
      return {
        ...state
        // activeQuoteLaborLines: newLaborItemLines
      };
    }
    case 'UPDATE_QUOTE_LABOR_LINE_FULFILLED': {
      // let updateActiveQuoteItems = new Array();
      const updatedLaborLines = [];
      for (let i = 0; i < state.activeQuoteLaborLines.length; i++) {
        if (state.activeQuoteLaborLines[i].id === action.payload.id) {
          updatedLaborLines.push(action.payload);
        } else {
          updatedLaborLines.push(state.activeQuoteLaborLines[i]);
        }
      }
      return {
        ...state,
        activeQuoteLaborLines: updatedLaborLines
      };
    }
    case 'DELETE_QUOTE_LABOR_LINE_FULFILLED': {
      const newQuoteLaborLines = [];
      for (let i = 0; i < state.activeQuoteLaborLines.length; i++) {
        if (state.activeQuoteLaborLines[i].id !== action.payload) {
          newQuoteLaborLines.push(state.activeQuoteLaborLines[i]);
        }
      }
      return {
        ...state,
        activeQuoteLaborLines: newQuoteLaborLines
      };
    }
    case 'LOGOUT':
    case 'STORE_RESET':
      return initialState;
    // return {
    //     ...state,
    //     activeQuote:{},
    //     activeQuoteItemLines:[],
    //     quote:'',
    //     quotesSearched:[],
    //     contactQuotesSearched:[],
    //     quotesSearchedFulfilled:false,
    //     convertToOrderFulfilled:'',
    //     quoteUpdateFulfilled: false,
    //     quoteDetailsFetched: false,
    //     createNewQuote: false,
    //     addItemToQuote: false,
    //     quoteItemDetailsFetched: false,
    //     updateQuoteItemFulfilled: false,
    //     quoteInfoMessage: '',
    //     quoteItems:[],
    //     quoteItemUpdateError:"",
    //     newWorkOrder:''
    // }
    default:
      return state;
  }
}
