import React, { useState, createContext, useCallback, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import red from '@material-ui/core/colors/red';
import { connect } from 'react-redux';
import { login } from './actions/userActions';

import { apiOspos } from './config/config';

export const AuthContext = createContext();

let timeoutReference;

const useAuthorizedTimeout = () => {
  const [isAuthorized, setIsAuthorized] = useState(localStorage.getItem('access'));

  const timeoutLocalContext = useCallback(() => {
    // four hours
    const timeoutLength = 1000 * 60 * 60 * 4;
    if (timeoutReference) {
      clearTimeout(timeoutReference);
    }

    const tempRef = setTimeout(() => {
      localStorage.removeItem('access');
      setIsAuthorized(false);
    }, timeoutLength);

    timeoutReference = tempRef;
  }, []);

  const timeoutLocalContextDebounced = useCallback(
    debounce(timeoutLocalContext, 30000, { leading: true }),
    [timeoutLocalContext]
  );

  useEffect(() => {
    window.addEventListener('mousemove', timeoutLocalContextDebounced);
    timeoutLocalContext();
    return () => window.removeEventListener('mousemove', timeoutLocalContextDebounced);
  }, []);

  return [isAuthorized, setIsAuthorized];
};

function AuthorizedLogin({ children, login }) {
  const [userName, setUser] = useState(localStorage.getItem('username') || '');
  const [isAuthorized, setIsAuthorized] = useAuthorizedTimeout();
  const [userPassword, setPassword] = useState('');
  const [errorMessage, setError] = useState('');

  function submitAuth() {
    fetch(`${apiOspos.authUrl}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: userName,
        password: userPassword,
        client_id: apiOspos.client_id,
        client_secret: apiOspos.client_secret,
        grant_type: 'password'
      })
    })
      .then(async response => {
        const data = await response.json();
        if (data.access_token) {
          localStorage.setItem('access', data.access_token);
          localStorage.setItem('username', userName);
          setIsAuthorized(data.access_token);
          login(data.access_token);
        } else if (data.error) {
          switch (data.error) {
            case 'invalid_credentials':
              setError('Invalid username / password');
              break;
            case 'invalid_client':
              setError('Invalid token. Probably a problem on the server');
              break;
            default:
              break;
          }
        }
        setPassword('');
      })
      .catch(err => console.info(err));
  }

  function handleSubmit(e) {
    e.preventDefault();
    submitAuth();
  }

  if (!isAuthorized) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography paragraph variant="h2" color="inherit">
          Welcome to WPOS3
        </Typography>
        <form>
          <input
            name="username"
            type="text"
            autoComplete="username"
            value={userName}
            onChange={e => setUser(e.target.value)}
          />
          <input
            name="password"
            type="password"
            autoComplete="current-password"
            value={userPassword}
            onChange={e => setPassword(e.target.value)}
          />
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
          <Typography paragraph style={{ color: red[500] }}>
            {errorMessage}
          </Typography>
        </form>
      </div>
    );
  }
  return (
    <AuthContext.Provider
      value={{
        username: userName,
        auth: isAuthorized,
        setIsAuthorized: () => {
          setIsAuthorized(false);
          localStorage.setItem('access', '');
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default connect(null, {
  login
})(AuthorizedLogin);
