import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { isEmpty, uniqueId } from 'lodash';
import { LinearProgress } from '@material-ui/core';
import CarouselApp from './CarouselApp';
import { createStore } from './redux/CarouselStore';
import './pseudoElements.css';
import { useAxios } from '../../common/Hooks';
import { getAxiosResponseErrorMessage } from '../../helpers/getErrors';

export const CarouselIndex = () => {
  const [axios] = useAxios('/carousel-images');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    carouselItems: []
  });
  const [triggered, setTriggered] = useState(false);
  const [errors, setErrors] = useState([]);

  const loadData = async () => {
    try {
      const response = await axios.get('/');
      const json = response.data;
      setData({
        carouselItems: json.map(value => ({
          id: uniqueId('crslItem'),
          link: value.link,
          img: value.image,
          errors: {
            link: false,
            img: false
          }
        }))
      });
    } catch (error) {
      setErrors([].concat(getAxiosResponseErrorMessage(error)));
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggered]);

  return loading ? (
    <LinearProgress color="primary" style={{ margin: '5em' }} />
  ) : !isEmpty(errors) ? (
    errors.map(error => <h2>{error}</h2>)
  ) : (
    <>
      <Provider store={createStore(data)}>
        <CarouselApp refetch={() => setTriggered(!triggered)} />
      </Provider>
    </>
  );
};
