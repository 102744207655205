import { useContext, useEffect, useState } from 'react';

import { useHttp } from '../../common/Hooks';
import { ApInvoicesContext } from '../../App';

function useStaticList(endpoint: string, initialState: any) {
  const [req, res] = useHttp(endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const [data, setData] = useState(initialState);
  const [sync, setSync] = useState(true);
  const { setToUpdate } = useContext(ApInvoicesContext);
  useEffect(() => {
    async function getData() {
      const requestData = await req.get();
      if (res.ok) {
        setData(requestData);
        setSync(false);
        setToUpdate(false);
      }
    }
    if (sync) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync]);
  return [data, setSync];
}

export function useAccountsPayableInvoices() {
  return useStaticList('accounts-payable-invoice', []);
}

export interface ApInvoiceSearch {
  invoiceNumber?: number | null;
  invoiceAmount?: number | null;
  financeCompany?: number | null;
  vendor?: number | null;
  invoiceDate?: string | null;
  purchaseDate?: string | null;
  dueDate?: string | null;
}

export function useSearchableAccountsPayableInvoices({
  invoiceNumber,
  invoiceAmount,
  financeCompany,
  vendor,
  invoiceDate,
  purchaseDate,
  dueDate
}: ApInvoiceSearch) {
  return useStaticList(
    `accounts-payable-invoice?invoice_number=${invoiceNumber ||
      ''}&invoice_amount=${invoiceAmount || ''}&finance_company=${financeCompany ||
      ''}&vendor=${vendor || ''}&invoice_date=${invoiceDate || ''}&purchase_date=${purchaseDate ||
      ''}&due_date=${dueDate || ''}`,
    []
  );
}

export function useFinanceCompanies() {
  return useStaticList('finance-company', {});
}

export function useVendorList() {
  return useStaticList('vendor', {});
}

export function useAttachedInvoices(purchaseOrder: number) {
  return useStaticList(`accounts-payable-invoice/attached-purchase-orders/${purchaseOrder}`, {});
}
