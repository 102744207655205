import { isEmpty, padStart } from 'lodash';
import React, { createContext, useState, useEffect, useCallback, Context } from 'react';
import { ALL_LOCATIONS, locations, STORAGE_LOCATION_INDEX } from '../../actions/locationActions';
import { useHttp } from '../../common/Hooks';
import { LooseObject } from '../../helpers/helpers';
import {
  BinLocationsQueryParams,
  BinManagementContextType,
  GetBinLocations,
  UseBinLocations
} from './BinManagementTypes';
import {
  ALLOWED_BIN_CONDITIONS,
  CreateBinLocation,
  CreateBinLocationData,
  UseCreateBinLocation
} from './CreateBin/CreateBinTypes';
import { DeleteBinData, DeleteBinLocation, UseDeleteBinLocation } from './DeleteBin/DeleteBinTypes';

let storedLocationIndex = new Number(localStorage.getItem(STORAGE_LOCATION_INDEX));
export const initialLocation = locations[+storedLocationIndex];

export const computeBinName = (binRow: number, binBay: number, binShelf: number): string => {
  const rowText = padStart(binRow.toString(), 2, '0');
  const bayText = padStart(binBay.toString(), 2, '0');
  const shelfText = padStart(binShelf.toString(), 2, '0');
  return `R${rowText}-B${bayText}-S${shelfText}`;
};

export const initialBinData = {
  binName: computeBinName(1, 1, 1),
  conditionCode: ALLOWED_BIN_CONDITIONS[0].value,
  row: 1,
  bay: 1,
  shelf: 1,
  locationCode: initialLocation
};

const defaultValues: BinManagementContextType = {
  binLocations: [],
  getBinLocations: () => {},
  loadingBinLocations: false,
  queryParams: {
    searchTerm: '',
    locationCode: initialLocation,
    orderBy: 'created_at',
    sortDirection: 'desc'
  },
  setQueryParams: () => {},
  binErrors: false,
  setBinErrors: () => {},
  createBinData: {...initialBinData},
  setCreateBinData: () => {},
  loadingCreateBin: false,
  createBinErrors: {},
  createBinSuccess: false,
  setCreateBinSuccess: () => {},
  createBin: () => {},
  setCreateBinErrors: () => {},
  deleteBinErrors: {},
  setDeleteBinErrors: () => {},
  deleteBinSuccess: false,
  setDeleteBinSuccess: () => {},
  deleteBin: () => {}
};

export const BinManagementContext: Context<BinManagementContextType> = createContext(defaultValues);

const getQueryStringFromParams = (params: BinLocationsQueryParams): string => {
  const usableQuery: LooseObject = {};
  let key: keyof BinLocationsQueryParams;
  for (key in params) {
    if (!isEmpty(params[key])) {
      /*
       * Excluding ALL_LOCATIONS since API does not accept
       * ALL_LOCATIONS as a valid location code.
       */
      if (!(key === 'locationCode' && params[key] === ALL_LOCATIONS)) {
        usableQuery[key] = params[key];
      }
    }
  }

  return new URLSearchParams(usableQuery).toString();
};

export const useBinLocations = (): UseBinLocations => {
  const [binLocations, setBinLocations] = useState([]);
  const [queryParams, setQueryParams] = useState({ ...defaultValues.queryParams });
  const [request, response, auth, loadingBinLocations] = useHttp('ims');
  const [binErrors, setBinErrors] = useState(false);

  const getBinLocations: GetBinLocations = useCallback(async () => {
    const queryParamsString = getQueryStringFromParams(queryParams);
    const binLocationsResponse = await request.get(`/bins?${queryParamsString}`);
    if (response.ok) {
      setBinLocations(binLocationsResponse);
    } else {
      setBinLocations([]);
      setBinErrors(true);
    }
  }, [queryParams]);

  return {
    binLocations,
    getBinLocations,
    loadingBinLocations,
    queryParams,
    setQueryParams,
    binErrors,
    setBinErrors
  };
};

export const useCreateBinLocation = (): UseCreateBinLocation => {
  const [createBinData, setCreateBinData] = useState({ ...defaultValues.createBinData });
  const [createBinSuccess, setCreateBinSuccess] = useState(false);
  const [createBinErrors, setCreateBinErrors] = useState({ ...defaultValues.createBinErrors });
  const [request, response, auth, loadingCreateBin] = useHttp('ims');

  const createBin: CreateBinLocation = useCallback(async (createBinData: CreateBinLocationData) => {
    const createBinResponse = await request.post(`/bins`, createBinData);
    if (response.ok) {
      setCreateBinSuccess(true);
    } else {
      setCreateBinErrors(createBinResponse);
    }
  }, []);

  return {
    loadingCreateBin,
    createBinErrors,
    createBinSuccess,
    setCreateBinSuccess,
    createBin,
    setCreateBinErrors,
    createBinData,
    setCreateBinData
  };
};

export const useDeleteBinLocation = (): UseDeleteBinLocation => {
  const [deleteBinSuccess, setDeleteBinSuccess] = useState(false);
  const [deleteBinErrors, setDeleteBinErrors] = useState({ ...defaultValues.deleteBinErrors });
  const [request, response, auth] = useHttp('ims');

  const deleteBin: DeleteBinLocation = useCallback(async (deleteBinData: DeleteBinData) => {
    const deleteBinResponse = await request.delete(`/bins/${deleteBinData.id}`);
    if (response.ok) {
      setDeleteBinSuccess(true);
    } else {
      setDeleteBinErrors(deleteBinResponse);
    }
  }, []);

  return {
    deleteBinErrors,
    setDeleteBinErrors,
    deleteBinSuccess,
    setDeleteBinSuccess,
    deleteBin
  };
};

export type BinLocationsProps = {
  children: React.ReactNode;
};

export function BinLocationsProvider({ children }: BinLocationsProps) {
  const {
    binLocations,
    getBinLocations,
    loadingBinLocations,
    queryParams,
    setQueryParams,
    binErrors,
    setBinErrors
  } = useBinLocations();

  useEffect(() => {
    setBinErrors(false);
    getBinLocations();
  }, [queryParams]);

  const {
    loadingCreateBin,
    createBinErrors,
    createBinSuccess,
    setCreateBinSuccess,
    createBin,
    setCreateBinErrors,
    createBinData,
    setCreateBinData
  } = useCreateBinLocation();

  const {
    deleteBinErrors,
    setDeleteBinErrors,
    deleteBinSuccess,
    setDeleteBinSuccess,
    deleteBin
  } = useDeleteBinLocation();

  return (
    <BinManagementContext.Provider
      value={{
        binLocations,
        getBinLocations,
        loadingBinLocations,
        queryParams,
        setQueryParams,
        binErrors,
        setBinErrors,
        loadingCreateBin,
        createBinErrors,
        createBinSuccess,
        setCreateBinSuccess,
        createBin,
        setCreateBinErrors,
        createBinData,
        setCreateBinData,
        deleteBinErrors,
        setDeleteBinErrors,
        deleteBinSuccess,
        setDeleteBinSuccess,
        deleteBin
      }}
    >
      {children}
    </BinManagementContext.Provider>
  );
}
