import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { BinManagementContext } from './BinManagementContext';
import LocationSelect from './LocationSelect';
import BinTable from './BinTable';
import CreateBinModal from './CreateBin/CreateBinModal';

export default function BinManagementLayout() {
  const { queryParams } = useContext(BinManagementContext);

  const SORT_BY_ENUM: { [index: string]: string } = {
    created_at: 'Recently Created Bins',
    bin_name: 'Bins Alphabetical by Bin Name'
  };
  const sortByColumn = queryParams.orderBy ? queryParams.orderBy : 'created_at';

  return (
    <Grid container>
      <Grid container spacing={3} justify={'space-between'} alignItems={'center'}>
        <Grid item xs={8}>
          <Typography variant="h5">{SORT_BY_ENUM[sortByColumn] || 'Bins'}</Typography>
          <CreateBinModal />
        </Grid>
        <Grid item xs={4}>
          <LocationSelect />
        </Grid>
        <Grid item xs={12}>
          <BinTable />
        </Grid>
      </Grid>
    </Grid>
  );
}
