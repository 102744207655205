import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { locations } from '../../../actions/locationActions';
import GlueSelect from '../../Presentational/GlueSelect';
import { PickListContext } from './PickListContext';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    top: -48,
    minWidth: '100%'
  }
});

export interface PickListLocationSelectProps {

};

export default function PickListLocationSelect({ }: PickListLocationSelectProps) {
  const classes = useStyles();
  const { queryParams, setQueryParams } = useContext(PickListContext);
  const handleLocationChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    let newLocationCode = '';
    if (typeof event.target.value === 'string') {
      newLocationCode = event.target.value;
    }
    setQueryParams({
      ...queryParams,
      warehouse: newLocationCode
    });
  }

  const options = locations.map((locationCode: string) => {
    return {
      key: locationCode,
      value: locationCode,
      name: locationCode
    };
  });
  return (
    <GlueSelect
      options={options}
      accessibleLabelId={'simple-select-label'}
      label={'Choose your location'}
      handleChange={handleLocationChange}
      value={queryParams.warehouse}
      className={classes.root}
    />
  )
}