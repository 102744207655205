import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Search as SearchIcon } from '@material-ui/icons';
import { useQueryWithLocation as useQuery, useHttp } from '../../common/Hooks';
import EditShippingTransaction from './EditShippingTransaction';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 500
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  formWrapper: {
    marginTop: 20
  }
}));

const searchTypeDictionary = {
  trackingNumber: 'Tracking Number'
};

function SearchShippingTransactions() {
  const classes = useStyles();
  const urlSearchType = useQuery().get('searchType');
  const urlSearchValue = useQuery().get('searchValue');
  const [searchType, setSearchType] = useState(
    urlSearchType || Object.keys(searchTypeDictionary)[0]
  );
  const [searchValue, setSearchValue] = useState(urlSearchValue || '');
  const [results, setResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const [request, response] = useHttp('shipping-reconciliation', {
    headers: { Accept: 'application/json' }
  });

  const navigateToSearchUrl = useCallback(
    (typeArg, valueArg) => {
      history.replace(`${match.url}?searchType=${typeArg}&searchValue=${valueArg}`);
    },
    [match.url, history]
  );

  const search = useCallback(
    async (typeArg, valueArg) => {
      if (isEmpty(valueArg)) {
        return;
      }
      setIsSearching(true);
      setResults(null);
      let query = '';
      switch (typeArg) {
        case 'trackingNumber':
          query += `trackingNumbers[]=${valueArg}`;
          break;

        default:
          query += `${typeArg}=${valueArg}`;
          break;
      }
      const data = await request.get(`/shipping-transactions?${query}`);
      if (response.ok) {
        setResults(data);
      }
      setIsSearching(false);
    },
    [request, response]
  );

  const submitSearch = useCallback(
    event => {
      event.preventDefault();
      if (!isSearching) {
        navigateToSearchUrl(searchType, searchValue);
        search(searchType, searchValue);
      }
    },
    [navigateToSearchUrl, searchValue, searchType, isSearching, search]
  );

  const changeSearchValue = useCallback(({ target: { value } }) => {
    setSearchValue(value);
  }, []);

  const changeSearchType = useCallback(({ target: { value } }) => {
    setSearchType(value);
  }, []);

  // Only execute on-mount.
  useEffect(() => {
    if (!isEmpty(urlSearchType) && !isEmpty(urlSearchValue)) {
      search(urlSearchType, urlSearchValue);
    }
  }, []);

  return (
    <>
      <Paper component="form" className={classes.root} onSubmit={submitSearch}>
        <InputBase
          className={classes.input}
          placeholder="Search Shipping Transactions"
          inputProps={{ 'aria-label': 'search transactions' }}
          id="search-shipping-transactions"
          value={searchValue || ''}
          onChange={changeSearchValue}
        />
        <Select
          labelId="select-search-type"
          id="select-search-type"
          value={searchType || ''}
          onChange={changeSearchType}
        >
          {Object.entries(searchTypeDictionary).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <IconButton type="submit" aria-label="search" className={classes.iconButton}>
          <SearchIcon />
        </IconButton>
      </Paper>
      {isSearching && <Typography>Searching...</Typography>}
      {results !== null &&
        (results.length === 0 ? (
          <Typography>No results to display</Typography>
        ) : (
          results.map(result => (
            <EditShippingTransaction
              key={result.tracking_number}
              className={classes.formWrapper}
              shippingTransaction={result}
            />
          ))
        ))}
    </>
  );
}

export default SearchShippingTransactions;
