import { get, isEmpty } from 'lodash';
import React, { createContext, useState, useEffect, useCallback, Context } from 'react';
import { ALL_LOCATIONS, locations, STORAGE_LOCATION_INDEX } from '../../../actions/locationActions';
import { useHttp } from '../../../common/Hooks';
import { LooseObject } from '../../../helpers/helpers';
import { GetPickList, ListType, LIST_TYPE_ENUM, PickableItem, PickableItemsQueryParams, PickListContextType, PickListResponse, PrintPickList, PrintPickListData, PrintPickListItem, PrintPickListResponse, UsePickList, UsePrintPickList } from './PickListTypes';
import { UseUnprintPickItem, UnprintPickItemData, UnprintPickItem } from './UnprintPickItem/UnprintPickItemTypes';

let storedLocationIndex = new Number(localStorage.getItem(STORAGE_LOCATION_INDEX));
export const initialLocation = locations[+storedLocationIndex];

const defaultValues: PickListContextType = {
  pickList: [],
  setPickList: () => {},
  getPickList: () => {},
  mapPLItemToPickableItem: (transactionReferenceId: number) => ({
    imsTransactionReferenceId: 0,
    itemMemberRefId: 0,
    itemGroupRefId: 0,
    fkProductId: 0,
    productUpc: '0',
    productAltUpc: '',
    itemCondition: '',
    itemWarehouse: '',
    quantityRequested: 0,
    isExpress: false,
    isFreight: false,
    isPremium: false,
    isPrime: false,
    shipByDate: '',
    suggestedBinId: 0,
  }),
  queryParams: {
    warehouse: initialLocation,
    listType: LIST_TYPE_ENUM.UNPRINTED,
  },
  setQueryParams: () => { },
  loadingPickList: false,
  pickListErrors: {},
  setPickListErrors: () => {},
  itemsToPrint: [],
  setItemsToPrint: () => {},
  loadingPrintPickList: false,
  printPickListErrors: {},
  setPrintPickListErrors: () => {},
  printPickListSuccess: false,
  setPrintPickListSuccess: () => {},
  printPickList: () => {},
  unprintPickItem: () => {},
  loadingUnprintPickItem: false,
  unprintPickItemErrors: {},
  setUnprintPickItemErrors: () => {},
  unprintPickItemSuccess: false,
  setUnprintPickItemSuccess: () => {},
};

export const PickListContext: Context<PickListContextType> = createContext(defaultValues);

const getResponseSegment = (response: PickListResponse, listType?: ListType) => {
  let segment: PickableItem[] = [];
  switch (listType) {
    case LIST_TYPE_ENUM.PRINTED:
      segment = getValidResults(response.printed_pick_list);
      break;
    case LIST_TYPE_ENUM.UNPRINTED:
      segment = getValidResults(response.unprinted_pick_list);
      break;
    default:
      break;
  }
  return segment;
}

const getValidResults = (segment: PickableItem[] = []) => {
  return segment;
}

const getPickListPath = (listType?: ListType) => {
  let path = '';
  switch (listType) {
    case LIST_TYPE_ENUM.PRINTED:
      path = 'printed'
      break;
    case LIST_TYPE_ENUM.UNPRINTED:
      path = 'unprinted'
      break;
    default:
      path = 'unprinted'
      break;
  }
  return path;
}

const getQueryString = (query: PickableItemsQueryParams) => {
  if (!query.warehouse || query.warehouse === ALL_LOCATIONS) {
    return '';
  }
  return `?warehouse=${query.warehouse}`;
};

export const usePickList = (): UsePickList => {
  const [pickList, setPickList] = useState<PickableItem[]>([]);
  const [queryParams, setQueryParams] = useState({ ...defaultValues.queryParams });
  const [request, response, auth, loadingPickList] = useHttp('ims');
  const [pickListErrors, setPickListErrors] = useState({});

  const getPickList: GetPickList = useCallback(async () => {
    setPickList([]);
    setPickListErrors({});
    const queryParamsString = getQueryString(queryParams);
    const path = getPickListPath(queryParams.listType);
    const pickListResponse: PickListResponse = await request.get(`/picklist/${path}${queryParamsString}`);
    if (response.ok) {
      let list = getResponseSegment(pickListResponse, queryParams.listType);
      setPickList(list);
    } else {
      setPickListErrors(pickListResponse);
    }
  }, [queryParams]);

  return {
    pickList,
    setPickList,
    getPickList,
    loadingPickList,
    queryParams,
    setQueryParams,
    pickListErrors,
    setPickListErrors
  };
};

const formatItemErrors = (response: any, itemsToPrint: number[]) => {
  let itemErrors: LooseObject = {};
  if (!isEmpty(response.message)) {
    Object.keys(response.message).forEach((key: string) => {
      if (key.includes('items.')) {
        const errorKey = key;
        const [topLevelProp, index, subKey]: string[] = key.split('.');
        const transactionReferenceId = itemsToPrint[Number(index)];
        itemErrors[transactionReferenceId] = itemErrors[transactionReferenceId]
          ? [...itemErrors[transactionReferenceId], get(response.message, errorKey).join('')]
          : get(response.message, errorKey, []);
      }
    });
  }
  return itemErrors;
}

export const usePrintPickList = (): UsePrintPickList => {
  const [itemsToPrint, setItemsToPrint] = useState<number[]>([]);
  const [printPickListErrors, setPrintPickListErrors] = useState({});
  const [printPickListSuccess, setPrintPickListSuccess] = useState(false);
  const [request, response, auth, loadingPrintPickList] = useHttp('ims');

  const printPickList: PrintPickList = useCallback(async (printPickListData: PrintPickListData) => {
    const pickListResponse = await request.post(`/picklist/items`, printPickListData);
    if (response.ok) {
      setPrintPickListSuccess(true);
    } else { 
    pickListResponse.itemErrors = formatItemErrors(
      pickListResponse, 
      itemsToPrint
      );
      setPrintPickListErrors(pickListResponse);
    }
    return pickListResponse;
  }, [itemsToPrint]);

  return {
    itemsToPrint,
    setItemsToPrint,
    loadingPrintPickList,
    printPickListErrors,
    setPrintPickListErrors,
    printPickListSuccess,
    setPrintPickListSuccess,
    printPickList,
  };
};

export const useUnprintPickItem = (): UseUnprintPickItem => {
  const [unprintPickItemErrors, setUnprintPickItemErrors] = useState({});
  const [unprintPickItemSuccess, setUnprintPickItemSuccess] = useState(false);
  const [request, response, auth, loadingUnprintPickItem] = useHttp('ims');

  const unprintPickItem: UnprintPickItem = useCallback(async (pickableItemData: UnprintPickItemData) => {
    const unprintPickItemResponse = await request.delete(`/picklist/items/${pickableItemData.id}`);
    if (response.ok) {
      setUnprintPickItemSuccess(true);
    } else {
      setUnprintPickItemErrors({
        ...unprintPickItemResponse,
        general: 'There was an error unprinting the item.'
      });
    }
  }, []);

  return {
    loadingUnprintPickItem,
    unprintPickItemErrors,
    setUnprintPickItemErrors,
    unprintPickItemSuccess,
    setUnprintPickItemSuccess,
    unprintPickItem,
  };
};

export type PickListContextProps = {
  children: React.ReactNode;
};

export function PickListProvider({ children }: PickListContextProps) {

  const {
    pickList,
    getPickList,
    setPickList,
    loadingPickList,
    queryParams,
    setQueryParams,
    pickListErrors,
    setPickListErrors
  } = usePickList();

  const mapPLItemToPickableItem = (transactionReferenceId: number) => {
    const unpickedItem: PickableItem | undefined = pickList.find((i: PickableItem) => i.transactionReferenceId === transactionReferenceId);
    const pickItem: PrintPickListItem = {
      imsTransactionReferenceId: unpickedItem?.transactionReferenceId ?? 0,
      itemMemberRefId: unpickedItem?.tid ?? null,
      itemGroupRefId: unpickedItem?.invoice ?? null,
      fkProductId: unpickedItem?.productId ?? null,
      productUpc: unpickedItem?.upc ?? null,
      productAltUpc: unpickedItem?.alternateUpc ?? null,
      itemCondition: unpickedItem?.condition ?? null,
      itemWarehouse: unpickedItem?.warehouse ?? null,
      quantityRequested: unpickedItem?.quantity ?? null,
      isExpress: unpickedItem?.isExpress ?? false,
      isFreight: unpickedItem?.isFreight ?? false,
      isPremium: unpickedItem?.isPremium ?? false,
      isPrime: unpickedItem?.isPrime ?? false,
      shipByDate: unpickedItem?.shipByDate ?? null,
      suggestedBinId: unpickedItem?.suggestedBin.binId ?? null,
    };
    return pickItem;
  }

  useEffect(() => {
    getPickList();
  }, [queryParams]);

  const {
    itemsToPrint,
    setItemsToPrint,
    loadingPrintPickList,
    printPickListErrors,
    setPrintPickListErrors,
    printPickListSuccess,
    setPrintPickListSuccess,
    printPickList,
  } = usePrintPickList();

  const {
    loadingUnprintPickItem,
    unprintPickItemErrors,
    setUnprintPickItemErrors,
    unprintPickItemSuccess,
    setUnprintPickItemSuccess,
    unprintPickItem,
  } = useUnprintPickItem();

  return (
    <PickListContext.Provider
      value={{
        pickList,
        setPickList,
        getPickList,
        mapPLItemToPickableItem,
        loadingPickList,
        queryParams,
        setQueryParams,
        pickListErrors,
        setPickListErrors,
        itemsToPrint,
        setItemsToPrint,
        loadingPrintPickList,
        printPickListErrors,
        setPrintPickListErrors,
        printPickListSuccess,
        setPrintPickListSuccess,
        printPickList,
        loadingUnprintPickItem,
        unprintPickItemErrors,
        setUnprintPickItemErrors,
        unprintPickItemSuccess,
        setUnprintPickItemSuccess,
        unprintPickItem,
      }}
    >
      {children}
    </PickListContext.Provider>
  )
}