const initialState = {
  scanSetup: false,
  productScanned: {}
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case 'GET_SCAN_SETUP_FULFILLED':
      return {
        ...state,
        scanSetup: true,
        productScanned: action.payload
      };
    case 'GET_SCAN_SETUP_REJECTED':
      return {
        ...state,
        scanSetup: false,
        productScanned: action.payload
      };

    default:
      return state;
  }
}
