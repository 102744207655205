import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
/* Dialog Edit */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import TaxTableObject from './components/TaxTableObject';
import { apiOspos } from '../../config/config';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  mapItem: {
    display: 'flex',
    flex: 0.3,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
});

export class TaxMapComponent extends Component {
  state = {
    states: [],
    platforms: [],
    taxData: [],
    open: false,
    editablePSTM: {}
  };

  componentDidMount() {
    this.getAllPlatforms();
    this.getAllStates();
    this.getAllTaxInfo();
  }

  mutate = data => {
    const result = {};

    for (const pstm of data) {
      if (result[pstm.state_code] === undefined) {
        result[pstm.state_code] = {};
      }
      result[pstm.state_code][pstm.platform_code] = pstm.collectAndRemitTax;
    }
    return result;
  };

  getAllTaxInfo = () => {
    fetch(`${apiOspos.baseUrl}/platformStateTaxMap/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.userAccessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        this.setState({ taxData: this.mutate(responseData.data) });
      })
      .catch(err => {});
  };

  getAllStates = () => {
    fetch(`${apiOspos.baseUrl}/states/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.userAccessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        this.setState({ states: responseData.data });
      })
      .catch(err => {});
  };

  getAllPlatforms = () => {
    fetch(`${apiOspos.baseUrl}/platforms/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.userAccessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        this.setState({ platforms: responseData.data });
      })
      .catch(err => {});
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.taxData !== this.state.taxData) {
      this.setState({ open: false });
    }
  }

  changeTax = (state_code, platform_code, collectAndRemitTax) => {
    const result = {};
    result.platform_code = platform_code;
    result.state_code = state_code;
    result.collectAndRemitTax = collectAndRemitTax;
    this.setState({ editablePSTM: result, open: true });
  };

  handleChange = name => event => {
    const newValue = event.target.checked;
    this.setState(prevState => ({
      editablePSTM: {
        ...prevState.editablePSTM,
        [name]: newValue
      }
    }));
  };

  handleSave = () => {
    // this.props.contactActions.contactUpdate(this.props.access_token, this.state.contact)
    //   .then(() =>{
    //     this.handleClose()
    //   });
    const pstm = this.state.editablePSTM;
    fetch(
      `${apiOspos.baseUrl}/platformStateTaxMap/${pstm.platform_code}/${pstm.state_code}/${pstm.collectAndRemitTax}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.props.userAccessToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(responseData => {
        this.getAllTaxInfo();
      })
      .catch(err => {});
  };

  render() {
    return (
      <div>
        <EditDialog
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
          dialogTitle={`Edit ${this.state.editablePSTM.state_code} on ${this.state.editablePSTM.platform_code}`}
          saveEdit={() => this.handleSave()}
        >
          <Switch
            checked={this.state.editablePSTM.collectAndRemitTax !== 0}
            onChange={() => this.handleChange('collectAndRemitTax')}
            value="collectAndRemitTax"
            color="primary"
          />
        </EditDialog>
        <h1>Collect and Remit Tax Table</h1>
        <TaxTableObject
          changeTax={(state_code, platform_code, collectAndRemitTax) =>
            this.changeTax(state_code, platform_code, collectAndRemitTax)
          }
          data={this.state.taxData}
          platforms={this.state.platforms}
          states={this.state.states}
        />
      </div>
    );
  }
}

export default connect(state => ({
  userAccessToken: state.user.loginCredentials.access_token
}))(withStyles(styles)(TaxMapComponent));

class EditDialog extends Component {
  state = {
    changeContact: false
  };

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        onChange: child.props.onChange(child.props.id)
        // onSelect: () => { child.props.getChildInfo(child.props.value) },
        // selected: this.props.selectedOrder === child.props.value
      });
    });

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          {this.props.changeContactOption ? (
            <Button onClick={() => this.setState({ changeContact: true })} color="primary">
              Change Contact
            </Button>
          ) : null}
          <Button onClick={this.props.saveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
