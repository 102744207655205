import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Cancel from '@material-ui/icons/Cancel';
import makeStyles from '@material-ui/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import red from '@material-ui/core/colors/red';
import isEmpty from 'lodash/isEmpty';
import { useStatus, useHttp } from '../../../common/Hooks';
import { AuthContext } from '../../../AuthContext';
import { matchesUpc, matchesPartNumber } from '../../../helpers/scanValidator';

export const AUDIT_TYPE_SERIALIZED = 'PHYSICAL_INVENTORY_AUDIT_ADJUSTMENT_SERIALIZED';
export const AUDIT_TYPE_NON_SERIALIZED = 'PHYSICAL_INVENTORY_AUDIT_ADJUSTMENT_NON_SERIALIZED';
export const deriveAuditTypeCode = product =>
  product.serial_number_tracked && product.num_of_serials_per_item > 0
    ? AUDIT_TYPE_SERIALIZED
    : AUDIT_TYPE_NON_SERIALIZED;

const useStyles = makeStyles(theme => ({
  unadornedList: { listStyleType: 'none' },
  scanInfoBlock: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  errorMessage: { color: red[500], fontWeight: 'bold', textAlign: 'center' }
}));

function Field({ label, forwardRef, handleKeyDown, disabled, value, setValue }) {
  return (
    <TextField
      disabled={disabled}
      label={label}
      inputRef={forwardRef}
      value={value}
      onChange={e => setValue(e.target.value.trim())}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          event.preventDefault();
          handleKeyDown(event);
        }
      }}
    />
  );
}

function UPCSerialFields({ serialsNeeded, scanUpc, scanSerial, disabled, validUpc, validSerial }) {
  const upcField = useRef(null);
  const [upcValue, setUpcValue] = useState('');

  const serialField = useRef(null);
  const [serialValue, setSerialValue] = useState('');
  const [domElementToFocus, setDomElementToFocus] = useState(null);

  useEffect(() => {
    let timeoutRef = null;
    if (domElementToFocus) {
      timeoutRef = setTimeout(() => {
        domElementToFocus.current.focus();
        setDomElementToFocus(null);
      }, 2100);
    }
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [domElementToFocus]);

  useEffect(() => {
    if (disabled) {
      if (!validUpc) {
        setUpcValue('');
        setDomElementToFocus(upcField);
      } else if (!validSerial) {
        setSerialValue('');
        setDomElementToFocus(serialField);
      }
    }
  }, [upcField, serialField, disabled, validUpc, validSerial]);

  useEffect(() => {
    if (validUpc && serialsNeeded > 0) {
      setSerialValue('');
      serialField.current.focus();
    } else {
      upcField.current.focus();
      setUpcValue('');
      setSerialValue('');
    }
  }, [upcField, serialField, validUpc, serialsNeeded]);

  return (
    <div>
      <Field
        label="UPC"
        disabled={disabled}
        forwardRef={upcField}
        value={upcValue}
        setValue={setUpcValue}
        handleKeyDown={event => {
          scanUpc(event);
        }}
      />
      {serialsNeeded > 0 ? (
        <Field
          label="Serial"
          disabled={disabled}
          forwardRef={serialField}
          value={serialValue}
          setValue={setSerialValue}
          handleKeyDown={event => {
            scanSerial(event);
          }}
        />
      ) : null}
    </div>
  );
}

function ScannedItems({ scannedItems, onSerialRemoval, numOfSerialsPerItem, talliedQuantity }) {
  const classes = useStyles();
  return (
    <div className={classes.scanInfoBlock}>
      <Typography component="h3">
        <strong>{talliedQuantity}</strong> Scanned Item{talliedQuantity !== 1 ? 's' : ''}:
      </Typography>
      {numOfSerialsPerItem > 0 && (
        <List>
          {scannedItems.length > 0
            ? scannedItems.map(item => (
                <ListItem key={item.id} dense>
                  <ListItemText primary={item.serials} />
                  <IconButton onClick={() => onSerialRemoval(item.id)}>
                    <Cancel />
                  </IconButton>
                </ListItem>
              ))
            : null}
        </List>
      )}
    </div>
  );
}

function SerialsPerItemScanned({ serials, numOfSerialsPerItem }) {
  const classes = useStyles();
  return (
    <div className={classes.scanInfoBlock}>
      <Typography component="h3">
        Serials Per Item:{' '}
        <strong>
          {serials.length} / {numOfSerialsPerItem}
        </strong>
      </Typography>
      {serials.length > 0 && (
        <ul className={classes.unadornedList}>
          {serials.map(serial => (
            <li key={serial}>{serial}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

function ProductInformation({ product }) {
  return <h2>{product.model}</h2>;
}

function useValidUpc(product, scannedUpc) {
  const validUpc = useMemo(() => {
    if (!isEmpty(scannedUpc)) {
      return matchesUpc(scannedUpc, product);
    }
    return null;
  }, [scannedUpc, product.upc]);
  return validUpc;
}

function useSerials(scannedSerial, setScannedSerial, product, alreadyScannedSerials) {
  const [serials, setSerials] = useState([]);
  const [status, setStatus, message, setMessage] = useStatus();

  useEffect(() => {
    if (!isEmpty(scannedSerial)) {
      if (matchesUpc(scannedSerial, product)) {
        setStatus(false);
        setMessage("UPC can't be serial");
      } else if (
        serials.includes(scannedSerial) ||
        alreadyScannedSerials
          .toString()
          .replace(/:=:/g, ',')
          .split(',')
          .includes(scannedSerial)
      ) {
        setStatus(false);
        setMessage('Serial already scanned');
      } else if (matchesPartNumber(scannedSerial, product)) {
        setStatus(false);
        setMessage("Serial can't equal model number");
      } else if (serials.length === product.num_of_serials_per_item) {
        // i dont think this is possible to hit
        setStatus(false);
        setMessage('Too many serials scanned');
      } else if (scannedSerial.includes(':=:')) {
        setStatus(false);
        setMessage('Individual serials cannot contain the delimiter ":=:".');
      } else {
        setSerials([...serials, scannedSerial]);
        setScannedSerial('');
        setStatus(true);
      }
    }
  }, [scannedSerial, product]);
  return [status, message, serials, () => setSerials([])];
}

function Scanner({
  product,
  setScannerTime,
  auditBucket,
  auditBatchId,
  auditBatchName,
  inProgressScans,
  isSetup,
  auditItem = {}
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [globalLock, setGlobalLock, globalMessage, setGlobalMessage] = useStatus(null, 2);

  const [scannedUpc, setScannedUpc] = useState('');
  const isValidUpc = useValidUpc(product, scannedUpc);
  const [scannedItems, setScannedItems] = useState(
    inProgressScans
      ? inProgressScans.map(scan => ({
          id: scan.id,
          upc: scan.scanned_upc,
          serials: scan.scanned_serial_number
        }))
      : []
  );
  const [alreadyScannedSerials, setAlreadyScannedSerials] = useState(
    scannedItems.map(item => item.serials)
  );
  const [scannedSerial, setScannedSerial] = useState('');
  const [isValidSerial, serialErrorMessage, serials, resetSerials] = useSerials(
    scannedSerial,
    setScannedSerial,
    product,
    alreadyScannedSerials
  );

  const [transactionReferenceId, setTransactionReferenceId] = useState(null);
  const [talliedQuantity, setTalliedQuantity] = useState(auditItem.tallied_quantity || 0);

  const [request, response] = useHttp('ims');

  useEffect(() => {
    if (isValidUpc === false) {
      setScannedUpc('');
      setGlobalLock(true);
      setGlobalMessage("UPC doesn't match");
    }
  }, [isValidUpc]);

  useEffect(() => {
    if (isValidSerial === false) {
      setScannedSerial('');
      setGlobalLock(true);
      setGlobalMessage(serialErrorMessage);
    }
  }, [isValidSerial]);

  useEffect(() => {
    async function quickAddScannedItem() {
      const auditTypeCode = deriveAuditTypeCode(product);
      const commitmentRequest = await request.post('/audit-adjustments/quick-add-scanned-item', {
        model: product.model,
        scannedUpc,
        serialNumbers: serials,
        auditBucket,
        auditBatchId,
        auditBatchName,
        auditTypeCode,
        talliedById: auth.username,
        scanningRequired: product.num_of_serials_per_item > 0,
        transactionReferenceId
      });
      if (response.ok) {
        const sortedSerials = serials.sort().join(':=:');
        let newId = null;
        if (auditTypeCode === AUDIT_TYPE_SERIALIZED) {
          newId = commitmentRequest.serials.find(
            serial => serial.scanned_serial_number === sortedSerials
          ).id;
        }
        setScannedItems([...scannedItems, { id: newId, upc: scannedUpc, serials: sortedSerials }]);
        setAlreadyScannedSerials([sortedSerials, ...alreadyScannedSerials]);
        setTransactionReferenceId(commitmentRequest.ims_transaction_reference_id);
        setTalliedQuantity(commitmentRequest.tallied_quantity);
      }
    }
    if (
      isValidUpc &&
      ((product.num_of_serials_per_item > 0 && serials.length >= product.num_of_serials_per_item) ||
        (product.num_of_serials_per_item === 0 && scannedUpc))
    ) {
      quickAddScannedItem();
      resetSerials();
      setScannedUpc('');
      setScannedSerial('');
    } else if (
      product.num_of_serials_per_item > 1 &&
      serials.length < product.num_of_serials_per_item
    ) {
      setScannedSerial('');
    }
  }, [serials, scannedUpc]);

  async function submitEmptyAuditScan() {
    const commitmentRequest = await request.post('/audit-adjustments/request', {
      model: product.model,
      auditBucket,
      auditBatchId,
      auditBatchName,
      auditTypeCode: deriveAuditTypeCode(product),
      talliedById: auth.username,
      scanningRequired: product.num_of_serials_per_item > 0,
      transactionReferenceId
    });
    if (response.ok) {
      setTransactionReferenceId(commitmentRequest.ims_transaction_reference_id);
    }
  }

  async function onSerialRemoval(committedAuditAdjustmentSerialId) {
    await request.delete(`/audit-adjustments/serials/${committedAuditAdjustmentSerialId}`);
    const selectedSerialToDelete = scannedItems.find(
      item => item.id === committedAuditAdjustmentSerialId
    ).serials;
    if (response.ok) {
      setScannedItems(scannedItems.filter(item => item.id !== committedAuditAdjustmentSerialId));
      setAlreadyScannedSerials(
        alreadyScannedSerials.filter(serial => serial !== selectedSerialToDelete)
      );
      setTalliedQuantity(talliedQuantity - 1);
    }
  }

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15 }}>
      {!isEmpty(globalMessage) && (
        <Typography className={classes.errorMessage}>{globalMessage}</Typography>
      )}
      <ProductInformation product={product} />
      <UPCSerialFields
        disabled={globalLock}
        scanUpc={event => setScannedUpc(event.target.value)}
        serialsNeeded={product.num_of_serials_per_item - serials.length}
        scanSerial={event => setScannedSerial(event.target.value)}
        validUpc={isValidUpc}
        validSerial={isValidSerial}
      />
      {product.num_of_serials_per_item > 1 && (
        <SerialsPerItemScanned
          serials={serials}
          numOfSerialsPerItem={product.num_of_serials_per_item}
        />
      )}
      <ScannedItems
        scannedItems={scannedItems}
        onSerialRemoval={onSerialRemoval}
        talliedQuantity={talliedQuantity}
        numOfSerialsPerItem={product.num_of_serials_per_item}
      />
      <Button variant="contained" onClick={setScannerTime} startIcon={<Close />}>
        Close Scanner
      </Button>
      {scannedItems.length === 0 && isEmpty(auditItem) && isSetup && (
        <Button
          style={{ marginLeft: '5px' }}
          variant="contained"
          onClick={async () => {
            await submitEmptyAuditScan();
            setScannerTime(false);
          }}
          startIcon={<Close />}
        >
          Close Scanner with Zero Found Quantity
        </Button>
      )}
    </div>
  );
}

export default Scanner;
