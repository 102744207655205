import React, { Component } from 'react';

type State = {
  hasError: boolean
}

type Props = {
  children: React.ReactNode
  fallback?: JSX.Element
}

type ErrorInfo = {
  componentStack: string,
};

class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    fallback: <h1>Sorry, something has gone wrong.</h1>
  }

  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo ) {
    // Display fallback UI
    console.info(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return fallback;
    }
    return children;
  }
}

export default ErrorBoundary;
