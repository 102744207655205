import React, { useEffect, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShippingTemplateMaps from './ShippingTemplateDisplay';
import { ShippingTemplateContext } from '../shippingTemplateContext';

export default function ShippingTemplateDisplayContainer() {
  const {
    shippingTemplates,
    getShippingTemplates,
    bucketGroupsList,
    getBucketGroupsList,
    platforms
  } = useContext(ShippingTemplateContext);

  useEffect(() => {
    if (bucketGroupsList.length === 0) {
      getBucketGroupsList();
    }
  }, []);

  if (shippingTemplates.length === 0) return <LinearProgress />;
  return (
    <ShippingTemplateMaps
      getShippingTemplates={getShippingTemplates}
      shippingTemplates={shippingTemplates}
      bucketGroupOptions={bucketGroupsList}
      platforms={platforms}
    />
  );
}
