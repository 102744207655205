import React, { useState, useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import { ShippingTemplateContext } from '../shippingTemplate/shippingTemplateContext';
import DialogDisplay from './DialogDisplay';

const useDialogStyles = makeStyles({
  paper: {
    background: 'white',
    padding: 15
  }
});

export default function AcivateRuleDialogCell({ usesTemplateCompositions, ruleCode, ruleId }) {
  const classes = useDialogStyles();
  const { getRules } = useContext(ShippingTemplateContext);

  function handleCloseActivateDialog() {
    setIsActivateDialogOpen(false);
    setActivateError('');
  }
  function handleOpenActivateDialog() {
    setIsActivateDialogOpen(true);
  }

  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [activateRequest, activateResponse] = useHttp('ims/shipping-templates/rule');
  const [activateSuccess, setActivateSuccess] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [activateError, setActivateError] = useState('');
  const [missingCompositions, setMissingCompositions] = useState([]);
  async function handleActivate() {
    setActivateLoading(true);
    await activateRequest.post(`/activate-compositions/${ruleId}`);

    if (activateResponse.ok) {
      setActivateLoading(false);
      setActivateSuccess(true);
      getRules();
    } else if (activateResponse.data && activateResponse.data.groups) {
      setActivateLoading(false);
      setActivateError(activateResponse.data.error);
      setMissingCompositions(activateResponse.data.groups);
    } else if (activateResponse.data && activateResponse.data.error) {
      setActivateLoading(false);
      setActivateError(activateResponse.data.error);
    } else {
      setActivateLoading(false);
      setActivateError('Unexpected error encountered while activating the rule.');
    }
  }

  return (
    <TableCell>
      {usesTemplateCompositions > 0 ? (
        <Button type="submit" variant="contained" color="secondary" disabled>
          Uses Compositions
        </Button>
      ) : (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleOpenActivateDialog}
        >
          Use Compositions
        </Button>
      )}
      <Dialog classes={classes} open={isActivateDialogOpen} onClose={handleCloseActivateDialog}>
        <DialogDisplay
          activateLoading={activateLoading}
          activateSuccess={activateSuccess}
          activateError={activateError}
          ruleCode={ruleCode}
          missingCompositions={missingCompositions}
        />
        <DialogActions>
          <Button disabled={activateLoading} color="primary" onClick={handleCloseActivateDialog}>
            {activateSuccess ? 'Done' : 'Cancel'}
          </Button>
          {!activateLoading && !activateSuccess && !activateError && (
            <Button disabled={activateLoading} color="primary" onClick={handleActivate}>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </TableCell>
  );
}
