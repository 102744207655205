import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import CarouselItem from './CarouselItem';

const SortableItem = SortableElement(CarouselItem);

const CarouselItemList = ({ items }) => {
  return (
    <div className="list-group">
      {items.map((item, i) => {
        return (
          <SortableItem
            index={i}
            key={item.id}
            id={item.id}
            link={item.link}
            img={item.img}
            errors={item.errors}
            sortOrder={items.findIndex(ogItem => ogItem.id === item.id) + 1}
            count={items.length}
          />
        );
      })}
    </div>
  );
};

export default CarouselItemList;
