import React, { MouseEventHandler, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import GlueButton from '../../Presentational/GlueButton';
import { BinManagementContext } from '../BinManagementContext';

export type CreateBinButtonsProps = {
  onCancel?: MouseEventHandler<HTMLButtonElement>;
};

export default function CreateBinButtons({ onCancel }: CreateBinButtonsProps) {
  const { createBinErrors, loadingCreateBin, createBinSuccess } = useContext(BinManagementContext);

  const createButtonText = createBinSuccess
    ? 'Bin Created Successfully'
    : loadingCreateBin
    ? 'Creating New Bin'
    : 'Create New Bin';

  const createButtonEndIcon = createBinSuccess ? 'done' : loadingCreateBin ? 'hourglass_empty' : '';

  return (
    <Grid container xs={12} justify="flex-end" spacing={3}>
      <Grid item>
        <GlueButton
          type="button"
          variant="outlined"
          size="large"
          color={createBinSuccess ? 'grey' : 'default'}
          fullWidth
          disabled={loadingCreateBin}
          onClick={onCancel}
        >
          Cancel
        </GlueButton>
      </Grid>
      <Grid item>
        <GlueButton
          type="submit"
          size="large"
          fullWidth
          endIcon={createButtonEndIcon}
          disabled={!isEmpty(createBinErrors) || loadingCreateBin}
          success={createBinSuccess}
        >
          {createButtonText}
        </GlueButton>
      </Grid>
    </Grid>
  );
}
