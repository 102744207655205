import { delimiter } from 'path';

export type CheckListItem = {
  rowIndex: number;
  row: JsonRow;
};

export type StringToJson = {
  table: JsonRow[];
  checkList: CheckListItem[];
};

export type JsonRow = {
  [key: string]: string | number;
};

const getDelimiter = (file: string): string => {
  const firstRowIndex = file.indexOf('\n');
  const checkRow = file.substr(0, firstRowIndex > -1 ? firstRowIndex : file.length);
  const comma = checkRow.match(/,/g)?.length || 0;
  const tab = checkRow.match(/\t/g)?.length || 0;
  if (comma === 0 && tab === 0) {
    throw new Error(`Couldn't parse delimiter type from row: ${checkRow.substr(0, 20)}...`);
  }
  return comma > tab ? ',' : '\t';
};

const getDelimiterRegex = (file: string): RegExp => {
  const delimiter = getDelimiter(file);

  // https://stackoverflow.com/a/53774647
  // Not tested on tabs may need more testing.
  return delimiter === ','
    ? // comma regex
      /,(?=(?:(?:[^"]*"){2})*[^"]*$)/
    : // tab regex
      /\t(?=(?:(?:[^"]*"){2})*[^"]*$)/;
};

const findHeaderRow = (file: string, delimiter: RegExp): number => {
  const rows = file.split('\n');
  let mostColumnsCount = 0;
  let headerRow = 0;


  for (let i = 0; i < Math.min(rows.length, 10); i++) {
    const rowColumnsCount = rows[i].split(delimiter).filter(val => val).length - 1;
    if (rowColumnsCount > mostColumnsCount) {
      headerRow = i;
      mostColumnsCount = rowColumnsCount;
    }
  }

  return headerRow;
};

const stringToJson = (
  result: string,
  startRow?: number,
  seperatorRegex = getDelimiterRegex(result)
): StringToJson => {
  const sr = startRow === undefined ? findHeaderRow(result, seperatorRegex) : startRow;
  const rows = result
    .split('\n')
    .slice(sr);

  const headers = rows.shift()?.split(seperatorRegex);
  if (!headers) {
    throw new Error('No headers found on csv');
  }
  const table: JsonRow[] = [];
  const checkList: CheckListItem[] = [];
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i].split(seperatorRegex);
    let count = 0;
    const jsonRow: JsonRow = {};
    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      if (!row[j]) count++;
      jsonRow[header] = row[j];
    }
    table.push({ ...jsonRow, rowIndex: i });
    if (count > Math.floor(0.7 * headers.length)) {
      checkList.push({ rowIndex: i, row: jsonRow });
    }
  }

  return { table, checkList };
};

export default stringToJson;
