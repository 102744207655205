import { isUserInputtableHtmlElement } from './ScanEventHelpers';
import ScanEvent from './ScanEvent';
import { ScanEventBusInterface, ScanEventHandler } from './ScanEventListeningTypes';

export default class ScanEventBus implements ScanEventBusInterface {
  scanEventListeners: ScanEventHandler[];

  constructor() {
    this.scanEventListeners = [];
  }

  addScanEventListener(handler: ScanEventHandler): void {
    this.scanEventListeners = [...this.scanEventListeners, handler];
  }

  removeScanEventListener(handlerToRemove: ScanEventHandler): void {
    this.scanEventListeners = this.scanEventListeners.filter(
      handler => handler !== handlerToRemove
    );
  }

  dispatch(scanEvent: ScanEvent): boolean {
    let eventDispatched: boolean = false;
    let stopPropagation: boolean = false;
    // Iterate through the registered handlers in reverse order and have each one dispatch the event,
    // so as to emulate the behavior of web browser events bubbling up the DOM tree.
    [...this.scanEventListeners].reverse().forEach((handler: ScanEventHandler): void => {
      if (stopPropagation) {
        return;
      }
      if (isUserInputtableHtmlElement(scanEvent) && !scanEvent.inputFieldListeningAllowed) {
        return;
      }
      handler(scanEvent);
      eventDispatched = true;
      if (!scanEvent.bubbles) {
        stopPropagation = true;
      }
    });
    return eventDispatched;
  }
}
