import { useCallback, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { AuthContextType } from '../../AuthTypes';
import {
  PrintPurchaseOrderAsPDF,
  SavePurchaseOrderAsPDF,
  SendPurchaseOrderAsPDF
} from './PurchaseOrderPDFTypes';
import { printPO, savePO, sendPO } from './PurchaseOrderPDFHelpers';

export function usePrintPurchaseOrderAsPDF(): PrintPurchaseOrderAsPDF {
  const accessToken = useContext<AuthContextType>(AuthContext).auth;

  const printPurchaseOrderAsPDF = useCallback<PrintPurchaseOrderAsPDF>(
    poNum => {
      return printPO(poNum, accessToken);
    },
    [accessToken]
  );

  return printPurchaseOrderAsPDF;
}

export function useSavePurchaseOrderAsPDF(): SavePurchaseOrderAsPDF {
  const accessToken = useContext<AuthContextType>(AuthContext).auth;

  const savePurchaseOrderAsPDF = useCallback<SavePurchaseOrderAsPDF>(
    poNum => {
      return savePO(poNum, accessToken);
    },
    [accessToken]
  );

  return savePurchaseOrderAsPDF;
}

export function useSendPurchaseOrderAsPDF(): SendPurchaseOrderAsPDF {
  const accessToken = useContext<AuthContextType>(AuthContext).auth;

  const sendPurchaseOrderAsPDF = useCallback<SendPurchaseOrderAsPDF>(
    (poNum, emailContent, recipientName, vendorEmail, ccs) => {
      return sendPO(poNum, accessToken, emailContent, recipientName, vendorEmail, ccs);
    },
    [accessToken]
  );

  return sendPurchaseOrderAsPDF;
}
