import React, { useState, useCallback, useEffect, useContext } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import Select from '../../common/LayoutComponents/Select';
import { ShippingTemplateContext } from './shippingTemplateContext';

const initialCreateRuleMapState = {
  ruleCode: '',
  shippingTemplate: ''
};

const useCreateRuleMapStyles = makeStyles({
  modalField: {
    minWidth: '100%'
  }
});

function CreateRuleMap({ setSubmit, isSelected, setDisableButton, setSubmissionState }) {
  const [createRuleMapState, setCreateRuleMapState] = useState(initialCreateRuleMapState);
  const [errors, setErrors] = useState({});
  const { shippingTemplates } = useContext(ShippingTemplateContext);
  const { getRuleMaps } = useContext(ShippingTemplateContext);

  const shippingTemplateList = shippingTemplates.map(({ shipping_template }) => shipping_template);

  const [ruleCodes, setRuleCodes] = useState([]);
  const [request, response] = useHttp('ims/shipping-templates/rule');

  const submitCreateRuleMap = useCallback(async () => {
    setDisableButton(true);
    setSubmissionState({ isSubmitting: true, isSubmitted: false });
    const submitResponse = await request.post('/mapping', { ...createRuleMapState });
    if (response.ok) {
      setCreateRuleMapState(initialCreateRuleMapState);
      getRuleMaps();
      setSubmissionState({ isSubmitting: false, isSubmitted: true });
    } else if (response.status === 422) {
      setSubmissionState({ isSubmitting: false, isSubmitted: false });
      setErrors(submitResponse);
      setDisableButton(false);
    }
  }, [createRuleMapState, getRuleMaps]);

  useEffect(() => {
    if (isSelected) {
      setSubmit(() => submitCreateRuleMap);
    }
  }, [submitCreateRuleMap, setSubmit, isSelected]);

  useEffect(() => {
    if (isSelected) {
      setDisableButton(!isEmpty(errors));
    }
  }, [isSelected, setDisableButton, errors]);

  useEffect(() => {
    async function getShippingRules() {
      const rules = await request.get('');

      if (response.ok) {
        setRuleCodes(rules.map(({ rule_code }) => rule_code));
      }
    }
    getShippingRules();
  }, [isSelected]);

  const handleChange = ({ target: { name, value } }) => {
    setErrors(previousErrorState => {
      const { [name]: removed, ...errorState } = previousErrorState;
      return errorState;
    });
    setCreateRuleMapState(previousState => ({
      ...previousState,
      [name]: value
    }));
  };

  const classes = useCreateRuleMapStyles();

  return (
    <List>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            inputValue={createRuleMapState.ruleCode}
            name="ruleCode"
            label="Rule Code"
            handleChange={handleChange}
            options={ruleCodes}
            error={!!errors.ruleCode}
            helperText={get(errors, 'ruleCode', []).join('')}
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            inputValue={createRuleMapState.shippingTemplate}
            name="shippingTemplate"
            label="Shipping Template"
            handleChange={handleChange}
            options={shippingTemplateList}
            error={!!errors.shippingTemplate}
            helperText={get(errors, 'shippingTemplate', []).join('')}
          />
        </FormControl>
      </ListItem>
    </List>
  );
}

export default CreateRuleMap;
