import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import Select from '../../common/LayoutComponents/Select';
import { ShippingTemplateContext } from './shippingTemplateContext';

const initialCreateCompositionState = {
  compositeShippingTemplate: '',
  simpleShippingTemplate: ''
};

const useCreateCompositionStyles = makeStyles({
  modalField: {
    minWidth: '100%'
  }
});

function CreateComposition({ setSubmit, isSelected, setDisableButton, setSubmissionState }) {
  const [createCompositionState, setCreateCompositionState] = useState(
    initialCreateCompositionState
  );
  const [errors, setErrors] = useState({});
  const { shippingTemplates, getCompositions } = useContext(ShippingTemplateContext);

  const computeFilteredShippingTemplates = templates => {
    const simpleShippingTemplates = [];
    const compositeShippingTemplates = [];
    if (templates.length) {
      shippingTemplates.forEach(shippingTemplate => {
        if (shippingTemplate.is_parent_template) {
          compositeShippingTemplates.push(shippingTemplate.shipping_template);
        } else {
          simpleShippingTemplates.push(shippingTemplate.shipping_template);
        }
      });
    }
    return [simpleShippingTemplates, compositeShippingTemplates];
  };

  const [simpleShippingTemplates, compositeShippingTemplates] = useMemo(
    () => computeFilteredShippingTemplates(shippingTemplates),
    [shippingTemplates]
  );

  const [request, response] = useHttp('ims/shipping-templates/composition');

  const submitCreateComposition = useCallback(async () => {
    setDisableButton(true);
    setSubmissionState({ isSubmitting: true, isSubmitted: false });
    const submitResponse = await request.post('', { ...createCompositionState });
    if (response.ok) {
      setCreateCompositionState(initialCreateCompositionState);
      getCompositions();
      setSubmissionState({ isSubmitting: false, isSubmitted: true });
    } else {
      setSubmissionState({ isSubmitting: false, isSubmitted: false });
      setErrors(submitResponse);
      setDisableButton(false);
    }
  }, [createCompositionState]);

  useEffect(() => {
    if (isSelected) {
      setSubmit(() => submitCreateComposition);
    }
  }, [submitCreateComposition, setSubmit, isSelected]);

  useEffect(() => {
    if (isSelected) {
      setDisableButton(!isEmpty(errors));
    }
  }, [isSelected, setDisableButton, errors]);

  const handleChange = ({ target: { name, value } }) => {
    setErrors(previousErrorState => {
      const { [name]: removed, ...errorState } = previousErrorState;
      return errorState;
    });
    setCreateCompositionState(previousState => ({
      ...previousState,
      [name]: value
    }));
  };

  const classes = useCreateCompositionStyles();

  return (
    <List>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            inputValue={createCompositionState.compositeShippingTemplate}
            name="compositeShippingTemplate"
            label="Composite Shipping Template"
            handleChange={handleChange}
            options={compositeShippingTemplates}
            error={!!errors.compositeShippingTemplate}
            helperText={get(errors, 'compositeShippingTemplate', []).join('')}
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            inputValue={createCompositionState.simpleShippingTemplate}
            name="simpleShippingTemplate"
            label="Simple Shipping Template"
            handleChange={handleChange}
            options={simpleShippingTemplates}
            error={!!errors.simpleShippingTemplate}
            helperText={get(errors, 'simpleShippingTemplate', []).join('')}
          />
        </FormControl>
      </ListItem>
    </List>
  );
}

export default CreateComposition;
