import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import { useHttp } from '../../common/Hooks';

interface CompanyData {
  total_unreceived_units: number;
  total_unreceived_dollars: number;
  invoiced_dollars: number;
  invoiced_but_not_received: number;
}

interface Report {
  grouped: CompanyData[];
  totals: CompanyData;
}

function useReport() {
  const [req, res] = useHttp('unreceived-invoice-report');
  const [report, setReport] = useState<Report | undefined>(undefined);
  useEffect(() => {
    async function getReport() {
      const reportData = await req.get();
      if (res.ok) {
        setReport(reportData);
      }
    }
    getReport();
  }, []);
  return report;
}

export default function UnReceivedInvoiceReport() {
  const report = useReport();
  if (!report) return <h2>Loading</h2>;
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vendor</TableCell>
            <TableCell>Total Un-Received Units</TableCell>
            <TableCell>Total Un-Received Dollars</TableCell>
            <TableCell>Invoiced Dollars</TableCell>
            <TableCell>Invoiced But Not Received</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(report.grouped).map(([company, data]) => (
            <TableRow>
              <TableCell>{company}</TableCell>
              <TableCell>{data.total_unreceived_units.toLocaleString()}</TableCell>
              <TableCell>${data.total_unreceived_dollars.toLocaleString()}</TableCell>
              <TableCell>${data.invoiced_dollars.toLocaleString()}</TableCell>
              <TableCell>${data.invoiced_but_not_received.toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell>{report.totals.total_unreceived_units.toLocaleString()}</TableCell>
            <TableCell>${report.totals.total_unreceived_dollars.toLocaleString()}</TableCell>
            <TableCell>${report.totals.invoiced_dollars.toLocaleString()}</TableCell>
            <TableCell>${report.totals.invoiced_but_not_received.toLocaleString()}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}
