import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';

function SiteMap({ routes, needsImplementing }) {
  const groupedRoutes = routes.reduce((group, route) => {
    const path = route.path ? route.path : route.relativePath;
    const routePathParams = path.split('/');
    const baseRoutePath =
      routePathParams[1] === undefined
        ? routePathParams[0]
        : routePathParams[0] === ''
        ? routePathParams[1]
        : routePathParams[0];

    if (group[baseRoutePath] !== undefined) {
      group[baseRoutePath].push(route);
    } else {
      group[baseRoutePath] = [route];
    }
    return group;
  }, {});

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper style={{ margin: '10px' }}>
          <h4
            style={{
              paddingLeft: '12px',
              paddingTop: '10px',
              fontWeight: 'bold'
            }}
          >
            Key
          </h4>
          <ul style={{ paddingBottom: '10px' }}>
            <li style={{ color: 'tomato' }}>Not a component...yet.</li>
            <li style={{ color: '#6805a6' }}>Not in the menu</li>
            <li style={{ color: 'forestgreen' }}>Live component in the menu</li>
          </ul>
        </Paper>
      </Grid>
      {Object.keys(groupedRoutes).map(baseRoutePath => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={baseRoutePath}>
            <Paper
              style={{
                margin: '10px',
                maxHeight: '220px',
                overflowY: 'scroll'
              }}
            >
              <h4
                style={{
                  paddingLeft: '12px',
                  paddingTop: '10px',
                  fontWeight: 'bold'
                }}
              >
                /{baseRoutePath}
              </h4>
              <ul style={{ paddingBottom: '10px' }}>
                {groupedRoutes[baseRoutePath].map(route => (
                  <li key={route.path}>
                    <Link
                      style={
                        route.Component === needsImplementing
                          ? { color: 'tomato' }
                          : !route.submenu
                          ? { color: '#6805a6' }
                          : { color: 'forestgreen' }
                      }
                      to={route.path}
                    >
                      {route.path}
                    </Link>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default SiteMap;
