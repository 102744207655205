import React, { useContext, useEffect } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { ALL_LOCATIONS, locations } from '../../../actions/locationActions';
import { GlueTheme } from '../../../GlueThemeType';
import GlueSelect from '../../Presentational/GlueSelect';
import { BinManagementContext } from '../BinManagementContext';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    label: {
      fontWeight: 500,
      color: theme.palette.grey[900],
      letterSpacing: '0px'
    }
  })
);

export default function BinLocationSelect() {
  const classes = useStyles();

  const { createBinErrors, setCreateBinErrors, setCreateBinData, createBinData } = useContext(
    BinManagementContext
  );

  useEffect(() => {
    const { locationCode, ...other } = createBinErrors;
    setCreateBinErrors({
      ...other
    });

  }, [createBinData.locationCode])

  const locationOptions = locations
    .filter((locationCode: string) => locationCode !== ALL_LOCATIONS)
    .map((locationCode: string) => {
      return {
        key: locationCode,
        value: locationCode,
        name: locationCode
      };
    });

  const handleLocationChange = (event: any) => {
    let newLocationCode = '';
    if (typeof event.target.value === 'string') {
      newLocationCode = event.target.value;
    }
    setCreateBinData({
      ...createBinData,
      locationCode: newLocationCode
    });
  };

  return (
    <Grid container justify={'space-between'} xs={12} spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.label} variant={'body1'}>
          Bin Warehouse:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <GlueSelect
          fullWidth
          required
          options={locationOptions}
          accessibleLabelId={'bin-location-select'}
          label={'Bin Warehouse Location'}
          handleChange={handleLocationChange}
          value={createBinData.locationCode}
          error={!!createBinErrors.locationCode}
          helperText={
            get(createBinErrors, 'locationCode', []).join('') || 'Select Bin Warehouse Location'
          }
        />
      </Grid>
    </Grid>
  );
}
