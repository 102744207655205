import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TransferForm from './TransferForm';
import Can from '../../../roles/Can';

const useAdminButtonStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: -50,
    right: 0
  },
  label: {
    fontWeight: theme.typography.fontWeightBold
    // color: theme.palette.common.white
  }
}));

export function AdminOnlyTransferButton({ handleOpenAdminTransfer }) {
  const classes = useAdminButtonStyles();

  return (
    <Can
      perform="admin:override"
      yes={() => (
        <Button
          onClick={handleOpenAdminTransfer}
          variant="contained"
          color="secondary"
          classes={classes}
        >
          Admin-Level Transfer Tool
        </Button>
      )}
    />
  );
}

AdminOnlyTransferButton.propTypes = {
  handleOpenAdminTransfer: PropTypes.func
};

AdminOnlyTransferButton.defaultProps = {
  handleOpenAdminTransfer: () => {}
};

const useAdminTransferStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function AdminTransfer({ open, handleClose, transferTypes, handleSubmit }) {
  const classes = useAdminTransferStyles();
  const [item, setItem] = useState({
    location_code: '',
    condition_code: '',
    bucket: '',
    quantity: 0,
    is_scanning_required: false,
    additional_fields: false
  });

  useEffect(() => {
    setItem(previousState => {
      const additional_fields =
        (item.bucket.includes('KY01') ||
          item.bucket.includes('TX01') ||
          item.bucket.includes('AZ02')) &&
        item.location_code.includes('_INBOUND');
      return {
        ...previousState,
        additional_fields
      };
    });
  }, [item.bucket, item.location_code]);

  const handleChange = ({ target: { name, value } }) => {
    const NAME_TO_BUCKET_VALUE_MAP = {
      fromBucket: 'bucket',
      toBucket: 'location_code'
    };
    setItem(previousState => ({
      ...previousState,
      [NAME_TO_BUCKET_VALUE_MAP[name] || name]: value
    }));
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Admin-Level Transfer</Typography>
        <IconButton className={classes.closeButton} edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <TransferForm
        admin
        item={item}
        toggleScanningRequired={handleChange}
        handleSubmit={e => handleSubmit(e, true)}
        handleInputChange={handleChange}
        transferTypes={transferTypes}
        handleSelectChange={handleChange}
      />
    </Dialog>
  );
}

export default AdminTransfer;

AdminTransfer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  transferTypes: PropTypes.arrayOf(PropTypes.string)
};

AdminTransfer.defaultProps = {
  handleClose: () => {},
  handleSubmit: () => {},
  transferTypes: []
};
