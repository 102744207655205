import { isEmpty, get } from 'lodash';
import { TransactedReceiptSnapshot } from '../../../common/Types/DbSnapshots/InventoryEventDbSnapshotTypes';
import { matchesUpc } from '../../../helpers/scanValidator';

export const unReceiveUpcMatches = (
  scannedUpc: string,
  unReceivableList: TransactedReceiptSnapshot[]
): boolean =>
  unReceivableList.length > 0 &&
  !isEmpty(unReceivableList[0].product) &&
  matchesUpc(scannedUpc, unReceivableList[0].product);

export const unReceiveSerialMatches = (
  joinedSerial: string,
  unReceivableList: TransactedReceiptSnapshot[]
): boolean =>
  unReceivableList.map(i => get(i, 'serials[0].scanned_serial_number', 0)).includes(joinedSerial);
