import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { useHttp, useQueryWithLocation as useQuery } from '../../common/Hooks';
import {
  AddPurchaseOrderItem,
  DeletePurchaseOrderItem,
  FetchPOFromLegacyPO,
  FinishPurchaseOrder,
  GetPOReceivableLocations,
  GetPurchaseOrder,
  PurchaseOrderParams,
  ReviewReceivedSerials,
  UpdatePOStatusToOrderConfirmed,
  UpdatePurchaseOrder,
  UpdatePurchaseOrderItem,
  UpdatePurchaseOrderTotals,
  UsePONumber,
  UseReviewReceivedSerials,
  GetPoTerms
} from './PurchaseOrderDetailsTypes';
import { TransactedReceiptSerialSnapshot } from '../../common/Types/DbSnapshots/InventorySerialEventDbSnapshotTypes';
import { PurchaseOrderStatus } from '../../common/Types/ApiOsposDbModels/PurchaseOrderTypes';

export function useGetPurchaseOrder(): GetPurchaseOrder {
  const [request, response] = useHttp('');

  const getPurchaseOrder = useCallback<GetPurchaseOrder>(async param => {
    const poResponse = await request.get(`purchase/getFull/${param}`);
    if (response.ok) {
      return poResponse.data;
    }
    return null;
  }, []);

  return getPurchaseOrder;
}

export function useUpdatePurchaseOrder(): UpdatePurchaseOrder {
  const [request, response] = useHttp('');

  const updatePurchaseOrder = useCallback<UpdatePurchaseOrder>(
    async (poNum, updatePurchaseOrderRequest) => {
      const updatedPurchaseOrder = await request.put(
        `purchase/${poNum}`,
        updatePurchaseOrderRequest
      );
      if (response.ok) {
        return updatedPurchaseOrder.data;
      }
      return null;
    },
    []
  );

  return updatePurchaseOrder;
}

export function useUpdatePurchaseOrderItem(): UpdatePurchaseOrderItem {
  const [request, response] = useHttp('');

  const updatePurchaseOrderItem = useCallback<UpdatePurchaseOrderItem>(
    async (purchaseOrderItemId, updatePurchaseOrderItemRequest) => {
      const updateReponse = await request.put(
        `purchase/orderItem/${purchaseOrderItemId}`,
        updatePurchaseOrderItemRequest
      );
      if (response.ok) {
        return updateReponse.data;
      }
      return null;
    },
    []
  );

  return updatePurchaseOrderItem;
}

export function usePONumber(): UsePONumber {
  const { id: paramId } = useParams<PurchaseOrderParams>();
  const [request, response] = useHttp('purchase');
  const legacy = Boolean(useQuery().get('legacy'));
  const [updatedPONum, setUpdatedPoNum] = useState<number | null>(null);

  const fetchPOFromLegacyPO = useCallback<FetchPOFromLegacyPO>(async id => {
    const legacyPo = await request.get(`?legacyPoNum=${id}`);
    if (response.ok) {
      setUpdatedPoNum(_get(legacyPo, 'data[0].id', ''));
    }
  }, []);

  useEffect(() => {
    if (legacy) {
      fetchPOFromLegacyPO(Number(paramId));
    }
  }, [paramId, legacy, fetchPOFromLegacyPO]);

  const poNum = legacy ? updatedPONum : Number(paramId);

  return {
    poNum,
    fetchPOFromLegacyPO,
    legacy
  };
}

export function useUpdatePOStatusToOrderConfirmed(): UpdatePOStatusToOrderConfirmed {
  const [request, response] = useHttp('');

  const updatePOStatusToOrderConfirmed = useCallback<UpdatePOStatusToOrderConfirmed>(
    async poNum => {
      await request.put(`purchase/confirmOrder/${poNum}`);
      return response.ok;
    },
    []
  );

  return updatePOStatusToOrderConfirmed;
}

export function useReviewReceivedSerials(): UseReviewReceivedSerials {
  const [request, response] = useHttp('');

  const [receivedSerials, setReceivedSerials] = useState<
    TransactedReceiptSerialSnapshot[][] | 'No serials found' | null
  >(null);

  const reviewReceivedSerials = useCallback<ReviewReceivedSerials>(async poItemId => {
    const receivedSerialsResponse = await request.get(
      `ims/transacted-receipts/poItem/serials/${poItemId}`
    );

    if (response.ok) {
      setReceivedSerials(receivedSerialsResponse.serials);
      return receivedSerialsResponse.serials;
    }
    return [];
  }, []);

  const resetReceivedSerials = useCallback<() => void>(() => {
    setReceivedSerials(null);
  }, []);

  return {
    receivedSerials,
    reviewReceivedSerials,
    resetReceivedSerials
  };
}

export function usePOStatuses(): PurchaseOrderStatus[] {
  // This surprisingly works! It will auto-fetch just by attempting to access the value
  // of the variable (probably using some sort of proxy or magic getter under the hood).
  const [poStatuses] = useHttp('purchase/status', {
    onMount: true
  });
  return poStatuses.data ? poStatuses.data.data || [] : [];
}

export function useAddPurchaseOrderItem(): AddPurchaseOrderItem {
  const [request, response] = useHttp('');

  const addPurchaseOrderItem = useCallback<AddPurchaseOrderItem>(
    async addPurchaseOrderItemRequest => {
      const newPurchaseOrderItem = await request.post(
        `purchase/orderItem`,
        addPurchaseOrderItemRequest
      );
      if (response.ok) {
        return newPurchaseOrderItem.data;
      }
      return null;
    },
    []
  );

  return addPurchaseOrderItem;
}

export function useSendPurchaseViaEdi(): any {
  const [request, response] = useHttp('');

  const sendPurchase = useCallback<any>(
    async (purchaseId: any) => {
      await request.post(`purchase/direct/${purchaseId}`);
      return response;
    },
    [request, response]
  );
  return sendPurchase;
}

export function useDeletePurchaseOrderItem(): DeletePurchaseOrderItem {
  const [request, response] = useHttp('');

  const deletePurchaseOrderItem = useCallback<DeletePurchaseOrderItem>(
    async purchaseOrderItemId => {
      await request.delete(`purchase/orderItem/${purchaseOrderItemId}`);
      return response.ok;
    },
    []
  );

  return deletePurchaseOrderItem;
}

export function useUpdatePurchaseOrderTotals(): UpdatePurchaseOrderTotals {
  const [request, response] = useHttp('');

  const updatePurchaseOrderTotals = useCallback<UpdatePurchaseOrderTotals>(async poNum => {
    await request.get(`purchase/updateTotals/${poNum}`);
    return response.ok;
  }, []);

  return updatePurchaseOrderTotals;
}

export function useGetPoTerms(): GetPoTerms {
  const [request, response] = useHttp('');

  const getPoTerms = useCallback<GetPoTerms>(async () => {
    const poTerms = await request.get('purchase/terms');
    if (response.ok) {
      return poTerms || [];
    }
    return [];
  }, []);

  return getPoTerms;
}

export function useGetPOReceivableLocations(): GetPOReceivableLocations {
  const [request, response] = useHttp('');

  const getPOReceivableLocations = useCallback<GetPOReceivableLocations>(async () => {
    const poReceivableLocations = await request.get('ims/canReceivePos');
    if (response.ok) {
      return poReceivableLocations || [];
    }
    return [];
  }, []);

  return getPOReceivableLocations;
}

export function useFinishPurchaseOrder(): FinishPurchaseOrder {
  const [request, response] = useHttp('');

  const finishPurchaseOrder = useCallback<FinishPurchaseOrder>(async poNum => {
    const finishPurchaseOrderResponse = await request.get(`purchase/finish/${poNum}`);
    if (response.ok) {
      return finishPurchaseOrderResponse.data;
    }
    return null;
  }, []);

  return finishPurchaseOrder;
}
