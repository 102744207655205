/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

const getIndex = (state, action) =>
  state.findIndex(carouselItem => carouselItem.id === action.payload.id);

const arrayMove = (inputArray, fromIndex, toIndex) => {
  const array = [...inputArray];
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
  return array;
};

export const carouselSlice = createSlice({
  name: 'carouselItems',
  initialState: [],
  reducers: {
    addCarouselItem: (state, action) => {
      const carouselItem = {
        id: uniqueId('crslItem'),
        link: action.payload.link,
        img: action.payload.img,
        errors: {
          img: false,
          link: false
        }
      };
      state.push(carouselItem);
    },
    updateCarouselItem: (state, action) => {
      state[getIndex(state, action)].link = action.payload.link;
      state[getIndex(state, action)].errors = action.payload.errors;
    },
    deleteCarouselItem: (state, action) => {
      return state.filter(carouselItem => carouselItem.id !== action.payload.id);
    },
    reorderCarouselItems: (state, action) => {
      const newCarouselItems = arrayMove(state, action.payload.oldIndex, action.payload.newIndex);
      return newCarouselItems;
    },
    // eslint-disable-next-line no-unused-vars
    reset: state => {
      //
    }
  }
});

export const {
  addCarouselItem,
  updateCarouselItem,
  deleteCarouselItem,
  reorderCarouselItems,
  reset
} = carouselSlice.actions;

export default carouselSlice.reducer;
