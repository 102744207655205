import React, { useContext, useEffect, useState } from 'react';
import GlueDate from '../../Presentational/GlueDate';
import GlueTable, { GlueTableColumn, GlueTableRow } from '../../Presentational/GlueTable';
import { PickListContext } from './PickListContext';
import { LIST_TYPE_ENUM, PickableItem } from './PickListTypes';
import BinList from './BinList';
import PrintColumnHeader from './PrintColumnHeader';
import PrintCellContents from './PrintCellContents';
import ShipPriorityColumnHeader from './ShipPriorityColumnHeader';
import PrintedAtColumnHeader from './PrintedAtColumnHeader';
import { get, isEmpty } from 'lodash';

export interface PickListTableProps {
  printVersion?: boolean;
};

export default function PickListTable({ printVersion }: PickListTableProps) {
  const {
    pickList,
    pickListErrors,
    loadingPickList,
    queryParams,
    itemsToPrint,
    setItemsToPrint,
    printPickListErrors
   } = useContext(PickListContext);

   const [rowsPerPage, setRowsPerPage] = useState(25);
   const handleChangeRowsPerPage = (rowsPerPage: number) => {
     setRowsPerPage(rowsPerPage);
   }

   const [page, setPage] = useState(0);
   const handleChangePage = (newPage: number) => {
     setPage(newPage);
   }

  useEffect(() => {
    if (loadingPickList) {
      setItemsToPrint([]);
    }
  }, [loadingPickList])

  const getShipPriority = (pickableItem: PickableItem) => {
    if (pickableItem.isPrime && pickableItem.isPremium) {
      return 'Prime/Premium';
    }
    if (pickableItem.isPremium) {
      return 'Premium';
    }
    if (pickableItem.isPrime) {
      return 'Amazon Prime';
    }
    if (pickableItem.isExpress) {
      return 'Walmart Express';
    }
  }

  const hasPrintItemError = (pickableItem: PickableItem) => {
    if (printPickListErrors && printPickListErrors.itemErrors && printPickListErrors.itemErrors[pickableItem.transactionReferenceId]) {
      return true;
    }
    return false;
  }

  const getHighlightColor = (pickableItem: PickableItem) => {
    if (printVersion && hasPrintItemError(pickableItem)) {
      return 'error';
    }
    if (!!getShipPriority(pickableItem)) {
      if (printVersion) {
        return 'darkGray'
      }
      return 'secondaryOrange';
    }
  }

  const start = page * rowsPerPage
  const end = ( page + 1 ) * rowsPerPage;

  const rowsToMap = printVersion
    ? pickList.filter((pickableItem: PickableItem) => {
      return itemsToPrint.indexOf(pickableItem.transactionReferenceId) > -1;
    })
    : pickList;
  const rows: GlueTableRow[] = rowsToMap.map((pickableItem, i) => {
    const availableBins = (!pickableItem.printedAt
      && Array.isArray(pickableItem.availableBins)
      && pickableItem.availableBins.length > 0)
        ? pickableItem.availableBins
        : [pickableItem.suggestedBin];
    return {
      key: pickableItem.transactionReferenceId,
      highlightColor: getHighlightColor(pickableItem),
      data: {
        ...pickableItem,
        shipByDate: <GlueDate date={pickableItem.shipByDate} />,
        printedAt: <GlueDate includeTime date={pickableItem.printedAt} />,
        print: <PrintCellContents
          pickableItem={pickableItem}
          printVersion={printVersion}
           />,
        isFreight: pickableItem.isFreight ? 'Freight' : 'Parcel',
        availableBins: (<BinList
          availableBins={availableBins}
          isPrintVersion={printVersion} />),
        shipPriority: getShipPriority(pickableItem),
      }
    }
  });


  const columns: GlueTableColumn[] = [
    {
      columnName: (<PrintColumnHeader
          start={start}
          end={end}
          printVersion={printVersion}
        />),
      columnKey: 'print',
      align: printVersion ? 'left' : 'right',
    },
    {
      columnName: 'Product Model',
      columnKey: 'model'
    },
    {
      columnName: 'Product UPC',
      columnKey: 'upc'
    },
    {
      columnName: 'Ship By Date',
      columnKey: 'shipByDate'
    },
    {
      columnName: <ShipPriorityColumnHeader printVersion={printVersion} />,
      columnKey: 'shipPriority'
    },
    {
      columnName: 'Ship Type',
      columnKey: 'isFreight'
    }
];

  if (!printVersion) {
    columns.push({
      columnName: 'Warehouse',
      columnKey: 'warehouse'
    })
  }

  columns.push({
    columnName: printVersion
      ? 'Available Bins'
      : 'Suggested Bin',
    columnKey: 'availableBins'
  });
  columns.push({
    columnName: 'Condition',
    columnKey: 'condition'
  });

  if (!printVersion) {
    columns.push({
      columnName: 'Invoice',
      columnKey: 'invoice'
    });
    columns.push({
      columnName: <PrintedAtColumnHeader />,
      columnKey: 'printedAt'
    });
  }

  if (queryParams.listType === LIST_TYPE_ENUM.PRINTED) {
    columns.splice(columns.length - 1, 0, {
      columnName: 'List Id',
      columnKey: 'pickListId'
    });
  }


  return (
      <GlueTable
        expandableHeight
        accessibleAriaLabel={'pick-list-table'}
        orderBy={'shipBy'}
        sortDirection={'desc'}
        isLoading={loadingPickList}
        isErrors={!isEmpty(pickListErrors)}
        errorMessage={
          get(pickListErrors, 'warehouse', []).join('')
          || 'There was an error retrieving the pick list.'
        }
        columns={columns}
        rows={rows}
        paginationProps={{
          onChangeRowsPerPage: handleChangeRowsPerPage,
          onChangePage: handleChangePage,
          page: page,
          rowsPerPage: rowsPerPage,
          count: rows.length,
          rowsPerPageOptions: [5, 10, 25, 50, 100]
        }}
        excludePagination={printVersion}
        disableElevation={printVersion}
      />
  );
}
