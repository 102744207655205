import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import orderBy from 'lodash/orderBy';
import { ImsSearchContext } from './InventoryTransactionSearchWrapper';
import { useHttp, useQueryWithLocation as useQuery } from '../../common/Hooks';

const useTransactionListItemStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1, 0)
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  flexContainer: {
    position: 'relative',
    paddingLeft: theme.spacing(5.5),
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1)
  },
  typography: {
    flex: '1 1 200px',
    flexBasis: '25%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '33%'
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%'
    }
  },
  titleTypography: {
    fontWeight: 'bold'
  },
  committedIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1)
  }
}));

const useImsSearchResults = () => {
  const imsSearchType = useContext(ImsSearchContext);
  const [request, response] = useHttp('ims/');
  const [transactions, setTransaction] = useState(null);
  const id = useQuery().get('id');

  const getShipmentList = useCallback(
    async posTransferBatch => {
      const [committed, transacted] = await Promise.all([
        request.get(`committed-transfers/inbound?${imsSearchType}s[]=${posTransferBatch}`),
        request.get(`transacted-transfers/inbound?${imsSearchType}s[]=${posTransferBatch}`)
      ]);
      if (response.ok && Array.isArray(committed) && Array.isArray(transacted)) {
        setTransaction(
          orderBy([...committed, ...transacted], ['ims_transaction_reference_id'], ['desc'])
        );
      }
    },
    [imsSearchType]
  );

  useEffect(() => {
    if (id) {
      getShipmentList(id);
    }
  }, [id]);

  return [transactions, getShipmentList, imsSearchType];
};

function TransactionList() {
  const [transactions] = useImsSearchResults();

  if (!Array.isArray(transactions)) return null;

  return transactions.map(item => <TransactionListItem transaction={item} />);
}

function TransactionListItem({ transaction }) {
  const classes = useTransactionListItemStyles();
  const type = transaction.transferred_at ? 'transferred' : 'committed';
  const notes = transaction.transfer_notes || transaction.committed_notes;

  const TitleAndText = ({ title, children }) => (
    <Typography className={classes.typography} noWrap>
      <Typography className={classes.titleTypography} component="span">
        {title}
      </Typography>{' '}
      {children}
    </Typography>
  );

  return (
    <Card className={classes.root}>
      <CardContent className={classes.flexContainer}>
        <div className={classes.committedIcon}>
          <Tooltip title={startCase(type)}>
            {type === 'transferred' ? (
              <CheckedIcon fontSize="large" color="primary" />
            ) : (
              <UncheckedIcon fontSize="large" color="primary" />
            )}
          </Tooltip>
        </div>
        <TitleAndText title="Batch Name:">{transaction.pos_transfer_batch_name}</TitleAndText>
        <TitleAndText title="Batch Ref Id:">{transaction.pos_transfer_batch_ref_id}</TitleAndText>
        <TitleAndText title="Quantity:">{get(transaction, `${type}_quantity`, '')}</TitleAndText>
        <TitleAndText title="SKU:">
          {transaction.receiver_sku || transaction.sender_sku || transaction.product.model}
        </TitleAndText>
        <TitleAndText title="From-Bucket:">{transaction.from_bucket}</TitleAndText>
        <TitleAndText title="To-Bucket:">{transaction.to_bucket}</TitleAndText>
        <TitleAndText title={`${startCase(type)} At:`}>
          {get(transaction, `${type}_at`)}
        </TitleAndText>
        <TitleAndText title="Scanning Required:">
          {transaction.scanning_required ? 'Yes' : 'No'}
        </TitleAndText>
        <TitleAndText title="User:">{get(transaction, `${type}_by_id`)}</TitleAndText>
        {notes ? <Typography title="Notes:">{notes}</Typography> : null}
      </CardContent>
      <CardActions>
        <Button
          style={{ color: 'inherit' }}
          component={Link}
          to={`/inventory-transactions/${transaction.ims_transaction_reference_id}`}
        >
          {transaction.ims_transaction_reference_id} Details
        </Button>
      </CardActions>
    </Card>
  );
}

export default TransactionList;
