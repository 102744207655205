import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { useHttp } from '../../common/Hooks';
import TableView from '../../common/LayoutComponents/Table';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginRight: theme.spacing(2)
  },
  info: {
    marginLeft: theme.spacing(2)
  }
}));

function ReportDetails({ reportDetails }) {
  return Object.entries(reportDetails).map(([vendor, info]) => (
    <Paper style={{ padding: 20, marginTop: 20, marginBottom: 20 }}>
      <h2>{vendor}</h2>
      <TableView
        header={[
          'PO Number',
          'PO Status',
          'Last Received',
          'QTY Ordered',
          'QTY Received',
          'Date Ordered',
          'Vendor'
        ]}
        initialRowsPerPage={info.length}
      >
        {info.map(value => (
          <tr>
            {Object.values(value).map(item => (
              <td>{item}</td>
            ))}
          </tr>
        ))}
      </TableView>
      <h3 style={{ textAlign: 'right' }}>
        Total QTY Received:{' '}
        {info.reduce((previous, current) => {
          console.info(previous, current['QTY Received']);
          return previous + parseInt(current['QTY Received'], 10);
        }, 0)}
      </h3>
    </Paper>
  ));
}

export default function AccountingInventoryReport() {
  const [request, response] = useHttp('accounting-inventory-report');
  const [model, setModel] = useState('');
  const [error, setError] = useState('');
  const [reportDetails, setReportDetails] = useState([]);
  const classes = useStyles();

  async function getReportForModel() {
    setError('');
    const reportRequest = await request.get(`?model=${model}`);
    if (response.ok) {
      setReportDetails(reportRequest);
    } else {
      setReportDetails([]);
      setError(reportRequest.error || 'Error');
    }
  }

  return (
    <div>
      <div className={classes.container}>
        <TextField
          value={model}
          variant="outlined"
          className={classes.textField}
          size="x-sm"
          onKeyDown={e => {
            if (e.keyCode === 13) {
              getReportForModel();
            }
          }}
          onChange={e => setModel(e.target.value)}
        />
        <Button variant="contained" onClick={getReportForModel}>
          Search
        </Button>
        <div className={classes.info}>
          <p>{error}</p>
          <p>{request.loading && 'Loading'}</p>
        </div>
      </div>
      <ReportDetails reportDetails={reportDetails} />
    </div>
  );
}
