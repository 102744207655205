import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useHttp } from '../../common/Hooks';

export const ShippingTemplateContext = createContext({
  shippingTemplates: [],
  getShippingTemplates: () => {},
  rules: [],
  getRules: () => {},
  maps: [],
  getMaps: () => {},
  ruleMaps: [],
  getRuleMaps: () => {},
  compositeTemplateMaps: [],
  getCompositeTemplateMaps: () => {},
  bucketGroupsList: [],
  getBucketGroupsList: () => {}
});

export const useShippingTemplates = () => {
  const [shippingTemplates, setShippingTemplates] = useState([]);
  const [request, response] = useHttp('ims/shipping-templates');

  const getShippingTemplates = useCallback(async () => {
    const shippingTemplateResponse = await request.get('');
    if (response.ok) {
      setShippingTemplates(shippingTemplateResponse);
    }
  }, []);

  return [shippingTemplates, getShippingTemplates];
};

export const useRules = () => {
  const [rules, setRules] = useState([]);
  const [request, response] = useHttp('ims/shipping-templates');

  const getRules = useCallback(async () => {
    const ruleResponse = await request.get(`/rule`);
    if (response.ok) {
      setRules(ruleResponse);
    }
  }, []);

  return [rules, getRules];
};

export const useRuleMaps = () => {
  const [ruleMaps, setRuleMaps] = useState([]);
  const [request, response] = useHttp('ims/shipping-templates');

  const getRuleMaps = useCallback(async () => {
    const ruleMapResponse = await request.get(`/rule/mapping`);
    if (response.ok) {
      setRuleMaps(ruleMapResponse);
    }
  }, []);

  return [ruleMaps, getRuleMaps];
};

export const useCompositions = () => {
  const [compositions, setCompositions] = useState([]);
  const [request, response] = useHttp('ims/shipping-templates');

  const getCompositions = useCallback(async () => {
    const compositionReponse = await request.get(`/composition`);
    if (response.ok) {
      setCompositions(compositionReponse);
    }
  }, []);

  return [compositions, getCompositions];
};

const useBucketGroups = () => {
  const [bucketGroupsList, setBucketGroupsList] = useState([]);
  const [request, response] = useHttp('ims/bucket-groups');

  const getBucketGroupsList = useCallback(async () => {
    const bucketGroupsResponse = await request.get();
    if (response.ok) {
      const options = bucketGroupsResponse.map(({ bucket_group }) => {
        return { label: bucket_group, value: bucket_group };
      });
      setBucketGroupsList(options);
    }
  });

  return [bucketGroupsList, getBucketGroupsList];
};

export const usePlatforms = () => {
  const [platforms, setPlatforms] = useState([]);
  const getPlatforms = useCallback(() => {
    setPlatforms([
      { platform_code: 'amazon', platform_name: 'Amazon', uses_default_bucket_group: true },
      {
        platform_code: 'amazon_home',
        platform_name: 'Amazon Home',
        uses_default_bucket_group: true
      },
      { platform_code: 'walmart', platform_name: 'Walmart', uses_default_bucket_group: false },
      { platform_code: 'walts', platform_name: 'Walts', uses_default_bucket_group: false }
    ]);
  }, []);
  return [platforms, getPlatforms];
};

export function ShippingTemplatesProvider({ children }) {
  const [shippingTemplates, getShippingTemplates] = useShippingTemplates();
  const [rules, getRules] = useRules();
  const [ruleMaps, getRuleMaps] = useRuleMaps();
  const [compositions, getCompositions] = useCompositions();
  const [bucketGroupsList, getBucketGroupsList] = useBucketGroups();
  const [platforms, getPlatforms] = usePlatforms();

  useEffect(() => {
    getShippingTemplates();
  }, [getShippingTemplates]);

  useEffect(() => {
    getRules();
  }, [getRules]);

  useEffect(() => {
    getRuleMaps();
  }, [getRuleMaps]);

  useEffect(() => {
    getPlatforms();
  }, [getPlatforms]);

  return (
    <ShippingTemplateContext.Provider
      value={{
        shippingTemplates,
        getShippingTemplates,
        rules,
        getRules,
        ruleMaps,
        getRuleMaps,
        compositions,
        getCompositions,
        bucketGroupsList,
        getBucketGroupsList,
        platforms
      }}
    >
      {children}
    </ShippingTemplateContext.Provider>
  );
}
