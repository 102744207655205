import React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { makeBinPaperStyles } from './PickItemStyles';

const useStyles = makeStyles(theme => ({
  availableBinPaper: makeBinPaperStyles(theme),
  suggestedBinPaper: {
    ...makeBinPaperStyles(theme),
    backgroundColor: theme.palette.success.light,
    fontWeight: 'bold'
  },
  binHelperText: {
    fontSize: '14px',
    textAlign: 'center'
  }
}));

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {import('@material-ui/core').PaperProps} props.paperProps
 * @param {string | null} props.helperText
 */
export function AvailableBinGridPaper({ children, paperProps = {}, helperText = null }) {
  const classes = useStyles();
  return (
    <Grid item md={4} sm={6} xs={12}>
      <Paper className={classes.availableBinPaper} {...paperProps}>
        {children}
      </Paper>
      {!!helperText && <Typography className={classes.binHelperText}>{helperText}</Typography>}
    </Grid>
  );
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function SuggestedBinGridPaper({ children }) {
  const classes = useStyles();
  return (
    <AvailableBinGridPaper
      paperProps={{
        className: classes.suggestedBinPaper
      }}
      helperText="(Suggested Bin)"
    >
      {children}
    </AvailableBinGridPaper>
  );
}
