import React, { Key, ReactNode, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { createStyles, TablePagination } from '@material-ui/core';
import GlueEmptyDisplay from './GlueEmptyDisplay';
import { GlueTheme } from '../../GlueThemeType';
import GlueErrorDisplay from './GlueErrorDisplay';
import GlueTableSkeletons from './GlueTableSkeletons';
import GlueTableRows from './GlueTableRows';
import GlueTableHeaders from './GlueTableHeaders';

const GlueTablePagination = TablePagination as any;

const minHeight = 420;

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    table: {
      minWidth: 650
    },
    tableContainer: {
      minHeight: minHeight,
      maxHeight: minHeight,
    },
    tableContainerExpandable: {
      minHeight: minHeight,
    },
    tablePaper: {
      width: '100%',
      overflow: 'hidden'
    },
    emptyMessage: {
      minHeight: minHeight
    },
    tableBody: {
      minHeight: minHeight
    }
  })
);

const columnCompareFunction = (colA: GlueTableColumn, colB: GlueTableColumn): number => {
  if (colA.orderNumber && colB.orderNumber) {
    return colA.orderNumber - colB.orderNumber;
  }
  return 0;
};

export type GlueTableProps = {
  accessibleAriaLabel: string;
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
  onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void;
  isLoading: boolean;
  isErrors: boolean;
  errorMessage?: string;
  columns: GlueTableColumn[];
  rows: GlueTableRow[];
  excludePagination?: boolean;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  expandableHeight?: boolean;
  paginationProps?: GluePaginationProps;
  disableElevation?: boolean;
};

export type GluePaginationProps = {
  rowsPerPageOptions?: number[];
  count?: number;
  rowsPerPage?: number;
  page?: number;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onChangePage?: (page: number) => void;
}

export interface GlueTableColumn extends TableCellProps {
  columnName: ReactNode | string;
  columnKey: string;
  orderNumber?: number;
  isSortable?: boolean;
}

export interface GlueTableRow {
  key: Key | undefined;
  highlightColor?: "primary" | "secondary" 
    | "secondaryGreen" | "secondaryTurquoise" 
    | "secondaryOrange" | "warning" | "error" 
    | "success" | "info" | 'grey' | 'darkGray';
  data: any;
}

const GlueTable = ({
  accessibleAriaLabel,
  orderBy,
  sortDirection,
  onRequestSort,
  columns,
  rows,
  isLoading,
  isErrors,
  errorMessage,
  expandableHeight,
  paginationProps,
  excludePagination,
  disableElevation,
}: GlueTableProps) => {
  const classes = useStyles();
  const onChangeRowsPerPage = paginationProps?.onChangeRowsPerPage;
  const onChangePage = paginationProps?.onChangePage;

  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPageOptions = paginationProps && Array.isArray(paginationProps?.rowsPerPageOptions)
    ? paginationProps?.rowsPerPageOptions : [5, 10, 25];
  const count = paginationProps?.count && Number.isFinite(paginationProps.count)
    ? paginationProps.count : rows.length;
  const rowsPerPage = paginationProps?.rowsPerPage && Number.isFinite(paginationProps.rowsPerPage)
    ? paginationProps.rowsPerPage : currentRowsPerPage;
  const page = paginationProps?.page && Number.isFinite(paginationProps.page)
    ? paginationProps.page : currentPage;

  useEffect(() => {
    const totalPages = count / rowsPerPage;
    const shouldGoToStartPage = totalPages < currentPage + 1;
    if (shouldGoToStartPage) {
      if (typeof onChangePage === 'function') {
        onChangePage(0);
      } else {
        setCurrentPage(0);
      }
    }
  }, [rows.length]);
  const handleChangePage = (event: unknown, newPage: number) => {
    if (typeof onChangePage === 'function') {
      onChangePage(newPage);
    } else {
      setCurrentPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (typeof onChangeRowsPerPage === 'function') {
      onChangeRowsPerPage(newRowsPerPage);
    } else {
      setCurrentRowsPerPage(newRowsPerPage);
    }
    handleChangePage(event, 0);
  };

  const sortedColumns = columns.sort(columnCompareFunction);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    if (typeof onRequestSort === 'function') {
      onRequestSort(event, property);
    }
  }

  const isEmpty = !isLoading && !isErrors && rows.length === 0;
  const resultsLoaded = !isLoading && rows.length > 0;
  const tableContainerClass = expandableHeight
    ? classes.tableContainerExpandable
    : classes.tableContainer;
  const variant = disableElevation ? 'outlined' : 'elevation';
  
  return (
    <Paper variant={variant} className={classes.tablePaper}>
      <TableContainer className={tableContainerClass}>
        <Table stickyHeader className={classes.table} aria-label={accessibleAriaLabel}>
          <GlueTableHeaders
            sortedColumns={sortedColumns}
            orderBy={orderBy}
            sortDirection={sortDirection}
            onRequestSort={handleRequestSort}
          />
          {resultsLoaded && (
            <GlueTableRows
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              sortedColumns={sortedColumns}
            />
          )}
          {isLoading && (
            <GlueTableSkeletons
              isLoading
              columns={columns}
              rowsPerPage={rowsPerPage} />
          )}
        </Table>
        {isEmpty && <GlueEmptyDisplay className={classes.emptyMessage} />}
        {isErrors && <GlueErrorDisplay
          className={classes.emptyMessage}
          message={errorMessage?.trim()
            ? errorMessage
            : 'There was an error.'
          }
        />}
      </TableContainer>
      {
        excludePagination
        ? null
        : (<GlueTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />)
      }
    </Paper>
  );
};

export default GlueTable;
