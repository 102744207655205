import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dotenv from 'dotenv';

export const CHANGE_LOCATION = Symbol('WPOS3::CHANGE_LOCATION');
export const STORAGE_LOCATION_INDEX = 'WPOS3::STORAGE_LOCATION_INDEX';

let LOCATIONS_ENUM = ['AZ02', 'KY01', 'TX01'];
dotenv.config({ path: '../dotenv' });
const base_url = process.env.REACT_APP_DEV_API;
if (typeof base_url !== 'undefined' && base_url.includes('retail')) {
  LOCATIONS_ENUM = ['RUBY'];
}

export const ALL_LOCATIONS = 'All Locations';

export const locations = [ALL_LOCATIONS, ...LOCATIONS_ENUM];

export function useLocationCompare() {
  const primaryLocationCode = useSelector(state => state.location.location);
  const locationCompare = useMemo(() => {
    switch (primaryLocationCode) {
      case ALL_LOCATIONS:
        return location => LOCATIONS_ENUM.includes(location);
      default:
        return location => primaryLocationCode === location;
    }
  }, [primaryLocationCode]);

  return locationCompare;
}

export function useChangeLocation() {
  const dispatch = useDispatch();

  const changeLocation = useCallback(
    location => {
      const locationIndex = locations.indexOf(location);
      if (locationIndex === -1) {
        return console.warn(`${location} is not a valid location`);
      }

      localStorage.setItem(STORAGE_LOCATION_INDEX, locationIndex);

      dispatch({ type: CHANGE_LOCATION, payload: location });

      // Success
      return true;
    },
    [dispatch]
  );

  return changeLocation;
}
