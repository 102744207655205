import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Select from '../../../common/LayoutComponents/Select';
import { useHttp } from '../../../common/Hooks';

const useAdvancedInventorySearchStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    minWidth: '60vw',
    // Fixes an overflow conflict between Material-UI and react-select
    // https://github.com/mui-org/material-ui/issues/7431
    overflow: 'visible',
    [theme.breakpoints.down('md')]: {
      minWidth: '80vw'
    }
  },
  button: {
    color: theme.palette.common.white
  },
  content: {
    // Fixes an overflow conflict between Material-UI and react-select
    // https://github.com/mui-org/material-ui/issues/7431
    overflow: 'initial',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
}));

const useLegacyBucketGroups = () => {
  const [request, response] = useHttp('ims');
  const [legacyBucketList, setLegacyBucketList] = useState({});

  const fetchLegacyBuckets = useCallback(async () => {
    const legacyBucketResponse = await request.get('/buckets');
    if (response.ok) {
      setLegacyBucketList(legacyBucketResponse);
    }
  }, []);

  return [legacyBucketList, fetchLegacyBuckets];
};

const useAdvancedSearchState = (setSearchResults, setInputValue) => {
  const [request, response] = useHttp('ims');
  const [formState, setFormState] = useState({
    bucket: '',
    model: ''
  });

  const handleFormInput = useCallback(({ target: { value, name } }) => {
    setFormState(previousState => ({
      ...previousState,
      [name]: value
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const params = new URLSearchParams('startsWith=1');
    Object.entries(formState).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });
    const requestUrl = `/inventory/products/search?${params.toString()}`;
    const results = await request.get(requestUrl);
    if (response.ok) {
      setSearchResults(results);
      // TODO: This is a terrible workaround to display the search results
      // fix it.
      setInputValue('advaced search');
    }
  }, [formState, setSearchResults, setInputValue]);

  return [formState, handleFormInput, handleSubmit];
};

// TODO: This shouldn't be dependent on the `setSearchResults`, `setInputValue` functions
// refactor to remove them.
function AdvancedInventorySearchModal({ open, handleClose, setSearchResults, setInputValue }) {
  const classes = useAdvancedInventorySearchStyles();
  const [legacyBucketList, fetchLegacyBuckets] = useLegacyBucketGroups();
  const [formState, handleFormInput, handleSubmit] = useAdvancedSearchState(
    setSearchResults,
    setInputValue
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    // Only fetch buckets when/if Advanced Search is open
    // or if we haven't already fetched the list.
    if (open && !legacyBucketList.length) {
      fetchLegacyBuckets();
    }
  }, [open, fetchLegacyBuckets, legacyBucketList.length]);

  const bucketList = useMemo(() => {
    return Object.keys(legacyBucketList);
  }, [legacyBucketList]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle>Advanced Search</DialogTitle>
      <DialogContent className={classes.content}>
        <Select
          name="bucket"
          inputValue={formState.bucket}
          handleChange={handleFormInput}
          label="Search By Bucket"
          options={bucketList}
        />
        <Select
          name="model"
          inputValue={formState.model}
          handleChange={handleFormInput}
          label="Search By Model"
          menuIsOpen={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            handleSubmit();
            handleClose();
          }}
          variant="contained"
          color="primary"
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdvancedInventorySearchModal;
