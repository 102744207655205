import React, { useState } from 'react';

import CommittedTransfers from '../CommittedTransfersContainer';
import Messaging from '../../Inventory/Transfer/Messaging';

export default function CommittedTransfersDashboard(props) {
  const [submitFeedback, setSubmitFeedback] = useState('');

  const saveNewFeedback = feedback => {
    const feedbackWithMessageId = {
      messageId: Date.now(),
      ...feedback
    };
    setSubmitFeedback(feedbackWithMessageId);
  };

  return (
    <>
      <Messaging submitFeedback={submitFeedback} />
      <CommittedTransfers
        onUpdate={feedback => saveNewFeedback(feedback)}
        onDelete={feedback => saveNewFeedback(feedback)}
        {...props}
      />
    </>
  );
}
