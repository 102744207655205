const initialState = {
  notes: [],
  users: [],
  usersFetched: false,
  noteTypes: [],
  noteTypesFetched: false,
  quoteNotes: []
};

export default function workorderNotes(state = initialState, action) {
  switch (action.type) {
    case 'ADD_NEW_NOTE': {
      const newNotes = state.notes;
      newNotes.push(action.payload.data);
      return {
        ...state,
        notes: newNotes
      };
    }
    case 'ADD_NEW_NOTE_FAILURE':
      return {
        ...state
      };
    case 'ADD_NEW_QUOTE_NOTE': {
      const newQuoteNotes = state.quoteNotes;
      newQuoteNotes.push(action.payload.data);
      return {
        ...state,
        quoteNotes: newQuoteNotes
      };
    }
    case 'ADD_NEW_QUOTE_NOTE_FAILURE':
      return {
        ...state
      };
    case 'GET_FULL_QUOTE_FULFILLED':
      return {
        ...state,
        quoteNotes: action.payload.data.body.quote_note
      };
    case 'GET_FULL_WORKORDER_FULFILLED':
      return {
        ...state,
        notes: action.payload.data.workorder_notes
      };
    case 'GET_ALL_USERS':
      return {
        ...state,
        users: action.payload.data,
        usersFetched: true
      };
    case 'GET_ALL_USERS_FAILURE':
      return {
        ...state,
        usersFetched: false
      };
    case 'GET_ALL_NOTE_TYPES':
      return {
        ...state,
        noteTypes: action.payload.data,
        noteTypesFetched: true
      };
    case 'GET_ALL_NOTE_TYPES_FAILURE':
      return {
        ...state,
        noteTypesFetched: false
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
