import { Theme } from '@material-ui/core';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

export interface GluePalette extends Palette {
  secondaryTurquoise: PaletteColor;
  secondaryOrange: PaletteColor;
  secondaryGreen: PaletteColor;
}

export interface GlueTheme extends Theme {
  palette: GluePalette;
}

export const getTransparentHex = (hexColor: string, opacityPercent: string) => {
  const highTransparenyColor = hexColor + opacityPercent;
  return highTransparenyColor;
}
