import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import CommittedTransferScanApp from './CommittedTransferScanApp';
import { useStatus, useHttp } from '../../../common/Hooks';
import { goodSound, badSound } from '../../../common/playSound';

export default function CommittedTransferScanAppContainer({
  transactionRefId,
  transactionDetailsOverride = null
}) {
  const auth = useContext(AuthContext);
  const [request, response] = useHttp('ims/committed-transfers');
  const [requestStatus, setRequestStatus, requestMessage, setRequestMessage] = useStatus();
  const [requestError, setRequestError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(transactionDetailsOverride);
  const [lastScannedUpcForItem, setLastScannedUpcForItem] = useState(null);
  const [lastScannedSerialForItem, setLastScannedSerialForItem] = useState(null);
  const [scannedSerialsForItem, setScannedSerialsForItem] = useState([]);
  const [transactComplete, setTransactComplete] = useState(false);
  const [submittedSerials, setSubmittedSerials] = useState([]);

  useEffect(() => {
    if (transactionRefId && auth) {
      fetchTransaction(transactionRefId);
    }
  }, [transactionRefId, auth]);

  async function fetchTransaction(transactionRefIdArg) {
    const transaction = await request.get(`/${transactionRefIdArg}`);
    if (response.ok) {
      setTransactionDetails(transaction);
      setSubmittedSerials(transaction.serials);
    }
  }

  async function submitScannedItem(force = false) {
    const scannedItem = await request.post(`/${transactionRefId}/addScannedItem`, {
      scannedUpc: lastScannedUpcForItem,
      scannedSerialNumbers: scannedSerialsForItem,
      forcePushSerialCorrection: force ? 1 : 0
    });
    if (response.ok) {
      setTransactionDetails(scannedItem.updated_committed_transfer);
      setSubmittedSerials(scannedItem.updated_committed_transfer.serials);
      setRequestStatus(true);
      setScannedSerialsForItem([]);
      setLastScannedUpcForItem(null);
      setLastScannedSerialForItem(null);
      setSerialNotAtLocation(false);
      setSerialDoesntExist(false);
      goodSound();
    } else {
      setRequestStatus(false);
      const error = scannedItem.errors || JSON.stringify(scannedItem);
      setRequestError(error);
      setRequestMessage(error);
      badSound();
    }
  }

  const [errorLocation, setErrorLocation] = useState('');
  const [forcePush, setForcePush] = useState(false);
  const [serialDoesntExist, setSerialDoesntExist] = useState(false);
  const [serialNotAtLocation, setSerialNotAtLocation] = useState(false);
  function closeForcePush() {
    setSerialDoesntExist(false);
    setSerialNotAtLocation(false);
    setScannedSerialsForItem([]);
    setLastScannedUpcForItem(null);
    setLastScannedSerialForItem(null);
  }
  useEffect(() => {
    if (requestError) {
      const notAtLocationString = 'according to records should be at';
      const doesntExistString = `The following errors occurred: Inventory serial ${scannedSerialsForItem
        .sort()
        .join(':=:')} does not exist for product`;
      const notAtLocationErrorCheck = requestError.includes(notAtLocationString);
      badSound();
      if (notAtLocationErrorCheck) {
        const location = requestError
          .match(/not (\w+\.)/)
          .pop()
          .replace('.', '');
        setErrorLocation(location);
      }
      setSerialDoesntExist(requestError.includes(doesntExistString));
      setSerialNotAtLocation(notAtLocationErrorCheck);
    }
  }, [requestError]);

  useEffect(() => {
    if (forcePush) {
      submitScannedItem(true);
      setForcePush(false);
    }
  }, [forcePush]);

  async function transactTransfer() {
    const submittedScan = await request.post(`/${transactionRefId}/transact`, {
      transferredById: auth.username
    });
    if (response.ok) {
      setRequestStatus(true);
      setRequestMessage('Transfer successfully submitted and processed!');
      setTransactComplete(true);
      goodSound();
    } else {
      setRequestStatus(false);
      setRequestMessage(submittedScan.errors);
      badSound();
    }
  }

  return (
    <CommittedTransferScanApp
      transactionDetails={transactionDetails}
      scanUpc={upc => setLastScannedUpcForItem(upc)}
      scanSerial={serial => {
        setLastScannedSerialForItem(serial);
        setScannedSerialsForItem([...scannedSerialsForItem, serial]);
      }}
      lastScannedUpcForItem={lastScannedUpcForItem}
      lastScannedSerialForItem={lastScannedSerialForItem}
      scannedSerialsForItem={scannedSerialsForItem}
      submitScannedItem={submitScannedItem}
      submittedSerials={submittedSerials}
      transactTransfer={transactTransfer}
      expectedProduct={transactionDetails.product}
      requestStatus={requestStatus}
      requestMessage={requestMessage}
      transactComplete={transactComplete}
      resetItem={() => {
        setLastScannedUpcForItem(null);
        setLastScannedSerialForItem(null);
        setScannedSerialsForItem([]);
      }}
      serialDoesntExist={serialDoesntExist}
      serialNotAtLocation={serialNotAtLocation}
      closeForcePush={closeForcePush}
      requestError={requestError}
      setForcePush={setForcePush}
      errorLocation={errorLocation}
    />
  );
}
