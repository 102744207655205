import startCase from 'lodash/startCase';

export interface LooseObject {
    [key: string]: any;
  }

export const transform = (s: string): string => startCase(s.replace('_', ' '));

export const uniq = (a: Array<number>): Array<number> => {
    const seen: LooseObject = {};
    return a.filter((item: number) => {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

