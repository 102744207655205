import React from 'react';
import ReactDOM from 'react-dom';

import logger from 'redux-logger';

// ################# comment this out in production
import { Provider } from 'react-redux';
import { ConnectedRouter as Router, connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

// import { reducer as formReducer } from 'redux-form';
import brandReducer from './reducers/brandReducer';
import contactsReducer from './reducers/contactsReducer';
import fulfillmentReducer from './reducers/fulfillmentReducer';
import loadingReducer from './reducers/loadingReducer';
import locationReducer from './reducers/locationReducer';
import noteReducer from './reducers/noteReducer';
import orderBoardReducer from './reducers/orderBoardReducer';
import paymentReducer from './reducers/paymentReducer';
import productReducer from './reducers/productReducer';
import purchasingReducer from './reducers/purchasingReducer';
import quoteReducer from './reducers/quoteReducer';
import robApiCallReducer from './reducers/robApiCallReducer';
import scannerReducer from './reducers/scannerReducer';
import settingReducer from './reducers/settingReducer';
import shippingBoardReducer from './reducers/shippingBoardReducer';
import userReducer from './reducers/userReducer';
import workOrderReducer from './reducers/workOrderReducer';
import messagingQueueReducer from './reducers/messagingQueueReducer';
import App from './App';

import User from './roles/User';
import AuthorizedLogin from './AuthContext';
import { ScanEventListeningProvider } from './common/ScanEventListening/ScanEventListeningContext';

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

/* Redux Persist
 * In PersistConfig, if creating a custom Config for a Reducer, you must blacklist it in the persistConfig.
 * Otherwise the custom one will get overridden.
 *
 * Blacklist stops it from persisting, whitelist allows you to make sure a specific part works.
 */

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['purchasing', 'quotes', 'loading', 'contacts', 'workOrder', 'payment']
};

const purchasingPersistConfig = {
  key: 'purchasing',
  storage,
  blacklist: ['purchaseOrderFulfilled']
};

const contactsPersistConfig = {
  key: 'contacts',
  storage,
  blacklist: ['contactHistoryFulfilled']
};

const quotesPersistConfig = {
  key: 'quotes',
  storage,
  blacklist: ['quoteDetailsFetched']
};

const workorderPersistConfig = {
  key: 'workOrder',
  storage,
  blacklist: ['fullWorkOrderFulfilled']
};

const paymentPersistConfig = {
  key: 'payment',
  storage,
  blacklist: ['paymentSettingsFulfilled']
};

// const loadingPersistConfig = {
//   key: 'loading',
//   storage: storage,
//   blacklist: ['loading', 'message']
// }

// const settingPersistConfig = {
//   key: 'setting',
//   storage: storage,
//   blacklist: ['locations']
// }

const reducers = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  contacts: persistReducer(contactsPersistConfig, contactsReducer),
  quotes: persistReducer(quotesPersistConfig, quoteReducer),
  workOrder: persistReducer(workorderPersistConfig, workOrderReducer),
  brand: brandReducer,
  products: productReducer,
  purchasing: persistReducer(purchasingPersistConfig, purchasingReducer),
  scanner: scannerReducer,
  settings: settingReducer,
  shipping: shippingBoardReducer,
  orders: orderBoardReducer,
  fulfillment: fulfillmentReducer,
  robApiCall: robApiCallReducer,
  notes: noteReducer,
  loading: loadingReducer,
  payment: persistReducer(paymentPersistConfig, paymentReducer),
  location: locationReducer,
  messangingQueue: messagingQueueReducer
  // form: formReducer
});

/* Redux Persist */
const persistedReducer = persistReducer(persistConfig, reducers);

/* Checks for enviroment, then applies middleware for debugging purposes in Development. */
const store =
  process.env.NODE_ENV === `development`
    ? createStore(persistedReducer, undefined, applyMiddleware(middleware, thunk, logger))
    : createStore(persistedReducer, applyMiddleware(middleware, thunk));

/* Wraps App with Redux Components */
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <AuthorizedLogin>
        <User>
          <Router history={history}>
            <ScanEventListeningProvider>
              <App />
            </ScanEventListeningProvider>
          </Router>
        </User>
      </AuthorizedLogin>
    </Provider>,
    document.getElementById('root')
  );
};

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    // This is required for proper hot-reloading
    // eslint-disable-next-line global-require
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
