import makeStyles from '@material-ui/core/styles/makeStyles';

export const useGlueCopyToClipboardIconButtonStyles = makeStyles(theme => {
  return {
    icon: {
      position: 'relative',
      opacity: 0.8,
      cursor: 'pointer',
      top: theme.spacing(0.3),
      left: theme.spacing(0.5),
      width: props => theme.typography[props.typographyVariant].fontSize,
      height: props => theme.typography[props.typographyVariant].fontSize
    }
  };
});
