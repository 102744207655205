import React from 'react';
import GenericShippingReconciliationUploader from './GenericShippingReconciliationUploader';

function CevaShippingReconciliationUploader() {
  return (
    <GenericShippingReconciliationUploader carrier="ceva" title="CEVA Shipping Reconciliation" />
  );
}

export default CevaShippingReconciliationUploader;
