import React, { useState, useEffect, SyntheticEvent } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useBrandList } from './Hooks';
import { useHttp } from '../../../common/Hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

const tableStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: '3rem'
  },
  totalsColumn: {
    fontWeight: 'bold'
  },
  posColumn: {
    color: deepPurple[500]
  },
  deductionsColumn: {
    color: red[500]
  },
  sellableColumn: {
    color: green[500]
  },
  unsellableColumn: {
    color: blue[500]
  },
  extraInfoColumn: {
    color: orange[500]
  }
}));

interface Inventory {
  True: any;
  False: any;
}

type Entry = string | number;
type Row = Record<string, Record<string, Entry> | string> & {
  inventory: Record<string, Record<'quantity', Entry>>;
};

const TOTALS_TITLE_TEXT = `Totals`;
const PURCHASE_ORDERS_TITLE_TEXT = `Purchase Orders`;
const DEDUCTIONS_TITLE_TEXT = `Deductions (Subtracted from Gross to calculate Net)`;
const SELLABLE_INVENTORY_TITLE_TEXT = `Sellable Inventory (Added to calculate Gross)`;
const UNSELLABLE_INVENTORY_TITLE_TEXT = `Unsellable Inventory (Added to calculate Gross, subtracted to calculate Net)`;
const EXTRA_INFO_TITLE_TEXT = `Extra Info`;

function BrandSellThruTable({ data }: { data: Row[] }) {
  const classes = tableStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.header}>Model</TableCell>
          <TableCell className={classes.header}>MPN</TableCell>
          {/* Totals */}
          <TableCell className={classes.header} title={TOTALS_TITLE_TEXT}>
            <span className={classes.totalsColumn}>QTY Sold</span>
          </TableCell>
          <TableCell className={classes.header} title={TOTALS_TITLE_TEXT}>
            <span className={classes.totalsColumn}>Net Total On Hand QTY</span>
          </TableCell>
          <TableCell className={classes.header} title={TOTALS_TITLE_TEXT}>
            <span className={classes.totalsColumn}>Gross Total On Hand QTY</span>
          </TableCell>
          {/* Purchase Orders */}
          <TableCell className={classes.header} title={PURCHASE_ORDERS_TITLE_TEXT}>
            <span className={classes.posColumn}>AZ02 PO</span>
          </TableCell>
          <TableCell className={classes.header} title={PURCHASE_ORDERS_TITLE_TEXT}>
            <span className={classes.posColumn}>TX01 PO</span>
          </TableCell>
          <TableCell className={classes.header} title={PURCHASE_ORDERS_TITLE_TEXT}>
            <span className={classes.posColumn}>KY01 PO</span>
          </TableCell>
          {/* Deductions (Not added to calculate Gross; all are subtracted to calculate Net) */}
          <TableCell className={classes.header} title={DEDUCTIONS_TITLE_TEXT}>
            <span className={classes.deductionsColumn}>Non-FBA Pending Orders</span>
          </TableCell>
          <TableCell className={classes.header} title={DEDUCTIONS_TITLE_TEXT}>
            <span className={classes.deductionsColumn}>FBA Pending Orders Standalone</span>
          </TableCell>
          <TableCell className={classes.header} title={DEDUCTIONS_TITLE_TEXT}>
            <span className={classes.deductionsColumn}>FBA Pending Orders Bundle</span>
          </TableCell>
          <TableCell className={classes.header} title={DEDUCTIONS_TITLE_TEXT}>
            <span className={classes.deductionsColumn}>QTY Committed</span>
          </TableCell>
          <TableCell className={classes.header} title={DEDUCTIONS_TITLE_TEXT}>
            <span className={classes.deductionsColumn}>Pending Transfers</span>
          </TableCell>
          {/* Inventory Details - Unsellable (Added to calculate Gross, subtracted to calculate Net) */}
          <TableCell className={classes.header} title={UNSELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.unsellableColumn}>Amazon Inbound</span>
          </TableCell>
          {/* TODO: Add the below back in after the "table settings" feature is implemented? */}
          {/* <TableCell className={classes.header} title={UNSELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.unsellableColumn}>Inter-Warehouse Transfers</span>
          </TableCell> */}
          {/* Inventory Details - Sellable (Added to calculate Gross) */}
          <TableCell className={classes.header} title={SELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.sellableColumn}>AZ02</span>
          </TableCell>
          <TableCell className={classes.header} title={SELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.sellableColumn}>TX01</span>
          </TableCell>
          <TableCell className={classes.header} title={SELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.sellableColumn}>KY01</span>
          </TableCell>
          <TableCell className={classes.header} title={SELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.sellableColumn}>FBA Real-Time Standalone</span>
          </TableCell>
          <TableCell className={classes.header} title={SELLABLE_INVENTORY_TITLE_TEXT}>
            <span className={classes.sellableColumn}>FBA Real-Time Bundle</span>
          </TableCell>
          {/* Extra Info */}
          <TableCell className={classes.header} title={EXTRA_INFO_TITLE_TEXT}>
            <span className={classes.extraInfoColumn}>Distributor Ruby</span>
          </TableCell>
          <TableCell className={classes.header} title={EXTRA_INFO_TITLE_TEXT}>
            <span className={classes.extraInfoColumn}>Latest PO Date</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: any) => (
          <TableRow>
            <TableCell>
              <Link to={`/inventory/detail/model/${row.model}`} rel="noopener" target="_blank">
                {row.model}
              </Link>
            </TableCell>
            <TableCell>{row.mpn}</TableCell>
            {/* Totals */}
            <TableCell>{row.total_sold}</TableCell>
            <TableCell>{row.summary.net}</TableCell>
            <TableCell>{row.summary.gross}</TableCell>
            {/* POs */}
            <TableCell>{row.AZ02}</TableCell>
            <TableCell>{row.TX01}</TableCell>
            <TableCell>{row.KY01}</TableCell>
            {/* Deductions (Not added to calculate Gross; all are subtracted to calculate Net) */}
            <TableCell>{row.details.pending_merchant_orders}</TableCell>
            <TableCell>{row.details.pending_fba_orders.standalone || 0}</TableCell>
            <TableCell>{row.details.pending_fba_orders.bundle || 0}</TableCell>
            <TableCell>{row.details.committed}</TableCell>
            <TableCell>{row.details.committed_outbound_transfers || 0}</TableCell>
            {/* Inventory Details - Non-Sellable (Added to calculate Gross, subtracted to calculate Net) */}
            <TableCell>{row.details.unsellable_inventory.AMAZON_FBA_INBOUND || 0}</TableCell>
            {/* TODO: Add the below back in after the "table settings" feature is implemented? */}
            {/* <TableCell>
              {(row.details.unsellable_inventory.KY01_TO_TX01 || 0) +
                (row.details.unsellable_inventory.KY01_TO_AZ02 || 0) +
                (row.details.unsellable_inventory.TX01_TO_KY01 || 0) +
                (row.details.unsellable_inventory.TX01_TO_AZ02 || 0) +
                (row.details.unsellable_inventory.AZ02_TO_TX01 || 0) +
                (row.details.unsellable_inventory.AZ02_TO_KY01 || 0)}
            </TableCell> */}
            {/* Inventory Details - Sellable (Added to calculate Gross) */}
            <TableCell>{row.details.sellable_inventory.AZ02 || 0}</TableCell>
            <TableCell>{row.details.sellable_inventory.TX01 || 0}</TableCell>
            <TableCell>{row.details.sellable_inventory.KY01 || 0}</TableCell>
            <TableCell>{row.details.fba_inventory.standalone || 0}</TableCell>
            <TableCell>{row.details.fba_inventory.bundle || 0}</TableCell>
            {/* Extra Info */}
            <TableCell>{row.details.distributor.DISTRIBUTOR_RUBY || 0}</TableCell>
            <TableCell>
              {row.latest_received !== null
                ? moment(row.latest_received).format('MMMM D, YYYY')
                : 'Not Received Yet'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const today = moment().format('YYYY-MM-DD');
const thirtyBack = moment()
  .subtract(7, 'days')
  .format('YYYY-MM-DD');

export default function BrandSellThruReport() {
  const brandList = useBrandList();
  const classes = useStyles();
  const [selectedBrand, setSelectedBrand] = useState<OptionTypeBase | undefined>(undefined);
  const [startDate, setStartDate] = useState(thirtyBack);
  const [endDate, setEndDate] = useState(today);
  const handleSelectedBrand = (option: OptionTypeBase) => {
    setSelectedBrand(option);
  };
  const [inventory, setInventory] = useState<Inventory | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const [req, res] = useHttp(
    `report/brand-sell-thru?brand=${selectedBrand?.value}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );

  async function getBrandSellThroughReport(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    if (selectedBrand) {
      const data = await req.get();
      if (res.ok) {
        setInventory(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }

  if (!brandList) return <h2>Loading</h2>;
  return (
    <Paper className={classes.paper}>
      <form onSubmit={getBrandSellThroughReport}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <label htmlFor="brands">Brand</label>
            <Select
              options={Object.entries(brandList).map(brand => ({
                label: brand[0],
                value: brand[0]
              }))}
              onChange={handleSelectedBrand}
              value={selectedBrand}
              id="brands"
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              type="date"
              name="startDate"
              label="Start Date"
              onChange={e => setStartDate(e?.target?.value)}
              value={startDate}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              type="date"
              name="endDate"
              label="End Date"
              onChange={e => setEndDate(e?.target?.value)}
              value={endDate}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={3}>
            <Button fullWidth variant="contained" type="submit" disabled={loading}>
              Run Report
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <h2>Loading Report...</h2>}

      {!isEmpty(inventory) ? (
        inventory?.True ? (
          <>
            <h2>With Sales</h2>
            <BrandSellThruTable data={inventory?.True} />
          </>
        ) : null
      ) : null}
      {!isEmpty(inventory) ? (
        inventory?.False ? (
          <>
            <h2>Without Sales</h2>
            <BrandSellThruTable data={inventory?.False} />
          </>
        ) : null
      ) : null}
    </Paper>
  );
}
