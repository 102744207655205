import React, { Key } from 'react';
import {
  FormControl,
  InputLabel,
  InputProps,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';

export type SelectItemProps = {
  value: string | number | string[] | undefined;
  name: string;
  key: Key | undefined;
};

export interface GlueSelectProps extends InputProps {
  options: SelectItemProps[];
  accessibleLabelId: string; // Used for accessible markup. Read more at: https://v4.mui.com/components/selects/#accessibility
  label: string;
  handleChange?: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void;
  value?: unknown;
  helperText?: string;
  error?: boolean;
}

const GlueSelect = (props: GlueSelectProps) => {
  const {
    options,
    accessibleLabelId,
    label,
    handleChange,
    value,
    className,
    helperText,
    fullWidth,
    required,
    error,
    ...selectProps
  } = props;
  let menuItems = options.map((option: SelectItemProps) => {
    return (
      <MenuItem key={option.key} value={option.value}>
        {option.name}
      </MenuItem>
    );
  });
  return (
    <FormControl error={error} fullWidth className={className} variant="outlined">
      <InputLabel required={required} id={accessibleLabelId}>
        {label}
      </InputLabel>
      <Select
        {...selectProps}
        labelId={accessibleLabelId}
        value={value}
        onChange={handleChange}
        label={label}
      >
        {menuItems}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default GlueSelect;
