import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '8vh'
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    marginTop: 18
  },
  footerTextWrapper: {
    width: '20vw',
    height: 80
  },
  footerText: {
    fontSize: 20
  },
  footerActions: {
    marginTop: 8
  }
}));

function FooterControlPanel({
  resetDefaultStagedUpdates,
  stagedUpdates,
  handleSubmitUpdates,
  isSubmitting
}) {
  const classes = useStyles();

  const numOfSelected = useMemo(() => {
    return Object.keys(stagedUpdates).length;
  }, [stagedUpdates]);

  const totalPaymentsToApply = useMemo(() => {
    return Object.values(stagedUpdates)
      .reduce((sum, current) => sum + parseFloat(current.payment_to_apply || 0), 0)
      .toFixed(2);
  }, [stagedUpdates]);

  return (
    <Paper className={classes.footer} component="footer">
      <div className={classes.footerContent}>
        <section className={classes.footerActions}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={resetDefaultStagedUpdates}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Please wait...' : 'Reset Defaults'}
          </Button>
        </section>
        <section className={classes.footerTextWrapper}>
          <Typography component="h4" className={classes.footerText}>
            Number of selected:
          </Typography>
          <Typography component="p" className={classes.footerText}>
            <strong>{numOfSelected}</strong>
          </Typography>
        </section>
        <section className={classes.footerTextWrapper}>
          <Typography component="h4" className={classes.footerText}>
            Total Payments to Apply:
          </Typography>
          <Typography component="p" className={classes.footerText}>
            <strong>${totalPaymentsToApply}</strong>
          </Typography>
        </section>
        <section className={classes.footerActions}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmitUpdates}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Please wait...' : 'Submit Apply Payments'}
          </Button>
        </section>
      </div>
    </Paper>
  );
}

export default FooterControlPanel;
