import React from 'react';

export default function RoleEditor(props) {
  const { users, roles, handleChange } = props;
  return (
    <div>
      {users.map(user => {
        const currentUserRoleId = user.fk_role_id;
        return (
          <div key={user.id}>
            <div>
              {user.name}
              <select
                value={currentUserRoleId}
                onChange={event => handleChange(user.id, event.target.value)}
              >
                {Object.entries(roles).map(([roleId, roleName]) => {
                  return (
                    <option key={roleId} value={roleId}>
                      {roleName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      })}
    </div>
  );
}
