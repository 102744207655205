import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useHttp, useDownloadWorker } from '../../common/Hooks';
import ShippingReconciliationTable from './ShippingReconciliationTable';
import PaidShippingTable from './PaidShippingTable';
import FooterControlPanel from './FooterControlPanel';
import {
  useShippingReconciliationSort,
  useShippingReconciliationStaging
} from './shippingReconciliationHooks';
import { getHttpResponseErrorMessage } from '../../helpers/getErrors';

const applyStagedUpdatesToRows = (stagedUpdates, rows) => {
  return rows.map(currentRow => {
    const matchingUpdate = stagedUpdates[currentRow.tracking_number];
    if (isEmpty(matchingUpdate)) {
      return { ...currentRow };
    }
    return {
      ...currentRow,
      shipping_cost_billed: matchingUpdate.reported_billed_charge,
      shipping_cost_paid: matchingUpdate.payment_to_apply
    };
  });
};

const useStyles = makeStyles(theme => ({
  tabPaper: {
    margin: '18px auto 0 auto',
    maxWidth: '70vw'
  }
}));

function TabPanel({ children, tabIndex, index }) {
  return tabIndex === index && children;
}

function ShippingReconciliationDashboard({ shippingTransactions }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [batchId, setBatchId] = useState(null);
  const [rows, setRows] = useState(shippingTransactions);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [downloadingBatchUpdateReport, setDownloadingBatchUpdateReport] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const classes = useStyles();
  const [sendUrlToWorker] = useDownloadWorker({
    reportName: 'shipping-reconciliation-batch-update-report'
  });

  const [request, response] = useHttp('shipping-reconciliation', {
    headers: { Accept: 'application/json', timeout: 30 }
  });

  useEffect(() => {
    setRows(shippingTransactions);
  }, [shippingTransactions]);

  const [notPaid, partiallyPaid, fullyPaid, notFound, invalidQuote] = useShippingReconciliationSort(
    rows
  );
  const [
    stagedUpdates,
    setStagedUpdates,
    resetDefaultStagedUpdates
  ] = useShippingReconciliationStaging(notPaid);

  const notPaidCount = useMemo(() => Object.keys(notPaid).length, [notPaid]);
  const fullyPaidCount = useMemo(() => Object.keys(fullyPaid).length, [fullyPaid]);
  const partiallyPaidCount = useMemo(() => Object.keys(partiallyPaid).length, [partiallyPaid]);
  const notFoundCount = useMemo(() => Object.keys(notFound).length, [notFound]);
  const invalidQuoteCount = useMemo(() => Object.keys(invalidQuote).length, [invalidQuote]);

  const handleSubmitUpdates = useCallback(() => {
    async function submitUpdates() {
      await request.put(`/update`, {
        updates: stagedUpdates
      });
      if (response.ok) {
        setRows(applyStagedUpdatesToRows(stagedUpdates, rows));
        setBatchId(response.data.batchId);
      } else {
        setSubmitError(getHttpResponseErrorMessage(response));
      }
      setIsSubmitting(false);
    }
    if (!isSubmitting) {
      setIsSubmitting(true);
      setOpenDialog(true);
      submitUpdates();
    }
  }, [request, response, stagedUpdates, rows, isSubmitting]);

  const handleDownloadBatchUpdateReport = useCallback(() => {
    async function downloadBatchUpdateReport() {
      sendUrlToWorker(`/shipping-reconciliation/updates/${batchId}`);
      setOpenDialog(false);
      setDownloadingBatchUpdateReport(false);
      setBatchId(null);
    }
    if (!downloadingBatchUpdateReport || !batchId) {
      setDownloadingBatchUpdateReport(true);
      downloadBatchUpdateReport();
    }
  }, [downloadingBatchUpdateReport, batchId, sendUrlToWorker]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setSubmitError(null);
  }, []);
  let i = 0;

  return (
    <>
      <Paper className={classes.tabPaper}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={handleTabChange}
          aria-label="shipping reconciliation dashboard tabs"
        >
          <Tab label={`Payments to Apply (${notPaidCount})`} />
          <Tab label={`Paid in Full (${fullyPaidCount})`} />
          <Tab label={`Partially Paid (${partiallyPaidCount})`} />
          {notFoundCount > 0 && <Tab label={`Not Found (${notFoundCount})`} />}
          {invalidQuoteCount > 0 && <Tab label={`Invalid Quote (${invalidQuoteCount})`} />}
        </Tabs>
      </Paper>

      <TabPanel tabIndex={tabIndex} index={i++}>
        <ShippingReconciliationTable
          rowsData={notPaid}
          stagedUpdates={stagedUpdates}
          setStagedUpdates={setStagedUpdates}
          resetDefaultStagedUpdates={resetDefaultStagedUpdates}
          handleSubmitUpdates={handleSubmitUpdates}
        />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={i++}>
        <PaidShippingTable rowsData={fullyPaid} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={i++}>
        <PaidShippingTable rowsData={partiallyPaid} />
      </TabPanel>
      {notFoundCount > 0 && (
        <TabPanel tabIndex={tabIndex} index={i++}>
          <PaidShippingTable rowsData={notFound} />
        </TabPanel>
      )}
      {invalidQuoteCount > 0 && (
        <TabPanel tabIndex={tabIndex} index={i++}>
          <PaidShippingTable rowsData={invalidQuote} />
        </TabPanel>
      )}
      <FooterControlPanel
        resetDefaultStagedUpdates={resetDefaultStagedUpdates}
        stagedUpdates={stagedUpdates}
        handleSubmitUpdates={handleSubmitUpdates}
        isSubmitting={isSubmitting}
      />
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>
            {!isEmpty(submitError)
              ? 'Payments failed to apply'
              : isSubmitting
              ? 'Applying payments to gray boxes'
              : `Payments successfully applied! Batch ID: ${batchId}`}
          </DialogTitle>
          <DialogContent>
            {!isEmpty(submitError)
              ? `The following error occurred: ${submitError}`
              : isSubmitting
              ? 'Please wait...'
              : 'Would you like to download a spreadsheet of the results for your records?'}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCloseDialog}
              disabled={isSubmitting && isEmpty(submitError)}
            >
              Close
            </Button>
            {isEmpty(submitError) && !isSubmitting && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadBatchUpdateReport}
                disabled={downloadingBatchUpdateReport}
              >
                Download
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ShippingReconciliationDashboard;
