import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MaterialLink from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import red from '@material-ui/core/colors/red';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import { useHttp } from '../../../common/Hooks';
import FinishInvoiceDialog from './FinishInvoiceDialog';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  label: {
    color: theme.palette.common.black
  },
  transactSaleButton: {
    backgroundColor: red[500]
  },
  searchContainer: {
    maxWidth: 500,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  search: {
    width: 180
  },
  invoiceSearch: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1)
    }
  }
}));

function useCommittedSales(initialState = []) {
  const [request, response] = useHttp('ims/committed-sales');
  const [committedSales, setCommittedSales] = useState(initialState);
  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [transactResults, setTransactResults] = useState(null);
  const auth = useContext(AuthContext);

  const getCommittedSales = useCallback(async () => {
    const newCommittedSales = await request.get();
    if (response.ok) {
      setCommittedSales(newCommittedSales);
      setIsFetching(false);
    } else {
      const text = await response.text();
      setIsFetching(false);
      setFetchError({ status: response.status, text });
    }
  }, [request, response, setCommittedSales, setIsFetching]);

  useEffect(() => {
    getCommittedSales();
  }, []);

  const transactSale = useCallback(
    async (imsTransactionReferenceId, strictMode = true, finishNotes = '') => {
      const transactResponse = await request.post(`/${imsTransactionReferenceId}/transact`, {
        invoicedById: auth.username,
        invoicedByName: auth.username,
        finishNotes: finishNotes === '' ? null : finishNotes,
        strictMode
      });
      if (response.ok) {
        setTransactResults(transactResponse);
        getCommittedSales();
      } else if (transactResponse.message) {
        setTransactResults(transactResponse);
      } else if (response.status === 404) {
        setTransactResults({
          message: 'The server responded with 404 (resource not found)'
        });
      } else {
        setTransactResults({
          status: response.status,
          data: transactResponse
        });
      }
    },
    [request, response, setTransactResults, getCommittedSales]
  );

  return [
    committedSales,
    transactSale,
    transactResults,
    setTransactResults,
    isFetching,
    fetchError,
    getCommittedSales
  ];
}

export default function CommittedSalesBoard() {
  const classes = useStyles();
  const [
    committedSales,
    transactSale,
    transactResults,
    setTransactResults,
    isFetching,
    fetchError,
    getCommittedSales
  ] = useCommittedSales();
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openFinishInvoiceDialog, setOpenFinishInvoiceDialog] = useState(false);
  const [saleToTransact, setSaleToTransact] = useState(null);
  const [strictMode, setStrictMode] = useState(true);
  const [finishNotes, setFinishNotes] = useState('');
  const [submittingTransactSale, setSubmittingTransactSale] = useState(false);
  const [form, setForm] = useState({
    invoice: '',
    invoiceError: false,
    model: '',
    modelError: false
  });

  const rows = useMemo(() => {
    const filteredCommittedSales = committedSales.filter(
      row =>
        `${row.legacy_pos_invoice_id}`.includes(form.invoice.toUpperCase()) &&
        row.product.model.includes(form.model.toUpperCase())
    );

    return orderBy(filteredCommittedSales, 'created_at', 'desc');
  }, [form.invoice, form.model, committedSales]);

  useEffect(() => {
    setForm(previousState => ({
      ...previousState,
      modelError: !rows.length && !!previousState.model.length,
      invoiceError: !rows.length && !!previousState.invoice.length
    }));
  }, [rows.length, form.invoice.length, form.model.length]);

  useEffect(() => {
    if (!isEmpty(transactResults)) {
      setSubmittingTransactSale(false);
    }
  }, [transactResults]);

  useEffect(() => {
    if (!openSubmitDialog) {
      setStrictMode(true);
      setFinishNotes('');
      setSaleToTransact(null);
      setSubmittingTransactSale(false);
      setTransactResults(null);
    }
  }, [openSubmitDialog]);

  const handleChange = useMemo(
    () => ({ target: { name, value } }) => {
      setForm(previousState => {
        if (previousState[`${name}Error`] && previousState[name].length < value.length) {
          return previousState;
        }
        return {
          ...previousState,
          [name]: value
        };
      });
    },
    []
  );

  return (
    <>
      <FinishInvoiceDialog
        open={openFinishInvoiceDialog}
        setOpen={setOpenFinishInvoiceDialog}
        onExit={getCommittedSales}
      />
      <Dialog open={openSubmitDialog} onExit={() => setOpenSubmitDialog(false)}>
        {submittingTransactSale ? (
          <ProcessingDialogContent setOpenSubmitDialog={setOpenSubmitDialog} />
        ) : transactResults != null ? (
          <ResultDialogContent
            transactResults={transactResults}
            setOpenSubmitDialog={setOpenSubmitDialog}
          />
        ) : saleToTransact != null ? (
          <ConfirmDialogContent
            saleToTransact={saleToTransact}
            submittingTransactSale={submittingTransactSale}
            setSubmittingTransactSale={setSubmittingTransactSale}
            transactSale={transactSale}
            setOpenSubmitDialog={setOpenSubmitDialog}
            classes={classes}
            strictMode={strictMode}
            setStrictMode={setStrictMode}
            finishNotes={finishNotes}
            setFinishNotes={setFinishNotes}
          />
        ) : (
          <ProcessingDialogContent setOpenSubmitDialog={setOpenSubmitDialog} />
        )}
      </Dialog>
      <Paper className={classes.searchContainer}>
        <Typography variant="h6" gutterBottom>
          Search Parameters
        </Typography>
        <TextField
          name="invoice"
          label="Invoice Number"
          value={form.invoice}
          onChange={handleChange}
          className={`${classes.invoiceSearch} ${classes.search}`}
          size="small"
          variant="outlined"
          error={form.invoiceError}
          helperText={form.invoiceError ? `There are no results for "${form.invoice}"` : null}
        />
        <TextField
          name="model"
          label="Model Number"
          value={form.model}
          onChange={handleChange}
          className={classes.search}
          size="small"
          variant="outlined"
          error={form.modelError}
          helperText={form.modelError ? `There are no results for "${form.model}"` : null}
        />
      </Paper>
      <Button
        onClick={() => setOpenFinishInvoiceDialog(true)}
        variant="contained"
        color="secondary"
        classes={classes}
      >
        Finish by Invoice #
      </Button>
      {isFetching ? (
        <p>Fetching committed sales...</p>
      ) : fetchError != null ? (
        <p>{JSON.stringify(fetchError, null, 2)}</p>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Model Number</TableCell>
                <TableCell>Product Title</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Scanning Required?</TableCell>
                <TableCell>Source Bucket</TableCell>
                <TableCell>Commit Notes</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.ims_transaction_reference_id}>
                  <TableCell>
                    <MaterialLink
                      href={`https://wpos.walts.com/pos/review_invoice.php?invoice=${row.legacy_pos_invoice_id}`}
                    >
                      {row.legacy_pos_invoice_id}
                    </MaterialLink>
                  </TableCell>
                  <TableCell>{row.product.model}</TableCell>
                  <TableCell style={{ maxWidth: 300 }}>
                    <MaterialLink
                      component={Link}
                      to={`/inventory/detail/model/${row.product.model}`}
                    >
                      {row.product.product_title}
                    </MaterialLink>
                  </TableCell>
                  <TableCell>{row.committed_quantity}</TableCell>
                  <TableCell>{row.scanning_required ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{row.source_bucket}</TableCell>
                  <TableCell>{row.commit_notes}</TableCell>
                  <TableCell>
                    <div style={{ margin: '3px' }}>
                      <Button
                        classes={{ label: classes.label }}
                        variant="contained"
                        size="small"
                        component={Link}
                        to={`/inventory-transactions/${row.ims_transaction_reference_id}`}
                      >
                        Details
                      </Button>
                    </div>
                    <div style={{ margin: '3px' }}>
                      <Button
                        classes={{
                          label: classes.label,
                          root: classes.transactSaleButton
                        }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setOpenSubmitDialog(true);
                          setSaleToTransact(row);
                        }}
                      >
                        Manually Transact Sale
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function ProcessingDialogContent({ setOpenSubmitDialog }) {
  return (
    <>
      <DialogTitle>Processing</DialogTitle>
      <DialogContent>
        <DialogContentText>Waiting for response from IMS...</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpenSubmitDialog(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

function ConfirmDialogContent({
  saleToTransact,
  submittingTransactSale,
  setSubmittingTransactSale,
  transactSale,
  setOpenSubmitDialog,
  classes,
  strictMode,
  setStrictMode,
  finishNotes,
  setFinishNotes
}) {
  return (
    <>
      <DialogTitle>
        Confirm Transact Sale: {saleToTransact.legacy_pos_invoice_id},{' '}
        {saleToTransact.product && saleToTransact.product.model}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to manually transact this sale? This action is not recommended, as
          it increases the likelihood of IMS falling out of sync with the POS.)
        </DialogContentText>
        <TextField
          label="Comments"
          multiline
          fullWidth
          rows="2"
          value={finishNotes}
          onChange={event => setFinishNotes(event.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Switch
              checked={strictMode}
              color="primary"
              onChange={event => setStrictMode(event.target.checked)}
              value="strictMode"
              inputProps={{ 'aria-label': 'set strict mode checkbox' }}
            />
          }
          labelPlacement="start"
          label={`Strict Mode ${strictMode ? 'On' : 'Off'}`}
        />
        <Button
          variant="contained"
          classes={{ root: classes.transactSaleButton }}
          disabled={submittingTransactSale}
          onClick={() => {
            setSubmittingTransactSale(true);
            transactSale(saleToTransact.ims_transaction_reference_id, strictMode, finishNotes);
          }}
        >
          Submit Transaction
        </Button>
        <Button variant="contained" onClick={() => setOpenSubmitDialog(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

function ResultDialogContent({ transactResults, setOpenSubmitDialog }) {
  return (
    <>
      <DialogTitle>{transactResults.message || 'Results'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {transactResults != null
            ? transactResults.message != null
              ? transactResults.message.includes('Success')
                ? 'Sale successfully trasacted!'
                : transactResults.errors || JSON.stringify(transactResults)
              : JSON.stringify(transactResults, null, 2)
            : 'No response to display'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpenSubmitDialog(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

CommittedSalesBoard.propTypes = {};
