import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { PurchaseOrder } from '../../../common/Types/ApiOsposDbModels/PurchaseOrderTypes';
import { useUpdatePurchaseOrder } from '../PurchaseOrderDetailsHooks';
import { makeUpdatePurchaseOrderRequestFromPurchaseOrder } from '../PurchaseOrderDetailsHelpers';

type PurchasingNotesProps = {
  purchaseOrder: PurchaseOrder;
  showPurchaseOrder: () => void;
};

const useStyles = makeStyles({
  root: {
    padding: '1rem'
  },
  textField: {
    marginBottom: '2rem'
  }
});

export default function PurchasingNotes(props: PurchasingNotesProps) {
  const { purchaseOrder, showPurchaseOrder } = props;

  const noteClasses = useStyles();
  const [poNotes, setPONotes] = useState<string>(purchaseOrder.notes || '');
  const updateNoteButtonRef = useRef<HTMLButtonElement>(null);
  const updatePurchaseOrder = useUpdatePurchaseOrder();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [updatedNote, setUpdatedNote] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setPONotes(e.target.value);
  };

  const uploadData = async () => {
    setDisabled(true);
    const updatePORequest = makeUpdatePurchaseOrderRequestFromPurchaseOrder(purchaseOrder);
    await updatePurchaseOrder(purchaseOrder.id, {
      ...updatePORequest,
      notes: poNotes
    });
    showPurchaseOrder();
    setDisabled(false);
    setUpdatedNote(true);
    setTimeout(() => {
      setUpdatedNote(false);
    }, 2000);
  };

  return (
    <div className={noteClasses.root}>
      <TextField
        className={noteClasses.textField}
        name="note"
        margin="normal"
        variant="outlined"
        label={updatedNote ? 'Notes (Saved)' : 'Notes'}
        fullWidth
        disabled={disabled}
        multiline
        rows={4}
        value={poNotes}
        onChange={e => handleChange(e)}
      />
      <Button
        buttonRef={updateNoteButtonRef}
        variant="contained"
        onClick={() => {
          uploadData();
          if (updateNoteButtonRef.current) {
            updateNoteButtonRef.current.blur();
          }
        }}
        disabled={poNotes === purchaseOrder.notes}
      >
        Update Note
      </Button>
    </div>
  );
}
