import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { useAxios, useToggle } from '../../common/Hooks';

function EPHoldingsCsvUpload() {
  const baseUrl = '/ep-holdings/orders';
  const [filenameOfGeneratedReport, setFilenameOfGeneratedReport] = useState('');
  const [syncSuccessMessage, setSyncSuccessMessage] = useState('');
  const [uploadFileForm, setUploadFileForm] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(false);
  const [axios] = useAxios(baseUrl);
  const [disableDownloadReportSubmitButton, setDisableDownloadReportSubmitButton] = useState(true);
  const [open, toggleOpen] = useToggle(true);
  const [cancelled, toggleCancelled] = useToggle(false);
  const history = useHistory();

  const handleChange = useCallback(event => {
    if (isEmpty(event.target.files) || isEmpty(event.target.files[0].name)) {
      setUploadFileForm(null);
    } else {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      setUploadFileForm(formData);
    }
  }, []);

  useEffect(() => {
    async function submitUploadFile() {
      try {
        const response = await axios.post('/upload', uploadFileForm);
        const json = response.data;
        setIsUploading(false);
        setFilenameOfGeneratedReport(json.filename);
        setDisableDownloadReportSubmitButton(false);
      } catch (error) {
        setIsUploading(false);
        setUploadFileForm(null);
        setUploadError(isEmpty(error.response) ? 'Unknown error' : error.response.data);
      }
    }
    if (uploadFileForm instanceof FormData && uploadFileForm.has('file')) {
      setIsUploading(true);
      setUploadError(null);
      submitUploadFile();
    }
  }, [uploadFileForm, axios]);

  const handleSubmit = useCallback(() => {
    async function submitSyncFile() {
      try {
        setIsUploading(true);
        setDisableDownloadReportSubmitButton(true);
        const response = await axios.put(`/sync?filename=${filenameOfGeneratedReport}`);
        const json = response.data;
        setIsUploading(false);
        setSyncSuccess(true);
        setSyncSuccessMessage(json.message);
      } catch (error) {
        setIsUploading(false);
        setUploadError(isEmpty(error.response) ? 'Unknown error' : error.response.data);
      }
    }
    if (!cancelled) {
      submitSyncFile();
    }
  }, [filenameOfGeneratedReport, axios, cancelled]);

  const handleCancelled = useCallback(() => {
    toggleCancelled(true);
    toggleOpen(false);
    history.push('/');
  }, []);

  return (
    <Dialog open={open} onClose={handleCancelled}>
      <DialogTitle>Upload EP Holdings CSV</DialogTitle>
      <DialogContent>
        {syncSuccess ? (
          <Typography>{syncSuccessMessage}</Typography>
        ) : (
          <>
            <input type="file" onChange={handleChange} name="file" />
            {isUploading && <Typography>Uploading...</Typography>}
            {!isEmpty(uploadError) && (
              <div style={{ color: 'red', margin: 5 }}>{JSON.stringify(uploadError)}</div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancelled}>
          {syncSuccess ? 'Close' : 'Cancel'}
        </Button>
        {!syncSuccess && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableDownloadReportSubmitButton}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EPHoldingsCsvUpload;
