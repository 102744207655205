import React, { useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MuiLink from '@material-ui/core/Link';
import { CheckCircle } from '@material-ui/icons';
import { useAuditBatchItems, useAuditBatchDetails } from './AuditBatchDashboard/hooks';
import { HideOrShow } from './ResolutionBoard/ResolutionBoard';

const useStyles = makeStyles(theme => ({
  singleItem: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 5
  },
  container: {
    background: '#fff',
    margin: 5,
    position: 'relative',
    width: '100%'
  },
  root: {
    background: '#f0f0f0',
    fontWeight: 'bold' // not working fk css
  },
  listFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  serialCard: {
    flex: '0 1 calc(25% - 10px)',
    margin: 5
  },
  serialHeader: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  detailList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    listStyle: 'none'
  },
  detailListItem: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function SerialList({ serials }) {
  const classes = useStyles();
  return (
    <div className={classes.listFlex}>
      {serials.length && serials.length > 0
        ? serials.map(item => (
            <Card
              key={item.fk_inventory_serial_id || item.scanned_serial_number}
              className={classes.serialCard}
            >
              <CardContent>
                <Typography className={classes.serialHeader}>
                  {item.fk_inventory_serial_id ? (
                    <Link target="_blank" to={`/inventory-serials/${item.fk_inventory_serial_id}`}>
                      {item.scanned_serial_number}
                    </Link>
                  ) : (
                    item.scanned_serial_number
                  )}
                </Typography>
              </CardContent>
            </Card>
          ))
        : null}
    </div>
  );
}

function DetailList({ children }) {
  const classes = useStyles();
  return <ul className={classes.detailList}>{children}</ul>;
}

function DetailListItem({ title, children }) {
  const classes = useStyles();
  return (
    <Typography component="li" className={classes.detailListItem}>
      <strong>{title}: </strong>
      <span>{children}</span>
    </Typography>
  );
}

function ReviewItem({ adjustmentItem }) {
  const classes = useStyles();
  const equalQty = useMemo(
    () => adjustmentItem.recorded_qty_at_start_of_tally === adjustmentItem.tallied_quantity,
    [adjustmentItem]
  );
  return (
    <Card className={classes.container} style={{ background: '#FFFFFF' }}>
      <CardHeader
        classes={{ root: classes.root }}
        title={`${adjustmentItem.product.model} at ${adjustmentItem.audit_bucket_map.bucket}`}
      />
      <CardContent>
        <DetailList>
          <DetailListItem title="Expected Quantity">
            {adjustmentItem.recorded_qty_at_start_of_tally}
          </DetailListItem>
          <DetailListItem title="Scanned Quantity">
            {adjustmentItem.tallied_quantity}
            {equalQty ? <CheckCircle style={{ fill: '#44EE00' }} /> : null}
          </DetailListItem>
          <DetailListItem title="Scanned By">{adjustmentItem.tallied_by_id}</DetailListItem>
          <DetailListItem title="Submitted/Resolved By">
            {adjustmentItem.submitted_by_id}
          </DetailListItem>
          <DetailListItem title="Submitted At">{adjustmentItem.submitted_at || ''}</DetailListItem>
          <DetailListItem title="Resolved At">{adjustmentItem.resolved_at || ''}</DetailListItem>
          <DetailListItem title="Serials">
            <HideOrShow component={React.Fragment}>
              <SerialList
                serials={adjustmentItem.serials}
                showDiff
                defaultForEmptyActualValue="(Removed)"
              />
            </HideOrShow>
          </DetailListItem>
        </DetailList>
      </CardContent>
    </Card>
  );
}

export default function AuditDiscrepancyReport() {
  const { auditBatchId } = useParams();
  const [fetchBatchItems, setFetchBatchItems] = useState(true);
  const { committed, transacted } = useAuditBatchItems(
    auditBatchId,
    fetchBatchItems,
    setFetchBatchItems
  );
  const auditBatchDetails = useAuditBatchDetails(auditBatchId);
  const allItems = [...committed, ...transacted];
  const auditBatchName = auditBatchDetails ? auditBatchDetails.audit_batch_name : '';
  const classes = useStyles();

  return (
    <>
      <MuiLink component={Link} to="/inventory/audit-scans">
        Back to Dashboard
      </MuiLink>
      <div>
        <Typography style={{ display: 'inline-block' }} variant="h2" gutterBottom>
          {auditBatchName || ''}
        </Typography>
        <Typography variant="h6">
          Audit Completed: {auditBatchDetails ? auditBatchDetails.completed_at : ''}
        </Typography>
      </div>
      <div className={classes.singleItem}>
        {allItems.length > 0
          ? allItems
              .sort(item => item.tally_started_at)
              .reverse()
              .map(adjustmentItem => (
                <ReviewItem
                  key={adjustmentItem.ims_transaction_reference_id}
                  adjustmentItem={adjustmentItem}
                  auditBatchId={auditBatchId}
                />
              ))
          : null}
      </div>
    </>
  );
}
