import React from 'react';
import Select from 'react-select';

import { TableRow, TableCell, Button, Dialog, TextField, Paper } from '@material-ui/core';

import { Close } from '@material-ui/icons';
import TableView from '../../../common/LayoutComponents/Table';
import ResultsView from '../../../common/LayoutComponents/ResultsView';

export default function InventoryAuditAdjustments(props) {
  const {
    models,
    selectedModel,
    setSelectedModel,
    setModelTerm,
    buckets,
    selectedBucket,
    setSelectedBucket,
    setNotes,
    setNewQty,
    adjustInventory,
    closeAdjustmentDialog,
    allBuckets,
    zeroBucket,
    setZeroBucket,
    setSelectedZeroBucket,
    setNewZeroValue,
    setZeroNotes,
    adjustZeroInventory,
    previousAuditAdjustments,
    getPreviousAuditAdjustments,
    setPreviousAuditAdjustments,
    getModels,
    getBucketsForModel,
    modelSearch
  } = props;

  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 999999
    })
  };
  return (
    <div>
      <h2>Model</h2>
      <Paper style={{ padding: '1rem' }}>
        <Select
          options={models.map(model => ({
            label: model.model_number,
            value: model.id
          }))}
          onChange={model => setSelectedModel(model !== null ? model.label : null)}
          onKeyDown={e => {
            if (e.which !== 40 && e.which !== 38) {
              setModelTerm(e.target.value);
            }
          }}
          onInputChange={v => setModelTerm(v)}
          onBlur={getModels}
          isClearable
          style={{ marginBottom: 10 }}
        />
        <p>
          <strong>Selected Model:</strong> {selectedModel || 'Please Select a Model'}
        </p>
        <Button variant="contained" onClick={() => getBucketsForModel(selectedModel)}>
          Select Model
        </Button>
      </Paper>
      {buckets.length === 0 && modelSearch && (
        <ResultsView>
          No inventory found for the selected models
          <Button variant="contained" onClick={() => setZeroBucket(true)}>
            Change Bucket Not Listed Here
          </Button>
        </ResultsView>
      )}
      {buckets.length > 0 && (
        <ResultsView>
          <div>
            <h2>{modelSearch}</h2>
            {buckets.length > 0 && (
              <TableView header={['Location', 'Quantity', 'Actions']}>
                {buckets.map(bucket => {
                  return (
                    <TableRow>
                      <TableCell>{bucket.bucket}</TableCell>
                      <TableCell>{bucket.quantity}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          style={{ marginRight: 20 }}
                          onClick={() => getPreviousAuditAdjustments(bucket.bucket)}
                        >
                          View audit adjustments
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setSelectedBucket({ [bucket.bucket]: bucket })}
                        >
                          Adjust Quantity
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableView>
            )}
            <Button onClick={() => setZeroBucket(true)} variant="contained">
              Change Bucket Not Listed Here
            </Button>
          </div>
        </ResultsView>
      )}
      {selectedBucket && (
        <Dialog open={selectedBucket} fullWidth onClose={closeAdjustmentDialog}>
          <div style={{ padding: 20 }}>
            <Button onClick={closeAdjustmentDialog}>
              <Close />
            </Button>
            <h2>{Object.keys(selectedBucket)[0]}</h2>
            <p>
              <strong>Current Quantity:</strong>{' '}
              {Object.values(selectedBucket).map(value => value.quantity)}
            </p>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="New Quantity"
              style={{ marginBottom: 20 }}
              onChange={e => setNewQty(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              multiline
              label="Notes (Required)"
              style={{ marginBottom: 20 }}
              onChange={e => setNotes(e.target.value)}
            />
            <Button variant="outlined" onClick={adjustInventory}>
              Submit Adjustment
            </Button>
          </div>
        </Dialog>
      )}
      {zeroBucket && (
        <Dialog open={zeroBucket} fullWidth onClose={() => setZeroBucket(false)}>
          <div style={{ padding: 20 }}>
            <Button onClick={() => setZeroBucket(false)}>
              <Close />
            </Button>
            <div style={{ marginBottom: 20 }}>
              <h2>Select a Bucket</h2>
              <Select
                options={allBuckets.map(bucket => ({
                  label: bucket,
                  value: bucket
                }))}
                styles={customStyles}
                onChange={e => setSelectedZeroBucket(e.value)}
              />
            </div>
            <TextField
              fullWidth
              variant="outlined"
              label="New Quantity"
              style={{ marginBottom: 20 }}
              onChange={e => setNewZeroValue(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              multiline
              label="Notes"
              style={{ marginBottom: 20 }}
              onChange={e => setZeroNotes(e.target.value)}
            />
            <Button onClick={adjustZeroInventory}>Submit</Button>
          </div>
        </Dialog>
      )}
      {previousAuditAdjustments.length > 0 && (
        <Dialog open={previousAuditAdjustments} onClose={() => setPreviousAuditAdjustments([])}>
          <div style={{ padding: 20 }}>
            <Button onClick={() => setPreviousAuditAdjustments([])}>
              <Close />
            </Button>
            {typeof previousAuditAdjustments !== 'string' ? (
              <TableView
                header={[
                  'Adjustment Time',
                  'Adjustment Notes',
                  'Submitted By',
                  'Quantity Adjusted'
                ]}
              >
                {previousAuditAdjustments.reverse().map(previous => (
                  <TableRow>
                    <TableCell>{previous.submitted_at}</TableCell>
                    <TableCell>{previous.tally_notes}</TableCell>
                    <TableCell>{previous.submitted_by_name}</TableCell>
                    <TableCell>{previous.new_quantity - previous.old_quantity}</TableCell>
                  </TableRow>
                ))}
              </TableView>
            ) : (
              <p>No audit adjustments have been made</p>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
}
