import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import GlueSearchBar from '../Presentational/GlueSearchBar';
import { BinManagementContext } from './BinManagementContext';

export default function BinSearch() {
  const { queryParams, setQueryParams } = useContext(BinManagementContext);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  useEffect(
    () => {
      if (typeof debouncedSearchTerm === 'string') {
        setQueryParams({
          ...queryParams,
          searchTerm: debouncedSearchTerm
        });
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  return <GlueSearchBar value={searchTerm} onChange={handleSearchChange} />;
}
