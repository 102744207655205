import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import useFetch from 'use-http';
import FileSaver from 'file-saver';

// Looks like there's some weird interop with Create-react-app TypeScript and this workerize-loader.
// The build process will hang with `Files successfully emitted, waiting for typecheck results...`
// It seems that you can force it to rebuild by editing and saving another file.
// https://github.com/facebook/create-react-app/issues/8707#issuecomment-606475886
/* eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved */
import DownloadWorker from 'workerize-loader!../workers/DownloadWorker';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';
import { AuthContext } from '../AuthContext';
import { apiOspos } from '../config/config';

/**
 *
 * @param {bool | null} initialStatus
 * @param {number} timeoutInSeconds
 * @returns {[boolean | null, React.Dispatch<React.SetStateAction<boolean | null>>, string | null, React.Dispatch<React.SetStateAction<string | null>>]}
 */
export function useStatus(initialStatus = null, timeoutInSeconds = 5) {
  const [status, setStatus] = useState(initialStatus);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (status !== null) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        setStatus(null);
        setMessage(null);
      }, timeoutInSeconds * 1000);
    }
  }, [status, timeoutInSeconds]);
  return [status, setStatus, message, setMessage];
}

/**
 * @param { Object } options
 * @param { string } options.reportName - The report name when downloaded
 *
 * @returns function[] sendUrlToWorker
 */

export function useDownloadWorker({ reportName = `report-${moment().format('MM-DD-YYYY')}` } = {}) {
  const { auth } = useContext(AuthContext);
  const [dispatch] = useMessageDispatch();
  const worker = useMemo(() => {
    return new DownloadWorker();
  }, []);

  useEffect(() => {
    const downloadReport = ({ data: { name, blob, ...errors } }) => {
      switch (name) {
        case 'download-link':
          FileSaver.saveAs(blob, `${reportName}.csv`);
          break;
        case undefined:
          // There's a weird case thing being sent back sometimes with an undefined value.
          break;
        default:
          dispatch({
            type: 'errors',
            message: errors.message || `No handler for ${name}`,
            status: errors.status || 'Unknown Error'
          });
      }
    };

    worker.addEventListener('message', downloadReport);

    return () => worker.removeEventListener('message', downloadReport);
  }, [dispatch, worker, reportName]);

  /**
   * @function sendUrlToWorker
   * @param { string } url - the url to fetch the report.
   */
  const sendUrlToWorker = useCallback(
    url => {
      worker.postMessage({ type: 'fetch-url', url: `${apiOspos.baseUrl}${url}`, auth });
    },
    [auth, worker]
  );

  return [sendUrlToWorker];
}

export function useQuery(search) {
  return new URLSearchParams(search);
}

export function useQueryWithLocation() {
  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  return query;
}

export function useMessageDispatch() {
  const dispatch = useDispatch();
  const advancedMessageDispatch = useCallback(
    ({ type, message, status }) => {
      const date = moment().format();
      dispatch({ type: `MESSAGING_QUEUE_SERVICE`, payload: { type, message, date, status } });
    },
    [dispatch]
  );

  return [advancedMessageDispatch];
}

export function useHttp(baseUrl, options = {}) {
  const auth = useContext(AuthContext);
  const [dispatch] = useMessageDispatch();
  const [request, response, loading] = useFetch(`${apiOspos.baseUrl}/${baseUrl}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${auth.auth}`,
      ...(options.headers ? options.headers : {})
    }
  });

  useEffect(() => {
    if (response.ok === false && !loading) {
      dispatch({ type: 'errors', message: response.url, status: response.status });
    }
  }, [response.ok, loading, response.status, dispatch, response.url]);

  return [request, response, auth, loading];
}

/**
 * @param { string } baseUrl
 * @param { AxiosRequestConfig } options
 *
 * @returns {[AxiosInstance]}
 */
export function useAxios(baseUrl, options = {}) {
  const auth = useContext(AuthContext);
  const [dispatch] = useMessageDispatch();
  const [memoizedOptions, setMemoizedOptions] = useState(options);
  const cleanBaseUrl = baseUrl.startsWith('/') ? baseUrl.substring(1) : baseUrl;

  useEffect(() => {
    if (!isEmpty(options)) {
      setMemoizedOptions(options);
    }
  }, [options]);

  const configuredAxios = useMemo(
    () =>
      axios.create({
        ...memoizedOptions,
        baseURL: `${apiOspos.baseUrl}/${cleanBaseUrl}`,
        headers: {
          Authorization: `Bearer ${auth.auth}`,
          ...(memoizedOptions.headers ? memoizedOptions.headers : {})
        }
      }),
    [cleanBaseUrl, auth, memoizedOptions]
  );

  const interceptError = useCallback(
    error => {
      if (error.isAxiosError) {
        dispatch({
          type: 'errors',
          message: error.request.responseURL,
          status: error.response.status
        });
      } else {
        dispatch({ type: 'errors', message: 'Unknown error with axios request', status: null });
      }
      return Promise.reject(error);
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isEmpty(configuredAxios)) {
      configuredAxios.interceptors.response.use(null, interceptError);
    }
  }, [configuredAxios, interceptError]);

  return [configuredAxios];
}

export function useDistributors() {
  const [distributorsList, setDistributorsList] = useState([]);
  const [request, response] = useHttp('ims/bucket-mappings');
  useEffect(() => {
    async function getDistributors() {
      const distributors = await request.get('/distributors');
      if (response.ok) {
        setDistributorsList(distributors);
      }
    }

    getDistributors();
  }, []);

  return [distributorsList, setDistributorsList];
}

export function useConditions() {
  const [conditionsList, setConditionsList] = useState([]);
  const [request, response] = useHttp('ims/bucket-mappings');

  useEffect(() => {
    async function getConditions() {
      const conditions = await request.get('/conditions');
      if (response.ok) {
        setConditionsList(conditions);
      }
    }

    getConditions();
  }, []);

  return [conditionsList, setConditionsList];
}
/**
 * @param {boolean} [defaultState = false]
 * @returns {[boolean, (override?: boolean) => void;]}
 */
export function useToggle(defaultState = false) {
  const [state, setState] = useState(defaultState);

  const toggleState = useCallback(override => {
    if (typeof override === 'boolean') {
      setState(override);
      return;
    }
    setState(prevState => !prevState);
  }, []);

  return [state, toggleState];
}

export function useSearchParams() {
  const query = useQueryWithLocation();
  const history = useHistory();
  const search = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(history.location.search));
  }, [history.location.search]);
  const setSearch = useCallback(
    (param, value) => {
      if (value === 0 || value) {
        query.set(param, value);
      } else {
        query.delete(param);
      }
      history.push(`?${query.toString()}`);
    },
    [query, history]
  );
  return [search, setSearch];
}
