import { Backdrop, createStyles, Fade, Modal, Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useState } from 'react';
import { GlueTheme } from '../../../GlueThemeType';
import { check } from '../../../roles/Can';
import { UserType } from '../../../roles/RolesTypes';
import { UserContext } from '../../../roles/User';
import GlueButton from '../../Presentational/GlueButton';
import GlueTooltip from '../../Presentational/GlueTooltip';
import { BinLocationsProvider, BinManagementContext, computeBinName, initialBinData, initialLocation } from '../BinManagementContext';
import CreateBinForm from './CreateBinForm';
import { ALLOWED_BIN_CONDITIONS } from './CreateBinTypes';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(8),
      maxWidth: 800
    },
    button: {
      margin: theme.spacing(4, 0)
    }
  })
);

export default function CreateBinModal() {
  const classes = useStyles();

  const {
    setCreateBinSuccess,
    setCreateBinErrors,
    setCreateBinData,
    createBinSuccess,
    getBinLocations
  } = useContext(BinManagementContext);
  const user: UserType = useContext(UserContext);
  const createEnabled = user && check(user.role, 'bin:admin');
  const createTooltipMessage = createEnabled
    ? 'Create new bin'
    : 'You do not have permission to create a bin.';

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (createEnabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setCreateBinErrors({});
    setCreateBinData({...initialBinData});
    setCreateBinSuccess(false);
    if (createBinSuccess) {
      getBinLocations();
    }
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.button}>
        <GlueTooltip title={createTooltipMessage}>
          <span>
            <GlueButton
              disabled={!createEnabled}
              variant={'contained'}
              endIcon="add"
              onClick={handleOpen}
            >
              Add New Bin Location
            </GlueButton>
          </span>
        </GlueTooltip>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="bin-create-form-modal"
        aria-describedby="bin-create-form-modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <CreateBinForm onCancel={handleClose} />
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
