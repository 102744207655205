import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  storeProduct,
  listProductCategories,
  listProductBrands,
  storeCategory,
  storeBrand,
  storeManufacturer
} from '../../../actions/productActions';

const useStyles = makeStyles({
  select: {
    minWidth: '15rem',
    margin: '0.5rem'
  }
});

export const CreateProductComponent = props => {
  const optionMap = {
    fk_category_id: props.storeCategory,
    fk_brand_id: props.storeBrand,
    fk_manufacturer_id: console.log,
    serial_number: console.log,
    item_type: console.log
  };
  const classes = useStyles();
  const { access_token } = props;
  const options = option => ({ value: option[1], label: option[0] });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const fillable = [
    { name: 'upc', type: 'text' },
    { name: 'description', type: 'text' },
    {
      name: 'fk_category_id',
      label: 'Cateogry',
      creatable: true,
      options: categories
    },
    { name: 'fk_brand_id', label: 'Brands', options: brands, creatable: true },
    {
      name: 'fk_manufacturer_id',
      type: 'text',
      label: 'Manufacturer',
      options: brands
    },
    { name: 'model_number', type: 'text' },
    { name: 'part_number', type: 'text' },
    {
      name: 'serial_number',
      type: 'text',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false }
      ]
    },
    { name: 'marketing_class', type: 'text', value: 'STANDARD' },
    { name: 'qty_on_hand', type: 'text', value: '0' },
    { name: 'qty_on_order', type: 'text', value: '0' },
    { name: 'qty_committed', type: 'text', value: '0' },
    { name: 'qty_on_quoted', type: 'text', value: '0' },
    { name: 'item_class', type: 'text' },
    {
      name: 'item_type',
      type: 'text',
      options: [
        { label: 'FREIGHT', value: 'FREIGHT' },
        { label: 'NON FREIGHT', value: 'NON FREIGHT' }
      ]
    },
    { name: 'stock_class', type: 'text', value: 'STOCK' },
    { name: 'box_qty', type: 'text', value: '1' },
    { name: 'external_data_source', type: 'text' },
    { name: 'external_data_model', type: 'text' },
    { name: 'external_data_source_id', type: 'text' },
    { name: 'external_data_source_update_status', type: 'text' },
    { name: 'status', type: 'text', value: 'ACTIVE' },
    { name: 'current_cost', type: 'text' },
    { name: 'current_rebate_credit', type: 'text' },
    { name: 'current_adj_cost', type: 'text' },
    { name: 'current_map', type: 'text' },
    { name: 'current_adj_map', type: 'text' },
    { name: 'current_rebate', type: 'text' },
    { name: 'map', type: 'text' },
    { name: 'msrp', type: 'text' },
    { name: 'minimum_price', type: 'text' },
    { name: 'spiff', type: 'text', value: '0.00' },
    { name: 'model_code', type: 'text' }
  ];

  const { categoriesFulfilled, listProductCategories, brandsFulfilled, listProductBrands } = props;
  const propsBrands = props.brands;
  const propsCategories = props.categories;

  useEffect(() => {
    if (categoriesFulfilled === false) {
      listProductCategories(access_token);
      setCategories(Object.entries(propsCategories).map(options));
    }
    if (brandsFulfilled === false) {
      listProductBrands(access_token);
      setBrands(Object.entries(propsBrands).map(options));
    }
  }, [
    listProductBrands,
    listProductCategories,
    brandsFulfilled,
    categoriesFulfilled,
    propsBrands,
    propsCategories,
    access_token
  ]);

  const [productForm, updateForm] = useState(
    fillable.reduce((acc, field) => ({ ...acc, [field.name]: field.value || '' }), {})
  );

  const handleChange = event =>
    updateForm({ ...productForm, [event.target.name]: event.target.value });

  const handleSelectChange = event => updateForm({ ...productForm, [event.name]: event.value });

  const fields = fillable.map(field => {
    if (field.options) {
      const handleCreate = (label, createFunc) => {
        createFunc(label, props.access_token);
        field.options.push({ label, value: label });
        const newOption = { name: field.name, value: label };
        handleSelectChange(newOption);
      };
      const styles = {
        control: styles => ({ ...styles, fontFamily: 'sans-serif' }),
        option: styles => ({ ...styles, fontFamily: 'sans-serif' })
      };
      const value =
        productForm[field.name] === ''
          ? undefined
          : {
              label: field.options.filter(x => x.value === productForm[field.name]).pop().label,
              value: productForm[field.name]
            };
      return (
        <FormControl key={field.name} className={classes.select}>
          <Typography variant="h6">{field.label || field.name}</Typography>
          {field.creatable !== undefined ? (
            <CreatableSelect
              onCreateOption={value => handleCreate(value, optionMap[field.name])}
              styles={styles}
              name={field.name}
              options={field.options}
              onChange={item => {
                handleSelectChange({ name: field.name, value: item.value });
              }}
              value={value}
            />
          ) : (
            <Select styles={styles} name={field.name} options={field.options} />
          )}
        </FormControl>
      );
    }
    return (
      <FormControl key={field.name} className={classes.select}>
        <Typography variant="h6">{field.label || field.name}</Typography>
        <TextField
          name={field.name}
          value={productForm[field.name]}
          onChange={handleChange}
          fullWidth
        />
      </FormControl>
    );
  });

  const sendProduct = () => props.storeProduct(access_token, productForm);

  return (
    <div>
      <Typography variant="h2">Create New Product</Typography>
      {fields}
      <div>
        <Button variant="contained" onClick={sendProduct}>
          Submit Product
        </Button>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    access_token: state.user.loginCredentials.access_token,
    categories: state.products.categories,
    categoriesFulfilled: state.products.categoriesFulfilled,
    brands: state.products.brands,
    brandsFulfilled: state.products.brandsFulfilled
  }),
  {
    storeProduct,
    listProductCategories,
    listProductBrands,
    storeCategory,
    storeManufacturer,
    storeBrand
  }
)(CreateProductComponent);
