import React, { useRef, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { isEmpty, startsWith } from 'lodash';
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { goodSound, badSound } from '../../common/playSound';
import { AuthContext } from '../../AuthContext';
import GlueButton from '../Presentational/GlueButton';
import ScanEventSubscriber from '../../common/ScanEventListening/ScanEventSubscriber';
import { usePickItem } from './PickItemHooks';
import AlreadyPickedItemLayout from './AlreadyPickedItemLayout';
import {
  PickItemGridBox,
  PickItemGridBoxContainer,
  PickItemGridBoxLabel,
  PickItemGridBoxValue
} from './PickItemGridBoxComponents';
import { makeRootStyles } from './PickItemStyles';
import { NoDataToDisplay, PickItemErrorLayout } from './PickItemErrorComponents';
import { AvailableBinGridPaper, SuggestedBinGridPaper } from './PickItemBinComponents';
import { looksLikeUpc } from '../../helpers/upcHelpers';

const NO_BINS_MESSAGE_LONG = 'There are no available bins to suggest.';
const NO_BINS_MESSAGE_SHORT = 'No available bins';

const useStyles = makeStyles(theme => ({
  root: makeRootStyles(theme),
  loadingText: {
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },
  successfulPickText: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
  },
  disabledTextField: {
    backgroundColor: theme.palette.action.disabledBackground
  },
  submitButton: {
    marginTop: theme.spacing(2)
  }
}));

export default function PickItemLayout() {
  const { pickListItemId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const {
    unpickedItem,
    fetchUnpickedItem,
    alreadyPickedItem,
    fetchAlreadyPickedItem,
    submitItemToPick,
    isFetching,
    isSubmitting,
    processBinScan,
    processUpcScan,
    upcApproved,
    binApproved,
    scannedBinName,
    scannedUpc,
    setScannedBinName,
    setScannedUpc,
    setPickedBy,
    doNotRemoveFromAnyBin,
    handleCheckDoNotRemoveFromAnyBin,
    binStatus,
    binMessage,
    upcStatus,
    upcMessage,
    itemSuccessfullyPicked,
    pickErrorMessage
  } = usePickItem();

  const binField = useRef(null);
  const upcField = useRef(null);
  const submitButton = useRef(null);
  const auth = useContext(AuthContext);

  useEffect(() => {
    async function fetchPickItemData() {
      const fetchedPickedItem = await fetchAlreadyPickedItem(pickListItemId);
      if (!fetchedPickedItem) {
        fetchUnpickedItem(pickListItemId);
      }
    }
    fetchPickItemData();
  }, [fetchUnpickedItem, fetchAlreadyPickedItem, pickListItemId]);

  useEffect(() => {
    setPickedBy(auth.username);
  }, [auth.username, setPickedBy]);

  useEffect(() => {
    let timeoutRef;
    if (itemSuccessfullyPicked) {
      timeoutRef = setTimeout(() => {
        history.push('/');
      }, 1500);
    }
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [itemSuccessfullyPicked]);

  useEffect(() => {
    if (pickErrorMessage) {
      badSound();
    }
  }, [pickErrorMessage]);

  const handleEnterBinInTextField = useCallback(
    binNameArg => {
      const binMatch = processBinScan(binNameArg);
      if (binMatch) {
        goodSound();
        if (!upcApproved) {
          upcField.current.focus();
        } else {
          submitButton.current.focus();
        }
      } else {
        badSound();
      }
    },
    [processBinScan, upcApproved]
  );

  const handleEnterUpcInTextField = useCallback(
    upcArg => {
      const upcMatch = processUpcScan(upcArg);
      if (upcMatch) {
        goodSound();
        if (!binApproved && !doNotRemoveFromAnyBin) {
          binField.current.focus();
        } else {
          submitButton.current.focus();
        }
      } else {
        badSound();
      }
    },
    [processUpcScan, binApproved, doNotRemoveFromAnyBin]
  );

  /** @type {import('../../common/ScanEventListening/ScanEventListeningTypes').ScanEventHandler} */
  const handleUnfocusedScan = useCallback(
    scanEvent => {
      // Call stopPropagation to prevent other upstream scan event handlers
      // from being invoked in the scan event bus.
      scanEvent.stopPropagation();
      if (looksLikeUpc(scanEvent.scannedValue)) {
        upcField.current.focus();
        setScannedUpc(scanEvent.scannedValue);
        handleEnterUpcInTextField(scanEvent.scannedValue);
      } else if (startsWith(scanEvent.scannedValue, 'BINASSIGN=') && !doNotRemoveFromAnyBin) {
        binField.current.focus();
        setScannedBinName(scanEvent.scannedValue);
        handleEnterBinInTextField(scanEvent.scannedValue);
      }
    },
    [
      handleEnterUpcInTextField,
      handleEnterBinInTextField,
      setScannedUpc,
      setScannedBinName,
      doNotRemoveFromAnyBin
    ]
  );

  return itemSuccessfullyPicked ? (
    <Typography className={classes.successfulPickText}>Item Successfully Picked!</Typography>
  ) : isFetching ? (
    <Typography className={classes.loadingText}>Fetching data...</Typography>
  ) : !isEmpty(alreadyPickedItem) ? (
    <AlreadyPickedItemLayout alreadyPickedItem={alreadyPickedItem} />
  ) : pickErrorMessage ? (
    <PickItemErrorLayout unpickedItem={unpickedItem} errorMessage={pickErrorMessage} />
  ) : isEmpty(unpickedItem) ? (
    <NoDataToDisplay />
  ) : (
    <ScanEventSubscriber onScan={handleUnfocusedScan}>
      <Grid container className={classes.root}>
        <Grid container spacing={5} alignItems="flex-start">
          <PickItemGridBox label="Model:">{unpickedItem.model}</PickItemGridBox>
          <PickItemGridBox label="Invoice:">{unpickedItem.invoice}</PickItemGridBox>
          <PickItemGridBox label="Quantity:">{unpickedItem.quantity}</PickItemGridBox>
          <PickItemGridBoxContainer>
            <PickItemGridBoxLabel>Available Bins:</PickItemGridBoxLabel>
            <Grid item container spacing={1} lg={4} md={6} xs={8}>
              {!isEmpty(unpickedItem.availableBins) ? (
                unpickedItem.availableBins.map(bin => {
                  const suggestedBinIdMatches =
                    !isEmpty(unpickedItem.suggestedBin) &&
                    unpickedItem.suggestedBin.binId === bin.binId;
                  if (suggestedBinIdMatches || unpickedItem.availableBins.length === 1) {
                    return (
                      <SuggestedBinGridPaper key={bin.binId}>{bin.binName}</SuggestedBinGridPaper>
                    );
                  }
                  return (
                    <AvailableBinGridPaper key={bin.binId}>{bin.binName}</AvailableBinGridPaper>
                  );
                })
              ) : (
                <Typography color="error">{NO_BINS_MESSAGE_LONG}</Typography>
              )}
            </Grid>
          </PickItemGridBoxContainer>

          <PickItemGridBoxContainer>
            <PickItemGridBoxLabel>Scan Bin Bar Code:</PickItemGridBoxLabel>
            <PickItemGridBoxValue>
              <TextField
                fullWidth
                label={
                  !isEmpty(unpickedItem.availableBins) ? 'Scan Bin Barcode' : NO_BINS_MESSAGE_SHORT
                }
                id="scan-bin-field"
                variant="outlined"
                placeholder="Scan Bin Barcode"
                autoFocus
                value={scannedBinName}
                error={binStatus === false}
                helperText={binMessage}
                onChange={e => setScannedBinName(e.target.value.trim())}
                inputRef={binField}
                className={doNotRemoveFromAnyBin ? classes.disabledTextField : ''}
                disabled={doNotRemoveFromAnyBin}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleEnterBinInTextField(scannedBinName);
                  }
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotRemoveFromAnyBin}
                    onChange={handleCheckDoNotRemoveFromAnyBin}
                    name="do-not-remove-from-any-bin-checkbox"
                    color="primary"
                    disabled={isEmpty(unpickedItem.availableBins)}
                  />
                }
                label="Do Not Remove From Any Bin"
              />
            </PickItemGridBoxValue>
          </PickItemGridBoxContainer>
          <PickItemGridBoxContainer>
            <PickItemGridBoxLabel>Scan UPC:</PickItemGridBoxLabel>
            <PickItemGridBoxValue>
              <TextField
                fullWidth
                label="Scan UPC"
                id="scan-upc-field"
                variant="outlined"
                placeholder="Scan UPC"
                inputRef={upcField}
                value={scannedUpc}
                error={upcStatus === false}
                helperText={upcMessage}
                onChange={e => setScannedUpc(e.target.value.trim())}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleEnterUpcInTextField(scannedUpc);
                  }
                }}
              />
            </PickItemGridBoxValue>
          </PickItemGridBoxContainer>
          <PickItemGridBoxContainer>
            <PickItemGridBoxLabel />
            <Grid item container lg={4} md={6} xs={8} justify="flex-end">
              <GlueButton
                onClick={submitItemToPick}
                size="large"
                disabled={isSubmitting}
                buttonRef={submitButton}
              >
                {isSubmitting ? 'Please Wait' : 'Submit'}
              </GlueButton>
            </Grid>
          </PickItemGridBoxContainer>
        </Grid>
      </Grid>
    </ScanEventSubscriber>
  );
}
