import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import { useHttp, useToggle } from '../../../common/Hooks';
import BinContentsStagingSpace from './BinContentsStagingSpace';
import BinContentsTable from './BinContentsTable';
import { GlueSwitch } from '../../Presentational/GlueSwitch';

export default function BinContentsLayout() {
  const { locationCode, binName } = useParams();
  const [binObject, setBinObject] = useState({});
  const [binId, setBinId] = useState('');
  const [refreshBinContents, setRefreshBinContents] = useState(false);
  const [refreshErrors, setRefreshErrors] = useState(false);
  const [binExactMatch, setBinExactMatch] = useState(true);
  const [alreadyScannedBinItems, setAlreadyScannedBinItems] = useState([]);
  const [isAdding, toggleIsAdding] = useToggle(true);
  const [request, response] = useHttp('ims/bins');

  useEffect(() => {
    setRefreshBinContents(false);
    async function getBinInfo() {
      const data = await request.get(
        `?searchTerm=${binName}&locationCode=${locationCode}&includeItems=1`
      );
      if (response.ok && !isEmpty(response.data)) {
        setRefreshErrors(false);
        setBinObject(data.pop());
      } else {
        setRefreshErrors(true);
        setBinExactMatch(false);
      }
    }
    getBinInfo();
  }, [binName, locationCode, refreshBinContents]);

  useEffect(() => {
    if (!isEmpty(binObject)) {
      if (binObject.binName === binName) {
        setAlreadyScannedBinItems(binObject.items);
        setBinId(binObject.id);
      } else {
        setBinExactMatch(false);
      }
    }
  }, [binObject]);

  return (
    <Grid container style={{ paddingTop: '20px' }}>
      <Grid container spacing={5} justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={2}>
          <Typography variant="h6">Bin Location:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{locationCode}</Typography>
        </Grid>
        <Grid item xs={8} />

        <Grid item xs={2}>
          <Typography variant="h6">Bin Name:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{binName}</Typography>
        </Grid>
        <Grid item xs={8} />

        <Grid item xs={2}>
          <Typography variant="h6">Bin Condtion:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">
            {binExactMatch ? binObject.conditionCode : "THIS BIN DOESN'T EXIST"}
          </Typography>
        </Grid>
        <Grid item xs={8} />

        <Grid item xs={4}>
          <FormControlLabel
            control={<GlueSwitch checked={isAdding} onChange={toggleIsAdding} size="large" />}
            label={
              isAdding ? (
                <Typography variant="h6" style={{ paddingLeft: '20px' }}>
                  Adding Items
                </Typography>
              ) : (
                <Typography variant="h6" style={{ paddingLeft: '20px' }}>
                  Removing Items
                </Typography>
              )
            }
            labelPlacement="end"
          />
        </Grid>

        <Grid item xs={8} />

        <Grid item xs={12}>
          <BinContentsStagingSpace
            isAdding={isAdding}
            binId={binId}
            refreshBinContents={() => setRefreshBinContents(true)}
            binExactMatch={binExactMatch}
          />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6">Bin Contents:</Typography>
        </Grid>
        <Grid item xs={10} />

        <Grid item xs={12}>
          <BinContentsTable scannedItems={alreadyScannedBinItems} refreshErrors={refreshErrors} />
        </Grid>
      </Grid>
    </Grid>
  );
}
