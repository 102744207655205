import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PickItemGridBox } from './PickItemGridBoxComponents';
import { makePickItemErrorMessageStyles, makeRootStyles } from './PickItemStyles';

const useStyles = makeStyles(theme => ({
  root: makeRootStyles(theme),
  pickItemError: makePickItemErrorMessageStyles(theme)
}));

/**
 * @param {Object} props
 * @param {import('./PickItemTypes').AlreadyPickedItem} props.alreadyPickedItem
 */
export default function AlreadyPickedItemLayout({ alreadyPickedItem }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid container spacing={5} alignItems="flex-start">
        <PickItemGridBox label="Model:">{alreadyPickedItem.productModel}</PickItemGridBox>
        <PickItemGridBox label="Invoice:">{alreadyPickedItem.itemGroupRefId}</PickItemGridBox>
        <PickItemGridBox label="Quantity:">{alreadyPickedItem.pickedQuantity}</PickItemGridBox>
        <PickItemGridBox label="Error Message:">
          <Typography className={classes.pickItemError}>Item Has Already Been Picked</Typography>
        </PickItemGridBox>
      </Grid>
    </Grid>
  );
}
