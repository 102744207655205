import { apiOspos } from '../config/config';

export function resetMessaging() {
  return dispatch => {
    return dispatch({ type: 'RESET_MESSAGES', payload: '' });
  };
}

export function quoteCreate(authorizationToken, quoteToCreate) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/store`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(quoteToCreate)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({ type: 'CREATE_QUOTE_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'CREATE_QUOTE_FULFILLED', payload: responseData });
        }
      });
  };
}

export function createNewQuoteState() {
  return dispatch => {
    return dispatch({ type: 'CREATE_NEW_QUOTE', payload: '' });
  };
}

export function quoteItemDetails(quoteItemDetail) {
  return dispatch => {
    return dispatch({ type: 'QUOTE_ITEM_DETAILS', payload: quoteItemDetail });
  };
}

export function addNewQuoteItemState() {
  return dispatch => {
    return dispatch({ type: 'GOTO_ADD_QUOTE_ITEM', payload: '' });
  };
}

export function editQuoteItems(data) {
  return dispatch => {
    return dispatch({ type: 'EDIT_QUOTE_ITEMS', payload: data });
  };
}

export function quoteItemUpdate(quoteItemToUpdate, authorizationToken, cb = () => {}) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/item/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: quoteItemToUpdate.id,
        // brand: quoteItemToUpdate.brand,
        // category: quoteItemToUpdate.category,
        condition: quoteItemToUpdate.condition,
        // created_at: quoteItemToUpdate.created_at,
        // description: quoteItemToUpdate.description,
        // employee_id: quoteItemToUpdate.employee_id,
        // ext_price: quoteItemToUpdate.ext_price,
        // final_gross_profit: quoteItemToUpdate.final_gross_profit,
        // item_class: quoteItemToUpdate.item_class,
        // item_type: quoteItemToUpdate.item_type,
        // model: quoteItemToUpdate.model,
        number_units: quoteItemToUpdate.number_units,
        // part_number: quoteItemToUpdate.part_number,
        // product_id: quoteItemToUpdate.product_id,
        // quote_date: quoteItemToUpdate.quote_date,
        quote_id: quoteItemToUpdate.quote_id,
        // serial_number: quoteItemToUpdate.serial_number,
        // source_vendor: quoteItemToUpdate.source_vendor,
        // standard_gross_profit: quoteItemToUpdate.standard_gross_profit,
        // tax_amount: quoteItemToUpdate.tax_amount,
        // tax_code: quoteItemToUpdate.tax_code,
        unit_price: quoteItemToUpdate.unit_price
        // upc: quoteItemToUpdate.upc,
        // updated_at: quoteItemToUpdate.updated_at,
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'QUOTE_ITEM_UPDATE_REJECTED',
            payload: responseData.data
          });
          cb({
            model_number: quoteItemToUpdate.model_number,
            quoteItemId: quoteItemToUpdate.id,
            errors: responseData.data
          });
        } else {
          dispatch({
            type: 'QUOTE_ITEM_UPDATE_FULFILLED',
            payload: responseData.data
          });
          cb(null, responseData.data);
        }
      });
  };
}

export function quoteSearch(searchKey, searchValue, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchKey,
        searchValue
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({ type: 'SEARCH_QUOTES_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'SEARCH_QUOTES_FULFILLED', payload: responseData });
        }
      });
  };
}

export function searchContactsForQuotes(searchKey, searchValue, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/quotes`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchKey, searchValue })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'SEARCH_CONTACTS_FOR_QUOTES_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'SEARCH_CONTACTS_FOR_QUOTES_FULFILLED',
            payload: responseData
          });
        }
      });
  };
}

export function searchContactsForQuotesByName(firstName, lastName, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/quotes/name`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ firstName, lastName })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'SEARCH_CONTACTS_FOR_QUOTES_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'SEARCH_CONTACTS_FOR_QUOTES_FULFILLED',
            payload: responseData
          });
        }
      });
  };
}

export function quoteUpdate(authorizationToken, quoteToUpdate) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: quoteToUpdate.id,
        sold_contact_id: quoteToUpdate.sold_contact_id,
        ship_contact_id: quoteToUpdate.ship_contact_id,
        bill_contact_id: quoteToUpdate.bill_contact_id,
        sold_account_id: quoteToUpdate.sold_account_id,
        quote_type: quoteToUpdate.quote_type,
        quote_class: quoteToUpdate.quote_class,
        quote_status: quoteToUpdate.quote_status,
        primary_sales_id: quoteToUpdate.primary_sales_id,
        second_sales_id: quoteToUpdate.second_sales_id,
        third_sales_id: quoteToUpdate.third_sales_id,
        product_total: quoteToUpdate.product_total,
        labor_total: quoteToUpdate.labor_total,
        shipping_total: quoteToUpdate.shipping_total,
        tax_total: quoteToUpdate.tax_total,
        total: quoteToUpdate.total,
        notes: quoteToUpdate.notes,
        lead_source: quoteToUpdate.lead_source,
        primary_development: quoteToUpdate.primary_development,
        primary_product_interest: quoteToUpdate.primary_product_interest,
        primary_feature_interest: quoteToUpdate.primary_feature_interest,
        demo_affinity: quoteToUpdate.demo_affinity,
        approval_status: quoteToUpdate.approval_status,
        approval_status_notes: quoteToUpdate.approval_status_notes
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'QUOTE_UPDATE_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'QUOTE_UPDATE_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function getQuoteItems(authorizationToken, searchKey, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/item/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchKey, searchValue })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'QUOTE_ITEMS_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'QUOTE_ITEMS_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function getFull(searchValue, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/getFull/${searchValue}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'GET_FULL_QUOTE_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'GET_FULL_QUOTE_FULFILLED', payload: responseData });
        }
      });
  };
}

export function addQuoteItem(quoteId, quoteItem, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/item`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        productId: quoteItem.id,
        quote_id: quoteId
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'ADD_QUOTE_ITEM_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'ADD_QUOTE_ITEM_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function deleteQuoteItem(quoteItemId, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/item/${quoteItemId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({
            type: 'QUOTE_ITEM_DELETE_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'QUOTE_ITEM_DELETE_FULFILLED',
            payload: quoteItemId
          });
        }
      });
  };
}

export function convertToOrder(quoteId, authorizationToken, cb = () => {}) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/convertToOrder/${quoteId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'CONVERT_TO_WORKORDER_REJECTED',
            payload: responseData
          });
          cb({ errors: responseData.data });
        } else {
          dispatch({
            type: 'CONVERT_TO_WORKORDER_FULFILLED',
            payload: responseData
          });
          cb(null, responseData.data);
        }
      });
  };
}

export function selectedQuote(quote) {
  return dispatch => {
    return dispatch({ type: 'SELECTED_QUOTE', payload: quote });
  };
}

export function createLaborLine(authorizationToken, laborLine) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/laborLines`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(laborLine)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'CREATE_QUOTE_LABOR_LINE_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'CREATE_QUOTE_LABOR_LINE_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function updateLaborLine(authorizationToken, laborLine) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/laborLines/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(laborLine)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'UPDATE_QUOTE_LABOR_LINE_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'UPDATE_QUOTE_LABOR_LINE_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function deleteLaborLine(authorizationToken, laborLineId) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/quote/laborLines/delete/${laborLineId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({
            type: 'DELETE_QUOTE_LABOR_LINE_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'DELETE_QUOTE_LABOR_LINE_FULFILLED',
            payload: laborLineId
          });
        }
      });
  };
}
