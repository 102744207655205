import React, { PureComponent } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import PickListTable from '../PickListTable';

interface PrintPickListTableProps {
  itemsNumber: number;
  isDonePrinting: boolean;
  className: string;
  warehouse: string;
}

interface PrintPickListTableState {
  date: string;
  inverval?: NodeJS.Timeout;
}

export class PrintPickListTable extends PureComponent<PrintPickListTableProps, PrintPickListTableState> {
  constructor(props: PrintPickListTableProps) {
    super(props);
    this.state = {
      date: moment().format('M-D-YY hh:mm:ss'),
    };
  }

  componentDidMount() {
    if (!this.props.isDonePrinting) {
      const newInterval = setInterval(() => {
        this.setState({
          date: moment().format('M-D-YY hh:mm:ss'),
        });
      }, 1000);
    
      this.setState((prevState): PrintPickListTableState => {
        return {
          ...prevState,
          inverval: newInterval,
        };
      });
    }
  }

  componentWillUnmount() {
    if (this.state.inverval) {
      clearInterval(this.state.inverval);
    }
  }

  render() {
    return (
      <div
        style={{
          width: 1080,
          padding: 24
        }}
      >
        <Typography variant="h4">Pick List ({this.props.itemsNumber} Items)</Typography>
        <Typography
          style={{
            marginBottom: 24,
            color: '#6D6D6D'
          }}
          variant="h5"
        >
          {this.props.warehouse} - Printed at {this.state.date}
        </Typography>
        <PickListTable printVersion />
      </div>
    );
  }
}
