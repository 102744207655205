import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { useHttp } from '../../../common/Hooks';
import TableView from '../../../common/LayoutComponents/Table';
import { CommittedTransfersDashboard } from '../../CommittedTransfers/CommittedTransfersDashboard';
import TransactionSerialExpansionPanel from './TransactionSerialExpansionPanel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function InventoryTransactionDetails() {
  const { id } = useParams();

  const [request, response] = useHttp('ims/');
  const [transaction, setTransaction] = useState(null);

  const getInventoryTransaction = useCallback(async argId => {
    const inventoryTransactionResponse = await request.get(`inventory-transactions/${argId}`);
    if (response.ok) {
      setTransaction(inventoryTransactionResponse);
    } else {
      setTransaction(null);
    }
  }, []);

  useEffect(() => {
    getInventoryTransaction(id);
  }, [id, getInventoryTransaction]);

  return (
    <>
      {transaction ? <Transaction transaction={transaction} /> : null}
      <Button onClick={() => getInventoryTransaction(id)}>Refresh Transaction Data</Button>
    </>
  );
}

function Transaction({ transaction }) {
  return (
    <div style={{ marginTop: '15px' }}>
      <h2>
        {startCase(transaction.transaction_type.toLowerCase())}
        {/* eslint-disable-next-line no-use-before-define */}
        {getTransactionStatus(transaction) && ` (${getTransactionStatus(transaction)})`}
      </h2>
      {(() => {
        switch (transaction.transaction_type) {
          case 'INVENTORY_RECEIPT':
          case 'INVENTORY_SALE':
          case 'INVENTORY_AUDIT_ADJUSTMENT':
            return <QuickTransactionView transaction={transaction} />;

          case 'INVENTORY_TRANSFER':
            return transaction.transacted_transfer == null ? (
              <CommittedTransfersDashboard
                imsTransactionReferenceIdOverride={transaction.ims_transaction_reference_id}
              />
            ) : (
              <QuickTransactionView transaction={transaction} />
            );

          default:
            return <div>Unsupported transaction type {transaction.transaction_type}</div>;
        }
      })()}
    </div>
  );
}

function QuickTransactionView({ transaction }) {
  const classes = useStyles();
  // function hoisting is fine here
  // eslint-disable-next-line no-use-before-define
  const transactionDetails = getTransactionDetails(transaction);
  const fieldsToIgnore = ['product', 'serials', 'id'];
  const pertinentFields = Object.keys(transactionDetails).filter(field => {
    return (
      !fieldsToIgnore.includes(field) &&
      !field.includes('location_code') &&
      !field.includes('condition_code') &&
      !isArray(transactionDetails[field]) &&
      !isObject(transactionDetails[field])
    );
  });

  const commitmentRelatedFields = [];
  const nonCommitmentRelatedFields = [];
  pertinentFields.forEach(field => {
    if (field.includes('commit') || field.includes('order')) {
      commitmentRelatedFields.push(field);
    } else {
      nonCommitmentRelatedFields.push(field);
    }
  });

  return (
    <div>
      {isEmpty(pertinentFields) ? (
        <h3>No transaction details to display.</h3>
      ) : (
        <>
          <TableView
            header={[...nonCommitmentRelatedFields, ...commitmentRelatedFields].map(header =>
              header.replace(/_/g, ' ')
            )}
            tableProps={{
              size: 'small'
            }}
            tableHeaderCellProps={{
              style: {
                fontSize: '1.1em'
              }
            }}
          >
            <TableRow>
              {[
                ...nonCommitmentRelatedFields.map(field => (
                  <TableCell key={field}>{transactionDetails[field]}</TableCell>
                )),
                ...commitmentRelatedFields.map(field => (
                  <TableCell key={field}>{transactionDetails[field]}</TableCell>
                ))
              ]}
            </TableRow>
          </TableView>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <h2>Scanned Serials</h2>
              {isEmpty(transactionDetails.serials) ? (
                <p>There are no serials associated with this transaction.</p>
              ) : (
                <List className={classes.root}>
                  {transactionDetails.serials.map(serial => (
                    <TransactionSerialExpansionPanel serial={serial} />
                  ))}
                </List>
              )}
            </div>
            <div style={{ width: '50%' }}>
              <h2>Product Details</h2>
              <table style={{ textAlign: 'left' }}>
                <tbody>
                  <tr>
                    <th>Model:</th>
                    <td>{transactionDetails.product.model}</td>
                  </tr>
                  <tr>
                    <th>Brand:</th>
                    <td>{transactionDetails.product.brand_code}</td>
                  </tr>
                  <tr>
                    <th>UPC:</th>
                    <td>{transactionDetails.product.upc}</td>
                  </tr>
                  <tr>
                    <th>Description:</th>
                    <td>
                      <Link to={`/inventory/detail/model/${transactionDetails.product.model}`}>
                        {transactionDetails.product.product_title}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>Serial Tracked:?</th>
                    <td>{transactionDetails.product.serial_number_tracked === 1 ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <th>Num Of Serials Per Item:</th>
                    <td>{transactionDetails.product.num_of_serials_per_item}</td>
                  </tr>
                  <tr>
                    <th>Master Box Qty:</th>
                    <td>{transactionDetails.product.master_box_qty}</td>
                  </tr>
                  <tr>
                    <th>Master Carton Code:</th>
                    <td>{transactionDetails.product.master_carton_code}</td>
                  </tr>
                  <tr>
                    <th>MPN:</th>
                    <td>{transactionDetails.product.mpn}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function getTransactionStatus(transaction) {
  const keys = Object.keys(transaction);
  const isCommittedKey = keys.some(key => key.includes('committed_') && !isEmpty(transaction[key]));
  if (isCommittedKey) return 'Committed';
  const isTransactedKey = keys.some(
    key => key.includes('transacted_') && !isEmpty(transaction[key])
  );
  if (isTransactedKey) return 'Transacted';

  return false;
}

function getTransactionDetails(transaction) {
  const transactionDetailPropertyList = [
    'committed_receipt',
    'transacted_receipt',
    'committed_transfer',
    'transacted_transfer',
    'committed_sale',
    'transacted_sale',
    'committed_audit_adjustment',
    'transacted_audit_adjustment'
  ];

  for (let i = 0; i < transactionDetailPropertyList.length; i++) {
    const property = transactionDetailPropertyList[i];
    if (!isEmpty(transaction[property])) {
      return transaction[property];
    }
  }

  return {};
}
