import React, { useState } from 'react';
import Select from 'react-select';
import { isEmpty, map } from 'lodash';
import { Grid } from '@material-ui/core';
import { useBrandList } from '../BrandSellThru/Hooks.tsx';
import { useHttp } from '../../../common/Hooks';
import GlueButton from '../../Presentational/GlueButton.tsx';
import SelloutThreatTable from './SelloutThreatTable';

export default function SelloutThreat() {
  const brandList = useBrandList();
  const [itemsToReport, setItemsToReport] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const handleSelectedBrand = option => {
    setSelectedBrand(option);
  };
  const [loading, setLoading] = useState(false);
  const [req, res] = useHttp(
    `ims/reporting/sellout-threat?brands[]=${map(selectedBrand, 'value').join('&brands[]=')}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );

  async function getSelloutThreatReport(e) {
    e.preventDefault();
    setLoading(true);

    if (!isEmpty(selectedBrand)) {
      const data = await req.get();
      if (res.ok) {
        setItemsToReport(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }
  return (
    <Grid container style={{ paddingTop: '15px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <GlueButton size="medium" onClick={getSelloutThreatReport} endIcon="refresh">
            Submit Sellout Threat Data
          </GlueButton>
        </Grid>
        <Grid item xs={6} style={{ zIndex: 4 }}>
          <Select
            options={Object.keys(brandList).map(brand => ({
              label: brand,
              value: brand
            }))}
            isMulti
            onChange={handleSelectedBrand}
            value={selectedBrand}
            menuPosition="fixed"
            id="brands"
          />
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <p>Loading...</p>
          ) : isEmpty(itemsToReport) ? (
            <p>There is no data to display.</p>
          ) : (
            <SelloutThreatTable itemsToReport={itemsToReport} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
