import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Update, Done, Add, Delete } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useAuditBatchItems, useAuditBatchDetails } from './hooks';
import { useHttp } from '../../../common/Hooks';
import SetUpScanner from '../Scanner/SetUpScanner';
import Scanner from '../Scanner/Scanner';
import ResolutionBoard from '../ResolutionBoard/ResolutionBoard';
import Can from '../../../roles/Can';
import ScanEventUnsubscriber from '../../../common/ScanEventListening/ScanEventUnsubscriber';

const adjustmentItemStyles = makeStyles(() => ({
  singleItem: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 5
  },
  container: {
    background: '#fff',
    margin: 5,
    position: 'relative'
  },
  deleteIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px'
  }
}));

function AreYouSure({ confirmed, backOut }) {
  return (
    <Alert
      severity="warning"
      action={
        <>
          <Button variant="outlined" onClick={confirmed}>
            Yes
          </Button>
          <Button color="inherit" onClick={backOut}>
            No
          </Button>
        </>
      }
    >
      <AlertTitle>Are you sure?</AlertTitle>
    </Alert>
  );
}

function AuditAdjustmentItem({ adjustmentItem, auditBatchName, auditBatchId, setToUpdateItems }) {
  const classes = adjustmentItemStyles();
  const StatusIcon = () => (adjustmentItem.tally_finished_at !== null ? <Done /> : <Update />);
  const [openContinueAuditModal, setOpenContinueAuditModal] = useState(false);
  const [openResolutionBoard, setOpenResolutionBoard] = useState(false);
  const [request, response] = useHttp('ims');
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const isResolving = useMemo(() => {
    if (!isEmpty(adjustmentItem.resolution_started_at) && isEmpty(adjustmentItem.resolved_at))
      return true;
    return false;
  }, [adjustmentItem.resolution_started_at, adjustmentItem.resolved_at]);

  async function deleteCommittedAuditAdjustment() {
    const imsTransactionRefId = adjustmentItem.ims_transaction_reference_id;
    await request.delete(`/audit-scans/${imsTransactionRefId}`);
    if (response.ok) {
      return setToUpdateItems(true);
    }
    return false;
  }

  return (
    <>
      <Card
        className={classes.container}
        style={
          isEmpty(adjustmentItem.resolved_at) && isEmpty(adjustmentItem.resolution_started_at)
            ? null
            : isResolving
            ? { background: '#FFFFB0' }
            : { background: '#f0f0f0' }
        }
      >
        <CardContent>
          <Typography className={classes.cardTitle} variant="h5" display="inline">
            <StatusIcon /> {adjustmentItem.product.model}
          </Typography>
          {confirmDeletion ? (
            <AreYouSure
              confirmed={deleteCommittedAuditAdjustment}
              backOut={() => setConfirmDeletion(false)}
            />
          ) : (
            <IconButton
              className={classes.deleteIcon}
              disabled={adjustmentItem.tally_finished_at !== null}
              onClick={() => {
                setConfirmDeletion(true);
              }}
            >
              <Delete />
            </IconButton>
          )}

          <h3>Bucket: {adjustmentItem.audit_bucket_map.bucket}</h3>
          <p>
            Scanned Quantity:{' '}
            {adjustmentItem.tallied_quantity ? adjustmentItem.tallied_quantity : 0}
          </p>
          <p>
            Scanned By:{' '}
            {!isEmpty(adjustmentItem.serials) &&
            !isEmpty(adjustmentItem.serials[adjustmentItem.serials.length - 1].scanned_by)
              ? adjustmentItem.serials[adjustmentItem.serials.length - 1].scanned_by
              : adjustmentItem.tallied_by_id}
          </p>
          <p>
            Last Scanned:{' '}
            {!isEmpty(adjustmentItem.serials)
              ? new Date(
                  adjustmentItem.serials[adjustmentItem.serials.length - 1].scanned_at
                ).toLocaleString()
              : new Date(adjustmentItem.tally_started_at).toLocaleString()}
          </p>
          {isResolving ? <Typography variant="h5">Resolution in Progress</Typography> : null}
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              setOpenContinueAuditModal(true);
            }}
            disabled={adjustmentItem.tally_finished_at !== null}
          >
            Continue Scanning
          </Button>
          <Button
            onClick={() => {
              setOpenResolutionBoard(true);
            }}
            disabled={
              (adjustmentItem.resolved_at === undefined
                ? false
                : adjustmentItem.resolved_at !== null) ||
              (adjustmentItem.resolution_started_at === undefined
                ? false
                : adjustmentItem.resolution_started_at !== null)
            }
          >
            Review and Resolve
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={openContinueAuditModal}
        onClose={() => {
          setOpenContinueAuditModal(false);
          setToUpdateItems(true);
        }}
      >
        <Scanner
          product={adjustmentItem.product}
          setScannerTime={() => {
            setOpenContinueAuditModal(false);
            setToUpdateItems(true);
          }}
          auditBucket={adjustmentItem.audit_bucket_map.bucket}
          auditBatchName={auditBatchName}
          auditBatchId={auditBatchId}
          inProgressScans={adjustmentItem.serials}
          isSetup={false}
          auditItem={adjustmentItem}
        />
      </Dialog>
      <Dialog
        maxWidth="xl"
        fullWidth
        style={{ minHeight: '50vh' }}
        open={openResolutionBoard}
        onClose={() => {
          setOpenResolutionBoard(false);
          setToUpdateItems(true);
        }}
      >
        <ResolutionBoard
          adjustmentItem={adjustmentItem}
          closeResolutionBoard={() => {
            setOpenResolutionBoard(false);
            setToUpdateItems(true);
          }}
        />
      </Dialog>
    </>
  );
}

export default function AuditBatchDashboard() {
  const { auditBatchId } = useParams();
  const [fetchBatchItems, setFetchBatchItems] = useState(true);
  const { committed, transacted } = useAuditBatchItems(
    auditBatchId,
    fetchBatchItems,
    setFetchBatchItems
  );
  const allItems = [...committed, ...transacted];
  const auditBatchDetails = useAuditBatchDetails(auditBatchId);
  const auditBatchName = auditBatchDetails ? auditBatchDetails.audit_batch_name : '';
  const [bucketOptions, setBucketOptions] = useState([]);
  const [bucketSelection, setBucketSelection] = useState('');
  const defaultAuditBucket = auditBatchDetails ? auditBatchDetails.default_audit_bucket : '';
  const [openContinueAuditModal, setOpenContinueAuditModal] = useState(false);
  const [request, response] = useHttp('ims');
  const [openCompleteBatchModal, setOpenCompleteBatchModal] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const classes = adjustmentItemStyles();
  const history = useHistory();

  useEffect(() => {
    async function getBuckets() {
      const bucketsResponse = await request.get('/buckets');
      if (response.ok && bucketsResponse) {
        setBucketOptions(
          Object.keys(bucketsResponse).map(bucket => ({ label: bucket, value: bucket }))
        );
      }
    }
    getBuckets();
  }, []);

  const completeAuditBatch = useCallback(async () => {
    await request.put('/audit-scans/finishBatch', { audit_batch_id: auditBatchId });
    if (response.ok) {
      // Return to audit batch dashboard
      setRedirectToDashboard(true);
    }
  }, [request, auditBatchId, response.ok, setRedirectToDashboard]);

  useEffect(() => {
    if (redirectToDashboard || !isEmpty(auditBatchDetails.completed_at)) {
      return history.push('/inventory/audit-scans');
    }
  }, [redirectToDashboard, history, auditBatchDetails]);

  return (
    <>
      <ScanEventUnsubscriber />
      <MuiLink component={Link} to="/inventory/audit-scans">
        Back to Dashboard
      </MuiLink>
      <MuiLink
        style={{ float: 'right' }}
        component={Link}
        to={`/inventory/audit-discrepancy-report/${auditBatchId}`}
      >
        View Report
      </MuiLink>
      <div display="inline-flex">
        <Typography style={{ float: 'left' }} variant="h2" gutterBottom>
          {auditBatchName || ''}
        </Typography>
        <Can
          perform="audit-scan:submit"
          yes={() => (
            <>
              <Button
                style={{ float: 'right' }}
                variant="contained"
                onClick={() => setOpenCompleteBatchModal(true)}
              >
                Complete Audit
              </Button>
              <Dialog
                // classes={classes}
                open={openCompleteBatchModal}
                onClose={() => setOpenCompleteBatchModal(false)}
              >
                <DialogTitle>{auditBatchName}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to complete this audit?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenCompleteBatchModal(false)}>Cancel</Button>
                  <Button onClick={() => completeAuditBatch()}>Yes</Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        />
      </div>
      <Grid container spacing={1} zeroMinWidth alignItems="flex-start" justify="flex-start">
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setOpenContinueAuditModal(true)}
          >
            Scan New Item
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Select
            id="bucketSearch"
            isClearable
            placeholder="Select Bucket"
            options={bucketOptions}
            onChange={e => setBucketSelection(e !== null ? e.value : '')}
            value={
              bucketSelection
                ? { label: bucketSelection, value: bucketSelection }
                : defaultAuditBucket
                ? { label: defaultAuditBucket, value: defaultAuditBucket }
                : null
            }
          />
        </Grid>
      </Grid>
      <div className={classes.singleItem}>
        {allItems.length > 0
          ? allItems
              .sort(item => item.tally_started_at)
              .reverse()
              .map(adjustmentItem => (
                <AuditAdjustmentItem
                  key={adjustmentItem.ims_transaction_reference_id}
                  adjustmentItem={adjustmentItem}
                  auditBatchName={auditBatchName}
                  auditBatchId={auditBatchId}
                  setToUpdateItems={setFetchBatchItems}
                />
              ))
          : null}
      </div>
      <SetUpScanner
        openContinueAuditModal={openContinueAuditModal}
        setOpenContinueAuditModal={() => {
          setOpenContinueAuditModal(false);
          setFetchBatchItems(true);
        }}
        audit_batch_name={auditBatchName}
        audit_batch_id={auditBatchId}
        allItems={allItems}
        bucket={bucketSelection || defaultAuditBucket}
      />
    </>
  );
}
