import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { styled, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import isEmpty from 'lodash/isEmpty';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useHttp } from '../../common/Hooks';
import InProgressAuditBatchesContainer from './InProgressAuditBatchesContainer';
import CompletedAuditBatchesContainer from './CompletedAuditBatchesContainer';
import { AuditBatchesContext } from './AuditBatchesContext';

const useStyles = makeStyles(theme => ({
  tabPaper: {
    maxWidth: '65rem',
    marginTop: theme.spacing(2)
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white
}));

function AuditBatchCreationForm({ closeModal }) {
  const currentDate = moment().format('YYYY-MM-DD');
  const [batchName, setBatchName] = useState('');
  const { setToUpdateInProgress } = useContext(AuditBatchesContext);
  const [bucketOptions, setBucketOptions] = useState([]);
  const [bucketSelection, setBucketSelection] = useState('');
  const [dateInput, setDateInput] = useState(currentDate);
  const [errors, setErrors] = useState({});
  const [request, response] = useHttp('ims');
  const defaultAuditBatchName = useMemo(
    () => `${dateInput} ${bucketSelection.toUpperCase()} Audit`,
    [dateInput, bucketSelection]
  );

  useEffect(() => {
    setBatchName(defaultAuditBatchName);
  }, [defaultAuditBatchName]);

  const submitFunction = useCallback(
    async e => {
      e.preventDefault();
      const internalSubmit = await request.post('/audit-scans/', {
        audit_batch_name: batchName,
        default_audit_bucket: bucketSelection
      });
      if (response.ok) {
        setErrors({});
        setBatchName('');
        setToUpdateInProgress(true);
        closeModal();
      } else {
        setErrors(internalSubmit);
      }
    },
    [batchName, bucketSelection, closeModal, request, response.ok, setToUpdateInProgress]
  );

  useEffect(() => {
    async function getBuckets() {
      const bucketsResponse = await request.get('/buckets');
      if (response.ok && bucketsResponse) {
        setBucketOptions(
          Object.keys(bucketsResponse).map(bucket => ({ label: bucket, value: bucket }))
        );
      }
    }
    getBuckets();
    // eslint-disable-next-line
  }, []);

  return (
    <form
      onSubmit={e => submitFunction(e)}
      style={{
        alignItems: 'left',
        justifyContent: 'space-between',
        minHeight: '30rem',
        maxWidth: '60rem',
        paddingBottom: '15px',
        paddingLeft: '15px',
        paddingRight: '15px'
      }}
    >
      <Grid container spacing={2} alignItems="flex-start" justify="flex-start">
        <Grid item xs={8}>
          <TextField
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => setDateInput(e.target.value)}
            label="Date Of Audit"
            type="date"
            value={dateInput}
          />
        </Grid>
        <Grid item xs={8}>
          <Select
            id="bucketSearch"
            isClearable
            placeholder="Select Default Bucket"
            options={bucketOptions}
            onChange={e => setBucketSelection(!isEmpty(e) ? e.value : '')}
            value={bucketSelection ? { label: bucketSelection, value: bucketSelection } : null}
          />
          {errors.default_audit_bucket ? (
            <Alert severity="error">{errors.default_audit_bucket}</Alert>
          ) : null}
        </Grid>
        {!isEmpty(dateInput) && !isEmpty(bucketSelection) && (
          <>
            <Grid item xs={12}>
              <TextField
                value={batchName}
                variant="outlined"
                fullWidth
                label="Audit Name"
                onChange={e => setBatchName(e.target.value)}
              />
              {errors.audit_batch_name ? (
                <Alert severity="error">{errors.audit_batch_name}</Alert>
              ) : null}
            </Grid>
            <Grid item xs>
              <Button
                type="submit"
                variant="contained"
                onClick={e => {
                  submitFunction(e);
                }}
              >
                Create New Audit
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

const AUDIT_BATCHES_TABS = {
  'In Progress': InProgressAuditBatchesContainer,
  'Completed Audits': CompletedAuditBatchesContainer
};

function AuditBatchesLandingPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [updateInProgress, setToUpdateInProgress] = useState(true);
  const [updateCompleted, setToUpdateCompleted] = useState(true);
  const [openCreateNewBatchModal, setOpenCreateNewBatchModal] = useState(false);

  const classes = useStyles();
  // useEffect(() => {
  //   getInProgressBatches();
  // }, []);
  const handleTabChange = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);
  return (
    <AuditBatchesContext.Provider
      value={{
        updateInProgress,
        setToUpdateInProgress,
        updateCompleted,
        setToUpdateCompleted
      }}
    >
      <Button variant="contained" type="submit" onClick={() => setOpenCreateNewBatchModal(true)}>
        Create New Audit
      </Button>
      <Paper className={classes.tabPaper}>
        <AppBar position="static">
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="audit dashboard tabs">
            {Object.keys(AUDIT_BATCHES_TABS).map(label => (
              <StyledTab key={label} label={label} />
            ))}
          </Tabs>
        </AppBar>
        {tabIndex === 0 ? <InProgressAuditBatchesContainer /> : null}
        {tabIndex === 1 ? <CompletedAuditBatchesContainer /> : null}
      </Paper>
      {openCreateNewBatchModal ? (
        <Dialog
          maxWidth="md"
          fullWidth
          open={openCreateNewBatchModal}
          onClose={() => setOpenCreateNewBatchModal(false)}
        >
          <DialogTitle>Create New Audit</DialogTitle>
          <AuditBatchCreationForm closeModal={() => setOpenCreateNewBatchModal(false)} />
        </Dialog>
      ) : null}
    </AuditBatchesContext.Provider>
  );
}

export default AuditBatchesLandingPage;
