import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShippingTemplateRules from './ShippingTemplateRules';
import { ShippingTemplateContext } from '../shippingTemplate/shippingTemplateContext';

export default function ShippingTemplateRulesContainer() {
  const { shippingTemplates, rules } = useContext(ShippingTemplateContext);
  const shippingTemplateOptions = shippingTemplates.map(({ shipping_template }) => {
    return { label: shipping_template, value: shipping_template };
  });

  if (rules.length === 0) return <LinearProgress />;

  return (
    <ShippingTemplateRules
      shippingTemplateRules={rules}
      shippingTemplateOptions={shippingTemplateOptions}
    />
  );
}
