import React, { useState, useEffect, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import red from '@material-ui/core/colors/red';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from '../../../AuthContext';
import { useHttp } from '../../../common/Hooks';

const useStyles = makeStyles(() => ({
  finishInvoiceButton: {
    backgroundColor: red[500]
  }
}));

function useFinishSales() {
  const [request, response] = useHttp('ims/legacy-wpos/finishInvoice');
  const [finishResults, setFinishResults] = useState(null);
  const auth = useContext(AuthContext);

  const finishInvoice = useCallback(
    async (legacyPosInvoiceId, strictMode = true, finishNotes = '') => {
      const transactResponse = await request.post('/', {
        legacyPosInvoiceId,
        invoicedById: auth.username,
        invoicedByName: auth.username,
        finishNotes: finishNotes === '' ? null : finishNotes,
        strictMode
      });
      if (response.ok) {
        setFinishResults(transactResponse);
      } else if (transactResponse.message) {
        setFinishResults(transactResponse);
      } else if (response.status === 404) {
        setFinishResults({
          message: 'The server responded with 404 (resource not found)'
        });
      } else {
        setFinishResults({
          status: response.status,
          data: transactResponse
        });
      }
    },
    [request, response, setFinishResults, auth.username]
  );

  return [finishInvoice, finishResults, setFinishResults];
}

export default function FinishInvoiceDialog({ open, setOpen, onExit = () => {} }) {
  const classes = useStyles();
  const [finishInvoice, finishResults, setFinishResults] = useFinishSales();
  const [strictMode, setStrictMode] = useState(true);
  const [finishNotes, setFinishNotes] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [finishingInvoice, setFinishingInvoice] = useState(false);

  useEffect(() => {
    if (!open) {
      setFinishResults(null);
      setInvoiceNumber('');
      setFinishNotes('');
      setStrictMode(false);
      setFinishingInvoice(false);
    }
  }, [open]);

  useEffect(() => {
    if (!isEmpty(finishResults)) {
      setFinishingInvoice(false);
    }
  }, [finishResults]);

  return (
    <Dialog
      open={open}
      onExit={() => {
        setOpen(false);
        onExit();
      }}
    >
      {finishingInvoice ? (
        <ProcessingDialogContent setOpen={setOpen} />
      ) : finishResults != null ? (
        <ResultDialogContent finishResults={finishResults} setOpen={setOpen} />
      ) : (
        <FinishInvoiceFormContent
          invoiceNumber={invoiceNumber}
          finishInvoice={finishInvoice}
          setInvoiceNumber={setInvoiceNumber}
          finishingInvoice={finishingInvoice}
          setFinishingInvoice={setFinishingInvoice}
          setOpen={setOpen}
          classes={classes}
          strictMode={strictMode}
          setStrictMode={setStrictMode}
          finishNotes={finishNotes}
          setFinishNotes={setFinishNotes}
        />
      )}
    </Dialog>
  );
}

function ProcessingDialogContent({ setOpen }) {
  return (
    <>
      <DialogTitle>Processing</DialogTitle>
      <DialogContent>
        <DialogContentText>Waiting for response from IMS...</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

function FinishInvoiceFormContent({
  invoiceNumber,
  setInvoiceNumber,
  finishingInvoice,
  setFinishingInvoice,
  setOpen,
  classes,
  strictMode,
  setStrictMode,
  finishNotes,
  finishInvoice,
  setFinishNotes
}) {
  return (
    <>
      <DialogTitle>Finish by Invoice Number</DialogTitle>
      <DialogContent>
        <DialogContentText>
          WARNING: This action could lead to unintended consequences if it is not utilized properly.
          For example if there are any line items for the invoice that have been partially finished,
          it might lead to errors. Also if the invoice has not been finished in the POS 1, IMS will
          be out-of-sync.
        </DialogContentText>
        <TextField
          name="invoiceNumber"
          label="Invoice Number"
          value={invoiceNumber}
          onChange={event => setInvoiceNumber(event.target.value)}
          variant="outlined"
        />
        <TextField
          label="Comments"
          multiline
          fullWidth
          rows="2"
          value={finishNotes}
          onChange={event => setFinishNotes(event.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Switch
              checked={strictMode}
              color="primary"
              onChange={event => setStrictMode(event.target.checked)}
              value="strictMode"
              inputProps={{ 'aria-label': 'set strict mode checkbox' }}
            />
          }
          labelPlacement="start"
          label={`Strict Mode ${strictMode ? 'On' : 'Off'}`}
        />
        <Button
          variant="contained"
          classes={{ root: classes.finishInvoiceButton }}
          disabled={finishingInvoice}
          onClick={() => {
            setFinishingInvoice(true);
            finishInvoice(invoiceNumber, strictMode, finishNotes);
          }}
        >
          Finish Invoice
        </Button>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

function ResultDialogContent({ finishResults, setOpen }) {
  return (
    <>
      <DialogTitle>{finishResults.message || 'Results'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {finishResults != null
            ? finishResults.message != null
              ? finishResults.message.includes('Success')
                ? 'Sale successfully trasacted!'
                : finishResults.errors || JSON.stringify(finishResults)
              : JSON.stringify(finishResults, null, 2)
            : 'No response to display'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Exit
        </Button>
      </DialogActions>
    </>
  );
}

FinishInvoiceDialog.propTypes = {};
