import React from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { CheckListItem } from '../stringToJson';
import { check } from '../../../roles/Can';

// Todo::073120 These JsonRow and CheckListItem should be moved out to a types file.
type RowCheckProps = {
  checkList: CheckListItem[];
  removeRowFromTable: (index: number) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  rowCheck: {
    backgroundColor: red[100],
    color: theme.palette.getContrastText(red[100]),
    padding: theme.spacing(2)
  },
  deleteRoot: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900]
    }
  },
  deleteAll: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900]
    },
    float: 'right'
  }
}));

// Todo::073120 RowCheck is a bad name
export default function RowCheck({ checkList, removeRowFromTable }: RowCheckProps) {
  const classes = useStyles();

  const removeAllErrorRows = () => {
    checkList.forEach(({ rowIndex }) => {
      removeRowFromTable(rowIndex);
    });
  };

  return (
    <>
      <List
        subheader={
          <ListSubheader component="div">
            Please ensure the correctness of the following rows
            <Button
              classes={{ root: classes.deleteAll }}
              onClick={removeAllErrorRows}
              variant="contained"
              size="small"
            >
              Remove all error rows
            </Button>
          </ListSubheader>
        }
      >
        {checkList.map(({ rowIndex, row }) => (
          <ListItem classes={{ root: classes.rowCheck }} key={rowIndex}>
            Row {rowIndex}: {Object.values(row).join(',')}
            <ListItemSecondaryAction>
              <Button
                classes={{ root: classes.deleteRoot }}
                onClick={() => removeRowFromTable(rowIndex)}
                variant="contained"
                size="small"
              >
                Remove Row
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}
