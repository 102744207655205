import React from 'react';
import { Grid, Icon, Typography } from '@material-ui/core';

export type GlueErrorDisplayProps = {
  className?: string;
  message?: string;
};

const GlueErrorDisplay = ({ className, message }: GlueErrorDisplayProps) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" className={className}>
      <Typography color="error" align="center" variant="h6">
        {message ? message : 'There was an error.'}
      </Typography>
      <Typography color="error" align="center" variant="h6">
        <Icon color={'error'} fontSize="large">
          error
        </Icon>
      </Typography>
    </Grid>
  );
};

export default GlueErrorDisplay;
