import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import GlueButton from '../../Presentational/GlueButton.tsx';
import { addCarouselItem } from '../redux/carouselSlice';
import { useValidUrl } from '../hooks/useValidUrl';
import { useAxios } from '../../../common/Hooks';
import { getAxiosResponseErrorMessage } from '../../../helpers/getErrors';

const AddNewDialog = ({ onClose, open }) => {
  const [imageDetails, setImageDetails] = useState({});
  const [pageLink, setPageLink] = useState('');
  const pageLinkIsValid = useValidUrl(pageLink);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [axios] = useAxios('/carousel-images');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setErrors([]);
  }, [imageDetails]);

  const revokeImageUrl = () => {
    if (!isEmpty(imageDetails)) {
      URL.revokeObjectURL(imageDetails.localUrl);
    }
  };

  const closeAndResetState = () => {
    onClose();
    setPageLink('');
    revokeImageUrl();
    setImageDetails({});
    setLoading(false);
    setErrors([]);
  };

  const handleFileInput = e => {
    if (!isEmpty(e.target.files)) {
      revokeImageUrl();
      setImageDetails({
        localUrl: URL.createObjectURL(e.target.files[0]),
        blob: e.target.files[0]
      });
    }
  };

  const handleSaveFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', imageDetails.blob);
      const response = await axios.post('/s3', formData);
      const json = response.data;
      dispatch(
        addCarouselItem({
          img: json.remoteImageUrl,
          link: pageLink
        })
      );
      closeAndResetState();
    } catch (error) {
      const axiosErrors = getAxiosResponseErrorMessage(error);
      // eslint-disable-next-line no-prototype-builtins
      const axiosMessages = axiosErrors.hasOwnProperty('image') ? axiosErrors.image : axiosErrors;
      setErrors([].concat(axiosMessages));
    }
    setLoading(false);
  };

  return (
    <Dialog onClose={closeAndResetState} open={open}>
      <DialogTitle>
        <span role="img" aria-label="mage emoji">
          🧙
        </span>{' '}
        Add new carousel image
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Images must be in <code>.gif, .jpg,</code> or <code>.png</code> format and have dimensions
          of exactly <code>1200px</code> x <code>300px</code>. The filesize cannot exceed{' '}
          <code>1mb (1000kb)</code>.
        </DialogContentText>
      </DialogContent>
      <input
        type="file"
        onChange={handleFileInput}
        style={{ margin: '1em', fontFamily: 'Open Sans' }}
        accept="image/jpg, image/jpeg, image/png, image/gif"
        disabled={loading}
      />
      {!isEmpty(errors) &&
        errors.map(errorAttr => (
          <Fade in={!isEmpty(errors)} key={uniqueId('anImgErrFade')}>
            <Alert severity="error" key={uniqueId('anImgErr')}>
              {errorAttr === 'Unknown error'
                ? 'Exceeded server filesize transfer threshold. Images must be less than 1mb in size.'
                : errorAttr}
            </Alert>
          </Fade>
        ))}
      <Fade in={!isEmpty(imageDetails)} timeout={{ enter: 500 }}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '1em' }}>
          <img
            id="upload-preview"
            src={imageDetails.localUrl}
            alt="upload preview"
            style={{
              maxWidth: '100%',
              maxHeight: '20vh',
              border: '10px solid #e0e0e0',
              borderRadius: '4px'
            }}
          />
        </div>
      </Fade>
      <Fade in={!isEmpty(imageDetails)} timeout={{ enter: 1000 }}>
        <DialogContent>
          <DialogContentText>
            Please enter the URL of the page you want the image to link to, starting with{' '}
            <code>https://</code>
          </DialogContentText>
        </DialogContent>
      </Fade>
      <Fade in={!isEmpty(imageDetails)} timeout={{ enter: 1200 }}>
        <TextField
          value={pageLink}
          label={!pageLinkIsValid && !isEmpty(pageLink) ? 'Invalid URL' : 'Link'}
          onChange={e => setPageLink(e.target.value)}
          error={!pageLinkIsValid && !isEmpty(pageLink)}
          style={{ margin: '1em' }}
          disabled={loading}
        />
      </Fade>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1em' }}
      >
        <GlueButton
          endIcon={loading ? <CircularProgress color="inherit" size="1em" /> : 'save'}
          onClick={handleSaveFile}
          disabled={isEmpty(pageLink) || !pageLinkIsValid || loading || !isEmpty(errors)}
        >
          Save
        </GlueButton>
        <GlueButton
          endIcon="close"
          onClick={closeAndResetState}
          style={{ marginLeft: '0.5em' }}
          variant="outlined"
        >
          Close
        </GlueButton>
      </div>
    </Dialog>
  );
};

export default AddNewDialog;
