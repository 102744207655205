import { FormHelperText, Grid } from '@material-ui/core';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { BinManagementContext } from '../BinManagementContext';

export default function BinRowBayShelfHelperText() {
  const { createBinErrors } = useContext(BinManagementContext);

  return (
    <Grid container justify={'space-between'} xs={12} spacing={3}>
      <Grid item xs={12} sm={3}></Grid>
      <Grid item xs={12} sm={9}>
        <FormHelperText
          error={!!createBinErrors.row || !!createBinErrors.bay || !!createBinErrors.shelf}
          id="row-bay-shelf-helper-text"
        >
          {get(createBinErrors, 'row', []).join('') +
            get(createBinErrors, 'bay', []).join('') +
            get(createBinErrors, 'shelf', []).join('') ||
            'Select the row, bay, and shelf for the bin.'}
        </FormHelperText>
      </Grid>
    </Grid>
  );
}
