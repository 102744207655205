import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(0.3)
  },
  date: {
    fontSize: '0.6rem'
  },
  styleBox: {
    position: 'fixed',
    right: theme.spacing(1),
    transition: 'bottom 1s',
    bottom: theme.spacing(1),
    zIndex: 9999999
  }
}));

const DEFAULT_ERROR_MESSAGES = {
  // 200s
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  // 400s
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  // 500s
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  510: 'Not Extended',
  511: 'Network Authentication Required'
};

const severityConversion = {
  errors: 'error',
  successes: 'success'
};

function Message({ date, status, message, count, type }) {
  const [mount, setMount] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  useLayoutEffect(() => {
    let timeout;
    if (mount === false) {
      timeout = setTimeout(
        () =>
          requestAnimationFrame(() =>
            dispatch({
              type: 'MESSAGING_QUEUE_SERVICE::CLEAR',
              payload: { message, date, type }
            })
          ),
        700
      );
    }

    return () => clearTimeout(timeout);
  }, [mount, date, dispatch, message, type]);

  useEffect(() => {
    const timeout = 10000;
    const growTimeout = setTimeout(() => setMount(false), timeout);
    setMount(true);

    return () => clearTimeout(growTimeout);
  }, []);

  return (
    // TODO: This style spacing should be more dynamic based on the size of the previous
    // error message.
    <div className={classes.styleBox} style={{ bottom: `${(count - 1) * 9.5 + 1}%` }}>
      <Slide direction="up" in={mount} unmountOnExit timeout={750}>
        <div>
          <Fade in={mount} timeout={{ enter: 900, exit: 300 }}>
            <Box boxShadow={3}>
              <Alert onClose={() => setMount(false)} severity={severityConversion[type]}>
                <Typography className={classes.title}>
                  {status}: {DEFAULT_ERROR_MESSAGES[status]}
                </Typography>
                <Typography variant="body1">{message}</Typography>
                <Typography align="right" className={classes.date} variant="body2">
                  date: {date}
                </Typography>
              </Alert>
            </Box>
          </Fade>
        </div>
      </Slide>
    </div>
  );
}

export default Message;
