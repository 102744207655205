import React, { useCallback, useEffect } from 'react';
import { ScanEventHandler, ScanEventUnsubscriberProps } from './ScanEventListeningTypes';
import ScanEventSubscriber from './ScanEventSubscriber';

export default function ScanEventUnsubscriber(props: ScanEventUnsubscriberProps) {
  const { children } = props;

  const onScanPlaceholder = useCallback<ScanEventHandler>(() => {}, []);

  useEffect(() => {
    console.log('Unsubscribing upstream scan event listening.');
    return () => {
      console.log('Restoring upstream scan event listening.');
    };
  }, []);

  return (
    <ScanEventSubscriber onScan={onScanPlaceholder} preventBubblingToAncestorElements>
      {children}
    </ScanEventSubscriber>
  );
}
