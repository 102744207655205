/* eslint-disable no-prototype-builtins */
import React, { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import { IconButton, Snackbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { uniqueId } from 'lodash';
import { Alert } from '@material-ui/lab';
import { useVendorList, useFinanceCompanies } from './hooks';
import { useHttp } from '../../common/Hooks';
import { ApInvoicesContext } from '../../App';
import GlueButton from '../Presentational/GlueButton';
import GlueSelect from '../Presentational/GlueSelect';

const useCreateFormStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  }
}));

type InvoiceCreationErrors = {
  vendor?: string | null;
  finance_company?: string | null;
  invoice_number?: string | null;
  invoice_amount?: string | null;
  invoice_date?: string | null;
  due_date?: string | null;
  purchase_date?: string | null;
  purchase_id?: string | null;
};

export default function CreationForm() {
  const [req, res] = useHttp('accounts-payable-invoice', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const { setToUpdate } = useContext(ApInvoicesContext);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [vendors] = useVendorList();
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [financeCompanies] = useFinanceCompanies();
  const [selectedFc, setSelectedFc] = useState<string | null>('');
  const [purchaseRefId, setPurchaseRefId] = useState<string | null>(null);
  const classes = useCreateFormStyles();
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [errors, setErrors] = useState<InvoiceCreationErrors | null>(null);
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    if (selectedVendor && invoiceNumber && invoiceDate) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedVendor, invoiceNumber, invoiceDate]);

  useEffect(() => {
    if (vendors.data) {
      const enCollator = new Intl.Collator('en');
      vendors.data.sort((a: any, b: any) => {
        return enCollator.compare(a.company_name, b.company_name);
      });
    }
  }, [vendors]);

  useEffect(() => {
    if (financeCompanies.data) {
      const enCollator = new Intl.Collator('en');
      financeCompanies.data.sort((a: any, b: any) => {
        return enCollator.compare(a.company_name, b.company_name);
      });
    }
  }, [financeCompanies]);

  const resetFormState = () => {
    setInvoiceNumber('');
    setInvoiceAmount(0);
    setSelectedVendor('');
    setSelectedFc('');
    setInvoiceDate('');
    setPurchaseDate('');
    setDueDate('');
    setPurchaseRefId('');
    setErrors(null);
  };

  async function submitInvoice(e: React.SyntheticEvent) {
    e.preventDefault();
    await req.post('', {
      invoice_number: invoiceNumber,
      invoice_amount: invoiceAmount,
      invoice_date: invoiceDate,
      due_date: isEmpty(dueDate) ? null : dueDate,
      purchase_date: isEmpty(purchaseDate) ? null : purchaseDate,
      vendor: selectedVendor,
      finance_company: !selectedFc ? null : selectedFc,
      purchase_id: purchaseRefId
    });
    if (res.ok) {
      setToUpdate(true);
      resetFormState();
      setSuccess('Successfully Created Accounts Payable Invoice!');
    } else if (res.status === 500) {
      setServerError(res.data.message);
    } else {
      setErrors(res.data.errors);
    }
  }

  const handleVendorChange = (event: any, child: any) => {
    setSelectedVendor(child.props.value);
    if (errors?.vendor) {
      const { vendor, ...updatedErrors } = errors;
      setErrors(updatedErrors);
    }
  };

  const handleFinanceCompanyChange = (event: any, child: any) => {
    setSelectedFc(child.props.value);
    if (errors?.finance_company) {
      const { finance_company, ...updatedErrors } = errors;
      setErrors(updatedErrors);
    }
  };

  const handleClose = () => {
    if (success.length > 0) {
      setSuccess('');
    } else if (serverError.length > 0) {
      setServerError('');
    }
  };

  if (isEmpty(vendors) || isEmpty(financeCompanies)) return <h2>Loading</h2>;
  return (
    <form onSubmit={submitInvoice} className={classes.container}>
      <GlueButton
        variant={open ? 'outlined' : 'contained'}
        onClick={() => setOpen(!open)}
        style={{ marginBottom: '1em' }}
        endIcon={open ? 'close' : 'add'}
      >
        {open ? 'Close Form' : 'Create a New Accounts Payable Invoice'}
      </GlueButton>
      {open ? (
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item xs={12}>
            <Typography component="h3" variant="h4">
              Create a New Accounts Payable Invoice
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlueSelect
              fullWidth
              required
              options={vendors.data.map((vendor: { company_name: string; id: number }) => ({
                key: uniqueId('vendor-'),
                value: vendor.id,
                name: vendor.company_name
              }))}
              handleChange={handleVendorChange}
              accessibleLabelId="vendors"
              label="Vendor"
              value={selectedVendor !== '' ? selectedVendor : ''}
              error={!!errors && !!errors.vendor}
              helperText={errors && errors.vendor ? errors.vendor : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GlueSelect
              fullWidth
              options={financeCompanies.data.map(
                (finance: { company_name: string; id: number }) => ({
                  key: uniqueId('finance-company-'),
                  value: finance.id,
                  name: finance.company_name
                })
              )}
              handleChange={handleFinanceCompanyChange}
              accessibleLabelId="finance-companies"
              label="Finance Company"
              value={selectedFc !== '' ? selectedFc : ''}
              error={!!errors && !!errors.finance_company}
              helperText={errors && errors.finance_company ? errors.finance_company : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={invoiceNumber}
              variant="outlined"
              fullWidth
              onChange={e => {
                setInvoiceNumber(e.target.value);
                if (errors?.invoice_number) {
                  const { invoice_number, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              label="Invoice Number"
              error={!!errors && !!errors.invoice_number}
              helperText={errors && errors.invoice_number ? errors.invoice_number : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              // eslint-disable-next-line no-restricted-globals
              value={!isNaN(invoiceAmount) ? invoiceAmount : ''}
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ step: 0.01 }}
              onChange={e => {
                setInvoiceAmount(parseFloat(e.target.value));
                if (errors?.invoice_amount) {
                  const { invoice_amount, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              label="Invoice Amount"
              error={!!errors && !!errors.invoice_amount}
              helperText={errors && errors.invoice_amount ? errors.invoice_amount : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={invoiceDate}
              variant="outlined"
              type="date"
              fullWidth
              onChange={e => {
                setInvoiceDate(e.target.value);
                if (errors?.invoice_date) {
                  const { invoice_date, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Invoice Date"
              error={!!errors && !!errors.invoice_date}
              helperText={errors && errors.invoice_date ? errors.invoice_date : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={dueDate}
              variant="outlined"
              type="date"
              fullWidth
              onChange={e => {
                setDueDate(e.target.value);
                if (errors?.due_date) {
                  const { due_date, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Due Date"
              error={!!errors && !!errors.due_date}
              helperText={errors && errors.due_date ? errors.due_date : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={purchaseDate}
              variant="outlined"
              type="date"
              fullWidth
              onChange={e => {
                setPurchaseDate(e.target.value);
                if (errors?.purchase_date) {
                  const { purchase_date, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Purchase Date"
              error={!!errors && !!errors.purchase_date}
              helperText={errors && errors.purchase_date ? errors.purchase_date : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={purchaseRefId !== null ? purchaseRefId : ''}
              variant="outlined"
              type="text"
              fullWidth
              onChange={e => {
                setPurchaseRefId(e.target.value);
                if (errors?.purchase_id) {
                  const { purchase_id, ...updatedErrors } = errors;
                  setErrors(updatedErrors);
                }
              }}
              label="Purchase Ref ID"
              error={!!errors && !!errors.purchase_id}
              helperText={errors && errors.purchase_id ? errors.purchase_id : ''}
            />
          </Grid>
          <Grid container item xs={12} sm={6} justify="flex-start">
            <Snackbar
              open={success.length > 0}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                variant="filled"
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccess('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {success}
              </Alert>
            </Snackbar>
            <Snackbar
              open={serverError.length > 0}
              autoHideDuration={7000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setServerError('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                A server error occured-please try resubmitting.
              </Alert>
            </Snackbar>
          </Grid>
          <Grid container item spacing={2} xs={12} sm={6} justify="flex-end">
            <Grid item>
              <GlueButton variant="outlined" endIcon="clear" onClick={resetFormState}>
                Clear Fields
              </GlueButton>
            </Grid>
            <Grid item>
              <GlueButton variant="contained" type="submit" endIcon="save" disabled={disabled}>
                Create Invoice
              </GlueButton>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </form>
  );
}
