import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import { PaperProps } from '@material-ui/core/Paper';
import { useDownloadWorker, useToggle } from '../../common/Hooks';
import { DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';

type Props = {
  name: string;
  url: string;
  hasOptions?: boolean;
  children?: React.ReactNode;
  submit?: () => void;
  dialogPaperProps?: PaperProps;
  disableSubmitButton?: boolean;
};

function DownloadReport({
  name,
  url,
  hasOptions = false,
  children,
  submit,
  dialogPaperProps = {},
  disableSubmitButton = false
}: Props) {
  const [timer, setTimer] = useState<number>(5);
  const [sendUrlToWorker] = useDownloadWorker({ reportName: name });
  const [open, toggleOpen] = useToggle(true);
  const [cancelled, toggleCancelled] = useToggle(false);
  const history = useHistory();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    function handleFetch() {
      if ((hasOptions && open) || cancelled) return;
      sendUrlToWorker(url);
      intervalId = setInterval(() => {
        setTimer(previousTime => {
          const time = previousTime - 1;
          if (time === 0) {
            clearInterval(intervalId);
            history.push('/');
          }
          return time;
        });
      }, 1000);
    }

    handleFetch();

    return () => clearInterval(intervalId);
  }, [history, url, hasOptions, open]);

  const handleSubmit = (): void => {
    if (submit) submit();
    toggleOpen(false);
  };

  const handleCancelled = (): void => {
    toggleCancelled(true);
    toggleOpen(false);
    history.push('/');
  };

  return (
    <>
      {open ? (
        <Typography>Setting {name} options</Typography>
      ) : (
        <Typography>
          Started to download {name} you will be{' '}
          <MuiLink component={Link} to="/">
            redirect home{' '}
          </MuiLink>
          in {timer}.
        </Typography>
      )}
      <Dialog
        open={open}
        onClose={() => {
          toggleOpen(false);
          handleCancelled();
        }}
        PaperProps={dialogPaperProps}
      >
        <DialogTitle>{name} Options</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCancelled}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disableSubmitButton}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DownloadReport;
