import React, { useState } from 'react';
import moment from 'moment';
import { useDownloadWorker } from '../../common/Hooks';
import GlueButton from '../Presentational/GlueButton.tsx';

export default function InactiveInventoryReport() {
  const currentDate = moment().format('YYYY-MM-DD');
  const [disabled, setDisabled] = useState(false);
  const fbaPendingOrdersDollarReportUrl = '/ims/reporting/fba-pending-orders-in-dollars';
  const fbaPendingOrdersDollarReportFileName = `fba-pending-orders-dollar-report-${currentDate}`;

  const [sendfbaPendingOrdersDollarReportUrlToWorker] = useDownloadWorker({
    reportName: fbaPendingOrdersDollarReportFileName
  });

  const handleSubmit = () => {
    setDisabled(true);
    sendfbaPendingOrdersDollarReportUrlToWorker(`${fbaPendingOrdersDollarReportUrl}`);
  };

  return (
    <GlueButton
      variant="contained"
      color="primary"
      onClick={handleSubmit}
      style={{ flex: 1, marginTop: '1em' }}
      disabled={disabled}
    >
      Download CSV
    </GlueButton>
  );
}
