import React, { useContext } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { ChangeEvent, ListType, LIST_TYPE_ENUM } from './PickListTypes';
import { PickListContext } from './PickListContext';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { GlueTheme } from '../../../GlueThemeType';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(2)
    },
  })
);

export default function FilterPickListByTypeOptions() {

  const classes = useStyles();

  const {
    queryParams,
    setQueryParams,
  } = useContext(PickListContext);

  const handleChange = (event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value as ListType;
    const newQueryParams = {
      ...queryParams,
      listType: value
    }
    setQueryParams(newQueryParams);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography
          className={classes.title}
          variant={'subtitle1'}
          id="filter-by-printed-title">
          Filter By
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-label="filter-by-printed"
        name="filter-by-printed"
        value={queryParams.listType}
        onChange={handleChange}>
        <FormControlLabel
          value={LIST_TYPE_ENUM.UNPRINTED}
          control={<Radio />}
          label="Unprinted Only" />
        <FormControlLabel
          value={LIST_TYPE_ENUM.PRINTED}
          control={<Radio />}
          label="Printed Only" />
      </RadioGroup>
    </FormControl>
  );
}