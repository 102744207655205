import { useState, useCallback, useEffect } from 'react';
// lodash/debounce doesn't currently support async requests with changing parameter fields
// https://github.com/lodash/lodash/issues/3864
import debounce from 'debounce-async';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { useHttp } from '../../../common/Hooks';
import { useSearchInventorySerials, useSearchProductInventory } from './InventorySearchHooks';

export const useManufacturerList = () => {
  const [manufacturerList, setManufacturerList] = useState([]);
  const [request, response] = useHttp('ims/brands');

  const fetchManufacturers = useCallback(async () => {
    const responseData = await request.get('');

    let list = [];
    if (response.ok) {
      list = responseData.map(item => {
        return { label: item.brand_name, value: item.brand_code };
      });
    }
    setManufacturerList(list);
  }, []);

  return [manufacturerList, fetchManufacturers];
};

export const useCategoryList = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [request, response] = useHttp('ims/categories');

  // TODO: `categoryTerm` isn't currently used. Find if this is intended.
  const fetchCategories = useCallback(async () => {
    const responseData = await request.get('');
    let list = [];
    if (response.ok) {
      list = responseData.map(item => {
        return { label: item.category_name, value: item.category_code };
      });
    }
    setCategoryList(list);
  }, []);

  return [categoryList, fetchCategories];
};

export const useFetchModelList = () => {
  const [request, response] = useHttp('ims/products/models');

  const memoizedFetchModelList = useCallback(async (searchTerm, includeInactive) => {
    if (searchTerm.length > 1) {
      const buildModelList = models =>
        Object.values(models).map(item => {
          return { label: item, value: item };
        });

      const params = new URLSearchParams();
      params.append('model', searchTerm);
      if (includeInactive) {
        params.append('includeInactive', 1);
      }

      const responseData = await request.get(`?${params.toString()}`);
      if (response.ok && responseData) {
        return buildModelList(responseData);
      }
    }
    return [];
  }, []);

  // debouncing works off of reference, the function needs to be
  // defined or it won't properly wait, so we're using some fancy features
  // to ensure that fetchModelList is properly memoized so that the debounce
  // can keep the same reference.
  const debouncedFetchModelList = useCallback(debounce(memoizedFetchModelList, 500), [
    memoizedFetchModelList
  ]);

  return debouncedFetchModelList;
};

// TODO: abstract this into a context provider
export const useSearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { searchProductInventory } = useSearchProductInventory();
  const { searchInventorySerials } = useSearchInventorySerials();
  const location = useLocation();

  const fetchSearchResults = useCallback(
    async (searchTerm, options = {}) => {
      const { searchType = 'model' } = options;
      let results = [];
      if (searchType === 'serial') {
        results = await searchInventorySerials(searchTerm, options);
      } else {
        results = await searchProductInventory(searchTerm, options);
      }
      setSearchResults(results);
    },
    [searchProductInventory, searchInventorySerials]
  );

  const clearState = get(location, 'state.clearState', false);

  useEffect(() => {
    if (clearState) {
      setSearchResults([]);
    }
  }, [clearState, location.pathname]);

  return [searchResults, fetchSearchResults, setSearchResults];
};
