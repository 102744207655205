import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { AuthContext } from '../../AuthContext';
import CommittedTransfers from './CommittedTransfers';
import updateCommittedTransfer from '../Inventory/Transfer/updateCommittedTransfer';
import deleteCommittedTransfer from '../Inventory/Transfer/deleteCommittedTransfer';
import { CommittedTransfersContext } from '../../App';
import { useHttp } from '../../common/Hooks';

const CommittedTransfersContainer = forwardRef((props, ref) => {
  const {
    imsTransactionReferenceIdOverride = null,
    onUpdate = () => {},
    onDelete = () => {},
    modelFilter = null
  } = props;

  const auth = useContext(AuthContext);
  const [request, response] = useHttp('ims/committed-transfers');
  const [committedTransfers, setCommittedTransfers] = useState([]);
  const [transacting, setTransacting] = useState(false);
  const [transactResults, setTransactResults] = useState(null);
  const committedTransferCount = useContext(CommittedTransfersContext);
  const [strictMode, setStrictMode] = useState(true);

  useEffect(() => {
    committedTransferCount.setCount(committedTransfers.length);
  }, [committedTransfers.length]);

  async function fetchCommittedTransfers() {
    const committedTransfersRequest = await request.get(
      imsTransactionReferenceIdOverride
        ? `?imsTransactionReferenceId=${imsTransactionReferenceIdOverride}`
        : modelFilter
        ? `/?model=${modelFilter}`
        : '/'
    );
    if (response.ok) {
      setCommittedTransfers(committedTransfersRequest.sort((a, b) => (a.id > b.id ? -1 : 1)));
    }
  }
  function transactTransfer(imsTransactionReferenceId, transferNotes) {
    return request.post(`/${imsTransactionReferenceId}/transact`, {
      transferredById: auth.username,
      transferNotes,
      strictMode
    });
  }
  useEffect(() => {
    if (auth) {
      fetchCommittedTransfers();
    }
  }, [auth, imsTransactionReferenceIdOverride]);

  async function updateTransfer(event) {
    event.preventDefault();
    const el = event.target.elements;
    const postVars = {
      ims_transaction_reference_id: el.ims_transaction_reference_id.value,
      commit_notes: el.commit_notes.value,
      pos_transfer_batch_ref_id: el.pos_transfer_batch_ref_id.value,
      pos_transfer_batch_name: el.pos_transfer_batch_name.value,
      sender_sku: el.sender_sku.value,
      receiver_sku: el.receiver_sku.value
    };
    const responseData = await updateCommittedTransfer(postVars);

    if (responseData.status === 404) {
      onUpdate({
        message: 'Failed',
        errors: 'Failed to update: commitment not found'
      });
      return;
    }
    const jsonData = await responseData.json();
    onUpdate(jsonData);
  }

  async function deleteCommitment(e, id) {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      const deleteResponse = await deleteCommittedTransfer(id);

      if (deleteResponse.status === 404) {
        onDelete({
          message: 'Failed',
          errors: 'Failed to delete: commitment not found'
        });
        return;
      }
      const jsonResponse = await deleteResponse.json();
      onDelete(jsonResponse);
      fetchCommittedTransfers();
    }
  }

  const handleCommitmentChange = (event, i) => {
    event.persist();
    const tempVar = [...committedTransfers];
    tempVar[i][event.target.name] = event.target.value;
    setCommittedTransfers(tempVar);
  };

  async function submitTransfer(imsTransactionReferenceId, transferNotes = null) {
    setTransacting(true);
    const transactTransferResponse = await transactTransfer(
      imsTransactionReferenceId,
      transferNotes
    );
    setTransacting(false);
    console.info(transactTransferResponse);
    if (transactTransferResponse.ok) {
      //
    } else {
      //
    }
    setTransactResults(transactTransferResponse);
    setStrictMode(true);
  }

  // We want to pass this function via ref to the parent, to allow the parent
  // to programmatically (i.e. imperatively) call this method as it needs.
  // Thus we use useImperativeHandle hook which builds a ref object that any parent
  // can then manipulate via useRef.
  // More details: https://stackoverflow.com/questions/37949981/call-child-method-from-parent
  useImperativeHandle(ref, () => {
    return {
      fetchCommittedTransfers: () => {
        fetchCommittedTransfers();
      },
      committedTransfers
    };
  });

  return (
    <CommittedTransfers
      committedTransfers={committedTransfers}
      updateTransfer={updateTransfer}
      handleCommitmentChange={handleCommitmentChange}
      deleteCommitment={deleteCommitment}
      fetchCommittedTransfers={fetchCommittedTransfers}
      submitTransfer={submitTransfer}
      transacting={transacting}
      transactResults={transactResults}
      resetTransactResults={() => setTransactResults(null)}
      strictMode={strictMode}
      setStrictMode={setStrictMode}
    />
  );
});

export default CommittedTransfersContainer;
