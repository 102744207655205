import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ShippingTemplateCreateContainer from './shippingTemplateCreateContainer';
import ShippingTemplateDisplayContainer from './Display';
import { ShippingTemplatesProvider } from './shippingTemplateContext';

// TODO: use nested routes.
// https://app.asana.com/0/0/1176087037633401/f
function ShippingTemplateContainer() {
  const match = useRouteMatch();

  return (
    <ShippingTemplatesProvider>
      {match.url.includes('create') ? (
        <ShippingTemplateCreateContainer />
      ) : (
        <ShippingTemplateDisplayContainer />
      )}
    </ShippingTemplatesProvider>
  );
}

export default ShippingTemplateContainer;
