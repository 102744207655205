import React, { useContext, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isEmpty from 'lodash/isEmpty';
import { transform } from '../../helpers/helpers';
import { useAttachedInvoices } from './hooks';
import { ApInvoicesContext } from '../../App';

export interface Purchase {
  id: number;
  purchase_ref_id: string;
}

interface Invoice {
  created_at: string;
  fk_finance_company_id: number | null;
  fk_purchase_id: number;
  fk_vendor_id: number | null;
  id: number;
  invoice_amount: number;
  invoice_number: number;
  updated_at: string;
  purchase: Purchase;
}

export default function AttachedInvoices({ purchaseOrder }: { purchaseOrder: number }) {
  const [attachedInvoices, setUpdateAttachedInvoices] = useAttachedInvoices(purchaseOrder);
  const { toUpdate } = useContext(ApInvoicesContext);

  useEffect(() => {
    if (toUpdate) {
      setUpdateAttachedInvoices(true);
    }
  }, [toUpdate, setUpdateAttachedInvoices]);

  if (isEmpty(attachedInvoices)) return <h2>Loading</h2>;
  if (isEmpty(attachedInvoices.data)) return <h2>No invoices yet</h2>;
  return (
    <Table>
      <TableHead>
        {Object.keys(attachedInvoices.data[0]).map(key => (
          <TableCell key={key}>{transform(key)}</TableCell>
        ))}
      </TableHead>
      <TableBody>
        {attachedInvoices.data
          .filter((i: Invoice) => i.purchase.id === purchaseOrder)
          .map((invoice: Invoice) => (
            <TableRow key={invoice.id}>
              {Object.entries(invoice).map(([key, value]) => (
                <TableCell key={key}>
                  {value?.company_name || value?.purchase_ref_id || value}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
