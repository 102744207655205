import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import { ShippingTemplateContext } from './shippingTemplateContext';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    padding: 15
  }
});

const useCompositionStyles = makeStyles({
  tableHeader: {
    '& td': {
      fontWeight: '700'
    }
  },
  tablePaper: {
    padding: 25
  }
});

export function CompositionRow({
  id,
  compositeShippingTemplate,
  simpleShippingTemplate,
  refreshList,
  numberOfTemplatesInComposition
}) {
  const classes = useStyles();
  const [modalChange, setModalChange] = useState(false);

  const [request, response] = useHttp('ims/shipping-templates/composition');

  function requestDelete() {
    setModalChange(true);
  }

  async function handleDelete() {
    await request.delete(`/${id}`);
    if (response.ok) {
      setModalChange(false);
      refreshList();
    }
  }

  return (
    <TableRow key={id}>
      <TableCell>{compositeShippingTemplate}</TableCell>
      <TableCell>{simpleShippingTemplate}</TableCell>
      <TableCell>{numberOfTemplatesInComposition}</TableCell>
      <TableCell>
        <Button variant="contained" onClick={requestDelete}>
          Delete
        </Button>
        <Dialog classes={classes} open={modalChange} onClose={() => setModalChange(false)}>
          Are you sure you want to delete?
          <Button onClick={() => handleDelete()}>Yes</Button>
          <Button onClick={() => setModalChange(false)}>No</Button>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

export default function CompositionsContainer() {
  const { compositions, getCompositions } = useContext(ShippingTemplateContext);

  useEffect(() => {
    if (compositions.length === 0) {
      getCompositions();
    }
  });

  const classes = useCompositionStyles();

  if (compositions.length === 0) return <LinearProgress />;

  return (
    <Paper className={classes.tablePaper}>
      <Table>
        <thead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Composite Shipping Template</TableCell>
            <TableCell>Simple Shipping Template</TableCell>
            <TableCell>Number of Templates in Composition</TableCell>
            <TableCell>Delete Composition</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {compositions.map(row => (
            <CompositionRow
              id={row.id}
              compositeShippingTemplate={row.composite_shipping_template}
              simpleShippingTemplate={row.simple_shipping_template}
              numberOfTemplatesInComposition={row.num_of_templates_in_composition}
              refreshList={getCompositions}
              key={row.id}
            />
          ))}
        </tbody>
      </Table>
    </Paper>
  );
}
