import {
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { capitalize, uniqueId } from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { locations } from '../../actions/locationActions';
import { useHttp } from '../../common/Hooks';
import GlueButton from '../Presentational/GlueButton.tsx';
import { styles } from './styles';

function getIterableLocations() {
  const env = process.env.REACT_APP_DEV_API;
  if (env === undefined || ['dev', 'test'].some(v => env.includes(v))) {
    return ['TEST'];
  }
  return locations.slice(1);
}

const iterableLocations = getIterableLocations();

const statusAntonym = status => {
  return status === 'Active' ? 'Inactive' : 'Active';
};

const sequentialArray = length => {
  return Array(length)
    .fill(1)
    .map((_, i) => i + 1);
};

const sequentialHoursArray = () => {
  return Array(24)
    .fill(0)
    .map((_, i) => {
      const newNumber = parseInt(i, 10);
      const stringedNumber = String(newNumber);
      return `${newNumber < 10 ? '0' : ''}${stringedNumber}:00`;
    });
};

export const SupplySourcesIndex = () => {
  const [request, response] = useHttp('ims/sp-api/supply-source/');
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [location, setLocation] = useState(iterableLocations[0]);
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [success, setSuccess] = useState({});

  const { scrollX, scrollY } = window;
  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const useStyles = makeStyles(() => createStyles(styles));
  const classes = useStyles();

  async function getDetails(selectedLocation) {
    const fetchedDetails = await request.get(`details/${selectedLocation}/`);
    if (response.ok) {
      setDetails({
        alias: fetchedDetails.alias,
        supplySourceId: fetchedDetails.supplySourceId,
        inventoryHoldPeriod:
          fetchedDetails.capabilities.outbound.pickupChannel.inventoryHoldPeriod.value,
        handlingTime:
          fetchedDetails.capabilities.outbound.pickupChannel.operationalConfiguration.handlingTime
            .value,
        operatingHoursByDay:
          fetchedDetails.capabilities.outbound.operationalConfiguration.operatingHoursByDay,
        status: fetchedDetails.status
      });
    } else {
      setErrors(fetchedDetails);
    }
    setLoading(false);
  }

  async function updateDetails() {
    const updateDetailsResponse = await request.post('details/', details);
    if (response.ok) {
      getDetails(location);
      setSuccess({ details: 'Details updated successfully!' });
      setTimeout(() => setSuccess({}), 6000);
    } else {
      setErrors(updateDetailsResponse);
      setLoading(false);
    }
  }

  async function updateSupplySourceStatus(status) {
    const updateSupplySourceStatusResponse = await request.post('status/', {
      status,
      supplySourceId: details.supplySourceId
    });
    if (response.ok) {
      getDetails(location);
      setSuccess({ status: 'Status set successfully!' });
      setTimeout(() => setSuccess({}), 6000);
    } else {
      setErrors(updateSupplySourceStatusResponse);
      setLoading(false);
    }
  }

  const handleLocationChange = event => {
    setLocation(event.target.value);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogYes = () => {
    handleDialogClose();
    setLoading(true);
    updateSupplySourceStatus(statusAntonym(details.status));
  };

  const handleSubmit = () => {
    setLoading(true);
    updateDetails();
  };

  const handleRefresh = () => {
    setLoading(true);
    getDetails(location);
  };

  const handleDetailChange = (value, name) => {
    const newState = { ...details };
    newState[name] = value;
    setDetails(newState);
  };

  const handleHoursChange = (day, position, value) => {
    const newState = { ...details };
    newState.operatingHoursByDay[day][0][position] = value;
    setDetails(newState);
  };

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (details.hasOwnProperty('alias') && details.alias === '') {
      setErrors({
        alias: 'The alias cannot be empty.'
      });
    } else {
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    setLoading(true);
    getDetails(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return loading ? (
    <LinearProgress className={classes.justMarginTop} />
  ) : Object.keys(details).length === 0 ? (
    <h1>Unable to fetch supply source details for location {location}.</h1>
  ) : (
    <div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to update the status of this supply source to{' '}
          {statusAntonym(details.status)}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you have any unsaved changes to this supply source&apos;s details, they will be
            discarded. If you need to save them, choose <em>No</em> and then click <em>Save</em>{' '}
            before toggling the status again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GlueButton onClick={handleDialogClose} variant="outlined">
            No
          </GlueButton>
          <GlueButton onClick={handleDialogYes} autoFocus>
            Yes
          </GlueButton>
        </DialogActions>
      </Dialog>
      <Paper className={classes.paper}>
        {Object.keys(errors).map(
          key =>
            key !== 'alias' && (
              <Alert severity="error" className={classes.justMarginBottom}>
                {errors[key]}
              </Alert>
            )
        )}
        <Snackbar
          open={Object.entries(success).length > 0}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            {success[Object.keys(success)[0]]}
          </Alert>
        </Snackbar>
        <div className={classes.justMarginBottom}>
          <FormControl>
            <InputLabel id="location-label" className={classes.infoIconLabel}>
              <span className={classes.infoIconLabelSpan}>Location</span>
              <Tooltip
                title={
                  <span className={classes.tooltipText}>
                    Use the e-commerce instance of WPOS3 to modify the supply sources corresponding
                    to e-commerce locations. Use the retail instance to modify the supply sources
                    corresponding to retail locations.
                  </span>
                }
              >
                <Icon>info</Icon>
              </Tooltip>
            </InputLabel>
            <Select
              labelId="location-label"
              id="location-select"
              value={location}
              label="Location"
              onChange={handleLocationChange}
              className={classes.locationSelect}
            >
              {iterableLocations.map(locationSelection => (
                <MenuItem
                  className={classes.locationSelect}
                  value={locationSelection}
                  key={uniqueId(locationSelection.toLowerCase())}
                >
                  {locationSelection}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={details.status === 'Active'} />}
            label={<strong>{details.status}</strong>}
            onChange={handleDialogOpen}
            className={classes.w100}
          />
          <TextField
            value={details.alias}
            label={errors.alias ? errors.alias : 'Alias'}
            onChange={e => handleDetailChange(e.target.value, 'alias')}
            inputProps={{
              maxLength: 45
            }}
            className={classes.aliasText}
            error={errors.alias}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="inventory-hold-label" className={classes.w200}>
              Inventory Hold Period
            </InputLabel>
            <Select
              labelId="inventory-hold-label"
              label="Inventory Hold Period"
              value={details.inventoryHoldPeriod}
              onChange={e => handleDetailChange(e.target.value, 'inventoryHoldPeriod')}
              endAdornment={
                <InputAdornment className={classes.selectAdornment} position="end">
                  days
                </InputAdornment>
              }
            >
              {sequentialArray(7).map(selectableNumber => (
                <MenuItem value={selectableNumber} key={`selectable-day-${selectableNumber}`}>
                  {selectableNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="handling-time-label" className={classes.infoIconLabel}>
              <span className={classes.infoIconLabelSpan}>Handling Time</span>
              <Tooltip
                title={
                  <span className={classes.tooltipText}>
                    This number includes 45 minutes to an hour of lag due to a persistent bug in
                    Amazon&apos;s system. Be sure to consider this when you set this number. For
                    example, if the handling time is set to 1 hour, this will give fulfillment
                    anywhere from 0-15 minutes to fulfill it.
                  </span>
                }
              >
                <Icon>error-outline</Icon>
              </Tooltip>
            </InputLabel>
            <Select
              labelId="handling-time-label"
              label="Handling Time"
              value={details.handlingTime}
              onChange={e => handleDetailChange(e.target.value, 'handlingTime')}
              endAdornment={
                <InputAdornment className={classes.selectAdornmentTrackingWider} position="end">
                  hours
                </InputAdornment>
              }
            >
              {sequentialArray(24).map(selectableNumber => (
                <MenuItem value={selectableNumber} key={`selectable-hour-${selectableNumber}`}>
                  {selectableNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>

        <h2 className={classes.pickupHoursHeading}>
          Pickup Hours
          <Tooltip
            title={
              <span className={classes.tooltipText}>
                Set both <em>Start Time</em> and <em>End Time</em> to <strong>00:00</strong> to
                indicate that the pickup location is closed.
              </span>
            }
          >
            <Icon className={classes.ml5px}>info</Icon>
          </Tooltip>
        </h2>
        {Object.entries(details.operatingHoursByDay).map(value => (
          <div key={uniqueId('form-control-wrapper')}>
            <h3 key={`${value[0]}-heading`}>{capitalize(value[0])}</h3>
            <FormControl
              className={classes.w100}
              id={`${value[0]}-start-select`}
              key={`${value[0]}-start-select`}
              error={
                errors[`operatingHoursByDay.${value[0]}.0`] ||
                errors[`operatingHoursByDay.${value[0]}`]
              }
            >
              <InputLabel
                id="start-time-label"
                className={classes.w200}
                key={`${value[0]}-start-select-label`}
              >
                Start Time
              </InputLabel>
              <Select
                labelId="start-time-label"
                label="Start Time"
                key={`${value[0]}-start-select-selector`}
                value={value[1][0].startTime}
                onChange={e => handleHoursChange(value[0], 'startTime', e.target.value)}
              >
                {sequentialHoursArray().map(selectableTime => (
                  <MenuItem
                    value={selectableTime}
                    key={uniqueId(`selectable-time-start-${selectableTime}-${value[0]}`)}
                  >
                    {selectableTime}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              className={classes.timeFormControl}
              id={`${value[0]}-end-select`}
              key={`${value[0]}-end-select`}
              error={
                errors[`operatingHoursByDay.${value[0]}.0`] ||
                errors[`operatingHoursByDay.${value[0]}`]
              }
            >
              <InputLabel
                id="end-time-label"
                className={classes.w200}
                key={`${value[0]}-end-select-label`}
              >
                End Time
              </InputLabel>
              <Select
                labelId="end-time-label"
                label="End Time"
                key={`${value[0]}-end-select-selector`}
                value={value[1][0].endTime}
                onChange={e => handleHoursChange(value[0], 'endTime', e.target.value)}
              >
                {sequentialHoursArray().map(selectableTime => (
                  <MenuItem
                    value={selectableTime}
                    key={uniqueId(`selectable-time-end-${selectableTime}-${value[0]}`)}
                  >
                    {selectableTime}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ))}
        <Container className={classes.submitButtonsContainer}>
          <GlueButton variant="outlined" endIcon="refresh" onClick={handleRefresh}>
            Reset
          </GlueButton>
          <GlueButton
            endIcon="done"
            onClick={handleSubmit}
            style={{ marginLeft: '1em' }}
            disabled={Object.keys(errors).some(
              val => Object.keys(details).indexOf(val) !== -1 || val.includes('operatingHoursByDay')
            )}
          >
            Submit
          </GlueButton>
        </Container>
      </Paper>
    </div>
  );
};
