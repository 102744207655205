import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { styled, makeStyles } from '@material-ui/styles';
import { Dialog } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import { HourglassEmpty } from '@material-ui/icons';
import { ShippingTemplatesProvider } from './shippingTemplateContext';
import CreateShippingTemplate from './createShippingTemplate';
import CreateShippingRule from './createShippingRule';
import CreateRuleMap from './createRuleMap';
import CreateComposition from './CreateComposition';
import ShippingTemplateRulesContainer from '../ShippingTemplateRules';
import ShippingTemplateDisplayContainer from './Display/ShippingTemplateDisplayContainer';
import ShippingTemplateRuleMappingsContainer from '../ShippingTemplateRuleMappings';
import CompositionsContainer from './CompositionsContainer';

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white
}));

const useShippingTabPanelStyles = makeStyles(theme => ({
  box: {
    padding: theme.spacing(3)
  },
  contentWrapper: {
    minWidth: '40vh',
    maxWidth: '100%',
    minHeight: '50vh',
    maxHeight: '100%'
  }
}));

function ShippingTabPanel({ children, value, index, label }) {
  const classes = useShippingTabPanelStyles();

  const hidden = value !== index;

  return (
    <Box hidden={hidden} className={classes.box} role="tabpanel" id={`wrapped-tabpanel-${value}`}>
      <Typography variant="h6">{label}</Typography>
      <Box className={classes.contentWrapper}>{children}</Box>
    </Box>
  );
}

const SHIPPING_TEMPLATE_MAPPING = {
  'New Shipping Template': CreateShippingTemplate,
  'New Rule': CreateShippingRule,
  'New (Legacy) Rule Map': CreateRuleMap,
  'New Composition': CreateComposition
};

const useShippingTemplateContainerStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'center'
  },
  createBar: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1)
  },
  createButton: {
    color: theme.palette.common.white
  }
}));

const initialSubmissionState = {
  isSubmitting: false,
  isSubmitted: false
};

function ShippingTemplateCreateContainer() {
  const [currentTemplateIndex, setTemplateIndex] = useState(0);
  const [submitFunction, setSubmitFunction] = useState(() => () => {});
  const [disableButton, setDisableButton] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submissionState, setSubmissionState] = useState(initialSubmissionState);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setSubmissionState(initialSubmissionState);
    }, 700);
  };

  const classes = useShippingTemplateContainerStyles();

  const handleTabChange = (event, newTemplateIndex) => {
    setTemplateIndex(newTemplateIndex);
  };

  return (
    <ShippingTemplatesProvider>
      <Paper>
        <AppBar position="static">
          <Tabs value={currentTemplateIndex} onChange={handleTabChange} variant="scrollable">
            {Object.keys(SHIPPING_TEMPLATE_MAPPING).map(label => (
              <StyledTab key={label} label={label.slice(4).concat('s')} />
            ))}
          </Tabs>
          <div className={classes.createBar} color="secondary">
            <Button onClick={handleDialogOpen} className={classes.createButton} startIcon={<Add />}>
              Create {Object.keys(SHIPPING_TEMPLATE_MAPPING)[currentTemplateIndex]}
            </Button>
          </div>
        </AppBar>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          {Object.entries(SHIPPING_TEMPLATE_MAPPING).map(([label, Component], index) => (
            <ShippingTabPanel key={label} label={label} index={currentTemplateIndex} value={index}>
              <Component
                setDisableButton={setDisableButton}
                isSelected={currentTemplateIndex === index}
                setSubmit={setSubmitFunction}
                setSubmissionState={setSubmissionState}
              />
            </ShippingTabPanel>
          ))}
          <Box className={classes.flexEnd}>
            <Button
              disabled={disableButton}
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={submitFunction}
              startIcon={
                submissionState.isSubmitted ? (
                  <DoneIcon />
                ) : submissionState.isSubmitting ? (
                  <HourglassEmpty />
                ) : null
              }
            >
              {submissionState.isSubmitted
                ? 'Submitted!'
                : submissionState.isSubmitting
                ? 'Submitting...'
                : 'Create '.concat(Object.keys(SHIPPING_TEMPLATE_MAPPING)[currentTemplateIndex])}
            </Button>
          </Box>
        </Dialog>
        {currentTemplateIndex === 0 ? <ShippingTemplateDisplayContainer /> : null}
        {currentTemplateIndex === 1 ? <ShippingTemplateRulesContainer /> : null}
        {currentTemplateIndex === 2 ? <ShippingTemplateRuleMappingsContainer /> : null}
        {currentTemplateIndex === 3 ? <CompositionsContainer /> : null}
      </Paper>
    </ShippingTemplatesProvider>
  );
}

export default ShippingTemplateCreateContainer;
