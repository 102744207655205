import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import FormControl from '@material-ui/core/FormControl';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import Close from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import { useHttp } from '../../../common/Hooks';

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  Checkbox: {
    display: 'inline-block',
    flexFlow: 'column wrap'
  },
  AdvancedForm: {
    display: 'flex',
    flexFlow: 'column wrap'
  },
  expansionPanel: {
    paddingBottom: '15px'
  }
}));

const allBucketFields = [
  { name: 'Location Code', type: 'text', key: 'location_code' },
  { name: 'Distributor', type: 'text', key: 'distributor' },
  { name: 'Location Type', type: 'text', key: 'location_type' },
  { name: 'Distributor Location ID', type: 'text', key: 'distributor_location_id' },
  { name: 'Address 1', type: 'text', key: 'address_1' },
  { name: 'Address 2', type: 'text', key: 'address_2' },
  { name: 'City', type: 'text', key: 'city' },
  { name: 'County', type: 'text', key: 'county' },
  { name: 'State', type: 'text', key: 'state_or_province' },
  { name: 'Country', type: 'text', key: 'country' },
  { name: 'ZIP', type: 'text', key: 'postal_code' },
  { name: 'Notes', type: 'text', key: 'notes' },
  { name: 'Managed by Third Party', type: 'checkbox', key: 'managed_by_third_party' },
  {
    name: 'Is Fulfillable From This Location',
    type: 'checkbox',
    key: 'is_fulfillable_from_this_location'
  },
  { name: 'Can Receive Purchase Orders', type: 'checkbox', key: 'can_receive_purchase_orders' },
  { name: 'Can Hold Inventory', type: 'checkbox', key: 'can_hold_inventory' },
  { name: 'Is Active', type: 'checkbox', key: 'is_active' }
];

const basicBucketFields = [
  { name: 'Distributor', type: 'text', key: 'distributor' },
  { name: 'Location Type', type: 'text', key: 'location_type' },
  { name: 'Distributor Location ID', type: 'text', key: 'distributor_location_id' },
  { name: 'Address 1', type: 'text', key: 'address_1' },
  { name: 'Address 2', type: 'text', key: 'address_2' },
  { name: 'City', type: 'text', key: 'city' },
  { name: 'County', type: 'text', key: 'county' },
  { name: 'State', type: 'text', key: 'state_or_province' },
  { name: 'Country', type: 'text', key: 'country' },
  { name: 'ZIP', type: 'text', key: 'postal_code' },
  { name: 'Notes', type: 'text', key: 'notes' }
];

const advancedBucketFields = [
  { name: 'Managed by Third Party', type: 'checkbox', key: 'managed_by_third_party' },
  {
    name: 'Is Fulfillable From This Location',
    type: 'checkbox',
    key: 'is_fulfillable_from_this_location'
  },
  { name: 'Can Receive Purchase Orders', type: 'checkbox', key: 'can_receive_purchase_orders' },
  { name: 'Can Hold Inventory', type: 'checkbox', key: 'can_hold_inventory' },
  { name: 'Is Active', type: 'checkbox', key: 'is_active' }
];

function PopulateBasicFields({ field, value, handleChange }) {
  const classes = useStyles();
  return (
    <TextField
      classes={classes.textField}
      value={value}
      name={camelCase(field.name)}
      id={camelCase(field.name)}
      label={field.name}
      type={field.type}
      margin="normal"
      onChange={handleChange}
    />
  );
}

function PopulateAdvancedFields({ field, value, handleCheckChange }) {
  const classes = useStyles();
  return (
    <FormControl classes={classes.AdvancedForm}>
      <label>
        <Checkbox
          classes={classes.Checkbox}
          id={field.name}
          checked={value === 1}
          name={camelCase(field.name)}
          onChange={handleCheckChange}
          color="primary"
        />
        {field.name}
      </label>
    </FormControl>
  );
}

function UpdateForm(props) {
  const { bucketChoice, currentChoiceFields, handleClose } = props;
  const [state, setState] = useState({});
  const [isFulfillableWarning, setIsFulfillableWarning] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [request, response] = useHttp('ims/buckets');
  const classes = useStyles();

  useEffect(() => {
    if (isEmpty(state) && !isEmpty(currentChoiceFields)) {
      setState(
        allBucketFields.reduce((curstate, field) => {
          curstate[camelCase(field.name)] = currentChoiceFields[field.key];
          return curstate;
        }, {})
      );
    }
  }, [currentChoiceFields, state]);

  const handleChange = useCallback(function handleChange(event) {
    const { name, value } = event.currentTarget;
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  async function handleBucketUpdate() {
    if (bucketChoice) {
      await request.put(`/updateBucketByLocation`, state);
      if (response.ok) {
        setSuccessState(true);
        setTimeout(() => {
          setSuccessState(false);
          handleClose();
        }, 4000);
      }
    }
  }

  const handleCheckChange = useCallback(function handleCheckChange(event) {
    const { name, checked } = event.currentTarget;
    if (name === 'isFulfillableFromThisLocation') {
      setIsFulfillableWarning(true);
    }
    setState(prev => ({ ...prev, [name]: checked ? 1 : 0 }));
  }, []);

  return (
    <Card>
      <CardContent>
        <h2>Updating: {bucketChoice}</h2>
        <form>
          {basicBucketFields.map(item => (
            <PopulateBasicFields
              field={item}
              value={state[camelCase(item.name)]}
              handleChange={handleChange}
            />
          ))}
          <ExpansionPanel classes={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Advanced Fields
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {advancedBucketFields.map(item => (
                <PopulateAdvancedFields
                  field={item}
                  value={state[camelCase(item.name)]}
                  handleCheckChange={handleCheckChange}
                />
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {isFulfillableWarning ? (
            <Alert severity="error">
              WARNING: Changes to &quot;Is Fulfillable From This Location&quot; determine whether or
              not inventory can be sourced via this bucket
            </Alert>
          ) : null}

          {successState ? <Alert>Bucket Successfully Updated</Alert> : null}
        </form>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleBucketUpdate}>
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            setSuccessState(false);
            setIsFulfillableWarning(false);
          }}
        >
          <Close />
          Reset
        </Button>
      </CardActions>
    </Card>
  );
}

export default UpdateForm;
