import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useHttp } from '../../../common/Hooks';
import Select from '../../../common/LayoutComponents/Select';
import UpdateForm from './BucketUpdateForm';

export default function BucketUpdater() {
  const [request, response] = useHttp('ims/');
  const [entireDataSet, setEntireDataSet] = useState([]);
  const [bucketOptions, setBucketOptions] = useState([]);
  const [bucketChoice, setBucketChoice] = useState('');
  const [currentChoiceFields, setCurrentChoiceFields] = useState({});

  async function getBucketNames() {
    const bucketList = await request.get('buckets/withActiveDistributor');
    if (response.ok) {
      setEntireDataSet(bucketList.data);
      setBucketOptions(bucketList.data.map(current => current.location_code));
    }
    return bucketList;
  }

  useEffect(() => {
    getBucketNames();
  }, []);

  const memoizedHandleChange = useCallback(
    // now this wont get re-created each time the component rerenders
    function handleChange(e) {
      setBucketChoice(e.target.value);
      setCurrentChoiceFields(
        entireDataSet.find(current => current.location_code === e.target.value)
      );
    },
    [entireDataSet]
  );

  return (
    <div>
      <h2>Select a Bucket</h2>
      <Select
        label="Bucket"
        name="bucket"
        inputValue={bucketChoice}
        options={bucketOptions}
        handleChange={memoizedHandleChange}
        style={{ marginBottom: '25px' }}
      />

      {!isEmpty(currentChoiceFields) ? (
        <UpdateForm
          bucketChoice={bucketChoice}
          currentChoiceFields={currentChoiceFields}
          handleClose={() => {
            setBucketChoice('');
            setCurrentChoiceFields({});
            getBucketNames();
          }}
        />
      ) : (
        <Typography variant="h6">Choose a valid bucket to edit</Typography>
      )}
    </div>
  );
}
