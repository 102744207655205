import React, { useState, ChangeEvent } from 'react';
import { useHttp } from '../../../common/Hooks';
import { TextField, MenuItem, Select, FormControl, InputLabel, Button } from '@material-ui/core';
import { saveAs } from 'file-saver';

export default function BundleSalesReport() {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sku, setSku] = useState('');
  const [reportType, setReportType] = useState('');
  const [request, response] = useHttp('report/bundle-sales');
  async function getReport() {
    await request.get(`?fromDate=${fromDate}&toDate=${toDate}&sku=${sku}&reportType=${reportType}`);
    if (response.ok) {
      const data = await response.blob();
      const now = new Date();
      saveAs(data, `${fromDate}--${toDate}__${reportType}__${now}.csv`);
    }
  }
  return (
    <div>
      <p>
        <em>Please note: this is a major work in progress</em>
      </p>
      <div style={{ display: 'flex' }}>
        <TextField
          style={{ flex: 1 }}
          type="date"
          value={fromDate}
          label="Start Date"
          onChange={e => setFromDate(e.target.value)}
        />
        <TextField
          style={{ flex: 1 }}
          type="date"
          value={toDate}
          onChange={e => setToDate(e.target.value)}
          label="End Date"
        />
        <TextField
          style={{ flex: 1 }}
          type="text"
          value={sku}
          onChange={e => setSku(e.target.value)}
          label="Model"
        />
        <FormControl style={{ flex: 1 }}>
          <InputLabel>Report Type</InputLabel>
          <Select onChange={e => setReportType(e.target.value)} value={reportType}>
            <MenuItem value="getAllBundleSales">Get All Bundle Sales</MenuItem>
            <MenuItem value="getBundleSalesByIncludedModel">Get Bundle Sales by Model</MenuItem>
            <MenuItem value="getBundleSalesByBundleSku">Get Bundle Sales by Bundle SKU</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Button variant="contained" style={{ marginTop: 16 }} fullWidth onClick={getReport}>
        Get report
      </Button>
    </div>
  );
}
