import { Emitter } from 'mitt';
import ScanEvent from './ScanEvent';
import { ScanEventTypes, ScanListenerInterface } from './ScanEventListeningTypes';

export const SCAN_EVENT_NAME = 'scan';

/**
 * Implementation adapted from:
 * https://stackoverflow.com/questions/11290898/detect-when-input-box-filled-by-keyboard-and-when-by-barcode-scanner/56540835#56540835
 */
export default class ScanListener implements ScanListenerInterface {
  emitter: Emitter<ScanEventTypes>;

  listening = false;

  scannedValue = '';

  timeoutRef = 0;

  scannerTerminatingCharacter = 'Enter';

  constructor(emitter: Emitter<ScanEventTypes>) {
    this.emitter = emitter;
  }

  start = (): void => {
    this.listening = true;
    document.addEventListener('keypress', this.handleKeyPress);
    this.refreshTimeout();
    this.timeoutRef = window.setTimeout(() => {
      this.scannedValue = '';
    }, 50);
  };

  stop = () => {
    document.removeEventListener('keypress', this.handleKeyPress);
    this.refreshTimeout();
    this.listening = false;
  };

  refreshTimeout = () => {
    if (this.timeoutRef) {
      window.clearTimeout(this.timeoutRef);
    }
  };

  handleKeyPress = (event: KeyboardEvent): void => {
    if (this.timeoutRef) {
      this.refreshTimeout();
      if (event.key !== this.scannerTerminatingCharacter) {
        this.scannedValue += event.key.toUpperCase();
      }
    }

    this.timeoutRef = window.setTimeout(() => {
      if (this.scannedValue.length <= 3) {
        this.scannedValue = '';
        return;
      }
      const scanEvent = new ScanEvent(this.scannedValue);
      scanEvent.lastKeypressEvent = event;

      this.emitter.emit(SCAN_EVENT_NAME, scanEvent);
      this.scannedValue = '';
    }, 50);
  };
}
