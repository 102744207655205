import React, { useEffect } from 'react';
import {
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel
} from '@material-ui/core';
import Can from '../../../roles/Can';

export default function TransactTransferDialog(props) {
  const {
    submitTransfer,
    committedTransfer,
    submitting = false,
    transactResults = null,
    resetTransactResults,
    onClose,
    strictMode,
    setStrictMode,
    ...restOfProps
  } = props;

  useEffect(() => {
    resetTransactResults();
  }, [committedTransfer]);

  return (
    <Dialog {...restOfProps} onClose={onClose}>
      {committedTransfer != null && [
        <DialogTitle key={`dialog-title-${committedTransfer.ims_transaction_reference_id}`}>
          {transactResults != null ? transactResults.message || 'Results' : 'Confirm Transfer'}
        </DialogTitle>,
        submitting == true ? (
          <DialogContent key={`dialog-content-${committedTransfer.ims_transaction_reference_id}`}>
            <DialogContentText>Submitting...</DialogContentText>
          </DialogContent>
        ) : transactResults != null ? (
          <DialogContent key={`dialog-content-${committedTransfer.ims_transaction_reference_id}`}>
            <DialogContentText>
              {transactResults.message.includes('Success')
                ? `${committedTransfer.total_scanned_quantity}/${committedTransfer.committed_quantity} Scanned and Successfully Transferred`
                : transactResults.errors || JSON.stringify(transactResults)}
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent key={`dialog-content-${committedTransfer.ims_transaction_reference_id}`}>
            <DialogContentText>
              Are you sure you would like to submit this transfer? Note that this action cannot be
              undone.
            </DialogContentText>
            <TransferSummaryTable transfer={committedTransfer} />
          </DialogContent>
        ),
        <DialogActions key={`dialog-actions-${committedTransfer.ims_transaction_reference_id}`}>
          <Button variant="contained" onClick={onClose}>
            Exit
          </Button>
          {submitting == false && transactResults == null && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                submitTransfer(
                  committedTransfer.ims_transaction_reference_id,
                  'Test Transfer Notes'
                );
              }}
            >
              Submit Transfer
            </Button>
          )}
          <Can
            perform="admin:override"
            yes={() => (
              <FormControlLabel
                control={
                  <Switch
                    checked={strictMode}
                    color="primary"
                    onChange={event => setStrictMode(event.target.checked)}
                    value="strictMode"
                    inputProps={{ 'aria-label': 'set strict mode checkbox' }}
                  />
                }
                labelPlacement="start"
                label={`Strict Mode ${strictMode ? 'On' : 'Off'}`}
              />
            )}
          />
        </DialogActions>
      ]}
    </Dialog>
  );
}

function TransferSummaryTable({ transfer }) {
  return (
    <table border="0">
      <tbody>
        <tr>
          <th style={{ textAlign: 'left' }}>Ref ID:</th>
          <td>{transfer.pos_transfer_batch_ref_id}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Ref Name:</th>
          <td>{transfer.pos_transfer_batch_name}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Model:</th>
          <td>{transfer.product.model}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>SKU:</th>
          <td>{transfer.sender_sku || transfer.receiver_sku}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Transfer Quantity:</th>
          <td>{transfer.committed_quantity}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Scanning Requred?:</th>
          <td>{transfer.scanning_required == 1 ? 'true' : 'false'}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>Scanned Quantity:</th>
          <td>{transfer.total_scanned_quantity}</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>From:</th>
          <td>
            {transfer.from_bucket != null
              ? transfer.from_bucket
              : `${transfer.from_location_code} / ${transfer.from_condition_code}`}
          </td>
        </tr>
        <tr>
          <th style={{ textAlign: 'left' }}>To:</th>
          <td>
            {transfer.to_bucket != null
              ? transfer.to_bucket
              : `${transfer.to_location_code} / ${transfer.to_condition_code}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
