import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';

const useTransferFormStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'spaceBetween',
    flex: 1
  },
  label: {}
}));

function ScanningMsg({ scanRequired }) {
  return (
    <Typography component="div" className="addFormFields scanRequiredMsg">
      {scanRequired ? (
        <>
          When checked, the inventory will be committed, after which you are required to scan
          serials to complete the transfer.
        </>
      ) : (
        <>
          When unchecked, a direct transfer will occur and inventory will be transferred instantly.
        </>
      )}
    </Typography>
  );
}

const AdditionalFields = ({ additionalFields, handleInputChange }) => {
  if (additionalFields === true) {
    return (
      <div key="key" className="addFormFields">
        <div className="transfer-form-field">
          <label className="field-msg">(SKU and one of Shipment Id or Name are required)</label>
          <label className="field-label">Shipment Id</label>
          <input type="text" name="posTransferBatchRefId" onChange={handleInputChange} />
        </div>
        <div className="transfer-form-field">
          <label className="field-label">Shipment Name</label>
          <input type="text" name="posTransferBatchName" onChange={handleInputChange} />
        </div>
        <div className="transfer-form-field">
          <label className="field-label">SKU</label>
          <input type="text" name="senderSku" onChange={handleInputChange} />
        </div>
      </div>
    );
  }

  return (
    <div key="key" className="addFormFields">
      <div className="transfer-form-field">
        <label className="field-msg">(Optional Fields)</label>
        <label className="field-label">Reference Id</label>
        <input type="text" name="posTransferBatchRefId" />
      </div>
      <div className="transfer-form-field">
        <label className="field-label">Reference Name</label>
        <input type="text" name="posTransferBatchName" />
      </div>
    </div>
  );
};

function TransferForm({
  item,
  handleSubmit,
  toggleScanningRequired,
  handleInputChange,
  handleSelectChange,
  transferTypes,
  index: i,
  admin = false
}) {
  const displayNegativeBucketNotice = get(item, 'quantity', 0) < 0;
  const shouldHideEmptyBuckets = get(item, 'quantity', 0) === 0;
  const classes = useTransferFormStyles();

  const transferIsFromOwnWarehouseToInbound =
    (item.bucket.includes('KY01') ||
      item.bucket.includes('TX01') ||
      item.bucket.includes('AZ02')) &&
    item.additional_fields;

  const scanRequired = item.scan_required || (!admin && transferIsFromOwnWarehouseToInbound);

  if (shouldHideEmptyBuckets && !admin) {
    return null;
  }

  return (
    <Paper
      id={item.bucket}
      onSubmit={handleSubmit}
      component="form"
      classes={{ root: classes.paper }}
    >
      {displayNegativeBucketNotice && !admin ? (
        <>
          {item.bucket} : <strong>{item.quantity} Quantity </strong>
        </>
      ) : (
        <>
          <div className="transfer-form-field">
            <label className="field-label">Location</label>
            {admin ? (
              <select name="fromBucket" onChange={e => handleSelectChange(e, i)}>
                <option>Not Selected</option>
                {transferTypes.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </select>
            ) : (
              <>
                {item.bucket}
                <input type="hidden" name="fromBucket" value={item.bucket} />
              </>
            )}
          </div>

          {admin ? null : (
            <div className="transfer-form-field center">
              <label className="field-label">Quantity</label>
              {item.quantity}
            </div>
          )}
          <div className="transfer-form-field">
            <label className="field-label">Transfer To</label>
            <select name="toBucket" onChange={e => handleSelectChange(e, i)}>
              <option>Not Selected</option>
              {transferTypes
                .filter(type => type !== item.bucket)
                .map(type => (
                  <option key={type}>{type}</option>
                ))}
            </select>
            <AdditionalFields
              additionalFields={item.additional_fields}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="transfer-form-field center">
            <label className="field-label">Transfer Qty</label>
            {admin ? (
              <input min="1" type="number" name="requestedQuantity" />
            ) : (
              <select name="requestedQuantity" onChange={e => handleSelectChange(e, i)}>
                <option>Not Selected</option>
                {Array.from({ length: item.quantity }, (_, i) => (
                  <option key={i}>{i + 1}</option>
                ))}
              </select>
            )}
          </div>
          <div className="transfer-form-field">
            <label className="field-label-required">Scan Required</label>
            <input
              type="checkbox"
              name="scanningRequired"
              checked={scanRequired}
              disabled={transferIsFromOwnWarehouseToInbound || admin}
              onChange={e => toggleScanningRequired(e, i)}
            />
            <ScanningMsg scanRequired={scanRequired} />
          </div>
          <div className="transfer-form-field">
            <label className="field-label">Comments</label>
            <input type="text" size="30" name="commitNotes" />
            <button
              type="submit"
              name="submitBtn"
              className={scanRequired ? 'commit-transfer-btn' : 'submit-transfer-btn'}
            >
              {scanRequired ? 'Commit Transfer' : 'Submit Transfer'}
            </button>
          </div>
        </>
      )}
    </Paper>
  );
}

export default TransferForm;
