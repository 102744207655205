import React from 'react';
import GenericShippingReconciliationUploader from './GenericShippingReconciliationUploader';

function UpsShippingReconciliationUploader() {
  return (
    <GenericShippingReconciliationUploader carrier="ups" title="UPS Shipping Reconciliation" />
  );
}

export default UpsShippingReconciliationUploader;
