import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pickItemInfoLabel: {
    fontWeight: 'bold',
    fontSize: '16px'
  },
  pickItemInfoValue: {
    fontSize: '16px'
  }
}));

/**
 * @param {Object} props
 * @param {string} props.label
 * @param {React.ReactNode} props.children
 */
export function PickItemGridBox({ label, children }) {
  return (
    <PickItemGridBoxContainer>
      <PickItemGridBoxLabel>{label}</PickItemGridBoxLabel>
      <PickItemGridBoxValue>{children}</PickItemGridBoxValue>
    </PickItemGridBoxContainer>
  );
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function PickItemGridBoxContainer({ children }) {
  return (
    <Grid item container xl={10} xs={12}>
      {children}
    </Grid>
  );
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function PickItemGridBoxLabel({ children }) {
  const classes = useStyles();
  return (
    <Grid item sm={2} xs={4}>
      <Box className={classes.pickItemInfoLabel}>{children}</Box>
    </Grid>
  );
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function PickItemGridBoxValue({ children }) {
  const classes = useStyles();
  return (
    <Grid item lg={4} md={6} xs={8}>
      <Box className={classes.pickItemInfoValue}>{children}</Box>
    </Grid>
  );
}
