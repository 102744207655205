import React, { MouseEventHandler, useContext, useEffect } from 'react';
import { createStyles, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { BinManagementContext } from '../BinManagementContext';
import { GlueTheme } from '../../../GlueThemeType';
import BinLocationSelect from './BinLocationSelect';
import BinConditionSelect from './BinConditionSelect';
import BinRowBayShelfInput from './BinRowBayShelfInput';
import BinRowBayShelfHelperText from './BinRowBayShelfHelperText';
import BinNameReadOnlyInput from './BinNameReadOnlyInput';
import CreateBinButtons from './CreateBinButtons';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    label: {
      fontWeight: 500,
      color: theme.palette.grey[900],
      letterSpacing: '0px'
    },
    title: {
      marginBottom: theme.spacing(5)
    },
    closeButton: {
      margin: theme.spacing(-6, -1, 0, 0)
    }
  })
);

export type CreateBinFormProps = {
  onCancel?: MouseEventHandler<HTMLButtonElement>;
};

export default function CreateBinForm({ onCancel }: CreateBinFormProps) {
  const classes = useStyles();

  const {
    createBin,
    createBinSuccess,
    createBinData,
  } = useContext(BinManagementContext);

  const handleCreateSubmition = async (e: any) => {
    e.preventDefault();
    if (createBinSuccess) {
      return;
    }
    const newBinData = {
      row: createBinData.row,
      bay: createBinData.bay,
      shelf: createBinData.shelf,
      locationCode: createBinData.locationCode,
      conditionCode: createBinData.conditionCode,
      binName: createBinData.binName
    };
    createBin(newBinData);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (typeof onCancel === 'function') {
      onCancel(e);
    }
  };

  useEffect(() => {
    if (createBinSuccess) {
      const closeModalTimeout = setTimeout(handleCancel, 1000);
      return () => clearTimeout(closeModalTimeout);
    }
  }, [createBinSuccess]);

  return (
    <Grid container spacing={1} justify={'space-around'}>
      <Grid container spacing={0} xs={12} justify={'flex-end'}>
        <Grid item xs={1}>
          <IconButton
            className={classes.closeButton}
            onClick={handleCancel}
            aria-label="close-create-bin-modal"
          >
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={0} xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h4">
            Create New Bin
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} spacing={0}>
        <form onSubmit={handleCreateSubmition}>
          <BinRowBayShelfInput />
          <BinRowBayShelfHelperText />
          <BinNameReadOnlyInput />
          <BinLocationSelect />
          <BinConditionSelect />
          <CreateBinButtons onCancel={handleCancel} />
        </form>
      </Grid>
    </Grid>
  );
}
