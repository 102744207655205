import { apiOspos } from '../config/config';

export function contactSearch(searchValue, searchKey, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchKey,
        searchValue,
        searchSize: 'light'
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'CONTACT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'CONTACT_SEARCH_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function searchContactsByName(firstName, lastName, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/name`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName,
        lastName
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.data.error) {
          dispatch({ type: 'CONTACT_SEARCH_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'CONTACT_SEARCH_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function contactGet(authorizationToken, searchValue) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/${searchValue}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'CONTACT_GET_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'CONTACT_GET_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}

export function contactDetails(contact) {
  return dispatch => {
    return dispatch({ type: 'CONTACT_DETAILS', payload: contact });
  };
}

export function contactCreate(authorizationToken, contactToCreate, cb = () => {}) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/store`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contactToCreate)
      //    JSON.stringify({
      // first_name: contactToCreate.first_name,
      // last_name: contactToCreate.last_name,
      // //middle_initial: contactToCreate.middle_initial,
      // personal_email: contactToCreate.personal_email,
      // work_email: contactToCreate.work_email,
      // third_party_email: contactToCreate.third_party_email,
      // home_phone: contactToCreate.home_phone,
      // work_phone: contactToCreate.work_phone,
      // mobile_phone: contactToCreate.mobile_phone,
      // address: contactToCreate.address,
      // address2: contactToCreate.address2,
      // city: contactToCreate.city,
      // state: contactToCreate.state,
      // zip: contactToCreate.zip,
      // source: contactToCreate.source,
      // account_id: 1
      //    })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'CONTACT_CREATE_REJECTED', payload: responseData });
          cb({
            errors: responseData.data,
            message: 'Contact could not be created'
          });
        } else {
          dispatch({
            type: 'CONTACT_CREATE_FULFILLED',
            payload: responseData.data
          });
          cb(null, responseData.data);
        }
      });
  };
}

export function contactUpdate(authorizationToken, contactToUpdate, cb = () => {}) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contactToUpdate)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'CONTACT_UPDATE_REJECTED', payload: responseData });
          cb({
            error: 'badStuff'
          });
        } else {
          dispatch({
            type: 'CONTACT_UPDATE_FULFILLED',
            payload: responseData.data
          });
          cb(null, responseData.data);
        }
      });
  };
}

export function contactHistory(searchValue, searchKey, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/contact/contactHistory`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchKey,
        searchValue,
        searchSize: 'light'
      })
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'CONTACT_HISTORY_REJECTED', payload: responseData });
        } else {
          dispatch({
            type: 'CONTACT_HISTORY_FULFILLED',
            payload: responseData
          });
        }
      });
  };
}
