import { auth, authEmail, authPass } from './firebase';

export const signIn = () =>
  auth.signInWithEmailAndPassword(authEmail, authPass).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // [START_EXCLUDE]
    if (errorCode === 'auth/wrong-password') {
      alert('Wrong password.');
    } else {
      alert(errorMessage);
    }
    // [END_EXCLUDE]
  });
