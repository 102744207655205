import React, { ReactNode } from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { GlueTableColumn } from './GlueTable';

export type GlueTableSkeletonsProps = {
  isLoading: boolean;
  rowsPerPage: number;
  columns: GlueTableColumn[];
};

export default function GlueTableSkeletons({
  isLoading,
  rowsPerPage,
  columns
}: GlueTableSkeletonsProps) {
  const skeletonRows: ReactNode[] = [];
  if (isLoading) {
    for (let i = 0; i < rowsPerPage; i++) {
      const cells = columns.map((column: GlueTableColumn, index) => {
        return (
          <TableCell key={`${column.columnName}-${i}-${index}`} component="th" scope="row">
            <Skeleton variant="text" />
          </TableCell>
        );
      });
      const row = <TableRow key={i}>{cells}</TableRow>;
      skeletonRows.push(row);
    }
  }

  return <TableBody>{skeletonRows}</TableBody>;
}
