import React, { useContext, useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles
} from '@material-ui/core';
import { GlueTheme } from '../../../../GlueThemeType';
import { PickableItem } from '../PickListTypes';
import { UserType } from '../../../../roles/RolesTypes';
import { UserContext } from '../../../../roles/User';
import { isEmpty, get } from 'lodash';
import { check } from '../../../../roles/Can';
import GlueConfirmActionModal from '../../../Presentational/GlueConfirmActionModal';
import { PickListContext } from '../PickListContext';
import { UnprintPickItemData } from './UnprintPickItemTypes';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    bold: {
      fontWeight: 600
    }
  })
);

export interface UnprintItemModalProps {
  pickableItem: PickableItem;
}

export default function UnprintItemModal({ pickableItem }: UnprintItemModalProps) {
  const classes = useStyles();

  const {
    loadingUnprintPickItem,
    unprintPickItemErrors,
    setUnprintPickItemErrors,
    unprintPickItemSuccess,
    setUnprintPickItemSuccess,
    unprintPickItem,
    getPickList
  } = useContext(PickListContext);

  useEffect(() => {
    if (unprintPickItemSuccess) {
      let setCloseUnprintModalTimeout = setTimeout(handleClose, 1000);
      return () => {
        clearTimeout(setCloseUnprintModalTimeout);
      };
    }
  }, [unprintPickItemSuccess]);

  const user: UserType = useContext(UserContext);
  const unprintButtonEnabled = user && check(user.role, 'bin:admin');
  const unprintButtonText = unprintPickItemSuccess
    ? 'Unprinted Successfully'
    : loadingUnprintPickItem
      ? 'Unprinting Item'
      : 'Unprint';
  let bodyMessageText = (
    <span>
      Are you sure you want to unprint the pick item id
      <span className={classes.bold}>{' ' + pickableItem.pickListItemId + ' '}</span>
      for pick list <span className={classes.bold}>{pickableItem.pickListId}?</span>
    </span>
  );
  if (loadingUnprintPickItem) {
    bodyMessageText = <span>Unprinting Pick Item</span>;
  }
  if (unprintPickItemSuccess) {
    bodyMessageText = <span>Pick item unprinted successfully.</span>;
  }
  if (!isEmpty(unprintPickItemErrors)) {
    bodyMessageText = (
      <span>
        {
        get(unprintPickItemErrors, 'general') + ' ' +
        get(unprintPickItemErrors, 'id', []).join('')
        }
      </span>
    );
  }

  const handleUnprintPickitem = (e: any) => {
    e.preventDefault();
    if (unprintPickItemSuccess) {
      return;
    }
    const unprintPickItemData: UnprintPickItemData = {
      id: pickableItem.pickListItemId
    };
    unprintPickItem(unprintPickItemData);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (unprintButtonEnabled) {
      // setUnprintPickItemErrors({});
      // setUnprintPickItemSuccess()
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (unprintPickItemSuccess) {
      getPickList();
    }
    setOpen(false);
    setUnprintPickItemSuccess(false);
    setUnprintPickItemErrors({});
  };

  const handleConfirmSuccess = () => {
    handleClose();
  }

  return (
    <GlueConfirmActionModal
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onConfirm={handleUnprintPickitem}
      onSuccessTimeout={handleConfirmSuccess}
      triggerButtonText={'Unprint'}
      confirmButtonText={unprintButtonText}
      canPerform={'bin:admin'}
      bodyMessageText={bodyMessageText}
      accessibleLabel={'unprint-pick-item'}
      title={'Unprint Pick Item'}
      confirmSuccess={unprintPickItemSuccess}
      loading={loadingUnprintPickItem}
      isError={!isEmpty(unprintPickItemErrors)}
    />
  );
}