const initialState = {
  brands: [], // justNames
  getActiveBrands: false,
  brandsFull: [],
  getActiveBrandsFull: false,
  categories: [],
  getActiveCategories: false,
  categoriesFull: [],
  getActiveCategoriesFull: false
};

export default function brands(state = initialState, action) {
  switch (action.type) {
    case 'ACTIVE_BRANDS_FULFILLED':
      return {
        ...state,
        brands: action.payload,
        getActiveBrands: true
      };
    case 'ACTIVE_BRANDS_REJECTED':
      return {
        ...state,
        brands: action.payload,
        getActiveBrands: false
      };
    case 'ACTIVE_BRANDS_FULL_FULFILLED':
      return {
        ...state,
        brandsFull: action.payload,
        getActiveBrands: true
      };
    case 'ACTIVE_BRANDS_FULL_REJECTED':
      return {
        ...state,
        brandsFull: action.payload,
        getActiveBrands: false
      };
    case 'ACTIVE_CATEGORIES_FULFILLED':
      return {
        ...state,
        categories: action.payload,
        getActiveCategories: true
      };
    case 'ACTIVE_CATEGORIES_REJECTED':
      return {
        ...state,
        categories: action.payload,
        getActiveCategories: false
      };
    case 'ACTIVE_CATEGORIES_FULL_REJECTED':
      return {
        ...state,
        categoriesFull: action.payload,
        getActiveCategories: false
      };
    case 'ACTIVE_CATEGORIES_FULL_FULFILLED':
      return {
        ...state,
        categoriesFull: action.payload,
        getActiveCategories: true
      };
    case 'BRAND_STORE_RESET':
      return {
        ...state,
        brands: [],
        getActiveBrands: false,
        categories: [],
        getActiveCategories: false
      };
    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
}
