import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 25,
    maxWidth: '.8vm'
  },
  container: {
    maxHeight: '70vh'
  }
}));

function PaidShippingTable({ rowsData }) {
  const classes = useStyles();

  const rows = useMemo(() => {
    return Object.values(rowsData);
  }, [rowsData]);

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>tracking_number</TableCell>
                <TableCell>date_shipped</TableCell>
                <TableCell>ship_company</TableCell>
                <TableCell>fulfillment_warehouse</TableCell>
                <TableCell>shipping_quote</TableCell>
                <TableCell>shipping_cost_billed</TableCell>
                <TableCell>shipping_cost_paid</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row.tracking_number}>
                    <TableCell>{row.tracking_number}</TableCell>
                    <TableCell>{row.date_shipped}</TableCell>
                    <TableCell>{row.ship_company}</TableCell>
                    <TableCell>{row.fulfillment_warehouse}</TableCell>
                    <TableCell>{row.shipping_quote}</TableCell>
                    <TableCell>{row.shipping_cost_billed}</TableCell>
                    <TableCell>{row.shipping_cost_paid}</TableCell>
                    <TableCell>
                      <a
                        href={`/shipping-reconciliation/search?searchType=trackingNumber&searchValue=${row.tracking_number}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Edit
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default PaidShippingTable;
