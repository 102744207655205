import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import sortBy from 'lodash/sortBy';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import { UserContext } from '../../roles/User';
import { UserType } from '../../roles/RolesTypes';
import {
  useAddPurchaseOrderItem,
  useDeletePurchaseOrderItem,
  useFinishPurchaseOrder,
  useGetPOReceivableLocations,
  useGetPurchaseOrder,
  usePONumber,
  usePOStatuses,
  useReviewReceivedSerials,
  useUpdatePOStatusToOrderConfirmed,
  useUpdatePurchaseOrder,
  useUpdatePurchaseOrderItem,
  useUpdatePurchaseOrderTotals,
  useGetPoTerms,
  useSendPurchaseViaEdi
} from './PurchaseOrderDetailsHooks';
import { makeUpdatePurchaseOrderRequestFromPurchaseOrder } from './PurchaseOrderDetailsHelpers';
import {
  PurchaseOrder,
  PurchaseOrderStatus,
  PurchaseTerm
} from '../../common/Types/ApiOsposDbModels/PurchaseOrderTypes';
import { PurchaseOrderItem } from '../../common/Types/ApiOsposDbModels/PurchaseOrderItemTypes';
import {
  AddProductToPORequest,
  PurchaseOrderData,
  UpdatePurchaseOrderItemRequest
} from './PurchaseOrderDetailsTypes';
import { InventoryLocationSnapshot } from '../../common/Types/DbSnapshots/InventoryStateDbSnapshotTypes';
import {
  usePrintPurchaseOrderAsPDF,
  useSavePurchaseOrderAsPDF,
  useSendPurchaseOrderAsPDF
} from './PurchaseOrderPDFHooks';
import { useHttp } from '../../common/Hooks';
import { optionFormat } from '../PurchaseOrderList/PurchaseOrderListContainer';
import moment from 'moment';
import { getDefaultSettings } from 'http2';

const defaultStatus: PurchaseOrderStatus = { id: 0, status_name: '' };
const defaultPoTerm: PurchaseTerm = { id: null, type: 'NONE', notes: '' };

function getDefaultRdd() {
  const today = moment();
  const weekday = today.get('weekday');
  if (weekday !== 5) {
    if (weekday < 5) {
      today.add(5 - weekday, 'days');
    } else {
      today.add(5 + (6 - weekday), 'days');
    }
  }
  return today.add(3, 'weeks').format('YYYY-MM-DD');
}

export default function PurchaseOrderContainer() {
  const user = useContext<UserType>(UserContext);
  const history = useHistory();

  const [poData, setPOData] = useState<PurchaseOrderData | null>(null);
  const [poIsUpdating, setPOIsUpdating] = useState<boolean>(false);
  const [toUpdate, setToUpdate] = useState<boolean>(true);
  const [poIsPaid, setPOIsPaid] = useState<boolean>(false);
  const [poHasDirtyFields, setPOHasDirtyFields] = useState<boolean>(false);
  const [isReceiving, setIsReceiving] = useState<boolean>(false);
  const [poItemToReceive, setPOItemToReceive] = useState<PurchaseOrderItem | null>(null);
  const [isUnReceiving, setIsUnReceiving] = useState<boolean>(false);
  const [poEditingDisabled, setPOEditingDisabled] = useState<boolean>(true);
  const [composingPOEmail, setComposingPOEmail] = useState<boolean>(false);
  const [poVendorEmail, setPOVenderEmail] = useState<string>('');
  const [poEmailContent, setPOEmailContent] = useState<string>('');
  const [poEmailCCs, setPOEmailCCs] = useState<string>('');
  const [poEmailRecipientName, setPOEmailRecipientName] = useState<string>('');
  const [vendorOrderId, setVendorOrderId] = useState<string>('');
  const [selectedLocationCode, setSelectedLocationCode] = useState<string>('');
  const [imsLocations, setImsLocations] = useState<InventoryLocationSnapshot[]>([]);
  const [poTerms, setPoTerms] = useState<PurchaseTerm[]>([]);
  const [currentPOStatus, setCurrentPOStatus] = useState<PurchaseOrderStatus>(defaultStatus);
  const [dfiDiscount, setDfiDiscount] = useState<number>(0);
  const [expectedArrivalDate, setExpectedArrivalDate] = useState<string>('');
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<string>(getDefaultRdd());
  const [selectedPoTerm, setSelectedPoTerm] = useState<PurchaseTerm>(defaultPoTerm);

  const handleUpdateExpectedArrivalDate = (date: string): void => {
    setExpectedArrivalDate(date);
  };

  const handleUpdateRequestedDeliveryDate = (date: string): void => {
    setRequestedDeliveryDate(date);
  };

  const [selectedVendor, setSelectedVendor] = useState<number>(0);

  const { poNum: currentPOParam } = usePONumber();
  const getPurchaseOrder = useGetPurchaseOrder();
  const getPoTerms = useGetPoTerms();
  const updatePurchaseOrder = useUpdatePurchaseOrder();
  const updatePurchaseOrderItem = useUpdatePurchaseOrderItem();
  const updatePOStatusToOrderConfirmed = useUpdatePOStatusToOrderConfirmed();
  const addPurchaseOrderItem = useAddPurchaseOrderItem();
  const deletePurchaseOrderItem = useDeletePurchaseOrderItem();
  const sendPurchaseViaEdi = useSendPurchaseViaEdi();
  const updatePurchaseOrderTotals = useUpdatePurchaseOrderTotals();
  const getPOReceivableLocations = useGetPOReceivableLocations();
  const finishPurchaseOrder = useFinishPurchaseOrder();
  const poStatuses = usePOStatuses();
  const {
    receivedSerials,
    reviewReceivedSerials,
    resetReceivedSerials
  } = useReviewReceivedSerials();

  const printPurchaseOrderAsPDF = usePrintPurchaseOrderAsPDF();
  const savePurchaseOrderAsPDF = useSavePurchaseOrderAsPDF();
  const sendPurchaseOrderAsPDF = useSendPurchaseOrderAsPDF();
  const handleDfiDiscount = (v: any) => {
    setDfiDiscount(v.target.value);
    setPOHasDirtyFields(true);
  };

  const [isAts, setIsAts] = useState<boolean>(false);
  const updateAts = (value: any): void => {
    setIsAts(value);
  };
  const showPurchaseOrder = () => setToUpdate(true);

  const handleUpdatePurchaseOrder = async (purchaseOrder: PurchaseOrder) => {
    setPOIsUpdating(true);
    const updatedPurchaseOrder = await updatePurchaseOrder(
      purchaseOrder.id,
      makeUpdatePurchaseOrderRequestFromPurchaseOrder(purchaseOrder)
    );
    if (updatedPurchaseOrder) {
      showPurchaseOrder();
    }
    setPOIsUpdating(false);
  };

  const handleSubmitPOUpdate = () => {
    if (poData && poHasDirtyFields) {
      handleUpdatePurchaseOrder({
        ...poData.PO,
        vendor_order_id: vendorOrderId,
        vendor_id: selectedVendor,
        purchase_order_status: currentPOStatus,
        ims_location_code: selectedLocationCode,
        paid: Number(poIsPaid),
        dfi_discount_percentage: Number(dfiDiscount),
        purchase_terms_id: selectedPoTerm.id,
        is_ats: Number(isAts)
      });
      setPOHasDirtyFields(false);
    }
  };

  const handleUpdatePOStatusToOrderConfirmed = async () => {
    if (!poData) {
      throw new Error('Failed to handleUpdatePOStatusToOrderConfirmed: poData cannot be empty');
    }
    await updatePOStatusToOrderConfirmed(poData.PO.id);
    showPurchaseOrder();
  };

  useEffect(() => {
    const refreshPurchaseOrder = async () => {
      if (!currentPOParam) {
        throw new Error('Failed to refreshPurchaseOrder: currentPOParam cannot be empty');
      }
      setToUpdate(false);
      const refreshedPOData = await getPurchaseOrder(currentPOParam);

      setPOData(refreshedPOData);
      setVendorOrderId(refreshedPOData?.PO.vendor_order_id || '');
      setPOIsPaid(refreshedPOData?.PO.paid === 1);
      setCurrentPOStatus(refreshedPOData?.PO.purchase_order_status || defaultStatus);
      setSelectedLocationCode(refreshedPOData?.PO.ims_location_code || '');
      setDfiDiscount(refreshedPOData?.PO.dfi_discount_percentage || 0);
      setIsAts(refreshedPOData?.PO.is_ats === 1)
    };
    if (toUpdate && currentPOParam) {
      refreshPurchaseOrder();
    }
  }, [getPurchaseOrder, toUpdate, currentPOParam]);

  const addProductToPO = async (itemToAdd: AddProductToPORequest) => {
    if (!itemToAdd.product.id) {
      throw new Error('Failed to addProductToPO: OSPOS product id cannot be empty.');
    }
    if (!poData) {
      throw new Error('Failed to addProductToPO: poData cannot be empty');
    }
    const newPurchaseOrderItem = await addPurchaseOrderItem({
      // The back-end is expecting productId to be the api-ospos product table id.
      productId: itemToAdd.product.id,
      cost: itemToAdd.cost,
      qty: itemToAdd.qty,
      purchaseOrderId: poData.PO.id
    });
    if (newPurchaseOrderItem) {
      // The back-end is set up such that we need to call this ourselves in order
      // for purchase-order totals to be updated properly after an item is added.
      // A future refactor should consider having the back-end take care of all
      // of this implicitly.
      await updatePurchaseOrder(
        poData.PO.id,
        makeUpdatePurchaseOrderRequestFromPurchaseOrder(poData.PO)
      );
    } else {
      // Error logic can go here.
    }
    showPurchaseOrder();
  };

  const handleUpdatePOItem = async (purchaseOrderItem: PurchaseOrderItem) => {
    if (!poData) {
      throw new Error('Failed to handleUpdatePOItem: poData cannot be empty');
    }
    const updatePOItemRequest: UpdatePurchaseOrderItemRequest = {
      invoice_cost: Number(purchaseOrderItem.invoice_cost.replace(',', '')),
      qty: Number(purchaseOrderItem.qty_ordered),
      shipping_cost: 0,
      note: '',
      expected_arrival_date: purchaseOrderItem.expected_arrival_date,
      carrier_name: purchaseOrderItem.carrier_name,
      tracking_number: purchaseOrderItem.tracking_number
    };
    const updatedPOItem = await updatePurchaseOrderItem(purchaseOrderItem.id, updatePOItemRequest);
    if (updatedPOItem) {
      await updatePurchaseOrder(
        poData.PO.id,
        makeUpdatePurchaseOrderRequestFromPurchaseOrder(poData.PO)
      );
      showPurchaseOrder();
    }
  };

  const applyExpectedArrivalDateToAllLineItems = (): void => {
    if (poData) {
      poData.PO_LINES.forEach((poLine: any) =>
        handleUpdatePOItem({
          ...poLine,
          expected_arrival_date: expectedArrivalDate
        })
      );
      setExpectedArrivalDate('');
    }
  };

  const applyRequestedDeliveryDateToAllLineItems = (): void => {
    if (poData) {
      poData.PO_LINES.forEach((poLine: any) =>
        handleUpdatePOItem({
          ...poLine,
          expected_arrival_date: requestedDeliveryDate
        })
      );
    }
  };

  useEffect(() => {
    applyRequestedDeliveryDateToAllLineItems();
  }, [requestedDeliveryDate]);

  const [carrier, setCarrier] = useState<string>('');

  const handleUpdateCarrier = (newCarrier: string): void => {
    setCarrier(newCarrier);
  };

  const applyCarrierToAllLineItems = (): void => {
    if (poData) {
      poData.PO_LINES.forEach((poLine: PurchaseOrderItem) =>
        handleUpdatePOItem({
          ...poLine,
          carrier_name: carrier
        })
      );
      setCarrier('');
    }
  };

  const [trackingNumber, setTrackingNumber] = useState<string>('');

  const handleUpdateTrackingNumber = (newTracking: string): void => {
    setTrackingNumber(newTracking);
  };

  const applyTrackingNumberToAllLineItems = (): void => {
    if (poData) {
      poData.PO_LINES.forEach((poLine: PurchaseOrderItem) =>
        handleUpdatePOItem({
          ...poLine,
          tracking_number: trackingNumber
        })
      );
      setTrackingNumber('');
    }
  };

  const handleUpdateTotals = useCallback<(poNum: number) => Promise<void>>(
    async (poNum: number) => {
      await updatePurchaseOrderTotals(poNum);
      showPurchaseOrder();
    },
    [updatePurchaseOrderTotals]
  );


  const initializeReceiving = useCallback((poItem: PurchaseOrderItem) => {
    setIsReceiving(true);
    setPOItemToReceive(poItem);
  }, []);

  const initializeUnReceiving = useCallback((poItem: PurchaseOrderItem) => {
    setIsUnReceiving(true);
    setPOItemToReceive(poItem);
  }, []);

  const endReceiving = useCallback(() => {
    setIsReceiving(false);
    setPOItemToReceive(null);
    showPurchaseOrder();
    if (poData) {
      handleUpdateTotals(poData.PO.id);
    }
  }, [poData, handleUpdateTotals]);

  const endUnReceiving = useCallback(() => {
    setIsUnReceiving(false);
    setPOItemToReceive(null);
    showPurchaseOrder();
  }, []);

  const handleDeletePOItem = async (purchaseOrderItem: PurchaseOrderItem) => {
    if (!poData) {
      throw new Error('Failed to handleDeletePOItem: poData cannot be empty');
    }
    const deletionSuccessful = await deletePurchaseOrderItem(purchaseOrderItem.id);
    if (deletionSuccessful) {
      await updatePurchaseOrder(
        poData.PO.id,
        makeUpdatePurchaseOrderRequestFromPurchaseOrder(poData.PO)
      );
      showPurchaseOrder();
    }
  };

  const [ediErrors, setEdiErrors] = useState<string[]>([]);
  const handleSendPurchaseViaEdi = async () => {
    setEdiErrors([]);;
    if (!poData) {
      throw new Error('Failed to handleDeletePOItem: poData cannot be empty');
    }
    const response = await sendPurchaseViaEdi(poData.PO.id);
    if (response.ok) {
      setComposingPOEmail(false);
      showPurchaseOrder();
    } else {
      const errors = await response.json();
      setEdiErrors(errors);
    }
  };

  const handleFinishPurchaseOrder = async () => {
    if (!poData) {
      throw new Error('Failed to handleFinishPurchaseOrder: poData cannot be empty');
    }
    const finishedPurchaseOrder = await finishPurchaseOrder(poData.PO.id);
    if (finishedPurchaseOrder) {
      history.push('/purchase-orders');
    }
  };

  useEffect(() => {
    const refreshLocations = async () => {
      const fetchedLocations = await getPOReceivableLocations();
      setImsLocations(sortBy(fetchedLocations || [], ['location_code']));
    };
    refreshLocations();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const refreshPoTerms = async () => {
      const fetchPoTerms = await getPoTerms();
      setPoTerms(fetchPoTerms || []);
    };
    refreshPoTerms();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleSelectPoTerm = (v: any) => {
    if (poData && poTerms) {
      const poTermToSelect = poTerms.find((p: any) => p.id === v.value);
      if (poTermToSelect) {
        setSelectedPoTerm(poTermToSelect);
        setPOHasDirtyFields(true);
      }
    }
  };

  useEffect(() => {
    if (poData && poTerms) {
      const poTermToSelect = poTerms.find((p: any) => p.id === poData.PO.purchase_terms_id);
      if (poTermToSelect) {
        setSelectedPoTerm(poTermToSelect);
      }
    }
  }, [poData, poTerms]);

  useEffect(() => {
    if (poData) {
      setSelectedLocationCode(poData.PO.ims_location_code || '');
    }
  }, [poData]);

  useEffect(() => {
    if (poData) {
      setSelectedVendor(poData.PO.vendor_id || 0);
    }
  }, [poData]);

  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const vendors: any = useHttp('vendor', {
    ...options,
    onMount: true
  });

  const vendorListFormatted: any = optionFormat(vendors, 'company_name');

  if (!poData || !vendorListFormatted) return 'Loading...';
  try {
    return (
      <PurchaseOrderDetails
        isAts={isAts}
        updateAts={updateAts}
        handleSendPurchaseViaEdi={handleSendPurchaseViaEdi}
        vendors={vendorListFormatted}
        selectedVendor={selectedVendor}
        handleVendorUpdate={(v: number) => {
          setSelectedVendor(v);
          setPOHasDirtyFields(true);
        }}
        poData={poData}
        poIsUpdating={poIsUpdating}
        showPurchaseOrder={showPurchaseOrder}
        role={user.role}
        poEditingDisabled={poEditingDisabled}
        setPOEditingDisabled={setPOEditingDisabled}
        addProductToPO={addProductToPO}
        composingPOEmail={composingPOEmail}
        setComposingPOEmail={setComposingPOEmail}
        poVendorEmail={poVendorEmail}
        setPOVenderEmail={setPOVenderEmail}
        poEmailContent={poEmailContent}
        setPOEmailContent={setPOEmailContent}
        poEmailCCs={poEmailCCs}
        setPOEmailCCs={setPOEmailCCs}
        poEmailRecipientName={poEmailRecipientName}
        setPOEmailRecipientName={setPOEmailRecipientName}
        handleDeletePOItem={handleDeletePOItem}
        handleUpdatePOItem={handleUpdatePOItem}
        poItemToReceive={poItemToReceive}
        initializeReceiving={initializeReceiving}
        initializeUnReceiving={initializeUnReceiving}
        endReceiving={endReceiving}
        endUnReceiving={endUnReceiving}
        isReceiving={isReceiving}
        isUnReceiving={isUnReceiving}
        printPurchaseOrderAsPDF={printPurchaseOrderAsPDF}
        savePurchaseOrderAsPDF={savePurchaseOrderAsPDF}
        sendPurchaseOrderAsPDF={sendPurchaseOrderAsPDF}
        handleFinishPurchaseOrder={handleFinishPurchaseOrder}
        handleUpdatePOStatusToOrderConfirmed={handleUpdatePOStatusToOrderConfirmed}
        vendorOrderId={vendorOrderId}
        setVendorOrderId={setVendorOrderId}
        currentPOStatus={currentPOStatus}
        setCurrentPOStatus={setCurrentPOStatus}
        poStatuses={poStatuses}
        poTerms={poTerms}
        reviewReceivedSerials={reviewReceivedSerials}
        receivedSerials={receivedSerials}
        resetReceivedSerials={resetReceivedSerials}
        imsLocations={imsLocations}
        expectedArrivalDate={expectedArrivalDate}
        handleUpdateExpectedArrivalDate={handleUpdateExpectedArrivalDate}
        applyExpectedArrivalDateToAllLineItems={applyExpectedArrivalDateToAllLineItems}
        requestedDeliveryDate={requestedDeliveryDate}
        handleUpdateRequestedDeliveryDate={handleUpdateRequestedDeliveryDate}
        handleLocationChange={(v: any) => {
          setSelectedLocationCode(v.value);
          setPOHasDirtyFields(true);
        }}
        selectedLocationCode={selectedLocationCode}
        poHasDirtyFields={poHasDirtyFields}
        setPOHasDirtyFields={setPOHasDirtyFields}
        handleSubmitPOUpdate={handleSubmitPOUpdate}
        poIsPaid={poIsPaid}
        setPOIsPaid={setPOIsPaid}
        dfiDiscount={dfiDiscount}
        handleDfiDiscount={handleDfiDiscount}
        selectedPoTerm={selectedPoTerm}
        handleSelectPoTerm={handleSelectPoTerm}
        ediErrors={ediErrors}
        applyCarrierToAllLineItems={applyCarrierToAllLineItems}
        handleUpdateCarrier={handleUpdateCarrier}
        carrier={carrier}
        trackingNumber={trackingNumber}
        handleUpdateTrackingNumber={handleUpdateTrackingNumber}
        applyTrackingNumberToAllLineItems={applyTrackingNumberToAllLineItems}
      />
    );
  } catch (e) {
    console.info(e);
    return (
      <div>
        <Typography variant="h2">PO {currentPOParam} does not exist.</Typography>
        <Typography>
          <Link to="/purchase-orders/">Go to all POs</Link>
        </Typography>
      </div>
    );
  }
}
