import { Modal, Backdrop, Fade, Paper, Grid, IconButton, Icon, Typography, createStyles, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { GlueTheme } from '../../GlueThemeType';
import Can from '../../roles/Can';
import GlueButton from './GlueButton';
import GlueTooltip from './GlueTooltip';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(8),
      maxWidth: 548
    },
    buttons: {
      padding: theme.spacing(4, 4, 0, 0)
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    closeButton: {
      margin: theme.spacing(-6, -1, 0, 0)
    },
    messageSuccess: {
      color: theme.palette.success.main
    },
    messageError: {
      color: theme.palette.error.main
    },
    bold: {
      fontWeight: 600
    }
  })
);

export type ConfirmActionModalProps = {
  onOpen: () => void;
  onClose: () => void;
  onConfirm: (e: any) => void;
  onSuccessTimeout: () => void;
  triggerButtonText: string;
  confirmButtonText: string;
  canPerform?: string;
  open: boolean;
  bodyMessageText: string | ReactNode;
  accessibleLabel: string;
  title: string;
  confirmSuccess: boolean;
  loading: boolean;
  isError: boolean;
}

export default function GlueConfirmActionModal({
  open,
  onOpen,
  onClose,
  onConfirm,
  onSuccessTimeout,
  triggerButtonText,
  confirmButtonText,
  canPerform,
  bodyMessageText,
  accessibleLabel,
  title,
  confirmSuccess,
  loading,
  isError,
}: ConfirmActionModalProps) {

  useEffect(() => {
    if (confirmSuccess) {
      let closeConfirmModalTimeout = setTimeout(onSuccessTimeout, 1000);
      return () => {
        clearTimeout(closeConfirmModalTimeout);
      };
    }
  }, [confirmSuccess]);

  const classes = useStyles();
  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = (e: any) => {
    onConfirm(e);
  }

  const confirmButtonEndIcon = confirmSuccess
    ? 'done'
    : loading
      ? 'hourglass_empty'
      : '';
  let bodyMessageClassName = '';
  if (confirmSuccess) {
    bodyMessageClassName = classes.messageSuccess;
  }
  if (isError) {
    bodyMessageClassName = classes.messageError;
  }

  return (
    <div>
      <Can
        perform={canPerform ? canPerform : 'boards:view'}
        yes={() => (
          <GlueTooltip title={triggerButtonText}>
            <span>
              <GlueButton
                onClick={handleOpen}
                variant={'outlined'}
                size={'small'}>
                {triggerButtonText}
              </GlueButton>
            </span>
          </GlueTooltip>
        )}
        no={() => (
          <GlueTooltip title="You don't have permission to do this.">
            <span>
              <GlueButton disabled variant={'outlined'} size={'small'}>
                {triggerButtonText}
              </GlueButton>
            </span>
          </GlueTooltip>
        )}
      />
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby={`${accessibleLabel}-modal`}
        aria-describedby={`${accessibleLabel}-modal`}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid container justify={'space-between'}>
              <Grid container spacing={0} xs={12} justify={'flex-end'}>
                <Grid item xs={1}>
                  <IconButton
                    className={classes.closeButton}
                    onClick={handleClose}
                    aria-label={`close-${accessibleLabel}-modal`}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.title} variant="h4">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={bodyMessageClassName} variant="body1">
                    {bodyMessageText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify={'flex-end'} spacing={0}>
                <Grid item xs={3} className={classes.buttons}>
                  <GlueButton
                    type="button"
                    variant="outlined"
                    size="large"
                    fullWidth
                    color={confirmSuccess ? 'grey' : 'default'}
                    disabled={loading}
                    onClick={handleClose}
                  >
                    Cancel
                  </GlueButton>
                </Grid>
                <Grid item xs={9} className={classes.buttons}>
                  <GlueButton
                    onClick={handleConfirm}
                    type="button"
                    size="large"
                    fullWidth
                    endIcon={confirmButtonEndIcon}
                    disabled={isError || loading}
                    success={confirmSuccess}
                  >
                    {confirmButtonText}
                  </GlueButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}