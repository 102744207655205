import { ApiOsposProduct } from '../../../common/Types/ApiOsposDbModels/ApiOsposProductTypes';
import { AddProductToPORequest } from '../PurchaseOrderDetailsTypes';

export type POProductOption = {
  label: string;
  value: string;
};

export const getOptionValue = (option: any): string => option.value;

export const formatPOProductSearchResults = (
  productResults: ApiOsposProduct[]
): POProductOption[] =>
  productResults.map(product => ({
    label: product.model_number,
    value: product.model_number
  }));

export const determinePOItemCost = (
  itemPrice: number,
  discountAmount: number,
  discountType: string | null | undefined = null
): string => {
  let calculated = null;
  switch (discountType) {
    case 'dollar':
      calculated = itemPrice - discountAmount;
      break;
    case 'percent':
      calculated = itemPrice * (1 - discountAmount / 100);
      break;
    default:
      calculated = itemPrice;
  }
  return calculated.toFixed(2);
};

export type ProductSearchForPOProps = {
  addProductToPO: (itemToAdd: AddProductToPORequest) => Promise<void>;
};
