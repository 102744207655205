import { apiOspos } from '../config/config';

export function getReadyToCreatePO() {
  return dispatch => {
    dispatch({
      type: 'READY_TO_CREATE_PO',
      payload: true
    });
  };
}

export function createPurchaseOrder(purchaseOrder, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/purchase`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(purchaseOrder)
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({
            type: 'CREATE_PURCHASE_ORDER_REJECTED',
            payload: responseData
          });
        } else {
          dispatch({
            type: 'CREATE_PURCHASE_ORDER_FULFILLED',
            payload: responseData
          });
        }
      })
      .catch(e => console.warn(e));
  };
}

export function createVendor(vendor, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/vendor/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(vendor)
    })
      .then(response => response.json())
      .then(responseData => {
        dispatch({ type: 'VENDOR_CREATE_FULFILLED', payload: responseData });
        return true;
      })
      .catch(err => {
        dispatch({ type: 'VENDOR_CREATE_REJECTED', payload: err });
        return false;
      });
  };
}

export function clearVendorFields() {
  return dispatch => {
    dispatch({
      type: 'CLEAR_VENDOR_FIELDS',
      payload: false
    });
  };
}

export function clearError() {
  return dispatch => {
    dispatch({
      type: 'ERROR_CLEARED',
      payload: false
    });
  };
}

export function getVendor(vendorId, authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/vendor/${vendorId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({ type: 'GET_VENDOR_REJECTED', payload: responseData });
        } else {
          dispatch({ type: 'GET_VENDOR_FULFILLED', payload: responseData });
        }
      });
  };
}

export function vendorList(authorizationToken) {
  return dispatch => {
    return fetch(`${apiOspos.baseUrl}/vendor`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          dispatch({
            type: 'VENDOR_LIST_REJECTED',
            payload: responseData.data
          });
        } else {
          dispatch({
            type: 'VENDOR_LIST_FULFILLED',
            payload: responseData.data
          });
        }
      });
  };
}
