import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { useLocationCompare } from '../../../actions/locationActions';
import { useToggle } from '../../../common/Hooks';
import Can from '../../../roles/Can';
import { GlueCopyToClipboardIconButton } from '../../Presentational/GlueCopyToClipboardIconButton';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1, 0)
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: theme.spacing(1, 0),
    marginBottom: theme.spacing(1)
  },
  expansion: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.default
  },
  expansionContent: {
    margin: 0,
    '&$expanded': { margin: 0 }
  },
  expansionRoot: {
    minHeight: 'initial',
    '&$expanded': { minHeight: 'initial' }
  },
  expansionText: {
    fontWeight: theme.typography.fontWeightBold
  },
  expandIcon: {
    padding: theme.spacing(1)
  },
  expanded: {},
  bucketListItem: {
    '&.az02': {
      color: theme.palette.info.main,
      fontWeight: theme.typography.fontWeightBold
    },
    '&.ky01': {
      color: theme.palette.error.dark,
      fontWeight: theme.typography.fontWeightBold
    },
    '&.tx01': {
      color: theme.palette.warning.main,
      fontWeight: theme.typography.fontWeightBold
    }
  }
}));

export default function ModelSearchResult({ result, expandResult }) {
  const classes = useStyles();
  const [expanded, toggleExpanded] = useToggle(expandResult);
  const primaryLocationCode = useSelector(state => state.location.location);
  const locationCompare = useLocationCompare();

  useEffect(() => {
    toggleExpanded(expandResult);
  }, [expandResult, toggleExpanded]);

  const locationCodeInventory = useMemo(
    () =>
      result.inventory.find(({ condition_code, location_code }) => {
        return locationCompare(location_code) && condition_code === 'NEW';
      }),
    [result.inventory, locationCompare]
  );

  const filteredSellable = useMemo(
    () =>
      result.inventory.filter(
        ({ condition_code, quantity }) => condition_code === 'NEW' && quantity > 0
      ),
    [result.inventory]
  );

  const onHandQuantity = get(locationCodeInventory, 'quantity', 0);

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.gridContainer}>
          <Typography>
            Brand:
            <br />
            {result.brand_name}
          </Typography>
          <Typography>
            Model:
            <br />
            <Typography variant="body1" component="span" style={{ fontWeight: 'bold' }}>
              <GlueCopyToClipboardIconButton parentTypographyVariant="body1">
                {result.model}
              </GlueCopyToClipboardIconButton>
            </Typography>
          </Typography>
          <Typography>Category: {result.category_name}</Typography>
          <Typography>Cost: {result.cost}</Typography>
          <Typography>Reg. Map: {result.master_listing_map_price}</Typography>
          <Typography>Adj. Map {result.master_listing_adj_map_price}</Typography>
          <Typography>Adj. Cart {result.master_listing_adj_cart_price}</Typography>
          <Typography>
            Commission: {Math.trunc(result.master_listing_commission_rate * 100)}%
          </Typography>
          <Typography style={{ display: 'flex' }}>
            On Hand Qty:
            <Typography component="span" style={{ fontWeight: 'bold', marginRight: 5 }}>
              {onHandQuantity}
            </Typography>
          </Typography>
        </div>
        <ExpansionPanel
          square
          variant="outlined"
          className={classes.expansion}
          expanded={expanded}
          onChange={toggleExpanded}
        >
          <ExpansionPanelSummary
            classes={{
              root: classes.expansionRoot,
              content: classes.expansionContent,
              expandIcon: classes.expandIcon,
              expanded: classes.expanded
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.expansionText}>
              See {expanded ? 'Less' : 'More'}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              <Typography variant="body1">
                UPC:{' '}
                <GlueCopyToClipboardIconButton parentTypographyVariant="body1">
                  {result.upc}
                </GlueCopyToClipboardIconButton>
              </Typography>
              <Typography>Description: {result.product_title}</Typography>
              {filteredSellable.length ? (
                <List
                  dense
                  subheader={
                    <ListSubheader disableSticky disableGutters component="strong">
                      Sellable Bucket Quantity
                    </ListSubheader>
                  }
                >
                  {filteredSellable.map(({ quantity, bucket, id }) => {
                    return (
                      <ListItem
                        key={id}
                        className={`${classes.bucketListItem} ${bucket.toLowerCase()}`}
                        disableGutters
                      >
                        {bucket} - Qty: {quantity}{' '}
                        {primaryLocationCode === bucket ? '(On Hand Qty)' : null}
                      </ListItem>
                    );
                  })}
                </List>
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          component={Link}
          to={{
            pathname: `/inventory/detail/model/${result.model}`,
            state: { clearState: true }
          }}
          size="small"
        >
          Details
        </Button>
        <Button
          color="primary"
          component={Link}
          to={`/inventory/simulation/?model=${result.model}`}
          size="small"
        >
          Inventory Events Simulation
        </Button>
        <Can
          perform="inventory-transfer:view"
          yes={() => (
            <Button
              color="primary"
              component={Link}
              to={`/inventory/transfer/${result.model}`}
              size="small"
            >
              Inventory Transfer
            </Button>
          )}
        />
      </CardActions>
    </Card>
  );
}
