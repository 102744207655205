import print from 'print-js';
import { saveAs } from 'file-saver';
import { apiOspos } from '../../config/config';

function getPDF(url, access_token, action) {
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
    .then(response => response.blob())
    .then(action);
}

function setupPDF(blob, action) {
  const pdfURL = URL.createObjectURL(blob);
  action(pdfURL);
  return blob;
}

function savePDF(blob) {
  return setupPDF(blob, b => saveAs(b, 'purchase-order.pdf'));
}

function printPDF(blob) {
  return setupPDF(blob, print);
}

export function savePO(id, access_token) {
  return getPDF(`${apiOspos.baseUrl}/purchaseOrder/${id}/pdf`, access_token, savePDF);
}

export function printPO(id, access_token) {
  return getPDF(`${apiOspos.baseUrl}/purchaseOrder/${id}/pdf`, access_token, printPDF);
}

export function sendPO(id, access_token, message, name = '', email = '', ccs = '') {
  return fetch(`${apiOspos.baseUrl}/purchaseOrder/${id}/email`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      name,
      message,
      ccs: ccs.split(',')
    })
  });
}

export function getPOEmailPreview(id, access_token) {
  return fetch(`${apiOspos.baseUrl}/purchaseOrder/${id}/email-preview`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
    .then(data => data.json())
    .then(json => json.mail)
    .catch(e => console.info(e));
}
