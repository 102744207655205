import { makeStyles } from '@material-ui/styles';

export const usePurchaseOrderDetailsStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  info: {
    display: 'flex',
    wrap: 'wrap',
    marginTop: '1rem'
  },
  infoField: {
    margin: '0.5rem 0',
    flex: '400px',
    marginRight: theme.spacing(4)
  },
  editArea: {
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap'
  },
  actionBtn: {
    marginRight: '1rem',
    marginBottom: '1rem',
    '*:last-child': {
      marginRight: '0'
    }
  },
  dialog: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column'
  },
  email_message: {
    marginBottom: '2rem'
  },
  rep_email: {
    marginBottom: '2rem'
  },
  edit: {
    color: '#42a5f5',
    borderColor: '#42a5f5',
    '&:hover': {
      backgroundColor: '#e6f2f7'
    }
  }
}));

export const usePODialogStyles = makeStyles({
  root: {
    padding: '2rem'
  },
  typography: {
    padding: '1rem 0'
  },
  heading: {
    fontSize: '2em'
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    marginRight: '1rem',
    flex: '200px',
    '&:last-child': {
      marginRight: 0
    }
  },
  editButton: {
    flex: 'none',
    width: '10rem',
    margin: '1rem auto'
  }
});
