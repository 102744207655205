import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/styles';

const useSearchTypeStyles = makeStyles(theme => ({
  radioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  radioControl: {
    flex: '0 1 33%',
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 50%'
    }
  },
  fieldSet: {
    width: 450,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 300,
      marginTop: theme.spacing(2)
    }
  },
  legend: {
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  }
}));

export const searchOptions = [
  'Model',
  'Manufacturer',
  'Category',
  'ASIN',
  'Listing ID',
  'MPN',
  'Serial',
  'SKU',
  'UPC'
];

function SearchType({ optionValue = searchOptions[0].toLowerCase(), handleOptionChange }) {
  const classes = useSearchTypeStyles();

  return (
    <FormControl className={classes.fieldSet} component="fieldset">
      <FormLabel className={classes.legend} component="legend">
        Search By:
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        defaultValue="model"
        value={optionValue}
        onChange={handleOptionChange}
      >
        {searchOptions.map(value => (
          <FormControlLabel
            key={value}
            className={classes.radioControl}
            value={value.toLowerCase()}
            control={<Radio color="primary" />}
            label={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default SearchType;
