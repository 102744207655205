import React, { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import { useHttp } from '../../../common/Hooks';
import { useFetchModelList } from '../../Inventory/Search/fetchFunctions';
import Scanner from './Scanner';
import { matchesUpc } from '../../../helpers/scanValidator';

const useStyles = makeStyles({
  dialogBack: {
    minHeight: '40vh',
    maxHeight: '60vh',
    minWidth: '70vh',
    padding: 15
  }
});

export default function SetUpScanner({
  openContinueAuditModal,
  setOpenContinueAuditModal,
  audit_batch_name,
  audit_batch_id,
  allItems,
  bucket
}) {
  const [request, response] = useHttp('ims');
  const [searchByUpcScan, toggleSearchByUpcScan] = useState(true);
  const [scannedUpc, setScannedUpc] = useState('');
  const [scannerTime, setScannerTime] = useState(false);
  const [selectedModel, setSelectedModel] = useState('');
  const [sendupObj, setSendupObj] = useState({});
  const debouncedFetchModelList = useFetchModelList();
  const classes = useStyles();
  const [adjustmentItem, setAdjustmentItem] = useState({});
  const [tallyFinished, setTallyFinished] = useState(false);

  const handleClose = useCallback(() => {
    setSendupObj({});
    setScannedUpc('');
    setSelectedModel('');
    setScannerTime(false);
    setOpenContinueAuditModal(false);
  }, [setOpenContinueAuditModal]);

  const loadOptions = input => {
    switch (searchByUpcScan) {
      case true:
        return [];
      default:
        return debouncedFetchModelList(input.trim(), false);
    }
  };

  const handleInputChange = (value, { action = false }) => {
    if (action === 'input-change') {
      setScannedUpc(value);
    }
  };

  const handleSelectChange = (e, { action = false }) => {
    if (e) {
      const trimmedLabel = (e.label || e.value).trim();
      if (action === 'select-option') {
        setSelectedModel(trimmedLabel);
      }
    } else {
      setSelectedModel('');
    }
  };

  async function createSendup() {
    if (scannedUpc !== '' && bucket !== '' && searchByUpcScan) {
      const upcResponse = await request.get(`/inventory/products/search?upc=${scannedUpc}&exact=1`);
      if (response.ok && !isEmpty(upcResponse)) {
        setSendupObj({
          ...upcResponse[0]
        });
        return true;
      }
      setSendupObj({});
      return false;
    }
    if (selectedModel !== '' && bucket !== '') {
      const modelResponse = await request.get(`/inventory/product/preview?model=${selectedModel}`);
      if (response.ok && !isEmpty(modelResponse)) {
        setSendupObj({
          ...modelResponse
        });
        return true;
      }
      setSendupObj({});
      return false;
    }
    setSendupObj({});
    return false;
  }

  const adjustmentItemSetter = useCallback(
    function adjustmentItemSetter() {
      if ((selectedModel !== '' || scannedUpc !== '') && bucket !== '') {
        setAdjustmentItem(
          allItems.find(
            item =>
              item.audit_bucket_map.bucket === bucket &&
              (item.product.model === selectedModel ||
                (searchByUpcScan && matchesUpc(scannedUpc, item.product)))
          )
        );
      }
    },
    [allItems, bucket, searchByUpcScan, selectedModel, scannedUpc]
  );

  useEffect(() => {
    adjustmentItemSetter();
    if (!isEmpty(adjustmentItem) && !isEmpty(adjustmentItem.tally_finished_at)) {
      setTallyFinished(true);
    }
  }, [adjustmentItem, adjustmentItemSetter]);

  const resetInputs = () => {
    setScannedUpc(null);
    setSelectedModel(null);
    setTallyFinished(false);
    setAdjustmentItem(undefined);
  };

  const TallyAlreadyFinished = () => (
    <Alert severity="error" onClose={resetInputs}>
      Tally has already been submitted
    </Alert>
  );

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogBack }}
        // scrollBody <-- doesn't appear to be supported in this version of MUI
        open={openContinueAuditModal}
        onClose={handleClose}
      >
        <h2>
          {audit_batch_name} : {audit_batch_id}
        </h2>
        <h3>Bucket : {bucket}</h3>
        <Grid container spacing={2} alignItems="flex-start" justify="flex-start">
          <Grid item xs={12} sm={9}>
            <AsyncSelect
              id="modelSearch"
              isClearable
              autoFocus
              onInputChange={handleInputChange}
              cacheOptions
              controlShouldRenderValue
              loadOptions={searchByUpcScan ? null : loadOptions}
              noOptionsMessage={() => (searchByUpcScan ? 'Scan UPC' : 'No Results')}
              onChange={handleSelectChange}
              getOptionLabel={option => option.label}
              placeholder={searchByUpcScan ? 'Scan UPC' : 'Search By Model'}
              value={
                scannedUpc
                  ? { label: scannedUpc, value: scannedUpc }
                  : selectedModel
                  ? { label: selectedModel, value: selectedModel }
                  : null
              }
              onKeyDown={async e => {
                if (e.keyCode === 13) {
                  const success = await createSendup();
                  if (success) {
                    setScannerTime(true);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={searchByUpcScan}
                  onChange={e => toggleSearchByUpcScan(e.target.checked)}
                />
              }
              label="Scan UPC"
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ paddingTop: 175 }}
          spacing={2}
          columns={3}
          alignItems="flex-end"
          justify="flex-end"
        >
          {tallyFinished ? (
            <TallyAlreadyFinished />
          ) : (
            <>
              <Grid item xs={12} sm={2}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={
                    isEmpty(bucket) ||
                    isEmpty(scannedUpc) ||
                    isEmpty(selectedModel) ||
                    tallyFinished
                  }
                  onClick={async () => {
                    const success = await createSendup();
                    if (success) {
                      setScannerTime(true);
                    }
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
      <Dialog open={scannerTime} onClose={handleClose}>
        <Scanner
          product={sendupObj}
          setScannerTime={handleClose}
          auditBucket={bucket}
          auditBatchId={audit_batch_id}
          auditBatchName={audit_batch_name}
          inProgressScans={adjustmentItem ? adjustmentItem.serials : []}
          isSetup
          auditItem={adjustmentItem}
        />
      </Dialog>
    </>
  );
}
