import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/styles';
import get from 'lodash/get';
import Switch from '../../../common/LayoutComponents/Toggle';
import SearchType, { searchOptions } from './SearchType';
import { useToggle, useQueryWithLocation as useQuery } from '../../../common/Hooks';
import SerialSearchResult from '../../InventorySerials/Search/InventorySerialSearch';
import ModelSearchResult from './ModelSearchResult';
import {
  useManufacturerList,
  useCategoryList,
  useFetchModelList,
  useSearchResults
} from './fetchFunctions';
import AdvancedInventorySearchModal from './AdvancedInventorySearchModal';

import './search.css';

const useInventorySearchStyles = makeStyles(theme => ({
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 350,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  rightOptions: {
    display: 'flex',
    flexDirection: 'column'
  },
  helperText: { marginLeft: 0 },
  advancedButton: {
    alignSelf: 'flex-start',
    marginTop: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2)
    }
  }
}));

const useSelectSearchType = () => {
  const params = useQuery();
  const history = useHistory();

  const handleOptionChange = e => {
    if (e) {
      params.set('searchBy', e.target.value);
      history.push(`?${params.toString()}`);
    }
  };

  return [params.get('searchBy') || searchOptions[0].toLowerCase(), handleOptionChange];
};

const useCheckInventoryTransfer = () => {
  const match = useRouteMatch();
  return match.path === '/inventory/transfer/:id';
};

function InventorySearch({ children, searchForModel = () => {} }) {
  // TODO: remove the need to have this comes from the InventoryDetails page.
  const { searchValue } = useParams();
  const query = useQuery();
  // There's an open issue in react-select about the input clearing on create[1].
  // having a controlled component fixes it.
  // [1]: https://github.com/JedWatson/react-select/issues/3882
  // TODO: abstract this into a context provider
  const [inputValue, _setInputValue] = useState(searchValue || query.get('searchValue') || '');
  const [error, setError] = useState('');
  const [searchResults, fetchSearchResults, setSearchResults] = useSearchResults();

  // searchable inventory models for react-select
  const [manufacturerList, fetchManufacturers] = useManufacturerList();
  const [categoryList, fetchCategories] = useCategoryList();
  const debouncedFetchModelList = useFetchModelList();
  const [searchSelection, handleOptionChange] = useSelectSearchType();

  const [includeInactive, toggleIncludeInactive] = useToggle(false);
  const [expandAllResults, toggleExpandAllResults] = useToggle(false);
  const [disableEnterKey, setDisableEnterKey] = useState(false);
  const [isAdvancedSearchOpen, toggleAdvancedSearch] = useToggle(false);
  const isInventoryTransfer = useCheckInventoryTransfer();

  const history = useHistory();
  const classes = useInventorySearchStyles();
  const theme = useTheme();

  const setInputValue = (value, saveFunction = history.replace) => {
    if (value) {
      query.set('searchValue', value);
    } else {
      query.delete('searchValue');
    }

    // prevents duplicate searchings being added to history
    const querySearch = query.toString();
    if (querySearch !== history.location.search.slice(1)) {
      saveFunction(`?${querySearch}`);
      _setInputValue(value);
    }
  };

  // The second argument is an action-meta provided by React-Select
  // See https://react-select.com/advanced#action-meta for further info
  const handleSelectChange = (e, { action = false }) => {
    setDisableEnterKey(false);
    if (e) {
      const trimmedValue = e.value.trim();
      const trimmedLabel = (e.label || e.value).trim();

      if (action === 'create-option') {
        setDisableEnterKey(true);
        setInputValue(trimmedLabel, history.push);
        if (searchSelection === 'serial' && trimmedValue.length < 3) {
          setError('Please Extend Search Parameters.');
        } else {
          fetchSearchResults(trimmedValue, { searchType: searchSelection, includeInactive });
        }
        return;
      }

      if (action === 'select-option') {
        setInputValue(trimmedLabel);
      }

      // The below code tries to determine where we go
      // based on where we are and what was searched on
      // since this component is being used on various pages
      if (isInventoryTransfer) {
        searchForModel(trimmedValue);
        history.push(`/inventory/transfer/${trimmedValue}`);
      } else if (searchSelection === 'serial') {
        history.push(`/inventory/simulation/serial/${trimmedValue}`);
      } else if (searchSelection === 'manufacturer') {
        fetchSearchResults(trimmedValue, { searchType: 'brand' });
      } else if (searchSelection === 'category') {
        fetchSearchResults(trimmedValue, { searchType: 'category' });
      } else {
        history.push(`/inventory/detail/${searchSelection}/${trimmedValue}`);
      }
    }
  };

  const handleInputChange = (value, { action = false }) => {
    if (action === 'input-change') {
      setError('');
      setInputValue(value);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !disableEnterKey) {
      if (searchSelection === 'serialNumber') {
        history.push(`/inventory/simulation/serial/${e.target.value}`);
      } else {
        history.push(`/inventory/detail/${searchSelection}/${e.target.value}`);
      }
    }
  };

  const loadOptions = input => {
    switch (searchSelection) {
      case 'manufacturer':
        // use manufacturerList cached in local state.
        return manufacturerList;

      case 'category':
        // use categoryList cached in local state.
        return categoryList;

      case 'model':
        // Fetch for each input change after 500ms.
        return debouncedFetchModelList(input.trim(), includeInactive);
      default:
        return [];
    }
  };

  useEffect(() => {
    if (searchSelection !== 'model') {
      toggleIncludeInactive(false);
    }

    if (searchSelection === 'serial') {
      toggleExpandAllResults(false);
    }
  }, [searchSelection, toggleExpandAllResults, toggleIncludeInactive]);

  useEffect(() => {
    const value = query.get('searchValue');
    if (value) {
      fetchSearchResults(value, { searchType: searchSelection, includeInactive });
    }
  }, []);

  useEffect(() => {
    if (searchSelection === 'manufacturer' && !manufacturerList.length) {
      fetchManufacturers();
    }

    if (searchSelection === 'category' && !categoryList.length) {
      fetchCategories();
    }
  }, [
    searchSelection,
    fetchManufacturers,
    fetchCategories,
    manufacturerList.length,
    categoryList.length
  ]);

  const defaultOptions =
    searchSelection === 'category' && categoryList.length > 0
      ? categoryList
      : searchSelection === 'manufacturer' && manufacturerList.length > 0
      ? manufacturerList
      : false;

  return (
    <>
      <Paper className={classes.searchContainer}>
        <div className={classes.rightOptions}>
          <div>
            {/*
              TODO: separate this into different search boxes for each searchBy type
                most searches don't need the complexity of this particular box to work
                efficiently. It'll also provide more flexability in the AdvancedSearch.
            */}
            <AsyncCreatableSelect
              styles={{
                singleValue: provided => ({ ...provided, display: 'none' }),
                container: provided => ({ ...provided, width: 250, height: 'min-content' }),
                control: provided => ({
                  ...provided,
                  borderColor: error ? theme.palette.error.main : provided.borderColor
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  backgroundColor: error ? theme.palette.error.main : provided.backgroundColor
                })
              }}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              cacheOptions
              controlShouldRenderValue
              allowCreateWhileLoading={!isInventoryTransfer}
              formatCreateLabel={input =>
                !isInventoryTransfer ? (
                  <Typography component="span">See all results for: {input}</Typography>
                ) : null
              }
              createOptionPosition="first"
              // Don't display search if only one option found
              isValidNewOption={(search, newSearchOptions, options) =>
                (searchSelection === 'model' || searchSelection === 'serial') &&
                options.length !== 1
              }
              loadOptions={loadOptions}
              onChange={handleSelectChange}
              // There's a race condition that happens between the event onKeyDown and
              // onInputChange, I couldn't find an exact reference, but it seems this
              // always happens after onInputChange
              onInputKeyDown={handleKeyPress}
              getOptionLabel={option => option.label}
              singleValue=""
              placeholder={
                !isInventoryTransfer ? <Typography>Search {searchSelection}...</Typography> : null
              }
              defaultOptions={defaultOptions}
            />
            <FormHelperText className={classes.helperText} error={!!error} variant="outlined">
              {error}
            </FormHelperText>
          </div>

          <Switch
            disabled={searchSelection !== 'model'}
            checked={includeInactive}
            onChange={toggleIncludeInactive}
            label="Include Inactive Models"
          />
          <Switch
            disabled={searchSelection === 'serial'}
            checked={expandAllResults}
            onChange={toggleExpandAllResults}
            label="Expand All Search Results"
          />
          <Button
            color="primary"
            className={classes.advancedButton}
            onClick={() => toggleAdvancedSearch(true)}
          >
            Advanced Search
          </Button>
        </div>

        {!isInventoryTransfer ? (
          <SearchType optionValue={searchSelection} handleOptionChange={handleOptionChange} />
        ) : null}
      </Paper>
      <AdvancedInventorySearchModal
        open={isAdvancedSearchOpen}
        handleClose={() => toggleAdvancedSearch(false)}
        setSearchResults={setSearchResults}
        setInputValue={_setInputValue}
      />
      {searchResults.length && inputValue.length && !isInventoryTransfer
        ? searchResults.map(result => {
            const serialNumber = get(result, 'serial_number', false);

            if (serialNumber) {
              return <SerialSearchResult key={serialNumber} result={result} />;
            }
            return (
              <ModelSearchResult expandResult={expandAllResults} key={result.id} result={result} />
            );
          })
        : children}
    </>
  );
}

export default InventorySearch;
