import React from 'react';
import { Link } from 'react-router-dom';
import { Card, TextField, Paper, Switch, FormControlLabel } from '@material-ui/core';
import './grid.css';

function Reports(props) {
  return (
    <div className="grid">
      <div className="filters">
        <Paper className="card">
          <TextField
            type="text"
            value={props.filter}
            onChange={props.handleChange}
            label="Report Name"
          />
          {props.categories.map(category => {
            return (
              <FormControlLabel
                key={category}
                control={
                  <Switch
                    name={category}
                    color="primary"
                    checked={props.activeCategories.includes(category)}
                    onChange={props.handleActiveCategories}
                  />
                }
                label={category}
              />
            );
          })}
        </Paper>
      </div>

      <div className="cards">
        {props.categoryRoutes
          .sort((a, b) => a.name > b.name)
          .map(category => (
            <Card className="card" key={category.name}>
              <h3>{category.name}</h3>
              <ul>
                {category.routes.map(route => (
                  <li key={route.path}>
                    <Link to={route.path}>{route.title}</Link>
                  </li>
                ))}
              </ul>
            </Card>
          ))}
      </div>
    </div>
  );
}

export default Reports;
