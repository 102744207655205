const initialState = {
  ordersFetched: false,
  currentOrders: []
};

export default function getCurrentOrders(state = initialState, action) {
  switch (action.type) {
    case 'CURRENT_ORDERS_FULFILLED':
      return {
        ...state,
        ordersFetched: true,
        currentOrders: action.payload.data
      };
    case 'CURRENT_ORDERS_REJECTED':
      return {
        ...state,
        ordersFetched: false
      };
    case 'TOGGLE_VIEWED_FULFILLED':
      return {
        ...state,
        ordersFetched: true,
        currentOrders: action.payload.data
      };
    case 'TOGGLE_VIEWED_REJECTED':
      return {
        ...state,
        ordersFetched: false
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
