const initialState = {
  robData: []
};

export default function brands(state = initialState, action) {
  switch (action.type) {
    case 'ROB_API_CALL_FULFILLED':
      return {
        ...state,
        robData: action.payload.data
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
