import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import GlueButton from '../../Presentational/GlueButton.tsx';

const ConfirmationDialog = ({ onClose, open }) => {
  return (
    <Dialog onClose={() => onClose(false)} open={open}>
      <DialogTitle>
        <span role="img" aria-label="warning emoji">
          ⚠️
        </span>{' '}
        Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Once changes are saved, the carousel on the{' '}
          <a href="https://walts.com" target="_blank" rel="noopener noreferrer">
            live website
          </a>{' '}
          will be replaced with the one you created.
        </DialogContentText>
        <DialogContentText>Are you sure you want to continue?</DialogContentText>
      </DialogContent>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1em' }}
      >
        <GlueButton endIcon="done" onClick={() => onClose(true)} style={{ marginLeft: '0.5em' }}>
          Yes
        </GlueButton>
        <GlueButton
          endIcon="close"
          onClick={() => onClose(false)}
          style={{ marginLeft: '0.5em' }}
          variant="outlined"
        >
          No
        </GlueButton>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
