import React from 'react';
import { PickListProvider } from './PickListContext';
import PickListLayout from './PickListLayout';


export default function PickListContainer() {
  return (
    <PickListProvider>
      <PickListLayout />
    </PickListProvider>
  );
}
