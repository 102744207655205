import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import { ShippingTemplateContext } from '../shippingTemplate/shippingTemplateContext';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    padding: 15
  }
});

const useRuleMappingStyles = makeStyles({
  tableHeader: {
    '& td': {
      fontWeight: '700'
    }
  },
  tablePaper: {
    padding: 25
  }
});

export function ShippingTemplateRuleMappingsRow(props) {
  const classes = useStyles();
  const { id, ruleCode, shippingTemplate, refreshList } = props;
  const [modalChange, setModalChange] = useState(false);

  const [request, response] = useHttp('ims/shipping-templates/rule/mapping');

  function requestDelete() {
    setModalChange(true);
  }

  async function handleDelete() {
    await request.delete(`/${id}`);
    if (response.ok) {
      setModalChange(false);
      refreshList();
    }
  }

  return (
    <TableRow key={id}>
      <TableCell>{ruleCode}</TableCell>
      <TableCell>{shippingTemplate}</TableCell>
      <TableCell>
        <Button variant="contained" onClick={requestDelete}>
          Delete
        </Button>
        <Dialog classes={classes} open={modalChange} onClose={() => setModalChange(false)}>
          Are you sure you want to delete?
          <Button onClick={() => handleDelete()}>Yes</Button>
          <Button onClick={() => setModalChange(false)}>No</Button>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

export default function ShippingTemplateRuleMappings() {
  const { ruleMaps, getRuleMaps } = useContext(ShippingTemplateContext);
  const classes = useRuleMappingStyles();

  if (ruleMaps.length === 0) return <LinearProgress />;

  return (
    <Paper className={classes.tablePaper}>
      <Table>
        <thead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Rule Code</TableCell>
            <TableCell>Shipping Template</TableCell>
            <TableCell>Delete Rule Mapping</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {ruleMaps.map(row => (
            <ShippingTemplateRuleMappingsRow
              id={row.id}
              ruleCode={row.rule_code}
              shippingTemplate={row.shipping_template}
              refreshList={getRuleMaps}
              key={row.id}
            />
          ))}
        </tbody>
      </Table>
    </Paper>
  );
}
