import React, { useState, createContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

/* Import all Routes */
import Wpos3 from './Wpos3';
import Login, { AuthContext } from './AuthContext';
import Navigation from './Navigation';
import PrintScanningLabels from './components/reports/PrintScanningLabels';
import MessangingQueue from './components/MessagingQueue';
import DefaultScanEventSubscriptions from './components/DefaultScanEventSubscriptions';

import './App.css';

/* Checks if user is authorized, returns the component they wish to route or redirects to Login */

export const CommittedTransfersContext = createContext();
export const ApInvoicesContext = createContext({ setToUpdate: arg0 => {}, toUpdate: false });
const allFonts = [
  'Jost',
  'Open Sans',
  'sans-serif',
  'Open ',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
];
const headerFonts = allFonts.join(',');
const allOtherFonts = allFonts.filter(font => font !== 'Jost').join(',');

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#B1DBFF',
      main: '#0F4D7B',
      dark: '#3E6E9F',
      contrastText: '#000000'
    },
    secondary: {
      light: '#79D1D6',
      main: '#20B2BB',
      dark: '#008E97',
      contrastText: '#000000'
    },
    secondaryGreen: {
      light: '#70C769',
      main: '#4CB944',
      dark: '#47A840',
      contrastText: '#FFFFFF'
    },
    secondaryTurquoise: {
      light: '#79D1D6',
      main: '#20B2BB',
      dark: '#008E97',
      contrastText: '#000000'
    },
    secondaryOrange: {
      light: '#FF9333',
      main: '#FF7800',
      dark: '#E66C00',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FF9333',
      contrastText: '#000000'
    },
    error: {
      main: '#E66C00'
    },
    success: {
      light: '#70C769',
      main: '#4CB944',
      dark: '#47A840',
      contrastText: '#FFFFFF'
    },
    info: {
      light: '#79D1D6',
      main: '#20B2BB',
      dark: '#008E97',
      contrastText: '#000000'
    },
    text: {
      primary: '#333333',
      secondary: '#7D7D7D',
      disabled: '#9D9D9D'
    },
    action: {
      active: '#7D7D7D',
      hover: '#F5F5F5',
      selected: '#E7E7E7',
      disabled: '#BCBCBC',
      disabledBackground: '#E7E7E7'
    }
  },
  typography: {
    useNextVariants: true, // allows us to use component declaration on typography components
    fontSize: 12,
    fontFamily: allOtherFonts,
    h1: {
      fontFamily: headerFonts
    },
    h2: {
      fontFamily: headerFonts
    },
    h3: {
      fontFamily: headerFonts
    },
    h4: {
      fontFamily: headerFonts
    },
    h5: {
      fontFamily: headerFonts,
      color: '#3E6E9F'
    },
    h6: {
      fontFamily: headerFonts
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#ffffff'
      }
    }
  },
  misc: {
    successAlert: {
      backgroundColor: '#8dfd8080'
    },
    warningAlert: {
      backgroundColor: '#f6f7ae96'
    },
    errorAlert: {
      backgroundColor: '#f7aeae96'
    }
  }
});

function App() {
  const [count, setCount] = useState(0);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <DefaultScanEventSubscriptions />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
        className="App"
      >
        {/* Navigation Component that sits at the Top. */}
        <Navigation />

        {/* Loading Component that is connected to Redux store so we can easily
         * display loading gif via redux actions
         */}

        <div style={{ height: '100%', flex: 1 }} className="App-main">
          {/* Our Router, any path that is not within the Protected Routes is unAuthenticated */}
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/scan-labels" component={PrintScanningLabels} />
            <CommittedTransfersContext.Provider value={{ count, setCount }}>
              <AuthContext.Consumer>{value => value && <Wpos3 />}</AuthContext.Consumer>
            </CommittedTransfersContext.Provider>
          </Switch>
        </div>
        <MessangingQueue />
      </div>
    </MuiThemeProvider>
  );
}

export default App;
