import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

// TODO: combine this with the transactions in inventory/simulation/inventorySimulation
function InventoryTransactionType({ transaction }) {
  switch (transaction.transaction_type) {
    case 'INVENTORY_RECEIPT':
      return <InventoryReceiptLineItem transaction={transaction.transacted_receipt} />;
    case 'INVENTORY_TRANSFER':
      return <InventoryTransferLineItem transaction={transaction.transacted_transfer} />;
    case 'INVENTORY_SALE':
      return <InventorySaleLineItem transaction={transaction.transacted_sale} />;
    case 'INVENTORY_AUDIT_ADJUSTMENT':
      return <InventoryAuditLineItem transaction={transaction.transacted_audit_adjustment} />;
    default:
      return null;
  }
}

const useTransactionsLinkStyles = makeStyles({
  link: {
    textTransform: 'uppercase'
  }
});

const TransactionsLink = ({ children, referenceId }) => {
  const classes = useTransactionsLinkStyles();
  return (
    <MuiLink
      className={classes.link}
      component={Link}
      to={`/inventory-transactions/${referenceId}`}
      target="_blank"
    >
      {children}
    </MuiLink>
  );
};

function InventoryTransferLineItem({ transaction }) {
  const isLegacy = /legacy/i.test(
    `${transaction.pos_transfer_batch_name}${transaction.pos_transfer_batch_ref_id}`
  );

  const batchName = transaction.pos_transfer_batch_ref_id || transaction.pos_transfer_batch_name;

  const notes = transaction.transfer_notes || transaction.commit_notes;

  return (
    <>
      <TransactionsLink referenceId={transaction.ims_transaction_reference_id}>
        transferred
      </TransactionsLink>{' '}
      <strong>{transaction.transferred_quantity}</strong>:{' '}
      {transaction.from_bucket ||
        [transaction.from_location_code, transaction.from_condition_code].join(' / ')}{' '}
      --&gt;{' '}
      {transaction.to_bucket ||
        [transaction.to_location_code, transaction.to_condition_code].join(' / ')}
      , by:{' '}
      {isLegacy
        ? transaction.committed_by_name || transaction.committed_by_id
        : transaction.transferred_by_name || transaction.transferred_by_id}
      {batchName || notes ? (
        <strong>
          {batchName ? <>({batchName}) </> : null}
          {notes && notes !== batchName ? <>({notes})</> : null}
        </strong>
      ) : null}
    </>
  );
}

function InventoryReceiptLineItem({ transaction }) {
  const isLegacy = !!transaction.po_api_po_id;
  const purchaseOrderId = transaction.po_api_po_id || transaction.legacy_po_api_po_id;
  return (
    <>
      <TransactionsLink referenceId={transaction.ims_transaction_reference_id}>
        received
      </TransactionsLink>{' '}
      <strong>{transaction.received_quantity}</strong> onto{' '}
      {purchaseOrderId ? (
        <>
          {isLegacy ? 'legacy ' : ''}PO #
          <MuiLink
            component={Link}
            target="_blank"
            to={`/purchase-orders/review/${purchaseOrderId}${isLegacy ? '?legacy=true' : ''}`}
          >
            {purchaseOrderId}
          </MuiLink>{' '}
        </>
      ) : null}
      into{' '}
      {transaction.receiving_bucket ||
        `${transaction.receiving_location_code} / ${transaction.receiving_condition_code}`}
    </>
  );
}

function InventoryAuditLineItem({ transaction }) {
  const name =
    transaction.submitted_by_name ||
    transaction.submitted_by_id ||
    transaction.tallied_by_name ||
    transaction.tallied_by_id ||
    '';

  const serialRecord = transaction.serials[0];
  const notes = transaction.submit_notes || transaction.tally_notes;
  const auditType = (transaction.audit_type_code || '')
    .toUpperCase()
    .includes('FORCE_PUSH_SERIAL_CORRECTION')
    ? 'Force-Push'
    : transaction.audit_type_code;
  const serialChanges = ['location_code', 'condition_code', 'disposition_code']
    .filter(field => serialRecord[`old_${field}`] !== serialRecord[`new_${field}`])
    .map(field => {
      const oldValue = isEmpty(serialRecord[`old_${field}`]) ? 'N/A' : serialRecord[`old_${field}`];
      const newValue = serialRecord[`new_${field}`];
      return `${startCase(field)} ${oldValue} --> ${newValue}`;
    })
    .join(', ');

  return (
    <>
      <TransactionsLink referenceId={transaction.ims_transaction_reference_id}>
        audit-adjusted
      </TransactionsLink>{' '}
      ({auditType}) {serialChanges}, by: {name}
      {notes ? <strong> ({notes})</strong> : null}, initiated by transaction{' '}
      {transaction.ims_initiating_transaction_id ? (
        <MuiLink
          component={Link}
          target="_blank"
          to={`/inventory-transactions/${transaction.ims_initiating_transaction_id}`}
        >
          {transaction.ims_initiating_transaction_id}
        </MuiLink>
      ) : null}
    </>
  );
}

function InventorySaleLineItem({ transaction }) {
  return (
    <>
      <TransactionsLink referenceId={transaction.ims_transaction_reference_id}>
        invoiced
      </TransactionsLink>{' '}
      <strong>{transaction.invoiced_quantity}</strong> Finished and sourced as{' '}
      {transaction.source_bucket ||
        [transaction.source_location_code, transaction.source_condition_code].join(' / ')}
      {transaction.pos_work_order_id ? (
        ` ${transaction.pos_work_order_id}`
      ) : transaction.legacy_pos_invoice_id ? (
        <>
          , invoice #
          <MuiLink
            target="_blank"
            href={`https://wpos.walts.com/pos/review_invoice.php?invoice=${transaction.legacy_pos_invoice_id}`}
          >
            {transaction.legacy_pos_invoice_id}
          </MuiLink>
        </>
      ) : null}
    </>
  );
}

const useInventoryTransactionStyles = makeStyles(theme => ({
  cardTitle: {
    ...theme.typography.h5
  },
  container: {
    margin: theme.spacing(2, 0),
    minWidth: 300
  }
}));

function InventorySerialTransaction({ item }) {
  const classes = useInventoryTransactionStyles();
  return (
    <Card className={classes.container}>
      <CardHeader
        title={item.events_date}
        titleTypographyProps={{ className: classes.cardTitle }}
      />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Events
        </Typography>
        {item.transactions.map(transaction => (
          <Typography key={transaction.ims_transaction_reference_id}>
            {moment(transaction.transaction_completed_at).format('HH:mm A')} -{' '}
            <InventoryTransactionType transaction={transaction} />
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}

export default InventorySerialTransaction;
