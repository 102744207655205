import React, { useContext } from 'react';
import { createStyles, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { GlueTheme } from '../../../GlueThemeType';
import { BinManagementContext, computeBinName } from '../BinManagementContext';
import { HandleRowBayShelfChange, SetByName } from './CreateBinTypes';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    label: {
      fontWeight: 500,
      color: theme.palette.grey[900],
      letterSpacing: '0px'
    }
  })
);

export default function BinRowBayShelfInput() {
  const classes = useStyles();

  const { createBinErrors, setCreateBinErrors, setCreateBinData, createBinData } = useContext(
    BinManagementContext
  );

  const checkRowBayOrShelfAreValid = (value: any) => {
    if (value[0] === '0') {
      return false;
    }
    if (value.includes('.')) {
      return false;
    }
    value = +value;
    if (value < 0 || value > 99) {
      return false;
    } else {
      return true;
    }
  };

  const nameToSetMap: SetByName = {
    row: {
      set: (value: number) => {
        const newBinName = computeBinName(value, createBinData.bay, createBinData.shelf);
        setCreateBinData({
          ...createBinData,
          binName: newBinName,
          row: value
        });
        if (!checkRowBayOrShelfAreValid(value)) {
          setCreateBinErrors({
            ...createBinErrors,
            row: ['Row is invalid. ']
          });
        } else {
          const { row, binName, ...other } = createBinErrors;
          setCreateBinErrors({
            ...other
          });
        }
      }
    },
    bay: {
      set: (value: number) => {
        const newBinName = computeBinName(createBinData.row, value, createBinData.shelf);
        setCreateBinData({
          ...createBinData,
          binName: newBinName,
          bay: value
        });
        if (!checkRowBayOrShelfAreValid(value)) {
          setCreateBinErrors({
            ...createBinErrors,
            bay: ['Bay is invalid. ']
          });
        } else {
          const { bay, binName, ...other } = createBinErrors;
          setCreateBinErrors({
            ...other
          });
        }
      }
    },
    shelf: {
      set: (value: number) => {
        const newBinName = computeBinName(createBinData.row, createBinData.bay, value);
        setCreateBinData({
          ...createBinData,
          binName: newBinName,
          shelf: value
        });
        if (!checkRowBayOrShelfAreValid(value)) {
          setCreateBinErrors({
            ...createBinErrors,
            shelf: ['Shelf is invalid. ']
          });
        } else {
          const { shelf, binName, ...other } = createBinErrors;
          setCreateBinErrors({
            ...other
          });
        }
      }
    }
  };

  const setByTargetName = (name: keyof SetByName, value: number) => {
    if (nameToSetMap[name]) {
      nameToSetMap[name].set(value);
    }
  };

  const handleRowBayAndShelfChange: HandleRowBayShelfChange = (e: any) => {
    setByTargetName(e.target.name, e.target.value);
  };

  return (
    <Grid container justify={'space-between'} xs={12} spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.label} variant={'body1'}>
          Bin Row/Bay/Shelf:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          autoFocus
          type={'number'}
          aria-describedby="row-bay-shelf-helper-text"
          value={createBinData.row}
          onChange={handleRowBayAndShelfChange}
          error={!!createBinErrors.row}
          label="Bin Row"
          name="row"
          required
          id="bin-row"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          type={'number'}
          aria-describedby="row-bay-shelf-helper-text"
          value={createBinData.bay}
          onChange={handleRowBayAndShelfChange}
          error={!!createBinErrors.bay}
          label="Bin Bay"
          name="bay"
          required
          id="bin-bay"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          type={'number'}
          aria-describedby="row-bay-shelf-helper-text"
          value={createBinData.shelf}
          onChange={handleRowBayAndShelfChange}
          error={!!createBinErrors.shelf}
          label="Bin Shelf"
          name="shelf"
          required
          id="bin-shelf"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}
