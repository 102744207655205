import React, { Fragment, useContext, useEffect, useState, SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import { transform } from '../../helpers/helpers';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import { useHttp, useMessageDispatch } from '../../common/Hooks';
import {
  ApInvoiceSearch,
  useFinanceCompanies,
  useSearchableAccountsPayableInvoices,
  useVendorList
} from './hooks';
import OsposSelect from '../../common/LayoutComponents/Select';
import CreateForm from './AccountsPayableInvoiceCreationForm';
import { ApInvoicesContext } from '../../App';
import { Purchase } from './AttachedInvoices';
import { useDebounce } from 'use-debounce';
import { OptionTypeBase } from 'react-select';
import Grid from '@material-ui/core/Grid';
import { TableFooter } from '@material-ui/core';
import { check } from '../../roles/Can';
import { UserContext } from '../../roles/User';

interface AccountsPayableInvoice {
  id: number;
  invoice_number: number;
  invoice_amount: number;
  vendor: number | null;
  finance_company: number | null;
  purchase: Purchase;
}

function ApInvoiceSearchComponent({
  formData,
  updateForm,
  updateSelect
}: {
  formData: ApInvoiceSearch;
  updateForm: (e: SyntheticEvent) => void;
  updateSelect: (selectName: string, o: number | null) => void;
}) {
  const [vendors] = useVendorList();
  const [selectedVendor, setSelectedVendor] = useState('');
  const [financeCompanies] = useFinanceCompanies();
  const [selectedFc, setSelectedFc] = useState('');
  if (isEmpty(vendors) || isEmpty(financeCompanies)) return <h2>Loading</h2>;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <TextField
            name="invoiceNumber"
            onChange={e => updateForm(e)}
            value={formData.invoiceNumber}
            label="Invoice Number"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            name="invoiceAmount"
            onChange={e => updateForm(e)}
            value={formData.invoiceAmount}
            label="Invoice Amount"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <OsposSelect
            isDisabled={false}
            options={financeCompanies.data.map(
              (f: { company_name: string; id: number }) => f.company_name
            )}
            inputValue={selectedFc}
            handleInputChange={(e: { target: { value: string } }) => setSelectedFc(e.target.value)}
            name={'Finance Company'}
            error={null}
            helperText={'Select a Finance Company'}
            handleChange={(o: { target: { name: string; value: string } }) => {
              const financeCompany = financeCompanies.data.find(
                (x: { company_name: string; id: number }) => x.company_name == o.target.value
              );
              setSelectedFc(o.target.value);
              if (!isEmpty(financeCompany)) {
                updateSelect('financeCompany', financeCompany.id);
              } else {
                updateSelect('financeCompany', null);
              }
            }}
            label="Finance Company"
          />
        </Grid>
        <Grid item md={3}>
          <OsposSelect
            isDisabled={false}
            options={vendors.data.map((f: { company_name: string; id: number }) => f.company_name)}
            inputValue={selectedVendor}
            handleInputChange={(e: { target: { value: string } }) =>
              setSelectedVendor(e.target.value)
            }
            name="Vendor"
            error={null}
            helperText="Select a vendor"
            handleChange={(o: { target: { name: string; value: string } }) => {
              const vendor = vendors.data.find(
                (x: { company_name: string; id: number }) => x.company_name == o.target.value
              );
              setSelectedVendor(o.target.value);
              if (!isEmpty(vendor)) {
                updateSelect('vendor', vendor.id);
              } else {
                updateSelect('vendor', null);
              }
            }}
            label="Vendors"
          />
        </Grid>
      </Grid>
    </>
  );
}

function AccountsPayableInvoices({ purchaseOrder }: { purchaseOrder?: number }) {
  const [formData, setFormData] = useState({});
  const handleFormChange = (e: SyntheticEvent) => {
    const target = e.target as HTMLFormElement;
    setFormData(state => ({ ...state, [target.name]: target.value }));
  };

  const handleSelectChange = (selectName: string, option: number | null) => {
    setFormData(state => ({ ...state, [selectName]: option }));
  };
  const [debouncedFormData] = useDebounce(formData, 500);
  const [apInvoices, setApInvoicesToUpdate] = useSearchableAccountsPayableInvoices(formData);
  const [dispatch] = useMessageDispatch();
  const user: any = useContext(UserContext);
  const { toUpdate, setToUpdate } = useContext(ApInvoicesContext);
  const theme = useTheme();
  const [req, res] = useHttp('accounts-payable-invoice', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  useEffect(() => {
    if (toUpdate) {
      setApInvoicesToUpdate(true);
    }
  }, [setApInvoicesToUpdate, setToUpdate, toUpdate]);

  useEffect(() => {
    if (!isEmpty(debouncedFormData)) {
      setApInvoicesToUpdate(true);
    }
  }, [debouncedFormData]);

  async function poBase(
    invoiceId: number,
    purchaseOrderId: number | null | undefined,
    message: string
  ) {
    await req.post('/link-purchase-order', {
      id: invoiceId,
      purchase_order_id: purchaseOrderId
    });

    if (res.ok) {
      dispatch({ type: 'successes', message, status: res.status });
      setApInvoicesToUpdate(true);
      setToUpdate(true);
    }
  }

  function linkPo(invoiceId: number) {
    return poBase(invoiceId, purchaseOrder, 'PO Linked');
  }

  function unLinkPo(invoiceId: number) {
    return poBase(invoiceId, null, 'PO Un-Linked');
  }

  async function deleteInvoice(invoiceId: number) {
    await req.delete(`/${invoiceId}`);

    if (res.ok) {
      dispatch({ type: 'successes', message: 'Invoice Deleted', status: res.status });
      setApInvoicesToUpdate(true);
      setToUpdate(true);
    }
  }

  const [page, setPage] = useState(0);
  useEffect(() => setPage(0), [apInvoices]);
  function handlePageChange(event: unknown, page: number) {
    setPage(page);
  }

  return (
    <Paper style={{ padding: theme.spacing(2), marginTop: theme.spacing(2) }}>
      <ApInvoiceSearchComponent
        formData={formData}
        updateForm={handleFormChange}
        updateSelect={handleSelectChange}
      />
      {!isEmpty(apInvoices) && (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(apInvoices[0]).map(key => (
                  <TableCell key={key}>{transform(key)}</TableCell>
                ))}
                {purchaseOrder && <TableCell>Link PO?</TableCell>}
                {check(user.role, 'ap-invoices:delete') && <TableCell>Delete</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {apInvoices
                .slice(page * 10, page * 10 + 10)
                .map((invoice: AccountsPayableInvoice) => (
                  <TableRow key={invoice.id}>
                    {Object.entries(invoice).map(([key, value]) => (
                      <TableCell key={key}>
                        {value?.company_name || value?.purchase_ref_id || value}
                      </TableCell>
                    ))}
                    {purchaseOrder && (
                      <TableCell key="PO Link">
                        <Button
                          onClick={
                            invoice.purchase ? () => unLinkPo(invoice.id) : () => linkPo(invoice.id)
                          }
                          disabled={invoice.purchase && invoice.purchase?.id !== purchaseOrder}
                        >
                          {invoice.purchase !== null ? 'Un-Link PO' : 'Link PO'}
                        </Button>
                      </TableCell>
                    )}
                    {check(user.role, 'ap-invoices:delete') && (
                      <TableCell key="Delete Invoice">
                        <Button disabled={!isEmpty(invoice.purchase)} onClick={() => deleteInvoice(invoice.id)}>Delete</Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                page={page}
                count={apInvoices.length}
                rowsPerPageOptions={[10]}
                rowsPerPage={10}
                onChangePage={handlePageChange}
              />
            </TableFooter>
          </Table>
        </div>
      )}
      <CreateForm />
    </Paper>
  );
}

export default function ApInvoicesWithContext({ purchaseOrder }: { purchaseOrder?: number }) {
  const [toUpdate, setToUpdate] = useState(false);
  return (
    <ApInvoicesContext.Provider value={{ toUpdate, setToUpdate }}>
      <AccountsPayableInvoices purchaseOrder={purchaseOrder} />
    </ApInvoicesContext.Provider>
  );
}
