import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    margin: theme.spacing(2, 0)
  },
  cardContent: { paddingTop: 10 },
  cardHead: { paddingBottom: 0 }
}));

export default function IndividualYearOfBrandPurchasing(props) {
  const { dateRange, yearPurchaseData } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer}>
      <CardHeader title={dateRange} className={classes.cardHead} />
      <CardContent className={classes.cardContent}>
        {Object.entries(yearPurchaseData).map(([brand, purchaseValue]) => {
          return (
            <div key={brand}>
              <strong>{brand}</strong> Total Purchase Cost:{' '}
              <strong>${parseFloat(purchaseValue).toFixed(2)}</strong>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}
