import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Create, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../../common/Hooks';
import Can from '../../../roles/Can';
import CreateBucketGroupDialog from './CreateBucketGroup';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    padding: 15
  }
});

function BucketMapRow(props) {
  const classes = useStyles();
  const {
    id,
    bucket_group,
    should_count_unsourced_committed_in_group,
    group_inventory_buffer,
    walmart_fulfillment_center_id,
    amazon_supply_source_id,
    updateBucketGroup,
    getBuckets
  } = props;
  const [shouldCountUnsourcedCommittedStatus, setShouldCountUnsourcedCommittedStatus] = useState(
    Boolean(should_count_unsourced_committed_in_group)
  );
  const [groupInventoryBufferQty, setGroupInventoryBufferQty] = useState(group_inventory_buffer);
  const [walmartFulfillmentCenterId, setWalmartFulfillmentCenterId] = useState(
    walmart_fulfillment_center_id
  );
  const [amazonSupplySourceId, setAmazonSupplySourceId] = useState(amazon_supply_source_id);
  const [deleteModalChange, setDeleteModalChange] = useState(false);
  const [updateModalChange, setUpdateModalChange] = useState(false);
  const [highlightRowToUpdate, setHighlightrowToUpdate] = useState(false);
  const [request, response] = useHttp('ims/bucket-groups');

  useEffect(() => {
    if (
      shouldCountUnsourcedCommittedStatus !== Boolean(should_count_unsourced_committed_in_group) ||
      groupInventoryBufferQty !== group_inventory_buffer ||
      (walmartFulfillmentCenterId === '' ? null : walmartFulfillmentCenterId) !==
        walmart_fulfillment_center_id ||
      (amazonSupplySourceId === '' ? null : amazonSupplySourceId) !== amazon_supply_source_id
    ) {
      setHighlightrowToUpdate(true);
    } else setHighlightrowToUpdate(false);
  }, [
    shouldCountUnsourcedCommittedStatus,
    groupInventoryBufferQty,
    walmartFulfillmentCenterId,
    amazonSupplySourceId,
    should_count_unsourced_committed_in_group,
    group_inventory_buffer,
    walmart_fulfillment_center_id,
    amazon_supply_source_id
  ]);

  function requestDelete() {
    setDeleteModalChange(true);
  }

  function requestUpdate() {
    setUpdateModalChange(true);
  }

  async function handleDelete() {
    await request.delete(`/${id}`);
    if (response.ok) {
      setDeleteModalChange(false);
      getBuckets();
    }
  }

  async function handleBucketGroupUpdate() {
    updateBucketGroup(
      id,
      shouldCountUnsourcedCommittedStatus ? 1 : 0,
      groupInventoryBufferQty,
      walmartFulfillmentCenterId,
      amazonSupplySourceId
    );
    setHighlightrowToUpdate(false);
    setUpdateModalChange(false);
  }

  function handleUpdateShouldCount() {
    setShouldCountUnsourcedCommittedStatus(!shouldCountUnsourcedCommittedStatus);
  }

  function handleUpdateGroupInventoryBufferChange(event) {
    // eslint-disable-next-line radix
    setGroupInventoryBufferQty(parseInt(event.target.value));
  }

  function handleUpdateFulfillmentCenterId(event) {
    setWalmartFulfillmentCenterId(event.target.value);
  }

  function handleUpdateSupplySourceId(event) {
    setAmazonSupplySourceId(event.target.value);
  }

  return (
    <TableRow key={id} style={highlightRowToUpdate ? { backgroundColor: '#FFFFBF' } : null}>
      <TableCell>{bucket_group}</TableCell>
      <TableCell>
        <Checkbox
          color="primary"
          checked={shouldCountUnsourcedCommittedStatus}
          onChange={handleUpdateShouldCount}
        />
      </TableCell>
      <TableCell>
        <Input
          type="number"
          inputProps={{
            min: '0'
          }}
          value={groupInventoryBufferQty}
          onChange={handleUpdateGroupInventoryBufferChange}
        />
      </TableCell>
      <TableCell>
        <Input
          type="text"
          value={walmartFulfillmentCenterId || ''}
          onChange={handleUpdateFulfillmentCenterId}
        />
      </TableCell>
      <TableCell>
        <Input
          type="text"
          value={amazonSupplySourceId || ''}
          onChange={handleUpdateSupplySourceId}
        />
      </TableCell>
      <TableCell>
        <Can
          perform="bucket-buffer:edit"
          yes={() => (
            <Button variant="contained" onClick={requestUpdate} disabled={!highlightRowToUpdate}>
              Submit Updates
            </Button>
          )}
        />
        <Dialog
          classes={classes}
          open={updateModalChange}
          onClose={() => setUpdateModalChange(false)}
        >
          Are you sure you want to Update the highlighted row?
          <Button onClick={() => handleBucketGroupUpdate()}>Yes</Button>
          <Button onClick={() => setUpdateModalChange(false)}>No</Button>
        </Dialog>
      </TableCell>
      <TableCell>
        <Can
          perform="admin:override"
          yes={() => (
            <IconButton variant="contained" onClick={requestDelete}>
              <Delete />
            </IconButton>
          )}
        />
        <Dialog
          classes={classes}
          open={deleteModalChange}
          onClose={() => setDeleteModalChange(false)}
        >
          Are you sure you want to Delete? This action must be done in consultation with the dev
          team, so if you are unsure, please check with them first.
          <Button onClick={() => handleDelete()}>Yes</Button>
          <Button onClick={() => setDeleteModalChange(false)}>No</Button>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

export default function BucketMaps(props) {
  const { bucketGroups, updateBucketGroup, getBuckets } = props;
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  return (
    <Paper style={{ padding: 25 }}>
      <Button variant="contained" onClick={() => setOpenCreateDialog(true)}>
        <Create />
        Create Bucket Group
      </Button>
      <Table>
        <thead>
          <TableRow>
            <TableCell>Group Name</TableCell>
            <TableCell>Should Count Unsourced Committed?</TableCell>
            <TableCell>Group Buffer</TableCell>
            <TableCell>Walmart Fulfillment Center ID</TableCell>
            <TableCell>Amazon Supply Source ID</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </thead>
        <tbody>
          {bucketGroups.map(row => (
            <BucketMapRow
              updateBucketGroup={updateBucketGroup}
              getBuckets={getBuckets}
              {...row}
              key={row.id}
            />
          ))}
        </tbody>
      </Table>
      <CreateBucketGroupDialog
        open={openCreateDialog}
        handleClose={() => {
          setOpenCreateDialog(false);
          getBuckets();
        }}
      />
    </Paper>
  );
}
