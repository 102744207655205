import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import GlueTable from '../../Presentational/GlueTable.tsx';

const useStyles = makeStyles({
  columnWidth: {
    minWidth: '150px',
    maxWidth: '200px'
  }
});

export default function SelloutThreatTable({ itemsToReport }) {
  const classes = useStyles();
  const contentsTableColumns = [
    {
      columnName: 'Model',
      columnKey: 'model'
    },
    {
      columnName: 'Brand',
      columnKey: 'brand'
    },
    {
      columnName: 'Net Available Walts Warehouse',
      columnKey: 'net_available_walts_warehouses_quantity',
      className: classes.columnWidth
    },
    {
      columnName: 'Net Available FBA',
      columnKey: 'net_available_fba_quantity',
      className: classes.columnWidth
    }
  ];

  const contentsTableRows = !isEmpty(itemsToReport)
    ? itemsToReport.map(item => {
        return {
          key: item.model,
          data: {
            model: (
              <Link to={`/inventory/detail/model/${item.model}`} rel="noopener" target="_blank">
                {item.model}
              </Link>
            ),
            brand: item.brand,
            net_available_walts_warehouses_quantity: (
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={
                    item.net_available.walts_warehouses_quantity <= 10
                      ? { backgroundColor: '#F56262', fontWeight: 'bold', textAlign: 'center' }
                      : item.net_available.walts_warehouses_quantity <= 25
                      ? { backgroundColor: '#FFFF39', fontWeight: 'bold', textAlign: 'center' }
                      : { textAlign: 'center' }
                  }
                >
                  {item.net_available.walts_warehouses_quantity}
                </Grid>
              </Grid>
            ),
            net_available_fba_quantity: (
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  style={
                    item.net_available.fba_quantity <= 10
                      ? { backgroundColor: '#F56262', fontWeight: 'bold', textAlign: 'center' }
                      : item.net_available.fba_quantity <= 25
                      ? { backgroundColor: '#FFFF39', fontWeight: 'bold', textAlign: 'center' }
                      : { textAlign: 'center' }
                  }
                >
                  {item.net_available.fba_quantity}
                </Grid>
              </Grid>
            )
          }
        };
      })
    : {};
  // isErrors={/** */}
  return (
    <Paper>
      <GlueTable
        accessibleAriaLabel="sellout-threat-table"
        orderBy="model"
        sortDirection="asc"
        columns={contentsTableColumns}
        rows={contentsTableRows}
        excludePagination
      />
    </Paper>
  );
}
