import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CommittedTransferScanApp } from './CommittedTransferScanApp';
import { TransactTransferDialog } from './TransactTransferDialog';

export default function CommittedTransfers(props) {
  const {
    committedTransfers,
    updateTransfer,
    handleCommitmentChange,
    deleteCommitment,
    fetchCommittedTransfers,
    submitTransfer,
    transacting,
    transactResults,
    resetTransactResults,
    strictMode,
    setStrictMode
  } = props;

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [openScanDialog, setOpenScanDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openScanDialog}
        onClose={() => {
          setSelectedTransaction(null);
          setOpenScanDialog(false);
          fetchCommittedTransfers();
        }}
      >
        {selectedTransaction != null && (
          <CommittedTransferScanApp
            transactionRefId={selectedTransaction.ims_transaction_reference_id}
            transactionDetailsOverride={selectedTransaction}
          />
        )}
      </Dialog>
      <TransactTransferDialog
        committedTransfer={selectedTransaction}
        open={openSubmitDialog}
        submitTransfer={submitTransfer}
        submitting={transacting}
        transactResults={transactResults}
        resetTransactResults={resetTransactResults}
        strictMode={strictMode}
        setStrictMode={setStrictMode}
        onClose={() => {
          setSelectedTransaction(null);
          setOpenSubmitDialog(false);
          fetchCommittedTransfers();
          resetTransactResults(null);
          setStrictMode(true);
        }}
      />
      {committedTransfers.map((committedTransfer, i) => (
        <div
          key={committedTransfer.id}
          style={{
            border: '1px solid #ccc',
            padding: 15,
            marginBottom: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <form
              key={i}
              id={committedTransfer.ims_transaction_reference_id}
              onSubmit={updateTransfer}
            >
              <input
                type="hidden"
                name="ims_transaction_reference_id"
                value={committedTransfer.ims_transaction_reference_id}
              />
              <h3>
                <Link
                  to={`/inventory/detail/model/${committedTransfer.product.model}`}
                  style={{ color: 'black' }}
                >
                  {committedTransfer.product.product_title}
                </Link>
              </h3>
              <div>
                <label>Commitment Notes</label>
                <textarea
                  name="commit_notes"
                  rows="2"
                  cols="50"
                  value={
                    committedTransfer.commit_notes === null ? '' : committedTransfer.commit_notes
                  }
                  onChange={e => handleCommitmentChange(e, i)}
                />
              </div>
              <p>
                <b>{committedTransfer.committed_by_name}</b>&nbsp; committed &nbsp;
                <b>{committedTransfer.committed_quantity}</b>
                &nbsp;&nbsp;{committedTransfer.product.model} for transfer from &nbsp;
                <b>{committedTransfer.from_bucket}</b>&nbsp; to &nbsp;
                <b>{committedTransfer.to_bucket}</b>&nbsp; on &nbsp;
                <b>{committedTransfer.committed_at}</b>
              </p>
              <strong>
                {committedTransfer.total_scanned_quantity} / {committedTransfer.committed_quantity}{' '}
                Scanned
              </strong>
              <div>
                <label>Batch Reference ID</label>
                <input
                  type="text"
                  name="pos_transfer_batch_ref_id"
                  value={
                    committedTransfer.pos_transfer_batch_ref_id === null
                      ? ''
                      : committedTransfer.pos_transfer_batch_ref_id
                  }
                  onChange={e => handleCommitmentChange(e, i)}
                />
              </div>
              <div>
                <label>Batch Reference Name</label>
                <input
                  type="text"
                  name="pos_transfer_batch_name"
                  value={
                    committedTransfer.pos_transfer_batch_name === null
                      ? ''
                      : committedTransfer.pos_transfer_batch_name
                  }
                  onChange={e => handleCommitmentChange(e, i)}
                />
              </div>
              <div>
                <label>Sender SKU</label>
                <input
                  type="text"
                  name="sender_sku"
                  value={committedTransfer.sender_sku === null ? '' : committedTransfer.sender_sku}
                  onChange={e => handleCommitmentChange(e, i)}
                />
              </div>
              <div>
                <label>Receiver SKU</label>
                <input
                  type="text"
                  name="receiver_sku"
                  value={
                    committedTransfer.receiver_sku === null ? '' : committedTransfer.receiver_sku
                  }
                  onChange={e => handleCommitmentChange(e, i)}
                />
              </div>
              <br />
              <div>
                <button type="submit" name="submitBtn">
                  Update
                </button>
              </div>
            </form>
            <div>
              <button
                type="button"
                onClick={e => deleteCommitment(e, committedTransfer.ims_transaction_reference_id)}
                className="deleteBtn"
              >
                DELETE
              </button>
            </div>
          </div>
          <div>
            <div>
              <img
                src={committedTransfer.product.image_url}
                style={{ height: 200 }}
                alt={committedTransfer.product.product_title}
              />
            </div>
            <div>
              <Button
                variant="contained"
                style={{ marginTop: '6px' }}
                onClick={() => {
                  setSelectedTransaction(committedTransfer);
                  setOpenScanDialog(true);
                }}
              >
                Scan Transfer
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: '6px',
                  marginLeft: '4px'
                }}
                onClick={() => {
                  setSelectedTransaction(committedTransfer);
                  setOpenSubmitDialog(true);
                }}
              >
                Submit Transfer
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
