import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useHttp } from '../../../common/Hooks';
import Select from '../../../common/LayoutComponents/Select';

const useStyles = makeStyles(theme => ({
  paper: {
    // Fixes an overflow conflict between Material-UI and react-select
    // https://github.com/mui-org/material-ui/issues/743
    overflow: 'visible'
  },
  content: {
    // Fixes an overflow conflict between Material-UI and react-select
    // https://github.com/mui-org/material-ui/issues/7431
    overflow: 'initial',
    flexDirection: 'column',
    display: 'flex',
    marginBottom: theme.spacing(2)
  }
}));

// TODO: probably by this point bucket_groups are ubiqitous enough in
// our application to create a Redux for them.
const useGroupBucketOptions = () => {
  const [request, response] = useHttp('ims/'); // bucket-groups/, buckets/bucketsWithDistributor
  const [groupOptions, setGroupOptions] = useState([]);
  const [bucketOptions, setBucketOptions] = useState([]);

  useEffect(() => {
    async function getGroupNames() {
      const groupList = await request.get('bucket-groups/');
      if (response.ok) {
        const options = groupList.map(({ bucket_group }) => bucket_group);
        setGroupOptions(options);
      }
    }

    async function getBucketNames() {
      const bucketList = await request.get('buckets/bucketsWithDistributor');
      if (response.ok) {
        setBucketOptions(bucketList);
      }
    }

    getGroupNames();
    getBucketNames();
  }, []);

  return [groupOptions, bucketOptions];
};

const initialFormState = {
  bucketGroup: '',
  bucket: ''
};

export default function CreateBucketMapDialog({ open, handleClose }) {
  const classes = useStyles();
  const [request, response] = useHttp('ims/');
  const [groupOptions, bucketOptions] = useGroupBucketOptions();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [formState, setFormState] = useState({ ...initialFormState });
  const [errors, setErrors] = useState({});

  const handleFormInput = useCallback(({ target: { value, name } }) => {
    setErrors(previousErrorState => {
      const { [name]: removed, errors: deleted, ...errorState } = previousErrorState;
      return errorState;
    });
    setFormState(previousState => ({
      ...previousState,
      [name]: value
    }));
  }, []);

  const onClose = () => {
    setFormState({ ...initialFormState });
    setErrors({});
    handleClose();
  };

  async function submitNewBucketMapping() {
    const requestResult = await request.post('bucket-groups/mapping', formState);
    if (response.ok) {
      onClose();
    } else if (response.status === 422) {
      setErrors(requestResult);
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={onClose}
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle bold="true">New Bucket Group Mapping</DialogTitle>
      <DialogContent className={classes.content}>
        <Select
          label="Bucket Group"
          name="bucketGroup"
          inputValue={formState.bucketGroup}
          options={groupOptions}
          handleChange={handleFormInput}
          error={!!errors.bucketGroup}
          helperText={get(errors, 'bucketGroup', []).join('')}
        />
        <Select
          label="Bucket"
          name="bucket"
          inputValue={formState.bucket}
          options={bucketOptions}
          handleChange={handleFormInput}
          error={!!errors.bucket}
          helperText={get(errors, 'bucket', []).join('')}
        />
        <Collapse in={errors.errors} timeout="auto" unmountOnExit>
          <Alert severity="error">{errors.errors}</Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={submitNewBucketMapping}>
          Create
        </Button>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
