import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useGroupRowStyles = makeStyles({
  row: {
    backgroundColor: '#E7E7E7',
    fontWeight: 500
  }
});

function GroupRow({ index }) {
  const classes = useGroupRowStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell>Group {index + 1}</TableCell>
    </TableRow>
  );
}

function MissingTemplateRow({ simpleShippingTemplate }) {
  return (
    <TableRow>
      <TableCell>{simpleShippingTemplate}</TableCell>
    </TableRow>
  );
}

const useTableStyles = makeStyles({
  table: {
    marginTop: 24,
    maxHeight: 480
  },
  tableHead: {
    fontWeight: '700',
    backgroundColor: '#0f4D7B',
    color: '#FFFFFF'
  }
});

export default function MissingCompositions({ missingGroups }) {
  const classes = useTableStyles();

  const computeMissingGroupRows = missingCompositions => {
    const flattenedMissingGroups = [];
    if (missingCompositions) {
      missingCompositions.forEach((missingCompositionGroup, index) => {
        flattenedMissingGroups.push(<GroupRow key={index} index={index} />);
        missingCompositionGroup.forEach(simpleShippingTemplate => {
          flattenedMissingGroups.push(
            <MissingTemplateRow
              index={index}
              simpleShippingTemplate={simpleShippingTemplate}
              key={`${index}-${simpleShippingTemplate}`}
            />
          );
        });
      });
    }
    return flattenedMissingGroups;
  };

  const missingRows = useMemo(() => computeMissingGroupRows(missingGroups), [missingGroups]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table stickyHeader aria-label="missing composite groups table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              Missing Composite Shipping Template Groups
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{missingRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
