import isEmpty from 'lodash/isEmpty';

export function matchesUpc(arg, productData) {
  return !isEmpty(arg) && [productData.upc, productData.alternate_upc].includes(arg);
}

export function matchesPartNumber(arg, productData) {
  return (
    !isEmpty(arg) &&
    // Use both model and model_number in case productData comes from api-ospos DB product table instead of IMS.
    [productData.model, productData.model_number, productData.mpn].includes(arg.toUpperCase())
  );
}

export function validateSerial(serial, productData) {
  return (
    !isEmpty(serial) && !matchesPartNumber(serial, productData) && !matchesUpc(serial, productData)
  );
}
