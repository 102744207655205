import { useState, useEffect } from 'react';
import { useHttp } from '../../../common/Hooks';

export function useBrandList() {
  const [req, res] = useHttp('brand/all', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  const [brandList, setBrandList] = useState({});
  useEffect(() => {
    async function getBrands() {
      const data = await req.get();
      if (res.ok) {
        setBrandList(data.data);
      }
    }
    getBrands();
  }, []);

  return brandList;
}
