import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import BucketBufferRow from './BucketBufferRow';
import { useHttp } from '../../../common/Hooks';

export default function BucketBuffers() {
  const [buckets, setBuckets] = useState({});
  const [request, response] = useHttp('ims/buckets');

  function sendIsLocalInventoryBucket(bucket, isLocalInventoryBucket) {
    request.put('/updateIsLocalBucket', {
      bucket,
      isLocalInventoryBucket
    });
  }

  useEffect(() => {
    async function getBuckets() {
      const bucketsResponse = await request.get('?includeDistributor=1');
      if (response.ok && bucketsResponse) {
        setBuckets(bucketsResponse);
      }
    }

    getBuckets();
  }, []);

  if (request.loading && buckets.length === 0) return 'Loading...';
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bucket Name</TableCell>
            <TableCell>Is Local Inventory Bucket?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(buckets).map(([bucketName, options = {}]) => (
            <BucketBufferRow
              key={bucketName}
              bucketName={bucketName}
              sendIsLocalInventoryBucket={sendIsLocalInventoryBucket}
              isLocalInventoryBucket={options.is_local_inventory_bucket}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
