import React from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

var Barcode = require('react-barcode');

class ScanReports extends React.Component {
  constructor() {
    super();
    this.state = {
      date: ''
    };
  }

  componentDidMount() {
    this.setState({ date: moment().format('M-D-YY') });
  }

  render() {
    return (
      <div style={{ position: 'sticky', top: 0 }}>
        {/* <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', marginBottom:10}}>
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ width:100 }}
            value={this.props.model.model_number + ":=:" + this.props.model.upc + ":=:" + this.props.serial + ":=:" + this.props.stock}
          />
        </div> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginBottom: 15
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flexStart'
            }}
          >
            <span style={{ fontSize: 28 }}>{this.props.model.model_number}</span>
            <span style={{ fontSize: 28 }}>{this.props.stock.toUpperCase()} Stock</span>
          </div>
        </div>

        {this.props.serialBox !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left'
            }}
          >
            <span style={{ fontSize: 22 }}>Serial(Box):</span>
            <Barcode height={30} width={1} value={this.props.serialBox} />
          </div>
        ) : null}

        {this.props.serialTV !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left'
            }}
          >
            <span style={{ fontSize: 22 }}>Serial(TV):</span>
            <Barcode height={30} width={1} value={this.props.serialTV} />
          </div>
        ) : null}

        {this.props.upc !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              marginBottom: 15
            }}
          >
            <span style={{ fontSize: 22 }}>UPC:</span>
            <Barcode height={30} width={1} value={this.props.model.upc} />
          </div>
        ) : null}

        {this.props.invoice !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              marginBottom: 15
            }}
          >
            <span style={{ fontSize: 28 }}>Invoice/Removal #:</span>
            <span style={{ fontSize: 24 }}>{this.props.invoice}</span>
          </div>
        ) : null}

        {this.props.notes !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              marginBottom: 15
            }}
          >
            <span style={{ fontSize: 28 }}>Notes:</span>
            <span style={{ fontSize: 24 }}>{this.props.notes}</span>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginBottom: 15
          }}
        >
          <div>
            <span style={{ fontSize: 22 }}>Date: {this.state.date} Initial:___________</span>
          </div>
        </div>
      </div>
    );
  }
}

class ExamplePrint extends React.Component {
  render() {
    if (this.props.history.location.state === undefined) {
      return <div />;
    }

    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#0">Print this out!</a>}
          content={() => this.componentRef}
        />
        <ScanReports
          model={this.props.history.location.state.props}
          serialBox={this.props.history.location.state.serialBox}
          serialTV={this.props.history.location.state.serialTV}
          stock={this.props.history.location.state.stock}
          notes={this.props.history.location.state.notes}
          invoice={this.props.history.location.state.invoice}
          ref={el => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default ExamplePrint;
// export default WorkorderTabs;
