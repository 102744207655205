import React, { useState, useContext, useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import FormControl from '@material-ui/core/FormControl';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/styles';
import Select from '../../common/LayoutComponents/Select';
import { useHttp } from '../../common/Hooks';
import { ShippingTemplateContext } from './shippingTemplateContext';

const initialCreateRuleState = {
  ruleCode: '',
  fallbackShippingTemplate: '',
  groupShippingTemplate: ''
};

const useCreateRuleStyles = makeStyles({
  modalField: {
    minWidth: '100%'
  }
});

function CreateShippingRule({ setSubmit, isSelected, setDisableButton, setSubmissionState }) {
  const [shippingRuleState, setShippingRuleState] = useState(initialCreateRuleState);
  const [errors, setErrors] = useState({});

  const [request, response] = useHttp('ims/shipping-templates/rule');

  const { shippingTemplates } = useContext(ShippingTemplateContext);

  const { getRules } = useContext(ShippingTemplateContext);

  const shippingTemplateList = shippingTemplates.map(({ shipping_template }) => shipping_template);

  const submitCreateShippingRule = useCallback(async () => {
    setDisableButton(true);
    setSubmissionState({ isSubmitting: true, isSubmitted: false });
    const submitResponse = await request.post('', { ...shippingRuleState });
    if (response.ok) {
      setShippingRuleState(initialCreateRuleState);
      getRules();
      setSubmissionState({ isSubmitting: false, isSubmitted: true });
    } else if (response.status === 422) {
      setSubmissionState({ isSubmitting: false, isSubmitted: false });
      setErrors(submitResponse);
      setDisableButton(false);
    }
  }, [shippingRuleState, getRules]);

  useEffect(() => {
    if (isSelected) {
      setSubmit(() => submitCreateShippingRule);
    }
  }, [submitCreateShippingRule, setSubmit, isSelected]);

  useEffect(() => {
    if (isSelected) {
      setDisableButton(!isEmpty(errors));
    }
  }, [isSelected, setDisableButton, errors]);

  const handleChange = ({ target: { name, value } }) => {
    setErrors(previousErrorState => {
      const { [name]: removed, ...errorState } = previousErrorState;
      return errorState;
    });
    setShippingRuleState(previousState => ({
      ...previousState,
      [name]: value
    }));
  };

  const classes = useCreateRuleStyles();

  return (
    <List>
      <ListItem>
        <TextField
          label="Rule Code"
          name="ruleCode"
          value={shippingRuleState.ruleCode}
          onChange={handleChange}
          error={!!errors.ruleCode}
          helperText={get(errors, 'ruleCode', []).join('')}
          className={classes.modalField}
        />
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            label="Fallback Shipping Template"
            name="fallbackShippingTemplate"
            inputValue={shippingRuleState.fallbackShippingTemplate}
            handleChange={handleChange}
            options={shippingTemplateList}
            error={!!errors.fallbackShippingTemplate}
            helperText={get(errors, 'fallbackShippingTemplate', []).join('')}
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            label="Super Composite Shipping Template"
            name="groupShippingTemplate"
            inputValue={shippingRuleState.groupShippingTemplate}
            handleChange={handleChange}
            options={shippingTemplateList}
            error={!!errors.groupShippingTemplate}
            helperText={get(errors, 'groupShippingTemplate', []).join('')}
          />
        </FormControl>
      </ListItem>
    </List>
  );
}

export default CreateShippingRule;
