import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  FormControl,
  TextField,
  Button,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Warning } from '@material-ui/icons';
import startCase from 'lodash/startCase';
import PurchaseOrderForm from '../PurchaseOrderCreate/NewPurchaseOrder';
import Can from '../../roles/Can';
import { useSearchParams } from '../../common/Hooks';
import './form-styles.css';
import { GlueCopyToClipboardIconButton } from '../Presentational/GlueCopyToClipboardIconButton';

const paidOptions = [
  { value: '', label: 'All' },
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

export default function PurchaseOrderList(props) {
  const {
    role,
    createPO,
    setCreatePO,
    purchaseOrders,
    updatePoListOrder,
    changeSortOrder,
    orderKey,
    ascending,
    loading,
    statuses,
    vendors,
    shipToLocations,
    users,
    modelSearch
  } = props;

  const [search, setSearch] = useSearchParams();
  const dynamicCallbackFn = queryParam => e => setSearch(queryParam, e.target.value);
  const selectOnChangeHandler = queryParam => item => {
    if (item !== null) {
      if (queryParam === 'selectedUser') {
        setSearch(queryParam, item.label);
      } else {
        setSearch(queryParam, item.value);
      }
    } else setSearch(queryParam, null);
  };

  return (
    <Paper style={{ padding: '2rem' }}>
      <Can
        role={role}
        perform="purchase-order:create"
        yes={() => {
          return (
            <Button variant="outlined" onClick={() => setCreatePO(!createPO)}>
              {props.createPO ? 'Cancel' : 'Create PO'}
            </Button>
          );
        }}
      />
      {createPO && <PurchaseOrderForm />}
      <form className="form">
        <div className="form-element">
          <FormControl>
            <TextField
              label="From:"
              name="fromdate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={search.fromDate || ''}
              onChange={dynamicCallbackFn('fromDate')}
            />
          </FormControl>
        </div>
        <div className="form-element">
          <FormControl>
            <TextField
              label="To:"
              name="todate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              value={search.toDate || ''}
              onChange={dynamicCallbackFn('toDate')}
            />
          </FormControl>
        </div>
        <div className="form-element">
          <FormControl>
            <TextField
              label="PO Reference Number"
              name="poNumber"
              value={search.poNumber || ''}
              onChange={dynamicCallbackFn('poNumber')}
              type="text"
            />
          </FormControl>
        </div>
        <div className="form-element">
          <FormControl>
            <TextField
              label="Model Number"
              name="modelNumber"
              onChange={dynamicCallbackFn('modelNumber')}
              value={search.modelNumber || ''}
              type="text"
            />
          </FormControl>
        </div>
        <div className="form-element">
          <FormControl>
            <TextField
              label="Vendor Order ID"
              name="vendorOrderId"
              onChange={dynamicCallbackFn('vendorOrderId')}
              value={search.vendorOrderId || ''}
              type="text"
            />
          </FormControl>
        </div>
        <div className="select-form">
          <div className="form-element select">
            <label htmlFor="status">Status</label>
            <Select
              id="status"
              options={statuses}
              value={statuses.find(v => v.value === +search.selectedStatus)}
              onChange={selectOnChangeHandler('selectedStatus')}
              isClearable
            />
          </div>
          <div className="form-element select">
            <label htmlFor="vendor">Vendor</label>
            <Select
              id="vendor"
              options={vendors}
              value={vendors.find(v => v.value === +search.selectedVendor)}
              onChange={selectOnChangeHandler('selectedVendor')}
              isClearable
            />
          </div>
          <div className="form-element select">
            <label htmlFor="ship-to">Ship To</label>
            <Select
              id="ship-to"
              options={shipToLocations}
              value={shipToLocations.find(v => v.value === search.selectedShipToLocation)}
              onChange={selectOnChangeHandler('selectedShipToLocation')}
              isClearable
            />
          </div>
          <div className="form-element select">
            <label htmlFor="user">User</label>
            <Select
              id="user"
              options={users}
              value={users.find(v => v.label === search.selectedUser)}
              onChange={selectOnChangeHandler('selectedUser')}
              isClearable
            />
          </div>
          <div className="form-element select">
            <label htmlFor="paid">Paid</label>
            <Select
              id="paid"
              options={paidOptions}
              value={paidOptions.find(v => v.value === search.paid) || { value: '', label: 'All' }}
              onChange={selectOnChangeHandler('paid')}
            />
          </div>
        </div>
      </form>
      {modelSearch && !loading ? (
        <POModels
          modelNumber={search.modelNumber}
          purchaseOrders={purchaseOrders}
          ascending={ascending}
          orderKey={orderKey}
          updatePoListOrder={updatePoListOrder}
          changeSortOrder={changeSortOrder}
        />
      ) : (
        <POTable
          updatePoListOrder={updatePoListOrder}
          changeSortOrder={changeSortOrder}
          ascending={ascending}
          loading={loading}
          purchaseOrders={purchaseOrders}
          orderKey={orderKey}
        />
      )}
    </Paper>
  );
}

const usePoTableStyles = makeStyles(theme => ({
  highlight: {
    color: theme.palette.primary.main
  }
}));

function POModels(props) {
  const { modelNumber, purchaseOrders, updatePoListOrder, orderKey } = props;
  const classes = usePoTableStyles();

  if (purchaseOrders.length > 0 && purchaseOrders[0].items !== undefined) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => updatePoListOrder('purchase_ref_id')}
                className={orderKey === 'purchase_ref_id' && classes.highlight}
              >
                Purchase Order ID
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => updatePoListOrder('created_at')}
                className={orderKey === 'created_at' && classes.highlight}
              >
                Created At
              </Button>
            </TableCell>
            <TableCell>Model Number</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Invoice Cost</TableCell>
            <TableCell>QTY Ordered</TableCell>
            <TableCell>QTY Received</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {purchaseOrders.map(
          po =>
            po.items.length > 0 &&
            po.items
              .filter(item => item.product.model_number === modelNumber)
              .map(poItem => (
                <TableRow key={poItem.id}>
                  <TableCell>{po.purchase_ref_id}</TableCell>
                  <TableCell>{po.created_at}</TableCell>
                  <TableCell>{poItem.product.model_number}</TableCell>
                  <TableCell>{po.ims_location_code}</TableCell>
                  <TableCell>{poItem.invoice_cost}</TableCell>
                  <TableCell>{poItem.qty_ordered}</TableCell>
                  <TableCell>{poItem.qty_received}</TableCell>
                  <TableCell>
                    <Link to={`/purchase-orders/review/${po.id}`}>View</Link>
                  </TableCell>
                </TableRow>
              ))
        )}
      </Table>
    );
  }
  return <h2>No Results</h2>;
}

function POTable(props) {
  const {
    purchaseOrders,
    loading,
    updatePoListOrder,
    changeSortOrder,
    orderKey,
    ascending
  } = props;
  const [page, setPage] = useState(() => {
    const saved = localStorage.getItem('poPage');
    const initialValue = JSON.parse(saved);
    return initialValue || 0;
  });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const classes = usePoTableStyles();

  function handleChangePage(_, newPage) {
    localStorage.setItem('poPage', newPage);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  if (purchaseOrders.length === 0) {
    return <h2>No results</h2>;
  }
  if (!loading && purchaseOrders.length > 0) {
    return (
      <div>
        <div style={{ overflow: 'scroll' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Button
                    onClick={() => updatePoListOrder('purchase_ref_id')}
                    className={orderKey === 'purchase_ref_id' && classes.highlight}
                  >
                    PO Reference ID
                  </Button>
                </TableCell>
                {Object.entries(purchaseOrders[0]).map(cell => {
                  if (
                    cell[0] === 'id' ||
                    cell[0] === 'purchase_ref_id' ||
                    cell[0] === 'legacy_po_number'
                  ) {
                    return '';
                  }
                  if (cell[0] === 'ims_location_code') {
                    return (
                      <TableCell key="IMS">
                        <Button
                          onClick={() => updatePoListOrder(cell[0])}
                          className={orderKey === cell[0] && classes.highlight}
                        >
                          Ship To
                        </Button>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={cell[0]}>
                      <Button
                        onClick={() => updatePoListOrder(cell[0])}
                        className={classes.highlight}
                      >
                        {startCase(cell[0].replace('_', ' '))}
                      </Button>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Button onClick={changeSortOrder}>
                    Change Sort Order {ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(purchaseOrder => {
                  return (
                    <TableRow key={purchaseOrder.id}>
                      <TableCell>
                        <GlueCopyToClipboardIconButton>
                          {purchaseOrder.purchase_ref_id}
                        </GlueCopyToClipboardIconButton>
                        {!purchaseOrder.purchase_ref_id.includes('W') &&
                          !purchaseOrder.legacy_po_number && (
                            <Tooltip
                              title={
                                <>
                                  <h2>PO Number Collision</h2>
                                  <Typography>
                                    This PO has a duplicate PO number as it was created in the new
                                    system.
                                  </Typography>
                                </>
                              }
                            >
                              <Warning color="error" style={{ marginLeft: 10 }} />
                            </Tooltip>
                          )}
                      </TableCell>
                      {Object.entries(purchaseOrder).map(po => {
                        if (
                          po[0] !== 'id' &&
                          po[0] !== 'purchase_ref_id' &&
                          po[0] !== 'legacy_po_number'
                        ) {
                          return <TableCell key={po[0]}>{po[1]}</TableCell>;
                        }
                        return null;
                      })}
                      <TableCell>
                        <Link to={`/purchase-orders/review/${purchaseOrder.id}`}>View</Link>{' '}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={purchaseOrders.length}
          page={page}
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    );
  }
  return null;
}
