import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';

type CollapseAlertProps = {
  open?: boolean;
  children: React.ReactNode;
};

export default function CollapseAlert({ open, children }: CollapseAlertProps) {
  return (
    <Collapse in={open}>
      <Alert severity="success">{children}</Alert>
    </Collapse>
  );
}
