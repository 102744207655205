import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import * as contactActions from '../../actions/contactActions';
import * as loadingActions from '../../actions/loadingActions';

export class ContactsComponent extends Component {
  state = {
    loading: false
  };

  searchForContacts = (searchBy, searchValue, firstName, lastName) => {
    this.props.loadingActions.loading(true, 'Searching for Contacts');
    const auth = localStorage.getItem('access');

    if (searchBy === 'name') {
      this.props.contactActions.searchContactsByName(firstName, lastName, auth).then(() => {
        this.props.loadingActions.loading(false, '');
      });
    } else {
      this.props.contactActions.contactSearch(searchValue, searchBy, auth).then(() => {
        this.props.loadingActions.loading(false, '');
      });
    }
  };

  goToContactHistory = contact => {
    this.props.loadingActions.loading(true, 'Loading Contact History');
    const auth = localStorage.getItem('access');
    const path = '/contacts/details';
    this.props.contactActions.contactGet(auth, contact.id, 'id');
    this.props.contactActions.contactHistory(contact.id, 'sold_contact_id', auth).then(() => {
      this.props.loadingActions.loading(false, '');
      this.props.history.push(path);
    });
  };

  render() {
    const { loading, searchResults } = this.props; // Getting Order Types from Redux store, same for Sales People.
    return (
      <>
        <Typography align="center" variant="h3" gutterBottom>
          Contacts
        </Typography>
        <SimpleSelect onSubmit={this.searchForContacts} />
        <div style={{ display: 'flex', justifyContent: 'center', margin: 5 }}>
          <Button
            style={{ height: '20px', marginTop: 14 }}
            color="primary"
            variant="contained"
            onClick={() => this.props.history.push('/contacts/create')}
          >
            Create New Customer
          </Button>
        </div>
        {loading ? null : (
          <SimpleResults results={searchResults} handleClick={this.goToContactHistory} />
        )}
      </>
    );
  } // end render function
} // end class Contacts

export default connect(
  state => ({
    searchResults: state.contacts.contactsSearched,
    loading: state.loading.loading
  }),
  dispatch => ({
    contactActions: bindActionCreators(contactActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch)
  })
)(ContactsComponent);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    margin: 5,
    minWidth: 120
  }
}));

function SimpleSelect({ onSubmit }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    searchBy: 'name',
    searchValue: '',
    firstName: '',
    lastName: ''
  });

  function handleChange(event) {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  }

  function handleChangeSearchBy(event) {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      searchValue: ''
    });
  }

  function onKeyPress(event) {
    if (event.charCode === 13) {
      // enter key pressed
      event.preventDefault();
      onSubmit(state.searchBy, state.searchValue, state.firstName, state.lastName); // fire handle submit button
    }
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <FormControl className={classes.formControl}>
        {state.searchBy === 'name' ? (
          <div>
            <TextField
              label="First Name"
              className={classes.textField}
              value={state.firstName}
              onChange={handleChange}
              onKeyPress={onKeyPress}
              // margin="normal"
              inputProps={{
                name: 'firstName',
                id: 'firstName-simple'
              }}
            />

            <TextField
              label="Last Name"
              className={classes.textField}
              value={state.lastName}
              onChange={handleChange}
              onKeyPress={onKeyPress}
              // margin="normal"
              inputProps={{
                name: 'lastName',
                id: 'lastName-simple'
              }}
            />
          </div>
        ) : null}

        {state.searchBy === 'address' || state.searchBy === 'primary_phone' ? (
          <TextField
            label={state.searchBy}
            className={classes.textField}
            value={state.searchValue}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            // margin="normal"
            inputProps={{
              name: 'searchValue',
              id: 'searchValue-simple'
            }}
          />
        ) : null}
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="searchBy-simple">Search By</InputLabel>
        <Select
          value={state.searchBy}
          onChange={handleChangeSearchBy}
          inputProps={{
            name: 'searchBy',
            id: 'searchBy-simple'
          }}
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="address">Address</MenuItem>
          <MenuItem value="primary_phone">Phone Number</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={{ height: '30px', marginTop: 14 }}
        variant="contained"
        color="primary"
        onClick={() => onSubmit(state.searchBy, state.searchValue, state.firstName, state.lastName)}
      >
        Search Contacts
      </Button>
    </div>
  );
}

function SimpleResults({ results, handleClick }) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <List>
        {results.map((r, i) => (
          <ListItem key={r.id} button divider onClick={() => handleClick(r)}>
            <Result contact={r} />
          </ListItem>
        ))}
      </List>
      {results.length <= 0 ? <h1>No Results</h1> : null}
    </div>
  );
}

function Result({ contact }) {
  const {
    id,
    first_name,
    last_name,
    address,
    address2,
    city,
    state,
    zip,
    primary_email,
    primary_phone
  } = contact;
  return (
    <div key={id}>
      <ListItemText
        style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        primary={
          <div>
            <Typography align="left" component="h2" variant="headline" gutterBottom>
              {`${first_name} ${last_name}`}
            </Typography>

            <Typography align="left" variant="body2" gutterBottom>
              Address: {address}
              {address2 === null ? '' : ` ${address2}`}, {city}, {state}, {zip}
            </Typography>

            <Typography align="left" variant="body2" gutterBottom>
              Phone: {primary_phone}
            </Typography>

            <Typography align="left" variant="body2" gutterBottom>
              Email: {primary_email}
            </Typography>
          </div>
        }
      />
    </div>
  );
}
