import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import BucketMaps from './BucketGroups';
import { useHttp } from '../../../common/Hooks';

export default function BucketMapsContainer() {
  const [request, response] = useHttp('ims/bucket-groups');
  const [bucketGroups, setBucketGroups] = useState([]);

  async function updateBucketGroup(
    id,
    shouldCountUnsourcedCommittedStatus,
    groupInventoryBufferQty,
    walmartFulfillmentCenterId,
    amazonSupplySourceId
  ) {
    request.put(`/${id}`, {
      shouldCountUnsourcedCommittedStatus,
      groupInventoryBufferQty,
      walmartFulfillmentCenterId: isEmpty(walmartFulfillmentCenterId)
        ? null
        : walmartFulfillmentCenterId,
      amazonSupplySourceId: isEmpty(amazonSupplySourceId) ? null : amazonSupplySourceId
    });
  }

  async function getBuckets() {
    const data = await request.get('/');
    if (response.ok) {
      setBucketGroups(data);
    }
  }

  useEffect(() => {
    getBuckets();
  }, []);

  if (request.loading && bucketGroups.length === 0) return 'Loading...';
  return (
    <BucketMaps
      bucketGroups={bucketGroups}
      updateBucketGroup={updateBucketGroup}
      getBuckets={getBuckets}
    />
  );
}
