import React, { useEffect, useState, useCallback, useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import isEmpty from 'lodash/isEmpty';
import TableView from '../../common/LayoutComponents/Table';
import { useHttp } from '../../common/Hooks';
import Results from '../../common/LayoutComponents/ResultsView';

const useDistributorInventory = () => {
  const [distributorInventory, setDistributorInventory] = useState({});
  const [request, response] = useHttp('ims/drop-ship-vendor-inventory/product');

  const fetchDistributorInventory = useCallback(async model => {
    const inventory = await request.get(`?model=${model}`);
    setDistributorInventory(response.ok ? inventory : {});
  }, []);

  return [distributorInventory, fetchDistributorInventory];
};

function DistributorInventoryView({ model }) {
  const [distributorInventory, fetchDistributorInventory] = useDistributorInventory();

  useEffect(() => {
    if (!isEmpty(model)) {
      fetchDistributorInventory(model);
    }
  }, [model, fetchDistributorInventory]);

  const totalInventoryQuantity = useMemo(
    () =>
      Object.values(distributorInventory).reduce(
        (acc, { qty_purchasable }) =>
          Number.isNaN(qty_purchasable) ? acc : acc + parseInt(qty_purchasable, 10),
        0
      ),
    [distributorInventory]
  );

  const buckets = useMemo(() => Object.keys(distributorInventory), [distributorInventory]);
  const fields = useMemo(() => {
    if (buckets.length > 0) {
      return Object.keys(distributorInventory[buckets[0]]);
    }
    return [];
  }, [distributorInventory, buckets]);
  const headers = useMemo(() => fields.map(header => header.replace(/_/g, ' ')), [fields]);

  return (
    <Results id="inventory-details-distributor-inventory">
      <h2>Distributor Inventory ({totalInventoryQuantity})</h2>
      <TableView
        header={['Vendor Location Code', ...headers]}
        tableProps={{
          size: 'small'
        }}
        tableHeaderCellProps={{
          style: {
            fontSize: '1.1em'
          }
        }}
      >
        {Object.entries(distributorInventory).map(([vendorLocationCode, inventoryData]) => (
          <TableRow key={vendorLocationCode}>
            <TableCell key="vendorLocationCode">{vendorLocationCode}</TableCell>
            {fields.map(field => (
              <TableCell key={field}>{inventoryData[field]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableView>
    </Results>
  );
}

export default DistributorInventoryView;
