import React, { useState } from 'react';
import Select from 'react-select';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Snackbar, TableContainer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { useHttp } from '../../../common/Hooks';

const useShippingTemplateDisplayStyles = makeStyles({
  tableHeader: {
    '& td': {
      fontWeight: '700'
    }
  },
  tablePaper: {
    padding: 25
  }
});

function ShippingTemplateRow(props) {
  const {
    shipping_template,
    bucketGroupOptions,
    getShippingTemplates,
    platforms,
    switch_buffer,
    bucket_group,
    is_parent_template,
    platform_code,
    platform_shipping_template_id,
    setPlatformError
  } = props;
  const [request, response] = useHttp('ims/shipping-templates');
  const [switchBufferNum, setSwitchBufferNum] = useState(switch_buffer);
  const [bucketGroupName, setBucketGroupName] = useState(bucket_group);
  const [selectedPlatformCode, setSelectedPlatformCode] = useState(platform_code || '');
  const [platformShippingTemplateId, setPlatformShippingTemplateId] = useState(
    platform_shipping_template_id
  );
  const [checked, setChecked] = useState(is_parent_template === 1);
  const [success, setSuccess] = useState(false);

  const platformCodeMaps = platforms.reduce(
    (carry, platform) => ({
      ...carry,
      [platform.platform_code]: platform
    }),
    {}
  );
  const platformOptions = platforms.map(platform => ({
    label: platform.platform_name,
    value: platform.platform_code
  }));

  async function handleUpdateShippingTemplate() {
    if (selectedPlatformCode === '') {
      setPlatformError('Enter a platform!');
      return;
    }
    const sendup = {
      shippingTemplate: shipping_template,
      switchBuffer: switchBufferNum,
      bucketGroup: bucketGroupName,
      isParentTemplate: checked,
      platformCode: selectedPlatformCode,
      platformShippingTemplateId
    };
    await request.put('', sendup);
    if (response.ok) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    getShippingTemplates();
  }

  function handleUpdateSwitchBuffer(event) {
    setSwitchBufferNum(event.target.value);
  }

  function handleUpdateBucketGroup(event) {
    setBucketGroupName(event.value);
  }

  function handleUpdateParentTemplate() {
    setChecked(!checked);
  }

  function handleUpdatePlatform(event) {
    const platformValue = event.value;
    setSelectedPlatformCode(platformValue);
  }

  function handleUpdatePlatformShippingTemplateId(event) {
    setPlatformShippingTemplateId(event.target.value);
  }

  return (
    <TableRow key={shipping_template}>
      <TableCell>{shipping_template}</TableCell>
      <TableCell>
        <Input
          name="switchBuffer"
          type="number"
          style={{ maxWidth: '3em' }}
          inputProps={{
            min: '0'
          }}
          value={switchBufferNum || ''}
          onChange={handleUpdateSwitchBuffer}
        />
      </TableCell>
      <TableCell style={{ minWidth: '22em' }}>
        <Select
          name="bucketGroup"
          options={bucketGroupOptions}
          defaultValue={{ label: bucketGroupName, value: bucketGroupName || '' }}
          onChange={handleUpdateBucketGroup}
          isDisabled={
            platformCodeMaps[platform_code]
              ? platformCodeMaps[platform_code].uses_default_bucket_group
              : false
          }
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="isParentTemplate"
          style={{ minWidth: '2em' }}
          color="primary"
          checked={checked}
          onChange={handleUpdateParentTemplate}
        />
      </TableCell>
      <TableCell style={{ minWidth: '16em' }}>
        <Select
          name="platform"
          options={platformOptions}
          defaultValue={{
            value: selectedPlatformCode,
            label: platformCodeMaps[selectedPlatformCode]
              ? platformCodeMaps[selectedPlatformCode].platform_name || ''
              : ''
          }}
          onChange={handleUpdatePlatform}
        />
      </TableCell>
      <TableCell>
        <Input
          name="platformShippingTemplateId"
          type="text"
          style={{ width: '12em' }}
          value={platformShippingTemplateId || ''}
          onChange={handleUpdatePlatformShippingTemplateId}
        />
      </TableCell>
      <TableCell>
        <Button
          type="submit"
          variant="contained"
          onClick={handleUpdateShippingTemplate}
          style={{ background: success ? 'lightGreen' : 'lightGray' }}
        >
          Submit
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default function ShippingTemplateMaps(props) {
  const { getShippingTemplates, shippingTemplates, bucketGroupOptions, platforms } = props;

  const classes = useShippingTemplateDisplayStyles();
  const [platformError, setPlatformError] = useState('');

  const handleClose = () => {
    if (platformError.length > 0) {
      setPlatformError('');
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <thead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Template Name</TableCell>
            <TableCell>Switch Buffer</TableCell>
            <TableCell>Bucket Group</TableCell>
            <TableCell>Is Composite Template?</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell>Platform Shipping Template ID</TableCell>
            <TableCell>Submit Row Changes</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {shippingTemplates &&
            shippingTemplates.map(row => (
              <ShippingTemplateRow
                getShippingTemplates={getShippingTemplates}
                bucketGroupOptions={bucketGroupOptions}
                setPlatformError={setPlatformError}
                platforms={platforms}
                {...row}
                key={row.shipping_template}
              />
            ))}
        </tbody>
      </Table>
      <Snackbar
        open={platformError.length > 0}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPlatformError('');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {platformError}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
