const initialState = {
  contactHistory: [],
  contactHistoryFulfilled: false,
  contactsSearched: [],
  contact: [],
  contactCreateFulfilled: false,
  contactDetailsFetched: false,
  contactGetFulfilled: false,
  contactInfoMessage: '',
  contactsFetched: false
};

export default function contacts(state = initialState, action) {
  switch (action.type) {
    case 'CONTACT_SEARCH_FULFILLED':
      return {
        ...state,
        contactsSearched: action.payload,
        contactsFetched: true
      };
    case 'CONTACT_SEARCH_REJECTED':
      return {
        ...state,
        contactsSearched: action.payload,
        contactsFetched: false
      };
    case 'CONTACT_DETAILS':
      return {
        ...state,
        contact: action.payload,
        contactDetailsFetched: true
      };
    case 'CONTACT_UPDATE_FULFILLED':
      return {
        ...state,
        contact: action.payload,
        contactDetailsFetched: true,
        contactInfoMessage: 'Contact Info Updated!'
      };
    case 'CONTACT_UPDATE_REJECTED':
      return {
        ...state,
        contactDetailsFetched: true,
        contactInfoMessage: 'Contact Update Failed!'
      };
    case 'CONTACT_CREATE_FULFILLED':
      return {
        ...state,
        contact: action.payload,
        contactCreateFulfilled: true,
        contactDetailsFetched: true,
        contactHistoryFulfilled: false,
        contactHistory: [],
        contactInfoMessage: 'Contact Created!'
      };
    case 'CONTACT_CREATE_REJECTED':
      return {
        ...state,
        contact: action.payload,
        contactCreateFulfilled: false,
        contactInfoMessage: 'Contact Create Failed!'
      };
    case 'CONTACT_HISTORY_FULFILLED':
      return {
        ...state,
        contactHistory: action.payload,
        contactHistoryFulfilled: true
      };
    case 'CONTACT_HISTORY_REJECTED':
      return {
        ...state,
        contactHistory: action.payload,
        contactHistoryFulfilled: false
      };
    case 'CONTACT_GET_FULFILLED':
      return {
        ...state,
        contact: action.payload,
        contactGetFulfilled: true
      };
    case 'CONTACT_GET_REJECTED':
      return {
        ...state,
        contact: action.payload,
        contactInfoMessage: 'Failed To Retrieve Contact Details!',
        contactGetFulfilled: false
      };
    case 'STORE_RESET':
      return {
        ...state,
        contactHistory: [],
        contactGetFulfilled: false,
        contactHistoryFulfilled: false,
        contactsSearched: [],
        contact: [],
        contactCreateFulfilled: false,
        contactsFetched: false,
        contactDetailsFetched: false
      };
    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
}
