import React, { useState, useEffect, useContext } from 'react';
import { useDebounce } from 'use-debounce';
import AuditAdjustments from './AuditAdjustments';

import { useHttp } from '../../../common/Hooks';
import { AuthContext } from '../../../AuthContext';

export default function AuditAdjustmentsContainer() {
  const username = useContext(AuthContext);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  const [modelTerm, setModelTerm] = useState('');
  const [debouncedModelTerm] = useDebounce(modelTerm, 500);
  const [modelBuckets, setBuckets] = useState([]);
  const [request, response, auth] = useHttp('');
  const [selectedBucket, setSelectedBucket] = useState('');
  const [notes, setNotes] = useState('');
  const [newQty, setNewQty] = useState(0);
  const [allBuckets, setAllBuckets] = useState([]);
  const [zeroBucket, setZeroBucket] = useState(false);
  const [selectedZeroBucket, setSelectedZeroBucket] = useState('');
  const [newZeroValue, setNewZeroValue] = useState(0);
  const [zeroNotes, setZeroNotes] = useState('');
  const [previousAuditAdjustments, setPreviousAuditAdjustments] = useState([]);

  useEffect(() => {
    if (auth) {
      getModels();
      getAllThirdPartyBuckets();
    }
  }, [auth]);

  /*
   *useEffect(() => {
   *  if (selectedModel) {
   *    getBucketsForModel(selectedModel);
   *  } else {
   *    setBuckets("");
   *    setModelTerm("");
   *    getModels();
   *  }
   *}, [selectedModel]);
   */

  async function getBucketsForModel() {
    const bucketsForModel = await request.get(`ims/audit-adjustments?model=${selectedModel}`);
    if (response.ok) {
      setBuckets(bucketsForModel.inventory);
      setModelSearch(selectedModel);
    }
  }

  async function getModels() {
    const modelsData = await request.get('product');
    if (response.ok) {
      setModels(modelsData.data);
    }
  }

  async function getAllThirdPartyBuckets() {
    const allThirdParty = await request.get('ims/audit-adjustments/buckets');
    if (response.ok) {
      setAllBuckets(allThirdParty);
    }
  }

  async function searchForModels() {
    const modelsData = await request.get(`product?modelTerm=${modelTerm}`);
    if (response.ok) {
      setModels(modelsData.data);
    }
  }

  async function getPreviousAuditAdjustments(bucket) {
    const previousAuditAdjustmentsData = await request.get(
      `ims/audit-adjustments/previous-audit-adjustments?legacyBucket=${bucket}&product=${selectedModel}`
    );
    if (response.ok) {
      if (previousAuditAdjustmentsData.length > 0) {
        setPreviousAuditAdjustments(previousAuditAdjustmentsData);
      } else {
        setPreviousAuditAdjustments('No audit adjustments have been made');
      }
    }
  }

  useEffect(() => {
    if (debouncedModelTerm) {
      searchForModels();
    }
  }, [debouncedModelTerm]);

  function closeAdjustmentDialog() {
    setNotes('');
    setNewQty('');
    setSelectedBucket('');
  }

  async function adjustInventory() {
    const adjustedInventory = await request.put('ims/audit-adjustments', {
      username: username.username,
      model: selectedModel,
      legacyBucket: Object.keys(selectedBucket)[0],
      notes,
      quantity: newQty
    });
    if (response.ok) {
      setNotes('');
      setNewQty('');
      setSelectedBucket('');
      getBucketsForModel();
    } else {
      console.info(adjustedInventory);
    }
  }

  async function adjustZeroInventory() {
    const adjustedInventory = await request.put('ims/audit-adjustments', {
      username: username.username,
      model: selectedModel,
      legacyBucket: selectedZeroBucket,
      notes: zeroNotes,
      quantity: newZeroValue
    });
    if (response.ok) {
      setZeroNotes('');
      setNewZeroValue('');
      setZeroBucket('');
      getBucketsForModel();
    } else {
      console.info(adjustedInventory);
    }
  }

  return (
    <AuditAdjustments
      models={models}
      setSelectedModel={setSelectedModel}
      setModelTerm={setModelTerm}
      buckets={modelBuckets}
      selectedBucket={selectedBucket}
      selectedModel={selectedModel}
      setSelectedBucket={setSelectedBucket}
      setNotes={setNotes}
      setNewQty={setNewQty}
      adjustInventory={adjustInventory}
      closeAdjustmentDialog={closeAdjustmentDialog}
      allBuckets={allBuckets}
      zeroBucket={zeroBucket}
      setZeroBucket={setZeroBucket}
      setSelectedZeroBucket={setSelectedZeroBucket}
      setNewZeroValue={setNewZeroValue}
      setZeroNotes={setZeroNotes}
      adjustZeroInventory={adjustZeroInventory}
      previousAuditAdjustments={previousAuditAdjustments}
      setPreviousAuditAdjustments={setPreviousAuditAdjustments}
      getPreviousAuditAdjustments={getPreviousAuditAdjustments}
      getModels={getModels}
      getBucketsForModel={getBucketsForModel}
      modelSearch={modelSearch}
    />
  );
}
