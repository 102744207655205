const initialState = {
  messages: [],
  errors: [],
  successes: []
};

export default function loading(state = initialState, { type, payload }) {
  switch (type) {
    case 'MESSAGING_QUEUE_SERVICE':
      return {
        ...state,
        [payload.type]: [
          ...state[payload.type],
          {
            date: payload.date,
            message: payload.message,
            status: payload.status,
            type: payload.type
          }
        ]
      };
    case 'MESSAGING_QUEUE_SERVICE::CLEAR':
      return {
        ...state,
        [payload.type]: state[payload.type].filter(
          msgs => !(msgs.message === payload.message && msgs.date === payload.date)
        )
      };
    default:
      return state;
  }
}
