import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { useDownloadWorker } from '../../common/Hooks';

export default function InactiveInventoryReport() {
  const currentDate = moment().format('YYYY-MM-DD');
  const inactiveInventoryUrl = '/ims/reporting/inventory/inactive';
  const [inactiveInventoryReportFileName] = useState(`inactive-inventory-report-${currentDate}`);
  const history = useHistory();

  const [sendInactiveInventoryUrlToWorker] = useDownloadWorker({
    reportName: inactiveInventoryReportFileName
  });

  const handleSubmit = () => {
    sendInactiveInventoryUrlToWorker(`${inactiveInventoryUrl}`);
    setTimeout(() => {
      history.push('/');
    }, 2000);
  };

  return (
    <Button variant="contained" color="primary" onClick={handleSubmit}>
      Download CSV
    </Button>
  );
}
