const user = ['boards:view'];

const customer_service_team_member = [...user, 'inventory:view', 'inventory:reports'];
const customer_service_team_member_2 = [...customer_service_team_member];
const customer_service_team_member_3 = [...customer_service_team_member_2];

const customer_service_team_lead = [
  ...customer_service_team_member_3,
  'purchase-order:view'
  // user management
];

const fufillment_team_member = [
  ...user,
  'purchase-order:view',
  'inventory:view',
  'inventory:reports'
];
const fufillment_team_member_2 = [...fufillment_team_member, 'inventory-transfer:view'];

const fufillment_team_lead = [
  ...fufillment_team_member_2,
  'purchase-order:receive',
  'committed-transfer:view',
  'purchase-order:edit',
  'purchase-order:create',
  'purchasing:reports'
  // user management
];

const purchasing_team_member = [
  ...user,
  'inventory:view',
  'inventory:reports',
  'purchase-order:create',
  'purchase-order:view',
  'purchase-order:edit',
  'purchasing:reports',
  'bucket-buffer:view'
];

const purchasing_team_member_2 = [
  ...purchasing_team_member,
  'purchase-order:send-to-vendor',
  'vendor:create',
  'vendor:edit'
];

const purchasing_team_member_3 = [
  ...purchasing_team_member_2,
  // 'buckets:adjust-group-buffers',
  // 'buckets:adjust-pre-bucket-local-inventory',
  'vendor:create',
  'vendor:edit'
];

const purchasing_team_admin = [
  ...purchasing_team_member_3,
  'bucket-buffer:edit',
  'purchase-order:receive',
  'purchase-order:un-receive',
  'audit-scan:submit'
  // user management
];

const sales_team_member = ['purchase-order:view', 'inventory:view', 'inventory:reports'];

const warehouse_team_member = [...user, 'inventory:view'];

const warehouse_team_member_2 = [
  ...warehouse_team_member,
  'purchase-order:view',
  'purchase-order:receive',
  'inventory:reports'
];

const warehouse_team_lead = [
  ...warehouse_team_member_2,
  'inventory-transfer:view',
  'committed-transfer:view',
  'purchasing:reports'
];

const warehouse_team_admin = [
  ...warehouse_team_lead,
  'purchase-order:create',
  'purchase-order:edit',
  'purchase-order:un-receive',
  'audit-scan:submit',
  'bin:admin'
];

const finance_team_member = [
  ...user,
  'purchase-order:view',
  'inventory:view',
  'ap-invoices:create',
  'ap-invoices:edit',
  'inventory:reports',
  'bucket-buffers:edit'
];

const finance_team_lead = [...finance_team_member, 'ap-invoices:delete', 'finance-company:create'];

const admin = [
  ...warehouse_team_admin,
  ...purchasing_team_admin,
  ...finance_team_lead,
  'qa-routes:view',
  'admin-menu:view',
  'bucket:create',
  'inventory:admin-adjust',
  'admin:override'
];

const developer = [
  ...warehouse_team_admin,
  ...purchasing_team_admin,
  ...finance_team_lead,
  ...admin,
  'dev-route:view',
  'admin-menu:view',
  'role-editor:view'
];

const roles = {
  customer_service_team_member,
  customer_service_team_member_2,
  customer_service_team_member_3,
  fufillment_team_member,
  fufillment_team_member_2,
  fufillment_team_lead,
  customer_service_team_lead,
  purchasing_team_member,
  purchasing_team_member_2,
  purchasing_team_member_3,
  purchasing_team_admin,
  sales_team_member,
  warehouse_team_member,
  warehouse_team_member_2,
  warehouse_team_lead,
  warehouse_team_admin,
  finance_team_lead,
  finance_team_member,
  admin,
  developer
};

const rules = Object.entries(roles)
  .sort(([a], [b]) => a.localeCompare(b))
  .reduce((acc, [name, ruleset]) => {
    acc[name] = { static: [...new Set(ruleset)] };
    return acc;
  }, {});

export default rules;
