import { Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import GlueTooltip from './GlueTooltip';

export type GlueDateProps = {
  date?: null | string;
  includeTime?: boolean;
};

export default function GlueDate({
  date,
  includeTime
}: GlueDateProps) {

  const validDate = !!date && moment(date).isValid()
    ? moment(date)
    : '';
  const formatOptions: moment.CalendarSpec = {
    sameDay: includeTime
      ? 'h:mm A'
      : '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    nextWeek: 'dddd',
  }
  const formatString = includeTime ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY';

  return (
    <GlueTooltip
      title={
        validDate
          ? validDate
            .format(formatString)
            .toString()
          : ''
      }
    >
      <Typography>
        {
          validDate
            ? validDate
              .calendar(Date.now(), formatOptions)
              .toString()
            : ''
        }
      </Typography>
    </GlueTooltip>
  );
}