import React, { useState, useEffect, useContext } from 'react';
import { uniqBy } from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import SelectVendor from './SelectVendor';
import { AuthContext } from '../../AuthContext';
import {
  vendorList,
  createVendor,
  createPurchaseOrder,
  getReadyToCreatePO,
  clearVendorFields,
  clearError
} from '../../actions/purchasingActions';
import { useHttp } from '../../common/Hooks';
import { useGetPoTerms } from '../PurchaseOrderDetails/PurchaseOrderDetailsHooks';
import { Switch, FormControlLabel } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

const styles = () => ({
  root: { padding: 32 },
  grid: { margin: 24 }
});

const PurchaseOrderForm = props => {
  const {
    vendorsFulfilled,
    vendorCreated,
    vendorList,
    createVendorFulfilled,
    createdVendor,
    clearVendorFields,
    vendors,
    purchaseOrder: po
  } = props;
  const [selectedVendor, setSelectedVendor] = useState('');
  const [newVendor, newVendorToggled] = useState(false);
  const [message, setMessage] = useState('');
  const [request, response] = useHttp('ims/canReceivePos');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [receivableLocations, setLocations] = useState([]);
  async function locationsRequest() {
    const locationsResponse = await request.get();
    if (response.ok) {
      setLocations(locationsResponse);
    }
  }

  useEffect(() => console.log(vendorList), [selectedVendor]);

  useEffect(() => {
    locationsRequest();
  }, []);

  const [selectedTerms, setSelectedTerms] = useState(null);
  const [defaultTermsList, setDefaultTermsList] = useState([]);
  const [showAllTerms, setShowAllTerms] = useState(false);
  const getPoTerms = useGetPoTerms();
  const [allTerms, setAllTerms] = useState([]);

  const [purchaseOrder, setLocalPurchaseOrder] = useState({
    ...po
  });

  const user = useContext(AuthContext);

  useEffect(() => {
    vendorList(user.auth);
  }, [vendorsFulfilled, vendorCreated, vendorList, user.auth]);

  const propsPurchaseOrder = props.purchaseOrder;
  const { history } = props;

  useEffect(() => {
    if (propsPurchaseOrder && purchaseOrder.id !== propsPurchaseOrder.id) {
      history.push(`/purchase-orders/review/${props.purchaseOrder.id}`);
      setLocalPurchaseOrder({ ...propsPurchaseOrder });
    }
  }, [propsPurchaseOrder, purchaseOrder, setLocalPurchaseOrder, po, history]);

  const updateToCreatedVendor = () => {
    if (createdVendor) {
      setSelectedVendor(createdVendor);
    }
  };

  useEffect(() => {
    if (createVendorFulfilled) {
      updateToCreatedVendor();
    }
  }, [createVendorFulfilled]);

  const handleChange = vendorID => {
    setSelectedVendor(vendorID);
  };

  const handleClose = () => {
    newVendorToggled(false);
    clearVendorFields();
    clearError();
  };

  const handleVendorSubmit = vendor => {
    props
      .createVendor({ programName: vendor.programName || 'Default', ...vendor }, user.auth)
      .then(vendorCreatedStatus => {
        if (vendorCreatedStatus) {
          handleClose();
        }
      });
  };

  const handleSubmit = () => {
    if (!selectedVendor) {
      setMessage('Please select a vendor');
      return false;
    }
    if (!selectedLocation) {
      setMessage('Please select a location this PO will be received at.');
      return false;
    }
    const values = {
      vendorId: selectedVendor,
      createdBy: user.username,
      purchaseOrderType: 'Default',
      shippingRequirements: 'Default',
      location_code: selectedLocation,
      purchase_terms_id: selectedTerms
    };
    props.getReadyToCreatePO();
    props.createPurchaseOrder(values, user.auth).then(() => {
      setTimeout(() => {
        console.info(props.purchaseOrder);
      }, 500);
    });
  };

  useEffect(() => {
    const refreshPoTerms = async () => {
      const fetchPoTerms = await getPoTerms();
      setAllTerms(fetchPoTerms || []);
    };
    refreshPoTerms();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (vendors && selectedVendor) {
      const vendorFound = vendors.find(v => v.id === parseInt(selectedVendor, 10));
      const vendorSpecificTerms = vendorFound ? vendorFound.default_terms : [];
      const terms =
        showAllTerms || isEmpty(vendorSpecificTerms)
          ? [...vendorSpecificTerms, ...allTerms]
          : vendorSpecificTerms;
      if (isEmpty(vendorSpecificTerms)) {
        setShowAllTerms(true);
      }
      if ((vendorFound || allTerms) && terms.length > 0) {
        setDefaultTermsList(uniqBy(terms, 'id'));
        if (terms.length === 1) {
          setSelectedTerms(terms[0].id);
        }
      }
    }
  }, [selectedVendor, vendors, showAllTerms, allTerms]);

  return (
    <div style={{ padding: '25px 0', maxWidth: 750 }}>
      <div>
        <SelectVendor
          vendors={props.vendors}
          selectedVendor={selectedVendor}
          handleChange={handleChange}
          handleVendorSubmit={handleVendorSubmit}
          newVendor={newVendor}
          handleClose={handleClose}
          newVendorToggled={newVendorToggled}
        />
        {selectedVendor && defaultTermsList && (
          <>
            <NativeSelect value={selectedTerms} onChange={e => setSelectedTerms(e.target.value)}>
              <option key={0} value={null}>
                Select a PO Term
              </option>
              {defaultTermsList.map(term => (
                <option key={term.id} value={term.id}>
                  {term.type}
                </option>
              ))}
            </NativeSelect>
            <FormControlLabel
              control={
                <Switch
                  checked={showAllTerms}
                  onChange={() => setShowAllTerms(!showAllTerms)}
                  name="poTerms"
                />
              }
              name="poTerms"
              onChange={() => setShowAllTerms(!showAllTerms)}
              label="Show All PO Terms"
            />
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <NativeSelect onChange={e => setSelectedLocation(e.target.value)}>
          <option value={null}>Set PO Receiving Location</option>
          {receivableLocations &&
            receivableLocations.map(location => (
              <option key={location.location_code} value={location.location_code}>
                {location.location_code}
              </option>
            ))}
        </NativeSelect>
        <Button style={{ marginLeft: 10 }} variant="contained" onClick={handleSubmit}>
          Create PO
        </Button>
        <br />
        {message}
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    state => {
      return {
        vendors: state.purchasing.vendors,
        createdVendor: state.purchasing.vendorCreated,
        createVendorFulfilled: state.purchasing.vendorCreatedFulfilled,
        vendorsFulfilled: state.purchasing.vendorsFulfilled,
        user: state.user.userName,
        fulfilled: state.purchasing.purchaseOrderFulfilled,
        purchaseOrder: state.purchasing.purchaseOrderCreated,
        vendorErrors: state.purchasing.vendorError
      };
    },
    {
      vendorList,
      createVendor,
      createPurchaseOrder,
      getReadyToCreatePO,
      clearVendorFields,
      clearError
    }
  )
)(PurchaseOrderForm);
