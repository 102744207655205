import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PickItemGridBox } from './PickItemGridBoxComponents';
import { makePickItemErrorMessageStyles, makeRootStyles } from './PickItemStyles';

const useStyles = makeStyles(theme => ({
  root: makeRootStyles(theme),
  pickItemError: makePickItemErrorMessageStyles(theme)
}));

/**
 * @param {Object} props
 * @param {import('./PickItemTypes').UnpickedItem} props.unpickedItem
 * @param {string} props.errorMessage
 */
export function PickItemErrorLayout({ unpickedItem, errorMessage }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid container spacing={5} alignItems="flex-start">
        <PickItemGridBox label="Model:">{unpickedItem.model}</PickItemGridBox>
        <PickItemGridBox label="Invoice:">{unpickedItem.invoice}</PickItemGridBox>
        <PickItemGridBox label="Quantity:">{unpickedItem.quantity}</PickItemGridBox>
        <PickItemGridBox label="Error Message:">
          <Typography className={classes.pickItemError}>{errorMessage}</Typography>
        </PickItemGridBox>
      </Grid>
    </Grid>
  );
}

export function NoDataToDisplay() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid container spacing={5} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography>
            There is no data to display. It is possible that this item has been unprinted. Please
            see the floor supervisor.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
