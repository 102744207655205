import React, { ChangeEvent, useContext } from 'react';
import { Checkbox, createStyles, makeStyles } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GlueTooltip from '../../Presentational/GlueTooltip';
import { PickListContext } from './PickListContext';
import { PickableItem } from './PickListTypes';
import { GlueTheme } from '../../../GlueThemeType';
import { get } from 'lodash';
import UnprintItemModal from './UnprintPickItem/UnprintItemModal';
const Barcode = require('react-barcode');

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    warningIcon: {
      padding: theme.spacing(1)
    },
  })
);

export type PrintCellContentsProps = {
  pickableItem: PickableItem;
  printVersion?: boolean;
}

export default function PrintCellContents({
  pickableItem,
  printVersion,
}: PrintCellContentsProps) {

  const classes = useStyles();
  const { itemsToPrint, setItemsToPrint, printPickListErrors } = useContext(PickListContext);

  const isSelected = (transactionReferenceId: number) => itemsToPrint.indexOf(transactionReferenceId) !== -1;
  const isChecked = isSelected(pickableItem.transactionReferenceId);

  const handleClick = (event: ChangeEvent) => {
    const selectedIndex = itemsToPrint.indexOf(pickableItem.transactionReferenceId);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemsToPrint, pickableItem.transactionReferenceId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemsToPrint.slice(1));
    } else if (selectedIndex === itemsToPrint.length - 1) {
      newSelected = newSelected.concat(itemsToPrint.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemsToPrint.slice(0, selectedIndex),
        itemsToPrint.slice(selectedIndex + 1),
      );
    }
    setItemsToPrint(newSelected);
  };

  let cellContents = (<Checkbox
    checked={isChecked}
    onChange={handleClick}
    inputProps={{ 'aria-label': 'select all pickable items to print' }}
  />);

  if (printVersion) {
    const barcodeText = `PLI=${pickableItem.pickListItemId ?? 'PENDING'}`;
    cellContents = (<Barcode
      height={36}
      width={1}
      fontSize={12}
      value={barcodeText} />)
  }

  if (!!pickableItem.printedAt) {
    cellContents = (
      <UnprintItemModal pickableItem={pickableItem} />
    )
  }

  if (!!pickableItem.warning) {
    cellContents = (
      <GlueTooltip title={pickableItem.warning}>
        <span className={classes.warningIcon}>
          <ReportProblemOutlinedIcon color={'error'} />
        </span>
      </GlueTooltip>
    );
  }
  
  const hasPrintItemError = (pickableItem: PickableItem) => {
    if (
      printPickListErrors 
      && printPickListErrors.itemErrors 
      && printPickListErrors.itemErrors[pickableItem.transactionReferenceId]) {
      return true;
    }
    return false;
  }

  if (hasPrintItemError(pickableItem)) {
    const errorArray = get(printPickListErrors.itemErrors, pickableItem.transactionReferenceId, []);
    const errorTitle = errorArray && Array.isArray(errorArray) ? errorArray.join(' ') : '';
    cellContents = (
      <GlueTooltip title={errorTitle} interactive>
        <span className={classes.warningIcon}>
          <ErrorOutlineIcon color={'error'} />
        </span>
      </GlueTooltip>
    )
  }

  return (
    <div>
      {cellContents}
    </div>
  );
}