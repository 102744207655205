import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { currencyFormatter } from '../PurchaseOrderDetailsHelpers';
import { PurchaseOrderData } from '../PurchaseOrderDetailsTypes';

type PurchaseOrderMetaProps = {
  poData: PurchaseOrderData;
};

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    marginRight: '2rem',
    marginTop: '1rem'
  },
  item: {
    padding: '1rem',
    border: '1px solid #ccc'
  }
});

export default function PurchaseOrderMeta(props: PurchaseOrderMetaProps) {
  const { poData } = props;
  const classes = useStyles();
  const total = Number(poData.PO.total);
  const dfiDiscount = Number(poData.PO.dfi_discount_percentage);
  const dfiDollars = total * (dfiDiscount / 100);
  return (
    <Grid className={classes.root} container justify="flex-end">
      <Grid className={classes.item} item>
        <Typography variant="body1">Sub Total: {currencyFormatter.format(total)}</Typography>
        <Typography variant="body1">
          Total Received {currencyFormatter.format(Number(poData.PO.total_received))}
        </Typography>
        <Typography variant="body1">
          DFI Discount Amount: {currencyFormatter.format(dfiDollars)}
        </Typography>
        <Typography variant="body1">
          Balance Due {currencyFormatter.format(Number(poData.PO.balance_due))}
        </Typography>
      </Grid>
    </Grid>
  );
}
