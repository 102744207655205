import { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

const isPartiallyPaid = (billed, paid) => {
  const billedCompare = Math.round(parseFloat(billed) * 100);
  const paidCompare = Math.round(parseFloat(paid) * 100);
  return paidCompare < billedCompare;
};

const sortRowInto = (sorted, row, category) => {
  return {
    ...sorted,
    [category]: {
      ...sorted[category],
      [row.tracking_number]: row
    }
  };
};

const sortRows = rowsArg => {
  return rowsArg.reduce((sorted, currentRow) => {
    if (currentRow.reconciliation_result === 'Unable to find matching gray box') {
      return sortRowInto(sorted, currentRow, 'notFound');
    }
    if (!currentRow.shipping_quote.match(/^\d+(.\d{1,2})?$/)) {
      return sortRowInto(sorted, currentRow, 'invalidQuote');
    }
    if (isEmpty(currentRow.shipping_cost_paid)) {
      return sortRowInto(sorted, currentRow, 'notPaid');
    }
    if (isPartiallyPaid(currentRow.shipping_cost_billed, currentRow.shipping_cost_paid)) {
      return sortRowInto(sorted, currentRow, 'partiallyPaid');
    }
    return sortRowInto(sorted, currentRow, 'fullyPaid');
  }, {});
};

export const useShippingReconciliationSort = initialRows => {
  const [notPaid, setNotPaid] = useState({});
  const [partiallyPaid, setPartiallyPaid] = useState({});
  const [fullyPaid, setFullyPaid] = useState({});
  const [notFound, setNotFound] = useState({});
  const [invalidQuote, setInvalidQuote] = useState({});

  useEffect(() => {
    const sorted = sortRows(initialRows);
    setNotPaid(sorted.notPaid || {});
    setPartiallyPaid(sorted.partiallyPaid || {});
    setFullyPaid(sorted.fullyPaid || {});
    setNotFound(sorted.notFound || {});
    setInvalidQuote(sorted.invalidQuote || {});
  }, [initialRows, setNotPaid, setPartiallyPaid, setFullyPaid, setNotFound, setInvalidQuote]);

  return [notPaid, partiallyPaid, fullyPaid, notFound, invalidQuote];
};

export const useShippingReconciliationStaging = notPaid => {
  const [stagedUpdates, setStagedUpdates] = useState({});

  const setDefaultStagedUpdates = useCallback(
    rowsArg => {
      const defaultStaged = rowsArg.reduce((staged, current) => {
        let newStaged = staged;
        switch (current.reconciliation_result) {
          case 'Matched':
          case 'Not Matched - In Our Favor':
          case 'Not Matched - Within Acceptable Threshold':
            newStaged = {
              ...staged,
              [current.tracking_number]: {
                reported_billed_charge: current.reported_billed_charge,
                payment_to_apply: current.reported_billed_charge
              }
            };
            break;

          default:
            break;
        }
        return newStaged;
      }, {});
      setStagedUpdates(defaultStaged);
    },
    [setStagedUpdates]
  );

  const resetDefaultStagedUpdates = useCallback(() => {
    setDefaultStagedUpdates(Object.values(notPaid));
  }, [setDefaultStagedUpdates, notPaid]);

  useEffect(() => {
    resetDefaultStagedUpdates();
  }, [resetDefaultStagedUpdates]);

  return [stagedUpdates, setStagedUpdates, resetDefaultStagedUpdates];
};
