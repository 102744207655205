import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useDebounce } from 'use-debounce';

import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { FormControl, Grid } from '@material-ui/core';

import {
  getProducts,
  listProductBrands,
  listProductCategories,
  updateProduct,
  deleteProduct,
  storeReset
} from '../../actions/productActions';

const useStyles = makeStyles({
  dialog: {
    padding: '2rem'
  },
  select: {
    zIndex: '999',
    fontFamily: 'sans-serif'
  },
  searchBar: {
    margin: '1rem 0'
  }
});

export const ProductsListComponent = props => {
  // Used for API calls

  // Effect for Product categories changing
  const {
    listProductCategories,
    listProductBrands,
    categoriesFulfilled,
    brandsFulfilled,
    productDeleted,
    getProducts,
    storeReset,
    categories,
    brands
  } = props;
  const access_token = localStorage.getItem('access');
  const [brandsState, setBrands] = useState([]);
  const [categoriesState, setCategories] = useState([]);
  useEffect(() => {
    // Maps the props.categories to what can be read by the Select components
    if (!categoriesFulfilled) {
      listProductCategories(access_token);
    }
    setCategories(
      Object.entries(categories).map(category => ({
        label: category[0],
        value: category[1]
      }))
    );
  }, [categoriesFulfilled, listProductCategories, categories, access_token]);

  // Effect for Product brands changing
  useEffect(() => {
    if (!brandsFulfilled) {
      listProductBrands(access_token);
    }
    setBrands(
      Object.entries(brands).map(brand => ({
        label: brand[0],
        value: brand[1]
      }))
    );
  }, [brandsFulfilled, listProductBrands, brands, access_token]);

  // Effect that watches for products being deleted
  // Will reset the dialog and then fetch the product list
  // without what was just deleted
  useEffect(() => {
    if (productDeleted === true) {
      setDialog(null);
    }
  }, [productDeleted]);

  // Page, min and pagesize are used to calculate the pages of products
  const [page, setPage] = useState(100);
  const pageSize = 100;
  const min = page - pageSize - 1;

  // Hook for styles
  const classes = useStyles();
  // Setting dialog to null is falsey which lets us use it for both open/close
  // But also for viewing individual items
  const [dialog, setDialog] = useState(null);
  const [brand, setBrand] = useState('');

  // State hooks for search terms
  const [category, setCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [upc, setUpc] = useState('');
  const [modelTerm, setModelTerm] = useState('');

  // Debounce hooks for performance while typing
  const [debounceSearch] = useDebounce(searchTerm, 500);
  const [debouncedModel] = useDebounce(modelTerm, 500);
  const [debouncedUpc] = useDebounce(upc, 500);

  // Main hook for getting products listed.
  // Filters are "optional" and will be filtered out if falsey
  useEffect(() => {
    // Resets to the first page displayed
    setPage(100);
    getProducts(brand, category, debounceSearch, debouncedModel, debouncedUpc, access_token);
    // Clears products searched when leaving the page
    return () => storeReset;
  }, [
    storeReset,
    getProducts,
    categoriesState,
    brandsState,
    category,
    brand,
    debounceSearch,
    debouncedModel,
    debouncedUpc,
    access_token
  ]);

  // Select component abstraction to be used for brands and categories
  const FilterSelect = props => {
    return (
      <div>
        <Typography>{props.name}</Typography>
        <Select
          isClearable
          options={props.list}
          value={props.list.filter(v => v.value === props.indiv).pop() || ''}
          className={classes.select}
          onChange={v => {
            const newValue = v instanceof Object ? v.value : '';
            props.update(newValue);
          }}
        />
      </div>
    );
  };

  // Moves pages. Defined here as they live in the layout twice
  const NavButtons = () => {
    return (
      <div>
        {page > pageSize && <button onClick={() => setPage(page - pageSize)}>Back</button>}
        {page < props.products.length && (
          <button onClick={() => setPage(page + pageSize)}>Forward</button>
        )}
      </div>
    );
  };

  // Map of the products into the table
  const productMap =
    props.products &&
    props.products.map((product, idx) => {
      // Logic to only show one page at a 16:23
      // min and page will move within 100-sized chunks
      // If page is 100, pageSize is 100 then min will be 1
      // Page is 200, pageSize is 100 then min will be 101
      if (min < idx && idx < page) {
        return (
          <tr key={product.id}>
            <td>
              <Typography>{product.model_number}</Typography>
            </td>
            <td>
              <Typography>{product.description}</Typography>
            </td>
            <td>
              <Typography>{product.upc}</Typography>
            </td>
            <td>
              <button onClick={() => setDialog(product)}>View</button>
            </td>
          </tr>
        );
      }
      return null;
    });

  // Main display
  return (
    <div>
      <NavButtons />
      <Grid container spacing={12}>
        <Grid item xs={12} sm={6}>
          <FilterSelect
            name="Category"
            list={categoriesState}
            indiv={category}
            update={setCategory}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FilterSelect name="Brand" list={brandsState} indiv={brand} update={setBrand} />
        </Grid>
      </Grid>
      <Grid container spacing={6} justify="space-between">
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.searchBar}>
            <TextField
              name="product_model"
              value={modelTerm}
              label="Product Model"
              onChange={e => setModelTerm(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.searchBar}>
            <TextField
              name="product_filter"
              label="Product Description"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.searchBar}>
            <TextField
              name="product_filter"
              label="UPC"
              value={upc}
              onChange={e => setUpc(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
      <table>
        <thead>
          <tr>
            <th>
              <Typography>Model Number</Typography>
            </th>
            <th>
              <Typography>Description</Typography>
            </th>
            <th>
              <Typography>UPC</Typography>
            </th>
          </tr>
        </thead>
        <tbody>{productMap}</tbody>
      </table>
      <NavButtons />

      {/* Needed to make sure dialog isn't accessing any undefined properties */}
      {dialog && (
        <Dialog onClose={() => setDialog(null)} open={dialog !== null}>
          <div className={classes.dialog}>
            {Object.entries(dialog).map(item => {
              return (
                <TextField
                  onChange={event =>
                    setDialog({
                      ...dialog,
                      [event.target.name]: event.target.value
                    })
                  }
                  key={item[0]}
                  name={item[0]}
                  label={item[0]}
                  value={item[1] || ''}
                />
              );
            })}
            <button onClick={() => props.updateProduct(dialog, access_token)}>Update</button>
            <button
              onClick={() => {
                props.deleteProduct(dialog.id, access_token);
              }}
            >
              Delete
            </button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default connect(
  state => ({
    productsFulfilled: state.products.productsFulfilled,
    productDeleted: state.products.productDeleted,
    products: state.products.products,
    brands: state.products.brands,
    categories: state.products.categories,
    categoriesFulfilled: state.products.categoriesFulfilled,
    brandsFulfilled: state.products.brandsFulfilled
  }),
  {
    getProducts,
    listProductBrands,
    listProductCategories,
    storeReset,
    updateProduct,
    deleteProduct
  }
)(ProductsListComponent);
