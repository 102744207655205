import React, { useCallback, useContext, useEffect } from 'react';
import { ScanEventListeningContext } from './ScanEventListeningContext';
import { ScanEventHandler, ScanEventSubscriberProps } from './ScanEventListeningTypes';

export default function ScanEventSubscriber(props: ScanEventSubscriberProps) {
  const { children, onScan, preventBubblingToAncestorElements } = props;
  const { scanEventBus } = useContext(ScanEventListeningContext);

  const enhancedOnScan = useCallback<ScanEventHandler>(
    scanEvent => {
      if (preventBubblingToAncestorElements) {
        scanEvent.stopPropagation();
      }
      onScan(scanEvent);
    },
    [onScan, preventBubblingToAncestorElements]
  );

  useEffect(() => {
    if (scanEventBus) {
      scanEventBus.addScanEventListener(enhancedOnScan);
    }
    return () => {
      if (scanEventBus) {
        scanEventBus.removeScanEventListener(enhancedOnScan);
      }
    };
  }, [scanEventBus, enhancedOnScan]);

  return <>{children}</>;
}
