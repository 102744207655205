const initialState = {
  loginCredentials: '',
  authorized: false,
  failedLog: false,
  userName: '',
  avatarImg: '',
  userTimeout: '',
  loginErrorMessage: '',
  userData: []
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_FULFILLED':
      return {
        ...state,
        loginCredentials: {
          access_token: action.payload
        }
      };
    case 'LOGIN_REJECTED':
      return {
        ...state,
        // loginCredentials : action.payload.data,
        authorized: false,
        failedLog: true,
        loginCredentials: '',
        userName: '',
        avatarImg: '',
        userTimeout: '',
        loginErrorMessage: 'Error with Login',
        userData: ''
      };
    case 'LOGIN_REFRESH':
      return {
        ...state,
        loginCredentials: {
          access_token: action.payload.accessToken,
          token: action.payload.token
        },
        authorized: action.payload.authorized,
        failedLog: false,
        userName: action.payload.name,
        avatarImg: action.payload.imageUrl,
        userTimeout: '',
        loginErrorMessage: ''
        // userData:action.payload.data.user
      };
    case 'LOGOUT':
      localStorage.clear();
      return initialState;
    default:
      return state;
  }
}
