import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/styles';
import { TableContainer } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableHeadCell: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[300]
  },
  tableBodyColor: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  tableFix: {
    '& td:first-child': {
      position: 'sticky',
      left: 0,
      backgroundColor: theme.palette.grey[200]
    }
  },
  tableHeaderIndex: {
    '&:first-child': {
      zIndex: 3,
      left: 0
    }
  }
}));

export default function TableView({
  header,
  tableProps,
  tableHeaderCellProps,
  initialRowsPerPage,
  maxHeight = null,
  children: tableContent
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  useEffect(() => {
    setRowsPerPage(initialRowsPerPage);
  }, [initialRowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
  };

  if (!tableContent) return <p>Loading...</p>;

  const children = React.Children.map(tableContent, child => {
    return React.cloneElement(child, {
      classes: { root: `${classes.tableBodyColor} ${classes.tableFix}` }
    });
  });

  return (
    <>
      <TableContainer style={maxHeight ? { maxHeight } : {}}>
        <Table {...tableProps}>
          <TableHead>
            <TableRow>
              {header.map(column => (
                <TableCell
                  key={column}
                  classes={{ root: classes.tableHeaderIndex }}
                  className={classes.tableHeadCell}
                  {...tableHeaderCellProps}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {children.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={children.length}
        page={page}
        rowsPerPageOptions={[5, 10, 15, 50, children.length]}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

TableView.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableProps: PropTypes.instanceOf(Object),
  tableHeaderCellProps: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  initialRowsPerPage: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

TableView.defaultProps = {
  tableProps: {},
  tableHeaderCellProps: {},
  initialRowsPerPage: 10
};
