import { createStyles, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { GlueTheme } from '../../../GlueThemeType';
import { BinManagementContext } from '../BinManagementContext';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    label: {
      fontWeight: 500,
      color: theme.palette.grey[900],
      letterSpacing: '0px'
    }
  })
);

export default function BinNameReadOnlyInput() {
  const classes = useStyles();

  const { createBinData, createBinErrors } = useContext(BinManagementContext);

  const binNameErrors = get(createBinErrors, 'binName', []).join('');

  return (
    <Grid container justify={'space-between'} xs={12} spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.label} variant={'body1'}>
          Bin Name:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          fullWidth
          type={'text'}
          value={createBinData.binName}
          error={!!createBinErrors.binName}
          helperText={
            binNameErrors ? binNameErrors : 'Bin name is derived from the row, bay, and shelf.'
          }
          InputProps={{
            readOnly: true
          }}
          label="Bin Name"
          name="binName"
          required
          id="bin-name"
          variant="outlined"
          placeholder="Bin Name"
        />
      </Grid>
    </Grid>
  );
}
