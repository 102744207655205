import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Typography, Grid, IconButton, FormHelperText } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import GlueTable from '../../Presentational/GlueTable.tsx';
import GlueButton from '../../Presentational/GlueButton.tsx';

const useStyles = makeStyles({
  columnWidth: {
    minWidth: '160px'
  }
});

export default function BinContentsTable({
  stagingContents,
  scannedItems,
  removeStagedItem,
  quantitySetter,
  binSubmissionErrors,
  refreshErrors,
  clearBinSubmissionErrors
}) {
  const classes = useStyles();

  const [itemIndexesWithQuantityErrors, setItemIndexesWithQuantityErrors] = useState([]);
  const [itemIndexesWithUpcErrors, setItemIndexesWithUpcErrors] = useState([]);
  useEffect(() => {
    if (!isEmpty(binSubmissionErrors)) {
      const upcErrorIndexes = [];
      const quantityErrorIndexes = [];
      Object.keys(binSubmissionErrors).forEach(key => {
        const errorKeyParts = key.split('.');
        if (errorKeyParts.includes('upc')) {
          upcErrorIndexes.push(errorKeyParts[1]);
        } else if (errorKeyParts.includes('updateQty')) {
          quantityErrorIndexes.push(errorKeyParts[1]);
        }
      });
      setItemIndexesWithUpcErrors(upcErrorIndexes);
      setItemIndexesWithQuantityErrors(quantityErrorIndexes);
    } else {
      setItemIndexesWithUpcErrors([]);
      setItemIndexesWithQuantityErrors([]);
    }
  }, [binSubmissionErrors]);

  const contentsTableRows = !isEmpty(scannedItems)
    ? stagingContents
      ? scannedItems.map((item, index) => {
          return {
            key: item.upc,
            data: {
              model: item.model,
              upc: (
                <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                  <Grid item xs={6}>
                    <Typography>{item.upc}</Typography>
                  </Grid>
                  <Grid item xs={6} />
                  {itemIndexesWithUpcErrors.includes(index.toString()) ? (
                    <>
                      <Grid item xs={6}>
                        <FormHelperText error>
                          UPC does not exist in this bin to be removed.
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              ),
              updateQty: (
                <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                  <Grid item xs={1}>
                    <IconButton onClick={() => quantitySetter(false, item.upc)}>
                      <RemoveCircle />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ paddingLeft: '30px', paddingTop: '12.5px' }}>
                      {item.updateQty}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => quantitySetter(true, item.upc)}>
                      <AddCircle />
                    </IconButton>
                  </Grid>

                  {itemIndexesWithQuantityErrors.includes(index.toString()) ? (
                    <>
                      <Grid item xs={7} />
                      <Grid item xs={5}>
                        <FormHelperText error>
                          Quantity Scanned must be less than or equal to the existing quantity when
                          removing
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={8} />
                </Grid>
              ),
              delete: (
                <GlueButton
                  onClick={() => {
                    removeStagedItem(item.upc);
                    clearBinSubmissionErrors();
                  }}
                  variant="outlined"
                  size="small"
                >
                  Delete
                </GlueButton>
              )
            }
          };
        })
      : scannedItems.map(item => {
          return {
            key: item.upc,
            data: {
              ...item
            }
          };
        })
    : {};

  const contentsTableColumns = stagingContents
    ? [
        {
          columnName: 'Model',
          columnKey: 'model'
        },
        {
          columnName: 'UPC',
          columnKey: 'upc'
        },
        {
          columnName: 'Quantity Scanned',
          columnKey: 'updateQty'
        },
        {
          columnName: '',
          columnKey: 'delete',
          align: 'center',
          className: classes.columnWidth
        }
      ]
    : [
        {
          columnName: 'Model',
          columnKey: 'model'
        },
        {
          columnName: 'UPC',
          columnKey: 'upc'
        },
        {
          columnName: 'Quantity',
          columnKey: 'quantity'
        }
      ];

  return (
    <Paper>
      <GlueTable
        accessibleAriaLabel="bin-contents-table"
        orderBy="model"
        sortDirection="asc"
        columns={contentsTableColumns}
        rows={contentsTableRows}
        isErrors={refreshErrors}
      />
    </Paper>
  );
}
