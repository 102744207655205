import { useState, useEffect } from 'react';
import { useHttp } from '../../../common/Hooks';

export function useAuditBatchItems(auditBatchId, fetchBatchItems, setFetchBatchItems) {
  const [committed, setCommitted] = useState([]);
  const [transacted, setTransacted] = useState([]);
  const [request, response] = useHttp(`ims/audit-scans/items?auditBatchId=${auditBatchId}`);

  useEffect(() => {
    async function getAuditBatchItems() {
      const auditBatchItems = await request.get();
      if (response.ok) {
        setCommitted(auditBatchItems.committed);
        setTransacted(auditBatchItems.transacted);
      }
    }
    if (fetchBatchItems) {
      getAuditBatchItems();
      setFetchBatchItems(false);
    }
  }, [fetchBatchItems]);

  return { committed, transacted };
}

export function useAuditBatchDetails(auditBatchId) {
  const [details, setDetails] = useState({});
  const [request, response] = useHttp(`ims/audit-scans/details?auditBatchId=${auditBatchId}`);

  useEffect(() => {
    async function getAuditBatchDetails() {
      const auditBatchDetails = await request.get();
      if (response.ok) {
        setDetails(auditBatchDetails);
      }
    }
    getAuditBatchDetails();
  }, []);

  return details;
}
