import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useAxios } from '../../common/Hooks';
import GlueButton from '../Presentational/GlueButton.tsx';
import { reset } from './redux/carouselSlice';
import AddNewDialog from './dialogs/AddNewDialog';
import { getAxiosResponseErrorMessage } from '../../helpers/getErrors';
import ServerErrorDialog from './dialogs/ServerErrorDialog';
import ConfirmationDialog from './dialogs/ConfirmationDialog';

const HigherOrderControls = ({ refetch }) => {
  const dispatch = useDispatch();
  const carouselItems = useSelector(state => state.carouselItems);
  const [axios] = useAxios('/carousel-images');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [serverDialogOpen, setServerDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [itemErrors, setItemErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const processAxiosErrors = (errors, status) => {
    if (status === 500) {
      return [].concat(errors);
    }
    return [].concat(Object.entries(errors).map(item => item[1]));
  };

  useEffect(() => {
    setItemErrors(
      carouselItems
        .map(item => item.errors)
        .map(errors => errors.link || errors.img)
        .includes(true)
    );
  }, [carouselItems]);

  useEffect(() => {
    if (serverDialogOpen) {
      setConfirmationDialogOpen(false);
    }
  }, [serverDialogOpen]);

  const handleUpload = async () => {
    try {
      await axios.post('/', {
        carouselItems: carouselItems.map(item => omit(item, 'errors'))
      });
      refetch();
    } catch (error) {
      const axiosErrors = getAxiosResponseErrorMessage(error);
      setServerErrors(processAxiosErrors(axiosErrors, error.response.status));
      setServerDialogOpen(true);
    }
  };

  const handleServerDialogClose = () => {
    setServerDialogOpen(false);
    refetch();
  };

  const handleConfDialogClose = proceed => {
    if (proceed) {
      handleUpload();
    } else {
      setConfirmationDialogOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <GlueButton
        endIcon="clear"
        onClick={() => dispatch(reset())}
        variant="outlined"
        style={{ marginRight: '0.5em' }}
      >
        Scrap Changes
      </GlueButton>
      <GlueButton
        endIcon={loading ? <CircularProgress color="inherit" size="1em" /> : 'done'}
        onClick={() => {
          setLoading(true);
          setConfirmationDialogOpen(true);
        }}
        disabled={itemErrors || loading}
        style={{ marginRight: '0.5em' }}
      >
        Save Changes
      </GlueButton>{' '}
      <GlueButton endIcon="add" onClick={() => setDialogOpen(true)}>
        Add New
      </GlueButton>
      <AddNewDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <ServerErrorDialog
        open={serverDialogOpen}
        onClose={handleServerDialogClose}
        serverErrors={serverErrors}
      />
      <ConfirmationDialog open={confirmationDialogOpen} onClose={handleConfDialogClose} />
    </>
  );
};

export default HigherOrderControls;
