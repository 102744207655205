import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useToggleStyles = makeStyles(theme => ({
  switchBase: {
    color: theme.palette.primary.light,
    '&$checked': {
      color: theme.palette.success.main
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.success.light
    }
  },
  checked: {},
  track: {}
}));

function ToggleSwitch({ disabled, checked, onChange, label }) {
  const classes = useToggleStyles();
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      control={<Switch classes={classes} checked={checked} onChange={onChange} color="primary" />}
    />
  );
}

export default ToggleSwitch;
