import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import MissingCompositions from './MissingCompositions';

export default function DialogDisplay({
  activateLoading,
  activateSuccess,
  activateError,
  ruleCode,
  missingCompositions
}) {
  let dialogContent;
  if (!activateLoading && !activateSuccess && !activateError) {
    dialogContent = (
      <div>
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to activate ${ruleCode} to use compositions?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once activated, the Rule will use compositions instead of legacy rule mappings.
          </DialogContentText>
        </DialogContent>
      </div>
    );
  }

  if (activateLoading && !activateSuccess) {
    dialogContent = (
      <div>
        <DialogTitle id="alert-dialog-title">{'Processing your request'}</DialogTitle>
        <LinearProgress />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Activating {ruleCode}...
          </DialogContentText>
        </DialogContent>
      </div>
    );
  }

  if (!activateLoading && activateSuccess) {
    dialogContent = (
      <div>
        <DialogContent>
          <Alert severity="success" variant="outlined">
            <AlertTitle>Success - {ruleCode}</AlertTitle>
            The Rule is now activated to use compositions.
          </Alert>
        </DialogContent>
      </div>
    );
  }

  if (!activateLoading && activateError) {
    dialogContent = (
      <div>
        <DialogContent>
          <Alert severity="error" variant="outlined">
            <AlertTitle>Error - {ruleCode}</AlertTitle>
            {activateError}
          </Alert>
          {missingCompositions.length > 0 && (
            <MissingCompositions missingGroups={missingCompositions} />
          )}
        </DialogContent>
      </div>
    );
  }

  return dialogContent;
}
