import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { startsWith } from 'lodash';
import ScanEventSubscriber from '../../common/ScanEventListening/ScanEventSubscriber';
import {
  useSearchInventorySerials,
  useSearchProductInventory
} from '../Inventory/Search/InventorySearchHooks';
import { looksLikeUpc } from '../../helpers/upcHelpers';

export default function DefaultScanEventSubscriptions() {
  const history = useHistory();
  const { searchProductInventory } = useSearchProductInventory();
  const { searchInventorySerials } = useSearchInventorySerials();

  const handleScanBinAssign = useCallback(scannedBinAssignValue => {
    const binDetails = scannedBinAssignValue
      .split('=')
      .pop()
      .split('_');
    const waltsLocation = binDetails[0];
    const binName = binDetails[1];
    if (binName.split('-').length === 3) {
      // make sure bin has all 3 parts/is fully scanned'
      history.push(`/inventory/bins/assignment/${waltsLocation}/${binName}`);
    }
  }, []);

  const handleScanPickListItem = useCallback(scannedPickListItemValue => {
    const pickListItemId = scannedPickListItemValue.split('=').pop();
    history.push(`/pick-item/${pickListItemId}`);
  }, []);

  const handleScanUpc = useCallback(
    async scannedUpc => {
      console.log('App component listener - UPC scanned', scannedUpc);
      const upcResults = await searchProductInventory(scannedUpc, {
        searchType: 'upc',
        includeInactive: true
      });
      if (upcResults.length) {
        history.push(`/inventory/detail/model/${upcResults[0].model}`);
      } else {
        history.push(`/inventory/search?searchBy=upc&searchValue=${scannedUpc}`);
      }
    },
    [searchProductInventory]
  );

  const handleScanSerial = useCallback(
    async scannedSerial => {
      console.log('App component listener - Serial scanned (presumably)', scannedSerial);
      const serialResults = await searchInventorySerials(scannedSerial, {
        includeInactive: true
      });
      if (serialResults.length) {
        history.push(`/inventory-serials/${serialResults[0].id}`);
      }
    },
    [searchInventorySerials]
  );

  /** @type {import('../../common/ScanEventListening/ScanEventListeningTypes').ScanEventHandler} */
  const scanEventHandler = useCallback(
    scanEvent => {
      if (startsWith(scanEvent.scannedValue, 'BINASSIGN=')) {
        handleScanBinAssign(scanEvent.scannedValue);
      } else if (startsWith(scanEvent.scannedValue, 'PLI=')) {
        handleScanPickListItem(scanEvent.scannedValue);
      } else if (looksLikeUpc(scanEvent.scannedValue)) {
        handleScanUpc(scanEvent.scannedValue);
      } else {
        handleScanSerial(scanEvent.scannedValue);
      }
    },
    [handleScanBinAssign, handleScanPickListItem, handleScanUpc, handleScanSerial]
  );

  return <ScanEventSubscriber onScan={scanEventHandler} />;
}
