import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import carouselReducer from './carouselSlice';

const combinedReducer = combineReducers({
  carouselItems: carouselReducer
});

const createRootReducer = preloadedState => {
  return (state, action) => {
    if (action.type === 'carouselItems/reset') {
      // eslint-disable-next-line no-param-reassign
      state = preloadedState;
    }
    return combinedReducer(state, action);
  };
};
export const createStore = preloadedState =>
  configureStore({
    reducer: createRootReducer(preloadedState),
    middleware: getDefaultMiddleware(),
    preloadedState
  });
