import { apiOspos } from '../../../config/config';

export default function createCommittedTransfer(postData, admin = false) {
  let url = `${apiOspos.baseUrl}/ims/committed-transfers`;
  if (!postData.scanningRequired) {
    url = `${apiOspos.baseUrl}/ims/transacted-transfers/quickTransferWithoutSerials`;
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fromBucket: postData.fromBucket,
      toBucket: postData.toBucket,
      model: postData.model,
      requestedQuantity: postData.requestedQuantity,
      committedById: postData.committedById,
      posTransferBatchRefId: postData.posTransferBatchRefId,
      posTransferBatchName: postData.posTransferBatchName,
      receiverSku: postData.receiverSku,
      senderSku: postData.senderSku,
      scanningRequired: postData.scanningRequired,
      commitNotes: postData.commitNotes,
      ...(admin && { strictMode: false })
    })
  });
}
