import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDebounce } from 'use-debounce';
import zipcodes from 'zipcodes';
import countryCityState from 'countrycitystatejson';
import { states, LOCATION_CODE_MAPPINGS, Checkbox, TextField } from './generics';
import { useDistributors, useHttp } from '../../../common/Hooks';
import ConfirmationModal from './BucketCreationConfirmationModal';

const createBucketEntry = ({
  formState,
  generatedBuckets,
  city,
  state_or_province,
  postal_code
}) => {
  const locationBuckets = {
    [formState.location_code]: {
      location: {
        ...Object.entries(formState).reduce((memo, [key, value]) => {
          return { ...memo, [key]: value !== '' ? value : null };
        }, {}),
        city,
        state_or_province,
        postal_code,
        legacy_bucket: generatedBuckets[0].legacy_bucket
      },
      buckets: []
    }
  };

  generatedBuckets.forEach(bucket => {
    const isNewLocation = LOCATION_CODE_MAPPINGS.some(locationCode =>
      bucket.legacy_bucket.includes(locationCode)
    );
    if (isNewLocation) {
      locationBuckets[bucket.legacy_bucket] = {
        location: {
          ...formState,
          location_code: bucket.legacy_bucket,
          city,
          state_or_province,
          postal_code,
          legacy_bucket: bucket.legacy_bucket
        },
        buckets: [bucket]
      };
    } else {
      locationBuckets[formState.location_code].buckets.push(bucket);
    }
  });

  return locationBuckets;
};

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 750
  },
  checkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  locationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& > *': {
      flex: 1
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto'
  }
}));

const useLocationsFilter = () => {
  const [stateAbbreviation, setStateAbbreviation] = useState([]);
  const [debouncedState] = useDebounce(stateAbbreviation, 500);

  const [city, setCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [debouncedCity] = useDebounce(city, 500);

  const [postalCode, setPostalCode] = useState('');
  const [postalCodeOptions, setPostalCodeOptions] = useState([]);
  const [debouncedPostalCode] = useDebounce(postalCode, 500);

  useEffect(() => {
    if (Array.isArray(stateAbbreviation)) {
      // Populate city based on selected stateAbbreviation
      setCityOptions(countryCityState.getCities('US', stateAbbreviation[0]));
      return;
    }
    setCityOptions([]);
  }, [debouncedState]);

  // Populate postal code based on selected stateAbbreviation and City
  useEffect(() => {
    if (stateAbbreviation.length && city && !postalCode) {
      // assigns an array of zipcodes
      setPostalCodeOptions(zipcodes.lookupByName(city, stateAbbreviation[1]));
    }
  }, [debouncedState, debouncedCity]);

  useEffect(() => {
    if (postalCode) {
      const postalInfo = zipcodes.lookup(postalCode) || {};
      if (!city && postalInfo.city) {
        setCity(postalInfo.city);
      }
      if (!stateAbbreviation.length && postalInfo.state) {
        const newState = states.find(([, stateCode]) => stateCode === postalInfo.state);
        setStateAbbreviation(newState);
      }
    }
  }, [debouncedPostalCode]);

  return {
    city: { value: city, options: cityOptions, change: setCity },
    stateLocation: {
      value: stateAbbreviation,
      options: states,
      change: setStateAbbreviation
    },
    postalCode: {
      value: postalCode,
      options: postalCodeOptions,
      change: setPostalCode
    }
  };
};

function BucketCreator() {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    location_code: '',
    managed_by_third_party: false,
    is_fulfillable_at_location: false,
    can_hold_inventory: false,
    can_receive_purchase_orders: false,
    is_active: true,
    distributor: '',
    location_type: '',
    distrubtor_location_id: '',
    address_1: '',
    address_2: '',
    county: '',
    country: 'US'
  });

  const { city, stateLocation, postalCode } = useLocationsFilter();
  const [distributorsList] = useDistributors();

  const [isOpenConfirmationModal, setConfirmationModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [request, response] = useHttp('ims');

  const handleSubmit = async generatedBuckets => {
    const locationBuckets = createBucketEntry({
      formState,
      generatedBuckets,
      city: city.value,
      state_or_province: Array.isArray(stateLocation.value)
        ? stateLocation.value[1]
        : stateLocation.value,
      postal_code: get(postalCode, 'value.zip', get(postalCode, 'value', postalCode))
    });

    await request.post('/bucket-mappings', locationBuckets);
    if (response.status === 422) {
      const excludeFields = ['location.legacy_bucket'];
      const newErrors = Object.entries(response.data).reduce((memo, [key, value]) => {
        // skip if error is included in excludeFields
        if (excludeFields.includes(key) || key.includes('buckets.')) return memo;
        return { ...memo, [key.replace('location.', '')]: value };
      }, {});
      setErrors(newErrors);
    }
    setConfirmationModal(false);
  };

  const closeConfirmationModal = () => setConfirmationModal(false);
  const openConfirmationModal = () => setConfirmationModal(true);

  const clearError = name => {
    setErrors(previousState => {
      const { [name]: _deleted, ...rest } = previousState;
      return rest;
    });
  };

  const handleChange = ({ target: { name, value } }, newValue) => {
    clearError(name);
    setFormState(previousState => ({
      ...previousState,
      [name]: newValue || value
    }));
  };

  const handleToggle = ({ target: { name } }) => {
    setFormState(previousState => ({
      ...previousState,
      [name]: !previousState[name]
    }));
  };

  // TODO: These Auto-compolete components could either be Abstracted to layout components,
  // or converted to the Select currently in Layout components.
  return (
    <>
      <Card component="form" className={classes.paper}>
        <CardContent>
          <TextField
            formState={formState}
            errors={errors}
            name="location_code"
            handleChange={handleChange}
          />

          <div className={classes.checkContainer}>
            <Checkbox
              formState={formState}
              name="managed_by_third_party"
              handleToggle={handleToggle}
            />

            <Checkbox
              formState={formState}
              name="is_fulfillable_at_location"
              handleToggle={handleToggle}
            />
            <Checkbox formState={formState} name="can_hold_inventory" handleToggle={handleToggle} />
            <Checkbox
              formState={formState}
              name="can_receive_purchase_orders"
              handleToggle={handleToggle}
            />
          </div>

          <Autocomplete
            freeSolo
            name="distributor"
            value={formState.distributor}
            onChange={handleChange}
            options={distributorsList}
            renderInput={params => (
              <MuiTextField
                {...params}
                name="distributor"
                onBlur={handleChange}
                error={errors.distributor}
                helperText={get(errors, 'distributor[0]', '')}
                label="Distributor"
                fullWidth
              />
            )}
          />

          <TextField
            name="location_type"
            formState={formState}
            errors={errors}
            handleChange={handleChange}
          />

          <TextField
            name="distrubtor_location_id"
            formState={formState}
            errors={errors}
            handleChange={handleChange}
          />

          <TextField
            name="address_1"
            formState={formState}
            errors={errors}
            handleChange={handleChange}
          />

          <TextField
            name="address_2"
            formState={formState}
            errors={errors}
            handleChange={handleChange}
          />

          <div className={classes.locationContainer}>
            <Autocomplete
              freeSolo
              value={stateLocation.value}
              onChange={(event, newValue) => {
                clearError('state_or_province');
                stateLocation.change(newValue || []);
              }}
              options={stateLocation.options}
              // option should always return a string
              getOptionLabel={option => (Array.isArray(option) ? option[1] : option) || ''}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  label="State"
                  error={errors.state_or_province}
                  helperText={get(errors, 'state_or_province[0]', '')}
                  fullWidth
                />
              )}
            />

            <TextField
              name="county"
              formState={formState}
              errors={errors}
              handleChange={handleChange}
            />

            <Autocomplete
              freeSolo
              value={city.value}
              onChange={(event, newValue) => {
                clearError('city');
                city.change(newValue);
              }}
              options={city.options}
              getOptionLabel={option => option}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  label="City"
                  error={errors.state_or_province}
                  helperText={get(errors, 'state_or_province[0]', '')}
                  fullWidth
                />
              )}
            />
          </div>
          <div className={classes.locationContainer}>
            <Autocomplete
              freeSolo
              value={postalCode.value}
              onChange={(event, newValue) => {
                clearError('postal_code');
                postalCode.change(newValue);
              }}
              options={postalCode.options}
              getOptionLabel={option => (typeof option === 'object' ? option.zip : option)}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  label="Postal Code"
                  error={errors.postal_code}
                  helperText={get(errors, 'postal_code[0]', '')}
                  fullWidth
                />
              )}
            />

            <TextField
              disabled
              name="country"
              formState={formState}
              errors={errors}
              handleChange={handleChange}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            disabled={!isEmpty(errors)}
            variant="contained"
            className={classes.submitButton}
            color="primary"
            onClick={openConfirmationModal}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
      <ConfirmationModal
        form={formState}
        open={isOpenConfirmationModal}
        handleSubmit={handleSubmit}
        handleClose={closeConfirmationModal}
      />
    </>
  );
}

export default BucketCreator;
