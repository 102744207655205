import { useCallback, useEffect, useState } from 'react';
import { useHttp } from '../../../common/Hooks';
import { TransactedReceiptSnapshot } from '../../../common/Types/DbSnapshots/InventoryEventDbSnapshotTypes';
import {
  GetUnReceivableTransactedReceipts,
  UnReceivePOItem,
  UnReceiveResponse,
  UnReceivingStatus,
  UseGetUnReceivableTransactedReceipts,
  UseUnReceivePOItem
} from './UnReceivingTypes';

export function useUnReceivingStatus(status: string): UnReceivingStatus {
  const [message, setMessage] = useState<string>(status);
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
        setError(false);
      }, 4000);
    }
  }, [message]);
  return [message, setMessage, error, setError];
}

export function useGetUnReceivableTransactedReceipts(): UseGetUnReceivableTransactedReceipts {
  const [request, response] = useHttp('transacted-receipts');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getUnReceivableTransactedReceipts = useCallback<GetUnReceivableTransactedReceipts>(
    async poItemId => {
      setIsFetching(true);
      const unReceivableListResponse = await request.get(
        `/can-be-unreceived?poApiPoItemId=${poItemId}`
      );
      let unreceivableItems: TransactedReceiptSnapshot[] = [];
      if (response.ok) {
        unreceivableItems = unReceivableListResponse;
      } else {
        console.info(response);
      }
      setIsFetching(false);
      return unreceivableItems;
    },
    []
  );

  return { getUnReceivableTransactedReceipts, isFetching };
}

export function useUnReceivePOItem(): UseUnReceivePOItem {
  const [request, response] = useHttp('transacted-receipts');
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const unReceivePOItem = useCallback<UnReceivePOItem>(async unReceiveRequest => {
    setIsPosting(true);
    const unReceiveResponse: UnReceiveResponse = await request.post(
      `/${unReceiveRequest.imsTransactionReferenceId}/unreceive`,
      {
        lineItemId: unReceiveRequest.poItemId,
        notes: unReceiveRequest.notes
      }
    );
    unReceiveResponse.requestSuccessful = response.ok;
    setIsPosting(false);
    return unReceiveResponse;
  }, []);

  return { unReceivePOItem, isPosting };
}
