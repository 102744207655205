export const makeRootStyles = theme => ({
  paddingTop: theme.spacing(4)
});

export const makeBinPaperStyles = theme => ({
  textAlign: 'center',
  fontSize: '16px',
  padding: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5)
});

export const makePickItemErrorMessageStyles = theme => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.error.main
});
