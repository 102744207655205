import React, { useState, createContext } from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Search as SearchIcon } from '@material-ui/icons';
import { useQueryWithLocation as useQuery } from '../../common/Hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 500
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

const searchTypeDictionary = {
  imsTransactionReferenceId: 'IMS Transaction Ref ID',
  posTransferBatchRefId: 'Batch Ref ID / Shipment ID',
  posTransferBatchName: 'Batch Name / Shipment Name'
};

const INVENTORY_SEARCH_CONTEXT = 'WPOS3::INVENTORY_SEARCH_CONTEXT';

const inventorySearchContextFromLocalStorage = localStorage.getItem(INVENTORY_SEARCH_CONTEXT);

export const ImsSearchContext = createContext(inventorySearchContextFromLocalStorage);

export default function InventoryTransactionSearchWrapper(props) {
  const { children, onSubmitSearch } = props;
  const classes = useStyles();
  const [searchId, setSearchId] = useState('');
  const urlSearchType = useQuery().get('searchType');
  const [searchType, setSearchType] = useState(
    urlSearchType || inventorySearchContextFromLocalStorage || Object.keys(searchTypeDictionary)[0]
  );

  const submitSearch = event => {
    event.preventDefault();
    onSubmitSearch(searchId, searchType);
  };

  const changeSearch = ({ target: { value } }) => {
    setSearchId(value);
  };

  const changeSearchType = ({ target: { value } }) => {
    if (Object.keys(searchTypeDictionary).includes(value)) {
      localStorage.setItem(INVENTORY_SEARCH_CONTEXT, value);
    }
    setSearchType(value);
  };

  return (
    <>
      <Paper component="form" className={classes.root} onSubmit={submitSearch}>
        <InputBase
          className={classes.input}
          placeholder="Search Transactions"
          inputProps={{ 'aria-label': 'search transactions' }}
          id="search-transaction"
          value={searchId}
          onChange={changeSearch}
        />
        <Select
          labelId="select-transaction-search-type"
          id="select-transaction-search-type"
          value={searchType}
          onChange={changeSearchType}
        >
          {Object.entries(searchTypeDictionary).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <IconButton type="submit" aria-label="search" className={classes.iconButton}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <ImsSearchContext.Provider value={searchType}>{children}</ImsSearchContext.Provider>
    </>
  );
}
