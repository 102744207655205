const initialState = {
  fulfillment: [],
  workorderFulfillmentHistory: [],
  quoteRates: [],
  shippingLabels: [],
  fullfillmentTypesFetched: false,
  errorMsg: '',
  errorField: ''
};

export default function shippingTransactions(state = initialState, action) {
  switch (action.type) {
    case 'FULFILLMENT_TYPES_FULFILLED':
      return {
        ...state,
        fullfillmentTypesFetched: true,
        fulfillmentTypes: action.payload.data
      };
    case 'FULFILLMENT_TYPES_REJECTED':
      return {
        ...state,
        fullfillmentTypesFetched: false,
        fulfillmentTypes: action.payload
      };
    case 'SHIP_METHODS_FULFILLED':
      return {
        ...state,
        shipMethodsFetched: true,
        shipMethods: action.payload.data
      };
    case 'SHIP_METHODS_REJECTED':
      return {
        ...state,
        shipMethodsFetched: false,
        shipMethods: action.payload
      };
    case 'SHIP_COMPANIES_FULFILLED':
      return {
        ...state,
        shipCompaniesFetched: true,
        shipCompanies: action.payload.data
      };
    case 'SHIP_COMPANIES_REJECTED':
      return {
        ...state,
        shipCompaniesFetched: false,
        shipCompanies: action.payload
      };
    case 'FULFILLMENT_STATUS_FULFILLED':
      return {
        ...state,
        fulfillmentStatusFetched: true,
        fulfillmentStatus: action.payload.data
      };
    case 'FULFILLMENT_STATUS_REJECTED':
      return {
        ...state,
        fulfillmentStatusFetched: false,
        fulfillmentStatus: action.payload
      };
    case 'FULFILLMENT_WAREHOUSE_FULFILLED':
      return {
        ...state,
        fulfillmentWarehouseFetched: true,
        fulfillmentWarehouse: action.payload.data
      };
    case 'FULFILLMENT_WAREHOUSE_REJECTED':
      return {
        ...state,
        fulfillmentWarehouseFetched: false,
        fulfillmentWarehouse: action.payload
      };
    case 'CREATE_FULFILLMENT_FULFILLED':
      return {
        ...state,
        fulfillment: action.payload
      };
    case 'CREATE_FULFILLMENT_REJECTED':
      return {
        ...state,
        fullfillmentTypesFetched: false,
        fulfillmentTypes: action.payload
      };
    case 'UPDATE_FULFILLMENT_FULFILLED':
      return {
        ...state,
        fulfillment: action.payload,
        errorMsg: ''
      };
    case 'UPDATE_FULFILLMENT_REJECTED':
      return {
        ...state,
        errorMsg: action.payload.data.error,
        errorField: action.payload.data.field
      };
    case 'ADD_ITEM_TO_PACKAGE_FULFILLED':
      return {
        ...state
        // fulfillment: action.payload,
      };
    case 'ADD_ITEM_TO_PACKAGE_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'FULFILLMENT_HISTORY_FULFILLED':
      return {
        ...state,
        workorderFulfillmentHistory: action.payload
      };
    case 'FULFILLMENT_HISTORY_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'ADD_PACKAGE_TO_FULFILLMENT_FULFILLED':
      return {
        ...state
        // workorderFulfillmentHistory: action.payload,
      };
    case 'ADD_PACKAGE_TO_FULFILLMENT_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'DELETE_PACKAGE_FROM_FULFILLMENT_FULFILLED':
      return {
        ...state
        // workorderFulfillmentHistory: action.payload,
      };
    case 'DELETE_PACKAGE_FROM_FULFILLMENT_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'DELETE_PACKAGE_PRODUCT_REJECTED_FULFILLED':
      return {
        ...state
        // workorderFulfillmentHistory: action.payload,
      };
    case 'DELETE_PACKAGE_PRODUCT_REJECTED_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'ADD_PACKAGE_PRODUCT_REJECTED_FULFILLED':
      return {
        ...state
        // workorderFulfillmentHistory: action.payload,
      };
    case 'ADD_PACKAGE_PRODUCT_REJECTED_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'UPDATE_PACKAGE_PRODUCT_FULFILLED':
      return {
        ...state
        // workorderFulfillmentHistory: action.payload,
      };
    case 'UPDATE_PACKAGE_PRODUCT_REJECTED':
      return {
        ...state
        // fullfillmentTypesFetched: false,
        // fulfillmentTypes: action.payload,
      };
    case 'GET_QUOTE_FOR_FULFILLMENT_REJECTED':
      return {
        ...state,
        quoteRates: action.payload
      };
    case 'GET_QUOTE_FOR_FULFILLMENT_FULFILLED':
      return {
        ...state,
        quoteRates: action.payload
      };
    case 'CREATE_SHIPMENT_FULFILLMENT_REJECTED':
      return {
        ...state
        // shippingLabels: action.payload.shippingLabelURLS,
      };
    case 'CREATE_SHIPMENT_FULFILLMENT_FULFILLED':
      return {
        ...state,
        shippingLabels: action.payload.shippingLabelURLS
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
