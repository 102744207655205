import React, { useState, useContext, useCallback } from 'react';
import { Grid, TextField, FormHelperText } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useHttp } from '../../../common/Hooks';
import { goodSound, badSound } from '../../../common/playSound';
import BinContentsTable from './BinContentsTable';
import { AuthContext } from '../../../AuthContext';
import GlueButton from '../../Presentational/GlueButton.tsx';
import ScanEventSubscriber from '../../../common/ScanEventListening/ScanEventSubscriber';
import { looksLikeUpc } from '../../../helpers/upcHelpers';

export default function BinContentsStagingSpace(props) {
  const { isAdding, binId, refreshBinContents, binExactMatch } = props;
  const [scannedUpc, setScannedUpc] = useState('');
  const [upcScanError, setUpcScanError] = useState(false);
  const [binSubmissionErrors, setBinSubmissionErrors] = useState({});
  const [stagedItems, setStagedItems] = useState([]);
  const [request, response] = useHttp('ims');
  const auth = useContext(AuthContext);

  const handleUpc = e => setScannedUpc(e.target.value.trim());

  async function confirmAndAddUpcScanToStaging() {
    if (isEmpty(scannedUpc)) {
      return false;
    }
    const upcResponse = await request.get(`/inventory/product/search?upc=${scannedUpc}&exact=1`);
    if (!response.ok || isEmpty(upcResponse)) {
      setScannedUpc('');
      setUpcScanError(true);
      badSound();
      return false;
    }
    const indexToIncrement = stagedItems.findIndex(upc => upc.upc === scannedUpc);
    if (indexToIncrement > -1) {
      const newStagedItems = stagedItems.slice();
      newStagedItems[indexToIncrement].updateQty += 1;
      setStagedItems(newStagedItems);
    } else {
      setStagedItems([
        ...stagedItems,
        {
          upc: upcResponse.upc,
          model: upcResponse.model,
          updateQty: 1
        }
      ]);
    }
    goodSound();
    setUpcScanError(false);
    return true;
  }

  async function submitStagedItems() {
    const stagedItemsWithoutModel = stagedItems.map(({ model, ...item }) => item);
    const submission = {
      isAdding,
      items: stagedItemsWithoutModel,
      submittedBy: auth.username
    };
    await request.put(`/bins/${binId}/items`, submission);
    if (response.ok) {
      setStagedItems([]);
      refreshBinContents();
      setBinSubmissionErrors({});
      setUpcScanError(false);
      goodSound();
    } else {
      setBinSubmissionErrors(response.data);
      badSound();
    }
  }

  async function removeStagedItem(selectedUpc) {
    const newStagedItems = stagedItems.filter(item => item.upc !== selectedUpc);
    setStagedItems(newStagedItems);
  }

  async function quantitySetter(addingFlag, selectedUpc) {
    if (addingFlag) {
      const indexToIncrement = stagedItems.findIndex(upc => upc.upc === selectedUpc);
      const newStagedItems = stagedItems.slice();
      newStagedItems[indexToIncrement].updateQty += 1;
      setStagedItems(newStagedItems);
    } else {
      const indexToDecrement = stagedItems.findIndex(upc => upc.upc === selectedUpc);
      const newStagedItems = stagedItems.slice();
      newStagedItems[indexToDecrement].updateQty -= 1;
      if (newStagedItems[indexToDecrement].updateQty < 1) {
        newStagedItems[indexToDecrement].updateQty = 1;
      }
      setStagedItems(newStagedItems);
    }
  }

  /** @type {import('../../../common/ScanEventListening/ScanEventListeningTypes').ScanEventHandler} */
  const handleUnfocusedScan = useCallback(scanEvent => {
    if (looksLikeUpc(scanEvent.scannedValue)) {
      scanEvent.stopPropagation();
    }
  }, []);

  return (
    <ScanEventSubscriber onScan={handleUnfocusedScan}>
      <Grid container style={{ paddingTop: '20px' }}>
        <Grid container spacing={5} justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Scan UPC"
              id="scan-upc-field"
              variant="outlined"
              placeholder="Scan UPC"
              value={scannedUpc}
              onChange={handleUpc}
              onKeyDown={async e => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                  const success = await confirmAndAddUpcScanToStaging();
                  if (success) {
                    setScannedUpc('');
                  }
                }
              }}
              disabled={!binExactMatch}
            />
          </Grid>
          {upcScanError ? (
            <Grid item xs={8}>
              <FormHelperText error>UPC Not Found</FormHelperText>
            </Grid>
          ) : (
            <Grid item xs={8} />
          )}

          <Grid item xs={12}>
            <BinContentsTable
              stagingContents
              scannedItems={stagedItems}
              removeStagedItem={removeStagedItem}
              quantitySetter={quantitySetter}
              binSubmissionErrors={binSubmissionErrors}
              clearBinSubmissionErrors={() => setBinSubmissionErrors({})}
            />
          </Grid>

          <Grid item xs={8} />
          <Grid item xs={4}>
            <GlueButton
              fullWidth
              onClick={submitStagedItems}
              endIcon={isAdding ? 'add' : 'clear'}
              disabled={!binExactMatch}
            >
              {isAdding ? 'Add Items' : 'Remove Items'}
            </GlueButton>
          </Grid>
        </Grid>
      </Grid>
    </ScanEventSubscriber>
  );
}
