import React, { useState } from 'react';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { useHttp } from '../../../common/Hooks';

const StyledTextField = styled(TextField)({
  marginLeft: 16,
  marginRight: 16
});

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const defaultErrorState = {
  status: null,
  errorText: []
};

export default function CreateBucketGroupDialog(props) {
  const { open } = props;
  const [bucketGroupName, setBucketGroupName] = useState('');
  const [errorState, setErrorState] = useState(defaultErrorState);
  const [countUnsourcedCommitted, setCountUnsourcedCommitted] = useState(true);
  const [inventoryBuffer, setInventoryBuffer] = useState(0);
  const [walmartFulfillmentCenterId, setWalmartFulfillmentCenterId] = useState('');
  const [amazonSupplySourceId, setAmazonSupplySourceId] = useState('');
  const [request, response] = useHttp('ims/bucket-groups');

  function resetErrorState() {
    setErrorState(defaultErrorState);
  }

  function handleNameChange(value) {
    setBucketGroupName(value);
    resetErrorState();
  }

  function handleWalmartFulfillmentCenterIdChange(value) {
    setWalmartFulfillmentCenterId(value);
    resetErrorState();
  }

  function handleAmazonSupplySourceIdChange(value) {
    setAmazonSupplySourceId(value);
    resetErrorState();
  }

  function handleCheckChange() {
    setCountUnsourcedCommitted(!countUnsourcedCommitted);
    resetErrorState();
  }

  function handleBufferChange(value) {
    setInventoryBuffer(value);
    resetErrorState();
  }

  function handleClose() {
    resetErrorState();
    props.handleClose();
  }

  async function submitNewBucketGroup() {
    const submission = {
      bucketGroup: snakeCase(bucketGroupName).toUpperCase(),
      shouldCountUnsourcedCommittedInGroup: countUnsourcedCommitted,
      groupInventoryBuffer: inventoryBuffer,
      walmartFulfillmentCenterId:
        walmartFulfillmentCenterId === '' ? null : walmartFulfillmentCenterId,
      amazonSupplySourceId: amazonSupplySourceId === '' ? null : amazonSupplySourceId
    };

    await request.post(`/`, submission);
    if (response.ok) {
      setBucketGroupName('');
      setCountUnsourcedCommitted(false);
      setInventoryBuffer(0);
      setWalmartFulfillmentCenterId('');
      setAmazonSupplySourceId('');
      handleClose();
    } else {
      setErrorState({
        status: response.status,
        data: response.data
      });
    }
  }

  function bucketFields(field, key) {
    return (
      <div>
        <FormControl key={key}>
          <StyledTextField
            name={camelCase('Bucket Group Name')}
            id={camelCase('Bucket Group Name')}
            label="Bucket Group Name"
            type="text"
            margin="normal"
            onChange={event => handleNameChange(event.target.value)}
          />
        </FormControl>
        <FormControl type="checkbox" key={key}>
          <Checkbox color="secondary" onChange={handleCheckChange} defaultChecked />
          <label>Should Count Unsourced Committed?</label>
        </FormControl>
        <FormControl key={key}>
          <StyledTextField
            name={camelCase('Inventory Buffer')}
            id={camelCase('Inventory Buffer')}
            label="Inventory Buffer"
            type="number"
            margin="normal"
            defaultValue="0"
            inputProps={{
              min: '0',
              step: '1'
            }}
            onChange={event => handleBufferChange(event.target.value)}
          />
        </FormControl>
        <FormControl key={key}>
          <StyledTextField
            name={camelCase('Walmart Fulfillment Center ID')}
            id={camelCase('Walmart Fulfillment Center ID')}
            label="Walmart Fulfillment Center ID"
            type="text"
            margin="normal"
            onChange={event => handleWalmartFulfillmentCenterIdChange(event.target.value)}
            style={{ minWidth: '200px' }}
          />
        </FormControl>
        <FormControl key={key}>
          <StyledTextField
            name={camelCase('Amazon Supply Source Id')}
            id={camelCase('Amazon Supply Source Id')}
            label="Amazon Supply Source Id"
            type="text"
            margin="normal"
            onChange={event => handleAmazonSupplySourceIdChange(event.target.value)}
            style={{ minWidth: '200px' }}
          />
        </FormControl>
      </div>
    );
  }

  return (
    <Dialog fullWidth maxWidth={false} onClose={handleClose} open={open}>
      <DialogContent>
        <Button variant="contained" onClick={handleClose}>
          <Close />
          Close
        </Button>
        <DialogTitle>New Bucket Group</DialogTitle>
        <form>
          <StyledFormControl>{(item, key) => bucketFields(item, key)}</StyledFormControl>
          <FormControl>
            <Button variant="contained" style={{ marginTop: 25 }} onClick={submitNewBucketGroup}>
              Create
            </Button>
          </FormControl>
        </form>
        {errorState.status
          ? Object.keys(errorState.data).map(key => (
              <Alert
                key={key.concat(Object.keys(errorState.data).indexOf(key))}
                severity="error"
                style={{ margin: 10 }}
              >
                {errorState.data[key][0]}
              </Alert>
            ))
          : null}
      </DialogContent>
    </Dialog>
  );
}
