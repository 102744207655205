import { useEffect, useCallback, useState } from 'react';
import mitt from 'mitt';
import {
  ScanEventBusInterface,
  ScanEventHandler,
  ScanEventTypes,
  ScanListenerInterface
} from './ScanEventListeningTypes';
import ScanListener, { SCAN_EVENT_NAME } from './ScanListener';
import ScanEventBus from './ScanEventBus';

const emitter = mitt<ScanEventTypes>();

export function useScanListener(): ScanListenerInterface | null {
  const [scanListener, setScanListener] = useState<ScanListenerInterface | null>(null);
  useEffect(() => {
    console.log(`Scanned listener started`);
    const initializedScanListener = new ScanListener(emitter);
    initializedScanListener.start();
    setScanListener(initializedScanListener);
    return () => {
      console.log(`Scanned listener stopped`);
      initializedScanListener.stop();
    };
  }, []);

  return scanListener;
}

export function useScanEventBus(): ScanEventBusInterface {
  const [scanEventBus] = useState<ScanEventBusInterface>(new ScanEventBus());

  const scanListener = useScanListener();

  const scanHandler = useCallback<ScanEventHandler>(
    scanEvent => {
      console.log(`Scanned value: ${scanEvent.scannedValue}`);
      const eventDispatched = scanEventBus.dispatch(scanEvent);
      if (!eventDispatched) {
        console.log('Scan event not executed by any handler when dispatched to the bus.');
      }
    },
    [scanEventBus]
  );

  useEffect(() => {
    if (scanListener && scanListener.listening) {
      console.log('Scan event emitter on');
      scanListener.emitter.on(SCAN_EVENT_NAME, scanHandler);
    }
    return () => {
      if (scanListener) {
        console.log('Scan event emitter off');
        scanListener.emitter.off(SCAN_EVENT_NAME, scanHandler);
      }
    };
  }, [scanListener, scanHandler]);

  return scanEventBus;
}
