import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { useHttp } from '../common/Hooks';

export const UserContext = React.createContext();

export default function User({ children }) {
  const [user, setUser] = useState('');
  const [toUpdate, setToUpdate] = useState(true);
  const [request, response] = useHttp('user/role');
  const authorized = useContext(AuthContext);

  useEffect(() => {
    async function getUserRole() {
      const { data } = await request.get();
      if (response.ok) {
        setUser(data);
        setToUpdate(false);
        if (data === 'developer') {
          localStorage.setItem(`${authorized.username}::is_super_user`, 1);
        }
      }
    }

    const cachedUserRole = localStorage.getItem(`${authorized.username}::user_role`);
    if (authorized.auth && toUpdate) {
      if (cachedUserRole) {
        setUser(cachedUserRole);
        setToUpdate(false);
        if (cachedUserRole === 'developer') {
          localStorage.setItem(`${authorized.username}::is_super_user`, 1);
        }
      } else {
        getUserRole();
      }
    }
  }, []);

  async function sudoSetUserRole(newUserRole) {
    if (authorized.auth) {
      const { data } = await request.get();
      if (data === 'developer') {
        setUser(newUserRole);
        localStorage.setItem(`${authorized.username}::user_role`, newUserRole);
        return true;
      }
    }
    return false;
  }

  return (
    <UserContext.Provider value={{ role: user, update: setToUpdate, sudoSetUserRole }}>
      {children}
    </UserContext.Provider>
  );
}
