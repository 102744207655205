import React, { useState, useEffect, useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const checkIfPaidOrBilledAreNull = (billed, paid) => {
  return (billed || '') === '' || (paid || '') === '';
};
const checkIfPaidMatchesBilled = (billed, paid) => {
  return parseFloat(paid).toFixed(2) === parseFloat(billed).toFixed(2);
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 25,
    maxWidth: '.8vm'
  },
  container: {
    maxHeight: '70vh'
  },
  paidAmountMatchesRow: theme.misc.successAlert,
  paidAmountDoesntMatchRow: theme.misc.warningAlert,
  inputInvalidRow: theme.misc.errorAlert,
  inputColumn: {
    minWidth: 235
  }
}));

function ShippingReconciliationTable({ rowsData, stagedUpdates, setStagedUpdates }) {
  const classes = useStyles();

  const rows = useMemo(() => {
    return Object.values(rowsData);
  }, [rowsData]);

  const hasReconciliationResult = useMemo(() => {
    const rowWithReconciliationResult = rows.find(row => !isEmpty(row.reconciliation_result));
    return !isEmpty(rowWithReconciliationResult);
  }, [rows]);

  const allRowsAlreadyBilled = useMemo(() => {
    const rowNotBilled = rows.find(row => isEmpty(row.reported_billed_charge));
    return isEmpty(rowNotBilled);
  }, [rows]);

  const setBilledToQuoted = useCallback(
    rowsArg => {
      rowsArg.forEach(row => {
        setStagedUpdates(prevState => ({
          ...prevState,
          [row.tracking_number]: {
            ...prevState[row.tracking_number],
            reported_billed_charge: row.shipping_quote
          }
        }));
      });
    },
    [setStagedUpdates]
  );

  const setPaidToBilled = useCallback(
    rowsArg => {
      rowsArg.forEach(row => {
        setStagedUpdates(prevState => {
          const stagedReportedBilled = (prevState[row.tracking_number] || {})
            .reported_billed_charge;
          return {
            ...prevState,
            [row.tracking_number]: {
              ...prevState[row.tracking_number],
              reported_billed_charge: stagedReportedBilled || row.reported_billed_charge || '',
              payment_to_apply: stagedReportedBilled || row.reported_billed_charge || ''
            }
          };
        });
      });
    },
    [setStagedUpdates]
  );

  const setBilledAndPaidToQuoted = useCallback(
    rowsArg => {
      setBilledToQuoted(rowsArg);
      setPaidToBilled(rowsArg);
    },
    [setBilledToQuoted, setPaidToBilled]
  );

  const handleCheck = useCallback(
    (event, row) => {
      if (isEmpty(stagedUpdates[row.tracking_number])) {
        if ((row.reported_billed_charge || '') === '') {
          setBilledAndPaidToQuoted([row]);
        } else {
          setPaidToBilled([row]);
        }
      } else {
        const newState = { ...stagedUpdates };
        delete newState[row.tracking_number];
        setStagedUpdates(newState);
      }
    },
    [stagedUpdates, setStagedUpdates, setPaidToBilled, setBilledAndPaidToQuoted]
  );

  const handleInputChange = useCallback(
    (event, row) => {
      const { value, name } = event.target;
      if (isEmpty(stagedUpdates[row.tracking_number])) {
        setStagedUpdates(prevState => ({
          ...prevState,
          [row.tracking_number]: {
            reported_billed_charge: row.reported_billed_charge,
            payment_to_apply: row.shipping_cost_paid,
            [name]: value
          }
        }));
      } else {
        setStagedUpdates(prevState => ({
          ...prevState,
          [row.tracking_number]: {
            ...prevState[row.tracking_number],
            [name]: value
          }
        }));
      }
    },
    [stagedUpdates, setStagedUpdates]
  );

  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="apply payments table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Apply Payment</TableCell>
              <TableCell>tracking_number</TableCell>
              <TableCell>ship_company</TableCell>
              {hasReconciliationResult && (
                <>
                  <TableCell>reconciled_difference</TableCell>
                  <TableCell>reconciliation_result</TableCell>
                </>
              )}
              <TableCell>
                {!allRowsAlreadyBilled && (
                  <Typography component="span">
                    <IconButton
                      aria-label="set-billed-and-paid-to-quoted"
                      onClick={() => setBilledAndPaidToQuoted(rows)}
                    >
                      <DoubleArrowIcon />
                    </IconButton>
                  </Typography>
                )}
                shipping_quote
              </TableCell>
              <TableCell>
                {!allRowsAlreadyBilled && (
                  <Typography component="span">
                    <IconButton
                      aria-label="set-billed-to-quoted"
                      onClick={() => setBilledToQuoted(rows)}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Typography>
                )}
                reported_billed_charge
              </TableCell>
              <TableCell className={classes.inputColumn}>
                {!allRowsAlreadyBilled && (
                  <Typography component="span">
                    <IconButton
                      aria-label="set-paid-to-billed"
                      onClick={() => setPaidToBilled(rows)}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Typography>
                )}
                payment_to_apply
              </TableCell>
              <TableCell>fulfillment_warehouse</TableCell>
              <TableCell>date_shipped</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const sendUpdateCheckboxId = `send-update-checkbox-id-${row.tracking_number}`;
              const matchingUpdate = stagedUpdates[row.tracking_number];
              const checked = !isEmpty(matchingUpdate);
              const paidAmountMatches =
                checked &&
                checkIfPaidMatchesBilled(
                  matchingUpdate.reported_billed_charge || row.reported_billed_charge,
                  matchingUpdate.payment_to_apply
                );
              const inputInvalid =
                checked &&
                checkIfPaidOrBilledAreNull(
                  matchingUpdate.reported_billed_charge || row.reported_billed_charge,
                  matchingUpdate.payment_to_apply
                );
              return (
                <TableRow
                  hover={!checked}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.tracking_number}
                  className={
                    !checked
                      ? ''
                      : inputInvalid
                      ? classes.inputInvalidRow
                      : paidAmountMatches
                      ? classes.paidAmountMatchesRow
                      : classes.paidAmountDoesntMatchRow
                  }
                >
                  <TableCell
                    padding="checkbox"
                    style={{ cursor: 'pointer' }}
                    onClick={event => handleCheck(event, row)}
                  >
                    <Checkbox
                      checked={checked}
                      color="primary"
                      inputProps={{ 'aria-labelledby': sendUpdateCheckboxId }}
                    />
                  </TableCell>
                  <TableCell id={sendUpdateCheckboxId}>{row.tracking_number}</TableCell>
                  <TableCell>{row.ship_company}</TableCell>
                  {hasReconciliationResult && (
                    <>
                      <TableCell>{row.reconciled_difference}</TableCell>
                      <TableCell>{row.reconciliation_result}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {!hasReconciliationResult && (
                      <Typography component="span">
                        <IconButton
                          aria-label="set-billed-and-paid-to-quoted"
                          onClick={() => setBilledAndPaidToQuoted([row])}
                        >
                          <DoubleArrowIcon />
                        </IconButton>
                      </Typography>
                    )}
                    <Typography component="span">{row.shipping_quote}</Typography>
                  </TableCell>
                  <TableCell
                    className={isEmpty(row.reported_billed_charge) ? classes.inputColumn : ''}
                  >
                    {!isEmpty(row.reported_billed_charge) ? (
                      row.reported_billed_charge
                    ) : (
                      <>
                        <IconButton
                          aria-label="set-billed-to-quoted"
                          onClick={() => setBilledToQuoted([row])}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                        <Input
                          type="number"
                          name="reported_billed_charge"
                          inputProps={{
                            min: '0',
                            step: '0.01'
                          }}
                          value={
                            isEmpty(matchingUpdate)
                              ? ''
                              : matchingUpdate.reported_billed_charge || ''
                          }
                          onChange={event => handleInputChange(event, row)}
                        />
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="set-paid-to-billed"
                      onClick={() => setPaidToBilled([row])}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                    <Input
                      type="number"
                      name="payment_to_apply"
                      inputProps={{
                        min: '0',
                        step: '0.01'
                      }}
                      value={isEmpty(matchingUpdate) ? '' : matchingUpdate.payment_to_apply || ''}
                      onChange={event => handleInputChange(event, row)}
                    />
                  </TableCell>
                  <TableCell>{row.fulfillment_warehouse}</TableCell>
                  <TableCell>{row.date_shipped}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ShippingReconciliationTable;
