import React from 'react';
import { Alert } from '@material-ui/lab';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { uniqueId } from 'lodash';
import GlueButton from '../../Presentational/GlueButton.tsx';

const ServerErrorDialog = ({ onClose, open, serverErrors }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <span role="img" aria-label="skull emoji">
          💀
        </span>{' '}
        Unexpected server error
      </DialogTitle>
      <DialogContent>
        {serverErrors.map(error => (
          <Alert severity="error" key={uniqueId('hoServErr')}>
            {error}
          </Alert>
        ))}
        <DialogContentText style={{ marginTop: '1em' }}>
          Click OK to refresh the page and try again.
        </DialogContentText>
      </DialogContent>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1em' }}
      >
        {' '}
        <GlueButton endIcon="close" onClick={onClose} style={{ marginLeft: '0.5em' }}>
          OK
        </GlueButton>
      </div>
    </Dialog>
  );
};

export default ServerErrorDialog;
