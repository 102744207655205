import { Modal, Backdrop, Fade, Paper, createStyles, makeStyles, Typography, Grid, Icon, IconButton, Color } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { GlueTheme } from '../../../../GlueThemeType';
import GlueButton from '../../../Presentational/GlueButton';
import GlueTooltip from '../../../Presentational/GlueTooltip';
import { PickListContext } from '../PickListContext';
import PickListPrintView from './PickListPrintView';
import { LIST_TYPE_ENUM } from '../PickListTypes';
import { ALL_LOCATIONS } from '../../../../actions/locationActions';
import { get, isEmpty } from 'lodash';

const useStyles = makeStyles((theme: GlueTheme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(8),
      maxWidth: 2550,
      maxHeight: 700,
      overflow:'scroll',
    },
    button: {
      margin: theme.spacing(4, 0)
    },
    closeButton: {
      margin: theme.spacing(-6, 0, 0, 7)
    },
    warningMessage: {
      marginBottom: theme.spacing(2)
    },
    printIcon: {
      display: 'inherit'
    },
    alertTitle: {
      fontWeight: 'bold'
    }
  })
);

export type PrintSelectedPickItemsModalProps = {

}

export default function PrintSelectedPickItemsModal({

}: PrintSelectedPickItemsModalProps) {

  const classes = useStyles();
  const {
    itemsToPrint,
    setItemsToPrint,
    queryParams,
    printPickListErrors,
    setPrintPickListErrors,
    printPickListSuccess,
    setPrintPickListSuccess,
    loadingPrintPickList,
    getPickList,
    loadingPickList,
  } = useContext(PickListContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loadingPrintPickList) {
      return;
    }
    setOpen(false);
    setPrintPickListSuccess(false);
    setPrintPickListErrors({});
    if (printPickListSuccess) {
      setItemsToPrint([]);
      getPickList();
    }
  };

  const isPrinted = queryParams.listType === LIST_TYPE_ENUM.PRINTED;
  let canPrint = false;

  let tooltipText = 'Please select items to print.';
  if (isPrinted) {
    tooltipText = 'Printed items cannot be reprinted.';
  }
  if (itemsToPrint.length > 0) {
    tooltipText = 'Print selected items for picking.';
    canPrint = true;
  } 
  if (queryParams.warehouse === ALL_LOCATIONS) {
    tooltipText = 'Please select a specific warehouse to print.';
    canPrint = false;
  }

  if (loadingPickList) {
    tooltipText = 'Cannot print while loading pick list.';
    canPrint = false;
  }

  let alertMessage = 'Clicking the "Print Selected Rows" ' + 
    'button will mark these items as Printed. If you click ' +
    '"Cancel" on the print dialog, they will still be considered printed.'
  let alert = (
    <Alert className={classes.warningMessage} severity={'warning'}>
      <AlertTitle className={classes.alertTitle}>Warning</AlertTitle>
      {alertMessage}
    </Alert>
  );
  if (loadingPrintPickList) {
    alertMessage = 'Recording Print information.'
    alert = (
      <Alert className={classes.warningMessage} severity={'info'}>
        <AlertTitle className={classes.alertTitle}>Info</AlertTitle>
        {alertMessage}
      </Alert>
    );
  }
  const getErrorMessageString = (errorProp: string) => {
    let errorArray = get(printPickListErrors.message, errorProp, []);
    let messageString = errorArray.join('');
    if (messageString) {
      return `${messageString} \n`;
    }
    return messageString;
  }
  if (!isEmpty(printPickListErrors)) {
    alertMessage = get(printPickListErrors, 'error') + '. '
      + getErrorMessageString('printedBy')
      + getErrorMessageString('items') + '\n';
    alert = (
      <Alert className={classes.warningMessage} severity='error'>
        <AlertTitle className={classes.alertTitle}>Error</AlertTitle>
        {alertMessage}
      </Alert>
    );
  }
  if (printPickListSuccess) {
    alertMessage = 'Items printed successfully.'
    alert = (
      <Alert className={classes.warningMessage} severity={'success'}>
        <AlertTitle className={classes.alertTitle}>Success</AlertTitle>
        {alertMessage}
      </Alert>
    );
  }

  return (
    <div>
      <div className={classes.button}>
            <GlueTooltip title={tooltipText}>
                <span>
                  <GlueButton
                    disabled={!canPrint}
                    size={'large'}
                    onClick={handleOpen}
                    endIcon='print'>
                    Print Selected Rows
                  </GlueButton>
                </span>
              </GlueTooltip>
          </div>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="print-pick-list-modal"
        aria-describedby="print-pick-list-modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Grid container spacing={1} xs={12} justify={'flex-end'}>
              <Grid item xs={1}>
                <IconButton
                  disabled={loadingPrintPickList}
                  className={classes.closeButton}
                  onClick={handleClose}
                  aria-label="close-print-pick-list-modal"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={0} xs={12}>
              <Grid item xs={12}>
                {alert}
              </Grid>
            </Grid>
            <Grid container spacing={0} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Print Preview
                </Typography>
              </Grid>
            </Grid>
            <PickListPrintView 
              onClose={handleClose}
            />
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}