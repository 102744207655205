import React, { useState, useEffect, useRef } from 'react';
import { startCase } from 'lodash';
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHttp } from '../../common/Hooks';
import GlueButton from '../Presentational/GlueButton.tsx';

function newFields(product) {
  const fields = {
    serialNumberTracked: product.serial_number_tracked === 1,
    masterCartonCode: product.master_carton_code,
    masterBoxQty: product.master_box_qty,
    numOfSerialsPerItem: product.num_of_serials_per_item,
    upc: product.upc,
    alternateUpc: product.alternate_upc
  };
  return fields;
}

export default function ScanSetup(props) {
  const { closeScanSetup, product, refreshProduct } = props;
  const [fields, setFields] = useState(newFields(product));
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);

  const [request, response] = useHttp('ims/scan-setup/updateProductManagerProduct');

  function resetSuccessError() {
    setError({});
    setSuccess(false);
  }

  useEffect(() => {
    setFields(newFields(product));
  }, [product]);

  async function updateScanSetup() {
    resetSuccessError();
    const sendFields = {
      ...fields,
      model: product.model
    };
    if (sendFields.serialNumberTracked) {
      sendFields.masterBoxQty = null;
    } else {
      sendFields.numOfSerialsPerItem = null;
    }
    const updatedSerials = await request.put(sendFields);
    refreshProduct();
    if (response.ok) {
      setSuccess(true);
    } else {
      setError(updatedSerials);
    }
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div>
      <h2 style={{ marginBottom: '2em' }}>Scan Setup</h2>
      {Object.entries(error).length > 0 && (
        <Alert severity="error" style={{ marginBottom: '2em' }}>
          {Object.values(error)[0]}
        </Alert>
      )}
      {success && (
        <Alert severity="success" style={{ marginBottom: '2em' }}>
          Updated!
        </Alert>
      )}
      <form style={{ marginBottom: '2em' }}>
        {Object.entries(fields).map(([k, v]) => {
          if (k === 'serialNumberTracked') {
            return (
              <FormControl variant="outlined" fullWidth>
                <InputLabel ref={inputLabel} htmlFor={k}>
                  {startCase(k)}
                </InputLabel>
                <Select
                  name={k}
                  value={v}
                  labelWidth={labelWidth}
                  InputProps={{ name: k, id: k }}
                  onChange={event => {
                    setFields({
                      ...fields,
                      [event.target.name]: event.target.value === 'true'
                    });
                    resetSuccessError();
                  }}
                  // eslint-disable-next-line no-prototype-builtins
                  error={error.hasOwnProperty(k)}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
            );
          }
          return (
            <TextField
              style={{ marginTop: 15 }}
              fullWidth
              name={k}
              disabled={
                (k === 'masterBoxQty' && fields.serialNumberTracked) ||
                (k === 'numOfSerialsPerItem' && !fields.serialNumberTracked)
              }
              onChange={event => {
                setFields({
                  ...fields,
                  [event.target.name]: event.target.value
                });
                resetSuccessError();
              }}
              variant="outlined"
              label={startCase(k)}
              value={v}
              // eslint-disable-next-line no-prototype-builtins
              error={error.hasOwnProperty(k)}
            />
          );
        })}
      </form>
      <GlueButton onClick={updateScanSetup} style={{ marginRight: '0.5em' }} endIcon="save">
        Save
      </GlueButton>
      <GlueButton onClick={closeScanSetup} endIcon="close" variant="outlined">
        Close
      </GlueButton>
    </div>
  );
}
