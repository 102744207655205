import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

function TransactionSerialExpansionPanel({ serial }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
      component="li"
      key={serial.scanned_serial_number}
      expanded={expanded === serial.scanned_serial_number}
      onChange={handleChange(serial.scanned_serial_number)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id={serial.scanned_serial_number}>
        <Typography className={classes.heading}>{serial.scanned_serial_number}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          {serial.scanned_upc ? (
            <Typography>
              <strong>Scanned UPC: </strong>
              {serial.scanned_upc}
            </Typography>
          ) : null}
          {serial.scanned_at ? (
            <Typography>
              <strong>Scanned At: </strong>
              {serial.scanned_at}
            </Typography>
          ) : null}
          {serial.old_location_code ? (
            <Typography>
              <strong>Old Location: </strong>
              {serial.old_location_code}
            </Typography>
          ) : null}
          {serial.new_location_code ? (
            <Typography>
              <strong>New Location: </strong>
              {serial.new_location_code}
            </Typography>
          ) : null}
          {serial.old_condition_code ? (
            <Typography>
              <strong>Old Condition: </strong>
              {serial.old_condition_code}
            </Typography>
          ) : null}
          {serial.new_condition_code ? (
            <Typography>
              <strong>New Condition: </strong>
              {serial.new_condition_code}
            </Typography>
          ) : null}
          {serial.old_disposition_code ? (
            <Typography>
              <strong>Old Disposition: </strong>
              {serial.old_disposition_code}
            </Typography>
          ) : null}
          {serial.new_disposition_code ? (
            <Typography>
              <strong>New Disposition: </strong>
              {serial.new_disposition_code}
            </Typography>
          ) : null}
          {serial.from_disposition_code ? (
            <Typography>
              <strong>From Disposition: </strong>
              {serial.from_disposition_code}
            </Typography>
          ) : null}
          {serial.to_disposition_code ? (
            <Typography>
              <strong>To Disposition: </strong>
              {serial.to_disposition_code}
            </Typography>
          ) : null}
          {serial.receiving_disposition_code ? (
            <Typography>
              <strong>Receiving Disposition: </strong>
              {serial.receiving_disposition_code}
            </Typography>
          ) : null}
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <MuiLink component={Link} to={`/inventory-serials/${serial.fk_inventory_serial_id}`}>
          Serial Details
        </MuiLink>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}

export default TransactionSerialExpansionPanel;
