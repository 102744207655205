export default function playSound(filename) {
  const audio = new Audio(`${process.env.PUBLIC_URL}/${filename}`);
  audio.play();
}

export function goodSound() {
  playSound('Good.mp3');
}

export function badSound() {
  playSound('Bad.mp3');
}

export function unReceiveSound() {
  playSound('Unreceive.mp3');
}
