const initialState = {
  poItemReceived: [],
  poItemReceivedFulfilled: false,
  purchaseOrders: [],
  purchaseOrdersFulfilled: false,
  purchaseOrder: [],
  purchaseOrderFulfilled: false,
  vendorCreated: [],
  vendorCreatedFulfilled: false,
  vendors: [],
  vendorsFulfilled: false,
  vendor: [],
  vendorFulfilled: false,
  vendorError: false
};

export default function purchasing(state = initialState, action) {
  switch (action.type) {
    case 'PURCHAE_ORDER_FINISHED':
      return {
        ...state,
        purchaseOrder: action.payload.data
      };
    case 'READY_TO_CREATE_PO':
      return {
        ...state,
        purchaseOrderFulfilled: false
      };
    case 'VENDOR_CREATE_FULFILLED':
      return {
        ...state,
        vendorCreated: action.payload.data,
        vendorCreatedFulfilled: true,
        vendorsFulfilled: false,
        vendorError: false
      };
    case 'VENDOR_CREATE_REJECTED':
      return {
        ...state,
        vendorCreated: action.payload,
        vendorCreatedFulfilled: false,
        vendorError: true
      };
    case 'CLEAR_VENDOR_FIELDS':
      return {
        ...state,
        vendorError: false
      };

    case 'ERROR_CLEARED':
      return {
        ...state,
        vendorCreated: action.payload,
        vendorCreatedFulfilled: false
      };

    case 'GET_VENDOR_FULFILLED':
      return {
        ...state,
        vendor: action.payload.data,
        vendorFulfilled: true
      };
    case 'GET_VENDOR_REJECTED':
      return {
        ...state,
        vendor: action.payload,
        vendorFulfilled: false
      };

    case 'VENDOR_LIST_FULFILLED':
      return {
        ...state,
        vendors: action.payload,
        vendorsFulfilled: true
      };
    case 'VENDOR_LIST_REJECTED':
      return {
        ...state,
        vendors: action.payload,
        vendorsFulfilled: false
      };

    case 'UPDATE_PURCHASE_ORDER_ITEM_QTY':
      return {
        ...state,
        productsSearched: action.payload
      };

    case 'CREATE_PURCHASE_ORDER_FULFILLED':
      return {
        ...state,
        purchaseOrderCreated: action.payload.data,
        purchaseOrderFulfilled: true
      };
    case 'CREATE_PURCHASE_ORDER_REJECTED':
      return {
        ...state,
        purchaseOrder: action.payload.data,
        purchaseOrderFulfilled: false
      };
    case 'NAVIGATE_TO_PO':
      return {
        ...state,
        purchaseOrderFulfilled: false
      };
    case 'LINE_ITEM_RESET':
      return {
        ...state,
        poItemReceived: false
      };
    case 'GET_PURCHASE_ORDER_FULFILLED':
      return {
        ...state,
        purchaseOrder: action.payload.data,
        purchaseOrderFulfilled: true
      };
    case 'GET_PURCHASE_ORDER_REJECTED':
      return {
        ...state,
        purchaseOrder: action.payload.data,
        purchaseOrderFulfilled: false
      };
    case 'SEARCH_PURCHASE_ORDERS_FULFILLED':
      return {
        ...state,
        purchaseOrders: action.payload,
        purchaseOrdersFulfilled: true
      };
    case 'SEARCH_PURCHASE_ORDERS_REJECTED':
      return {
        ...state,
        purchaseOrders: action.payload,
        purchaseOrdersFulfilled: false
      };
    case 'RECENT_PURCHASE_ORDERS_FULFILLED':
      return {
        ...state,
        purchaseOrders: action.payload.data,
        purchaseOrdersFulfilled: true
      };
    case 'RECENT_PURCHASE_ORDERS_REJECTED':
      return {
        ...state,
        purchaseOrders: action.payload,
        purchaseOrdersFulfilled: false
      };
    case 'SHOW_PURCHASE_ORDER_FULFILLED':
      return {
        ...state,
        purchaseOrder: action.payload.data,
        purchaseOrderFulfilled: true,
        vendorFulfilled: false
      };
    case 'SHOW_PURCHASE_ORDER_REJECTED':
      return {
        ...state,
        purchaseOrder: action.payload,
        purchaseOrderFulfilled: false
      };
    case 'RECEIVE_BULK_PO_ITEM_REJECTED_FULFILLED':
      return {
        ...state,
        poItemReceived: action.payload.data,
        poItemReceivedFulfilled: true
      };
    case 'RECEIVE_BULK_PO_ITEM_REJECTED_REJECTED':
      return {
        ...state,
        poItemReceived: action.payload.data,
        poItemReceivedFulfilled: false
      };

    case 'ADD_PURCHASE_ORDER_ITEM_FULFILLED':
      return {
        ...state,
        poItemReceived: action.payload.data,
        poItemReceivedFulfilled: true,
        purchaseOrderFulfilled: false
      };

    case 'RECEIVE_PO_ITEM_FULLFILLED':
      return {
        ...state,
        poItemReceived: action.payload.data,
        poItemReceivedFulfilled: true,
        purchaseOrderFulfilled: false
      };

    case 'RECEIVE_PO_ITEM_REJECTED':
      return {
        ...state,
        poItemReceived: action.payload.data,
        poItemReceivedFulfilled: false,
        purchaseOrderFulfilled: true
      };

    case 'STORE_RESET':
      return {
        ...state,
        purchaseOrdersFulfilled: false,
        setupScan: false,
        scannedProduct: {}
      };
    case 'STORE_RESET_SCANNER':
      return {
        ...state,
        purchaseOrders: [],
        purchaseOrdersFulfilled: false,
        purchaseOrder: [],
        purchaseOrderFulfilled: false,
        vendorCreated: [],
        vendorCreatedFulfilled: false,
        vendors: [],
        vendorsFulfilled: false
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
