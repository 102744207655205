import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { GlueTableColumn, GlueTableRow } from './GlueTable';
import { getTransparentHex, GlueTheme } from '../../GlueThemeType';

const useStyles = makeStyles((theme: GlueTheme) => {
  return createStyles({
    primary: {
      backgroundColor: theme.palette.primary.main,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    secondaryGreen: {
      backgroundColor: theme.palette.secondaryGreen.main,
    },
    secondaryTurquoise: {
      backgroundColor: getTransparentHex(
        theme.palette.secondaryTurquoise.main, '75'
      ),
      color: theme.palette.getContrastText(
        theme.palette.secondaryTurquoise.main
      ),
    },
    secondaryOrange: {
      backgroundColor: getTransparentHex(
        theme.palette.secondaryOrange.main, '20'
      ),
      color: theme.palette.getContrastText(
        theme.palette.secondaryOrange.main
      ),
    },
    warning: {
      backgroundColor: getTransparentHex(
        theme.palette.warning.main, '20'
      ),
    },
    error: {
      backgroundColor: getTransparentHex(
        theme.palette.error.main, '50'
      ),
    },
    success: {
      backgroundColor: getTransparentHex(
        theme.palette.success.main, '20'
      ),
    },
    info: {
      backgroundColor: getTransparentHex(
        theme.palette.info.main, '20'
      ),
    },
    grey: {
      backgroundColor: getTransparentHex(
        theme.palette.grey[100], '50'
      ),

    },
    darkGray: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.getContrastText(theme.palette.grey[400])
    }
  })
});

export type GlueTableRowsProps = {
  rows: GlueTableRow[];
  page: number;
  rowsPerPage: number;
  sortedColumns: GlueTableColumn[];
};

export default function GlueTableRows({
  rows,
  page,
  rowsPerPage,
  sortedColumns
}: GlueTableRowsProps) {
  const classes = useStyles();
  const tableRows = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row: GlueTableRow) => {
      const className = row.highlightColor
        ? classes[row.highlightColor]
          ? classes[row.highlightColor]
          : ''
        : '';
      const cells = sortedColumns.map((column: GlueTableColumn) => {
        const cellValue = row.data[column.columnKey] ? row.data[column.columnKey] : '';
        const align = column.align;
        return (
          <TableCell
            className={className}
            key={`${column.columnKey}-${row.key}`}
            align={align}
            component="th"
            scope="row"
          >
            {cellValue}
          </TableCell>
        );
      });
      return (<TableRow
        key={row.key}
        className={className}
      >
        {cells}
      </TableRow>
      );
    });

  return <TableBody>{tableRows}</TableBody>;
}