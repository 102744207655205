import { TableCell, TableSortLabel } from '@material-ui/core';
import { snakeCase } from 'lodash';
import React from 'react';
import { GlueTableColumn } from './GlueTable';

export type GlueTableHeaderProps = {
  column: GlueTableColumn;
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
};

export default function GlueTableHeader({
  column,
  orderBy,
  sortDirection,
  onRequestSort
}: GlueTableHeaderProps) {
  const { columnKey, orderNumber, columnName, isSortable, ...other } = column;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableCell {...other} key={columnKey}>
      {isSortable ? (
        <TableSortLabel
          active={orderBy === snakeCase(columnKey)}
          direction={sortDirection}
          onClick={createSortHandler(columnKey)}
        >
          {columnName}
        </TableSortLabel>
      ) : (
        columnName
      )}
    </TableCell>
  );
}
