import React from 'react';
import { Grid, Icon, Typography } from '@material-ui/core';

export type GlueEmptyDisplayProps = {
  className?: string;
};

const GlueEmptyDisplay = ({ className }: GlueEmptyDisplayProps) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" className={className}>
      <Typography color="textSecondary" align="center" variant="h6">
        No data to display.
      </Typography>
      <Typography color="textSecondary" align="center" variant="h6">
        <Icon fontSize="large">inbox</Icon>
      </Typography>
    </Grid>
  );
};

export default GlueEmptyDisplay;
