import React from 'react';
import GenericShippingReconciliationUploader from './GenericShippingReconciliationUploader';

function FedexShippingReconciliationUploader() {
  return (
    <GenericShippingReconciliationUploader carrier="fedex" title="FedEx Shipping Reconciliation" />
  );
}

export default FedexShippingReconciliationUploader;
