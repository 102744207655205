import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TextField, Button, Dialog } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { green, red, pink } from '@material-ui/core/colors';
import isEmpty from 'lodash/isEmpty';
import { useStatus } from '../../../common/Hooks';
import { matchesUpc, matchesPartNumber, validateSerial } from '../../../helpers/scanValidator';
import ScanEventUnsubscriber from '../../../common/ScanEventListening/ScanEventUnsubscriber';

export default function CommittedTransferScanApp(props) {
  const {
    transactionDetails,
    scanUpc,
    scanSerial,
    lastScannedUpcForItem,
    lastScannedSerialForItem,
    scannedSerialsForItem,
    submitScannedItem,
    submittedSerials,
    transactTransfer,
    expectedProduct,
    requestStatus,
    requestMessage,
    transactComplete,
    resetItem,
    serialDoesntExist,
    serialNotAtLocation,
    closeForcePush,
    requestError,
    setForcePush,
    errorLocation
  } = props;
  const upcInputRef = useRef(null);
  const serialInputRef = useRef(null);
  const submitTransferButton = useRef(null);
  const [serialInputValue, setSerialInputValue] = useState('');
  const [upcInputValue, setUpcInputValue] = useState('');
  const [upcStatus, setUpcStatus, upcMessage, setUpcMessage] = useStatus();
  const [serialStatus, setSerialStatus, serialMessage, setSerialMessage] = useStatus();

  // WARNING: Do not add the returned values from useStatus in the dependency arrays like ESLint suggests,
  // as there seems to be an issue with memoization that causes the entire scan app to break.
  useEffect(() => {
    if (isEmpty(upcInputValue)) {
      return;
    }
    if (matchesUpc(upcInputValue, expectedProduct)) {
      setUpcStatus(true);
      setUpcMessage('UPC matches!');
    } else if (matchesPartNumber(upcInputValue, expectedProduct)) {
      setUpcStatus(false);
      setUpcMessage('UPC cannot equal part number.');
    } else {
      setUpcStatus(false);
      setUpcMessage('UPC does not match.');
    }
  }, [upcInputValue, expectedProduct]);

  // WARNING: Do not add the returned values from useStatus in the dependency arrays like ESLint suggests,
  // as there seems to be an issue with memoization that causes the entire scan app to break.
  useEffect(() => {
    if (isEmpty(serialInputValue)) {
      return;
    }
    if (matchesPartNumber(serialInputValue, expectedProduct)) {
      setSerialStatus(false);
      setSerialMessage('Serial cannot equal part number.');
    } else if (matchesUpc(serialInputValue, expectedProduct)) {
      setSerialStatus(false);
      setSerialMessage('Serial cannot equal UPC.');
    }
  }, [expectedProduct, serialInputValue]);

  useEffect(() => {
    const inTheMiddleOfScanning =
      lastScannedUpcForItem !== null || lastScannedSerialForItem !== null;
    if (
      !inTheMiddleOfScanning &&
      transactionDetails.total_scanned_quantity < transactionDetails.committed_quantity
    ) {
      setUpcInputValue('');
      setSerialInputValue('');
      upcInputRef.current.focus();
    }
  }, [
    lastScannedUpcForItem,
    lastScannedSerialForItem,
    upcInputRef.current && upcInputRef.current.disabled,
    closeForcePush
  ]);

  useEffect(() => {
    const upcScanAccepted = upcInputValue === lastScannedUpcForItem;
    if (upcScanAccepted) {
      if (expectedProduct.num_of_serials_per_item > 0) {
        serialInputRef.current.focus();
      } else {
        submitScannedItem();
      }
    }
  }, [upcInputValue, lastScannedUpcForItem]);

  useEffect(() => {
    const upcIsReady = matchesUpc(lastScannedUpcForItem, expectedProduct);
    const lastSerialScanAccepted = serialInputValue === lastScannedSerialForItem;
    if (lastSerialScanAccepted && upcIsReady) {
      if (scannedSerialsForItem.length < expectedProduct.num_of_serials_per_item) {
        setSerialInputValue('');
        return;
      }
      if (validateSerial(lastScannedSerialForItem, expectedProduct)) {
        submitScannedItem();
      }
    }
  }, [serialInputValue, lastScannedSerialForItem, scannedSerialsForItem, lastScannedUpcForItem]);

  useEffect(() => {
    if (
      transactionDetails.committed_quantity === transactionDetails.total_scanned_quantity &&
      !isEmpty(submitTransferButton.current)
    ) {
      submitTransferButton.current.focus();
    }
  }, [transactionDetails]);

  if (!transactionDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ScanEventUnsubscriber />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          background: requestStatus !== null ? (requestStatus ? 'lightgreen' : 'tomato') : ''
        }}
      >
        <div
          style={{
            padding: 15
          }}
        >
          <p>{requestMessage}</p>
          <h2>Shipment Name: {transactionDetails.pos_transfer_batch_name}</h2>
          <h2>Shipment ID: {transactionDetails.pos_transfer_batch_ref_id}</h2>
          <h3>Model Number: {expectedProduct.model}</h3>
          <div>
            <h4>Transfer Details</h4>
            <p>From: {transactionDetails.from_bucket}</p>
            <p>To: {transactionDetails.to_bucket}</p>
          </div>
          <div>
            <p>Total Quantity: {transactionDetails.committed_quantity}</p>
          </div>
          <div>
            {!transactComplete && (
              <TextField
                variant="outlined"
                label="Scan UPC"
                id="scan-upc-field"
                value={upcInputValue}
                error={upcStatus === false}
                helperText={upcMessage}
                onChange={e => setUpcInputValue(e.target.value.trim())}
                inputRef={upcInputRef}
                disabled={
                  (matchesUpc(lastScannedUpcForItem, expectedProduct) &&
                    scannedSerialsForItem.length > 0 &&
                    scannedSerialsForItem.length === expectedProduct.num_of_serials_per_item) ||
                  transactionDetails.committed_quantity ===
                    transactionDetails.total_scanned_quantity
                }
                onKeyDown={e => {
                  if (
                    (e.keyCode === 13 || e.keyCode === 9) &&
                    matchesUpc(upcInputValue, expectedProduct)
                  ) {
                    scanUpc(upcInputValue);
                  }
                }}
              />
            )}
            {!transactComplete && expectedProduct.num_of_serials_per_item !== 0 && (
              <TextField
                variant="outlined"
                label={`Scan Serial ${scannedSerialsForItem.length} / ${expectedProduct.num_of_serials_per_item}`}
                value={serialInputValue}
                error={serialStatus === false}
                helperText={serialMessage}
                onChange={e => setSerialInputValue(e.target.value)}
                inputRef={serialInputRef}
                disabled={
                  (validateSerial(serialInputValue, expectedProduct) ||
                    validateSerial(lastScannedSerialForItem || '', expectedProduct)) &&
                  (scannedSerialsForItem.length === expectedProduct.num_of_serials_per_item ||
                    transactionDetails.committed_quantity ===
                      transactionDetails.total_scanned_quantity)
                }
                onKeyDown={e => {
                  if (
                    (e.keyCode === 13 || e.keyCode === 9) &&
                    validateSerial(serialInputValue, expectedProduct)
                  ) {
                    scanSerial(serialInputValue);
                  }
                }}
              />
            )}
            <div>
              {transactionDetails.committed_quantity ===
                transactionDetails.total_scanned_quantity &&
                !transactComplete && (
                  <Button
                    variant="contained"
                    onClick={transactTransfer}
                    id="submit-transfer-btn"
                    style={{
                      backgroundColor: green[500],
                      color: 'white',
                      marginTop: '6px'
                    }}
                    ref={submitTransferButton}
                  >
                    Submit Transfer
                  </Button>
                )}
            </div>
            {(lastScannedUpcForItem !== null || lastScannedSerialForItem !== null) &&
              expectedProduct.num_of_serials_per_item > 0 && (
                <div>
                  <Button
                    onClick={resetItem}
                    style={{
                      backgroundColor: red[500],
                      color: 'white',
                      marginTop: '6px'
                    }}
                  >
                    Reset Item
                  </Button>
                  <h5>Currently Scanning:</h5>
                  <p>
                    <span style={{ display: 'block' }}>
                      UPC: <strong>{lastScannedUpcForItem}</strong>
                    </span>
                    {!isEmpty(lastScannedSerialForItem) &&
                      expectedProduct.num_of_serials_per_item > 0 && (
                        <span style={{ display: 'block' }}>
                          <span>Serial(s): </span>
                          {scannedSerialsForItem.sort().map((serial, i) => (
                            <span key={i}>
                              <strong>{serial}</strong>
                              {i + 1 < scannedSerialsForItem.length && ` :=: `}
                            </span>
                          ))}
                        </span>
                      )}
                  </p>
                </div>
              )}
            <div>
              {submittedSerials && expectedProduct.num_of_serials_per_item > 0 && (
                <>
                  <h5>Scanned Serials</h5>
                  <ul>
                    {submittedSerials.map(serial => (
                      <li key={serial.scanned_serial_number}>
                        <strong>{serial.scanned_serial_number}:</strong> ({serial.scanned_at})
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '2.3em',
            color:
              transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity
                ? 'white'
                : 'black',
            padding: '5px 10px 10px 10px',
            marginTop: '12px',
            marginRight: '12px',
            borderColor:
              transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity
                ? green[500]
                : 'none',
            borderRadius: '18px',
            borderStyle:
              transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity
                ? 'solid'
                : 'none',
            borderWidth:
              transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity
                ? '1px'
                : '0',
            backgroundColor:
              transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity
                ? green[500]
                : 'none'
          }}
        >
          {transactionDetails.total_scanned_quantity === transactionDetails.committed_quantity && (
            <CheckCircleIcon
              style={{
                color: 'white',
                fontSize: '1.3em',
                paddingRight: '5px',
                paddingTop: '8px'
              }}
            />
          )}
          {transactionDetails.total_scanned_quantity} / {transactionDetails.committed_quantity}
        </div>
      </div>
      <Dialog open={serialDoesntExist || serialNotAtLocation}>
        <div
          style={{
            backgroundColor: serialDoesntExist ? 'goldenrod' : pink[500]
          }}
        >
          <div style={{ padding: 20 }}>
            {serialDoesntExist ? (
              <>
                <h2>Serial Does Not Exist: [{scannedSerialsForItem.sort().join(':=:')}]</h2>
                <p style={{ fontSize: 16 }}>
                  <strong>Would you like to force-push this serial into our records?</strong>
                </p>
              </>
            ) : serialNotAtLocation ? (
              <>
                <h2>Serial Not At Expected Location</h2>
                <p style={{ fontSize: 16 }}>
                  <strong>
                    Would you like to force-push this serial to be from {errorLocation}?
                  </strong>
                </p>
              </>
            ) : (
              ''
            )}
            {(serialDoesntExist || serialNotAtLocation) && (
              <>
                <p style={{ fontSize: 12 }}>{requestError}</p>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'lightgreen', marginRight: 10 }}
                  onClick={() => setForcePush(true)}
                >
                  Force Push
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'tomato' }}
                  onClick={closeForcePush}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
