import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from '../../../AuthContext';
import { useHttp } from '../../../common/Hooks';
import Messaging from './Messaging';
import TransferForm from './TransferForm';
import AdminTransferTool, { AdminOnlyTransferButton } from './AdminTransferTool';
import fetchInventoryByModel from './fetchInventoryByModel';
import InventorySearch from '../Search/InventorySearch';
import CommittedTransfers from '../../CommittedTransfers';
import createCommittedTransfer from './createCommittedTransfer';
import TransactedTransfers from '../../TransactedTransfers';
import { CommittedTransfersContext } from '../../../App';

function InventoryTransferContainer() {
  const [request, response] = useHttp('ims/');
  const [inventory, setInventory] = useState([]);
  const [selectedModelData, setSelectedModelData] = useState({});
  const [selectedModel, setSelectedModel] = useState([]);
  const [transferTypes, setTransferTypes] = useState([]);
  const [submitFeedback, setSubmitFeedback] = useState('');
  const [submitForm, setSubmitForm] = useState('');
  const [numOfCommittedTransfers, setNumOfCommittedTransfers] = useState(0);
  const committedTransferCount = useContext(CommittedTransfersContext);
  const auth = useContext(AuthContext);
  const committedTransfersRef = useRef();
  const [selectedFromBucket, setSelectedFromBucket] = useState('');
  const [isAdminTransferOpen, setAdminTransferOpen] = useState(false);

  const { search } = useLocation();
  const params = useParams();

  useEffect(() => {
    setSelectedFromBucket(search.replace('?from_bucket=', ''));
  }, [search]);

  const modelFilter = params.id;

  const saveNewFeedback = feedback => {
    const feedbackWithMessageId = {
      messageId: Date.now(),
      ...feedback
    };
    setSubmitFeedback(feedbackWithMessageId);
  };

  async function fetchAllInventory() {
    const responseData = await request.get(`products/models`);
    if (response.ok) {
      const tempArray = Object.entries(responseData).map(item => {
        return { label: item[1], value: item[1] };
      });
      setInventory(tempArray); // save initial inventory to re-use without re-refetching
    }
  }

  async function fetchTransferTypes() {
    const responseData = await request.get('buckets');
    if (response.ok) {
      setTransferTypes(Object.keys(responseData));
    }
  }

  // Fetch inventory data
  useEffect(() => {
    fetchAllInventory();
    fetchTransferTypes();
    if (modelFilter !== ':id') {
      setSelectedModel(modelFilter);
      fetchInventoryByModel(modelFilter).then(async response => {
        const responseData = await response.json();
        responseData.inventory.forEach(item => {
          item.scan_required = false;
          item.additional_fields = false;
          if (item.location_code.includes('_INBOUND')) {
            item.additional_fields = true;
            item.scan_required = true;
          }
        });
        setSelectedModelData(responseData);
      });
    }
  }, [setInventory]);

  const searchForModel = searchValue => {
    // from drop-down of inventory
    if (submitForm) {
      submitForm.reset();
      submitForm.submitBtn.disabled = false;
      setSubmitFeedback('');
    }
    if (searchValue) {
      fetchInventoryByModel(searchValue).then(async response => {
        // I'm not the biggest fan of how this response works,
        // It's possible the text could take a while to search through
        // the entire object being returned.
        const data = await response.text();
        let responseData = '';
        if (!data.includes('Product inventory preview')) {
          responseData = JSON.parse(data);
          responseData.inventory.forEach(item => {
            item.additional_fields = item.location_code.includes('_INBOUND');
            item.scan_required = false;
          });
        }
        setSelectedModelData(responseData);
        setSelectedModel(searchValue);
      });
    }
  };

  const checkTransferType = (transferType, i) => {
    // force react to detect state change
    const tempSelectedModel = { ...selectedModelData };
    tempSelectedModel.inventory.forEach((item, index) => {
      // used to render correctly with multiple forms
      item.additional_fields = index === i && transferType.includes('_INBOUND');
    });
    setSelectedModelData(tempSelectedModel);
  };

  const handleSelectChange = (e, i) => {
    if (e.target.value !== 'Not Selected') {
      e.persist();
      if (
        e.target.name === 'toBucket' &&
        !selectedModelData.inventory[i].bucket.includes('_INBOUND')
      ) {
        checkTransferType(e.target.value, i);
      }
    } else {
      // forces that inventory model to update
      // TODO: maybe bring in immer for complex objects like this
      selectedModelData.inventory[i] = {
        ...selectedModelData.inventory[i],
        scan_required: false,
        additional_fields: false
      };
      setSelectedModelData({ ...selectedModelData, inventory: [...selectedModelData.inventory] });
    }
  };

  const handleSubmit = (event, admin = false) => {
    event.preventDefault();
    const { elements } = event.target;
    const postVars = {};
    postVars.fromBucket = elements.fromBucket.value;
    postVars.toBucket = elements.toBucket.value;
    postVars.model = selectedModel;
    postVars.requestedQuantity = elements.requestedQuantity.value;
    postVars.committedById = auth.username;
    postVars.posTransferBatchRefId = elements.posTransferBatchRefId.value;
    postVars.posTransferBatchName = elements.posTransferBatchName.value;
    if ('senderSku' in elements) {
      // check if form even contained this field
      postVars.senderSku = elements.senderSku.value;
    }
    postVars.receiverSku = postVars.senderSku;
    postVars.scanningRequired = elements.scanningRequired.checked;
    postVars.commitNotes = elements.commitNotes.value;

    if (validateForm(postVars)) {
      const submitForm = document.getElementById(postVars.fromBucket);
      setSubmitForm(submitForm);

      createCommittedTransfer(postVars, admin).then(async response => {
        const responseData = await response.json();
        saveNewFeedback(responseData);
        if (isAdminTransferOpen) setAdminTransferOpen(false);
        if (!response.ok) {
          submitForm.submitBtn.disabled = false;
        }

        fetchInventoryByModel(modelFilter).then(async response => {
          const responseData = await response.json();
          const tempModelData = { ...responseData };
          /* eslint-disable no-param-reassign */
          tempModelData.inventory.forEach(item => {
            item.additional_fields = item.location_code.includes('_INBOUND');
            item.scan_required = false;
          });
          /* eslint-enable no-param-reassign */
          setSelectedModelData(tempModelData);
        });

        if (committedTransfersRef && committedTransfersRef.current) {
          committedTransfersRef.current.fetchCommittedTransfers();
        }
      });
    } else {
      const message = {
        messageId: Date.now(),
        message: 'Failed',
        errors: 'One or more required fields are missing'
      };
      saveNewFeedback(message);
    }
  };

  const handleInputChange = event => {
    event.persist();
  };

  const validateForm = postVars => {
    let validationResult = true;
    if (postVars.requestedQuantity === 'Not Selected' || postVars.toBucket === 'Not Selected') {
      validationResult = false;
    } else if (postVars.fromBucket.includes('_INBOUND') || postVars.toBucket.includes('_INBOUND')) {
      const missingBatchRefInfo =
        postVars.posTransferBatchRefId === '' && postVars.posTransferBatchName === '';
      const missingSku = postVars.senderSku === '';

      if (missingBatchRefInfo || missingSku) {
        validationResult = false;
      }
    }
    return validationResult;
  };

  const toggleScanningRequired = (e, i) => {
    const tempInventoryItem = {
      ...selectedModelData.inventory[i],
      scan_required: e.target.checked
    };
    selectedModelData.inventory[i] = tempInventoryItem;
    setSelectedModelData({ ...selectedModelData });
  };

  if (!inventory) {
    return <h1>No Inventory Available</h1>;
  }

  return (
    <div style={{ paddingBottom: 100, position: 'relative' }}>
      {!isEmpty(selectedModelData) ? (
        <AdminOnlyTransferButton handleOpenAdminTransfer={() => setAdminTransferOpen(true)} />
      ) : null}
      <AdminTransferTool
        open={isAdminTransferOpen}
        handleClose={() => setAdminTransferOpen(false)}
        transferTypes={transferTypes}
        handleSubmit={handleSubmit}
      />
      <Messaging submitFeedback={submitFeedback} />
      <InventorySearch searchForModel={searchForModel}>
        {!isEmpty(selectedModelData) ? (
          <div className="transfer-container">
            <span className="title">{selectedModelData.brand_name}</span>
            <span className="title">{selectedModelData.model}</span>
            <span className="title">Inventory</span>
            {selectedModelData.inventory.map((item, index) => {
              let shouldRenderTransferForm = true;
              if (selectedFromBucket) {
                shouldRenderTransferForm = selectedFromBucket === item.bucket;
              }

              return shouldRenderTransferForm ? (
                <TransferForm
                  index={index}
                  toggleScanningRequired={toggleScanningRequired}
                  key={item.bucket}
                  handleSubmit={handleSubmit}
                  item={item}
                  handleInputChange={handleInputChange}
                  transferTypes={transferTypes}
                  handleSelectChange={handleSelectChange}
                />
              ) : null;
            })}
          </div>
        ) : null}
      </InventorySearch>
      <ExpansionPanel>
        <ExpansionPanelSummary>
          <h2>Committed Transfers ({committedTransferCount.count})</h2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CommittedTransfers
            onUpdate={feedback => {
              saveNewFeedback(feedback);
            }}
            onDelete={feedback => {
              saveNewFeedback(feedback);
              setNumOfCommittedTransfers(numOfCommittedTransfers - 1);
            }}
            ref={committedTransfersRef}
            modelFilter={modelFilter}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <TransactedTransfers modelNumber={selectedModel} />
    </div>
  );
}

export default InventoryTransferContainer;
