import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHttp, useMessageDispatch } from '../../common/Hooks';

export default function FinanceCompanyCreation() {
  const [companyName, setCompanyName] = useState('');
  const [dispatch] = useMessageDispatch();
  const [req, res] = useHttp('finance-company', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  async function submitFinanceCompany(e: React.SyntheticEvent) {
    e.preventDefault();
    await req.post('/', {
      company_name: companyName
    });

    if (res.ok) {
      dispatch({ type: 'successes', message: 'Finance Company Created', status: res.status });
      setCompanyName('');
    }
  }

  return (
    <form onSubmit={submitFinanceCompany}>
      <TextField
        label="Company Name"
        value={companyName}
        onChange={e => setCompanyName(e.target.value)}
      />
      <Button type="submit">Submit</Button>
    </form>
  );
}
