import React, { useState, useEffect, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from '../../../AuthContext';
import { useQueryWithLocation as useQuery, useHttp } from '../../../common/Hooks';
import InventorySimulation from './InventorySimulation';
import InventorySearch from '../Search/InventorySearch';

function InventorySimuluationContainer() {
  const auth = useContext(AuthContext);
  const model = useQuery().get('model');
  const timeoutInSeconds = 120;
  const [request, response] = useHttp('ims/inventory/product', {
    timeout: timeoutInSeconds * 1000
  });
  const [donePulling, setDonePulling] = useState(true);
  const [inventoryHistory, setInventoryHistory] = useState({});

  // How do I set this after the fact?
  // Maybe with a defualt value based on the length
  const [panels, setPanels] = useState({ 0: true, 1: false });
  const handleExpansion = panel => {
    setPanels(panelz => {
      return { ...panelz, [panel]: !panels[panel] };
    });
  };

  useEffect(() => {
    async function fetchInventoryHistory(modelArg, page) {
      setDonePulling(false);
      const params = new URLSearchParams();
      params.append('model', modelArg);
      if (page) {
        params.append('page', page);
      }
      const inventoryHistoryRequest = await request.get(`/history/?${params.toString()}`);
      if (response.ok) {
        if (!inventoryHistoryRequest.done) {
          fetchInventoryHistory(modelArg, (page || 0) + 1);
        } else {
          setDonePulling(true);
        }
        if (page) {
          setInventoryHistory(previousState => ({
            ...previousState,
            inventory_history: [].concat(
              previousState.inventory_history || [],
              inventoryHistoryRequest.inventory_history || []
            )
          }));
        } else {
          setInventoryHistory(inventoryHistoryRequest);
        }
      } else {
        setDonePulling(true);
      }
    }

    if (auth) {
      fetchInventoryHistory(model);
    }
  }, [auth, model]);

  if (isEmpty(model)) {
    return <h3>No product model specified.</h3>;
  }

  const loading = request.loading || !donePulling;

  if (isEmpty(inventoryHistory) && !loading) {
    return <h3>No Data Available.</h3>;
  }

  return (
    <InventorySearch>
      <InventorySimulation
        handleExpansion={handleExpansion}
        panels={Object.entries(panels)}
        data={inventoryHistory}
        loading={loading}
      />
    </InventorySearch>
  );
}

export default InventorySimuluationContainer;
