import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiTextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import get from 'lodash/get';

// TODO: These states should use the table in API-OSPOS
export const states = [
  ['Arizona', 'AZ'],
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
];

export const LOCATION_CODE_MAPPINGS = ['ONSITE', 'RELOCATING', 'RETURN', 'FBA_INBOUND'];

// Empty string for default map
export const AUTOGENERATED_LEGACY_BUCKET_MAPPINGS = [
  ['', 'NEW'],
  ['B_STOCK', 'B_STOCK'],
  ['C_STOCK', 'C_STOCK'],
  ['DAMAGED', 'DAMAGED'],
  ['RENEWED', 'RENEWED'],
  ...LOCATION_CODE_MAPPINGS.map(locationCode => [locationCode, 'NEW'])
];

export const getStateAbbreviationByName = name => {
  return states.find(([stateName]) => stateName === name)[1];
};

export function Checkbox({ formState, name, handleToggle }) {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={formState[name]}
          name={name}
          onChange={handleToggle}
          color="primary"
        />
      }
      label={capitalize(startCase(name))}
    />
  );
}

export function TextField({ name, formState, errors, handleChange, disabled = false }) {
  return (
    <MuiTextField
      disabled={disabled}
      label={startCase(name)}
      name={name}
      value={formState[name]}
      error={!!errors[name]}
      helperText={get(errors, name, []).join('')}
      onChange={handleChange}
      fullWidth
    />
  );
}
