import React, { useState, useEffect, useContext, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../common/Hooks';
import Select from '../../common/LayoutComponents/Select';
import { ShippingTemplateContext } from './shippingTemplateContext';

const useCreateShippingTemplateStyles = makeStyles({
  modalField: {
    minWidth: '100%'
  }
});

const initialShippingTemplateState = {
  shippingTemplate: '',
  isParentTemplate: false,
  switchBuffer: 0,
  bucketGroup: '',
  platformCode: '',
  platformShippingTemplateId: ''
};

function CreateShippingTemplate({ setSubmit, isSelected, setDisableButton, setSubmissionState }) {
  const [shippingTemplateState, setShippingTemplateState] = useState(initialShippingTemplateState);
  const [errors, setErrors] = useState({});
  const [platformName, setPlatformName] = useState('');
  const [request, response] = useHttp('ims/shipping-templates');

  const { getShippingTemplates, bucketGroupsList, platforms } = useContext(ShippingTemplateContext);

  const bucketGroupsOptions = bucketGroupsList.length
    ? bucketGroupsList.map(({ label }) => label)
    : [];

  const platformNames = platforms.map(platform => platform.platform_name);
  const platformNameMaps = platforms.reduce(
    (carry, platform) => ({
      ...carry,
      [platform.platform_name]: platform
    }),
    {}
  );

  // Submit button function
  const submitCreateShippingTemplate = useCallback(async () => {
    setDisableButton(true);
    setSubmissionState({ isSubmitting: true, isSubmitted: false });
    const submitResponse = await request.post('', {
      ...shippingTemplateState
    });
    if (response.ok) {
      setShippingTemplateState(initialShippingTemplateState);
      setPlatformName('');
      getShippingTemplates();
      setSubmissionState({ isSubmitting: false, isSubmitted: true });
    } else {
      setSubmissionState({ isSubmitting: false, isSubmitted: false });
      setErrors(submitResponse);
      setDisableButton(false);
    }
  }, [getShippingTemplates, shippingTemplateState]);

  useEffect(() => {
    if (isSelected) {
      // set the submit button function
      // the the function needs to be wrapped
      // if setState is given a function it will invoke
      // the function
      setSubmit(() => submitCreateShippingTemplate);
    }
  }, [submitCreateShippingTemplate, setSubmit, isSelected]);

  useEffect(() => {
    if (isSelected) {
      setDisableButton(!isEmpty(errors));
    }
  }, [isSelected, setDisableButton, errors]);

  const handleChange = ({ target: { name, checked, value, type } }) => {
    setErrors(previousErrorState => {
      const { [name]: removed, ...errorState } = previousErrorState;
      return errorState;
    });
    setShippingTemplateState(previousState => ({
      ...previousState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePlatformChange = ({ target: { name, value } }) => {
    if (!isEmpty(errors)) {
      setErrors(previousErrorState => {
        const { [name]: removed, ...errorState } = previousErrorState;
        return errorState;
      });
    }
    setShippingTemplateState(previousState => ({
      ...previousState,
      platformCode: platformNameMaps[value] ? platformNameMaps[value].platform_code : ''
    }));
    setPlatformName(value);
  };

  const shouldDisableNonParentOptions = shippingTemplateState.isParentTemplate;

  useEffect(() => {
    if (shouldDisableNonParentOptions) {
      setShippingTemplateState(previousProps => ({
        ...previousProps,
        switchBuffer: 0,
        bucketGroup: ''
      }));
    }
  }, [shouldDisableNonParentOptions]);

  const classes = useCreateShippingTemplateStyles();

  return (
    <List>
      <ListItem>
        <TextField
          name="shippingTemplate"
          value={shippingTemplateState.shippingTemplate}
          onChange={handleChange}
          label="Shipping Template"
          error={!!errors.shippingTemplate}
          helperText={get(errors, 'shippingTemplate', []).join('')}
          className={classes.modalField}
        />
      </ListItem>
      <ListItem>
        <FormControlLabel
          control={
            <Checkbox
              name="isParentTemplate"
              onChange={handleChange}
              value={shippingTemplateState.isParentTemplate}
              color="primary"
            />
          }
          label="Is Composite Template"
          className={classes.modalField}
        />
      </ListItem>
      <ListItem>
        <TextField
          name="switchBuffer"
          value={shippingTemplateState.switchBuffer}
          disabled={shouldDisableNonParentOptions}
          onChange={handleChange}
          label="Switch Buffer"
          type="number"
          className={classes.modalField}
        />
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            handleChange={handleChange}
            options={bucketGroupsOptions}
            inputValue={shippingTemplateState.bucketGroup}
            isDisabled={shouldDisableNonParentOptions}
            label="Bucket Group"
            name="bucketGroup"
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <FormControl className={classes.modalField}>
          <Select
            handleChange={handlePlatformChange}
            options={platformNames}
            inputValue={platformName}
            isDisabled={shouldDisableNonParentOptions}
            error={!!errors.platformCode}
            helperText={get(errors, 'platformCode', []).join('')}
            label="Platform"
            name="platformCode"
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <TextField
          name="platformShippingTemplateId"
          value={shippingTemplateState.platformShippingTemplateId}
          disabled={shouldDisableNonParentOptions}
          onChange={handleChange}
          label="Platform Shipping Template ID"
          type="text"
          className={classes.modalField}
        />
      </ListItem>
    </List>
  );
}

export default CreateShippingTemplate;
