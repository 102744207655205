import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer } from 'react-sortable-hoc';
import { Paper } from '@material-ui/core';
import CarouselItemList from './CarouselItemList';
import { reorderCarouselItems } from './redux/carouselSlice';
import HigherOrderControls from './HigherOrderControls';

const SortableList = SortableContainer(CarouselItemList);

const CarouselApp = ({ refetch }) => {
  const dispatch = useDispatch();
  const carouselItems = useSelector(state => state.carouselItems);

  const onSortEnd = e => {
    dispatch(reorderCarouselItems({ oldIndex: e.oldIndex, newIndex: e.newIndex }));
  };

  return (
    <Paper style={{ padding: '1em', marginTop: '1em' }}>
      <SortableList items={carouselItems} onSortEnd={onSortEnd} />
      <HigherOrderControls refetch={refetch} />
    </Paper>
  );
};

export default CarouselApp;
