import React, { useContext, useRef } from 'react';
import { createStyles, makeStyles, Grid, Paper } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import GlueButton from '../../../Presentational/GlueButton';
import GlueTooltip from '../../../Presentational/GlueTooltip';
import { PrintPickListTable } from './PrintPickListTable';
import { PickListContext } from '../PickListContext';
import { PickableItem, PrintPickListResponse, PrintPickListSuccessResponse, PrintResponseItem } from '../PickListTypes';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      margin: theme.spacing(4, 0)
    },
    outline: {
      maxWidth: 1080,
      border: '1px solid black'
    }
  })
);

export type PickListPrintViewProps = {
  onClose: () => void;
}

export default function PickListPrintView({ onClose }: PickListPrintViewProps) {
  const classes = useStyles();
  const componentRef = useRef<PrintPickListTable>(null);
  const {
    itemsToPrint,
    pickList,
    printPickList,
    setPickList,
    mapPLItemToPickableItem,
    printPickListErrors,
    setPrintPickListErrors,
    printPickListSuccess,
    loadingPrintPickList,
    queryParams
  } = useContext(PickListContext);

  const isSelected = (i: PickableItem) => itemsToPrint.indexOf(i.transactionReferenceId) > -1;

  const isPrintDisabled = loadingPrintPickList || !isEmpty(printPickListErrors);

  const throwPrintError = () => {
    throw Error('Error printing pick list items.');
  }

  const preparePrintData = () => {
  const userName = localStorage.getItem('username');
    return {
      printedBy: userName ?? '',
      items: itemsToPrint
        .map(mapPLItemToPickableItem)
        .filter(i => i.itemMemberRefId !== 0),
    }
  }

  const mapPrintSuccessResponse = (response: PrintPickListSuccessResponse) => {
    return pickList.map((i: PickableItem) => {
      let pickListItem: PickableItem = {...i};
      if (isSelected(i)) {
        const responseItem = response.find((item: PrintResponseItem) => {
          return item.ims_transaction_reference_id === i.transactionReferenceId
        });
        pickListItem.pickListId = responseItem?.ims_pick_list_id;
        pickListItem.pickListItemId = responseItem?.id;
      }
      return pickListItem;
    });
  };

  const handlePrintResponse = (response: void | PrintPickListResponse) => {
    if (Array.isArray(response) && response.length > 0) {
      const updatedPickListItems: PickableItem[] = mapPrintSuccessResponse(response);
      setPickList(updatedPickListItems);
    } else {
      throwPrintError();
    }
  }

  const handleOnBeforeGetContent = async () => {
    if (isPrintDisabled || printPickListSuccess) {
      throwPrintError();
    }
    const printData = preparePrintData();
    const response: void | PrintPickListResponse = await printPickList(printData);
    handlePrintResponse(response);
  };

  const handleAfterPrint = () => {
    if (printPickListSuccess) {
      let closePickListPrintModal = setTimeout(onClose, 1000);
      return () => {
        clearTimeout(closePickListPrintModal);
      };
    }
  };

  const handlePrintError = (errorLocation: string, error: Error) => {
    if (isPrintDisabled || printPickListSuccess) {
      return;
    };
    if (!isEmpty(printPickListErrors)) {
      setPrintPickListErrors({
        ...printPickListErrors,
        error: error.message,
      })
    }
  };

  let endIcon = 'print';

  let printButtonText = 'Print Selected Rows';
  if (loadingPrintPickList) {
    printButtonText = 'Printing Pick Items';
    endIcon = 'hourglass_empty';
  }
  if (printPickListSuccess) {
    printButtonText = 'Print Successful';
    endIcon = 'done';
  }
  if (!isEmpty(printPickListErrors)) {
    printButtonText = 'Error Printing';
    endIcon = 'error_outlined';
  }

  const printButton = (
    <div className={classes.button}>
      <GlueTooltip title={printButtonText}>
        <span>
          <GlueButton
            success={printPickListSuccess}
            disabled={isPrintDisabled} 
            size={'large'} 
            endIcon={endIcon}>
            {printButtonText}
          </GlueButton>
        </span>
      </GlueTooltip>
    </div>
  );

  return (
    <div>
      <ReactToPrint
        onBeforeGetContent={handleOnBeforeGetContent}
        onAfterPrint={handleAfterPrint}
        onPrintError={handlePrintError}
        trigger={() => printButton}
        content={() => componentRef.current}
      />
      <Paper
        style={{
          width: 1080,
          border: '1px dashed #6D6D6D'
        }}
        elevation={0}
        variant={'outlined'}
      >
        <PrintPickListTable
          className={classes.outline}
          itemsNumber={itemsToPrint.length}
          isDonePrinting={printPickListSuccess && isEmpty(printPickListErrors)}
          ref={componentRef}
          warehouse={queryParams.warehouse ?? ''}
        />
      </Paper>
      <Grid container xs={12} alignItems={'center'} spacing={3}>
        <Grid item>
          <div>
            <ReactToPrint
              onBeforeGetContent={handleOnBeforeGetContent}
              onAfterPrint={handleAfterPrint}
              onPrintError={handlePrintError}
              trigger={() => printButton}
              content={() => componentRef.current}
            />
          </div>
        </Grid>
        <Grid item>
          <GlueButton
            disabled={loadingPrintPickList}
            onClick={onClose}
            size={'large'}
            variant={'outlined'}>
            Cancel
          </GlueButton>
        </Grid>
      </Grid>
    </div>
  );
};

