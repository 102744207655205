import React from 'react';
import { useSelector } from 'react-redux';
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import orderBy from 'lodash/orderBy';
import Message from './ErrorMessage';

const useMessangingQueueStyles = makeStyles(theme => ({
  errorMessage: {
    paddingBottom: theme.spacing(1)
  }
}));

function MessangingQueue() {
  const errors = useSelector(state => state.messangingQueue.errors);
  const successes = useSelector(state => state.messangingQueue.successes);
  const messages = orderBy([...errors, ...successes], ['date'], ['desc']);
  const theme = useTheme();
  const classes = useMessangingQueueStyles();
  const shouldHideQueue = useMediaQuery(theme.breakpoints.down('xs'));

  if (shouldHideQueue) {
    return null;
  }

  return messages.map((message, index) => (
    <Message
      className={classes.errorMessage}
      key={message.date}
      count={messages.length - index}
      type={message.type}
      // This is a controlled error prop
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...message}
    />
  ));
}

export default MessangingQueue;
