import { Card, TextField } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { isURL } from 'validator';
import { useDispatch } from 'react-redux';
import { deleteCarouselItem, updateCarouselItem } from './redux/carouselSlice';

const useStyles = makeStyles({
  deleteButton: {
    float: 'right',
    position: 'relative',
    color: 'white',
    backgroundColor: ' #cc303b ',
    border: 'none',
    borderRadius: '4px',
    textTransform: 'uppercase',
    letterSpacing: '0.28px',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    padding: '1em',
    fontSize: '0.6rem',
    lineHeight: '1.6',
    '&:hover': {
      backgroundColor: '#91190b',
      transition: '0.2s'
    },
    '&:active': {
      backgroundColor: '#82170b'
    },
    '&:disabled': {
      backgroundColor: '#9c9c9c',
      color: '#e7e7e7',
      cursor: 'not-allowed'
    }
  }
});

const CarouselItem = ({ id, link, img, errors, sortOrder, count }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleCarouselItemEdit = e => {
    dispatch(
      updateCarouselItem({
        id,
        link: e.target.value,
        errors: { link: !isURL(e.target.value, { protocols: ['https'], require_protocol: true }) }
      })
    );
  };
  const handleDeleteClick = () => {
    dispatch(deleteCarouselItem({ id }));
  };

  return (
    <Card
      className="list-group-item"
      style={{ padding: '1em', margin: '1em', backgroundColor: '#e0e0e0' }}
      draggable="false"
    >
      <div draggable="false" style={{ width: '100%', display: 'inline-block' }}>
        <p
          draggable="false"
          className="disable-text-selection"
          style={{ float: 'left', position: 'relative', fontSize: '1.5em', fontWeight: 'bold' }}
        >
          {sortOrder}
        </p>
        <button
          type="submit"
          onClick={handleDeleteClick}
          className={classes.deleteButton}
          draggable="false"
          disabled={count === 1}
        >
          Remove
        </button>
      </div>
      <img src={img} alt="carousel item" draggable="false" width="100%" />
      <div style={{ marginTop: '1em' }} draggable="false">
        <TextField
          value={link}
          label={errors.link ? 'Invalid URL' : 'Page the image links to...'}
          error={!!errors.link}
          onChange={handleCarouselItemEdit}
          variant="outlined"
          style={{ width: '100%' }}
          draggable="false"
        />
      </div>
    </Card>
  );
};

export default CarouselItem;
