import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import Results from '../../../common/LayoutComponents/ResultsView';
import TableView from '../../../common/LayoutComponents/Table';

function titleCase(str) {
  return startCase(lowerCase(str)).replace(/\sby|at|to\s/gi, found => found.toLowerCase());
}

const TransactionsLink = ({ children, details }) => (
  <Link
    style={{ textTransform: 'uppercase' }}
    target="_blank"
    to={`/inventory-transactions/${details.ims_transaction_reference_id}`}
  >
    {children}
  </Link>
);

function Transaction(props) {
  const { transaction_type } = props;

  switch (transaction_type) {
    case 'INVENTORY_RECEIPT': {
      const { transacted_receipt: details } = props;
      return (
        <div>
          <TransactionsLink details={details}>received</TransactionsLink>
          <strong>{` ${details.received_quantity}`}</strong>
          &nbsp;onto{' '}
          {details.po_api_po_id != null ? (
            <span>
              , PO #
              <a target="_blank" href={`/purchase-orders/review/${details.po_api_po_id}`}>
                {details.po_api_po_id}
              </a>
            </span>
          ) : details.legacy_po_api_po_id != null ? (
            <span>
              , legacy PO #
              <MuiLink
                component={Link}
                target="_blank"
                to={`/purchase-orders/review/${details.legacy_po_api_po_id}?legacy=true`}
              >
                {details.legacy_po_api_po_id}
              </MuiLink>
            </span>
          ) : null}
          &nbsp;into{' '}
          {details.receiving_bucket ||
            `${details.receiving_location_code} / ${details.receiving_condition_code}`}
        </div>
      );
    }
    case 'INVENTORY_TRANSFER': {
      const { transacted_transfer: details } = props;
      return (
        <div>
          <TransactionsLink details={details}>transferred</TransactionsLink>
          <strong>{` ${details.transferred_quantity}`}</strong>:{' '}
          {details.from_bucket || `${details.from_location_code} / ${details.from_condition_code}`}{' '}
          --> {details.to_bucket || `${details.to_location_code} / ${details.to_condition_code}`},
          by:{' '}
          {(details.transferred_by_name || details.transferred_by_id || '')
            .toUpperCase()
            .includes('LEGACY')
            ? details.committed_by_name || details.committed_by_id
            : details.transferred_by_name || details.transferred_by_id}{' '}
          {(details.pos_transfer_batch_ref_id ||
            details.pos_transfer_batch_name ||
            details.transfer_notes ||
            details.commit_notes) != null ? (
            <span style={{ fontWeight: 'bold' }}>
              {(details.pos_transfer_batch_ref_id || details.pos_transfer_batch_name) != null
                ? `(${details.pos_transfer_batch_ref_id || details.pos_transfer_batch_name})`
                : ''}{' '}
              {(details.transfer_notes || details.commit_notes) != null &&
              (details.transfer_notes || details.commit_notes) !==
                (details.pos_transfer_batch_ref_id || details.pos_transfer_batch_name)
                ? ` ("${details.transfer_notes || details.commit_notes}")`
                : ''}
            </span>
          ) : (
            ''
          )}
        </div>
      );
    }
    case 'INVENTORY_SALE': {
      const { transacted_sale: details } = props;
      return (
        <div>
          <TransactionsLink details={details}>invoiced</TransactionsLink>
          <strong>{` ${details.invoiced_quantity}`}</strong>
          &nbsp;Finished and sourced as{' '}
          {details.source_bucket ||
            `${details.source_location_code} / ${details.source_condition_code}`}
          &nbsp;
          {details.pos_work_order_id != null ? (
            <a target="_blank" href={`/work-orders/${details.pos_work_order_id}`}>
              {details.pos_work_order_id}
            </a>
          ) : details.legacy_pos_invoice_id != null ? (
            <span>
              , invoice #
              <a
                target="_blank"
                href={`https://wpos.walts.com/pos/review_invoice.php?invoice=${details.legacy_pos_invoice_id}`}
              >
                {details.legacy_pos_invoice_id}
              </a>
            </span>
          ) : null}
        </div>
      );
    }
    case 'INVENTORY_AUDIT_ADJUSTMENT': {
      const { transacted_audit_adjustment: details } = props;
      return (
        <div>
          <TransactionsLink details={details}>audit-adjusted</TransactionsLink>
          &nbsp;quantity in&nbsp;
          {details.audit_bucket ||
            `${details.audit_location_code} / ${details.audit_condition_code}`}
          &nbsp;: <strong>{` ${details.old_quantity}`}</strong> -->{' '}
          <strong>{` ${details.new_quantity}`}</strong>, by:{' '}
          {details.submitted_by_name ||
            details.submitted_by_id ||
            details.tallied_by_name ||
            details.tallied_by_id ||
            ''}{' '}
          {(details.submit_notes || details.tally_notes) != null ? (
            <span style={{ fontWeight: 'bold' }}>
              {(details.submit_notes || details.tally_notes) != null
                ? `("${details.submit_notes || details.tally_notes}")`
                : ''}
            </span>
          ) : (
            ''
          )}{' '}
          {details.ims_initiating_transaction_id != null ? (
            <span style={{ fontWeight: 'bold' }}>
              (Initiating transaction: &nbsp;
              <a
                target="_blank"
                href={`/inventory-transactions/${details.ims_initiating_transaction_id}`}
              >
                {details.ims_initiating_transaction_id}
              </a>
              )
            </span>
          ) : (
            ''
          )}
        </div>
      );
    }
    default:
      break;
  }
}

function EventHistory(props) {
  const { events_date = null, simulated_totals = {}, transactions = [] } = props;
  return (
    <div style={{ padding: 15, border: '1px solid #e6e6e6', marginBottom: 15 }}>
      <p>{events_date}</p>
      <div style={{ width: '100%' }}>
        <h3>Events</h3>
        {transactions.map((transaction, key) => (
          <Transaction key={key} {...transaction} />
        ))}
        <h3>Simulated Totals</h3>
        <div>
          {Object.entries(simulated_totals).map(([key, value]) => (
            <div key={key}>
              {key}: {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const useInventorySimulationStyles = makeStyles(theme => ({
  danger: {
    color: theme.palette.error.dark
  },
  success: {
    color: theme.palette.success.main
  },
  totals: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase'
  }
}));

export default function InventorySimulation(props) {
  const classes = useInventorySimulationStyles();
  const { data = {}, loading } = props;

  const finalHistoricalRecord = get(data, 'final_inventory_history[0]', {}) || {};
  const finalSimulatedInventoryState = finalHistoricalRecord.simulated_totals || {};
  const totalSimulatedCount = Object.values(finalSimulatedInventoryState).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const inventory = get(data, 'inventory', []);

  const totalRealInventoryCount = inventory.reduce((acc, { quantity }) => acc + quantity, 0);
  const realBucketsNotIncluded = inventory.filter(
    ({ bucket }) => !Object.keys(finalSimulatedInventoryState).includes(bucket)
  );
  const eventsSummary = Object.entries(get(data, 'events_summary', {})) || [];
  const inventoryHistory = get(data, 'inventory_history', []) || [];
  const finalSimulatedInventoryStateKeyed = Object.entries(finalSimulatedInventoryState || {});

  return (
    <>
      {eventsSummary.length ? (
        <Results>
          <div>
            <img src={data.image_url} />
          </div>
          <div style={{ maxWidth: 500 }}>
            <h3>
              <Link to={`/inventory/detail/model/${data.model}`}>{data.product_title}</Link>
            </h3>
            <p>Total Inventory: {totalRealInventoryCount}</p>
            <p>Model: {data.model}</p>
            <p>MPN: {data.mpn}</p>
            <p>UPC: {data.upc}</p>
          </div>
          <div>
            <h3>Event Summary</h3>
            {eventsSummary.map(([summaryLabel, summaryValue] = []) => (
              <div key={summaryLabel + summaryValue}>
                <p>
                  {titleCase(summaryLabel)}: {summaryValue}
                </p>
              </div>
            ))}
          </div>
        </Results>
      ) : null}
      {inventoryHistory.map((historyItem, key) => (
        <EventHistory key={key} {...historyItem} />
      ))}
      {loading ? <h3>Fetching inventory history...</h3> : null}
      {inventoryHistory.length ? (
        <Results>
          <div>
            <h2>Simulation vs Reality</h2>
            <TableView
              header={['Bucket', 'Simulated', 'Real', 'Match']}
              initialRowsPerPage={
                finalSimulatedInventoryStateKeyed.length + realBucketsNotIncluded.length
              }
            >
              {finalSimulatedInventoryStateKeyed.map(([key, value]) => {
                const realItemFiltered = data.inventory.find(({ bucket }) => bucket === key);
                const realItem = get(realItemFiltered, 'quantity', 0);
                return (
                  <TableRow
                    key={key + value}
                    style={{
                      background: realItem === value ? 'lightgreen' : 'salmon'
                    }}
                  >
                    <TableCell>{key}:</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>{realItem}</TableCell>
                    <TableCell className={realItem === value ? classes.success : classes.danger}>
                      {(realItem === value).toString()}
                    </TableCell>
                  </TableRow>
                );
              })}
              {realBucketsNotIncluded.map(location => (
                <TableRow key={location.bucket}>
                  <TableCell>{location.bucket}</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>{location.quantity}</TableCell>
                  <TableCell className={classes.danger}>False</TableCell>
                </TableRow>
              ))}
            </TableView>
            <Typography>
              Total Real: <strong>{totalRealInventoryCount}</strong>
            </Typography>
            <Typography>
              Total Simulated: <strong>{totalSimulatedCount}</strong>
            </Typography>
            <Typography
              className={`${
                totalRealInventoryCount === totalSimulatedCount ? classes.success : classes.danger
              } ${classes.totals}`}
            >
              {totalRealInventoryCount === totalSimulatedCount
                ? 'totals match!'
                : 'totals do not match!'}
            </Typography>
          </div>
        </Results>
      ) : null}
    </>
  );
}
