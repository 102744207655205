import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    alignSelf: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'auto',
    height: 600
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  head: {
    // backgroundColor: "#fff",
    position: 'sticky',
    top: 0
  }
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

function SimpleTable(props) {
  const { classes, data, platforms, changeTax } = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell className={classes.head}>State</CustomTableCell>
            {platforms.map((n, k) => (
              <CustomTableCell className={classes.head} key={k}>
                {n.platform_name}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map(i => {
            return (
              <TableRow className={classes.row} key={i}>
                <CustomTableCell component="th" scope="row">
                  {i}
                </CustomTableCell>
                {platforms.map((n, k) => (
                  <CustomTableCell
                    style={{
                      backgroundColor: data[i][n.platform_code] === 0 ? '' : '#34d387'
                    }}
                    onClick={() => changeTax(i, n.platform_code, data[i][n.platform_code])}
                    key={i + n.platform_code}
                    component="th"
                    scope="row"
                  >
                    {data[i][n.platform_code] === 0 ? 'False' : 'True'}
                  </CustomTableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

/*
{ Object.keys(data).map(i=>{

   return data[i].map((n, k) => (
      <CustomTableCell key={} component="th" scope="row">{n.platform_code} {n.state_code} {n.collectAndRemitTax}</CustomTableCell>
  ))
}) }

{data[n.state_code].map((m, l) => (
                    <CustomTableCell key={m.state_code + l} component="th" scope="row">{m.platform_code} {m.state_code} {m.collectAndRemitTax}</CustomTableCell>
                  ))}

*/

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTable);
