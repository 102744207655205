import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { ReactElement } from 'react';
import { GlueTheme } from '../../GlueThemeType';

export interface GlueTooltipProps extends TooltipProps {
  children: ReactElement;
}

export default function GlueTooltip(props: GlueTooltipProps) {
  const {
    children,
    ...other
  } = props;
  const theme = useTheme<GlueTheme>();
  const StyledTooltip = withStyles({
    tooltip: {
      backgroundColor: '#6D6D6D',
      color: '#FFFFFF',
      fontSize: 12,
      zIndex: 3,
      opacity: '80%'
    }
  })(Tooltip);

  return (
    <StyledTooltip
      {...other}
    >
      {children}
    </StyledTooltip>
  );
}