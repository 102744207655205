import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import DownloadReport from './DownloadReport';
import { useDownloadWorker } from '../../common/Hooks';
import moment from 'moment';

function GeOnHandExport() {
  const currentDate = moment().format('YYYY-MM-DD');
  const geExportBaseUrl = '/ims/reporting/inventory/ge-on-hand-export';
  const [dateInput, setDateInput] = useState<string>(currentDate);

  const [geExportReportFileName, setGeExportReportFileName] = useState('');
  const [totalsSummaryFileName, setTotalsSummaryFileName] = useState('');

  const [geExportUrl, setGeExportUrl] = useState<string>(geExportBaseUrl);

  useEffect(() => {
    setGeExportReportFileName(`${dateInput}-GE_on_hand_export`);
    setTotalsSummaryFileName(`${dateInput}-GE_on_hand_totals`);
  }, [dateInput]);

  const [sendGeTotalsUrlToWorker] = useDownloadWorker({ reportName: totalsSummaryFileName });

  const onDateInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setDateInput(value);
  };

  const handleSubmit = () => {
    sendGeTotalsUrlToWorker(`${geExportBaseUrl}/totals?date=${dateInput}`);
    setGeExportUrl(previousUrl => `${previousUrl}?date=${dateInput}`);
  };

  return (
    <DownloadReport
      url={geExportUrl}
      name={geExportReportFileName}
      hasOptions={true}
      submit={handleSubmit}
    >
      <TextField
        InputLabelProps={{
          shrink: true
        }}
        onChange={onDateInputChange}
        label="Date Of Report"
        type="date"
        value={dateInput}
      />
    </DownloadReport>
  );
}

export default GeOnHandExport;
